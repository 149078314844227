import { getTheme, Stack, Text } from '@fluentui/react';
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Typewriter from 'typewriter-effect';
import appIcon from '../../media/img/AppIcon-no-bg.png';

export interface IComponentClassNames {
    bouncingLogo: string;
    loadingText: string;
}

const LoadingDialogFullView: React.FC<{ loadingStrings: string | string[]; loop?: boolean; typewriter: boolean }> = ({ loadingStrings, loop, typewriter }) => {
    const [displayToNone, setDisplayToNone] = useState<string>('block');
    const theme = getTheme();
    const bouncingAnimation = keyframes`
    0% {
        top: 3px
    }
    50% {
        top: -7px
    }
    100% {
        top: 3px
    }
    `;

    const BouncingCampusIcon = styled.img`
        display: inline-block;
        position: relative;
        width: 80px;
        animation: ${bouncingAnimation} 0.7s infinite;
    `;
    const FadeOutBackground = styled.div`
        display: ${displayToNone};
        height: 100vh;
        width: 100%;
        background-color: ${theme.palette.neutralLight};
        position: absolute;
        z-index: 99999;
    `;

    return (
        <>
            <FadeOutBackground>
                <Stack style={{ height: '100%', width: '100%' }} horizontalAlign="center" verticalAlign="center">
                    <Stack horizontal={false} horizontalAlign="center" verticalAlign="space-around" style={{ height: '20vh ', width: '30vh' }}>
                        <Stack>
                            <BouncingCampusIcon src={appIcon} alt="bouncing campus icon" />
                        </Stack>

                        <Stack horizontal horizontalAlign="center" verticalAlign="center">
                            <Text variant="large" style={{ textAlign: 'center', color: theme.palette.black }}>
                                {typewriter ? (
                                    <Typewriter
                                        options={{
                                            cursor: '',
                                            deleteSpeed: 1,
                                            strings: loadingStrings,
                                            autoStart: true,
                                            delay: 15,
                                            loop: loop
                                        }}
                                        onInit={(typewriter) => {
                                            typewriter.callFunction(() => setDisplayToNone('none'));
                                        }}
                                    />
                                ) : (
                                    <Text variant="xLarge">{loadingStrings[0] || 'Empty'}</Text>
                                )}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </FadeOutBackground>
        </>
    );
};

export default LoadingDialogFullView;
