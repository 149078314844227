import { Icon, Persona, PersonaSize, Stack, Text, getTheme } from '@fluentui/react';
import React from 'react';
import UserActions from './Actions/UserActions';
import { IAreaSegmentProps, ICrossUserProfile } from '../dataTypes';
import { useCurrentAreaId } from 'shared-state/location/hook';

const UserRow: React.FC<{ user: ICrossUserProfile }> = ({ user }) => {
    const theme = getTheme();

    return (
        <Stack
            key={user.uid}
            style={{
                width: '100%',
                padding: '0 10px',
                height: 50,
                backgroundColor: theme.palette.white,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: `0.5px solid ${theme.palette.black}`
            }}
        >
            <Persona
                imageUrl={user.profileImage}
                imageInitials={
                    user.displayName.split(' ').length > 1
                        ? `${user.displayName.split(' ')[0].slice(0, 1).toUpperCase()}${user.displayName.split(' ')[1].slice(0, 1).toUpperCase()}`
                        : `${user.displayName.split(' ')[0].slice(0, 1).toUpperCase()}`
                }
                text={user.displayName}
                size={PersonaSize.size32}
                imageAlt={user.displayName}
            />

            <Stack horizontal>
                <UserActions user={user} />
            </Stack>
        </Stack>
    );
};

const AreaSegment: React.FC<IAreaSegmentProps> = ({ areaName, users, iconName, handleToggleArea, expanded, textColor, mobileView }) => {
    const [currentAreaId] = useCurrentAreaId();
    const isUserInsideOFAnArea = currentAreaId && window.location.pathname.includes('/areas/');

    if (!users.length) {
        return null; // Skip rendering the area if there are no users
    }

    return (
        <Stack>
            {isUserInsideOFAnArea ? (
                <>
                    <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={handleToggleArea}>
                        <Icon
                            iconName={iconName}
                            style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: mobileView ? '#000' : textColor
                            }}
                        />
                        <Text
                            variant="medium"
                            style={{
                                fontWeight: 600,
                                marginLeft: 5,
                                color: mobileView ? '#000' : textColor
                            }}
                        >
                            In {areaName}
                        </Text>
                    </Stack>
                    {expanded && (
                        <Stack
                            style={{
                                margin: '10px 0'
                            }}
                            tokens={{
                                childrenGap: 10
                            }}
                        >
                            {users.map((user) => (
                                <UserRow user={user} key={user.uid} />
                            ))}
                        </Stack>
                    )}
                </>
            ) : (
                <Stack
                    tokens={{
                        childrenGap: 10
                    }}
                >
                    {users.map((user) => (
                        <UserRow user={user} key={user.uid} />
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

export default AreaSegment;
