import { getTheme, IStyle, mergeStyles, Stack } from '@fluentui/react';
import React, { CSSProperties } from 'react';

export const getSVGIconStyles2 = (hoverable?: boolean, customIconColor?: string): any => {
    const theme = getTheme();
    const iconStyle: IStyle = {
        ' svg *': {
            fill: customIconColor
        }
    };

    const hoverableStyle: IStyle = {
        ':hover svg *': {
            fill: theme.palette.themePrimary
        }
    };

    return mergeStyles(hoverable ? [iconStyle, hoverableStyle] : iconStyle);
};

export interface ISVGIconProps {
    size?: number;
    hoverable?: boolean;
    iconColor?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
}

const SVGIcon2: React.FC<ISVGIconProps> = ({ children, size, hoverable, iconColor, style }) => {
    const svgIconStyles = getSVGIconStyles2(hoverable, iconColor);
    return (
        <Stack
            className={'svg-icon ' + svgIconStyles + (hoverable ? ' svg-icon--hoverable' : '')}
            horizontalAlign="center"
            verticalAlign="center"
            style={{ ...style, ...{ fontSize: size } }}
        >
            {children}
        </Stack>
    );
};

export default SVGIcon2;
