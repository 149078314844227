import { Stack } from '@fluentui/react';
import TopBar from 'features/Header/children/TopBar/TopBar';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

export const UITest: React.FC<{}> = () => {
    return (
        <>
            <TopBar title={IvicosStrings.experimentalFeatures} />
            <Stack style={{ maxWidth: '100%', height: 800, paddingTop: 16, backgroundColor: 'lightgray' }}></Stack>
        </>
    );
};
