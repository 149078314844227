export const de = {
    //Topbar
    helpLinkTitle: 'Hilfe',
    signOutActionTitle: 'Abmelden',
    experimentalFeatures: 'Experimentell',
    leaveActionTitle: 'Campus verlassen',
    leaveActionMessage:
        'Um Campus zu verlassen, schließen Sie einfach die Tab in Ihrem Browser. Wenn Sie die Seite von Campus erneut öffnen, kehren Sie direkt zu Ihrem Büro zurück.',
    logOutFromSystem: 'Hier klicken',
    logOutFromSystemTwo: 'um sich abzumelden.',

    // Identity Panel
    appearenceModification: 'Profilansicht',
    identityPanelTitle: 'Meine Einstellungen',
    statusTabTitle: 'Status',
    profileTabTitle: 'Profil',
    preferencesTabTitle: 'Voreinstellungen',
    switchOrgActionTitle: 'Ort wechseln',
    updateImageActionTitle: 'Bild aktualisieren',
    changeEmailActionTitle: 'Email ändern',
    cameraPreferenceActionTitle: 'Kamera beim Betreten eines Raums deaktivieren?',
    microphonePreferenceActionTitle: 'Mikrofon beim Betreten eines Raums deaktivieren?',
    cameraSettingErrorMessage:
        'Bei der Einstellung Ihrer Geräteeinstellungen ist ein Fehler aufgetreten. Bitte warten Sie einige Zeit und versuchen Sie es erneut. Wenn der Fehler erneut auftritt, wenden Sie sich an den IT-Support.',
    languageSelectionLabel: 'Sprache auswählen',
    languageSelectionPlaceholder: 'Sprache für Textanzeigen wählen',
    languageSelectionActionTitle: 'Ihre präferierte Sprache',
    languageSelectionDescription: 'CAMPUS wird in englischer Sprache angezeigt. In Ordnung?',
    logoutTemporaryButton: 'Hier geht es zum Logout',
    communicationSettings: 'Kommunikationseinstellungen',

    // ProfileImageUploader page
    profileImageUploaderTitle: 'Bild hochladen...',
    //check
    profileImageUploaderDescription: 'Datei hierher ziehen (Drag&Drop) klicken, um eine Datei auszuwählen.',
    profileImageUploaderDescription02: 'Die Datei darf nicht größer als 2 MB sein.',
    profileImageUploaderUploadCapError: 'Die Größe des Bildes, das Sie hochladen möchten, ist größer als 2 Megabytes',

    // Help
    resetTutorialsActionTitle: 'Tutorial erneut starten',
    helpCentreActionTitle: 'Hilfe-Seiten',
    reportErrorActionTitle: 'Fehler melden',
    dialogBoxTitle: 'Tutorials zurücksetzen',
    dialogBoxDescription: 'Bitte diese Seite neu laden, um die Tutorials zu starten!',

    // General
    someone: 'Jemand',
    dismissActionTitle: 'Ablehnen',
    saveActionTitle: 'Speichern',
    saveChangesActionTitle: 'Änderungen speichern',
    savingActionLoadingTitle: 'Speichern...',
    savingActionSuccessTitle: 'Gespeichert!',
    changesSavedActionTitle: 'Sprachpräferenz gespeichert',
    confirmActionTitle: 'Bestätigen',
    cancelActionTitle: 'Abbrechen',
    closeActionTitle: 'Schließen',
    refreshPageActionTitle: 'Seite aktualisieren',
    backgroundImageTitle: 'Hintergrundbild',
    //check
    typeTitle: 'Gäste-Zutritt zum CAMPUS',
    titleTitle: 'Titel',
    calloutTitle: 'Der Titel wird beim Gästeempfang angezeigt',
    minimumCharacterLimitForTitle: 'Die Mindestanzahl der Zeichen beträgt 2',
    generalTitle: 'Allgemein',
    accessTitle: 'Zutritt',
    editActionTitle: 'Bearbeiten',
    deleteActionTitle: 'Löschen',
    clearActionTitle: 'Entfernen',
    submitActionTitle: 'Senden',
    meetActionTitle: 'Abholen',
    finishActionTitle: 'Fertig',
    okActionTitle: 'Ok',
    clearFilterActionTitle: 'Filter zurücksetzen',
    reconnectToCampusActionTitle: 'Verbindung wiederherstellen',
    sendActionTitle: 'Senden',
    skipActionTitle: 'Überspringen',
    skipActionTitleInCap: 'ÜBERSPRINGEN',
    startButtonLabelInCap: 'START',
    playActionTitle: 'Abspielen',
    pauseActionTitle: 'Pause',
    downloadMicrosoftEdgeActionTitle: 'Microsoft Edge herunterladen',
    enterActionTitle: 'Eintreten',
    talkingToMicrosoftActionTitle: 'Mit Microsoft Identity Platform austauschen...',
    loadingTitle: 'Laden...',
    authenticatingTitle: 'Authentifizierung...',
    connectingTitle: 'Verbinden...',
    loggedInto: 'Angemeldet bei',
    yesAction: 'Ja',
    noAction: 'Nein',
    connectionLost: 'Verbindung unterbrochen',
    clientConnectionLostDescription1: 'Die Verbindung zum Server wurde unterbrochen.',
    clientConnectionLostDescription2: 'Dies kann verschiedene Gründe haben. Bitte u.a. WLAN prüfen.',
    mobileActionTitle: 'Mobil',
    welcomeBack: 'Willkommen zurück!',
    goodToSeeYouAgain: 'Schön, Sie wiederzusehen.',
    loadingText: 'Authentifizierung mit der ivicos Zugangskontrolle...',
    licenseNotActive: 'Ihre Lizenz scheint nicht gültig zu sein.',
    errorAuthentication: 'Bei der Authentifizierung ist ein Fehler aufgetreten.',
    //check
    usersIn: 'Nutzer in ',
    editMessageActionTitle: 'Nachricht bearbeiten',
    previewActionTitle: 'Vorschau',
    addGifActionTitle: 'Gif-Grafik hinzufügen',
    removeActionTitle: 'Entfernen',
    back: 'Zurück',
    of: 'von',
    discard: 'Verwerfen',
    save: 'Speichern',
    cancel: 'Abbruch',
    removeImage: 'Bild entfernen',
    later: 'Später',
    writeUs: 'Schreiben Sie uns',
    talkToSales: 'Vertrieb kontaktieren',
    benefitsMissing: 'Entgehende Vorteile…',

    // visitor landing page
    landingPageEnterName: 'Bitte geben Sie hier Ihren Namen ein',
    landingPageEnterNameCallOutPart1: 'Bitte geben Sie Ihren Namen, einen Spitznamen oder Psydonym ein.',
    landingPageEnterNameCallOutPart2: 'Diese Information wird nicht in unserem System gespeichert und gelöscht, sobald Sie den CAMPUS verlassen.',
    landingPagePrivacyHint: 'Datenschutzhinweise: Fragezeichen anklicken.',
    landingPageGoIn: 'Eintreten',
    landingPageAudioCallOut: 'Diese Anzeige dient dem Test der lokalen Mikrofon-Freigabe. Es werden nirgendwohin Daten übertragen.',
    landingPageSetAudioDevices: 'Audiogeräte einstellen',
    landingPageRisk: 'Mit dem von Ihnen verwendeten Browser könnte die Bedienung etwas umständlicher sein.',
    landingPageWeRecommend: 'Wir empfehlen:',
    landingPageRiskCallOutPart1: 'Einige Browser weichen hin und wieder stärker von Standard ab,so dass sie manchmal zu Inkompatibilitäten führen.',
    landingPageRiskCallOutPart2: 'Aus diesem Grund können wir derzeit die Verwendung von Safari oder Firefox nicht empfehlen, wenngleich sie möglich.',

    // waitingArea
    waitingAreaHelloVisitor: 'Hallo',
    waitingAreaWaitBriefly: 'Bitte warten Sie kurz:',
    waitingAreaHostInformed: 'Ihr Gastgeber wurde soeben über Ihre Ankunft informiert.',
    waitingAreaNoOneGreet: 'Es scheint niemand gekommen zu sein, um Sie zu begrüßen:',
    waitingAreaClickAgain: 'Bitte klicken Sie auf die Schaltfläche mit der Glocke unten, um eine weitere Benachrichtigung über Ihre Anwesenheit zu senden.',
    waitingAreaNotifyHost: 'Host benachrichtigen',

    // invalid invitation link
    insertedLinkIncorrect: 'Der eingefügte Link scheint falsch oder unvollständig zu sein!',
    verifyAccuracyOfInvitationLink: 'Bitte überprüfen Sie die Vollständigkeit des Links und kopieren Sie ihn erneut in einen Browser-Tab.',
    invalidInvitationProblemPersists: 'Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unseren ',
    supportServiceHere: 'Support',

    // Campus Settings
    changeColorActionTitle: 'Farbe anpassen',
    changeLogoActionTitle: 'Logo ändern',
    changeImageActionTitle: 'Bild auswählen',
    changeImageCalloutTitle: 'Dieses Bild wird im Hintergrund Ihres Besuchereingangs erscheinen. Die Dateigröße muss bis zu 2 MB betragen.',
    visitorEntranceYTTitle: 'Video für das Wartezimmer',
    instructionToSetVideoAtVisitorEntrance:
        'Dieses Video wird Gästen zur Ansicht angeboten, wenn Sie im Gästeempfang auf einen Abholer warten. Das Video ersetzt das in den CAMPUS-Einstellungen als Standard festgelegte Video.',
    pasteYTVideo: 'Hier einen YouTube-Videolink einfügen',
    changeYTVideo: 'Video ändern',
    selectYTVideo: 'Video auswählen',
    changeColorCalloutTitle: 'Wählen Sie hier Ihre Unternehmens-Farbe die u.a. in der Kopfzeile Ihres CAMPUS dargestellt wird.',
    changeLogoCalloutTitle:
        'Das Logo erscheint in Ihrer Kopfzeile und in Ihrem Wartebereich auf Ihren CAMPUS. Die Größe sollte 250 x 100 Pixel betragen. Sie kann jedoch je nach Logo-Format variieren.',
    //check
    ownershipTitle: 'CAMPUS Eigentümer',
    transferOwnershipActionTitle: 'Rolle des Eigentümers übertragen',
    transferActionTitle: 'Übertragen',
    transferOwnershipError:
        'Bei der Übertragung der Eigentümerschaft ist ein Fehler aufgetreten. Bitte versuchen Sie, die Eigentumsübertragung erneut vorzunehmen, und wenden Sie sich an den Support, wenn der Fehler erneut auftritt.',
    transferOwnerShipDescriptionTitle:
        'Nach der Übertragung der Eigentümerschaft sind Sie nicht mehr der Eigentümer dieses Campus. Bitte bedenken Sie dies vorab!',
    currentManagersListTitle: 'Liste der derzeitigen CAMPUS-Manager',
    searchManagersPlaceholder: 'Suche CAMPUS-Manager',
    managerDetailsListTitle: 'Details zu den Managern',
    transferOwnershipWarningTitle1: 'Wenn Sie auf Bestätigen klicken, ändern sich die Eigentumsrechte auf ',
    transferOwnershipWarningTitle2: ' und Sie werden zum CAMPUS-Manager.',
    brandIdentityTitle: 'Markenidentität',
    campusNameTitle: 'CAMPUS Name',
    campusColorTitle: 'Farbe',
    campusLogoTitle: 'Logo',
    saveCampusSettingsDescription: 'Klicken Sie auf "Bestätigen", um die von Ihnen vorgenommenen Änderungen zu speichern.',
    accessMethodTitle: 'Alternative Nutzeranmeldung',
    switchOnDescription: 'Schalten Sie das Single-Sign-On von Microsoft 365 für alle CAMPUS-Mitglieder ein.',
    switchOffDescription: 'Alle Mitarbeiter Ihres Microsoft-Tenant werden so bei der Anmeldung gebeten, diesem Campus beizutreten.',
    accessMethodCallout1: 'Sie können Ihren CAMPUS mit Ihrer Microsoft-Tenant verbinden.',
    accessMethodCallout2: 'Auf diese Weise wird jeder in Ihrer Microsoft-Organisation bei der Anmeldung aufgefordert, diesem CAMPUS beizutreten.',
    confirmDescription: 'Sind Sie sicher, dass Sie für Ihre Organisation eine Microsoft-Single-Sign-On freischalten wollen?',
    OwnershipTransferredActionTitle: 'Eigentümerschaft übertragen',
    signOutAndSignInError: 'Bitte melden Sie sich vom CAMPUS ab und dann erneut an, damit die Änderungen wirksam und sichtbar werden.',
    errorOccurred: 'Es ist ein Fehler aufgetreten',
    removeOrganizationButton: 'Organisation entfernen',
    removeOrganizationChoiceAccept: 'Ja, ich bin mir sicher',
    removeOrganizationChoiceRefuse: 'Nein, ich möchte es nicht entfernen',
    removeOrganizationQuestion: 'Sind Sie sicher, dass Sie [org] entfernen möchten?',
    removeOrganizationExplanation:
        'Dies bedeutet, dass alle Mitglieder, Bereiche, Räume und Dokumente, die auf dem Campus dieser Organisation erstellt wurden, verloren gehen.',
    removeOrganizationModalTitle: 'Organisation entfernen',
    isSavingChanges: 'Änderungen werden gespeichert...',

    // Auth
    loginPromptTitle: 'Bitte authentifizieren Sie sich!',
    loginPromptDescription: 'Melden Sie sich mit Ihrem Microsoft-Zugangsdaten an, die Sie von Ihrem Unternehmen erhalten haben...',

    // Notifications
    meetNotificationActionTitle: 'Sich treffen',
    inviteNotificationActionTitle: 'Einladen',
    acceptNotificationActionTitle: 'Annehmen',
    answerNotificationTitle: 'Antworten',
    //check
    doorKnockNotificationBody: 'Wollen Sie die Person herein lassen?',
    doorKnockConfirmationNotificationBody: 'Alle im Raum Anwesenden wurden informiert!',
    memoSentNotificationBody: 'Die Person wurde benachrichtigt',
    noUsersWarning: 'Es ist niemand da, der benachrichtigt werden könnte.',
    showDetailsActionTitle: 'Details anzeigen',
    meetThemActionTitle: 'Sich treffen',
    visitorIsWaitingNotificationTitle: 'ist an der Tür!',
    visitorIsWaitingNotificationBody: 'Wollen Sie die Person treffen?',
    visitorFor: 'Für: ',
    visitorHost: 'Gastgeber: ',
    visitorIsWith: ' ist im Besucherempfang bei ',
    visitorWithOthers: ' und andere sind mit ',
    memoSentToNotificationActionTitle: 'Nachricht gesendet an ',
    somethingWentWrongNotificationActionTitle: 'Etwas ist schief gelaufen',
    invitationSentNotificationActionTitle: 'Einladung abgeschickt!',
    inputSupportsMarkdownTitle: 'Dieses Feld unterstützt die sog. Markdown-Schreibweise (klicken Sie, um mehr zu erfahren)',
    inviteVisitorNotificationBody: 'Wollen Sie den Besucher empfangen?',
    youHaveTheGuestMessage: 'Ein Gast befindet sich im Wartebereich!',
    successMessage: 'Erfolgreich!',
    inviteYouToMessage: 'lädt Sie in den/die',
    inviteYouToMessage1: 'lädt Sie in ihr/sein persönliches Büro ein',
    invitedYouToMessage: 'lud Sie in den/die',
    invitedYouToMessage1: 'lud Sie in ihr/sein persönliches Büro ein',
    inviteYouToMessage2: 'ein',
    saysMessage: 'sagt:',
    saidMessage: 'sagte:',
    isAtYourDoorMessage: 'ist an der Tür',
    wasAtYourDoorMessage: 'war an der Tür',
    someoneMessage: 'Jemand',
    replyActionTitle: 'Antworten',
    inviteThemInActionTitle: 'Hereinlassen',
    acceptInvitationActionTitle: 'Einladung annehmen',
    userAlreadyExists: 'Benutzer bereits vorhanden',
    sendMemoToActionTitle: 'Nachricht senden an',

    // Grouped Notifications
    closeAllMemosAndInvitations: 'Alle Memos und Einladungen schließen',
    closeAllMemos: 'Alle Memos schließen',
    closeAllInvitations: 'Alle Einladungen schließen',
    closeAllVisitorNotifications: 'Alle Besucher Benachrichtigungen schließen',
    closeAllMessages: 'Alle Nachrichten schließen',
    closeAllMessageDialogSubText1: 'Wenn Sie dieses Fenster schließen, verlieren Sie alle ihre ',
    onlyMemos: 'Memos',
    onlyInvitations: 'Einladungen',
    memosAndInvitations: 'Memos und Einladungen',
    onlyVisitorNotifications: 'Besucher Benachrichtigungen',
    onlyMessages: 'Nachrichten',
    closeAllMessageDialogSubText2: 'Sind Sie sicher?',
    closeAllMessageDialogNo: 'Nein',
    closeAllMessageDialogYes: 'Ja',
    seekingAttention: 'bittet um ihre Aufmerksamkeit!',
    wasSeekingAttention: 'suchte Ihre Aufmerksamkeit!',
    thereAreVisitors: 'Jemand ist am Besucherempfang!',
    youHave: 'Sie haben ',
    unreadMemos: 'ungelesene Nachricht(en) ',
    notificationDescriptionAnd: 'und ',
    ignoredInvitations: ' ignorierte Einladung(en).',
    visitorsWaiting: ' Besucher, die auf Sie warten.',
    viewAll: 'Alle anzeigen',
    hideAll: 'Alle ausblenden',
    closeAll: 'Alle schließen',

    //User Actions
    inviteUserToRoomActionTitle: 'In Ihr Zimmer einladen',
    sendUserMemoActionTitle: 'Nachricht senden',
    kickOutVisitorActionTitle: 'Besucher aus dem CAMPUS entfernen',

    // Sidebar
    personalRoomsSectionTitle: 'Persönliche Büroräume',
    commonRoomsSectionTitle: 'Räume',
    commonRoomsSectionEmptyNoticeTitle: 'Keine Zimmer in diesem Bereich',
    commonRoomsSectionEmptyNotice: 'Sie können unten Zimmer hinzufügen.',

    // Member Invites
    inviteMemberLabelTitle: 'zu dieser Organisation',
    inviteAnyoneLabelTitle: 'von ausserhalb dieser Organisation',
    inviteMemberLabel: 'Personen hinzufügen',
    inviteMemberPlaceholder: 'E-Mail-Adresse',
    inviteMemberErrorMessage: 'Ungültige E-Mail Adresse',
    InviteRoleErrorMessage: ' ist nicht gültig, bitte wählen Sie einen Mitglied oder Manager',
    inviteLanguageErrorMessage: ' ist nicht gültig, bitte wählen Sie English oder Deutsch',
    inviteMemberDescription1:
        'Dies ist eine Mitglieder-Einladung für eine einzelne Person. Nach dem Versenden der Einladung wird die Person per E-Mail benachrichtigt und sie erscheint in der Mitgliederliste.',
    inviteMemberDescription2:
        'Verwenden Sie eine .csv-Datei, um mehrere Mitglieder gleichzeitig einzuladen. Nach dem Versenden der Einladung werden die Personen per E-Mail benachrichtigt und sie erscheinen in der Mitgliederliste.',
    ImportCSVFileTitle1: 'CSV-Datei importieren',
    ImportCSVFileTitle2: 'Einladungen überprüfen',
    ImportCSVFileTitle3: 'Einladungsprozess initiiert...',
    ImportCSVFilePlaceholder: 'Ziehen Sie eine .csv-Datei auf diese Feld (Drag&Drop), oder klicken Sie auf das Feld hier, um eine Datei auszuwählen.',
    downloadCSVTemplateFileLabel: 'Eine .CSV-Vorlagendatei zum Ausfüllen herunterladen',
    changeFileActionTitle: 'Datei ändern',
    inviteThesePeopleActionTitle: 'Diese Personen einladen',
    inviteThesePeopleConfirmDescription: 'Sind Sie sicher, dass Sie diese Personen einladen wollen?',
    emailTitleLabel: 'E-Mail Adresse',
    emailTitlePlaceholder: 'Geben Sie eine Arbeits-E-Mail-Adresse ein',
    roleTitleLabel: 'Rolle',
    languageTitleLabel: 'Sprache',
    rolePlaceHolder: 'Wählen Sie eine Rolle',
    inviteSingleUserTitle: 'Ein einzelnes Mitglied hinzufügen',
    inviteMultipleUserTitle: 'Mehrere Mitglieder hinzufügen',
    bulkInviteDescription1: 'Die Einladung von mehreren Mitgliedern gleichzeitig funktioniert mithilfe einer ',
    bulkInviteDescription2:
        ' Datei, die alle E-Mail-Adressen der einzuladenden Personen listet. Die neu eingeladenen Personen erscheinen dann in der Mitgliederliste.',
    sendInviteButtonTitle: 'Einladung versenden',
    sendInvitesButtonTitle: 'Einladungen versenden',
    openBulkInviteButtonTitle: 'Mehrere Personen hinzufügen',
    confirmBulkInviteQuestion1: 'Sind Sie sicher, dass Sie diese Mitglieder-Einladungen versenden möchten?',
    confirmBulkInviteQuestion2: 'Sind Sie sicher, dass Sie diese Mitglieder-Einladung versenden möchten?',
    bulkInviteStep1Title: 'Schritt 1:',
    bulkInviteStep2Title: 'Schritt 2:',
    bulkInviteStep1Description:
        'Laden Sie bitte diese Datei/Vorlage auf Ihren Computer und listen Sie die E-Mail-Adressen von Personen, die Mitglieder Ihres CAMPUS werden sollen.',
    bulkInviteStep2Description: 'Um gleichzeitig mehrere Mitglieder hinzuzufügen, bitte unten die von Ihnen ausgefüllte Datei hochladen.',
    invitationAlreadySent: 'Einladung bereits versandt',
    successfullyInvited: 'Erfolgreich eingeladen!',
    inactiveAccountInvited: 'Inactive account',
    invitedSponsorTooltipYourList: 'Diese Person ist in Ihrer Rechnungsliste enthalten',
    invitedSponsorTooltipOtherList: 'Diese Person steht auf einer anderen Rechnungsliste',

    // Invitations
    createInvitationTitle: 'Zugangsberechtigung',
    createInvitationButtonTitle: 'Zugangsberechtigung erstellen',
    inviteMemberActionTitle: 'Neues Mitglied einladen',
    bulkInviteActionTitle: 'Mehrere Mitglieder einladen',
    resendInvitationActionTitle: 'Einladung erneut versenden?',
    resendInvitationActionDescription: 'Sind Sie sicher, dass Sie die Einladung erneut an folgende Adresse senden möchten ',
    reviewInvitationTitle: 'Review invitations',
    reviewInvitationDescription1: 'One or more people could not be invited as they have no active account.',
    reviewInvitationDescription2: 'However, their invitations can be reworked at any time by filtering the table by Inactive.',
    reviewInvitationClose: 'Close',
    deleteInvitationActionTitle: 'Einladung löschen',
    deleteInvitationActionTitle2: 'Zugangsberechtigung löschen',
    deleteInvitationActionDescription: 'Sind Sie sicher, dass Sie diesen Benutzer keinen Zugang mehr zum CAMPUS ermöglichen möchten?',
    invitationsManagerTitle: 'Besucher-Einladungsmanagement',
    invitationsTitle: 'Einladungen',
    waitingVisitorsTitle: 'Gäste im Empfangsraum',
    filterInvitationsPlaceholder: 'Einladungen filtern',
    upcomingInvitationsTitle: 'Anstehende Gästeeinladungen',
    recurringInvitationsTitle: 'Sich wiederholende Gästeeinladungen',
    showExpiredInvitationsActionTitle: 'Auch abgelaufene Einladungen anzeigen',
    noVisitorsDescriptionTitle: 'Derzeit gibt es keine Gäste auf dem CAMPUS',
    areaTitle: 'Bereich',
    roomTitle: 'Raum',
    hostsTitle: 'Benachrichtigen',
    welcomeMessageTitle: 'Begrüßungsnachricht',
    missingWelcomeMessageTitle: 'Keine Begrüßungsnachricht eingegeben.',
    validityTitle: 'Gültigkeit',
    validTitle: 'Gültig',
    validFromTitle: 'gültig von',
    onTitle: 'On',
    selectedHostTitle: 'Ausgewählte Gastgeber',
    hostSelectorDescription: 'Diese Gastgeber werden dann über die Ankunft eines Besuchers benachrichtigt.',
    hostSelectorPlaceholder: 'Tippen Sie hier, um Gastgeber auszuwählen',
    invitationValidityCallout:
        'Einmalige Einladungen sind für das unten angegebene Datum gültig, während wiederkehrende Einladungen in einer bestimmten Häufigkeit wiederholt werden.',
    invitationTypeCallout:
        'Im Falle eines direkten Zugangs können Besucher nach der Namenseingabe direkt einen definierten Raum betreten, während sie im Falle einer Abholung zunächst im Wartebereich vom Gastgeber abgeholt werden müssen.',
    invitationDeleteConfirm: 'Sind Sie sicher, dass Sie diesen Einladungslink löschen möchten?',
    eventTypeSingle: 'Einmalig',
    eventTypeRecurring: 'Wiederkehrend',
    InvitationTypeDirect: 'Direkter Zutritt eines Bereichs',
    InvitationTypePickup: 'Abholung durch einen Gastgeber',
    RecurringTypeEveryDay: 'Jeden Tag',
    RecurringTypeEveryWeek: 'Jede Woche',
    invitationDeleteConfirm2: 'Sind Sie sicher, dass Sie Folgendes löschen möchten',
    copyFailedActionTitle: 'Kopieren fehlgeschlagen!',
    copyActionTitle: 'Link kopieren',
    copiedActionTitle: 'Link kopiert!',
    manageAccessTitles: 'Zugang verwalten',
    msEnabledTitle: 'MS Single-Sign-On: eingeschaltet',
    suggestionsHeaderText: 'Vorgeschlagene Personen',
    mostRecentlyUsedHeaderText: 'Vorgeschlagene Kontakte',
    noResultsFoundText: 'Kein Ergebnis gefunden',
    loadingTitleText: 'Applikation laden',
    suggestionsAvailableAlertText: 'Personenauswahl - Vorschläge verfügbar',
    suggestionsContainerAriaLabel: 'Vorgeschlagene Kontakte',
    invitationSendButtonText: 'Erstellen',
    invitationEmailSubject: 'Anbei erhalten Sie von {orgName} eine Zugangsberechtigung zum Campus',
    invitationEmailBody: `Titel: {invName}%0D%0A
    %0D%0AGültigkeit: {frequency} von {validFromTime} bis {validToTime}%0D%0A
    %0D%0AZutrittslink: {invitationLink}%0D%0A
    %0D%0A
    %0D%0A===================================================%0D%0A
    %0D%0A
    %0D%0ALernen Sie ivCAMPUS kennen: https://ivicos.eu%0D%0A
    `,
    createAndContinue: 'Erstellen und weiter',
    updateInviationTitle: 'Ihre Zugangsberechtigung ist fertig und sieht so aus:',
    editCustomUrlTitle: 'Link-Vorschau',
    editCustomUrl: 'Endung anpassen',
    customUrlCallout:
        'Hier kann die Endung der Zugangsberechtigungslink nach Belieben geändert werden. Beilspielsweise kann ein Titel einer Veranstaltung oder der Name einer einzuladenden Person genutzt werden. Bitte verwenden Sie keine Sonderzeichen.',
    calendarIntegrationTitle: 'Sie können auch zwischen den folgenden Möglichkeiten wählen:',
    calendarIntegrationButton: 'Zum Kalender hinzufügen und/oder .ics-Datei herunterladen',
    invitationToEmailButton: 'An E-Mail Adresse(n) senden',
    downloadInvitation: '.ics-Datei herunterladen ',

    // Custom Url Modal
    customUrlModalTitle: 'Einladungslink individualisieren',
    customUrlModalDescription1:
        'Hier kann die Endung des Einladungslinks nach Belieben geändert werden. Beilspielsweise kann ein Titel einer Veranstaltung oder der Name einer einzuladenden Person genutzt werden. Beim Schließen der Seite oder Klick auf "Verwerfen" wird die Einladung mit einer zufällig generierten Endung versehen.',
    customUrlModalDescription2: 'Bitte keine Sonderzeichen verwenden.',
    customUrlModalErrorNoSpecialCharacters: 'Sonderzeichen können nicht verwendet werden!',
    customUrlModalErrorMaximumLimit: 'Die maximale Zeichenanzahl beträgt 50!',
    customUrlModalErrorMinimumLimit: 'Die Mindestanzahl der Zeichen beträgt 1!',
    customUrlModalErrorCustomUrlExists: 'Dieser Einladungslink existiert bereits!',
    customUrlModalLinkAppearance: 'Vorschau des Einladungslinks:',
    customUrlModalDiscard: 'Verwerfen',
    customUrlModalSaveAndCopy: 'Speichern und kopieren',

    // Customized video url
    customizedVideoLabel: 'Standardvideo für das Wartezimmer verwenden',
    customizedVideoCallOutPart1: 'Dieses Video wird Gästen zur Ansicht angeboten, wenn Sie im Gästeempfang auf einen Abholer warten.',
    customizedVideoCallOutPart2: 'Das Video ersetzt das in den CAMPUS-Einstellungen als Standard festgelegte Video.',
    customizedVideoOn: 'An',
    customizedVideoOff: 'Aus',
    customizedVideoPasteLink: 'YouTube Link einfügen',

    // Visitors
    visitorTabTitle: 'Gäste',
    visitorEntranceTitle: 'Gästeempfang',
    farewellMessageTitle: 'Nachricht zur Verabschiedung',
    farewellMessageCallout:
        'Alle Besucher eines CAMPUS können mit einem einfachen Klick aus dem CAMPUS entfernt werden. Hierzu einfach die Maus über den entsprechenden Besucherausweis bewegen. Entsprechenden Nutzern wird daraufhin eine hier festgelegte Verabschiedungsnachricht angezeigt.',
    visitorLinkTitle: 'Ihr Einladungslink',
    visitorLinkDescription: 'Teilen Sie diesen Link, um andere zu diesem Bereich einzuladen. Sie werden benachrichtigt, sobald ein Besucher eintrifft.',
    copyButtonLabel: 'Einladungslink in die Zwischenablage kopieren',
    copyButtonSuccessLabel: 'Link kopiert!',
    copyButtonErrorLabel: 'Fehler',
    letVisitorInsideActionTitle: 'Gewähren Sie Zutritt',
    letVisitorInActionTitle: 'Gewähren Sie Zutritt',
    meetVisitorActionTitle: 'Treffen',
    dialogYourInvitationSent: 'Ihre Einladung wurde versandt! Hinweis: Es kann bis zu 5 Minuten dauern, bis der Empfänger sie erhält.',
    dialogPersonNotAddedToBillingList1: 'Das Konto der Person, die Sie eingeladen haben, ist aktiviert, so dass es nicht in die Liste der ',
    dialogPersonNotAddedToBillingList2: '-Rechnung aufgenommen wird.',
    dialogPersonDoesNotHaveActiveAccountTitle: 'Inaktives Konto',
    dialogPersonDoesNotHaveActiveAccount1: 'Die Person, die Sie einladen möchten, hat kein aktives Benutzerkonto.',
    dialogPersonDoesNotHaveActiveAccount2: 'Möchten Sie den Nutzer zu Ihrer Organisation hinzufügen?',
    dialogWeDidNotSendInvitationTitle: 'Einladung wurde nicht verschickt!',
    dialogPersonDoesNotHaveActiveAccount3: 'Wir haben keine Einladung versendet, da der Nutzer noch nicht auf dem ivCAMPUS registriert ist.',
    dialogPersonDoesNotHaveActiveAccount4:
        'Bitten Sie diese Person, der nicht Ihrer Organisation zugeordnet werden soll, dass er für seine Organisation einen eigenen CAMPUS anlegt. Sobald er auf der Plattform registriert ist, kann er auf Ihren Campus eingeladen werden.',
    dialogPersonDoesNotHaveActiveAccountNo: 'Nein',
    dialogPersonDoesNotHaveActiveAccountYes: 'Ja',
    dialogIsAlreadyAMember: ' ist bereits ein Mitglied',
    notActiveMessageDaily: 'Ihre Einladung mit dem Titel {eventName} ist täglich gültig von {timeFrom} bis {timeTo}.',
    notActiveMessageWeekly: 'Ihre Einladung mit dem Titel {eventName} ist wöchentlich gültig von {dayFrom} um {timeFrom} bis {dayTo} um {timeTo}.',
    notActiveMessageSingle: 'Ihre Einladung mit dem Titel {eventName} ist gültig von {completeDateFrom} Uhr bis {completeDateTo} Uhr.',
    youAreTalkingTo: 'Sie sprechen mit',
    directVisitorToRoom: 'Gastzugang direkt zum Raum',
    thanksForVisitMessage: 'Danke für Ihren Besuch!',
    VisitorExitDescription1: 'Ihr Besuch wurde ermöglicht durch ivicos CAMPUS.',
    VisitorExitDescription2: 'Erfahren Sie mehr auf',
    VisitorExitDescription3: 'ivicos.eu',
    invitationLinkNotValid: 'Derzeit ist der Besuchereingang geschlossen. Deshalb führt sie ihr Link derzeit nicht weiter.',
    errorInButton: 'Fehler',
    backToAreaButton: 'Zurück',

    // Area Actions
    addAreaActionTitle: 'Bereich hinzufügen',
    editAreaActionTitle: 'Bereich bearbeiten',
    deleteAreaActionTitle: 'Bereich löschen',
    searchAreasPlaceholder: 'Bereich suchen',
    createAreaTitle: 'Neuen Bereich erstellen',
    enterAreaNameLabel: 'Bereichsname',
    enterAreaNamePlaceholder: 'Neuen Bereichsnamen eingeben',
    deleteAreaDescription: 'Sind Sie sicher, dass Sie Folgendes löschen möchten:',
    areaLeaveConfirmDescription: 'Sind Sie sicher, dass Sie diesen Bereich verlassen wollen?',
    panelTitle: 'Navigator',
    showMoreOptionsTooltip: 'Weitere Optionen anzeigen',

    // Area Actions / Search Person
    crossAreaSearchPersonIconTitle: 'Person finden',
    crossAreaSearchPersonPanelHeaderTitle: 'Person finden',
    crossAreaSearchPersonPanelCloseButton: 'Schließen',
    crossAreaSearchPersonSearchBarPlaceholder: 'Suchen',
    crossAreaSearchPersonThisArea: 'diesem Bereich',
    crossAreaSearchPersonOtherArea: 'einem anderen Bereich (dieses Campus)',
    crossAreaSearchPersonDashboard: 'Lobby',
    crossAreaSearchPersonNotFound: 'Es wurden keine Ergebnisse zum eingegebenen Suchbegriff gefunden.',

    // Room Actions
    enterRoomActionTitle: 'Raum betreten',
    knockOnDoorActionTitle: 'Anklopfen',
    editRoomActionTitle: 'Raum einrichten',
    sendMemoIntoRoomActionTitle: 'Nachricht in den Raum senden',
    roomSettingsActionTitle: 'Raum einrichten',
    createNewRoomActionTitle: 'Neuen Raum erstellen',
    createNewRoomActionLoadingTitle: 'Ein neuer Raum wird erstellt...',
    deleteRoomActionTitle: 'Raum archivieren',
    deleteRoomActionLoadingTitle: 'Archivieren...',
    deleteRoomActionConfirmationTitle: 'Raum ist archiviert.',
    NoRoomAvailableDescription: 'In diesem Bereich ist kein Raum verfügbar',
    memoPlaceholder: 'Geben Sie Ihre Nachricht ein...',
    mobileDescription1: 'Scannen Sie diesen Code mit Ihrem Handy',
    mobileDescription2: 'Um den Raum zu betreten',

    // Room settings
    roomSettingsPanelTitle: 'Raum einrichten',
    roomNameInputLabel: 'Raumname',
    roomNameInputPlaceholder: 'Raum ohne Namen',
    iconPickerLabel: 'Raum-Symbol',
    privacySectionLabel: 'Datenschutz',
    openToVisitorsCheckBoxLabel: 'Für externe Besucher geöffnet',
    openToVisitorsCheckBoxDescription:
        'Besucher, die von CAMPUS-Mitgliedern eingeladen werden, können diesen Raum betreten. Dieser Raum wird mit diesem Symbol gesondert gekennzeichnet. Jedoch nur, wenn Besucher auf dem Campus sind.',
    confirmDeletePromptLabel: 'Sind Sie sicher, dass Sie diesen Raum aus dem CAMPUS entfernen möchten?',
    audioOnlyCheckBoxLabel: 'Video in diesem Raum deaktivieren',
    audioOnlyCheckBoxDescription: 'Gespräche Sind in diesem Raum nur ohne Videobild und nur auf der Tonspur möglich, wenn diese Option aktiviert ist',
    whitelistSelectorLabel: 'Raum-Zugangsberechtigung',
    whitelistSelectorPlaceholder: 'Tippen, um berechtigte Personen auszuwählen...',
    whitelistSelectorDescription: '',
    toggleWhitelistLabel: 'Zutritt nur für ausgewählte Benutzer zulassen',
    toggleWhitelistDescription: '',
    dismissAction: 'aufheben',
    deleteAction: 'löschen',
    noneAction: 'keine',
    deletionAction: 'löschen',
    updateAction: 'aktualisieren',
    deleteQuestion1: 'Sind Sie sicher?',
    deleteQuestion2: 'Nicht gespeicherte Änderungen verwerfen?',
    deleteQuestion3: 'Archivierte Räume können von keinem CAMPUS-Mitglied mehr betreten werden...',
    deleteQuestion4: 'Sie haben Änderungen an diesem Raum vorgenommen, die noch nicht gespeichert wurden... Wollen Sie diese verwerfen?',
    setTopicTitle: 'Thema festhalten',
    setATopicDialogBoxTitle: 'Beschreibe das Thema ',

    //Call view
    deviceSettingsActionTitle: 'Geräteeinstellungen',
    toggleVideoActionTitle: 'Kamera umschalten',
    toggleAudioActionTitle: 'Mikrofon umschalten',
    toggleAttachment: 'Dateien ein-/ausblenden',
    safariNotSupported: 'Diese Funktion wird von Safari derzeit nicht unterstützt.',
    toggleTileViewActionTitle: 'Videobilder-Anordnung ändern',
    shareScreenActionTitle: 'Bildschirm teilen',
    shareScreenActionActiveTitle: 'Bildschirm nicht mehr teilen',
    toggleEncryptionActionTitle: 'Verschlüsselung umschalten (experimentell)',
    toggleEncryptionActionActiveTitle: 'Die Verschlüsselung ist aktiviert (experimentell)',
    raiseHandActionTitle: 'Hand heben',
    unraiseHandActionTitle: 'Tippen Sie auf "Fertig", um Ihre Hand zu senken.',
    unraiseHandActionButtonTitle: 'Fertig',
    toggleBackgroundDialogActionTitle: 'Meinen Videohintergrund festlegen',
    leaveRoomActionTitle: 'Zurück in den persönlichen Raum wechseln',
    toggleStatsActionTitle: 'Virtuellen Moderator ein-/ausblenden',
    encryptionIsEnabledLabel: 'Ende-zu-Ende-verschlüsselt',
    connectedToLabel1: 'Sicher verbunden mit der',
    connectedToLabel2: 'CAMPUS-Plattform',
    connectedToLabel3: 'Powered by IONOS Cloud ',
    personalRoomTitle: 's persönliches Büro',
    personalRoomHelper: 's persönliches',
    personalRoomTitleForNameEndingWithS: 's persönliches Büro',
    unknownPersonalRoomTitle: 'Ein persönliches Büro',
    callLoadingScreenLabel: 'Die Verbindung zu einem sicheren Server in Europa wird hergestellt...',
    callLoadingScreenDescription: 'Bitte warten Sie kurz, während eine Verbindung zu einem unserer sicheren Server in Europa herstellt wird.',
    changeCallAudioAndVideo: 'Hier können Sie Ihre Audio- und Videoeinstellungen ändern.',
    callRetryMessage: 'Wenn dies länger dauert als erwartet, versuchen Sie es bitte erneut.',
    PleaseStandByMessage: 'Bitte warten Sie.',
    announcingArrival: 'Wir kündigen Ihre Ankunft an...',
    retryActionTitle: 'Wiederholen',
    safariRoomCallDescription:
        'Safari verbietet standardmäßig das Cross-Site-Tracking, indem es Cookies von Drittanbietern und Cross-Site-LocalStorage deaktiviert. Sie können also leider nicht auf das Etherpad zugreifen, es sei denn, Sie deaktivieren die Einstellungen manuell, indem Sie die unten genannten Schritte ausführen.',
    safariRoomCallDescriptionStep1:
        '1. Öffnen Sie die Safari-Einstellungen, indem Sie auf das Menü "Safari" auf der linken Seite der Menüleiste und dann auf "Einstellungen" klicken, oder nutzen Sie die Tastenkombination `⌘,`.',
    safariRoomCallDescriptionStep2: '2. Wählen Sie die Registerkarte `Datenschutz`..',
    safariRoomCallDescriptionStep3: '3. Deaktivieren Sie das Kontrollkästchen "Cross-Site-Tracking verhindern".',
    waitingAreaTitle: 'Besucherempfang',
    videoCallTitle: 'Videogespräch',
    setATopicActionTitle: 'Thema festhalten',
    alertMessageWhileScreenShareIsActive: 'Bildschirmteilung beenden, um in Ihren persönlichen Raum zurückzugehen',
    callActionButtonSelectBackground: 'Hintergrund auswählen',

    // Device settings
    audioSettingsTitle: 'Audio-Einstellungen',
    videoSettingsTitle: 'Video-Einstellungen',
    audioInputDeviceTitle: 'Audio-Eingabegerät',
    audioOutputDevice: 'Audio-Ausgabegerät',
    videoDeviceTitle: 'Video-Gerät',
    selectDevicePlaceholder: 'Gerät auswählen',

    // Portal
    availableAreasTitle: 'Bereiche',
    manageAccessTitle: 'Mitglieder',
    campusSettingsTitle: 'CAMPUS Konfiguration',
    billingTitle: 'Billing',
    addAreaToTabBarActionTitle: 'Bereich zum Schnellwechsel in der Kopfzeile darstellen',
    removeAreaFromTabBarActionTitle: 'Bereichsdarstellung aus der Kopfzeile entfernen',
    changeLogTitle: 'Neuigkeiten',
    areaOpenToEveryoneLabel: 'Für alle Mitglieder zugänglich',

    // Members
    filterMembersPlaceholder: 'Mitgliedernamen filtern',
    emailColumnTitle: 'E-Mail',
    nameColumnTitle: 'Name',
    roleColumnTitle: 'Rolle',
    pendingInviteTitle: 'Registrierung noch ausstehend...',

    // Roles
    userRoleTitle: 'Mitglied',
    managerRoleTitle: 'Manager',
    userRoleTitleInCap: 'Mitglied',
    managerRoleTitleInCap: 'Manager',
    ownerRoleTitle: 'Eigentümer',
    inviteeRoleTitle: 'Eingeladen',
    inactiveInviteeRoleTitle: 'Inactive',

    // Language
    englishLanguageTitle: 'English',
    deutschLanguageTitle: 'Deutsch',

    //Alerts
    generalAlertTitle: 'Achtung',
    webSocketAlert: 'Wir können Sie nicht mit dem Web-Socket verbinden. Bitte versuchen Sie es erneut.',
    connectionTimedOut: 'Ihre Verbindung wurde unterbrochen.',
    safariDetection: 'Leider unterstützt CAMPUS derzeit den Safari-Browser nicht :/ Wir empfehlen die Verwendung von Edge oder Chrome',
    mobileDetection: 'Leider unterstützt CAMPUS im Moment keine mobile Ansicht :/ Wir empfehlen die Verwendung eines Computers',
    invalidDataAlert: 'Ungültige Daten',
    yeetAlert: 'Neu laden',
    browserNotSupportAlert: 'Dieser Browser unterstützt leider keine Desktop-Benachrichtigungen.',
    errorConnectingAlert: 'Es gab einen Verbindungsfehler... drücken Sie bitte "Ok", um die Verbindung wiederherzustellen.',
    connectionLostMessage: 'Verbindung abgebrochen!',
    lostConnectionMessage: 'Die Verbindung zum Internet scheint unterbrochen zu sein.',
    clickToReconnectMessage: 'Bitte klicken Sie auf die Schaltfläche unten, nachdem die Verbindung wiederhergestellt ist.',
    firefoxAlert: 'Firefox-Bildschirmfreigabe: Bitte klicken Sie zunächst einmal in den Videorahmen unten, bevor Sie versuchen, Ihren Bildschirm zu teilen.',
    reconnectToMicrosoft: 'Wiederherstellung der Verbindung zu Microsoft-Diensten... ',
    trialPeriodExpiredTitle: 'Testphase abgelaufen',
    trialPeriodExpiredContent: 'Die Campus-Testphase von [org] ist beendet.',
    trialPeriodExpiredContactSalesTeam1: 'Ihr Campus kann nun nicht mehr betreten werden? Bitte kontaktieren Sie jetzt unser Team unter ',
    trialPeriodExpiredContactSalesTeam2: ' das Ihnen gerne wieder Zugang zu Ihren Räumen und Bereichen verschafft.',
    trialPeriodExpiredContactSalesTeam3: 'Alternativ können Sie auch auf die Schaltfläche unten klicken und es wird Ihre Standard-E-Mail-Anwendung geöffnet.',
    trialPeriodExpiredUserContent:
        'Die Campus-Testphase für [org] ist beendet. Für Ihre Organisation wurde noch keine weiter Vereinbarung abgeschlossen. Bitte wenden Sie sich an den Vertrieb. Man hilft Ihnen dort gerne weiter.',
    trialPeriodExpiredChoiceTitle: 'Wählen Sie eine Aktion',
    trialPeriodExpiredChoiceAccept: 'Ein Abonnement erstellen',
    trialPeriodExpiredChoiceRefuse: 'Campus entfernen und eine neue Testphase starten',
    makeSubscriptionTitle: 'Ein Abonnement erstellen',
    makeSubscriptionContent: 'Um ein Abonnement abzuschließen, benötigen wir einige zusätzliche Angaben:',
    subscriptionFieldCompanyName: 'Name der Organisation',
    subscriptionFieldPostalCode: 'Postleitzahl',
    subscriptionFieldAddress: 'Adresse',
    subscriptionFieldAddressPlaceholder: 'Straße und Hausnummer',
    subscriptionFieldCityPlaceholder: 'Stadt',
    subscriptionFieldCity: 'Stadt',
    subscriptionFieldCountryCode: 'Länderkennzeichen',
    subscriptionFieldVatNumber: 'USt-IdNr.',
    trialPeriodAboutToExpireTitle: 'Die Testphase wird bald enden!',
    trialPeriodAboutToExpireDaysLeft: 'Es verbleiben noch [count] Tage bis zum Ende der Testphase, danach wird [org] entfernt.',
    trialPeriodAboutToExpireQuestion: 'Möchten Sie ihren Campus weiterhin nutzen? Dann setzen Sie sich jetzt bitte mit dem Vertrieb in Verbindung.',
    billingActiveDirectTitle: 'Nutzungsvertrag mit der ivicos GmbH',
    billingActiveDirectDescription: 'Dieser CAMPUS wird direkt mit der ivicos GmbH abgerechnet. Bitte wenden Sie sich bei Fragen an unser Team unter ',
    billingActiveDirectAlternative: 'Alternativ können Sie auf die Schaltfläche unten klicken und es wird Ihre Standard-E-Mail-Anwendung geöffnet.',

    // Tooltips
    announcementTitle: 'Senden Sie eine Nachricht an ALLE in diesem Bereich...',
    areaSwitchTitle: 'Zurück in die Lobby / Verfügbare Bereiche anzeigen',
    togglePanelTitle: 'Raumansicht ein- bzw. ausklappen',
    backToDashboardTitle: 'Zurück in die Lobby',
    openForVisitorsTitle: 'Dieser Raum ist für Besucher frei zugänglich',
    roomOptionsTitle: 'Aktionen',
    noAccessToRoom: 'Für diesen Raum braucht es eine spezielle Zugangsberechtigung',

    // Attachments
    toggleAttachmentsActionTitle: 'Dateien ein-/ausblenden',
    attachmentPanelTitle: 'Dateien',
    videoOwnerTransferModalTitle: 'Video Playback Control',
    videoOwnerTransferModalSentence1: 'You are about to transfer control of video playback to',
    videoOwnerTransferModalSentence2: 'Are you sure you want to continue?',
    videoOwnerTransferModalCancelButtonLabel: 'Cancel',
    videoOwnerTransferModalTransferButtonLabel: 'Transfer',
    videoOwnershipTransferredToLocalUserModalSentence1Part1: 'You have been elected controller of the video by ',
    videoOwnershipTransferredToLocalUserModalSentence1Part2: ' and are now responsible for its playback. ',
    videoOwnershipTransferredToLocalUserModalSentence2: 'If you leave the room for any other reason, you can appoint another person to control video playback.',
    videoOwnershipTransferredToLocalUserModalOkButtonLabel: 'Ok',
    videoControlPanelTitle: 'Video Playing',
    videoControlPanelPlaybackControlledByTitle: 'Gesteuert von',
    videoControlPanelTransferPlaybackControlTitle: 'Übertragung der Videosteuerungswiedergabe auf',
    pinnedAttachmentPanelTitle: 'Angeheftetes Video',
    createNewAttachmentActionTitle: 'Neue Datei anhängen',
    filterAttachmentsPlaceholder: 'Dateien filtern...',
    savedItemTitle: 'Anhänge',
    noAttachmentsWarning: 'Diesem Raum wurden noch keine Dateien zugeordnet.',
    chooseAttachmentTypeTitle: 'Dateitypen...',
    linkTypeTitle: 'Internet-Verknüfung',
    collaboardTypeTitle: 'Collaboard',
    collaboardTypeDescription: 'Gemeinsam auf Collaboard skizzieren',
    collaboardLinkAttachmentModalBoxTitle: 'Neuen Collaboard Link-Anhang erstellen',
    colleboardLinkAttachmentModalBoxSubText: 'Bitte geben Sie einen Link zu einer Collaboard Project ein (oder fügen Sie ihn ein)',
    etherpadTypeTitle: 'Etherpad',
    youtubeLinkTypeTitle: 'YouTube',
    youtubeLinkTypeDescription: 'Gemeinsam ein Video ansehen',
    tldrawTypeTitle: 'Tldraw',
    linkTypeDescription: 'Verknüpfung zu Webseite/Werkzeug/Datei erstellen',
    etherpadTypeDescription: 'Gemeinsam, synchron Text bearbeiten',
    tldrawTypeDescription: 'Gemeinsam auf Flipchart skizzieren',
    enterUrlActionTitle: 'Bitte hier Internet-Adresse (inklusive https://) einfügen',
    deleteAttachmentConfirmationTitle: 'Sind Sie sicher, dass Sie diese Verknüpfung löschen möchten?',
    editAttachmentTitle: 'Dateibeschreibung bearbeiten',
    noDescriptionProvidedWarning: 'Keine Beschreibung vorhanden',
    createdAtTitle: 'Erstellt:',
    justNowTitle: 'gerade eben',
    oneMinutePassedTitle: 'vor einer Minute',
    minutesPassedTitle: 'vor {quantity} Minuten',
    oneHourPassedTitle: 'vor einer Stunde',
    hoursPassedTitle: 'vor {quantity} Stunden',
    yesterdayTitle: 'gestern',
    daysPassedTitle: 'vor {quantity} Tagen',
    weeksPassedTitle: 'vor {quantity} Wochen',
    monthsPassedTitle: 'vor {quantity} Monaten',
    aYearPassedTitle: 'vor einem Jahr',
    yearsPassedTitle: 'vor {quantity} Jahren',
    giveAttachmentNameTitle: 'Geben Sie der Datei einen Titel',
    giveAttachmentDescriptionTitle: 'Fügen Sie eine Beschreibung zur Datei hinzu',
    linkAttachmentModalBoxTitle: 'Neuen Link-Anhang erstellen',
    linkAttachmentModalBoxSubText:
        'Bitte geben Sie einen Link zu einer Webseite ein (oder fügen Sie ihn ein), die in einem neuen Browser-Tab geöffnet werden kann',
    youtubeLinkAttachmentModalBoxTitle: 'Erstellung eines neuen Videoanhangs',
    youtubeLinkAttachmentModalBoxSubText: 'Fügen Sie unten den Link zu einem beliebigen Video ein',
    youtubeLinkAttachmentModalVideoPinText: 'Video an den Raum anheften',
    youtubeLinkAttachmentModalVideoPinUpperText: 'Das Video kann an den Raum angeheftet werden, so dass es im oberen Bereich des Dateifensters angezeigt wird.',
    youtubeVideoPlaying: 'Video Wiedergabe',
    youtubePlaybackControl: 'Gesteuert von',
    youtubePlaybackTransfer: 'Übertragung der Videosteuerungswiedergabe an',
    youtubePinnedVideo: 'Angeheftetes Video',

    // Speaker stats
    speakerStatsTitle: 'Virtueller Moderator:',
    totalTalkTimeTitle: 'Gesprächszeit insgesamt',
    personalTalkTimeToggle: 'Einblenden meiner persönliche Sprechzeit.',
    talkingTooMuchToggle: 'Informiere nur mich, wenn ich zu viel rede.',
    experimentalLabelTitle: 'Experimentell',
    noDataTitle: 'Bislang keine Daten vorhanden',
    talkingMoreDescription: 'Hinweis: Sie reden aktuell deutlich mehr als alle anderen.',
    talkingMoreDescription2: 'Wäre es nun an der Zeit, jemand anderem das Wort zu erteilen?',
    startButtonLabel: 'Start',
    roundTableTitle: 'Gesprächsrunde',
    applyTimer: 'Sprechzeit definieren',
    startRoundTableModeTitle: 'Starte die Gesprächsrunde',
    stopRoundTableModeTitle: 'Beende die Gesprächsrunde',
    roundTableModeDialogBoxTitle: 'Gesprächsrunden-Modus',
    youAreOnTitle: 'Sie sind dran!',
    youAreTheLastSpeakerTitle: 'Sie beenden die Gesprächsrunde',
    clickNextWhenYouAreDoneTitle: 'Bitte "Nächster" anklicken, wenn Sie fertig sind',
    nextActionTitle: 'Nächster',
    nextActionTitle2: 'Weiter',
    isOnTitle: 'ist dran!',
    minutesLabel: 'Minuten',
    addUserToRoundTableButtonLabel: 'Hinzufügen',
    removeUserFromRoundTableButtonLabel: 'Entfernen',

    // General Time Words
    from: 'Von',
    at: 'Am',
    to: 'bis',
    until: 'bis',
    everyWeekOn: 'Jede Woche',
    everyday: 'Jeden Tag',
    weekly: 'Wöchentlich',
    daily: 'Täglich',
    noRepeat: 'Keine Wiederholung',

    // Months
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',

    // MonthsInShortForm
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mär',
    apr: 'Apr',
    may2: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Dez',

    // Weekdays
    monday: 'Montags',
    tuesday: 'Dienstags',
    wednesday: 'Mittwochs',
    thursday: 'Donnerstags',
    friday: 'Freitags',
    saturday: 'Samstags',
    sunday: 'Sonntags',

    // ImagePicker
    uploadNewImageTitle: 'Ein neues Bild hochladen',
    uploadNewImageActionTitle: 'Neues Bild hochladen',
    recentlyUploadedImages: 'Kürzlich hochgeladene Bilder',
    uploadActionTitle: 'Hochladen',
    clickToUploadImage: 'Zum Hochladen eines Bildes hier klicken',
    deleteImageDescription: 'Sind Sie sicher, dass Sie dieses Bild löschen möchten?',
    imageTitle: 'Bild',
    uploadCap: 'Die Größe des Bildes darf 2 Megabytes nicht überschreiten',
    uploadCapError: 'Die Größe des Bildes, das Sie hochladen möchten, ist größer als 2 Megabytes',
    onImagePickerModalClose: 'Bestätigen und schließen',

    // OverlaySelector
    videoOverlayLabel: 'Video-Overlay',
    overlayLabel: 'Overlay',
    backgroundLabel: 'Hintergrund',
    checkboxLabel: 'Transparenz des Bildes verwenden',

    // Availability
    available: 'Verfügbar',
    away: 'Als abwesend anzeigen',
    beRightBack: 'Gleich zurück',
    doNotDisturb: 'Bitte nicht stören',
    busy: 'Beschäftigt',

    // RegistrationForm
    registrationFormLabel: 'Zugang zum CAMPUS',
    registrationFormPlaceholder: 'Bitte geben Sie hier Ihren Namen ein',
    registrationFormErrorMessage: 'Bitte geben Sie einen gültigen Namen ein',
    registrationFormInfoMessage:
        'Bitte geben Sie Ihren Namen, einen Spitznamen oder Psydonym ein. Diese Information wird nicht in unserem System gespeichert und gelöscht, sobald Sie den CAMPUS verlassen.',

    // User Profile
    editNamePlaceholder: 'Geben Sie Ihren Namen ein',
    editNameErrorMessage: 'Die Mindestzeichenanzahl beträgt ein Zeichen',
    updateImageButtonLabel: 'Bild aktualisieren',
    editNameButtonLabel: 'Namen editieren',

    // OnboardingVideo
    onboardingVideoTitle: 'Dieses kurze Video erklärt Ihnen die wichtigsten Funktionen des CAMPUS. ',

    // FlagsView
    goBackActionTitle: 'Zurück wechseln',
    darkModeActionTitle: 'Dunklen Anzeigemodus einschalten',
    enableThisFrag1: 'Wenn Sie diese Option aktivieren, können Sie den CAMPUS im Dunkel-Modus verwenden.',
    useJitsiEnv: 'Verwenden der Jitsi-Staging-Umgebung',
    enableThisFrag2: 'Wichtig: Wenn Sie diese Funktion aktivieren, können Sie nicht mit Benutzern sprechen, die diese Funktion nicht aktiviert haben.',

    // App Errors
    logInTwiceTitle: 'Sie haben sich zweimal in denselben Bereich eingeloggt, es gibt sie jedoch nur einmal.',
    logInTwiceDescription:
        'Sie wurden auf dieser Browser-Registerkarte automatisch abgemeldet, weil Sie sich von einem anderen Ort aus, im selben Bereich angemeldet haben. Sie können sich nur von einer Browser-Registerkarte aus mit demselben Konto in denselben Bereich anmelden.',
    sessionExpiredTitle: 'Ihre Sitzung ist abgelaufen. Sie wurden automatisch abgemeldet.',
    sessionExpiredDescription: 'Sie wurden aus Sicherheitsgründen automatisch abgemeldet.',
    sessionExpiredReasonTitle: 'Hier einige Gründe, warum dies passieren kann:',
    sessionExpiredReason1:
        'Ihr ‘Magic-Link’ war bereits längere Zeit gültig und ist nun aus Sicherheitsgründen abgelaufen. Eine neue Anmeldung ist erforderlich.',
    sessionExpiredReason2:
        'Sie haben versucht, ein Lesezeichen zu verwenden, um einen persönlichen Raum zu betreten. Dies ist aus Sicherheitsgründen nicht erlaubt.',
    sessionExpiredReason3: 'Sie haben den Campus möglicherweise von einem anderen Gerät aus betreten.',
    sessionExpiredReconnect: 'Verbindung wiederherstellen',
    resourceNotFoundTitle: 'Der von Ihnen angeforderte Zugang konnte nicht gefunden werden',
    resourceNotFoundDescription: 'Sie wurden automatisch abgemeldet',
    unexpectedErrorOccuredTitle: 'Es ist ein unerwarteter Fehler aufgetreten',
    unexpectedErrorOccuredDescription:
        'Ihre Verbindung wurde automatisch unterbrochen, weil ein unerwarteter Fehler aufgetreten ist. Bitte kontaktieren Sie Ihren Campus-Betreiber oder info@ivicos.eu',

    // loadingStrings
    loadingStringAreas: 'Bereiche laden...',
    loadingStringSettings: 'Einstellungen laden...',
    loadingStringMembersList: 'Mitgliederliste laden...',
    loadingStringInvitation: 'Einladungen laden...',
    loadingStringWaitingAreas: 'Wartebereich laden...',
    loadingStringBilling: 'Billing laden...',

    // SetupAssistant
    setProfileImage: 'Profilfoto festlegen',
    setOrgLogo: 'Unternehmenslogo einsetzen',
    setOrgColor: 'Unternehmensfarbe festlegen',
    SetupAssistantLabelOpenPrimary: 'Bitte das Unternehmensprofil vervollständigen!',
    SetupAssistantLabelOpenSecondary: 'Es gibt noch ein paar Dinge, die Sie als CAMPUS-Manager erledigen müssen, bevor Ihr CAMPUS einsatzbereit ist!',
    SetupAssistantLabelCompletedPrimary: 'Vorbereitungen abgeschlossen!',
    SetupAssistantLabelCompletedSecondary: 'Sie haben Ihr Unternehmensprofil vollständig eingerichtet!',
    completeLabel: 'erledigt',
    completedLabel: 'abgeschlossen',
    openLabel: 'noch offen',

    // OnboardingVideo
    introVideoTitle: 'Kurzes Einführungsvideo',
    tutorialConfirmationTitle: 'Ein paar Tipps, um sich in Ihrem neuen Büro zu orientieren!',

    // Intro Steps
    areaIntroStep1: 'Willkommen zu dieser kurzen Einführung in Ihren CAMPUS! Klicken Sie einfach auf "Weiter", um loszulegen. ',
    areaIntroStep2:
        'Hier sind Registerkarten, um zwischen Bereichen zu wechseln und Hinweise auf Neuerungen zu erhalten. Für CAMPUS-Manager gibt es zusätzlich die Registerkarten "Mitglieder verwalten" und "Campus-Einstellungen".',
    areaIntroStep3: 'Dies ist ein Bereich. Innerhalb von Bereichen können Sie Räume erstellen. Es kann mehrere Bereiche auf dem CAMPUS geben.',
    campusSettingIntroStep1: 'Hier können Sie Ihr Profilbild hinterlegen und individuelle Einstellungen vornehmen.',
    campusSettingIntroStep2: 'Hier können Sie den CAMPUS-Namen, die Unternehmensfarbe und das Unternehmens-Logo festlegen.',
    memberIntroStep1:
        'CAMPUS-Manager können hier Personen zum CAMPUS einladen, indem Sie deren E-Mail eingeben und auf die Schaltfläche “Einladung versenden” klicken. In der Tabelle unten können Sie Einladungen stornieren, erneut senden sowie Mitglieder wieder entfernen.',
    memberIntroStep2:
        'Diese Tabelle zeigt alle Personen, die Sie zu Ihrem CAMPUS eingeladen haben, unabhängig davon, ob sie die Einladung angenommen haben oder nicht. Name und ggf. Profilbild werden angezeigt, sobald die Einladung angenommen wurde.',
    memberIntroStep3: 'E-Mail-Daten in der Tabelle suchen.',
    roomIntroStep1:
        'Hier finden Sie alle Informationen zu Ihrem Profil bei CAMPUS. Sie können ein persönliches Profilbild hinterlegen und Präferenzen einstellen.',
    roomIntroStep2:
        'Jedes Mitglied hat einen eigenen persönlichen Raum, sobald die Person einen Bereich betritt. Bewegen Sie den Mauszeiger über Profilbider, um weitere Kommunikationsmöglichkeiten anzuzeigen.',
    roomIntroStep3:
        'Räume sind für verschieden Funktionen gestaltbar, z.B. für Besprechungen, Workshops oder gemeinsamen Arbeiten/Lernen. Sie sind flexibler gestaltbar als physische Räume und können nach Belieben gelöscht bzw. archiviert werden.',
    roomIntroStep4: 'Hier können Sie Besucher einladen, die nicht Mitglieder des CAMPUS sind.',
    roomIntroStep5: 'Mit diesen Icons können Sie u.a. die Video- und Anrufeinstellungen anpassen.',
    roomIntroStep6: 'Mit diesen Icons können Sie weitere nützliche CAMPUS-Funktionen erreichen, z. B. den "Virtuellen Moderator".',
    roomIntroStep7: 'Hier endet das kurze Tutorial. Viel Spaß beim Entdecken ihres CAMPUS!',

    // Not Supported Browser warning
    notSupportedBrowserString1: 'ist eine Browseranwendung, für die keine Software installiert werden muss.',
    notSupportedBrowserString2:
        'Da /devices/ und /devices/ häufig aktualisiert werden und manchmal Inkompatibilitäten verursachen, können nicht alle Versionen unterstützt werden.',
    notSupportedBrowserString3:
        'Wir bitten um Verständnis, dass ivCAMPUS nicht mit den /browser/ und /browser/Versionen von /browser/ und /browser/ genutzt werden kann.',
    notSupportedBrowserString4: 'Bitte verwenden Sie einen der folgenden Browser:',
    notSupportedBrowserFooterText: 'Für weitere Informationen über den ivCAMPUS finden Sie auf /unsere Website / oder kontaktieren Sie uns /hier',
    downloadHere: 'Herunterladen',

    // SetupCompletePage
    setupCompletePageTitle: 'CAMPUS einrichten!',

    // Profile page
    profilePageTitle: 'Nutzerprofil einrichten',
    profilePageTextFieldPlaceholder: 'Geben Sie Ihren vollständigen Namen ein',

    // ivCAMPUS Setup page
    ivCAMPUSSetupPageTitle: 'CAMPUS Einrichtung',
    nameLabel: 'Name',
    organisationNamePlaceholder: 'Unternehmensname',
    sizeOfTheOrganisationLabel: 'Mitarbeiterzahl der Organisation',
    sizeOfTheOrganisationPlaceholder: '',
    locationsLabel: 'Standortanzahl der Organisation',
    howManyLocationsPlaceholder: '',
    ivCAMPUSSetupPageCheckBoxDescription1: 'Ich bestätige hiermit, dass ich den CAMPUS für',
    ivCAMPUSSetupPageCheckBoxDescription3: ' einrichte',
    ivCAMPUSSetupPageCheckBoxDescription2: 'Hiermit bestätige ich, dass ich berechtigt bin einen CAMPUS einzurichten - und zwar für (die Organisation)',
    moreThan500: 'mehr als 500',
    moreThan20: 'mehr als 20',
    enterOrgNameErrorMessage: 'Bitte geben Sie einen gültigen Unternehmensnamen ein',
    enterOrgSizeErrorMessage: 'Bitte wählen Sie die etwaige Unternehmensgröße',
    enterOrgLocationErrorMessage: 'Bitte geben Sie die ungefähre Anzahl von Standorten an',

    // FindCampus page
    findCampusPageTitle: 'Bei nachfolgender Organisation anmelden...',
    findCampusPageLabel: 'Wechseln zu:',
    findCampusPagePlaceholder: 'CAMPUS auswählen',
    hideOption: 'Optionen ausblenden',
    showOption: 'Weitere Optionen einblenden',
    startSetupButtonTitle: 'Neuen CAMPUS erstellen...',
    startSetupButtonDescription: 'Ein virtuelles Büro für Ihre Organisation erstellen ',
    seeAllInvitations: 'Alle Einladungen anzeigen',
    invitationTitle: 'Sie erhalten als Mitglied Zugang zu ',
    noSponsorModalTitle: 'Zugang inaktiv',
    noSponsorModalWelcome: 'Willkommen auf dem ivCAMPUS.',
    noSponsorModalDescription: 'Sie haben jetzt die Möglichkeit sich von der Leistungsstärke von Campus zu überzeugen. ',
    noSponsorModalTip: 'Die verbleibende Zeit ihres Testzeitraums können Sie sich nach der Registierung im Tab “Billing” anzeigen lassen.',
    trialPeriodOverButConsider1: 'Ihr Testzeitraum ist abgelaufen.',
    trialPeriodOverButConsider2: 'Sie können jedoch fortfahren. Hierfür bitte den…',

    // General Button Text
    enter: 'Eintreten',
    login: 'Anmelden',
    accept: 'Annehmen',
    decline: 'Ablehnen',
    acceptAndJoin: 'Annehmen & Zutritt erhalten',
    loading: 'Laden...',
    somethingWentWrong: 'Etwas ging leider schief',
    continue: 'Weiter',
    finish: 'Fertig',
    okAction: 'Ok',

    // General Text
    here: 'hier',
    orVisitOur: 'oder besuchen Sie unsere',
    helpPage: 'Hilfeseiten',
    empty: 'Leer',
    signedInAs: 'Eingeloggt als ',
    errorHasOccured: 'Es it ein Fehler aufgetreten',
    talkingToMicrosoft: 'Mit der Microsoft Identity Platform verbinden...',

    // Error messages
    min3: 'Der Name sollte mindestens 3 Zeichen lang sein',
    max7: 'Der Name darf maximal 72 Zeichen lang sein',
    minimumLimit: 'Die Mindestanzahl beträgt drei Zeichen',

    // Login Page
    signInTitle: 'Anmelden',
    emailPlaceHolder: 'Geben Sie Ihre Arbeits-E-Mail-Adresse ein',
    emailButtonText: 'Magic-Link erhalten',
    emailErrorMessage: 'Bitte geben Sie eine gültige Email-Adresse ein!',
    loginPageDescription3: 'Wir senden Ihnen einen Zugangslink an Ihre E-Mail-Adresse.',
    orOptionTitle: 'oder',
    developedByTitle1: 'Entwickelt von ',
    developedByTitle2: '(c) ivicos GmbH, Deutschland',
    newToPassWordlessAcesss: 'Ein passwortloser Zugang ist neu für Sie?',
    clickHereToKnowMore: 'Klicken Sie hier, um mehr zu erfahren.',

    // NewUserAwaitingConfirmation Page
    userConfirmationPageTitle: 'Bitte bestätigen Sie Ihre Zugangsdaten',
    userConfirmationPageDescription1: 'Bitte zum E-Mail-Posteingang (ggf. Spam-Ordner) wechseln.',
    userConfirmationPageDescription2: 'In der E-Mail, die Sie erhalten: ',
    userConfirmationPageDescription3:
        'Klicken Sie bitte auf die Schaltfläche "Zugang" oder kopieren Sie den Magic-Link und fügen Sie ihn in diesen oder einen anderen Browser-Tab ein.',
    userConfirmationPageDescription4: 'Keine E-Mail empfangen? ',
    userConfirmationPageDescription5: ' Geben Sie bitte Ihre E-Mail-Adresse',
    userConfirmationPageDescription6: 'erneut ein.',

    // MagicLinkVerification
    processingLoadingText: 'Überprüfung...',

    // NewUserAccessGranted
    accessGrantedTitle: 'Zugang gewährt!',
    accessGrantedDescription1: ' wurde bestätigt.',
    accessGrantedDescription2: 'Bitte klicken Sie erneut',
    accessGrantedDescription3: 'wenn Sie nicht automatisch zum CAMPUS weitergeleitet werden.',

    // UserAccessGranted
    userAccessGrantedDescription1: 'Hallo ',
    userAccessGrantedDescription2: '! Ihr Zugang wurde bestätigt.',
    userAccessGrantedDescription3: 'wenn Sie nicht automatisch zu CAMPUS weitergeleitet werden.',

    // NewUserSomethingWrong
    somethingWrongTitle: 'Etwas ist schief gegangen!',
    newUserSomethingWrongDescription1: 'Während der Authentifizierung ist ein Fehler aufgetreten.',
    newUserSomethingWrongDescription2: 'Um einen neuen Zugangslink zu erhalten, klicken Sie bitte',
    newUserSomethingWrongDescription3: 'Wenn der Fehler weiterhin auftritt, kontaktieren Sie bitte den Support',

    // AccountCreationSuccessful
    accountCreationSuccessfulTitle1: 'Einen CAMPUS-Zugang für ',
    accountCreationSuccessfulTitle2: ' wurde erfolgreich erstellt!',
    accountCreationSuccessfulDescription:
        'Bitte prüfen Sie Ihren E-Mail-Posteingang (auch Ihren Spam-Ordner) und klicken Sie auf den Zugangslink, um dann Ihr Profil zu vervollständigen.',

    // AccountCreationSomethingWentWrong
    accountCreationSomethingWentWrongDescription1: 'Die Erstellung eines neuen Kontos konnte nicht abgeschlossen werden.',
    accountCreationSomethingWentWrongDescription2: 'Bitte kontaktieren Sie den Support.',

    // LoadingStrings
    creatingAnAccountLoadingString: 'Zugang wird erstellt...',
    waitingForResponseLoadingString: 'Bitte warten Sie...',

    // PasswordlessAccessModal;
    passwordLessAccessModalTitle: 'Warum ein passwortloser Zugang?',
    passwordLessAccessModalDescription1:
        'Diese Methode erlaubt die Überprüfung der Identität eines Benutzers ohne Verwendung von Passwörtern und bietet so unmittelbaren Schutz vor den gefährlichsten und häufigsten Cyberangriffen.',
    passwordLessAccessModalDescription2:
        'Auf diese Weise sind Benutzer vor betrügerischen E-Mails und Textnachrichten geschützt, mit denen versucht wird, an Passwörter zu gelangen. Da nirgendwo ein Passwort gespeichert wird, kann es auch nicht gestohlen werden.',
    passwordLessAccessModalDescription3: 'Wie funktioniert das dann?',
    passwordLessAccessModalDescription4:
        'Die Methode ist einfach. Die Nutzer geben lediglich ihre E-Mail-Adresse in ein Anmeldefeld ein. Anstatt dann nach einem Passwort gefragt zu werden, erhalten sie eine E-Mail mit einem Link zum anklicken, um Zugang zu erhalten. Aus Sicherheitsgründen wird jeweils ein anderer Link bei jedem Anmeldeversuch an die Nutzer verschickt.',
    passwordLessAccessModalDescription5:
        'Früher ging man zur Arbeit und zeigte dem Pförtner seinen Mitarbeiterausweis. Heute funktioniert es mit einem einfachen Link, mit dem man dann den ganzen Arbeitstag auf dem CAMPUS präsent ist.'
};
