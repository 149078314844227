import { useRecoilState } from 'recoil';
import { aCurrentAreaId, aCurrentCampusId, aCurrentOrgId, aCurrentRoomId, aIsVisitorAtLandingPage, aSelectedAttachment } from 'shared-state/location/atoms';

export const useSelectedAttachment = (): any => {
    return useRecoilState(aSelectedAttachment);
};
export const useCurrentRoomId = (): any => {
    return useRecoilState(aCurrentRoomId);
};
export const useCurrentAreaId = (): any => {
    return useRecoilState(aCurrentAreaId);
};
export const useCurrentCampusId = (): any => {
    return useRecoilState(aCurrentCampusId);
};
export const useCurrentOrgId = (): any => {
    return useRecoilState(aCurrentOrgId);
};

export const useIsVisitorAtLandingPage = (): any => {
    return useRecoilState(aIsVisitorAtLandingPage);
};
