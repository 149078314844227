import PowerUserImporterView from 'features/Members/PowerUserImporter/PowerUserImporter.view';
import React from 'react';
import { PowerUserImporterState } from './PowerUserImporter.state';

const PowerUserImporter: React.FC<{}> = () => {
    const state = PowerUserImporterState();
    return (
        <PowerUserImporterView
            {...{
                ...state,
                ...{
                    acceptData: () => state.onAcceptData(),
                    declineData: () => state.setStep(0),
                    data: { body: state.body, headline: state.headline, setBody: state.setBody },
                    onDismiss: () => state.setIsOpen(false),
                    onFinish: () => state.onFinish()
                }
            }}
        />
    );
};

export default PowerUserImporter;
