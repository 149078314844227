import React, { ReactElement } from 'react';
import { useCallLoadingScreenState } from './CallLoadingScreen.state';
import CallLoadingScreenView from './CallLoadingScreen.view';

export interface ICallLoadingScreenProps {
    label?: string;
    description?: string;
    onRenderIcon?: () => ReactElement;
}
const CallLoadingScreen: React.FC<ICallLoadingScreenProps> = (props) => {
    const state = useCallLoadingScreenState(props.label, props.description);

    return (
        <CallLoadingScreenView
            {...{
                ...state,
                ...{
                    onRenderIcon: props.onRenderIcon
                }
            }}
        ></CallLoadingScreenView>
    );
};
export default CallLoadingScreen;
