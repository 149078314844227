import React from 'react';
import { IProfile } from 'shared-state/identity/types';
import { useUserFaceOverflowButtonState } from './UserFaceOverflowButton.state';
import UserFaceOverflowButtonView from './UserFaceOverflowButton.view';

export const UserFaceOverflowButton: React.FC<{ users: IProfile[]; overflowIndex: number; panelTitle?: string }> = (props) => {
    const state = useUserFaceOverflowButtonState();

    return (
        <UserFaceOverflowButtonView
            {...{
                ...state,
                ...{
                    users: props.users,
                    overflowIndex: props.overflowIndex
                }
            }}
        />
    );
};
