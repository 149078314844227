/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import HoverCardHostView from './HoverCardHost.view';

export interface IHoverCardHostProps {
    content: string | ReactElement;
    disabled?: boolean;
    children?: React.ReactNode;
}

const HoverCardHost: React.FC<IHoverCardHostProps> = (props) => {
    if (props.disabled) return <>{props.children}</>;

    return (
        <HoverCardHostView
            {...{
                content: props.content,
                disabled: props.disabled
            }}
        >
            {props.children}
        </HoverCardHostView>
    );
};

export default HoverCardHost;
