import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getFlag } from 'routes/Flags/Flags';
import { resourceService } from 'services/api/resource.service';
import { thirdPartyAuthService } from 'services/api/third-party-auth.service';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { useLocalJitsiDomain, useLocalJitsiRoom, useLocalJitsiToken } from 'shared-state/tokens/hooks';

export const useJitsiToken = (): [string | undefined, string | undefined, string | undefined, Function] => {
    const routeHistory = useNavigate();
    const currentUser = useLocalProfile();
    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();
    const [currentCampusId] = useCurrentCampusId();
    const [jwt, setJwt] = useLocalJitsiToken(currentRoomId || 'default');
    const [room, setRoom] = useLocalJitsiRoom(currentRoomId || 'default');
    const [domain, setDomain] = useLocalJitsiDomain(currentRoomId || 'default');

    const jitsiSessionId = localStorage.getItem('jitsiSessionId');
    const roomSessionToken = sessionStorage.getItem('tempRoomSessionToken') || '';

    // Memoized function to clear JWT token
    const clearToken = useCallback(() => setJwt(undefined), [setJwt]);

    // Function to check room access
    const checkRoomAccess = useCallback(async () => {
        if (!currentRoomId || !currentUser) {
            return false;
        }

        if (!['visitor', 'auto-confirmed-visitor', 'unconfirmed_visitor'].includes(currentUser.type)) {
            if (currentRoomId.includes('personal')) {
                if (!currentRoomId.includes(currentUser?.uid) && !roomSessionToken) {
                    return false;
                }
            } else {
                // check for common room access
                try {
                    const currentRoom = await resourceService.getRoomById(currentCampusId, currentAreaId, currentRoomId);
                    const isRoomWhiteListed = Array.isArray(currentRoom?.whitelist) && currentRoom?.isPrivate;
                    const isUserRoomCreator = currentRoom?.creatorId === currentUser?.uid;
                    const isUserListed = currentRoom?.whitelist?.includes(currentUser?.uid || '');

                    // Handle access control based on whitelist and creator
                    if (isRoomWhiteListed && !isUserRoomCreator && !isUserListed && !roomSessionToken) {
                        return false;
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        return true;
    }, [currentUser, currentRoomId, currentCampusId, currentAreaId, roomSessionToken]);

    // Function to fetch the Jitsi token
    const fetchToken = useCallback(async () => {
        // Always clear token before fetching
        clearToken();

        const jitsiEnvFlavor = getFlag('jitsiEnv');
        const visitorSessionId = sessionStorage.getItem('whitelist-roomId') || '';

        if (visitorSessionId && !currentRoomId.includes('visitor') && visitorSessionId !== currentRoomId) {
            sessionStorage.removeItem('whitelist-roomId');
        }

        const hasAccess = await checkRoomAccess();
        if (!hasAccess) {
            routeHistory('/error/403');
            return;
        }

        // Fetch Jitsi token
        try {
            const tokenReq = await thirdPartyAuthService.getJitsiKey(
                currentCampusId,
                currentAreaId,
                currentRoomId,
                jitsiEnvFlavor,
                roomSessionToken,
                visitorSessionId
            );
            setDomain(tokenReq.domain);
            setRoom(tokenReq.room);
            setJwt(tokenReq.key);
        } catch (err) {
            console.error('Failed to get Jitsi token:', err);
            localStorage.clear();
            sessionStorage.clear();
            routeHistory('/error/403');
        }
        console.log('[JITSIHOOK] Setting JWT...');
    }, [clearToken, currentUser, currentRoomId, currentCampusId, currentAreaId, routeHistory, roomSessionToken, setDomain, setRoom, setJwt]);

    useEffect(() => {
        if (!currentRoomId || !currentCampusId || !currentAreaId) {
            clearToken();
            return;
        }

        fetchToken();
    }, [currentRoomId, currentCampusId, currentAreaId, jitsiSessionId, fetchToken]);

    return [jwt, room, domain, clearToken];
};
