import * as React from 'react';

function SvgEditMessage(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3 5v18h5v5.08L14.352 23h2.851l-.416-2h-3.139L10 23.92V21H5V7h22v11.973l2 2V5H3zm15 12l1.1 5.3 7.8 7.8c.6.6 1.35.9 2.1.9s1.5-.3 2.1-.9c.6-.6.9-1.35.9-2.1s-.3-1.5-.9-2.1l-7.8-7.8L18 17zm2.6 2.6l1.7.3 7.4 7.4c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-7.4-7.4-.3-1.7z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgEditMessage;
