import React from 'react';
import AreaListView from './AreaList.view';

import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import { useAreaListState } from './AreaList.state';
import IvicosStrings from 'kits/language/stringKit';

interface IAreaListProps {
    vertical?: boolean;
    onSelection?: Function;
}

export const AreaList: React.FC<IAreaListProps> = (props) => {
    const state = useAreaListState(props.vertical, props.onSelection);

    return (
        <>
            {state._areas.length == 0 ? (
                <LoadingDialog loadingStrings={[IvicosStrings.loadingStringAreas]} view="small" typewriter={false} />
            ) : (
                <AreaListView
                    {...{
                        ...state,
                        ...{
                            vertical: props.vertical,
                            areas: state._areas
                        }
                    }}
                ></AreaListView>
            )}
        </>
    );
};

export default AreaList;
