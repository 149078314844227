import * as React from 'react';

function SvgVisitorsVideo(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.570801 0.447876V11.8764H16.2851V9.46573L20.5708 11.6086V0.715733L16.2851 2.85859V0.447876H0.570801ZM1.99937 1.87645H14.8565V10.4479H1.99937V1.87645ZM19.1422 3.03716V9.28716L16.2851 7.85859V4.46573L19.1422 3.03716Z"
                fill="black"
            />
        </svg>
    );
}

export default SvgVisitorsVideo;
