/* eslint-disable react/display-name */
import { DefaultEffects, getTheme, IconButton, Persona, PersonaSize, Stack, Text, TooltipHost } from '@fluentui/react';
import Badge from 'components/Badge';
import HoverCardHost from 'components/HoverCardHost/HoverCardHost';
import { IC } from 'components/SVGIcon';
import { availabilityToPersonaPresence, UserAvailability } from 'kits/availabilityKit';
import React from 'react';
import { useControlNameWidthInMemo, useSideBarDraggableWidth, useWindowWidth } from 'shared-state/display-options/hooks';
import { IUserAction } from '../helpers/userTypes';

export interface IUserFaceActionStyles {
    hidden?: boolean;
    expanded?: boolean;
}

export interface IUserFaceViewProps {
    user: any;
    index: number;
    showDetails?: boolean;
    actionStyles?: IUserFaceActionStyles;
    profileImageURL?: string;
    actionsShouldBeDisplayOnHover: boolean;
    detailsAreHidden: boolean;
    defaultAvailibility: UserAvailability;
    actions: IUserAction[];
}

const UserFaceView: React.FC<IUserFaceViewProps> = ({
    user,
    index,
    showDetails,
    actionStyles,
    profileImageURL,
    actionsShouldBeDisplayOnHover,
    detailsAreHidden,
    defaultAvailibility,
    actions
}) => {
    const theme = getTheme();
    const renderActions = () =>
        actions?.map((action) => (
            <TooltipHost key={action.key} content={smallDevice ? '' : action.title}>
                <IconButton
                    onClick={() => action.action()}
                    onRenderIcon={() => (
                        <IC variant="dark" size={16}>
                            {action.onRenderIcon && action.onRenderIcon()}
                        </IC>
                    )}
                />
            </TooltipHost>
        ));

    const { smallDevice } = useWindowWidth();
    const [sideBarDraggableWidth] = useSideBarDraggableWidth();
    const decreasedSideBarDraggableWidth = sideBarDraggableWidth - 160;
    const decreasedWindowInnerWidth = window.innerWidth - 175;

    const [controlNameWidthInMemo] = useControlNameWidthInMemo();

    const stylesForUserPersona = {
        primaryText: {
            maxWidth: controlNameWidthInMemo ? 320 : smallDevice ? decreasedWindowInnerWidth : sideBarDraggableWidth ? decreasedSideBarDraggableWidth : 160
        }
    };

    return (
        <Stack className="hoverable" horizontal style={{ position: 'relative', overflow: 'visible' }} verticalAlign="center" horizontalAlign="space-between">
            {(actionsShouldBeDisplayOnHover || detailsAreHidden) && (
                <Stack
                    horizontalAlign={index <= 2 ? 'start' : 'end'}
                    className="display--on-parent-hover"
                    style={{ position: 'absolute', zIndex: 300, bottom: 30, left: index > 2 ? undefined : 16, right: index <= 2 ? undefined : 16, width: 300 }}
                ></Stack>
            )}
            <HoverCardHost
                disabled={!actionsShouldBeDisplayOnHover && !detailsAreHidden}
                content={
                    <Stack
                        horizontal
                        className="padding--small "
                        verticalAlign="center"
                        tokens={{ childrenGap: 4 }}
                        style={{ minHeight: 32, borderRadius: 4, boxShadow: DefaultEffects.elevation16, background: theme.palette.white }}
                    >
                        {actionsShouldBeDisplayOnHover && (
                            <>
                                <Stack horizontal>{renderActions()}</Stack>
                                {detailsAreHidden && <div style={{ width: 1, height: 24, minHeight: 24, background: theme.palette.neutralLight }} />}
                            </>
                        )}
                        {detailsAreHidden && <Text className={'padding_horizontal--small'}>{user.displayName}</Text>}
                    </Stack>
                }
            >
                {user.type === 'user' ? (
                    <Stack>
                        <Persona
                            styles={stylesForUserPersona}
                            size={PersonaSize.size40}
                            text={user.displayName}
                            imageUrl={profileImageURL}
                            hidePersonaDetails={!showDetails}
                            presence={availabilityToPersonaPresence(user.availability || defaultAvailibility)}
                            aria-label="user-name-in-user-face"
                        />
                    </Stack>
                ) : (
                    <Stack horizontal verticalAlign="center">
                        <Stack horizontalAlign="center" verticalAlign="start" style={{ width: '40px', paddingRight: 10, marginLeft: 5 }}>
                            <Badge text={user.displayName} size="51" fontSize="medium" />
                        </Stack>
                        {showDetails && (
                            <Text
                                variant="medium"
                                style={{
                                    color: theme.palette.black,
                                    marginLeft: 7,
                                    width: '70%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {user.displayName}
                            </Text>
                        )}
                    </Stack>
                )}

                {showDetails && !actionStyles?.hidden && actionStyles?.expanded && <Stack horizontal>{renderActions()}</Stack>}
            </HoverCardHost>
        </Stack>
    );
};

export default UserFaceView;
