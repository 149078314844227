import * as React from 'react';

function SvgYoutubeLogo(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="59" height="44" viewBox="0 0 59 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M57.3531 7.5625C56.8077 4.53857 54.209 2.33643 51.1932 1.64893C46.6803 0.6875 38.3281 0 29.2915 0C20.2602 0 11.7743 0.6875 7.25603 1.64893C4.24561 2.33643 1.64156 4.39893 1.09616 7.5625C0.545406 11 0 15.8125 0 22C0 28.1875 0.545406 33 1.22984 36.4375C1.78059 39.4614 4.37928 41.6636 7.38971 42.3511C12.1807 43.3125 20.3939 44 29.4305 44C38.4671 44 46.6803 43.3125 51.4713 42.3511C54.4817 41.6636 57.0804 39.6011 57.6311 36.4375C58.1765 33 58.861 28.0479 59 22C58.722 15.8125 58.0375 11 57.3531 7.5625ZM21.9018 31.625V12.375L38.6008 22L21.9018 31.625Z"
                fill="white"
            />
        </svg>
    );
}

export default SvgYoutubeLogo;
