import { useJitsiToken } from 'kits/apiKit3/jitsi';
import React, { useState } from 'react';
import { useCurrentRoomId } from 'shared-state/location/hook';

export interface IOutsideCallControllerState {
    room: string | undefined;
    jwt: string | undefined;
    jitsiRoom: string | undefined;
    domain: string | undefined;
    numberOfParticipants: number;
    setNumberOfParticipants: React.Dispatch<React.SetStateAction<number>>;
}

export const useOutsideCallControllerState: () => IOutsideCallControllerState = () => {
    const [room] = useCurrentRoomId();
    const [jwt, jitsiRoom, domain] = useJitsiToken();
    const [numberOfParticipants, setNumberOfParticipants] = useState<number>(1);

    return { room, jwt, jitsiRoom, domain, numberOfParticipants, setNumberOfParticipants };
};
