import { IResource } from 'kits/apiKit3/legacy';
import React from 'react';
import { useAddOnState } from './AddOn.state';
import AddOnView from './AddOn.view';

export interface IAddOnViewProps {
    addOn: IResource;
    isHidden?: boolean;
    attachmentRef?: string;
    onMessage?: (message: any) => void;
}

const AddOn: React.FC<IAddOnViewProps> = (props) => {
    const state = useAddOnState(props.addOn, props.attachmentRef, props.onMessage);
    return (
        <AddOnView
            {...{
                ...state,
                ...{
                    isHidden: props.isHidden,
                    addOn: props.addOn
                }
            }}
        ></AddOnView>
    );
};
export default AddOn;
