import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, getTheme, IPersonaProps, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useBoolean } from '@uifabric/react-hooks';
import CopyButton from 'components/CopyButton/CopyButton';
import { IC } from 'components/SVGIcon';
import { IParsedInvitation } from 'features/Header/children/CreateInvitationPanel/Data';
import { DownArrow, UpArrow } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect } from 'react';
import { useArea } from 'shared-state/directory/hooks';

interface IInvitationsCardProps {
    data: IParsedInvitation;
    members: IPersonaProps[] | undefined;
    onDelete: () => Promise<void>;
    cardIsOpen: boolean;
    setCardIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    roomName: string;
    selectedDay: string;
    from1: string;
    to1: string;
    from2: string;
    to2: string;
    specifyRoomName: () => void;
    retrievingDay: () => void;
    retrievingTime: (data: any) => void;
    retrievingShortMonth: (data: any) => void;
    buttonStatus: number;
    copyButtonContent: {
        [index: number]: string;
    };
    copyCopyButtonValueToClipboard: (value: string) => void;
    copyValue: string;
    hideDialog: boolean;
    toggleHideDialog: () => void;
    invitationLinkValue: any;
    isNewCreated: boolean | undefined;
}

const InvitationsCardView: React.FC<IInvitationsCardProps> = (props) => {
    const {
        data,
        members,
        onDelete,
        cardIsOpen,
        setCardIsOpen,
        roomName,
        selectedDay,
        from1,
        to1,
        from2,
        to2,
        specifyRoomName,
        retrievingDay,
        retrievingTime,
        retrievingShortMonth,
        buttonStatus,
        copyButtonContent,
        copyCopyButtonValueToClipboard,
        copyValue,
        hideDialog,
        toggleHideDialog,
        invitationLinkValue,
        isNewCreated
    } = props;

    const theme = getTheme();
    const [isDraggable] = useBoolean(false);
    const buttonId = useId('callout-button');
    const areaName = useArea(data.area);

    useEffect(() => {
        specifyRoomName();
        retrievingDay();
        retrievingTime(data);
        retrievingShortMonth(data);
    }, []);

    const allHost: any = [];

    data.hosts?.forEach((host) => {
        const person = members?.find((person) => person.id == host);

        allHost.push(person);
    });

    const hostNames = allHost?.map((host: any) => host?.primaryText);

    const hostNamesString = hostNames.join(', ');

    const dialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.deleteInvitationActionTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle,
        subText: `${IvicosStrings.invitationDeleteConfirm2} "${data.name}"?`
    };

    const modalPropsStyles = { main: { maxWidth: 450 } };

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined
        }),
        [isDraggable]
    );

    return (
        <Stack
            style={{
                borderRadius: '4px',
                border: `0.5px solid ${theme.palette.black}`,
                padding: '5px',
                margin: '5px 0px'
            }}
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
            aria-label="invitation-card"
        >
            <Stack style={{ width: '100%' }}>
                <Stack horizontal={false}>
                    <Stack horizontal>
                        <Text style={{ fontWeight: 'bold' }}>{data.name}</Text>
                    </Stack>
                    {data.validity === 'single' ? (
                        <Stack style={{ marginTop: '5px' }} horizontal={false}>
                            <Text>{IvicosStrings.from + ` ${from1} ` + ` ${from2} ` + IvicosStrings.until + ` ${to1} ` + ` ${to2}`}</Text>
                        </Stack>
                    ) : data.repeat === 'weekly' ? (
                        <>
                            <Stack style={{ marginTop: '5px' }}>
                                <Text>
                                    {IvicosStrings.everyWeekOn} {selectedDay}
                                </Text>
                            </Stack>
                            <Stack style={{ marginTop: '5px' }}>
                                <Text>
                                    {IvicosStrings.from +
                                        ` ${data.validFromTime?.toString().slice(0, 5)} ` +
                                        IvicosStrings.until +
                                        ` ${data.validToTime?.toString().slice(0, 5)}`}
                                </Text>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Stack style={{ marginTop: '5px' }}>
                                <Text>{IvicosStrings.everyday}</Text>
                            </Stack>
                            <Stack style={{ marginTop: '5px' }}>
                                <Text>
                                    {IvicosStrings.from +
                                        ` ${data.validFromTime?.toString().slice(0, 5)} ` +
                                        IvicosStrings.until +
                                        ` ${data.validToTime?.toString().slice(0, 5)}`}
                                </Text>
                            </Stack>
                        </>
                    )}
                </Stack>
                {cardIsOpen && (
                    <>
                        {data.type === 'pickup' ? (
                            <Stack>
                                {data.message !== '' ? (
                                    <Stack horizontal style={{ marginTop: '5px', wordBreak: 'break-word' }}>
                                        <Text>
                                            {IvicosStrings.welcomeMessageTitle} {`"${data.message}"`}
                                        </Text>
                                    </Stack>
                                ) : (
                                    <Stack horizontal style={{ marginTop: '5px' }}>
                                        <Text>{IvicosStrings.missingWelcomeMessageTitle}</Text>
                                    </Stack>
                                )}
                                <Stack horizontal style={{ marginTop: '5px' }}>
                                    <Text style={{ marginRight: '5px' }}>{IvicosStrings.hostsTitle}:</Text>
                                    <Stack horizontal>
                                        <Text style={{ marginRight: '3px' }}>{hostNamesString}</Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack>
                                <Stack horizontal style={{ marginTop: '5px' }}>
                                    <Text>{IvicosStrings.areaTitle}: </Text>
                                    <Text style={{ marginLeft: '3px' }}> {areaName[0]?.name}</Text>
                                </Stack>
                                <Stack horizontal style={{ marginTop: '5px' }}>
                                    <Text>{IvicosStrings.roomTitle}: </Text>
                                    <Text style={{ marginLeft: '3px' }}>{roomName && roomName}</Text>
                                </Stack>
                                <Stack horizontal style={{ marginTop: '5px' }}>
                                    <Text style={{ marginRight: '3px' }}>{IvicosStrings.hostsTitle}:</Text>
                                    <Stack horizontal>
                                        <Text style={{ marginRight: '3px' }}>{hostNamesString}</Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                        )}
                        {!isNewCreated && (
                            <Stack horizontal style={{ width: '100%', margin: '15px 0px' }} horizontalAlign="center" tokens={{ childrenGap: 16 }}>
                                <DefaultButton
                                    text={IvicosStrings.deleteActionTitle}
                                    iconProps={{ iconName: 'Delete' }}
                                    onClick={toggleHideDialog}
                                    aria-label="open-delete-modal-button"
                                />
                                <PrimaryButton
                                    aria-label="copy-invitation-link-button"
                                    text={copyButtonContent[buttonStatus]}
                                    iconProps={{ iconName: 'Copy' }}
                                    onClick={() => copyCopyButtonValueToClipboard(copyValue)}
                                />
                            </Stack>
                        )}
                    </>
                )}
                <Stack
                    style={{ width: '100%', cursor: 'pointer' }}
                    aria-label="expand-invitation-card-button"
                    horizontalAlign="center"
                    onClick={() => setCardIsOpen(!cardIsOpen)}
                >
                    {!cardIsOpen ? (
                        <IC variant={'dark'}>
                            <DownArrow />
                        </IC>
                    ) : (
                        !isNewCreated && (
                            <IC variant={'dark'}>
                                <UpArrow />
                            </IC>
                        )
                    )}
                </Stack>
            </Stack>
            <Stack id={buttonId} style={{ padding: '3px 0px' }}>
                {!cardIsOpen && (
                    <CopyButton
                        ariaLabel={'copy-invitation-link-icon'}
                        type="alternative-view"
                        value={
                            window.location.host.split(':')[0] === 'localhost'
                                ? `http://${window.location.host}/i/${invitationLinkValue}`
                                : `https://${window.location.host}/i/${invitationLinkValue}`
                        }
                    />
                )}
            </Stack>
            <Dialog
                data-test-id="delete-modal"
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <DefaultButton text={IvicosStrings.noAction} onClick={toggleHideDialog} aria-label="dismiss-delete-modal" />
                    <PrimaryButton text={IvicosStrings.yesAction} onClick={() => onDelete()} aria-label="delete-invitation-button" />
                </DialogFooter>
            </Dialog>
        </Stack>
    );
};
export default InvitationsCardView;
