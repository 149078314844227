import React from 'react';
import { DefaultEffects, getTheme, mergeStyles, PrimaryButton, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { ICallActionButtonProps } from './CallActionButton';
import { useIsVisitorAtLandingPage } from 'shared-state/location/hook';
import { useRecoilState } from 'recoil';
import { aIsAudioDeviceSetVisible } from 'routes/LandingPage/atom';
import IvicosStrings from 'kits/language/stringKit';

const CallActionButtonView: React.FC<ICallActionButtonProps> = ({ action, onPressed }) => {
    const theme = getTheme();

    const iconButtonStyles = mergeStyles({
        borderRadius: 4,
        padding: 8,
        position: 'relative'
    });
    const actionLabelStyles = mergeStyles([
        {
            backgroundColor: theme.palette.white,
            borderRadius: 4,
            pointerEvents: 'none',
            bottom: -16,
            width: 'auto',
            whiteSpace: 'nowrap',
            padding: '4px 8px',
            boxShadow: DefaultEffects.elevation16,
            right: 34,
            color: theme.palette.black
        }
    ]);
    const { smallDevice } = useWindowWidth();
    const [isVisitorAtLandingPage] = useIsVisitorAtLandingPage();
    const [, setIsAudioDeviceSetVisible] = useRecoilState<boolean>(aIsAudioDeviceSetVisible);

    const handleClick = (providedAction) => {
        const { key } = providedAction;
        if (key === 'device-settings' && isVisitorAtLandingPage) {
            setIsAudioDeviceSetVisible(false);
        }
        onPressed(providedAction);
    };

    return (
        <PrimaryButton
            className={iconButtonStyles + ' pressable--transparent'}
            key={action.key}
            onClick={() => handleClick(action)}
            styles={{ root: { minWidth: '40px', marginTop: 4, marginBottom: 4 } }}
            data-test-id={action.key + '-action'}
        >
            {isVisitorAtLandingPage ? (
                <Stack className={'hoverable'} horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 6 }}>
                    <IC variant={action.iconVariant || 'dark'} size={24}>
                        {action.icon}
                    </IC>
                    <Text variant="medium">{IvicosStrings.callActionButtonSelectBackground}</Text>
                </Stack>
            ) : (
                <Stack className={'hoverable'} verticalAlign="center" horizontalAlign="center" style={{ maxWidth: '40px' }}>
                    <IC variant={action.iconVariant || 'dark'} size={24}>
                        {action.icon}
                    </IC>
                    {!smallDevice && (
                        <Stack className={actionLabelStyles + ' display--on-parent-hover'} style={{ position: 'absolute' }}>
                            <Text>{action.title}</Text>
                        </Stack>
                    )}
                </Stack>
            )}
        </PrimaryButton>
    );
};

export default CallActionButtonView;
