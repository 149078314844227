import { DirectionalHint, mergeStyleSets } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useOrganizationBranding } from 'features/Header/hooks';
import { IvicosColors } from 'kits/colorKit';
import { useCurrentOrgId } from 'shared-state/location/hook';

interface IBasicListState {
    isCalloutVisible: boolean;
    styles: any;
    toggleIsCalloutVisible: any;
    buttonId: string;
    directionalHint: any;
    onBasicListChange: (ev: any, option: any) => void;
    backgroundColor?: string;
}

export const useBasicListState: (onChange: any) => IBasicListState = (onChange) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    const buttonId = useId('callout-button');
    const directionalHint = DirectionalHint.rightCenter;
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const onBasicListChange = (ev: any, item: any) => {
        onChange(item);
    };

    return {
        isCalloutVisible,
        styles,
        toggleIsCalloutVisible,
        buttonId,
        directionalHint,
        onBasicListChange,
        backgroundColor
    };
};
