import { Stack } from '@fluentui/react';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useMembersState } from './Members.state';
import MembersView from './Members.view';

const Members: React.FC = () => {
    const state = useMembersState();

    return (
        <Stack style={{ height: '100%' }}>
            {state._members.length === 0 ? (
                <LoadingDialog loadingStrings={[IvicosStrings.loadingStringMembersList]} view="small" typewriter={false} />
            ) : (
                <MembersView
                    {...{
                        ...state,
                        ...{
                            members: state._members,
                            microsoftProps: {
                                microsoftTenantIsInvited: state.microsoftProps.microsoftTenantIsInvited,
                                isMicrosoftUser: state.microsoftProps.isMicrosoftUser,
                                setMicrosoftTenantIsInvited: state.microsoftProps.setMicrosoftTenantIsInvited
                            }
                        }
                    }}
                />
            )}
        </Stack>
    );
};

export default Members;
