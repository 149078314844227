export const getSessionKeyForTokenType = (tokenType: string, tennantId: string): any => {
    return 'token:' + tokenType + ':' + tennantId;
};

export const getTokenFromSession = (tokenType: string, tennantId: string): any => {
    const tokenKey = getSessionKeyForTokenType(tokenType, tennantId);
    const token = sessionStorage.getItem(tokenKey);
    return token;
};

export const setTokenInSession = (tokenType: string, tennantId: string, newValue: string): any => {
    const tokenKey = getSessionKeyForTokenType(tokenType, tennantId);
    const token = sessionStorage.setItem(tokenKey, newValue);
    return token;
};
