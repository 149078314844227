import * as React from 'react';

function SvgStar(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg aria-label="star" width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M24.45 29L16 24.559 7.55 29l1.614-9.406-6.832-6.664 9.445-1.371L16 3l4.223 8.559 9.445 1.37-6.832 6.665L24.449 29zM16 22.469l5.988 3.148-1.144-6.672 4.847-4.722-6.695-.973L16 7.184l-2.992 6.066-6.7.973 4.848 4.722-1.144 6.672L16 22.47z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgStar;
