import { DefaultEffects, getTheme, Stack, Text, TooltipHost } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IC } from 'components/SVGIcon';
import MarkdownHost from 'features/MarkdownHost/MarkdownHost';
import {
    aControlValue,
    aDismissOverflow,
    aExpandNotification,
    aHideNotification,
    aIsUserOutOfCampus,
    aIsUserSwitchedArea,
    aNotificationSenderId,
    aShowSingleMessageNotification,
    aShowViewAll,
    aShowViewAllOfNotificationsOnly
} from 'features/NotificationsLayer/hooks/atoms';
import { Close, HideAll, ViewAll } from 'kits/IconKit2';
import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { CloseAllMessagesDialog } from './CloseAllMessagesDialog';
import { useDirectMessageUserList, useUserList } from 'shared-state/presence/hooks';
import IvicosStrings from 'kits/language/stringKit';
import { useWindowWidth } from 'shared-state/display-options/hooks';

interface INotificationsGroupedBannerView {
    closeMessageDialogValue: string;
    listOfMemos: any;
    getNotificationIcon: Function;
    title: string;
    description: string;
    typeOfMessage: string[];
    senderId: string;
    idOfMessages: any;
    children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationsGroupedBannerView: React.FC<INotificationsGroupedBannerView> = ({
    closeMessageDialogValue,
    listOfMemos,
    getNotificationIcon,
    description,
    typeOfMessage,
    senderId,
    idOfMessages
}) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [, addMessage] = useReceivedMessageInbox();
    const [showViewAll, setShowViewAll] = useRecoilState(aShowViewAll);
    const setHideNotification = useSetRecoilState(aHideNotification);
    const [, setNotificationSenderId] = useRecoilState(aNotificationSenderId);
    const setExpandNotification = useSetRecoilState(aExpandNotification);
    const setShowViewAllOfNotificationsOnly = useSetRecoilState(aShowViewAllOfNotificationsOnly);
    const [, setControlValue] = useRecoilState(aControlValue);
    const setDismissOverflow = useSetRecoilState(aDismissOverflow);
    const setShowSingleMessageNotification = useSetRecoilState(aShowSingleMessageNotification);
    const [, setIsUserSwitchedArea] = useRecoilState(aIsUserSwitchedArea);
    const [, setIsUserOutOfCampus] = useRecoilState(aIsUserOutOfCampus);
    const [users] = useUserList();
    const [directMessageUserList] = useDirectMessageUserList();
    const { smallDevice } = useWindowWidth();

    const viewAllMessages = (allData: any, senderId: string) => {
        setNotificationSenderId(senderId);
        setHideNotification(false);

        setDismissOverflow(true);
        setShowSingleMessageNotification(false);

        allData.forEach((data: any) => {
            displayReceivedMessages(data);
        });

        setShowViewAllOfNotificationsOnly(true);
        setControlValue(true);

        setTimeout(() => {
            setExpandNotification(true);
        }, 300);

        setTimeout(() => {
            setShowViewAll(false);
        }, 100);
    };

    const hideAllMessages = () => {
        setShowViewAllOfNotificationsOnly(false);
        setHideNotification(true);
        setShowViewAll(true);
        setNotificationSenderId('');
        setExpandNotification(false);
        setControlValue(false);
        setDismissOverflow(false);
    };

    const displayReceivedMessages = (data: any) => {
        addMessage({ ...data, announce: false });
    };

    //! This codes needs to more optimized when both of the messaging systems will be merged together
    useEffect(() => {
        if (
            (typeOfMessage.includes('memo') || typeOfMessage.includes('invite') || typeOfMessage.includes('knock')) &&
            !users.some((user) => user.uid === senderId)
        ) {
            setIsUserSwitchedArea((prevState) => ({ ...prevState, [senderId]: true }));
        } else {
            setIsUserSwitchedArea((prevState) => ({ ...prevState, [senderId]: false }));
        }
    }, [users, typeOfMessage, senderId]);

    useEffect(() => {
        if (
            (typeOfMessage.includes('cross-area-memo') || typeOfMessage.includes('cross-area-invite')) &&
            !directMessageUserList.some((user) => user.uid === senderId)
        ) {
            setIsUserOutOfCampus((prevState) => ({ ...prevState, [senderId]: true }));
        } else {
            setIsUserOutOfCampus((prevState) => ({ ...prevState, [senderId]: false }));
        }
    }, [directMessageUserList, typeOfMessage, senderId]);

    const theme = getTheme();

    return (
        <Stack
            style={{
                padding: '12px',
                background: '#fff',
                zIndex: 3000,
                color: '#000',
                wordBreak: 'break-word',
                borderRadius: '4px',
                border: `1px solid ${theme.palette.neutralLight}`
            }}
        >
            <Stack
                horizontal
                tokens={{ childrenGap: 16 }}
                style={{
                    maxHeight: description.length < 65 ? 50 : 70
                }}
            >
                <Stack
                    verticalAlign="center"
                    horizontalAlign="center"
                    style={{
                        width: 48
                    }}
                >
                    <Stack
                        style={{
                            marginLeft: typeOfMessage.includes('guest') ? 0 : 23
                        }}
                    >
                        {getNotificationIcon()}
                    </Stack>
                </Stack>

                <Stack grow verticalAlign="center">
                    <Text variant="medium" style={{ wordWrap: 'break-word', color: '#000' }}>
                        <MarkdownHost>{description}</MarkdownHost>
                    </Text>
                </Stack>
                <Stack horizontal horizontalAlign="center" verticalAlign="center" style={{ padding: '0px 10px' }} tokens={{ childrenGap: 8 }}>
                    {showViewAll ? (
                        <Stack
                            onClick={() => viewAllMessages(listOfMemos, senderId)}
                            className="pressable"
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={{
                                width: 28,
                                height: 28
                            }}
                        >
                            <TooltipHost content={smallDevice ? '' : IvicosStrings.viewAll}>
                                <IC variant={'custom'} size={24} iconColor="#000">
                                    <ViewAll />
                                </IC>
                            </TooltipHost>
                        </Stack>
                    ) : (
                        <Stack
                            className="pressable"
                            horizontalAlign="center"
                            verticalAlign="center"
                            onClick={hideAllMessages}
                            style={{
                                width: 28,
                                height: 28
                            }}
                        >
                            <TooltipHost content={smallDevice ? '' : IvicosStrings.hideAll}>
                                <IC variant={'custom'} size={24} iconColor="#000">
                                    <HideAll />
                                </IC>
                            </TooltipHost>
                        </Stack>
                    )}
                </Stack>
                <Stack
                    className="pressable"
                    onClick={toggleHideDialog}
                    horizontalAlign="center"
                    verticalAlign="center"
                    style={{
                        height: 24,
                        marginTop: 12
                    }}
                >
                    <TooltipHost content={smallDevice ? '' : IvicosStrings.closeAll}>
                        <IC size={24} variant="custom" iconColor="#000">
                            <Close />
                        </IC>
                    </TooltipHost>
                </Stack>
            </Stack>

            <CloseAllMessagesDialog
                hideDialog={hideDialog}
                toggleHideDialog={toggleHideDialog}
                closeMessageDialogValue={closeMessageDialogValue}
                listOfMemos={listOfMemos}
                idOfMessages={idOfMessages}
            />
        </Stack>
    );
};

export default NotificationsGroupedBannerView;
