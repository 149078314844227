import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { aAvailableDevicesInDeviceSettings, aCurrentDevicesInDeviceSettings, aIsSetAudioDeviceSettingsClicked, aUpdatedVisitorsDeviceSettings } from './atom';
import { IUpdatedVisitorsDeviceSettings } from 'routes/LandingPage/LandingPageSideCard';

export type IJitsiDeviceType = 'audioInput' | 'videoInput' | 'audioOutput';

export interface IJitsiDevice {
    deviceId: string;
    label: string;
    groupId: string;
    kind: string;
}

export interface IJitsiDeviceList {
    audioInput: Array<IJitsiDevice>;
    audioOutput: Array<IJitsiDevice>;
    videoInput: Array<IJitsiDevice>;
}
export interface IJitsiDeviceConfig {
    audioInput: IJitsiDevice | undefined;
    audioOutput: IJitsiDevice | undefined;
    videoInput: IJitsiDevice | undefined;
}

export interface IDeviceSettingsAtVisitorEntranceProps {
    jitsiAPI: any;
}

export const DeviceSettingsAtVisitorEntrance: React.FC<IDeviceSettingsAtVisitorEntranceProps> = ({ jitsiAPI }) => {
    const [, setAvailableDevices] = useRecoilState<IJitsiDeviceList>(aAvailableDevicesInDeviceSettings);
    const [, setCurrentDevices] = useRecoilState<IJitsiDeviceConfig>(aCurrentDevicesInDeviceSettings);
    const updatedVisitorsDeviceSettings = useRecoilValue<IUpdatedVisitorsDeviceSettings>(aUpdatedVisitorsDeviceSettings);
    const [isSetAudioDeviceSettingsClicked] = useRecoilState(aIsSetAudioDeviceSettingsClicked);
    const { isVisitorUpdate, visitorDevice, visitorDeviceType } = updatedVisitorsDeviceSettings ?? {};

    const updateCurrentDevices = () => {
        if (!jitsiAPI) return;

        jitsiAPI.getCurrentDevices().then((devices: IJitsiDeviceConfig) => {
            setCurrentDevices(devices);
        });
    };

    const updateDevices = () => {
        if (!jitsiAPI) return;

        updateCurrentDevices();
        jitsiAPI.getAvailableDevices().then((devices: IJitsiDeviceList) => setAvailableDevices(devices));
    };

    const onDeviceAvailabilityChanged = () => {
        updateDevices && updateDevices();
    };

    const onDeviceChange = (deviceType: IJitsiDeviceType, device: IJitsiDevice) => {
        if (jitsiAPI) {
            if (deviceType == 'audioInput') jitsiAPI.setAudioInputDevice(null, device.deviceId);
            if (deviceType == 'audioOutput') jitsiAPI.setAudioOutputDevice(null, device.deviceId);
            if (deviceType == 'videoInput') jitsiAPI.setVideoInputDevice(null, device.deviceId);
        }
        updateCurrentDevices();
    };

    useEffect(() => {
        if (jitsiAPI) {
            jitsiAPI.addEventListener('audioAvailabilityChanged', onDeviceAvailabilityChanged);
            jitsiAPI.addEventListener('videoAvailabilityChanged', onDeviceAvailabilityChanged);
        }

        updateDevices();

        return () => {
            if (jitsiAPI) {
                jitsiAPI.removeEventListener('audioAvailabilityChanged', onDeviceAvailabilityChanged);
                jitsiAPI.removeEventListener('videoAvailabilityChanged', onDeviceAvailabilityChanged);
            }
        };
    }, [jitsiAPI]);

    useEffect(() => {
        if (isVisitorUpdate) {
            onDeviceChange(visitorDeviceType, visitorDevice);
            setTimeout(() => {
                updateDevices();
            }, 1000);
        } else {
            return;
        }
    }, [isVisitorUpdate, visitorDevice, visitorDeviceType]);

    useEffect(() => {
        updateDevices();
    }, [isSetAudioDeviceSettingsClicked]);
    return <></>;
};
