import React from 'react';
import { useNotificationBannerState } from './NotificationsBanner.state';
import NotificationsBannerView from './NotificationsBanner.view';

interface INotificationsBannerProps {
    messageId: string;
    children?: React.ReactNode;
}

const NotificationBanner: React.FC<INotificationsBannerProps> = ({ children, messageId }) => {
    const state = useNotificationBannerState(messageId);

    return (
        <NotificationsBannerView
            {...{
                ...state
            }}
        >
            {children}
        </NotificationsBannerView>
    );
};

export default NotificationBanner;
