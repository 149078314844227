import { getTheme, IconButton, Stack, Text } from '@fluentui/react';
import { AttachmentDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import DraggableItem from 'components/DraggableItem/DraggableItem';
import { IC } from 'components/SVGIcon';
import { parseUrlTemplate } from 'features/Rooms/RoomView/addons';
import { DownArrow, UpArrow } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import drag from 'media/img/drag.svg';
import React, { useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { IProfile } from 'shared-state/identity/types';
import 'styles/helpers.css';
import { useYoutubeVideoAttachmentManager } from '../../YoutubeVideoAttachmentManager';

export interface AttachmentItemViewProps {
    item: AttachmentDto;
    index: any;
    campusId: string;
    areaId: string;
    roomId: string;
    setIsAttachmentPanelVisible: SetterOrUpdater<boolean>;
    setSelectedAttachment: SetterOrUpdater<
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined
    >;
    refetchAttachments: () => Promise<void>;
    setIsAttachmentUpdateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAttachmentToEdit: React.Dispatch<React.SetStateAction<AttachmentDto | undefined>>;
    addOn: (addOnName: string) =>
        | {
              id: string;
              type: string;
              attributes: {
                  urlTemplate: string;
                  iconUrl: string;
                  displayName: string;
                  description: string;
              };
              relationships: {};
          }
        | undefined;
    localProfile?: IProfile;
    onDelete: (attachmentId: any) => Promise<void>;
    jitsiApi: any;
}
const AttachmentItemView: React.FC<AttachmentItemViewProps> = ({
    item,
    index,
    roomId,
    setIsAttachmentPanelVisible,
    setSelectedAttachment,
    refetchAttachments,
    setIsAttachmentUpdateModalOpen,
    setAttachmentToEdit,
    addOn,
    localProfile,
    onDelete,
    jitsiApi
}) => {
    const [attachmentHovered, setAttachmentHovered] = useState(false);
    const [expandAttachment, setExpandAttachment] = useState(false);
    const translation = (attachmentName: string) => {
        if (attachmentName === 'Link') {
            return IvicosStrings.linkTypeTitle;
        } else if (attachmentName === 'Collaboard') {
            return IvicosStrings.collaboardTypeTitle;
        } else if (attachmentName === 'Etherpad') {
            return IvicosStrings.etherpadTypeTitle;
        } else if (attachmentName === 'Tldraw') {
            return IvicosStrings.tldrawTypeTitle;
        } else {
            return item.title;
        }
    };

    const handleMouseEnter = (id: any) => {
        setAttachmentHovered(item.id === id);
    };

    const handleMouseLeave = (id: any) => {
        item.id === id && attachmentHovered && setAttachmentHovered(false);
    };

    const handleDownArrowClick = (id: any) => {
        setExpandAttachment(item.id === id);
    };

    const handleUpArrowClick = (id: any) => {
        item.id === id && expandAttachment && setExpandAttachment(false);
    };

    const { playVideo, stopVideo, playingVideoId, isLocalUserVideoOwner } = useYoutubeVideoAttachmentManager(jitsiApi);
    const theme = getTheme();

    return (
        <DraggableItem key={item.id} draggableId={item.id} index={index}>
            <Stack style={{ marginBottom: 7, border: '0.5px solid black', borderRadius: '4px', width: '100%', backgroundColor: theme.palette.white }}>
                <Stack
                    className="pressable"
                    horizontal={false}
                    style={{ padding: 4, borderRadius: '4px', width: 'inherit' }}
                    tokens={{ childrenGap: 8 }}
                    onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={() => handleMouseLeave(item.id)}
                >
                    <Stack horizontalAlign="space-between" horizontal style={{ flexDirection: 'row', padding: '2px 0px', width: 'inherit' }}>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 8 }}
                            verticalAlign="center"
                            onClick={() => {
                                if (item.addOnName == 'youtubelinkmanager') {
                                    const isAnyVideoPlaying = playingVideoId && playingVideoId !== '';
                                    if (isAnyVideoPlaying && isLocalUserVideoOwner) stopVideo();
                                    else if (!isAnyVideoPlaying) playVideo(item.ref);
                                    return;
                                }
                                if (item.addOnName == 'linkmanager') {
                                    const url = parseUrlTemplate(addOn(item.addOnName), roomId || '', item.ref, localProfile?.displayName || '');
                                    window.open(url, '_blank');
                                    return;
                                }
                                item && setSelectedAttachment({ addOnId: item?.addOnName, ref: item?.ref, description: item?.description });
                                setIsAttachmentPanelVisible(false);
                                refetchAttachments();
                            }}
                        >
                            <Stack horizontal verticalAlign="start">
                                <Stack horizontal verticalAlign="center">
                                    {attachmentHovered ? (
                                        <Stack style={{ width: 14 }} horizontalAlign="start" verticalAlign="start">
                                            <img style={{ height: 19, cursor: 'grab' }} src={drag} />
                                        </Stack>
                                    ) : (
                                        <Stack style={{ width: 14 }}></Stack>
                                    )}
                                    <img height={34} src={addOn(item.addOnName)?.attributes.iconUrl || 'https://static-cdn.vercel.app/addon.svg'} />
                                </Stack>
                                <Stack horizontal verticalAlign="center" style={{ height: 34, marginLeft: 7 }}>
                                    <Text
                                        variant="medium"
                                        style={{
                                            whiteSpace: attachmentHovered ? 'nowrap' : 'normal',
                                            overflow: attachmentHovered ? 'hidden' : 'visible',
                                            inlineSize: attachmentHovered ? '120px' : '170px'
                                        }}
                                    >
                                        {item.title ? translation(item.title) : item.addOnName}
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack style={{ flexDirection: 'row' }}>
                            {attachmentHovered ? (
                                <Stack
                                    horizontal
                                    verticalAlign="center"
                                    style={{ border: '1px solid #A19F9D', borderRadius: '4px', position: 'relative', background: '#E4E4E3' }}
                                >
                                    <IconButton
                                        iconProps={{ iconName: 'edit' }}
                                        title={IvicosStrings.editActionTitle}
                                        onClick={() => {
                                            setIsAttachmentUpdateModalOpen(true);
                                            setAttachmentToEdit(item);
                                        }}
                                    />
                                    <IconButton
                                        iconProps={{ iconName: 'delete' }}
                                        title={IvicosStrings.deleteActionTitle}
                                        onClick={() => {
                                            onDelete(item.id);
                                        }}
                                    />
                                </Stack>
                            ) : null}
                        </Stack>
                    </Stack>
                    {item.description !== 'No description provided' && (
                        <Stack style={{ paddingBottom: 5, paddingLeft: 6 }}>
                            {expandAttachment ? (
                                <Stack
                                    style={{
                                        marginTop: 4,
                                        marginBottom: 4,
                                        paddingRight: 20,
                                        paddingLeft: 10,
                                        gap: 10
                                    }}
                                >
                                    {
                                        <Text variant="medium" style={{ marginTop: -8, fontStyle: 'italic' }}>
                                            {item.description === 'No description provided' ? IvicosStrings.noDescriptionProvidedWarning : item.description}
                                        </Text>
                                    }

                                    <Stack
                                        onClick={() => {
                                            handleUpArrowClick(item.id);
                                            handleMouseLeave(item.id);
                                        }}
                                    >
                                        <IC size={18} variant="dark">
                                            <UpArrow />
                                        </IC>
                                    </Stack>
                                </Stack>
                            ) : (
                                <Stack
                                    onClick={() => {
                                        handleDownArrowClick(item.id);
                                    }}
                                >
                                    <IC size={18} variant="dark">
                                        <DownArrow />
                                    </IC>
                                </Stack>
                            )}
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </DraggableItem>
    );
};

export default AttachmentItemView;
