/* eslint-disable @typescript-eslint/no-unused-vars */
import { useBoolean } from '@uifabric/react-hooks';
import { CampusDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import jwtDecode from 'jwt-decode';
import IconKit from 'kits/IconKit';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useRef, useState } from 'react';
import { Params, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useEvent } from 'react-use';
import { useRecoilState } from 'recoil';
import { idpService } from 'services/api/identity-provider.service';
import { resourceService } from 'services/api/resource.service';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId, useIsVisitorAtLandingPage } from 'shared-state/location/hook';
import { aIsYoutubeVideoVisible, aLandingPageClickedGoIn } from './atom';

export interface IWaitingAreaRouteParams extends Params {
    formattedOrgName: string;
    customPath: string;
    invitationId: string;
}

export interface IWaitingAreaState {
    headerActions: {
        title: string;
        symbol: any;
        action: (e: any) => void;
    }[];
    videntityClaims?: IIdentityClaims;
    currentAreaId?: string;
    campus?: CampusDto;
    customUrl: string;
    invitationId?: string;
    isTimerActive: boolean;
    setIsTimerActive: (isActive: boolean) => void;
    hourSeconds: number;
    timerProps: {
        isPlaying: boolean;
        size: number;
        strokeWidth: number;
    };
    shouldNotifyHosts: boolean;
    notifyHost: () => void;
    visitorName: string;
    eventName: string;
    customizedVideo: string;
    hideWhenHostJoin: boolean;
    orgId: string;
    isLoadingDialogVisible: string;
    setIsButtonHovered: React.Dispatch<React.SetStateAction<boolean>>;
    isButtonHovered: boolean;
    tokenPayload: any;
    welcomeMessage: string;
    isYoutubeVideoVisible: boolean;
    isVisitorWithHost: boolean;
    isSendMemoBtnVisible: boolean;
}

export const useWaitingAreaState: () => IWaitingAreaState = () => {
    const params = useParams<IWaitingAreaRouteParams>();
    const customUrl = `${params.formattedOrgName}/${params.customPath}`;
    const invitationId = params.invitationId;
    const routeHistory = useNavigate();
    const [, setCurrentRoomId] = useCurrentRoomId();
    const [currentAreaId, setCurrentAreaId] = useCurrentAreaId();
    const [currentCampusId, setCurrentCampusId] = useCurrentCampusId();
    const [videntityClaims, setVidentityClaims] = useIdentityClaims();
    const localProfile = useLocalProfile();

    const [orgId, setOrgId] = useState('');

    const [campus, setCampus] = useState<CampusDto | undefined>(undefined);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideWelcomeMessageDialog, { toggle: toggleWelcomeMessageHideDialog }] = useBoolean(false);

    const [isTimerActive, setIsTimerActive] = useState(false);
    const [visitorName, setVisitorName] = useState('');
    const [hostIds, setHostIds] = useState<string[]>([]);
    const [shouldNotifyHosts, setShouldNotifyHosts] = useState(false);
    const [eventName, setEventName] = useState('');
    const [customizedVideo, setCustomizedVideo] = useState('');
    const [hideWhenHostJoin, setHideWhenHostJoin] = useState(false);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const [isVisitorWithHost, setIsVisitorWithHost] = useState(false);
    const [isSendMemoBtnVisible, setIsSendMemoBtnVisible] = useState(false);
    const [tokenPayload, setTokenPayload] = useState<any>({});
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [landingPageClickedGoIn, setLandingPageClickedGoIn] = useRecoilState(aLandingPageClickedGoIn);
    const [isYoutubeVideoVisible] = useRecoilState(aIsYoutubeVideoVisible);
    const [, setIsVisitorAtLandingPage] = useIsVisitorAtLandingPage();

    useEvent('hostJoined', () => {
        setHideWhenHostJoin(true);
        setIsVisitorWithHost(true);
        setIsSendMemoBtnVisible(true);
    });

    useEvent('hostLeft', () => {
        setHideWhenHostJoin(false);
        setIsVisitorWithHost(false);
        setIsSendMemoBtnVisible(false);
    });

    const refetchCampus = async () => {
        if (!currentCampusId) return;
        const nextCampus = await resourceService.getCampusById(currentCampusId);
        setCampus(nextCampus);
    };

    localStorage.setItem('jitsiSessionId', localProfile?.uid || '');

    useEffect(() => {
        refetchCampus();
    }, [currentCampusId]);

    const redirectVisitor = invitationId ? invitationId : customUrl;
    const headerActions = [
        ...(videntityClaims?.uid
            ? [
                  {
                      title: IvicosStrings.signOutActionTitle,
                      symbol: IconKit.getIcon('Small', 'Exit'),
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      action: (e: any) => {
                          localStorage.clear();
                          routeHistory('/i/' + redirectVisitor);
                      }
                  }
              ]
            : []),
        ...[]
    ];

    const invitation = async () => {
        const res = await idpService.showInvitation(customUrl, invitationId);
        setOrgId(res.orgId);
        setEventName(res.event_name);
        setCustomizedVideo(res.customized_video_url);
        setWelcomeMessage(res.welcome_message);
    };

    useEffect(() => {
        const token = localStorage.getItem('ivAccessToken');
        if (!token) return;
        const payload = jwtDecode<any>(token);
        setTokenPayload(payload);
        setCurrentAreaId(payload.areaId);
        setCurrentCampusId(payload.campusId);
        setVisitorName(payload.name);
        setHostIds(payload.invited_by);
        const identity: IIdentityClaims = {
            uid: payload.sub.split(':')[2],
            displayName: payload.name,
            roles: [payload.role],
            type: 'visitor',
            tid: payload.orgId,
            upn: payload.email,
            areas: ['*'],
            idp: 'ivicos'
        };
        setVidentityClaims(identity);
        setCurrentAreaId('waiting-area');
        invitation();
        setIsVisitorAtLandingPage(window.location.pathname.includes('/visitorLandingPage'));
    }, []);

    useEffect(() => {
        videntityClaims && setCurrentRoomId('visitor-' + videntityClaims.uid);
    }, [videntityClaims]);

    useEffect(() => {
        setTimeout(() => {
            landingPageClickedGoIn && notifyHost();
        }, 1000);
    }, [landingPageClickedGoIn]);

    const visitorIdToken: string | null = localStorage.getItem('ivAccessToken');

    const [minute, setMinuter] = useState(3);
    const funRef: any = useRef(null);
    const hourSeconds = 180;

    useEffect(() => {
        if (minute !== 0) {
            funRef.current = setTimeout(() => {
                setMinuter(minute - 1);
            }, 60000);
        } else {
            clearTimeout(funRef.current);
        }
    });

    useEffect(() => {
        if (hideWhenHostJoin == false) isTimerActive == false ? resetNotifyHost() : null;
    }, [isTimerActive]);

    const timerProps = {
        isPlaying: true,
        size: 120,
        strokeWidth: 6
    };

    const timerPropsResponsive = {
        isPlaying: true,
        size: 60,
        strokeWidth: 6
    };

    const StartTimer = () => {
        setIsTimerActive(true);
        toggleHideDialog();
    };

    const notifyHost = () => {
        StartTimer();
        setShouldNotifyHosts(true);
    };

    const resetNotifyHost = () => {
        setShouldNotifyHosts(false);
        setLandingPageClickedGoIn(false);
    };

    const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState<string>('true');

    const clean = () => {
        setTimeout(() => {
            setIsLoadingDialogVisible('false');
        }, 1000);
    };

    clean();

    sessionStorage.setItem('showLoadingAnimation', isLoadingDialogVisible);

    return {
        headerActions,
        videntityClaims,
        campus,
        customUrl,
        invitationId,
        isTimerActive,
        setIsTimerActive,
        hourSeconds,
        timerProps,
        notifyHost,
        visitorName,
        eventName,
        customizedVideo,
        shouldNotifyHosts,
        hideWhenHostJoin,
        orgId,
        setIsButtonHovered,
        isButtonHovered,
        tokenPayload,
        welcomeMessage,
        isYoutubeVideoVisible,
        isVisitorWithHost,
        isLoadingDialogVisible,
        currentAreaId,
        isSendMemoBtnVisible
    };
};
