/* eslint-disable react/react-in-jsx-scope */
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import IvicosStrings from 'kits/language/stringKit';
import { useVisitorsEntryState } from './VisitorsEntry.state';
import VisitorsEntryView from './VisitorsEntry.view';

const VisitorsEntry = (): any => {
    const state = useVisitorsEntryState();

    return (
        <>
            {state.isLoadingDialogVisible && <LoadingDialog loadingStrings={[IvicosStrings.loadingStringInvitation]} view="full" typewriter={false} />}
            {state.orgId ? (
                <VisitorsEntryView
                    {...{
                        ...state
                    }}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default VisitorsEntry;
