import { Callout, ChoiceGroup, DelayedRender, IChoiceGroupOption, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { QuestionCircle } from 'kits/IconKit2';
import React from 'react';

interface IChoiceGroupViewProps {
    isCalloutVisible: boolean;
    styles: any;
    toggleIsCalloutVisible: any;
    buttonId: string;
    directionalHint: any;
    onChoiceGroupChange: (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption) => void;
    label?: string;
    options: any;
    defaultSelectedKey: any;
    callout?: string;
    disabled?: boolean;
}

const ChoiceGroupView: React.FC<IChoiceGroupViewProps> = (props) => {
    const {
        isCalloutVisible,
        styles,
        toggleIsCalloutVisible,
        buttonId,
        directionalHint,
        onChoiceGroupChange,
        label,
        options,
        defaultSelectedKey,
        callout,
        disabled
    } = props;

    return (
        <Stack horizontal horizontalAlign="baseline">
            <ChoiceGroup
                label={label}
                options={options}
                defaultSelectedKey={defaultSelectedKey}
                onChange={(ev: any, option: any) => onChoiceGroupChange(ev, option)}
                disabled={disabled}
            ></ChoiceGroup>
            {callout != undefined ? (
                <IC variant="custom" size={15} style={{ cursor: 'pointer', marginBottom: 70, marginLeft: 10 }}>
                    <QuestionCircle onClick={toggleIsCalloutVisible} id={buttonId} />
                </IC>
            ) : null}
            {isCalloutVisible && (
                <Callout
                    className={styles.callout}
                    onDismiss={toggleIsCalloutVisible}
                    role="status"
                    target={`#${buttonId}`}
                    aria-live="assertive"
                    directionalHint={directionalHint}
                >
                    <DelayedRender>
                        <Text variant="small">{callout}</Text>
                    </DelayedRender>
                </Callout>
            )}
        </Stack>
    );
};

export default ChoiceGroupView;
