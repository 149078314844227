import { Checkbox, ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, PrimaryButton, Stack, Text, Label } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { Close } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useBoolean } from 'react-use';
import { IPersonalPreferencesState } from './PersonalPreferences.State';

const PersonalPreferencesView: React.FC<IPersonalPreferencesState> = (props) => {
    const {
        currentDeviceSettings,
        toggleDeviceSettings,
        hideErrorDialog,
        toggleHideErrorDialog,
        colorButtonId,
        switchLanguage,
        hideDialog,
        toggleHideDialog,
        language,
        setLanguage,
        showLanguage,
        openDialogBox
    } = props;

    const [isDraggable] = useBoolean(false);

    const dialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.languageSelectionActionTitle,
        closeButtonAriaLabel: 'Close',
        subText: IvicosStrings.languageSelectionDescription
    };

    const modalPropsStyles = { main: { maxWidth: 450 } };

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined
        }),
        [isDraggable]
    );

    return (
        <>
            <Stack tokens={{ childrenGap: 15 }}>
                <Stack style={{ padding: '5px 5px 5px 0px' }}>
                    <Dropdown
                        aria-label="Select-language"
                        label={IvicosStrings.languageSelectionLabel}
                        placeholder={showLanguage()}
                        options={[
                            { key: 'en', text: 'English', data: 'en' },
                            { key: 'de', text: 'Deutsch', data: 'de' }
                        ]}
                        onChange={(e, i) => {
                            openDialogBox(i && i.data);
                            // toggleHideDialog();
                            setLanguage(i && i.data);
                        }}
                        selectedKey={showLanguage()}
                    ></Dropdown>
                </Stack>
                <Stack>
                    <Label
                        style={{
                            padding: '5px 5px 5px 0px'
                        }}
                    >
                        {IvicosStrings.communicationSettings}
                    </Label>
                    <Stack
                        horizontal
                        style={{
                            padding: '5px 5px 5px 0px'
                        }}
                    >
                        <Checkbox
                            checked={currentDeviceSettings.cameraDisabled}
                            onChange={() => {
                                toggleDeviceSettings('camera');
                            }}
                        />
                        <Text variant="medium" style={{ padding: '0vh 1vh' }}>
                            {IvicosStrings.cameraPreferenceActionTitle}
                        </Text>
                    </Stack>
                    <Stack
                        horizontal
                        style={{
                            padding: '5px 5px 5px 0px'
                        }}
                    >
                        <Checkbox
                            checked={currentDeviceSettings.microphoneDisabled}
                            onChange={() => {
                                toggleDeviceSettings('mic');
                            }}
                        />
                        <Text variant="medium" style={{ padding: '0vh 1vh' }}>
                            {IvicosStrings.microphonePreferenceActionTitle}
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
            <Dialog
                hidden={hideErrorDialog}
                onDismiss={() => {
                    toggleHideErrorDialog();
                }}
            >
                <Stack>
                    <Stack style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: '-30px' }}>
                        <IC size={20} variant="red">
                            <Close id={colorButtonId} />
                        </IC>

                        <Text variant="xLarge" style={{ marginLeft: 10 }}>
                            {IvicosStrings.errorOccurred}
                        </Text>
                    </Stack>

                    <Text style={{ marginLeft: 30, marginTop: 10, marginRight: 0, textAlign: 'justify' }}>{IvicosStrings.cameraSettingErrorMessage}</Text>
                </Stack>

                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            toggleHideErrorDialog();
                            window.location.reload();
                        }}
                        text={IvicosStrings.closeActionTitle}
                    />
                </DialogFooter>
            </Dialog>
            <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <DefaultButton
                        text={IvicosStrings.noAction}
                        onClick={() => {
                            toggleHideDialog();
                        }}
                        aria-label="cancel-change-language-button"
                    />
                    <PrimaryButton
                        text={IvicosStrings.yesAction}
                        onClick={() => {
                            switchLanguage(language);
                        }}
                        aria-label="change-language-button"
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default PersonalPreferencesView;
