import { useEffect, useState } from 'react';
import { useDirectMessageUserList } from 'shared-state/presence/hooks';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useAreaList, useCurrentOrgBranding } from 'shared-state/directory/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCrossAreaSearchBarInputValue, useIsCrossAreaUsersVisible } from 'shared-state/display-options/hooks';
import { IProfile } from 'shared-state/identity/types';
import { getTextColorForBackground } from 'kits/colorKit';
import { ICrossUserProfile, IDirectMessagingState } from '../dataTypes';

export const useDirectMessagingState: () => IDirectMessagingState = () => {
    const [expandedIcons, setExpandedIcons] = useState({
        isThisAreaIconExpanded: true,
        isOtherAreaIconExpanded: true,
        isDashboardIconExpanded: true
    });
    const [directMessageUserList] = useDirectMessageUserList();
    const [currentAreaId] = useCurrentAreaId();
    const [currentRoomId] = useCurrentRoomId();
    const [areas] = useAreaList();
    const [expandedAreas, setExpandedAreas] = useState<string[]>([]);
    const localProfile: IProfile | undefined = useLocalProfile();
    const [crossAreaSearchBarInputValue] = useCrossAreaSearchBarInputValue();
    const [orgBranding] = useCurrentOrgBranding();
    const textColor: string =
        orgBranding && orgBranding.orgColors && orgBranding.orgColors.length > 0 ? getTextColorForBackground(orgBranding.orgColors[0]) : '#fff';
    const [, setIsCrossAreaUsersVisible] = useIsCrossAreaUsersVisible();

    const usersOutsideOfAnArea = directMessageUserList.filter(
        (user) => user.uid !== localProfile?.uid && user.campusId === localProfile?.campusId && !user.userInThisArea
    );
    const usersInsideOfAnArea = directMessageUserList.filter(
        (user) => user.uid !== localProfile?.uid && user.campusId === localProfile?.campusId && user.userInThisArea
    );
    const usersInCurrentArea =
        currentAreaId && usersInsideOfAnArea.filter((user) => user.userInThisArea === currentAreaId && user.campusId === localProfile?.campusId);
    const usersInOtherAreas =
        currentAreaId && usersInsideOfAnArea.filter((user) => user.userInThisArea !== currentAreaId && user.campusId === localProfile?.campusId);

    const [filteredUsers, setFilteredUsers] = useState<{
        outsideOfAnArea: ICrossUserProfile[];
        insideOfAnArea: ICrossUserProfile[];
        inCurrentArea: ICrossUserProfile[];
        inOtherAreas: ICrossUserProfile[];
    }>({
        outsideOfAnArea: [],
        insideOfAnArea: [],
        inCurrentArea: [],
        inOtherAreas: []
    });

    const handleToggleAreas = (areaId: string) => {
        setExpandedAreas((prevState) => (prevState.includes(areaId) ? prevState.filter((id) => id !== areaId) : [...prevState, areaId]));
    };

    const handleToggle = (key: string) => {
        setExpandedIcons((prevState) => ({ ...prevState, [key]: !prevState[key] }));
    };

    const handleToggleThisArea = () => handleToggle('isThisAreaIconExpanded');
    const handleToggleOtherArea = () => handleToggle('isOtherAreaIconExpanded');
    const handleToggleDashboard = () => handleToggle('isDashboardIconExpanded');
    const getIconName = (key: string) => (expandedIcons[key] ? 'ChevronDownMed' : 'ChevronRightMed');

    const iconNameForThisArea = getIconName('isThisAreaIconExpanded');
    const iconNameForOtherArea = getIconName('isOtherAreaIconExpanded');
    const iconNameForDashboard = getIconName('isDashboardIconExpanded');

    const filterCrossAreaUsers = (searchInput: string) => {
        if (searchInput.length > 0) {
            const handleSpecialCharacters = searchInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regex = new RegExp(handleSpecialCharacters.toLowerCase());

            const filterUsers = (users: ICrossUserProfile[]) => users?.filter((user) => regex.test(user.displayName.toLowerCase()));

            setFilteredUsers({
                outsideOfAnArea: filterUsers(usersOutsideOfAnArea),
                insideOfAnArea: filterUsers(usersInsideOfAnArea),
                inCurrentArea: filterUsers(usersInCurrentArea),
                inOtherAreas: filterUsers(usersInOtherAreas)
            });
        } else {
            setFilteredUsers({
                outsideOfAnArea: [],
                insideOfAnArea: [],
                inCurrentArea: [],
                inOtherAreas: []
            });
        }
    };

    const isNotFilteredArrayEmpty = (arr) => arr?.length > 0;

    useEffect(() => {
        filterCrossAreaUsers(crossAreaSearchBarInputValue);
    }, [crossAreaSearchBarInputValue, directMessageUserList]);

    return {
        expandedIcons,
        handleToggleThisArea,
        handleToggleOtherArea,
        handleToggleDashboard,
        iconNameForThisArea,
        iconNameForOtherArea,
        iconNameForDashboard,
        currentAreaId,
        areas,
        expandedAreas,
        handleToggleAreas,
        filteredUsers,
        isNotFilteredArrayEmpty,
        textColor,
        crossAreaSearchBarInputValue,
        setIsCrossAreaUsersVisible,
        currentRoomId
    };
};
