import { Panel, PanelType, Stack } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import ImportCSVStep from './children/ImportCSVStep/ImportCSVStep';
import InviteStep from './children/InviteStep/InviteStep';
import ReviewDataStep from './children/ReviewDataStep/ReviewDataStep';

export type IFileUploadHandler = (file: File) => void;

export type ICSVData = { body: string[][]; headline: string[]; setBody: (value: React.SetStateAction<string[][]>) => void };

const PowerUserImporterView: React.FC<{
    isOpen?: boolean;
    step?: number;
    onFileUpload?: IFileUploadHandler;
    onFinish?: Function;
    onDismiss?: Function;
    data?: ICSVData;
    declineData?: Function;
    acceptData?: Function;
    invitationStatus?: number[];
}> = ({ isOpen, step = 0, onFileUpload, onFinish, onDismiss, data, declineData, acceptData, invitationStatus }) => {
    const stepTitles: any = {
        0: IvicosStrings.bulkInviteActionTitle,
        1: IvicosStrings.ImportCSVFileTitle2,
        2: IvicosStrings.ImportCSVFileTitle3
    };

    return (
        <Panel isOpen={isOpen} type={PanelType.medium} onDismiss={() => onDismiss && onDismiss()} headerText={stepTitles[step]}>
            <Stack tokens={{ childrenGap: 32 }}>
                {step == 0 && <ImportCSVStep onFileUpload={(file) => onFileUpload && onFileUpload(file)} />}
                {step == 1 && <ReviewDataStep data={data} declineData={() => declineData && declineData()} acceptData={() => acceptData && acceptData()} />}
                {step == 2 && <InviteStep data={data} invitationStatus={invitationStatus || [0]} onFinish={() => onFinish && onFinish()} />}
            </Stack>
        </Panel>
    );
};

export default PowerUserImporterView;
