import { ICallActionKey } from 'features/Rooms/RoomView/CallActions';
import { useJitsiToken } from 'kits/apiKit3/jitsi';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { sendEvent } from 'kits/eventKit';
import { useEffect, useState } from 'react';
import { useArea, useRoom } from 'shared-state/directory/hooks';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import 'styles/helpers.css';
import { getClassNames } from 'styles/responsiveness';
import useCampusMembersManager from 'features/Rooms/RoomList/RoomSettings/campusMembersManager';

export interface IRoomViewState {
    jwt: string | undefined;
    room: string | undefined;
    domain: string | undefined;
    smallDevice: boolean;
    innerBurgerMenu: string;
    campusNameSmallDevice: string;
    currentRoom: IRoomResource | undefined;
    title: string;
    callActions: ICallActionKey[][];
    pimg: string | undefined;
    showSideBySide: boolean;
    selectedTool: string;
    identity: IIdentityClaims | undefined;
    noVideoRoom: string | null;
    userIsAloneInOwnPersonalRoom: boolean;
}

export const useRoomViewState: () => IRoomViewState = () => {
    const [roomId] = useCurrentRoomId();
    const [identity] = useIdentityClaims();
    const [currentRoom] = useRoom(roomId || '');
    const [jwt, room, domain] = useJitsiToken();
    const localProfile = useLocalProfile();
    const pimg = localProfile?.profileImage;

    const [selectedTool, setSelectedTool] = useState('jitsi');
    const [showSideBySide] = useState(false);

    const usersInRoom = useUsersInRoom(roomId);

    const [userIsAloneInOwnPersonalRoom, setUserIsAloneInOwnPersonalRoom] = useState<boolean>(roomId.includes('personal-') && usersInRoom.length < 2);
    useCampusMembersManager();

    useEffect(() => {
        setUserIsAloneInOwnPersonalRoom(roomId.includes('personal-') && usersInRoom.length < 2);
    }, [roomId, usersInRoom]);

    useEffect(() => {
        setSelectedTool('jitsi');
    }, [roomId]);

    const isPersonalRoom = roomId?.includes('personal');
    const isAloneInRoom = isPersonalRoom && userIsAloneInOwnPersonalRoom;

    const callActions: ICallActionKey[][] = [
        ['device-settings', 'toggle-view'],
        currentRoom?.attributes?.audioOnly ? ['toggle-audio'] : ['toggle-video', 'toggle-audio'],
        isPersonalRoom ? (isAloneInRoom ? [] : ['share-screen']) : ['share-screen', 'toggle-chat', 'toggle-attachments', 'raise-hand', 'toggle-stats'],
        window.location.pathname.includes('rooms') ? ['leave-room'] : []
    ];
    const { smallDevice } = useWindowWidth();
    const [areaId] = useCurrentAreaId();
    const [area] = useArea(areaId);
    const title = area?.name || 'Area';
    const idValue = window.location.pathname.split('rooms/')[1];
    const noVideoRoom: string | null = sessionStorage.getItem(`noVideo-${idValue}`);
    const { innerBurgerMenu, campusNameSmallDevice } = getClassNames();

    // storing the roomId in sessionStorage for older no-video rooms
    if (currentRoom?.attributes.audioOnly && !noVideoRoom) {
        sessionStorage.setItem(`noVideo-${idValue}`, idValue);
        sendEvent('disable-camera', {});
    }

    // // removing roomIds from localStorage
    // if (noVideoRoom && !currentRoom?.attributes.audioOnly) {
    //     sessionStorage.removeItem(`noVideo-${idValue}`);
    // }

    // removing undefined roomIds from sessionStorage
    if (sessionStorage.getItem('noVideo-undefined')) {
        sessionStorage.removeItem('noVideo-undefined');
    }

    return {
        jwt,
        room,
        domain,
        smallDevice,
        innerBurgerMenu,
        campusNameSmallDevice,
        currentRoom,
        title,
        callActions,
        pimg,
        showSideBySide,
        selectedTool,
        identity,
        noVideoRoom,
        userIsAloneInOwnPersonalRoom
    };
};
