import 'index.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactElement, useEffect, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import App from './App';
import * as serviceWorker from './serviceWorker';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DebugObserver(): ReactElement {
    const snapshot = useRecoilSnapshot();
    useEffect(() => {
        console.debug('The following atoms were modified:');
        for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
            console.log('[OBS]: ', node.key, snapshot.getInfo_UNSTABLE(node));
        }
    }, [snapshot]);

    return <></>;
}
// Since we upgraded to React 18, here is a list of changes: https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <StrictMode>
        <RecoilRoot>
            <App />
        </RecoilRoot>
    </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
