import { Steps } from 'intro.js-react';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';

const CampusSettingsIntro: React.FC = () => {
    const [stepsEnabled, setStepsEnabled] = useState<boolean>();

    useEffect(() => {
        if (localStorage.getItem('intro-campus-settings') === 'false') {
            setStepsEnabled(false);
        } else if (localStorage.getItem('intro-campus-settings') === 'true') {
            setStepsEnabled(true);
        }
    }, []);

    const stepsSettings = {
        stepsEnabled: true,
        initialStep: 0,
        steps: [
            {
                element: '.profile-picture',
                intro: IvicosStrings.campusSettingIntroStep1,
                tooltipClass: 'introjs-tooltip-dude'
            },
            {
                element: '.display-settings',
                intro: IvicosStrings.campusSettingIntroStep2,
                position: 'right'
            }
        ],
        hintsEnabled: false
    };

    const options = {
        doneLabel: 'Ok!',
        nextLabel: IvicosStrings.nextActionTitle2,
        prevLabel: IvicosStrings.back,
        stepNumbersOfLabel: IvicosStrings.of,
        hideNext: false,
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true
    };

    return (
        <Steps
            enabled={stepsEnabled}
            steps={stepsSettings.steps}
            initialStep={stepsSettings.initialStep}
            options={options}
            onExit={() => setStepsEnabled(false)}
            onStart={() => {
                localStorage.setItem('intro-campus-settings', 'false');
            }}
        />
    );
};

export default CampusSettingsIntro;
