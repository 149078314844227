import React from 'react';
import { useRoomListState } from './RoomList.state';
import RoomListView from './RoomList.view';

const RoomList: React.FC<{}> = () => {
    const state = useRoomListState();

    return <RoomListView {...state} />;
};
export default RoomList;
