/* eslint-disable react/display-name */
import { HoverCard, HoverCardType } from '@fluentui/react';
import React from 'react';
import { IHoverCardHostProps } from './HoverCardHost';

const HoverCardHostView: React.FC<IHoverCardHostProps> = (props) => {
    const { children, content, disabled } = props;

    if (disabled) return <>{children}</>;

    return (
        <HoverCard
            type={HoverCardType.plain}
            trapFocus={false}
            instantOpenOnClick
            cardOpenDelay={250}
            plainCardProps={{ onRenderPlainCard: () => <>{content}</> }}
        >
            {children}
        </HoverCard>
    );
};

export default HoverCardHostView;
