import { DefaultEffects, IconButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import SvgCheckbox from 'kits/IconKit2/Checkbox';
import SvgCheckboxEmpty from 'kits/IconKit2/CheckboxEmpty';
import SvgInfoBubble from 'kits/IconKit2/InfoBubble';
import SvgLink from 'kits/IconKit2/Link';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { Link } from 'react-router-dom';
import { ISetupAssistantState } from './SetupAssistant.state';

const LinkIcon: React.FC<{ href: string }> = ({ href }) => {
    return (
        <Link to={href} rel="noreferrer" style={{ top: -4, position: 'relative' }}>
            <IC inline size={18} variant="primary">
                <SvgLink />
            </IC>
        </Link>
    );
};

const SetupAssistantView: React.FC<ISetupAssistantState> = (props) => {
    const { theme, shouldBeVisible, setWasDismissed, setVisibilityOverride, isCompleted, infoLabels, thingsToDo, completedTodos } = props;
    return (
        <>
            {shouldBeVisible && (
                <div
                    style={{
                        position: 'fixed',
                        padding: 16,
                        maxWidth: 256,
                        bottom: 64,
                        right: 64,
                        zIndex: 2000,
                        background: theme.semanticColors.infoBackground,
                        boxShadow: DefaultEffects.elevation64
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        <span style={{ position: 'absolute', float: 'left', marginTop: 0, marginRight: 0, zIndex: 2002, right: 0, top: 0 }}>
                            <IconButton
                                iconProps={{ iconName: 'Clear' }}
                                onClick={() => {
                                    setWasDismissed(true);
                                    setVisibilityOverride(false);
                                }}
                            />
                        </span>
                        <Stack tokens={{ childrenGap: 32 }}>
                            <Stack tokens={{ childrenGap: 16 }}>
                                <Stack horizontal horizontalAlign="start">
                                    <IC variant="primary" size={64}>
                                        {isCompleted ? <SvgCheckbox /> : <SvgInfoBubble />}
                                    </IC>
                                </Stack>
                                <Text variant="xLargePlus">{infoLabels[isCompleted ? 'completed' : 'open'].primary}</Text>
                                <Text variant="medium">{infoLabels[isCompleted ? 'completed' : 'open'].secondary}</Text>
                            </Stack>

                            {!isCompleted && (
                                <Stack tokens={{ childrenGap: 8 }}>
                                    {thingsToDo.map((thing) => (
                                        <Stack key={thing.key} horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                            <IC variant="primary">{thing.done ? <SvgCheckbox /> : <SvgCheckboxEmpty />}</IC>
                                            <Text>
                                                {thing.label} {!thing.done && thing.path && <LinkIcon href={thing.path} />}
                                            </Text>
                                        </Stack>
                                    ))}
                                </Stack>
                            )}
                            <Stack tokens={{ childrenGap: 8 }}>
                                <Text variant="small">
                                    {thingsToDo.filter((a) => a.done).length}/{thingsToDo.length} {IvicosStrings.completeLabel}
                                </Text>
                                <Stack
                                    horizontal
                                    horizontalAlign="start"
                                    style={{ background: theme.palette.neutralTertiary, minHeight: 4, height: 4, borderRadius: 1, overflow: 'hidden' }}
                                >
                                    <Stack
                                        style={{ background: theme.palette.themePrimary, width: `${(completedTodos.length / thingsToDo.length) * 100 + 1}%` }}
                                    ></Stack>
                                </Stack>
                            </Stack>
                            {isCompleted && (
                                <Stack horizontal horizontalAlign="end">
                                    <PrimaryButton
                                        text={IvicosStrings.dismissActionTitle}
                                        onClick={() => {
                                            setWasDismissed(true);
                                            setVisibilityOverride(false);
                                        }}
                                    />
                                </Stack>
                            )}
                        </Stack>
                    </div>
                </div>
            )}
        </>
    );
};

export default SetupAssistantView;
