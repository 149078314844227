import { RoomDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { roomDTOtoResource } from 'kits/apiKit3/legacy';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { resourceService } from 'services/api/resource.service';
import { useAreaList, useCampus, useRoomList, useSelectedVisitor } from 'shared-state/directory/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentCampusId, useCurrentOrgId, useCurrentRoomId } from 'shared-state/location/hook';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ResourceAdapter: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const params = useParams<{ areaId?: string; roomId?: string }>();

    const [identityClaims] = useIdentityClaims();
    const [currentOrgId] = useCurrentOrgId();
    const [currentCampusId, setCurrentCampusId] = useCurrentCampusId();
    const [currentAreaId, setCurrentAreaId] = useCurrentAreaId();
    const [, setCurrentRoomId] = useCurrentRoomId();

    const routeHistory = useNavigate();
    const [, setSelectedVisitor] = useSelectedVisitor();

    const [, setCampus] = useCampus();
    const [, setAreaList] = useAreaList();
    //Currently legacy format
    const [, setRoomList] = useRoomList();
    const fetchCampus = async () => {
        if (!currentOrgId || currentOrgId != identityClaims?.tid) return console.warn('Not able to fetch campus', currentOrgId, identityClaims?.tid);
        const campusDTO = await resourceService.getCampusById(currentOrgId);

        if (!campusDTO) return; // Error @TODO handle

        setCampus(campusDTO);
        setCurrentCampusId(campusDTO.id);
    };

    const fetchAreas = async () => {
        if (!currentCampusId) {
            setAreaList([]);
            return console.warn('Not able to fetch areas');
        }
        const areaDTOs = await resourceService.getAllForCampus(currentCampusId);

        if (!areaDTOs) return; // Error @TODO handle

        setAreaList(areaDTOs);
    };

    const fetchRooms = async () => {
        if (!currentAreaId || !currentCampusId || currentAreaId == 'waiting-area') {
            setRoomList([]);
            return console.warn('Not able to fetch Rooms');
        }
        const roomDTOs: any = await resourceService.getAllForArea(currentCampusId, currentAreaId);

        if (!roomDTOs) return;

        /* Backwards comp */
        const roomResources = roomDTOs.map((room: RoomDto) => roomDTOtoResource(room));
        setRoomList(roomResources);
    };

    const handleError = (err: any) => {
        let loginRequired = false;
        console.error('[RESOURCE ADAPTER]', err.message);

        if (err.message.includes('401')) loginRequired = true;
        if (err.message.includes('404')) loginRequired = true;

        if (loginRequired) routeHistory('/login');
    };

    useEffect(() => {
        fetchCampus().catch((err: any) => {
            handleError(err);

            // Retry later
            setTimeout(() => {
                fetchCampus().catch((err: any) => handleError(err));
            }, 1000);
        });
    }, [currentOrgId]);

    useEffect(() => {
        fetchAreas()
            .then(() => {
                currentAreaId && fetchRooms();
            })
            .catch((err: any) => {
                handleError(err);
            });
    }, [currentCampusId]);

    useEffect(() => {
        fetchRooms().catch((err: any) => {
            handleError(err);
        });
    }, [currentAreaId]);

    useEffect(() => {
        params.areaId && localStorage.setItem('lastAreaId', params.areaId);
        setCurrentAreaId(params.areaId);
    }, [params.areaId]);

    useEffect(() => {
        const personalRoomName = 'personal-' + identityClaims?.uid + '_' + params.areaId;
        const nextRoomId = params.roomId ? params.roomId : personalRoomName || undefined;
        if (params.roomId?.includes('visitor-')) setSelectedVisitor(params.roomId.replace('visitor-', ''));
        else setSelectedVisitor(undefined);

        setCurrentRoomId(nextRoomId);
    }, [params.roomId, currentAreaId]);

    return <></>;
};
