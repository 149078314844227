/* eslint-disable react/display-name */
import React from 'react';
import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import { DoorOpen, HandKnock, Memo, Settings } from 'kits/IconKit2';
import { useNavigate } from 'react-router';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import { useRoomSettingsRoomId } from 'shared-state/display-options/hooks';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { useRoom } from 'shared-state/directory/hooks';
import IvicosStrings from 'kits/language/stringKit';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';
export const useRoomActions = (roomId: string): IRoomAction[] => {
    const { knock } = useEmittingEvents();
    const routeHistory = useNavigate();
    const [, setRoomSettingsRoomId] = useRoomSettingsRoomId();
    const [identityClaims] = useIdentityClaims();
    const localProfile = useLocalProfile();

    const usersInRoom = useUsersInRoom(roomId);
    const [, setMemoComposerUsersIds] = useMessageComposerUserIds();
    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();
    const [, addMessage] = useReceivedMessageInbox();

    const [room] = useRoom(roomId);

    const isRoomAccessRestricted = room?.attributes.whitelist && room?.attributes.whitelist.length > 0;
    const isLocalUserOnWhitelist = !isRoomAccessRestricted || room?.attributes.whitelist?.includes(localProfile?.uid || '@@@');
    const isLocalUserVisitor = localProfile?.type == 'visitor';
    const doesLocalUserHaveAccess = isLocalUserOnWhitelist && (!isLocalUserVisitor || room?.attributes.openForVisitors);

    const userIdsInRoom = usersInRoom.filter((u: any) => u.uid != localProfile?.uid).map((u: any) => u.uid);
    const knockOnRoomAction: IRoomAction = {
        key: 'knock',
        title: IvicosStrings.knockOnDoorActionTitle,
        hasPriority: localProfile?.room != roomId && usersInRoom.length > 0,
        getSymbol: () => <HandKnock />,
        action: () => {
            knock(roomId);
            addMessage({
                id: makeMessageId(),
                type: 'confirmation',
                body: IvicosStrings.doorKnockConfirmationNotificationBody,
                timeout: 2500,
                meta: {},
                sender: 'system',
                timestamp: new Date()
            });
        }
    };

    const sendMemoToRoom: IRoomAction = {
        key: 'send-memo',
        title: IvicosStrings.sendMemoIntoRoomActionTitle,
        hasPriority: currentRoomId == roomId || !doesLocalUserHaveAccess,
        isDisabled: userIdsInRoom.length <= 0,
        getSymbol: () => <Memo />,
        action: () => {
            setMemoComposerUsersIds(userIdsInRoom);
        }
    };

    const enterRoomAction: IRoomAction = {
        key: 'enter',
        title: IvicosStrings.enterRoomActionTitle,
        getSymbol: () => <DoorOpen />,
        action: () => {
            routeHistory('/areas/' + currentAreaId + '/rooms/' + roomId);
        }
    };

    const editRoomAction: IRoomAction = {
        key: 'edit',
        title: IvicosStrings.editRoomActionTitle,
        isDisabled: identityClaims?.roles.includes('visitor') || identityClaims?.roles.includes('auto-confirmed-visitor'),
        getSymbol: () => <Settings />,
        action: () => {
            setRoomSettingsRoomId(roomId);
        }
    };

    if (currentRoomId == roomId) {
        return roomId.includes('personal') ? [sendMemoToRoom] : [sendMemoToRoom, editRoomAction];
    }

    if (doesLocalUserHaveAccess) return [knockOnRoomAction, sendMemoToRoom, enterRoomAction, editRoomAction];

    return [sendMemoToRoom, editRoomAction];
};
