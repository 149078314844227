import styled, { keyframes } from 'styled-components';
import React from 'react';
import { IC } from 'components/SVGIcon';
import SvgDoorbell from 'kits/IconKit2/Doorbell';

interface IAnimatedDoorbellIconProps {
    isButtonHovered: boolean;
}

const AnimatedDoorbellIcon: React.FC<IAnimatedDoorbellIconProps> = ({ isButtonHovered }) => {
    const ringingAnimation = keyframes`
        0% {
            transform: rotate(0deg);
        }
        10% {
            transform: rotate(50deg);
        }
        20% {
            transform: rotate(-50deg);
        }
        30% {
            transform: rotate(50deg);
        }
        40% {
            transform: rotate(-50deg);
        }
        50% {
            transform: rotate(50deg);
        }
        60% {
            transform: rotate(-50deg);
        }
        70% {
            transform: rotate(50deg);
        }
        80% {
            transform: rotate(-50deg);
        }
        90% {
            transform: rotate(50deg);
        }
        100% {
            transform: rotate(0deg);
        }
        `;

    const BouncingCampusIcon = styled.div`
        display: inline-block;
        position: relative;
        animation: ${isButtonHovered && ringingAnimation} 1.5s infinite;
    `;

    return (
        <BouncingCampusIcon>
            <IC variant={'light'} size={17}>
                <SvgDoorbell />
            </IC>
        </BouncingCampusIcon>
    );
};

export default AnimatedDoorbellIcon;
