import { mergeStyleSets } from '@fluentui/merge-styles';

export interface IComponentClassNames {
    loginPageAlignment: string;
    loginPageCampusLogo: string;
    loginPageCampusIcon: string;
    loginPageMiddleCard: string;
    loginPageInnerCard: string;
    loginPageSignIn: string;
    loginPageInputField: string;
    loginPageStackContainingMailAndSubmitButton: string;
    loginPageSubmitButton: string;
    loginPageSubmitButtonText: string;
    loginPageLoading: string;
}

export const useLoginClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        loginPageAlignment: {
            backgroundColor: '#e4e4e3',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
            justifyContent: 'center'
        },

        loginPageCampusLogo: {
            marginBottom: 53,
            width: 375,
            height: 73
        },

        loginPageCampusIcon: {
            marginBottom: -50,
            zIndex: 10
        },

        loginPageMiddleCard: {
            height: 375,
            width: 375,
            marginTop: 30
        },

        loginPageInnerCard: {
            margin: '37px 28px 36px 28px',
            height: 302,
            width: 323
        },

        loginPageSignIn: {
            marginTop: 0,
            fontWeight: 'bold',
            textAlign: 'center'
        },

        loginPageInputField: {
            width: 305,
            height: 41,
            marginTop: 20,
            borderRadius: 5,
            padding: '0 5px',
            fontSize: 14,
            borderColor: '#172D4C'
        },

        loginPageStackContainingMailAndSubmitButton: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: 319,
            height: 43,
            marginTop: 20,
            backgroundColor: '#172D4C',
            borderRadius: 5
        },

        loginPageSubmitButton: {
            border: 'none',
            backgroundColor: '#172D4C',
            cursor: 'pointer'
        },

        loginPageSubmitButtonText: {
            color: '#fff',
            cursor: 'pointer',
            fontWeight: 'bold'
        },

        loginPageLoading: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 169,
            fontWeight: 'bold',
            textAlign: 'center'
        }
    });
};
