import { Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { ErrorSymbol, Loading } from 'kits/IconKit2';
import React from 'react';
import { getTheme } from '@fluentui/react';
const theme = getTheme();

const SmallLoadingIndicator: React.FC<{ text: string; state?: 'loading' | 'error' }> = ({ text, state = 'loading' }) => {
    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <IC spinning={state == 'loading'} variant={state == 'error' ? 'red' : 'dark'} size={16}>
                {state == 'loading' ? <Loading /> : <ErrorSymbol />}
            </IC>
            <Text style={{ color: state == 'error' ? theme.palette.red : theme.palette.black }}>{text}</Text>
        </Stack>
    );
};

export default SmallLoadingIndicator;
