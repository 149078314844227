import { getTheme, Stack, Text } from '@fluentui/react';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Typewriter from 'typewriter-effect';
import appIcon from '../../media/img/AppIcon-no-bg.png';

export interface IComponentClassNames {
    bouncingLogo: string;
    loadingText: string;
}

const LoadingDialogSmallView: React.FC<{ loadingStrings: string | string[]; loop?: boolean; typewriter: boolean }> = ({ loadingStrings, loop, typewriter }) => {
    const theme = getTheme();
    const bouncingAnimation = keyframes`
    0% {
        top: 3px
    }
    50% {
        top: -7px
    }
    100% {
        top: 3px
    }
    `;

    const BouncingCampusIcon = styled.img`
        display: inline-block;
        position: relative;
        width: 80px;
        animation: ${bouncingAnimation} 0.7s infinite;
    `;

    return (
        <>
            <Stack style={{ height: '50vh', width: '100%' }} horizontalAlign="center" verticalAlign="center">
                <Stack horizontal={false} horizontalAlign="center" verticalAlign="space-around" style={{ height: '20vh ', width: '30vh' }}>
                    <Stack>
                        <BouncingCampusIcon src={appIcon} alt="bouncing campus icon" />
                    </Stack>

                    <Stack horizontal horizontalAlign="center" verticalAlign="center">
                        <Text variant="large" style={{ textAlign: 'center', color: theme.palette.black }}>
                            {typewriter ? (
                                <Typewriter
                                    options={{
                                        cursor: '',
                                        deleteSpeed: 1,
                                        strings: loadingStrings,
                                        autoStart: true,
                                        delay: 15,
                                        loop: loop
                                    }}
                                />
                            ) : (
                                <Text variant="xLarge">{loadingStrings[0]}</Text>
                            )}
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default LoadingDialogSmallView;
