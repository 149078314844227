import React, { useState } from 'react';

export interface IUserFaceOverflowButtonState {
    showOverflowUserPanel: boolean;
    setShowOverflowUserPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useUserFaceOverflowButtonState: () => IUserFaceOverflowButtonState = () => {
    const [showOverflowUserPanel, setShowOverflowUserPanel] = useState<boolean>(false);

    return { showOverflowUserPanel, setShowOverflowUserPanel };
};
