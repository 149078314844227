import { useBoolean } from '@uifabric/react-hooks';
import { useEvent } from 'kits/eventKit';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useState } from 'react';
import { useArea } from 'shared-state/directory/hooks';
import { useControlTogglePanelState, useIsSideBarMaximized } from 'shared-state/display-options/hooks';
import { useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { useUserList } from 'shared-state/presence/hooks';
import { useCurrentAreaId } from 'shared-state/location/hook';
import { v1 as uuidv1 } from 'uuid';

export interface ISideBarState {
    isMaximized: boolean;
    toggleSidebar: Function;
    isConnected: boolean;
    goBackToDashboard: Function;
    sidebarTitle: string;
    children?: React.ReactNode;
    toggleAlert: () => void;
    displayAlert: boolean;
    openAnnouncementDialog: Function;
}

export const useSideBarState: () => ISideBarState = () => {
    const [isMaximized, setIsMaximized] = useIsSideBarMaximized();
    const [areaId] = useCurrentAreaId();
    const [isConnected, setIsConnected] = useState(false);
    const [connectionTimeout, setConnectionTimeout] = useState(false);
    const [area] = useArea(areaId);
    const [controlTogglePanelState, setControlTogglePanelState] = useControlTogglePanelState();
    const [userList] = useUserList();
    const [, setMemoRecipients] = useMessageComposerUserIds();

    const openAnnouncementDialog = () => {
        if (userList.length <= 0) {
            alert(IvicosStrings.noUsersWarning);
            return;
        }
        setMemoRecipients(userList.map((u: any) => u.uid));
    };

    const [displayAlert, { toggle: toggleAlert }] = useBoolean(false);

    const sidebarTitle = area?.name || 'Area';

    const goBackToDashboard = () => {
        if (confirm(IvicosStrings.areaLeaveConfirmDescription)) {
            window.location.href = '/areas';
        }

        const newJitsiSessionId = uuidv1();
        localStorage.setItem('jitsiSessionId', newJitsiSessionId);
    };

    const toggleSidebar = () => {
        setIsMaximized((prev: any) => !prev);
        setControlTogglePanelState(!controlTogglePanelState);
    };

    useEffect(() => {
        setTimeout(() => {
            setConnectionTimeout(true);
        }, 6000000000);
    }, []);

    useEffect(() => {
        if (!connectionTimeout) return;

        if (!isConnected) {
            toggleAlert();
            window.location.reload();
        }
    }, [connectionTimeout]);

    useEvent('presenceChannelConnectionChanged', (event: any) => {
        setIsConnected(event.connected);
    });

    useEvent('displayConnecting', (data: boolean) => {
        setIsConnected(data);
    });

    useEvent('maximizeSideBar', () => {
        setIsMaximized(true);
    });

    return {
        isMaximized,
        toggleSidebar,
        isConnected,
        goBackToDashboard,
        sidebarTitle,
        displayAlert,
        toggleAlert,
        openAnnouncementDialog
    };
};
