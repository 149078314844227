import React from 'react';
import { useAllHandlers } from './handlers/users';
import { useSocketHandlers } from './event-actions/listeningEvents';

const SocketAdapter: React.FC = () => {
    const allHandlers = useAllHandlers();
    useSocketHandlers(allHandlers);
    return null;
};
export default SocketAdapter;
