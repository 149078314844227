import { IPersonaProps } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { useEvent } from 'kits/eventKit';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useState } from 'react';
import { useAreaList, useFetchInvitationLists, useFetchMembers } from 'shared-state/directory/hooks';
import { useSharedAvailability } from 'shared-state/identity/hooks';
import { days, months } from '../CreateInvitationPanel/Data';
import useCampusMembersManager from 'features/Rooms/RoomList/RoomSettings/campusMembersManager';

export interface IInvitationsManagerState {
    invitationsFilterValue: string;
    saveInvitationsFilterValue: (e: any, newValue: any) => void;
    groupedInvitationsByDayActive: any;
    groupedInvitationsByCondition: any;
    groupedInvitationsByDayExpired: any;
    isCreateInvitationOpen: boolean;
    openCreateInvitationPanel: () => void;
    dismissCreateInvitationPanel: () => void;
    areaNamesList: {
        key: string;
        text: string;
    }[];
    members: IPersonaProps[] | undefined;
    translateMonth: (day: string) => string | undefined;
    translateDay: (con: string) => string | undefined;
}

export const useInvitationsManagerState: () => IInvitationsManagerState = () => {
    const [invitationsFilterValue, setInvitationsFilterValue] = useState('');
    const [allInvitations, setAllInvitations] = useState<any>([]);
    const [isCreateInvitationOpen, { setTrue: openCreateInvitationPanel, setFalse: dismissCreateInvitationPanel }] = useBoolean(false);

    const [areaList] = useAreaList();

    const areaNamesList: { key: string; text: string }[] = areaList?.map((area: any) => ({ ['key']: area.id, ['text']: area.name }));

    const [campusMembers] = useCampusMembersManager();

    const [members, setMembers] = useState<IPersonaProps[] | undefined>(campusMembers);

    const [localAvailability] = useSharedAvailability();

    useEvent('refetchInvitations', () => {
        useFetchInvitationLists(setAllInvitations);
    });

    useEffect(() => {
        const fetchMembersAndInvitations = async () => {
            await useFetchMembers(localAvailability, setMembers);
            await useFetchInvitationLists(setAllInvitations);
        };

        fetchMembersAndInvitations();
    }, []);

    const saveInvitationsFilterValue = (e: any, newValue: string) => {
        typeof newValue == 'string' && setInvitationsFilterValue(newValue);
    };

    const today = new Date();
    const invitationsActive = allInvitations.filter((inv: any) => inv.validToDate > today);
    const invitationsExpired = allInvitations.filter((inv: any) => inv.validToDate < today);

    const filterSingleInvitations = (searchValue: string, invitationsToFilter: any) => {
        const singleInvitations = invitationsToFilter?.filter((inv: any) => inv.validity === 'single');
        const filteredByName =
            searchValue === ''
                ? singleInvitations
                : singleInvitations.filter((inv: any) => inv.name && inv.name.toLowerCase().includes(searchValue.toLowerCase()));
        const groupedByDay: any = {};
        filteredByName?.forEach((inv: any) => {
            const key = `${months[new Date(inv?.validFromDate).getMonth()]} ${new Date(inv?.validFromDate).getFullYear()}`;
            if (groupedByDay[key] === undefined) {
                const invArray: string[] = [];
                groupedByDay[key] = invArray;
            }
            groupedByDay[key].push(inv);
        });
        return groupedByDay;
    };

    const filterRecurringInvitations = (searchValue: string, invitationsToFilter: any) => {
        const recurringInvitations = invitationsToFilter?.filter((inv: any) => inv.validity !== 'single');

        const filteredByName =
            searchValue === ''
                ? recurringInvitations
                : recurringInvitations.filter((inv: any) => inv.name && inv.name.toLowerCase().includes(searchValue.toLowerCase()));

        const groupedByCondition: any = {};
        const dailyInvitations = filteredByName.filter((inv: any) => (inv.repeat == 'daily' ? inv : null));
        groupedByCondition['Daily'] = dailyInvitations;
        groupedByCondition['Mondays'] = [];
        groupedByCondition['Tuesdays'] = [];
        groupedByCondition['Wednesdays'] = [];
        groupedByCondition['Thursdays'] = [];
        groupedByCondition['Fridays'] = [];
        groupedByCondition['Saturdays'] = [];
        groupedByCondition['Sundays'] = [];

        filteredByName?.forEach((inv: any) => {
            const key = `${days[new Date(inv?.validFromDate).getDay()]}`;
            if (groupedByCondition[key] === undefined) {
                const emptyArray: string[] = [];
                groupedByCondition[key] = emptyArray;
            }
            inv?.repeat == 'weekly' ? groupedByCondition[key].push(inv) : null;
        });
        return groupedByCondition;
    };

    // 1 - Naive implementation:

    const groupedInvitationsByDayActive = filterSingleInvitations(invitationsFilterValue, invitationsActive);
    const groupedInvitationsByCondition = filterRecurringInvitations(invitationsFilterValue, allInvitations);
    const groupedInvitationsByDayExpired = filterSingleInvitations(invitationsFilterValue, invitationsExpired);

    const translateMonth = (day: string) => {
        if (months[new Date(day).getMonth()] === 'January') {
            return IvicosStrings.january;
        } else if (months[new Date(day).getMonth()] === 'February') {
            return IvicosStrings.february;
        } else if (months[new Date(day).getMonth()] === 'March') {
            return IvicosStrings.march;
        } else if (months[new Date(day).getMonth()] === 'April') {
            return IvicosStrings.april;
        } else if (months[new Date(day).getMonth()] === 'May') {
            return IvicosStrings.may;
        } else if (months[new Date(day).getMonth()] === 'June') {
            return IvicosStrings.june;
        } else if (months[new Date(day).getMonth()] === 'July') {
            return IvicosStrings.july;
        } else if (months[new Date(day).getMonth()] === 'August') {
            return IvicosStrings.august;
        } else if (months[new Date(day).getMonth()] === 'September') {
            return IvicosStrings.september;
        } else if (months[new Date(day).getMonth()] === 'October') {
            return IvicosStrings.october;
        } else if (months[new Date(day).getMonth()] === 'November') {
            return IvicosStrings.november;
        } else if (months[new Date(day).getMonth()] === 'December') {
            return IvicosStrings.december;
        }
    };

    const translateDay = (con: string) => {
        if (con === 'Mondays') {
            return IvicosStrings.monday;
        } else if (con === 'Tuesdays') {
            return IvicosStrings.tuesday;
        } else if (con === 'Wednesdays') {
            return IvicosStrings.wednesday;
        } else if (con === 'Thursdays') {
            return IvicosStrings.thursday;
        } else if (con === 'Fridays') {
            return IvicosStrings.friday;
        } else if (con === 'Saturdays') {
            return IvicosStrings.saturday;
        } else if (con === 'Sundays') {
            return IvicosStrings.sunday;
        } else if (con === 'Daily') {
            return IvicosStrings.daily;
        }
    };

    return {
        invitationsFilterValue,
        saveInvitationsFilterValue,
        groupedInvitationsByDayActive,
        groupedInvitationsByCondition,
        groupedInvitationsByDayExpired,
        isCreateInvitationOpen,
        openCreateInvitationPanel,
        dismissCreateInvitationPanel,
        areaNamesList,
        members,
        translateMonth,
        translateDay
    };
};
