import { Dialog, DialogFooter, ITheme, Panel, Pivot, PivotItem, PrimaryButton, Stack, Text } from '@fluentui/react';
import { CampusDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { OrgBrandingDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import { IC } from 'components/SVGIcon';
import TrialPeriodAboutToEndModal from 'components/TrialPeriodAboutToEndModal/TrialPeriodAboutToEndModal';
import AreaList from 'features/Areas/AreaList/AreaList';
import Billing from 'features/Billing/Billing';
import AreasPanel from 'features/Areas/AreaPanel/AreaPanel';
import CampusSettings from 'features/CampusSettings/CampusSettings';
import SetupAssistant from 'features/CampusSettings/SetupAssistant/SetupAssistant';
import ChangeLog from 'features/ChangeLog/ChangeLog';
import VisitorCard from 'features/Header/children/InvitationsManager/children/VisitorsCard/VisitorsCard';
import InvitationsManager from 'features/Header/children/InvitationsManager/InvitationsManager';
import Members from 'features/Members/Members';
import PowerUserImporter from 'features/Members/PowerUserImporter';
import { aNotification } from 'features/NotificationsLayer/hooks/atoms';
import NotificationsLayer from 'features/NotificationsLayer/NotificationsLayer';
import OnboardingVideoPopup from 'features/OnboardingVideoPopup/OnboardingVideoPopup';
import 'intro.js/introjs.css';
import { IvicosColors } from 'kits/colorKit';
import { Menu } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useState } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { useVisitorList } from 'shared-state/presence/hooks';
import 'styles/pivot.css';
import utf8 from 'utf8';

export interface IDashboardViewProps {
    selectedKey: string;
    theme: ITheme;
    campus?: CampusDto;
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    tabIsVisible: {
        areas: boolean;
        members: boolean;
        settings: boolean;
        billing: boolean;
        log: boolean;
    };
    setTabIsVisible: React.Dispatch<
        React.SetStateAction<{
            areas: boolean;
            members: boolean;
            settings: boolean;
            billing: boolean;
            log: boolean;
        }>
    >;
    handleLinkClick: (item?: PivotItem | undefined) => void;
    isAdmin?: boolean;
    branding?: OrgBrandingDto;
    pivotDisplaySmallDevice: string;
    burgerMenu: string;
    campusNameSmallDevice: string;
    stackTitleSmallDevice: string;
    smallDevice: boolean;
    invitationsManagerModal: boolean;
    setInvitationsManagerModal: SetterOrUpdater<boolean>;
    trialExpiringWarningDisplayedToday: boolean;
    isOrgInTrial: string;
    identityClaims: any;
}

const DashboardView: React.FC<IDashboardViewProps> = (props) => {
    const {
        selectedKey,
        theme,
        campus,
        isOpen,
        openPanel,
        dismissPanel,
        tabIsVisible,
        setTabIsVisible,
        handleLinkClick,
        isAdmin,
        branding,
        pivotDisplaySmallDevice,
        burgerMenu,
        campusNameSmallDevice,
        stackTitleSmallDevice,
        smallDevice,
        invitationsManagerModal,
        setInvitationsManagerModal,
        trialExpiringWarningDisplayedToday,
        isOrgInTrial
    } = props;

    const isAreasVisible = selectedKey == 'areas';
    const isManageAccessVisibile = selectedKey == 'members';
    const isCampusSettingVisible = selectedKey == 'settings';
    const isBillingVisible = selectedKey == 'billing';
    const isChangeLogVisible = selectedKey == 'changelog';

    const visitors = useVisitorList()[0];

    const [hideDialog, setHideDialog] = useRecoilState<any>(aNotification);

    const [members] = useState<any>([]);

    return (
        // Areas
        <Stack style={{ height: 'calc(100% - 48px)' }}>
            <AreasPanel />
            <NotificationsLayer />
            {invitationsManagerModal && <InvitationsManager setInvitationsManagerModal={setInvitationsManagerModal} />}
            {isAdmin && !trialExpiringWarningDisplayedToday && isOrgInTrial && <TrialPeriodAboutToEndModal />}
            <Panel
                isOpen={isOpen}
                onDismiss={dismissPanel}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel={IvicosStrings.closeActionTitle}
            >
                <img src={branding && branding.orgLogo} style={{ width: '50%' }} />

                <Stack style={{ marginTop: '15px' }}>
                    <Stack>
                        <Text
                            variant="xLarge"
                            onClick={() => {
                                dismissPanel();
                                setTabIsVisible({ areas: true, members: false, settings: false, billing: false, log: false });
                            }}
                            style={
                                tabIsVisible.areas
                                    ? {
                                          fontSize: '27px',
                                          color: IvicosColors.Mittelblau,
                                          marginBottom: '35px',
                                          fontWeight: 'bolder'
                                      }
                                    : { marginBottom: '30px' }
                            }
                        >
                            {IvicosStrings.availableAreasTitle}
                        </Text>
                    </Stack>
                    {isAdmin && (
                        <Text
                            variant="xLarge"
                            onClick={() => {
                                dismissPanel();
                                setTabIsVisible({ areas: false, members: true, settings: false, billing: false, log: false });
                            }}
                            style={
                                tabIsVisible.members
                                    ? {
                                          fontSize: '27px',
                                          color: IvicosColors.Mittelblau,
                                          marginBottom: '35px',
                                          fontWeight: 'bolder'
                                      }
                                    : { marginBottom: '30px' }
                            }
                        >
                            {IvicosStrings.manageAccessTitle}
                        </Text>
                    )}
                    {isAdmin && (
                        <Text
                            variant="xLarge"
                            onClick={() => {
                                dismissPanel();
                                setTabIsVisible({ areas: false, members: false, settings: true, billing: false, log: false });
                            }}
                            style={
                                tabIsVisible.settings
                                    ? {
                                          fontSize: '27px',
                                          color: IvicosColors.Mittelblau,
                                          marginBottom: '35px',
                                          fontWeight: 'bolder'
                                      }
                                    : { marginBottom: '30px' }
                            }
                        >
                            {IvicosStrings.campusSettingsTitle}
                        </Text>
                    )}
                    {isAdmin && (
                        <Text
                            variant="xLarge"
                            onClick={() => {
                                dismissPanel();
                                setTabIsVisible({ areas: false, members: false, settings: false, billing: true, log: false });
                            }}
                            style={
                                tabIsVisible.billing
                                    ? {
                                          fontSize: '27px',
                                          color: IvicosColors.Mittelblau,
                                          marginBottom: '35px',
                                          fontWeight: 'bolder'
                                      }
                                    : { marginBottom: '30px' }
                            }
                        >
                            {IvicosStrings.billingTitle}
                        </Text>
                    )}
                    <Text
                        variant="xLarge"
                        onClick={() => {
                            dismissPanel();
                            setTabIsVisible({ areas: false, members: false, settings: false, billing: false, log: true });
                        }}
                        style={
                            tabIsVisible.log
                                ? {
                                      fontSize: '27px',
                                      color: IvicosColors.Mittelblau,
                                      marginBottom: '35px',
                                      fontWeight: 'bolder'
                                  }
                                : { marginBottom: '30px' }
                        }
                    >
                        {IvicosStrings.changeLogTitle}
                    </Text>
                </Stack>
            </Panel>

            <Stack verticalAlign="stretch" style={{ maxWidth: '100%', paddingTop: 16, backgroundColor: theme.palette.neutralLight, height: '100%' }}>
                {!smallDevice && <SetupAssistant />}
                <PowerUserImporter />
                {!smallDevice && <OnboardingVideoPopup />}
                <Stack horizontal={smallDevice} className={`${stackTitleSmallDevice} start`}>
                    <IC size={32} variant="custom">
                        <Menu className={burgerMenu} onClick={openPanel} />
                    </IC>
                    <Text aria-label="campus-name-title" className={campusNameSmallDevice} style={{ color: theme.semanticColors.bodyText }} variant="xxLarge">
                        {campus?.name || ''}
                    </Text>
                </Stack>
                <Stack horizontal horizontalAlign="start" style={{ padding: 26 }} className={pivotDisplaySmallDevice}>
                    <Pivot
                        aria-label="dashboard-tabs"
                        selectedKey={selectedKey}
                        onLinkClick={handleLinkClick}
                        headersOnly={true}
                        className={`${pivotDisplaySmallDevice} menu`}
                    >
                        <PivotItem
                            headerText={IvicosStrings.availableAreasTitle}
                            itemKey="areas"
                            className="one-tab"
                            style={{ width: '30px', height: '20px' }}
                        />
                        {isAdmin && <PivotItem headerText={IvicosStrings.manageAccessTitle} itemKey="members" />}
                        {isAdmin && <PivotItem headerText={IvicosStrings.campusSettingsTitle} itemKey="settings" />}
                        {isAdmin && <PivotItem headerText={IvicosStrings.billingTitle} itemKey="billing" />}
                        <PivotItem headerText={IvicosStrings.changeLogTitle} itemKey="changelog" />
                    </Pivot>
                </Stack>

                {!smallDevice && (
                    <Stack style={{ height: 'calc(100% - 133px)' }}>
                        {isAreasVisible && (
                            <Stack aria-label="dashboard-area-list" grow>
                                <AreaList />
                            </Stack>
                        )}

                        {isManageAccessVisibile && <Members />}

                        {isCampusSettingVisible && <CampusSettings />}

                        {isBillingVisible && <Billing />}

                        {isChangeLogVisible && <ChangeLog />}
                    </Stack>
                )}

                {smallDevice && (
                    <Stack style={{ height: '100%' }}>
                        {tabIsVisible.areas && (
                            <Stack grow>
                                <AreaList />
                            </Stack>
                        )}

                        {tabIsVisible.members && <Members />}

                        {tabIsVisible.settings && <CampusSettings />}

                        {tabIsVisible.log && (
                            <Stack>
                                <ChangeLog />
                            </Stack>
                        )}
                    </Stack>
                )}

                <Dialog hidden={hideDialog}>
                    {visitors.length > 0 &&
                        visitors.map((visitor: any) => {
                            return (
                                <>
                                    <VisitorCard
                                        name={utf8.decode(visitor.displayName)}
                                        key={visitor.uid}
                                        host={visitor.host}
                                        members={members}
                                        invitationId={visitor.invitationId}
                                        subInfo={visitor.subInfo}
                                    />
                                </>
                            );
                        })}

                    <DialogFooter>
                        <PrimaryButton onClick={() => setHideDialog(!hideDialog)} text={IvicosStrings.closeActionTitle} />
                    </DialogFooter>
                </Dialog>
            </Stack>
        </Stack>
    );
};

export default DashboardView;
