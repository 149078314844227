import { useColorSelectorState } from 'components/ColorSelector/ColorSelector.state';
import ColorSelectorView from 'components/ColorSelector/ColorSelector.view';
import React from 'react';

interface IColorSelectorProps {
    label?: string;
    value: string;
    onChange: any;
    callout?: string;
    width: string;
    height: string;
}

const ColorSelector: React.FC<IColorSelectorProps> = (props) => {
    const state = useColorSelectorState(props.onChange, props.value);

    return (
        <ColorSelectorView
            {...{
                ...state,
                ...{
                    label: props.label,
                    value: props.value,
                    callout: props.callout,
                    width: props.width,
                    height: props.height
                }
            }}
        ></ColorSelectorView>
    );
};

export default ColorSelector;
