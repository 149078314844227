import {
    Callout,
    DelayedRender,
    DirectionalHint,
    ITextFieldStyles,
    IconButton,
    Stack,
    Text,
    TextField,
    Toggle,
    TooltipHost,
    mergeStyleSets
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { IC } from 'components/SVGIcon';
import { QuestionCircle } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useValidYouTubeUrl } from './hooks';
import { useCurrentOrgBranding } from 'shared-state/directory/hooks';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IVideoForTheWaitingRoomProps {
    customizedVideoUrlForWaitingRoom: string;
    setCustomizedVideoUrlForWaitingRoom: React.Dispatch<React.SetStateAction<string>>;
    onSavingVideoUrlForWaitingRoom: (isDefaultVideoIsUsed: boolean, defaultVideoAtVisitorEntrance?: string) => void;
}

const VideoForTheWaitingRoom: React.FunctionComponent<IVideoForTheWaitingRoomProps> = ({
    customizedVideoUrlForWaitingRoom,
    setCustomizedVideoUrlForWaitingRoom,
    onSavingVideoUrlForWaitingRoom
}) => {
    const isFirstRender = useRef(true);
    const [isVideoUrlCalloutVisible, setIsVideoUrlCalloutVisible] = useState<boolean>(false);
    const [isDefaultVideoIsUsed, setIsDefaultVideoIsUsed] = useState<boolean>(true);
    const [providedVideoUrl, setProvidedVideoUrl] = useState<string>('');
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
    const [isEditButtonVisible, setIsEditButtonVisible] = useState(false);
    const [orgBranding] = useCurrentOrgBranding();
    const buttonId = useId('callout-button');
    const { smallDevice } = useWindowWidth();
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '10px 14px'
        }
    });

    const textFieldStyles: Partial<ITextFieldStyles> = {
        fieldGroup: { width: 275, height: 35, border: '1px solid black', padding: '8px 9px', marginBottom: 10 }
    };

    const _onChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked === undefined) return;
        setIsDefaultVideoIsUsed(checked);
        if (checked) {
            setProvidedVideoUrl('');
            setCustomizedVideoUrlForWaitingRoom('');
            setIsSaveButtonDisabled(true);
            setIsDeleteButtonDisabled(true);
            setIsEditButtonVisible(false);
        }
    };

    const changeDefaultVideo = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue) {
            setProvidedVideoUrl(newValue);
            setIsDeleteButtonDisabled(false);
            const matched = useValidYouTubeUrl(newValue);
            if (matched) {
                setCustomizedVideoUrlForWaitingRoom(matched[0]);
                setIsSaveButtonDisabled(false);
            } else {
                setCustomizedVideoUrlForWaitingRoom('');
                setIsSaveButtonDisabled(true);
            }
        } else {
            setProvidedVideoUrl('');
            setIsDeleteButtonDisabled(true);
        }
    };

    const deleteCurrentUrl = () => {
        setProvidedVideoUrl('');
        setCustomizedVideoUrlForWaitingRoom('');
        setIsSaveButtonDisabled(true);
        setIsDeleteButtonDisabled(true);
        setIsEditButtonVisible(false);
    };

    const saveCurrentUrl = () => {
        onSavingVideoUrlForWaitingRoom(isDefaultVideoIsUsed);
        setIsEditButtonVisible(true);
    };

    const editCurrentUrl = () => {
        setIsEditButtonVisible(false);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (isDefaultVideoIsUsed && customizedVideoUrlForWaitingRoom === '') {
            onSavingVideoUrlForWaitingRoom(isDefaultVideoIsUsed, orgBranding.visitor_entrance_video_url);
        }
    }, [isDefaultVideoIsUsed]);

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal horizontalAlign="space-between">
                <Text style={{ fontWeight: 'bold', width: '80%' }} aria-label="video-for-waiting-room-title">
                    {IvicosStrings.customizedVideoLabel}
                </Text>

                {/* callout for youtube video for visitor entrance */}
                {isVideoUrlCalloutVisible && (
                    <Callout
                        className={styles.callout}
                        onDismiss={() => setIsVideoUrlCalloutVisible(false)}
                        role="status"
                        target={`#${buttonId}`}
                        aria-live="assertive"
                        directionalHint={DirectionalHint.leftCenter}
                    >
                        <DelayedRender>
                            <Stack tokens={{ childrenGap: 8 }}>
                                <Text variant="small" aria-label="video-for-waiting-room-callout-part1">
                                    {IvicosStrings.customizedVideoCallOutPart1}
                                </Text>
                                <Text variant="small" aria-label="video-for-waiting-room-callout-part2">
                                    {IvicosStrings.customizedVideoCallOutPart2}
                                </Text>
                            </Stack>
                        </DelayedRender>
                    </Callout>
                )}

                <IC variant="custom" size={15} style={{ cursor: 'pointer', marginRight: 0 }}>
                    <QuestionCircle
                        onClick={() => setIsVideoUrlCalloutVisible(!isVideoUrlCalloutVisible)}
                        id={buttonId}
                        data-test-id="video-for-waiting-room-callout"
                    />
                </IC>
            </Stack>

            <Toggle
                defaultChecked
                onText={IvicosStrings.customizedVideoOn}
                offText={IvicosStrings.customizedVideoOff}
                onChange={_onChange}
                data-test-id="video-for-waiting-room-toggle"
            />
            {!isDefaultVideoIsUsed ? (
                <Stack>
                    {customizedVideoUrlForWaitingRoom && (
                        <Stack
                            style={{
                                marginBottom: 10
                            }}
                            aria-label="video-for-waiting-room-video"
                        >
                            <ReactPlayer url={customizedVideoUrlForWaitingRoom} controls={true} width={275} height={170} />
                        </Stack>
                    )}

                    {isEditButtonVisible ? (
                        <Stack
                            horizontal
                            horizontalAlign="center"
                            verticalAlign="center"
                            tokens={{ childrenGap: 30 }}
                            style={{
                                marginTop: 2
                            }}
                        >
                            <TooltipHost content={smallDevice ? '' : IvicosStrings.editActionTitle}>
                                <IconButton
                                    iconProps={{
                                        iconName: 'Edit',
                                        styles: {
                                            root: {
                                                fontSize: '20px'
                                            }
                                        }
                                    }}
                                    onClick={editCurrentUrl}
                                    aria-label="video-for-waiting-room-edit"
                                />
                            </TooltipHost>

                            <TooltipHost content={smallDevice ? '' : IvicosStrings.deleteActionTitle}>
                                <IconButton
                                    iconProps={{
                                        iconName: 'Delete',
                                        styles: {
                                            root: {
                                                fontSize: '22px'
                                            }
                                        }
                                    }}
                                    disabled={isDeleteButtonDisabled}
                                    onClick={deleteCurrentUrl}
                                    aria-label="video-for-waiting-room-delete"
                                />
                            </TooltipHost>
                        </Stack>
                    ) : (
                        <Stack
                            style={{
                                height: 'auto'
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: 700,
                                    marginBottom: 10
                                }}
                                aria-label="video-for-waiting-room-paste-link"
                            >
                                {IvicosStrings.customizedVideoPasteLink}
                            </Text>
                            <TextField
                                value={providedVideoUrl}
                                onChange={(event, newValue) => typeof newValue == 'string' && changeDefaultVideo(event, newValue)}
                                styles={textFieldStyles}
                                data-test-id="video-for-waiting-room-textfield"
                            />

                            <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 30 }}>
                                <TooltipHost content={smallDevice ? '' : IvicosStrings.saveActionTitle}>
                                    <IconButton
                                        iconProps={{
                                            iconName: 'CheckMark',
                                            styles: {
                                                root: {
                                                    fontSize: '24px'
                                                }
                                            }
                                        }}
                                        disabled={isSaveButtonDisabled}
                                        onClick={saveCurrentUrl}
                                        aria-label="video-for-waiting-room-save"
                                    />
                                </TooltipHost>

                                <TooltipHost content={smallDevice ? '' : IvicosStrings.deleteActionTitle}>
                                    <IconButton
                                        iconProps={{
                                            iconName: 'Delete',
                                            styles: {
                                                root: {
                                                    fontSize: '22px'
                                                }
                                            }
                                        }}
                                        disabled={isDeleteButtonDisabled}
                                        onClick={deleteCurrentUrl}
                                        aria-label="video-for-waiting-room-delete"
                                    />
                                </TooltipHost>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            ) : null}
        </Stack>
    );
};
export default VideoForTheWaitingRoom;
