import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import { ISpeakerStatsSpeaker, shuffleArray, timerForm } from './children/Data';

import { IRoundTableStateUpdate, RoundTableSpeakerInfo } from './RoundTableStateManager';
import { IProfile } from 'shared-state/identity/types';

export interface ISpeakerStatsState {
    onChangeMinutes: (key: string, newValue: any) => void;
    initialiseRoundTableMode: (stats: ISpeakerStatsSpeaker[]) => IRoundTableStateUpdate;
    totalTime: number;
    formFields: IDynamicFormElementProps[];
    prettifyTime: (timeInMs: number) => string;
    isLocalUserTalksTooMuchModalActive: boolean;
    setIsLocalUserTalksTooMuchModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    isLocalUserTalkingTooMuch: boolean;
    setIsTalkDetailsModalShown: React.Dispatch<React.SetStateAction<boolean>>;
    isTalkDetailsModalShown: boolean;
    setIsRoundTableSettingsModalShown: React.Dispatch<React.SetStateAction<boolean>>;
    isRoundTableSettingsModalShown: boolean;
    localProfile: IProfile | undefined;
    isRoundTableTimerActivated: boolean;
    setIsRoundTableTimerActivated: React.Dispatch<React.SetStateAction<boolean>>;
    resetRoundTableTimerForm: () => void;
}

export const useSpeakerStatsState: (
    stats: ISpeakerStatsSpeaker[],
    setRoundTableBorder: React.Dispatch<React.SetStateAction<string>>,
    executeCommand: (command: string, data?: any) => void,
    isOpen: boolean,
    roomId: string
) => ISpeakerStatsState = (stats, setRoundTableBorder, executeCommand, isOpen, roomId) => {
    const { smallDevice } = useWindowWidth();
    const localProfile = useLocalProfile();
    const [roundTableMinuteSelection, setRoundTableMinuteSelection] = React.useState('0');

    const usersInRoom = useUsersInRoom(roomId);

    const [formFields, setFormFields] = useState<IDynamicFormElementProps[]>(timerForm('0', false, smallDevice));
    const [isLocalUserTalksTooMuchModalActive, setIsLocalUserTalksTooMuchModalActive] = useState<boolean>(false);
    const [isLocalUserTalkingTooMuch, setIsLocalUserTalkingTooMuch] = useState<boolean>(false);
    const [isTalkDetailsModalShown, setIsTalkDetailsModalShown] = useState<boolean>(false);
    const [isRoundTableSettingsModalShown, setIsRoundTableSettingsModalShown] = useState<boolean>(false);
    const [isRoundTableTimerActivated, setIsRoundTableTimerActivated] = useState<boolean>(false);

    useEffect(() => {
        isRoundTableSettingsModalShown && resetRoundTableTimerForm();
    }, [isRoundTableSettingsModalShown]);

    useEffect(() => {
        setFormFields(timerForm(roundTableMinuteSelection, isRoundTableTimerActivated, smallDevice));
    }, [roundTableMinuteSelection, isRoundTableTimerActivated]);

    const onChangeMinutes = (key: string, newValue: any) => {
        if (key == 'timer') {
            setRoundTableMinuteSelection(newValue.selectedKey);
        } else if (key == 'applyTimer') {
            setIsRoundTableTimerActivated(newValue);
        }
    };

    const initialiseRoundTableMode: (stats: ISpeakerStatsSpeaker[]) => IRoundTableStateUpdate = React.useCallback(
        (stats: ISpeakerStatsSpeaker[]) => {
            const randomOrderedSpeakerStats = shuffleArray(stats);
            const userDisplayNamesInRoom: string[] = usersInRoom.map((user: any) => user.displayName);
            const speakersList: RoundTableSpeakerInfo[] = randomOrderedSpeakerStats
                .filter((speaker: ISpeakerStatsSpeaker) => speaker.userName != undefined && userDisplayNamesInRoom.includes(speaker.userName))
                .map((speaker: ISpeakerStatsSpeaker) => ({ userId: speaker.userName ? speaker.userName : '', roundStartTimestamp: 0, isInTheRoom: true }));

            const minutes = roundTableMinuteSelection;
            speakersList[0].roundStartTimestamp = new Date().getTime();

            const roundtableObject = {
                roundDurationInMinutes: minutes,
                roundTableSpeakerList: speakersList,
                currentSpeakerIndex: 0,
                isRoundTableActive: true,
                roundTableRoomId: roomId
            };

            return roundtableObject;
        },
        [usersInRoom, roundTableMinuteSelection]
    );

    useEffect(() => {
        const statTimes = stats.slice(1).map((speaker) => speaker.speakerTime);

        if (statTimes.length <= 0) return;

        const highest = Math.max(...statTimes);
        const localSpeakerTime = stats[0].speakerTime || 1;

        const ratio = localSpeakerTime / highest;

        const talkingtooMuch = stats[0] && stats[0].speakerTime > 300000 && (highest == 0 || ratio > 1.25);
        if (isLocalUserTalkingTooMuch !== talkingtooMuch) setIsLocalUserTalkingTooMuch(talkingtooMuch);
    }, [stats]);

    const resetRoundTableTimerForm = useCallback(() => {
        setFormFields(timerForm('0', false, smallDevice));
        setRoundTableMinuteSelection('0');
        setIsRoundTableTimerActivated(false);
    }, []);

    const prettifyTime = React.useCallback((timeInMs: number) => {
        function pad(n: number, z?: number) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }
        let s = Math.abs(timeInMs);
        const ms = s % 1000;
        s = (s - ms) / 1000;
        const secs = s % 60;
        s = (s - secs) / 60;
        const mins = s % 60;
        const hrs = (s - mins) / 60;
        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
    }, []);

    let totalTime = 0;

    if (stats.length > 1) {
        totalTime = stats.map((stat) => stat.speakerTime).reduce((prev, curr) => prev + curr);
    }

    return {
        onChangeMinutes,
        totalTime,
        initialiseRoundTableMode,
        formFields,
        prettifyTime,
        isLocalUserTalksTooMuchModalActive,
        setIsLocalUserTalksTooMuchModalActive,
        isLocalUserTalkingTooMuch,
        isTalkDetailsModalShown,
        setIsTalkDetailsModalShown,
        isRoundTableSettingsModalShown,
        setIsRoundTableSettingsModalShown,
        localProfile,
        isRoundTableTimerActivated,
        setIsRoundTableTimerActivated,
        resetRoundTableTimerForm
    };
};
