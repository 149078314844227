import { InterfaceConfigOptions } from '@ivicos/react-jitsi/dist/types';
import { useCurrentRoomId } from 'shared-state/location/hook';

export const getJitsiConfig = (muteOnJoin?: 'audio' | 'both' | 'video'): any => {
    const [currentRoomId] = useCurrentRoomId();

    return {
        resolution: 480,
        constraints: {
            video: {
                height: {
                    ideal: 480,
                    max: 480,
                    min: 240
                }
            }
        },
        startWithAudioMuted: muteOnJoin && ['audio', 'both'].includes(muteOnJoin),
        startWithVideoMuted: muteOnJoin && ['video', 'both'].includes(muteOnJoin),
        channelLastN: 22,
        deeplinking: {
            disable: true
        },
        enableLayerSuspension: true,
        disableAudioLevels: false,
        audioLevelsInterval: 200,
        hideConferenceTimer: currentRoomId?.includes('personal'),
        hideConferenceSubject: true,
        disableSelfViewSettings: true,
        disableTileEnlargement: true,
        disableScreensharingVirtualBackground: true,
        desktopSharingFrameRate: {
            min: 1,
            max: 10
        },
        prejoinConfig: {
            enabled: false
        },
        resizeDesktopForPresenter: true,
        toolbarButtons: ['raisehand'],
        remoteVideoMenu: {
            // Whether the remote video context menu to be rendered or not.
            disabled: false,
            // If set to true the 'Kick out' button will be disabled.
            disableKick: true,
            // If set to true the 'Grant moderator' button will be disabled.
            disableGrantModerator: false,
            // If set to true the 'Send private message' button will be disabled.
            disablePrivateChat: true
        },
        giphy: {
            //     // Whether the feature is enabled or not.
            enabled: true,
            //     // SDK API Key from Giphy.
            sdkKey: 'YSvWToolxXYQ891W6B8XRWQrD3fscQmA',
            //     // Display mode can be one of:
            //     // - tile: show the GIF on the tile of the participant that sent it.
            //     // - chat: show the GIF as a message in chat
            //     // - all: all of the above. This is the default option
            displayMode: 'all'
            //     // How long the GIF should be displayed on the tile (in milliseconds).
            //     tileTime: 5000,
            //     // Limit results by rating: g, pg, pg-13, r. Default value: g.
            //     rating: 'pg',
            //     // The proxy server url for giphy requests in the web app.
            //     proxyUrl: 'https://giphy-proxy.example.com',
        }
    };
};

export const getJitsiInterfaceConfig = (): InterfaceConfigOptions => {
    return {
        APP_NAME: 'Campus Alpha',
        SHOW_BRAND_WATERMARK: false,
        SHOW_POWERED_BY: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        DISABLE_VIDEO_BACKGROUND: true,
        DISABLE_FOCUS_INDICATOR: true,
        DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow team member',
        DEFAULT_LOCAL_DISPLAY_NAME: 'me',
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        VIDEO_LAYOUT_FIT: 'height',
        VIDEO_QUALITY_LABEL_DISABLED: true,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        SETTINGS_SECTIONS: ['devices', 'language']
    };
};
