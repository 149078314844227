import React from 'react';
import { useReviewDataStepState } from './ReviewDataStep.state';
import ReviewDataStepView from './ReviewDataStep.view';

export type ICSVData = { body: string[][]; headline: string[]; setBody: (value: React.SetStateAction<string[][]>) => void };

export interface IReviewDataStepProps {
    data?: ICSVData;
    declineData: () => void;
    acceptData: () => void;
}

const ReviewDataStep: React.FC<IReviewDataStepProps> = (props) => {
    const state = useReviewDataStepState(props.data);
    return (
        <ReviewDataStepView
            {...{
                ...state,
                ...{
                    data: props.data,
                    declineData: props.declineData,
                    acceptData: props.acceptData
                }
            }}
        ></ReviewDataStepView>
    );
};

export default ReviewDataStep;
