import React from 'react';
import { useMemoComposerState } from './MemoComposer.state';
import MemoComposerView from './MemoComposer.view';

const MemoComposer: React.FC = () => {
    const state = useMemoComposerState();

    return (
        <MemoComposerView
            {...{
                ...state
            }}
        />
    );
};
export default MemoComposer;
