import React from 'react';
import 'styles/RoomList.css';
import { useListedRoomState } from './ListedRoom.state';
import ListedRoomView from './ListedRoom.view';

export interface IListedRoomProps {
    roomId: string;
}

const ListedRoom: React.FC<IListedRoomProps> = (props) => {
    const state = useListedRoomState(props.roomId);

    return (
        <ListedRoomView
            {...{
                ...state,
                ...{
                    roomId: props.roomId
                }
            }}
        />
    );
};
export default ListedRoom;
