import React from 'react';
import { PrimaryButton, Stack, TooltipHost } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface ISideBarIconButtonProps {
    onClick?: Function;
    title?: string;
    variant?: 'dark' | 'primary' | 'red';
    size?: number;
    ariaLabel?: string;
    children?: React.ReactNode;
}

export const SideBarIconButton: React.FC<ISideBarIconButtonProps> = ({ onClick, title, variant = 'dark', children, size = 24, ariaLabel }) => {
    const { smallDevice } = useWindowWidth();
    return (
        <TooltipHost content={smallDevice ? '' : title}>
            <PrimaryButton
                className="pressable--transparent"
                onClick={() => onClick && onClick()}
                aria-label={ariaLabel}
                styles={{ root: { padding: 0, minWidth: 0 } }}
            >
                <Stack style={{ borderRadius: 4, padding: 4 }} verticalAlign="center" horizontalAlign="center">
                    <IC size={size} variant={variant}>
                        {children}
                    </IC>
                </Stack>
            </PrimaryButton>
        </TooltipHost>
    );
};
