import Info from './media/notifications/info.svg';
import Check from './media/notifications/check.svg';
import Timing from './media/notifications/timing.svg';
import InvitationRecieved from './media/notifications/invitation--recieved.svg';
import MessageRecieved from './media/notifications/message--recieved.svg';
import MessageSent from './media/notifications/message--sent.svg';
import HandWave from './media/notifications/hand--wave.svg';
import UserJoined from './media/notifications/user--joined.svg';

const Notifications = {
    Check,
    UserJoined,
    HandWave,
    MessageRecieved,
    MessageSent,
    Timing,
    Info,
    InvitationRecieved
};

export default Notifications;
