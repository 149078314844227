import React, { useState } from 'react';
import StartSetupButtonView from './StartSetupButton.view';
import { useNavigate } from 'react-router';

const StartSetupButton: React.FC<{}> = () => {
    const routeHistory = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onModalDismiss = () => setIsModalOpen(false);
    const onModalShouldOpen = () => setIsModalOpen(true);

    const onConfirm = () => {
        routeHistory('/ivcampussetup');
    };
    return <StartSetupButtonView {...{ isModalOpen, onModalDismiss, onModalShouldOpen, onConfirm }} />;
};
export default StartSetupButton;
