import React from 'react';
import { DefaultEffects, Stack, Text } from '@fluentui/react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { prettyDate } from './helpers';
import { IChangeLogFeedEntry, IChangeLogState } from './ChangeLog.state';
import { IvicosColors } from 'kits/colorKit';

const ChangeLogView: React.FC<IChangeLogState> = ({ changeLogFeed, theme, changeLogCard }) => {
    const { smallDevice } = useWindowWidth();
    const renderFeedEntry = (entry: IChangeLogFeedEntry) => (
        <Stack
            className={smallDevice ? 'padding--large' : `${changeLogCard} padding--large`}
            tokens={{ childrenGap: 8 }}
            style={{
                background: theme.palette.white,
                color: theme.palette.black,
                boxShadow: DefaultEffects.elevation8,
                width: smallDevice ? '100%' : '50%'
            }}
        >
            <Text variant="large">{entry.title}</Text>
            <Text variant="small">{prettyDate(new Date(entry.timestamp)) || 'At some point'}</Text>
            <Text>{entry.body}</Text>
        </Stack>
    );

    return (
        <Stack style={{ overflowY: 'scroll', height: '78vh', paddingBottom: '300px' }}>
            {smallDevice && (
                <Stack className="padding--large" style={{ position: 'sticky', top: 0, background: IvicosColors.Grau75, zIndex: 1 }}>
                    <Text variant="large">Change log</Text>
                </Stack>
            )}
            <Stack className="padding--large">
                <Stack tokens={{ childrenGap: 8 }} horizontal={false} wrap={false}>
                    {changeLogFeed &&
                        changeLogFeed.map((entry: any, entryIndex: any) => <React.Fragment key={'e-' + entryIndex}>{renderFeedEntry(entry)}</React.Fragment>)}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ChangeLogView;
