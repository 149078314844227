import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { useReviewInvitations } from 'shared-state/directory/hooks';

export interface IPowerUserImporterStateProps {
    routeHistory: any;
    actionKey?: string;
    isOpen: boolean;
    setIsOpen: Function;
    step: number;
    setStep: Function;
    invitationStatus: number[];
    setInvitationStatus: Function;
    setHeadline: Function;
    headline: string[];
    body: string[][];
    setBody: React.Dispatch<React.SetStateAction<string[][]>>;
    onFileUpload: (e: any) => void;
    setStatus: Function;
    onAcceptData: Function;
    onFinish: Function;
}

export const PowerUserImporterState: () => IPowerUserImporterStateProps = () => {
    const routeHistory = useNavigate();
    const { actionKey } = useParams<{ actionKey?: string }>();
    const isOpen = actionKey == 'powerImporter';
    const setIsOpen = (a: boolean) => {
        routeHistory('/dashboard/members' + (a ? '/powerImporter' : ''));
    };
    const [step, setStep] = useState(0);
    const [invitationStatus, setInvitationStatus] = useState<number[]>([]);

    const [headline, setHeadline] = useState<string[]>([]);
    const [body, setBody] = useState<Array<string[]>>([]);
    const [, setIsReviewInvitationsModalClose] = useReviewInvitations();
    const capitalizeFirstLetter = (providedString: string) => {
        return providedString.charAt(0).toUpperCase() + providedString.slice(1);
    };
    const onFileUpload = (file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
            const text = reader.result;
            if (typeof text != 'string') return;

            const parsedCSV = text
                ?.split('\r')
                .join('')
                .split('\n')
                .map((line) => line.split(';'))
                .filter((line) => line.length == 3);
            const nextHeadline = parsedCSV[0];
            const nextBody = parsedCSV.splice(1);
            setHeadline(nextHeadline);
            setBody(
                nextBody
                    .map((importedPerson) => {
                        return [
                            importedPerson[0],
                            ['user', 'manager'].includes(importedPerson[1].toLocaleLowerCase()) ? importedPerson[1].toLocaleLowerCase() : importedPerson[1],
                            ['english', 'deutsch'].includes(importedPerson[2].toLocaleLowerCase())
                                ? capitalizeFirstLetter(importedPerson[2].toLocaleLowerCase())
                                : importedPerson[2]
                        ];
                    })
                    .sort((a: string[], b: string[]) => {
                        return a[0].localeCompare(b[0]);
                    })
            );
            setStep(1);
        };
        reader.readAsText(file);
    };

    const setStatus = (index: number, value: number) => {
        setInvitationStatus((prevStatus: number[]) => {
            const imStatus = [...prevStatus];

            imStatus[index] = value;

            return imStatus;
        });
    };

    const onAcceptData = async () => {
        setStep(2);

        const isAnyoneBillingSystem = localStorage.getItem('isAnyoneBillingSystem') === 'true' ? true : false;

        for (let index = 0; index < body.length; index++) {
            let importedPersonLanguageValue = '';
            if (body[index][2].toLocaleLowerCase() === 'english') {
                importedPersonLanguageValue = 'en';
            }
            if (body[index][2].toLocaleLowerCase() === 'deutsch') {
                importedPersonLanguageValue = 'de';
            }
            try {
                if (isAnyoneBillingSystem) {
                    const hasSponsor = await (await idpService.emailHasSponsor(body[index][0])).email_has_sponsor;

                    if (hasSponsor) {
                        await idpService.newInvited(body[index][0], body[index][1] === 'manager' ? 'manager' : 'user', importedPersonLanguageValue);
                        setStatus(index, 1);
                    } else {
                        await idpService.newInvited(
                            body[index][0],
                            body[index][1] === 'manager' ? 'manager' : 'user',
                            importedPersonLanguageValue,
                            'anyone_bulk'
                        );
                        setStatus(index, 3);
                    }
                } else {
                    await idpService.newInvited(body[index][0], body[index][1] === 'manager' ? 'manager' : 'user', importedPersonLanguageValue);
                    setStatus(index, 1);
                }
            } catch {
                setStatus(index, 2);
            }
        }
    };

    const onFinish = () => {
        setIsOpen(false);
        setBody([]);
        setHeadline([]);
        setInvitationStatus([]);
        setStep(0);
        if (!invitationStatus.includes(3)) {
            window.location.reload();
        } else {
            setIsReviewInvitationsModalClose(false);
        }
    };

    return {
        routeHistory,
        actionKey,
        isOpen,
        setIsOpen,
        step,
        setStep,
        invitationStatus,
        setInvitationStatus,
        headline,
        setHeadline,
        body,
        setBody,
        onFileUpload,
        setStatus,
        onAcceptData,
        onFinish
    };
};
