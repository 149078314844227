import React from 'react';
import { Link, Stack, Text } from '@fluentui/react';
import { useNavigate } from 'react-router';
import wrong from 'media/img/Wrong.svg';
import IvicosStrings from '../kits/language/stringKit';

const NewUserSomethingWrong: React.FC<{ preferredLanguage?: string }> = ({ preferredLanguage }) => {
    const routeHistory = useNavigate();

    return (
        <Stack>
            <Stack
                style={{
                    background: 'linear-gradient(150deg, #E4E3E4 41.98%, #E4E4E3 54.26%, rgba(255, 255, 255, 0.807062) 58.41%, #E4E4E3 62.45%, #E4E4E3 74.73%)',
                    flexDirection: 'column',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    style={{
                        height: preferredLanguage === 'en' ? 443 : 453,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack
                        style={{
                            margin: '69px 23px 69px 22px',
                            height: preferredLanguage === 'en' ? 305 : 353,
                            width: 330
                        }}
                    >
                        <Stack
                            style={{
                                width: 70,
                                height: 70,
                                border: '1px solid #ff0000',
                                borderRadius: '50%',
                                backgroundColor: '#ff0000',
                                alignSelf: 'center'
                            }}
                        >
                            <img
                                src={wrong}
                                alt="something went wrong"
                                width={30}
                                height={30}
                                style={{
                                    alignSelf: 'center',
                                    marginTop: 19 // measured it and 19 suits as the perfect value to center it
                                }}
                            />
                        </Stack>

                        <Text
                            variant="xLarge"
                            style={{
                                marginTop: 33,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            {IvicosStrings.somethingWrongTitle}
                        </Text>
                        <Text variant="medium" style={{ marginTop: 33 }}>
                            {IvicosStrings.newUserSomethingWrongDescription1}
                        </Text>
                        <Text variant="medium" style={{ marginTop: 23 }}>
                            {IvicosStrings.newUserSomethingWrongDescription2}{' '}
                            <Link
                                style={{ color: '#000', fontWeight: 700 }}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                onClick={(e) => {
                                    routeHistory('/login');
                                    sessionStorage.setItem('showEmail', 'true');
                                }}
                                data-test-id="redirect-to-login-page"
                            >
                                {IvicosStrings.here}
                            </Link>
                            .
                        </Text>
                        <Text variant="medium" style={{ marginTop: 23 }}>
                            {IvicosStrings.newUserSomethingWrongDescription3}{' '}
                            <Link
                                style={{ color: '#000', fontWeight: 700 }}
                                href={'mailto:support@ivicos.eu?subject=Authentication%20process%20is%20not%20working'}
                                target="_blank"
                                data-test-id="send-email-to-support"
                            >
                                {IvicosStrings.here}
                            </Link>{' '}
                            {IvicosStrings.orVisitOur}{' '}
                            <Link style={{ color: '#000', fontWeight: 700 }} href="https://help.ivicos.eu/en/HELP-ivcampus" target="_blank">
                                {IvicosStrings.helpPage}
                            </Link>
                            .
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default NewUserSomethingWrong;
