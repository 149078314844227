import {
    aControlValue,
    aDismissOverflow,
    aExpandNotification,
    aHideNotification,
    aNotificationSenderId,
    aShowSingleMessageNotification,
    aShowViewAll,
    aShowViewAllOfNotificationsOnly
} from 'features/NotificationsLayer/hooks/atoms';
import { useNotificationGroupedAssets } from 'features/NotificationsLayer/hooks/useNotificationGroupedAssets';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import NotificationBanner from '../NotificationBanner';
import NotificationsGroupedBannerView from './NotificationGroupedBanner.view';

interface INotificationsGroupedBannerProps {
    senderId: string;
    numOfMessages: number;
    idOfMessages: any;
    typeOfMessage: string[];
    listOfMemos: any;
    children?: React.ReactNode;
}

const NotificationGroupedBanner: React.FC<INotificationsGroupedBannerProps> = ({
    children,
    senderId,
    numOfMessages,
    idOfMessages,
    typeOfMessage,
    listOfMemos
}) => {
    const setNotificationSenderId = useSetRecoilState(aNotificationSenderId);
    const setControlValue = useSetRecoilState(aControlValue);
    const setDismissOverflow = useSetRecoilState(aDismissOverflow);
    const setExpandNotification = useSetRecoilState(aExpandNotification);
    const setHideNotification = useSetRecoilState(aHideNotification);
    const setShowViewAllOfNotificationsOnly = useSetRecoilState(aShowViewAllOfNotificationsOnly);

    const setShowViewAll = useSetRecoilState(aShowViewAll);
    const showSingleMessageNotification = useRecoilValue(aShowSingleMessageNotification);

    const state = useNotificationGroupedAssets(senderId, typeOfMessage, numOfMessages);

    const closeMessageDialogValue =
        (typeOfMessage.includes('memo') || typeOfMessage.includes('cross-area-memo') || typeOfMessage.includes('memo-at-visitor-entrance')) &&
        (typeOfMessage.includes('invite') || typeOfMessage.includes('cross-area-invite') || typeOfMessage.includes('knock'))
            ? 'memosAndInvitations'
            : typeOfMessage.includes('memo') || typeOfMessage.includes('cross-area-memo')
            ? 'memos'
            : typeOfMessage.includes('invite') || typeOfMessage.includes('cross-area-invite') || typeOfMessage.includes('knock')
            ? 'invitations'
            : typeOfMessage.includes('guest')
            ? 'visitorNotifications'
            : 'messages';

    if (numOfMessages === 1 && showSingleMessageNotification) {
        setShowViewAllOfNotificationsOnly(false);
        setHideNotification(true);
        setShowViewAll(true);
        setNotificationSenderId('');
        setExpandNotification(false);
        setControlValue(false);
        setDismissOverflow(false);
    }
    return numOfMessages === 1 ? (
        <NotificationBanner messageId={idOfMessages[0]} key={idOfMessages[0]} />
    ) : (
        <NotificationsGroupedBannerView
            closeMessageDialogValue={closeMessageDialogValue}
            listOfMemos={listOfMemos}
            senderId={senderId}
            idOfMessages={idOfMessages}
            {...{
                ...state
            }}
        >
            {children}
        </NotificationsGroupedBannerView>
    );
};

export default NotificationGroupedBanner;
