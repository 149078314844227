/* eslint-disable quotes */
import { getTheme, Persona, PersonaSize, PrimaryButton, Stack, Text, TooltipHost } from '@fluentui/react';
import Badge from 'components/Badge';
import { ITopBarState } from 'features/Header/children/TopBar/TopBar.state';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import IconKit from 'kits/IconKit';
import IvicosStrings from 'kits/language/stringKit';
import IvicosLogo from 'media/logos/iv_campus_logo_white.svg';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import 'styles/helpers.css';

export interface ITopBarStyles {
    textColor?: string;
    backgroundColor?: string;
    barLogo?: string;
}

const TopBarView: React.FC<ITopBarState> = (props) => {
    const theme = getTheme();

    const { children, localProfile, branding, links, barTitle, variantLabel, personaProps } = props;

    const { smallDevice } = useWindowWidth();

    const customLogo = branding && branding.orgLogo;
    const textColor = branding && branding.orgColors && branding.orgColors.length > 0 ? getTextColorForBackground(branding.orgColors[0]) : '#fff';
    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    return (
        <>
            <Stack aria-label="topbar" disableShrink={true}>
                <Stack
                    aria-label="topbar-color"
                    horizontal
                    verticalAlign="center"
                    style={{
                        height: '48px',
                        minHeight: 48,
                        background: backgroundColor,
                        color: textColor,
                        padding: '0px 16px'
                    }}
                >
                    <Stack aria-label="orgLogo-topbar" key="brand" horizontal horizontalAlign="center" verticalAlign="center">
                        <img src={customLogo || IvicosLogo} height={32} aria-label="orgLogo-topBar-image" />
                    </Stack>

                    <>
                        {!smallDevice && (
                            <Stack aria-label="campus-variant-label" key="titles" horizontal verticalAlign="center">
                                {customLogo ? (
                                    <Text style={{ marginLeft: 16, color: textColor }}>Campus </Text>
                                ) : (
                                    <Text style={{ marginLeft: 16, color: textColor }}>{barTitle || 'Campus'}</Text>
                                )}
                                {variantLabel != 'PROD' ? (
                                    <Text
                                        style={{
                                            padding: '2px 4px',
                                            lineHeight: '8px',
                                            fontSize: 8,
                                            fontWeight: 'bold',
                                            borderRadius: 4,
                                            marginLeft: 4,
                                            color: theme.palette.black,
                                            background: '#FEC323'
                                        }}
                                    >
                                        {variantLabel}
                                    </Text>
                                ) : null}
                            </Stack>
                        )}

                        <Stack
                            key="content"
                            horizontal
                            grow
                            horizontalAlign="start"
                            verticalAlign="center"
                            style={{ height: '100%', marginLeft: 16, marginRight: 16 }}
                        >
                            {typeof children == 'function' ? children(textColor) : children}
                        </Stack>
                    </>

                    {smallDevice && <Stack grow />}

                    {!smallDevice &&
                        links &&
                        links.map((action) => (
                            <PrimaryButton
                                aria-label="help-button"
                                key={action.key}
                                onClick={(e) => {
                                    action.action && action.action(e);
                                }}
                                className="pressable--transparent"
                                style={{ height: 48, cursor: 'pointer', padding: '0px 16px' }}
                            >
                                <IconKit.Icon icon={action.symbol} />
                                <Text variant="small" style={{ marginLeft: 4, color: textColor }}>
                                    {action.title}
                                </Text>
                            </PrimaryButton>
                        ))}

                    {personaProps && (
                        <PrimaryButton
                            aria-label="profile-picture-button"
                            className="pressable--transparent"
                            style={{
                                color: textColor,
                                borderRadius: 2,
                                padding: '0px 8px',
                                height: '48px'
                            }}
                            onClick={() => (window.location.href = '#status')}
                        >
                            {localProfile?.type !== 'user' ? (
                                <Stack>
                                    {/* says primaryText is deprecated but it is rendering correctly, the suggested "text" key is not appearing in the personaProps obj. */}
                                    <Badge text={personaProps.text} size="41" fontSize={'medium'} textColor={textColor} />
                                </Stack>
                            ) : (
                                <TooltipHost content={smallDevice ? '' : IvicosStrings.identityPanelTitle}>
                                    <Persona
                                        styles={{ primaryText: { color: textColor } }}
                                        size={PersonaSize.size40}
                                        hidePersonaDetails={true}
                                        {...personaProps}
                                    />
                                </TooltipHost>
                            )}
                        </PrimaryButton>
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default TopBarView;
