import { DefaultButton, DefaultEffects, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import Badge from 'components/Badge';
import DynamicForm from 'components/DynamicForm/DynamicForm';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import { Memo } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect } from 'react';
import { useIsWaitingAreaMemoDialogOpen } from 'shared-state/display-options/hooks';
import utf8 from 'utf8';
import { IRoomSelecitonDialogState } from './RoomSelectionDialog.state';
import WaitingAreaMemoComposer from './WaitingAreaMemoComposer/WaitingAreaMemoComposer';

export const RoomSelectionDialogView: React.FC<IRoomSelecitonDialogState> = ({
    branding,
    eventName,
    visitorName,
    formFields,
    onWaitingRoomFormChange,
    letVisitorInside,
    changes,
    selectedVisitor,
    fetchedRooms,
    activeBtn,
    setActiveBtn,
    selectedArea,
    buttonLabelText,
    backToArea
}) => {
    const theme = useTheme();

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    useEffect(() => {
        if (fetchedRooms.length === 0) {
            setActiveBtn(true);
        }
    }, [fetchedRooms]);

    const [isWaitingAreaMemoDialogOpen, setIsWaitingAreaMemoDialogOpen] = useIsWaitingAreaMemoDialogOpen();

    return (
        <>
            <Stack style={{ width: 340, marginTop: 16 }}>
                <Stack verticalAlign={'center'} style={{ background: backgroundColor, borderRadius: 4, boxShadow: DefaultEffects.elevation64 }}>
                    <Text
                        variant="xLargePlus"
                        style={{
                            lineHeight: '35px',
                            borderRadius: 4,
                            padding: 20,
                            color: getTextColorForBackground(backgroundColor)
                        }}
                    >
                        {eventName}
                    </Text>
                    <Stack>
                        <Text
                            aria-label="meet-area-you-are-talking-to"
                            variant="medium"
                            style={{
                                lineHeight: '25px',
                                borderRadius: 4,
                                marginLeft: 20,
                                marginBottom: 20,
                                color: getTextColorForBackground(backgroundColor)
                            }}
                        >
                            {IvicosStrings.youAreTalkingTo}
                        </Text>
                        <Stack aria-label="meet-area-visitor-badge" verticalAlign="center" style={{ marginLeft: 20, marginBottom: 20 }}>
                            <Stack horizontal>
                                <Badge text={utf8.decode(visitorName || '')} size="50" fontSize="medium" />
                                <Text
                                    variant="large"
                                    style={{
                                        lineHeight: '25px',
                                        marginTop: 7,
                                        fontWeight: 'bold',
                                        marginLeft: 10,
                                        marginRight: 10,
                                        wordBreak: 'break-word',
                                        color: getTextColorForBackground(backgroundColor)
                                    }}
                                >
                                    {utf8.decode(visitorName || '')}
                                </Text>
                            </Stack>

                            <DefaultButton
                                text="Send memo"
                                styles={{
                                    root: {
                                        width: '37%',
                                        marginTop: 15,
                                        padding: 'auto'
                                    }
                                }}
                                onRenderIcon={() => <Memo style={{ marginRight: -2 }} />}
                                onClick={() => setIsWaitingAreaMemoDialogOpen(true)}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack style={{ backgroundColor: 'white' }}>
                    <Stack>
                        <Text
                            aria-label="meet-area-direct-visitor-to-room"
                            variant="large"
                            style={{
                                marginTop: 40,
                                fontWeight: 'bold',
                                marginLeft: 20,
                                marginBottom: 30,
                                color: theme.palette.black
                            }}
                        >
                            {IvicosStrings.directVisitorToRoom}
                        </Text>
                    </Stack>
                    <Stack style={{ width: '85%', marginLeft: 20 }}>
                        <DynamicForm formFields={formFields} onChange={onWaitingRoomFormChange}></DynamicForm>
                        {selectedArea && fetchedRooms.length === 0 && (
                            <Text style={{ fontWeight: 600, color: 'red' }}>{IvicosStrings.NoRoomAvailableDescription}</Text>
                        )}
                    </Stack>
                    <Stack horizontalAlign="end" style={{ padding: 16 }}>
                        <Stack style={{ flexDirection: 'row', padding: '10px', width: '100%', justifyContent: 'space-around' }}>
                            <DefaultButton
                                aria-label="back-to-area-button"
                                style={{ marginRight: '10px' }}
                                text={IvicosStrings.backToAreaButton}
                                onClick={backToArea}
                                iconProps={{ iconName: 'Leave' }}
                            />
                            <PrimaryButton
                                aria-label="meet-area-let-them-in-button"
                                text={buttonLabelText()}
                                disabled={activeBtn}
                                onClick={() => letVisitorInside(selectedVisitor || '', changes.room, changes.area.selectedKey)}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {isWaitingAreaMemoDialogOpen && (
                <WaitingAreaMemoComposer
                    recipientName={visitorName || ''}
                    recipientId={selectedVisitor || ''}
                    sentBy="host"
                    isOpen={isWaitingAreaMemoDialogOpen}
                    onClose={() => setIsWaitingAreaMemoDialogOpen(false)}
                />
            )}
        </>
    );
};
