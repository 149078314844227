import { aPlayingVideoId, aPlayingVideoOwnerId, aParticipantsList, aIsLocalUserVideoOwner, aPreviousVideoOwnerId } from 'features/LinkAttachmentModalBox/atom';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

export interface YoutubeVideoAttachmentManagerState {
    playVideo: (attachment: string) => void;
    stopVideo: () => void;
    changeVideoOwner: (newVideoOwnerId: string) => void;
    playingVideoId: string;
    playingVideoOwnerId: string;
    isLocalUserVideoOwner: boolean;
    videoWatchersList: ParticipantInfo[];
    isVideoOwnershipTransferedToLocal: boolean;
    previousVideoOwnerId: string;
    resetVideoState: () => void;
    setVideoState: (sharedVideoState: any) => void;
}

export interface ParticipantInfo {
    avatarURL?: string;
    displayName: string;
    formattedDisplayName: string;
    participantId: string;
}

export const useYoutubeVideoAttachmentManager: (jitsiAPI: any) => YoutubeVideoAttachmentManagerState = (jitsiAPI: any) => {
    const [playingVideoId, setPlayingVideoId] = useRecoilState(aPlayingVideoId);
    const [playingVideoOwnerId, setPlayingVideoOwnerId] = useRecoilState(aPlayingVideoOwnerId);
    const participantsList = useRecoilValue(aParticipantsList);
    const [isLocalUserVideoOwner, setIsLocalUserVideoOwner] = useRecoilState(aIsLocalUserVideoOwner);
    const [previousVideoOwnerId, setPreviousVideoOwnerId] = useRecoilState(aPreviousVideoOwnerId);

    const [isVideoOwnershipTransferedToLocal, setIsVideoOwnershipTransferedToLocal] = useState<boolean>(false);

    const [videoWatchersList, setVideoWatchersList] = useState<ParticipantInfo[]>([]);

    const resetVideoState = () => {
        useResetRecoilState(aPlayingVideoId);
        useResetRecoilState(aPlayingVideoOwnerId);
        useResetRecoilState(aParticipantsList);
        useResetRecoilState(aIsLocalUserVideoOwner);
        useResetRecoilState(aPreviousVideoOwnerId);
    };
    const setVideoState = async (sharedVideoState) => {
        const res = await jitsiAPI.getLocalParticipantId();
        const isLocalParticipantVideoOwner = res.localParticipantId === sharedVideoState.ownerId;
        setIsLocalUserVideoOwner(isLocalParticipantVideoOwner);
        const hasOwnerChanged = sharedVideoState && 'previousOwnerId' in sharedVideoState && sharedVideoState.previousOwnerId !== sharedVideoState.ownerId;

        if (hasOwnerChanged && isLocalParticipantVideoOwner) setIsVideoOwnershipTransferedToLocal(true);
        else if (hasOwnerChanged && previousVideoOwnerId === res.localParticipantId) jitsiAPI.requestSharedVideoStateUpdateFromVideoOwner();

        setPlayingVideoOwnerId(sharedVideoState.ownerId);
        setPlayingVideoId(sharedVideoState.videoUrl);
        setPreviousVideoOwnerId(sharedVideoState.previousOwnerId);
    };

    useEffect(() => {
        if (jitsiAPI && playingVideoId && playingVideoId !== '' && participantsList && participantsList.length > 0) {
            const videoWatcherParticipants = participantsList.filter((p: ParticipantInfo) => p.participantId !== playingVideoId);
            setVideoWatchersList(videoWatcherParticipants);
        } else setVideoWatchersList([]);
    }, [jitsiAPI, playingVideoId, participantsList]);

    const playVideo = (attachmentId: string) => {
        jitsiAPI.startShareVideo(`https://www.youtube.com/watch?v=${attachmentId}`);
    };

    const stopVideo = () => {
        jitsiAPI.stopShareVideo();
        setPlayingVideoId('');
        setPlayingVideoOwnerId('');
        setVideoWatchersList([]);
    };

    const changeVideoOwner = (newVideoOwnerId: string) => {
        jitsiAPI.updateSharedVideoOwner(newVideoOwnerId);
        jitsiAPI.requestSharedVideoStateUpdateFromVideoOwner();
    };

    useEffect(() => {
        if (!isLocalUserVideoOwner && playingVideoId && playingVideoId !== '') jitsiAPI && jitsiAPI.pinParticipant(playingVideoId);
    }, [playingVideoId]);

    return {
        playVideo,
        stopVideo,
        changeVideoOwner,
        playingVideoId,
        playingVideoOwnerId,
        isLocalUserVideoOwner,
        videoWatchersList,
        isVideoOwnershipTransferedToLocal,
        previousVideoOwnerId,
        resetVideoState,
        setVideoState
    };
};
