import IvicosStrings from 'kits/language/stringKit';
import tldraw from 'media/img/tldraw.svg';

// import collaboard from 'media/img/collaboard.svg';
import youtube from 'media/img/youtube.svg';

export const hardcodedAddOns = [
    {
        id: 'youtubelinkmanager',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate: 'https://www.youtube.com/watch?v=${ref}',
            iconUrl: youtube,
            displayName: IvicosStrings.youtubeLinkTypeTitle,
            description: IvicosStrings.youtubeLinkTypeDescription
        },
        relationships: {}
    },
    {
        id: 'linkmanager',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate: 'https://static-cdn.vercel.app/api/go?to=${ref}',
            iconUrl: 'https://static-cdn.vercel.app/link.svg',
            displayName: IvicosStrings.linkTypeTitle,
            description: IvicosStrings.linkTypeDescription
        },
        relationships: {}
    },
    // {
    //     id: 'collaboardlinkmanager',
    //     type: 'iv_campus_addon',
    //     attributes: {
    //         // urlTemplate: 'https://web.collaboard.app/collaboard/${ref}?oneTimeToken=${token}',
    //         urlTemplate: 'https://web.collaboard.app/collaboard/${ref}',
    //         displayName: IvicosStrings.collaboardTypeTitle,
    //         iconUrl: collaboard,
    //         description: IvicosStrings.collaboardTypeDescription
    //     },
    //     relationships: {}
    // },
    {
        id: 'etherpad',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate:
                'https://etherpad.ivicos-campus.app/p/${ref}?showControls=true&showChat=true&showLineNumbers=true&useMonospaceFont=false&userName=${userName}',
            displayName: IvicosStrings.etherpadTypeTitle,
            iconUrl: 'https://static-cdn.vercel.app/etherpad.svg',
            description: IvicosStrings.etherpadTypeDescription
        },
        relationships: {}
    },
    {
        id: 'tldraw',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate: 'https://tldraw.com/r/ivicos-${ref}',
            displayName: IvicosStrings.tldrawTypeTitle,
            iconUrl: tldraw,
            description: IvicosStrings.tldrawTypeDescription
        },
        relationships: {}
    }
];

export const parseUrlTemplate = (addOn: any, roomId: string, attachmentRef: string, displayName: string): any => {
    const template = addOn.attributes.urlTemplate;

    return template.replace('${roomId}', roomId).replace('${ref}', attachmentRef).replace('${userName}', encodeURIComponent(displayName));
};
