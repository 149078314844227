import * as React from 'react';

function SvgHideAll(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M19.9007 1.23889C19.9793 1.13234 20.0132 0.999376 19.9953 0.868217C19.9775 0.737058 19.9092 0.618019 19.805 0.536355C19.7009 0.45469 19.569 0.416827 19.4373 0.430798C19.3057 0.444768 19.1847 0.509473 19.1 0.611184C19.0593 0.662079 14.9334 5.84316 10 5.84316C5.0666 5.84316 0.940734 0.662079 0.900019 0.607791C0.815304 0.50608 0.694295 0.441375 0.562664 0.427405C0.431033 0.413434 0.299137 0.451297 0.19496 0.532962C0.0907835 0.614626 0.022522 0.733665 0.00465786 0.864825C-0.0132062 0.995984 0.0207328 1.12894 0.0992746 1.23549C0.868318 2.17353 1.72871 3.03279 2.66776 3.80059L1.24271 5.22564C1.19271 5.27224 1.1526 5.32842 1.12478 5.39085C1.09697 5.45328 1.08201 5.52068 1.0808 5.58901C1.0796 5.65735 1.09217 5.72523 1.11776 5.7886C1.14336 5.85198 1.18146 5.90955 1.22979 5.95787C1.27812 6.0062 1.33569 6.0443 1.39906 6.0699C1.46243 6.0955 1.53031 6.10807 1.59865 6.10686C1.66699 6.10566 1.73438 6.0907 1.79681 6.06288C1.85924 6.03506 1.91543 5.99496 1.96202 5.94495L3.47529 4.43168C5.09035 5.61244 7.17364 6.70159 9.48766 6.8441V9.0665C9.48766 9.20148 9.54128 9.33093 9.63673 9.42638C9.73217 9.52183 9.86163 9.57545 9.99661 9.57545C10.1316 9.57545 10.261 9.52183 10.3565 9.42638C10.4519 9.33093 10.5056 9.20148 10.5056 9.0665V6.8441C12.823 6.70159 14.9164 5.61244 16.5179 4.43168L18.0312 5.94495C18.1277 6.03485 18.2553 6.0838 18.3871 6.08147C18.519 6.07915 18.6448 6.02573 18.738 5.93248C18.8313 5.83924 18.8847 5.71343 18.887 5.58158C18.8893 5.44973 18.8404 5.32212 18.7505 5.22564L17.3255 3.80059C18.2667 3.0342 19.1294 2.17607 19.9007 1.23889Z"
                fill="black"
            />
        </svg>
    );
}

export default SvgHideAll;
