import { IPersonaProps, PersonaPresence } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import { availabilityToPersonaPresence } from 'kits/availabilityKit';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useState } from 'react';
import { useCurrentOrgBranding, useDateToTimeStamp, useFetchRoomLists, useGetSpecificDay } from 'shared-state/directory/hooks';
import { useLocalProfile, useSharedAvailability } from 'shared-state/identity/hooks';
import { useCurrentCampusId } from 'shared-state/location/hook';
import { formFieldsForInvitationCreation, IAreaListsDetails, ICreateInvitationPanelState, IParsedInvitation, IRoomListsDetails } from './Data';
import { changeCustomUrlInputField, createInvitation, saveCustomizedVideoUrlForWaitingRoom, updateInvitationCustomUrl } from './hooks';
import { IInvitationOptions } from './types';
import { getWeekDay } from './hooks';

export const useCreateInvitationPanelState: (
    areaNamesList: IAreaListsDetails[],
    members: IPersonaProps[] | undefined,
    dismissCreateInvitationPanel: () => void
) => ICreateInvitationPanelState = (areaNamesList, members) => {
    const currentCampusId = useCurrentCampusId();
    const [invitationLink, setInvitationLink] = useState<string>('');
    const [newCreatedInvitation, setNewCreatedInvitation] = useState<IParsedInvitation | undefined>(undefined);
    const [localAvailability] = useSharedAvailability();
    const creatorOfTheInvitation = useLocalProfile();
    const creatorAsTheFirstHost = {
        id: creatorOfTheInvitation?.uid,
        primaryText: creatorOfTheInvitation?.displayName,
        imageUrl: creatorOfTheInvitation?.profileImage,
        presence: (localAvailability && availabilityToPersonaPresence(localAvailability)) || PersonaPresence.online
    };
    const [orgBranding] = useCurrentOrgBranding();

    const [changes, setChanges] = useState<any>({
        name: '',
        validity: 'single',
        recurringType: {
            selectedKey: 'everyDay'
        },
        on: { selectedKey: 'monday' },
        hosts: [creatorOfTheInvitation?.uid],
        visitorEntrance: 'direct'
    });

    const [selectedMembers] = useState<IPersonaProps[] | undefined>([creatorAsTheFirstHost]);
    const [fetchedRooms, setFetchedRooms] = useState<IRoomListsDetails[]>([]);
    const [isDisable, { setTrue: disableButton, setFalse: notDisableButton }] = useBoolean(true);
    const [validityTimeOfSingle, setValidityTimeOfSingle] = useState<any>({
        openFrom: 0,
        openTo: 0
    });

    const [validityTimeOfRecurring, setValidityTimeOfRecurring] = useState<any>({
        openFrom: 0,
        openTo: 0
    });

    const [selectedFromDate, setSelectedFromDate] = useState<Date | undefined>();
    const [selectedToDate, setSelectedToDate] = useState<Date | undefined>();
    const [updateIsOpen, setUpdateIsOpen] = useState(false);
    const [selectedArea, setSelectedArea] = useState(false);
    const [invitationCustomUrl, setInvitationCustomUrl] = useState('');

    const [editablePartOfTheLink, setEditablePartOfTheLink] = useState('');
    const [newEditablePartOfTheLink, setNewEditablePartOfTheLink] = useState('');
    const [customizedVideoUrlForWaitingRoom, setCustomizedVideoUrlForWaitingRoom] = useState('');
    const [displayAlert, { toggle: toggleAlert }] = useBoolean(false);

    useEffect(() => {
        changes['validToTime'] = undefined;
        changes['validFromTime'] = undefined;
    }, [changes.validity]);

    useEffect(() => {
        if (changes['validFromDate']) {
            const dateFromSelected = new Date(changes['validFromDate']);
            setSelectedFromDate(dateFromSelected);
            if (selectedToDate && dateFromSelected > selectedToDate) {
                changes['validToDate'] = undefined;
                setSelectedToDate(undefined);
            }
        }
        if (changes['validToDate']) {
            const dateToSelected = new Date(changes['validToDate']);
            setSelectedToDate(dateToSelected);
        }
    }, [changes]);

    useEffect(() => {
        setFormFields(formFieldsForInvitationCreation(changes, selectedFromDate, selectedToDate, selectedMembers, areaNamesList, fetchedRooms, members));
    }, [members, fetchedRooms, changes, selectedFromDate, selectedToDate]);

    useEffect(() => {
        if (changes.name.length > 1 && changes['validFromTime'] && changes['validToTime'] && changes.hosts[0]) {
            if (changes.validity === 'single') {
                if (changes['visitorEntrance'] == 'direct' && changes.area && changes.room) {
                    notDisableButton();
                } else if (changes['visitorEntrance'] == 'pickup') {
                    setSelectedArea(false);
                    notDisableButton();
                } else {
                    disableButton();
                }
            } else {
                if (changes['visitorEntrance'] == 'direct' && changes.area && changes.room) {
                    notDisableButton();
                } else if (changes['visitorEntrance'] == 'pickup') {
                    setSelectedArea(false);
                    notDisableButton();
                } else {
                    disableButton();
                }
            }
        } else {
            disableButton();
        }

        const validityTimeFrameForSingle = () => {
            const validFrom =
                changes['validFromDate'] && changes['validFromTime']
                    ? useDateToTimeStamp(changes['validFromDate'], changes['validFromTime'].selectedKey)
                    : null;

            const validTo =
                changes['validToDate'] && changes['validToTime'] ? useDateToTimeStamp(changes['validToDate'], changes['validToTime'].selectedKey) : null;

            if (validFrom && validTo) {
                const differenceInTimeStamp = validTo - validFrom;

                if (differenceInTimeStamp < 0) {
                    toggleAlert();
                } else {
                    setValidityTimeOfSingle({ openFrom: validFrom, openTo: validTo });
                }
            }
        };

        const validityTimeFrameForRecurring = () => {
            const dayName: any = changes['recurringType'].selectedKey === 'everyDay' ? new Date().getDay() : changes.on.selectedKey;

            if (dayName && changes['validFromTime'] && changes['validToTime']) {
                const nextDate = useGetSpecificDay(dayName, changes['validFromTime'].selectedKey, changes['validToTime'].selectedKey);

                if (nextDate) {
                    setValidityTimeOfRecurring({ openFrom: nextDate.timeStampOfFromTime, openTo: nextDate.timeStampOfToTime });
                }
            }
        };

        if (changes.validity === 'single') {
            validityTimeFrameForSingle();
            setValidityTimeOfRecurring({ openFrom: null, openTo: null });
        } else {
            validityTimeFrameForRecurring();
            setValidityTimeOfSingle({ openFrom: 0, openTo: 0 });
        }
    }, [changes]);

    const [formFields, setFormFields] = useState<IDynamicFormElementProps[]>(
        formFieldsForInvitationCreation(changes, selectedFromDate, selectedToDate, selectedMembers, areaNamesList, fetchedRooms, members)
    );

    const onChange = (change: { [index: string]: string | boolean }) => {
        setChanges((prevState: any) => {
            const newState = { ...prevState, ...change };
            return newState;
        });
    };

    const onCreateInviteFormChange = (key: string, newValue: any) => {
        if (key == 'area') {
            const newSelectedArea = newValue.options.find((val: any) => {
                return newValue.selectedKey == val.key;
            });
            if (newSelectedArea.key) {
                setSelectedArea(true);
                localStorage.setItem('storedAreaId', newSelectedArea.key);
            }
        }

        onChange({ [key]: newValue });
        setFormFields((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };

    const storedAreaId: string | null = localStorage.getItem('storedAreaId');

    useEffect(() => {
        if (currentCampusId[0] && storedAreaId) {
            useFetchRoomLists(currentCampusId[0], storedAreaId, creatorOfTheInvitation?.uid || '', setFetchedRooms);
        }
    }, [storedAreaId]);

    const newInvitation = (changes: any) => {
        const newInvitationToCreate: IInvitationOptions = {
            hosts: changes.hosts,
            event_name: changes.name,
            type: changes.validity === 'single' ? 'single' : 'recurring',
            repeat: changes.validity === 'single' ? 'no repeat' : changes.recurringType?.selectedKey === 'everyDay' ? 'daily' : 'weekly',
            from:
                changes.validity === 'single' && validityTimeOfSingle.openFrom
                    ? validityTimeOfSingle.openFrom
                    : changes.validity === 'recurring' && validityTimeOfRecurring.openFrom
                    ? validityTimeOfRecurring.openFrom
                    : 0,
            to:
                changes.validity === 'single' && validityTimeOfSingle.openTo
                    ? validityTimeOfSingle.openTo
                    : changes.validity === 'recurring' && validityTimeOfRecurring.openTo
                    ? validityTimeOfRecurring.openTo
                    : 0,
            campusId: currentCampusId[0] || '',
            areaId: changes.visitorEntrance === 'direct' ? changes.area.selectedKey : '',
            roomId: changes.visitorEntrance === 'direct' ? changes.room.selectedKey : '',
            welcome_message: changes.visitorEntrance === 'pickup' ? (changes.message ? changes.message : 'wElCoMe_MeSsAgE') : 'wElCoMe_MeSsAgE'
        };
        return newInvitationToCreate;
    };

    const onModalDismiss = () => {
        setUpdateIsOpen(!updateIsOpen);
    };

    const onCreateInvitation = () => {
        const newInvitationToCreate = newInvitation(changes);

        createInvitation(
            newInvitationToCreate,
            setInvitationLink,
            currentCampusId,
            setInvitationCustomUrl,
            setEditablePartOfTheLink,
            setNewCreatedInvitation,
            setNewEditablePartOfTheLink
        );
    };

    const invitationLinkWithCampusName = invitationLink && invitationCustomUrl && `${invitationLink.split('/i/')[0]}/i/${invitationCustomUrl.split('/')[0]}/`;

    const onUpdateInvitationCustomUrl = () => {
        newEditablePartOfTheLink == editablePartOfTheLink
            ? setUpdateIsOpen(false)
            : updateInvitationCustomUrl(
                  invitationLink.split('/i/')[1],
                  newEditablePartOfTheLink,
                  setUpdateIsOpen,
                  setCustomPathError,
                  setEditablePartOfTheLink,
                  setNewEditablePartOfTheLink
              );
    };

    const [customPathError, setCustomPathError] = useState('');

    const onChangeCustomUrlInputField = (value: string | undefined) => {
        changeCustomUrlInputField(value, setCustomPathError, setNewEditablePartOfTheLink);
    };

    const validation =
        newCreatedInvitation?.validity === 'recurring'
            ? {
                  frequency: newCreatedInvitation.repeat === 'weekly' ? getWeekDay(newCreatedInvitation) : 'everyday',
                  validFromTime: newCreatedInvitation.validFromTime || '',
                  validToTime: newCreatedInvitation.validToTime || ''
              }
            : {
                  frequency: '',
                  validFromTime: newCreatedInvitation?.validFromDate || '',
                  validToTime: newCreatedInvitation?.validToDate || ''
              };
    const emailSubject = IvicosStrings.invitationEmailSubject.replace('{orgName}', orgBranding.orgName);
    const emailBody = IvicosStrings.invitationEmailBody
        .replace('{invName}', newCreatedInvitation?.name || 'ivCAMPUS')
        .replace('{frequency}', validation.frequency)
        .replace('{validFromTime}', validation.validFromTime.toString())
        .replace('{validToTime}', validation.validToTime.toString())
        .replace('{invitationLink}', `${invitationLinkWithCampusName}${editablePartOfTheLink}`);

    const onSavingVideoUrlForWaitingRoom = (defaultVideoIsUsed: boolean, defaultVideoAtVisitorEntrance = '') => {
        if (!invitationLink) return;
        saveCustomizedVideoUrlForWaitingRoom(
            invitationLink.split('/i/')[1],
            customizedVideoUrlForWaitingRoom,
            defaultVideoIsUsed,
            defaultVideoAtVisitorEntrance
        );
    };

    return {
        formFields,
        onCreateInviteFormChange,
        onCreateInvitation,
        isDisable,
        updateIsOpen,
        onModalDismiss,
        invitationLinkWithCampusName,
        editablePartOfTheLink,
        setUpdateIsOpen,
        onUpdateInvitationCustomUrl,
        customPathError,
        onChangeCustomUrlInputField,
        selectedArea,
        fetchedRooms,
        newCreatedInvitation,
        setNewCreatedInvitation,
        newEditablePartOfTheLink,
        setChanges,
        creatorOfTheInvitation,
        emailSubject,
        emailBody,
        customizedVideoUrlForWaitingRoom,
        setCustomizedVideoUrlForWaitingRoom,
        onSavingVideoUrlForWaitingRoom,
        toggleAlert,
        displayAlert
    };
};
