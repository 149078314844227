import { ActionButton, DefaultButton, Panel, PanelType, Stack, Text } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import { useBoolean } from '@uifabric/react-hooks';
import AlertModal from 'components/AlertModals/AlertModal';
import AreaList from 'features/Areas/AreaList/AreaList';
import SvgLeaveCampus from 'kits/IconKit2/LeaveCampus';
import { signOut } from 'kits/authKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useNavigate } from 'react-router';
import { useCampus } from 'shared-state/directory/hooks';
import { useShowAreaPanel } from 'shared-state/display-options/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';

const AreasPanelView: React.FC<{ showPanel: any; setShowPanel: any }> = ({ showPanel, setShowPanel }) => {
    const theme = useTheme();
    const [identity] = useIdentityClaims();
    const routeHistory = useNavigate();
    const [campus] = useCampus();
    const [, setShowAreaPanel] = useShowAreaPanel();
    const isVisitor = identity?.roles.includes('visitor') || identity?.roles.includes('auto-confirmed-visitor');
    const [displayAlert, { toggle: toggleAlert }] = useBoolean(false);

    const actions = [
        {
            key: 'switch-org',
            title: IvicosStrings.switchOrgActionTitle,
            symbol: 'EMI',
            disabled: isVisitor,
            action: () => {
                setShowAreaPanel(false);
                routeHistory('/welcome');
            }
        },
        {
            key: 'leave',
            title: IvicosStrings.leaveActionTitle,
            action: () => {
                toggleAlert();
            }
        },
        {
            key: 'sign-out',
            title: IvicosStrings.logOutFromSystem,
            titleTwo: IvicosStrings.logOutFromSystemTwo,
            symbol: 'PowerButton',
            action: () => {
                signOut(false);
            }
        }
    ];

    const onRenderHeader = React.useCallback(
        () => (
            <>
                {!isVisitor && (
                    <Stack style={{ width: '85%' }}>
                        <Text variant="xLarge">Navigator </Text>
                        <Stack style={{ paddingTop: '9px', width: '60%' }}>
                            {window.location.href.split('areas/')[1] && (
                                <DefaultButton
                                    iconProps={{ iconName: 'Home' }}
                                    text={IvicosStrings.backToDashboardTitle}
                                    aria-label="back-to-lobby-button"
                                    onClick={() => (window.location.href = '/areas')}
                                />
                            )}
                        </Stack>
                    </Stack>
                )}
            </>
        ),
        [showPanel]
    );

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack
                horizontal={false}
                verticalAlign="center"
                horizontalAlign="start"
                tokens={{ childrenGap: 15 }}
                style={{ position: 'fixed', bottom: 0, backgroundColor: 'white', width: '420px', padding: '0px 0px 24px 24px' }}
            >
                {/* implement back to the lobby here? */}
                <Text variant="medium">
                    {IvicosStrings.loggedInto} <b aria-label="organisation-name">{campus?.name}.</b>
                </Text>
                {/* switch org */}
                <DefaultButton
                    text={actions[0].title}
                    onClick={() => {
                        actions[0].action && actions[0].action();
                    }}
                    iconProps={{ iconName: actions[0].symbol }}
                    disabled={actions[0].disabled && actions[0].disabled}
                />
                {/* leave */}
                <DefaultButton
                    text={actions[1].title}
                    onClick={() => {
                        actions[1].action && actions[1].action();
                    }}
                    onRenderIcon={() => <SvgLeaveCampus style={{ margin: '0px 4px 0px 7px' }} />}
                />
                {/* log out */}
                <ActionButton
                    onClick={() => {
                        actions[2].action && actions[2].action();
                    }}
                    style={{ padding: 0, height: 18.5 }}
                >
                    <Text
                        style={{
                            color: theme.palette.black,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            marginRight: 4
                        }}
                    >
                        {actions[2].title}
                        <span style={{ fontWeight: 'normal' }}>,</span>
                    </Text>
                    <Text>{actions[2].titleTwo}</Text>
                </ActionButton>
            </Stack>
        ),
        [showPanel]
    );

    return (
        <Panel
            styles={{ main: { background: theme.palette.white } }}
            type={PanelType.customNear}
            customWidth={'420px'}
            isOpen={showPanel}
            isLightDismiss
            onDismiss={() => setShowPanel(false)}
            isFooterAtBottom={true}
            onRenderFooter={onRenderFooterContent}
            onRenderHeader={onRenderHeader}
            hasCloseButton={true}
            aria-label="navigator-panel"
        >
            <AreaList vertical onSelection={() => setShowPanel(false)} />
            {displayAlert && <AlertModal title={actions[1].title} subText={IvicosStrings.leaveActionMessage} toggleAlert={toggleAlert} />}
        </Panel>
    );
};

export default AreasPanelView;
