import { getTheme, ITheme, mergeStyles } from '@fluentui/react';
import React from 'react';
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';
import emoji from 'remark-emoji';
interface IMarkdownHostProps {
    children?: string;
}

export const useMarkdownStyles = (theme: ITheme): any => {
    const markDownStyle = mergeStyles({
        '& a': {
            color: theme.palette.black,
            fontWeight: 'bold'
        },
        '& img': {
            width: '100%'
        },
        '& blockquote': {
            borderLeft: '2px ' + theme.palette.black + 'solid'
        },
        '& a:hover': {
            color: theme.palette.themePrimary,
            fontWeight: 'bold'
        },
        '& a:active': {
            color: theme.palette.themeDark,
            fontWeight: 'bold'
        }
    });
    return markDownStyle;
};

const MarkdownHost: React.FC<IMarkdownHostProps> = ({ children }) => {
    const theme = getTheme();
    const markDownStyle = useMarkdownStyles(theme);

    return (
        <Markdown linkTarget="_blank" className={markDownStyle} remarkPlugins={[gfm, [emoji, { padSpaceAfter: false, emoticon: false }]]}>
            {children || ''}
        </Markdown>
    );
};

export default MarkdownHost;
