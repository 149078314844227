import { Stack, Text } from '@fluentui/react';
import React from 'react';
import AreaSegment from './AreaSegment';
import { IDirectMessagingViewProps } from '../dataTypes';
import IvicosStrings from 'kits/language/stringKit';

const DirectMessagingView: React.FC<IDirectMessagingViewProps> = ({
    expandedIcons,
    handleToggleThisArea,
    handleToggleOtherArea,
    handleToggleDashboard,
    iconNameForThisArea,
    iconNameForOtherArea,
    iconNameForDashboard,
    currentAreaId,
    areas,
    expandedAreas,
    handleToggleAreas,
    filteredUsers,
    isNotFilteredArrayEmpty,
    textColor,
    mobileView,
    crossAreaSearchBarInputValue,
    setIsCrossAreaUsersVisible,
    currentRoomId
}) => {
    const { outsideOfAnArea, insideOfAnArea, inCurrentArea, inOtherAreas } = filteredUsers;
    const excludeExistingUsers = insideOfAnArea.filter((user) => user.room !== currentRoomId);

    if (crossAreaSearchBarInputValue.length <= 0) {
        setIsCrossAreaUsersVisible(false);
        return null;
    }

    if (![outsideOfAnArea, excludeExistingUsers, inOtherAreas].some(isNotFilteredArrayEmpty)) {
        return (
            <Stack
                style={{
                    height: 70,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                }}
                aria-label="search-person-not-found"
            >
                <Text
                    style={{
                        color: mobileView ? '#000' : textColor,
                        fontWeight: 'bold',
                        margin: mobileView ? 0 : '0 15px'
                    }}
                >
                    {IvicosStrings.crossAreaSearchPersonNotFound}
                </Text>
            </Stack>
        );
    }

    return (
        <Stack
            style={{
                margin: mobileView ? '15px 15px 15px 0px' : '15px 30px 15px 15px',
                overflowY: 'auto',
                msOverflowX: 'hidden'
            }}
            tokens={{ childrenGap: 12 }}
        >
            {currentAreaId ? (
                <>
                    {/* user(who is searching) is inside of an area */}
                    {/* user(s) appears on search results are in the same area where the user(who is searching) is */}
                    <AreaSegment
                        areaName={IvicosStrings.crossAreaSearchPersonThisArea}
                        users={inCurrentArea.filter((user) => user.room !== currentRoomId)}
                        iconName={iconNameForThisArea}
                        handleToggleArea={handleToggleThisArea}
                        expanded={expandedIcons.isThisAreaIconExpanded}
                        textColor={textColor}
                        mobileView={mobileView}
                    />

                    {/* user(s) appears on search results are not in the same area where the user(who is searching) is */}
                    <AreaSegment
                        areaName={IvicosStrings.crossAreaSearchPersonOtherArea}
                        users={inOtherAreas}
                        iconName={iconNameForOtherArea}
                        handleToggleArea={handleToggleOtherArea}
                        expanded={expandedIcons.isOtherAreaIconExpanded}
                        textColor={textColor}
                        mobileView={mobileView}
                    />
                </>
            ) : (
                <>
                    {/* user(who is searching) is outside of an area */}
                    {areas.map((area: any) => (
                        <AreaSegment
                            areaName={area.name}
                            users={insideOfAnArea.filter((user) => user.userInThisArea === area.id)}
                            iconName={!expandedAreas.includes(area.id) ? 'ChevronDownMed' : 'ChevronRightMed'}
                            handleToggleArea={() => handleToggleAreas(area.id)}
                            expanded={!expandedAreas.includes(area.id)}
                            key={area.id}
                            textColor={textColor}
                            mobileView={mobileView}
                        />
                    ))}
                </>
            )}

            {/* user(s) appears on search results are in the lobby */}
            <AreaSegment
                areaName={IvicosStrings.crossAreaSearchPersonDashboard}
                users={outsideOfAnArea}
                iconName={iconNameForDashboard}
                handleToggleArea={handleToggleDashboard}
                expanded={expandedIcons.isDashboardIconExpanded}
                textColor={textColor}
                mobileView={mobileView}
            />
        </Stack>
    );
};

export default DirectMessagingView;
