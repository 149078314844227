import { Link, Stack, Text } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import accountCreationUnsuccessful from 'media/img/AccountCreationUnsuccessful.svg';

const AccountCreationSomethingWentWrong: React.FC<{}> = () => {
    return (
        <Stack>
            <Stack
                style={{
                    background: 'linear-gradient(150deg, #E4E3E4 41.98%, #E4E4E3 54.26%, rgba(255, 255, 255, 0.807062) 58.41%, #E4E4E3 62.45%, #E4E4E3 74.73%)',
                    flexDirection: 'column',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    style={{
                        height: 443,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack
                        style={{
                            margin: '69px 23px 69px 22px',
                            height: 305,
                            width: 330
                        }}
                    >
                        <img
                            src={accountCreationUnsuccessful}
                            alt="something went wrong"
                            width={70}
                            height={70}
                            style={{
                                alignSelf: 'center',
                                marginTop: 19
                            }}
                        />

                        <Text
                            variant="xLarge"
                            style={{
                                marginTop: 51,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            {IvicosStrings.somethingWrongTitle}
                        </Text>
                        <Stack
                            style={{
                                marginTop: 51,
                                marginLeft: 25
                            }}
                        >
                            <Text variant="medium" style={{ marginTop: 5 }}>
                                {IvicosStrings.accountCreationSomethingWentWrongDescription1}
                            </Text>
                            <Text variant="medium" style={{ marginTop: 23 }}>
                                {IvicosStrings.accountCreationSomethingWentWrongDescription2}{' '}
                                <Link
                                    style={{ color: '#000', fontWeight: 700 }}
                                    href={'mailto:support@ivicos.eu?subject=Authentication%20process%20is%20not%20working'}
                                    target="_blank"
                                >
                                    {IvicosStrings.here}
                                </Link>{' '}
                                {IvicosStrings.orVisitOur}{' '}
                                <Link style={{ color: '#000', fontWeight: 700 }} href="https://help.ivicos.eu/en/HELP-ivcampus" target="_blank">
                                    {IvicosStrings.helpPage}
                                </Link>
                                .
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AccountCreationSomethingWentWrong;
