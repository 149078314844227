import React from 'react';
import { useTextFieldWithMaxLengthState } from './TextFieldWithMaxLength.state';
import TextFieldWithMaxLengthView from './TextFieldWithMaxLength.view';

interface ITextFieldWithMaxLength {
    label?: string;
    value?: string;
    onChange: any;
    errorMessage?: string;
    placeholder?: string;
    maxLength?: number;
    required?: boolean;
    callout?: string;
    dataTestId?: string;
}

const TextFieldWithMaxLength: React.FC<ITextFieldWithMaxLength> = (props) => {
    const state = useTextFieldWithMaxLengthState(props.onChange, props.maxLength || 99999999999999);

    return (
        <TextFieldWithMaxLengthView
            {...{
                ...state,
                ...{
                    label: props.label,
                    value: props.value,
                    placeholder: props.placeholder,
                    errorMessage: props.errorMessage,
                    maxLength: props.maxLength,
                    required: props.required,
                    callout: props.callout,
                    dataTestId: props.dataTestId
                }
            }}
        ></TextFieldWithMaxLengthView>
    );
};

export default TextFieldWithMaxLength;
