import { Label, List, Stack } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import IvicosStrings from 'kits/language/stringKit';
import React, { ReactElement, useRef } from 'react';
import ListedRoom from '../ListedRoom/ListedRoom';
import { PersonalRoomListState } from './PersonalRoomList.state';

const PersonalRoomListView: React.FC<PersonalRoomListState> = (props) => {
    const theme = useTheme();
    const personalRoomContainerRef = useRef<HTMLDivElement>(null);

    const onRenderCell = (roomId: string | undefined): ReactElement => {
        if (!roomId) return <></>;
        return <ListedRoom roomId={roomId} />;
    };

    return (
        <React.Fragment>
            <div
                ref={personalRoomContainerRef}
                style={{
                    width: '100%',
                    height: '50%',
                    maxHeight: '90%',
                    minHeight: 128
                    // borderBottom: '1px ' + theme.palette.neutralTertiary + ' solid'
                }}
            >
                <div style={{ height: '100%', position: 'relative' }}>
                    <div
                        style={{
                            background: `linear-gradient(0deg,${theme.palette.neutralLight},${theme.palette.neutralLight},transparent)`,
                            height: 32,
                            minHeight: 16,
                            zIndex: 20,
                            position: 'absolute',
                            bottom: 0,
                            pointerEvents: 'none',
                            width: '100%'
                        }}
                    ></div>

                    <Stack className={'personal-room'} style={{ height: 'calc( 100% - 16px )' }}>
                        <Label>{IvicosStrings.personalRoomsSectionTitle}</Label>
                        <Stack style={{ direction: 'ltr', overflowY: 'auto', marginTop: 10, marginBottom: 10 }}>
                            <List items={props.personalRoomIds} onRenderCell={onRenderCell} />
                        </Stack>
                    </Stack>
                </div>
                <Stack horizontalAlign="center" verticalAlign="space-around" style={{ height: '7px', textAlign: 'center' }}>
                    <Stack style={{ height: '1px', width: '10%', backgroundColor: theme.palette.neutralTertiary }}></Stack>
                    <Stack style={{ height: '1px', width: '100%', backgroundColor: theme.palette.neutralTertiary }}></Stack>
                    <Stack style={{ height: '1px', width: '10%', backgroundColor: theme.palette.neutralTertiary }}></Stack>
                </Stack>
            </div>
        </React.Fragment>
    );
};
export default PersonalRoomListView;
