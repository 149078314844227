import { OrgBrandingDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import { useEvent } from 'kits/eventKit';
import { useEffect } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { useCurrentOrgBranding } from 'shared-state/directory/hooks';

export const useOrganizationBranding = (orgId: string | undefined): [OrgBrandingDto | undefined, Function] => {
    const [branding, setBranding] = useCurrentOrgBranding();
    const fetchBranding = async () => {
        if (!orgId) return;
        const nextBranding = await idpService.getOrgBranding(orgId);
        if (!nextBranding) return;
        const brandingCleaned = { ...nextBranding, ...{ orgLogo: nextBranding.orgLogo.replace('none', '') } };
        setBranding(brandingCleaned);
    };

    useEffect(() => {
        fetchBranding();
    }, [orgId]);

    useEvent('refetchBranding', () => {
        setTimeout(() => fetchBranding(), 500);
    });

    return [branding, fetchBranding];
};
