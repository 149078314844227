import { DefaultButton, IPersonaProps, Stack, Text } from '@fluentui/react';
import Badge from 'components/Badge';
import { useInvitationLogoState } from 'components/InvitationLogo/InvitationLogo.state';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useNavigate } from 'react-router';
import { DefaultValue } from 'recoil';
import { useShowInvitationsManagerModal } from 'shared-state/display-options/hooks';
import { useTabOption } from 'shared-state/tabs/hooks';

interface IVisitor {
    name: string;
    key: string;
    host: string;
    members: IPersonaProps[] | undefined;
    invitationId: string;
    subInfo: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const VisitorCard: React.FC<IVisitor> = (props: any) => {
    const { name, host, members, invitationId, subInfo } = props;

    const hostSplit = host.split(', ');

    const hostSplitMap = hostSplit.map((host: any) => host.split(':')[2]);

    const allHost: any = [];

    hostSplitMap?.forEach((host: string) => {
        const person = members?.find((member: any) => member.id == host);

        allHost.push(person?.primaryText);
    });

    const invitation = useInvitationLogoState(invitationId);

    const routeHistory = useNavigate();

    const [, setTabOption] = useTabOption('sidebar', 'visitors');

    const [invitationsManagerModal, setInvitationsManagerModal] = useShowInvitationsManagerModal();

    const handleMeetBtn = () => {
        if (invitationsManagerModal) {
            setInvitationsManagerModal(false);
        }

        setTabOption((prevOption: any) => {
            if (!prevOption) return;
            if (prevOption instanceof DefaultValue) return prevOption;

            return { ...prevOption, ...{ isSelected: true } };
        });

        routeHistory(`/meet/visitor-${subInfo.split(':')[2]}`);
    };

    return (
        <Stack style={{ width: '300px', borderRadius: '4px', border: '0.5px solid black', marginTop: 30 }}>
            <Stack horizontal>
                <Stack horizontal horizontalAlign="center" style={{ width: '57px', height: '104px', paddingTop: '5px' }}>
                    <Badge size={'42'} fontSize={'medium'} text={name} />
                </Stack>
                <Stack horizontal={false} verticalAlign="space-around" style={{ width: '242px', height: '104px', padding: '10px 0px 0px 10px' }}>
                    <Text variant="xLarge">{name}</Text>
                    <Stack>
                        <Stack horizontal>
                            <Text variant="medium" style={{ fontWeight: 'bold', marginTop: 5 }}>
                                {invitation.eventName}
                            </Text>
                        </Stack>
                        <Stack horizontal style={{ marginTop: 5 }}>
                            <Text variant="small" style={{ fontWeight: 'bold' }}>
                                {IvicosStrings.hostsTitle}:
                            </Text>
                            <Text variant="small" style={{ paddingLeft: 5 }}>
                                {allHost.join(', ')}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack horizontal horizontalAlign="end" verticalAlign="center" style={{ width: '300px', height: '57px' }}>
                <DefaultButton text={IvicosStrings.meetActionTitle} style={{ marginRight: '15px' }} onClick={() => handleMeetBtn()} />
            </Stack>
        </Stack>
    );
};

export default VisitorCard;
