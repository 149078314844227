import React, { useContext } from 'react';
import {
    Grid, // our UI Component to display the results
    SearchBar, // the search bar the user will type into
    SearchContext, // the context that wraps and connects our components
    SearchContextManager, // the context manager, includes the Context.Provider
    SuggestionBar // an optional UI component that displays trending searches and channel / username results
} from '@giphy/react-components';
import { Panel, ScrollablePane, Stack } from '@fluentui/react';
import { DarkTheme } from 'themes/dark';
interface IGiphyPanelProps {
    isOpen?: boolean;
    onDismiss?: Function;
    onGifSelected?: (url: string) => void;
}

const GiphyPanelContent: React.FC<{ onSelect: (url: string) => void }> = ({ onSelect }) => {
    const { fetchGifs, searchKey } = useContext(SearchContext);
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <img src="https://static-cdn.vercel.app/giphy.png" width={160} style={{ position: 'absolute', right: 10, bottom: 10, zIndex: 2000 }} />
            <SearchBar />
            <SuggestionBar />
            {/** 
                key will recreate the component, 
                this is important for when you change fetchGifs 
                e.g. changing from search term dogs to cats or type gifs to stickers
                you want to restart the gifs from the beginning and changing a component's key does that 
            **/}
            <Stack style={{ position: 'relative', height: 720 }}>
                <ScrollablePane>
                    <Grid
                        onGifClick={(gif, e) => {
                            e.preventDefault();

                            onSelect(gif.images.original.url);
                        }}
                        key={searchKey}
                        columns={2}
                        gutter={6}
                        width={280}
                        fetchGifs={fetchGifs}
                    />
                </ScrollablePane>
            </Stack>
        </Stack>
    );
};

const GiphyPanel: React.FC<IGiphyPanelProps> = ({ isOpen, onDismiss, onGifSelected }) => {
    return (
        <SearchContextManager apiKey={'YSvWToolxXYQ891W6B8XRWQrD3fscQmA'}>
            <Panel isLightDismiss isOpen={isOpen} theme={DarkTheme} onDismiss={() => onDismiss && onDismiss()}>
                <GiphyPanelContent onSelect={(url) => onGifSelected && onGifSelected(url)} />
            </Panel>
        </SearchContextManager>
    );
};

export default GiphyPanel;
