interface IColorSelectorState {
    onDateChange: (d: Date | null | undefined) => void;
}

export const useDateSelectorState: (onChange: any, value: Date | undefined) => IColorSelectorState = (onChange) => {
    const onDateChange = (d: Date | null | undefined) => {
        return onChange(d);
    };

    return {
        onDateChange
    };
};
