import IvicosStrings from 'kits/language/stringKit';
import { SetterOrUpdater } from 'recoil';
import { idpService } from 'services/api/identity-provider.service';
import { IInvitationOptions } from './types';
import { convertInvitationLists } from 'shared-state/directory/hooks';
import { IParsedInvitation } from './Data';

export const createInvitation = async (
    newCreatedInvitation: IInvitationOptions,
    setInvitationLink: React.Dispatch<React.SetStateAction<string>>,
    currentCampusId: [string | undefined, SetterOrUpdater<string | undefined>],
    setInvitationCustomUrl: React.Dispatch<React.SetStateAction<string>>,
    setEditablePartOfTheLink: React.Dispatch<React.SetStateAction<string>>,
    setNewCreatedInvitation: React.Dispatch<React.SetStateAction<IParsedInvitation | undefined>>,
    setNewEditablePartOfTheLink: React.Dispatch<React.SetStateAction<string>>
): Promise<any> => {
    if (!currentCampusId[0]) {
        return undefined;
    }

    try {
        const createdInvitation = await idpService.createInvitation(
            newCreatedInvitation.campusId,
            newCreatedInvitation.hosts,
            newCreatedInvitation.event_name,
            newCreatedInvitation.type,
            newCreatedInvitation.repeat,
            newCreatedInvitation.from,
            newCreatedInvitation.to,
            newCreatedInvitation.areaId,
            newCreatedInvitation.roomId,
            newCreatedInvitation.welcome_message
        );

        setInvitationCustomUrl(createdInvitation.custom_url);
        setEditablePartOfTheLink(createdInvitation.custom_url.split('/')[1]);
        setNewEditablePartOfTheLink(createdInvitation.custom_url.split('/')[1]);
        setInvitationLink(window.location.origin + '/i/' + createdInvitation.invitationId);
        setNewCreatedInvitation(convertInvitationLists(createdInvitation));
    } catch (error) {
        console.log(error);
    }
};

export const updateInvitationCustomUrl = async (
    invitationId: string,
    customPath: string,
    setUpdateIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomPathError: React.Dispatch<React.SetStateAction<string>>,
    setEditablePartOfTheLink: React.Dispatch<React.SetStateAction<string>>,
    setNewEditablePartOfTheLink: React.Dispatch<React.SetStateAction<string>>
): Promise<any> => {
    idpService
        .updateCustomUrl(invitationId, customPath)
        .then((res: any) => {
            setUpdateIsOpen(false);
            setEditablePartOfTheLink(res.custom_url.split('/')[1]);
            setNewEditablePartOfTheLink(res.custom_url.split('/')[1]);
        })

        .catch((err) => {
            if (err) setCustomPathError(IvicosStrings.customUrlModalErrorCustomUrlExists);
        });
};

export const changeCustomUrlInputField = (
    value: string | undefined,
    setCustomPathError: React.Dispatch<React.SetStateAction<string>>,
    setNewEditablePartOfTheLink: React.Dispatch<React.SetStateAction<string>>
): void => {
    if (value && value.length > 0) {
        const noSpecialCharacters = /[^a-zA-Z0-9- ]/;
        const noSpaces = value.replace(/ /g, '-');
        if (noSpecialCharacters.test(noSpaces)) {
            setCustomPathError(IvicosStrings.customUrlModalErrorNoSpecialCharacters);
        } else if (noSpaces.length > 49) {
            setCustomPathError(IvicosStrings.customUrlModalErrorMaximumLimit);
        } else {
            setCustomPathError('');
        }
        setNewEditablePartOfTheLink(noSpaces.toLocaleLowerCase());
    } else {
        setNewEditablePartOfTheLink('');
        setCustomPathError(IvicosStrings.customUrlModalErrorMinimumLimit);
    }
};

export const getWeekDay = (invData: IParsedInvitation | undefined): string => {
    switch (invData?.validFromDate?.getDay()) {
        case 0:
            return IvicosStrings.sunday;
        case 1:
            return IvicosStrings.monday;
        case 2:
            return IvicosStrings.tuesday;
        case 3:
            return IvicosStrings.wednesday;
        case 4:
            return IvicosStrings.thursday;
        case 5:
            return IvicosStrings.friday;
        case 6:
            return IvicosStrings.saturday;
        default:
            return '';
    }
};

export const getShortMonth = (date: Date | undefined): string => {
    switch (date?.getMonth()) {
        case 0:
            return IvicosStrings.jan;
        case 1:
            return IvicosStrings.feb;
        case 2:
            return IvicosStrings.mar;
        case 3:
            return IvicosStrings.apr;
        case 4:
            return IvicosStrings.may2;
        case 5:
            return IvicosStrings.jun;
        case 6:
            return IvicosStrings.jul;
        case 7:
            return IvicosStrings.aug;
        case 8:
            return IvicosStrings.sep;
        case 9:
            return IvicosStrings.oct;
        case 10:
            return IvicosStrings.nov;
        case 11:
            return IvicosStrings.dec;
        default:
            return '';
    }
};

export const useValidYouTubeUrl = (url: string | undefined): RegExpMatchArray | undefined => {
    if (!url) return;

    const checkYouTubeUrl = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|(?:m\.)?youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

    const matched = url.match(checkYouTubeUrl);

    if (!matched) return;

    return matched;
};

export const saveCustomizedVideoUrlForWaitingRoom = async (
    invitationId: string,
    customizedVideoUrl: string,
    defaultVideoIsUsed: boolean,
    defaultVideoAtVisitorEntrance: string
): Promise<void> => {
    let videoUrlToUpdate = '';

    if (customizedVideoUrl && !defaultVideoIsUsed) {
        videoUrlToUpdate = customizedVideoUrl;
    } else if (!customizedVideoUrl && defaultVideoIsUsed && defaultVideoAtVisitorEntrance) {
        videoUrlToUpdate = defaultVideoAtVisitorEntrance;
    }

    if (videoUrlToUpdate) {
        try {
            await idpService.updateCustomizedVideoUrl(invitationId, videoUrlToUpdate);
        } catch (err) {
            console.log(err);
        }
    }
};
