import { IResource } from 'kits/apiKit3/legacy';
import { MutableRefObject, useEffect, useState } from 'react';

export const sortRoomList = (roomList: Array<IResource>): any => {
    const _roomList = [...roomList];
    return _roomList.sort((a, b) => {
        const textA = getRoomNameInUppercase(a);
        const textB = getRoomNameInUppercase(b);

        return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
};
export const getRoomNameInUppercase = (room: IResource): string => {
    if (typeof room.attributes.displayName != 'string' || room.attributes.displayName.length <= 0) return '_Z';
    return room.attributes.displayName.toUpperCase();
};

export const useContainerWidth = (ref: MutableRefObject<HTMLDivElement | null>): any => {
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const onResize = () => {
        setContainerWidth(ref.current?.offsetWidth || 0);
    };

    useEffect(() => {
        onResize();
    }, [ref]);
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return containerWidth;
};
