import { useBoolean, useId } from '@uifabric/react-hooks';
import { useEffect, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { useInvitationLanguage } from 'shared-state/invitationLanguage/hooks';

export interface IPersonalPreferencesState {
    currentDeviceSettings: {
        cameraDisabled: boolean;
        microphoneDisabled: boolean;
    };
    toggleDeviceSettings: (toggle: string) => Promise<void>;
    hideErrorDialog: boolean;
    toggleHideErrorDialog: () => void;
    colorButtonId: string;
    deviceSettings: any;
    switchLanguage: (newLang: string) => void;
    toggleHideDialog: () => void;
    hideDialog: boolean;
    language: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
    showLanguage: () => 'Deutsch' | 'English';
    openDialogBox: (lang: string) => void;
}

export const deviceSettings: any = {};

export const usePersonalPreferencesState: () => IPersonalPreferencesState = () => {
    const [hideErrorDialog, { toggle: toggleHideErrorDialog }] = useBoolean(true);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [language, setLanguage] = useState<string>('');
    const [preferredLanguage, setPreferredLanguage] = useState<string>('');
    // const [UILanguage, setUILanguage] = useUILanguage();
    const [, setInvitationLanguageChoice] = useInvitationLanguage();

    const colorButtonId = useId('callout-button');
    const [currentDeviceSettings, setCurrentDeviceSettings] = useState<{ cameraDisabled: boolean; microphoneDisabled: boolean }>({
        cameraDisabled: false,
        microphoneDisabled: false
    });
    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await idpService.getUser();
                setPreferredLanguage(response.preferred_language);
                setInvitationLanguageChoice(response.preferred_language);
                deviceSettings.cameraDisabled = response.device_settings.camera_disabled;
                deviceSettings.microphoneDisabled = response.device_settings.mic_disabled;
                setCurrentDeviceSettings({
                    cameraDisabled: response.device_settings.camera_disabled,
                    microphoneDisabled: response.device_settings.mic_disabled
                });
            } catch (error) {
                console.log(error);
            }
        };

        getUserData();
    }, []);

    const toggleDeviceSettings = async (toggle: string) => {
        const currentCameraSetup = toggle == 'camera' ? !currentDeviceSettings.cameraDisabled : currentDeviceSettings.cameraDisabled;
        const currentMicSetup = toggle == 'mic' ? !currentDeviceSettings.microphoneDisabled : currentDeviceSettings.microphoneDisabled;
        setCurrentDeviceSettings({ cameraDisabled: currentCameraSetup, microphoneDisabled: currentMicSetup });

        try {
            const updateResponse = await idpService.updateUser(undefined, undefined, undefined, undefined, currentCameraSetup, currentMicSetup);
            deviceSettings.cameraDisabled = updateResponse.device_settings.camera_disabled;
            deviceSettings.microphoneDisabled = updateResponse.device_settings.mic_disabled;
        } catch {
            toggleHideErrorDialog();
        }
    };

    const switchLanguage = async (newLang: string) => {
        await idpService.updateUser(undefined, undefined, undefined, newLang, undefined);

        window.location.reload();
    };

    const showLanguage = () => {
        const lang = preferredLanguage;
        if (lang === 'de') {
            return 'Deutsch';
        } else return 'English';
    };

    const openDialogBox = (lang: string) => {
        const lang2 = preferredLanguage;
        if (lang === lang2) {
            null;
        } else toggleHideDialog();
    };

    return {
        currentDeviceSettings,
        toggleDeviceSettings,
        hideErrorDialog,
        toggleHideErrorDialog,
        colorButtonId,
        deviceSettings,
        switchLanguage,
        hideDialog,
        toggleHideDialog,
        language,
        setLanguage,
        showLanguage,
        openDialogBox
    };
};
