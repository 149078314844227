import {
    Checkbox,
    DefaultButton,
    Dialog,
    DialogType,
    IDialogContentProps,
    Label,
    Layer,
    PrimaryButton,
    Stack,
    TextField,
    Image,
    IImageProps,
    ImageFit
} from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

import { ILinkAttachmentModalBoxState } from './LinkAttachmentModalBox.state';

const LinkAttachmentModalBoxView: React.FC<ILinkAttachmentModalBoxState> = (props) => {
    const {
        toggleLinkAttachmentModalBox,
        cancelLinkAttachment,
        saveLinkAttachment,
        onLinkAttachmentValueChange,
        onLinkAttachmentPinnedChange,
        linkAttachmentModalValue,
        linkAttachmentItemId,
        linkAttachmentItemIsPinned
    } = props;
    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        styles: {
            title: {
                fontSize: 22,
                fontWeight: 900
            }
        },
        title:
            linkAttachmentItemId === 'youtubelinkmanager'
                ? IvicosStrings.youtubeLinkAttachmentModalBoxTitle
                : linkAttachmentItemId === 'collaboardlinkmanager'
                ? IvicosStrings.collaboardLinkAttachmentModalBoxTitle
                : IvicosStrings.linkAttachmentModalBoxTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle,
        subText:
            linkAttachmentItemId === 'youtubelinkmanager'
                ? IvicosStrings.youtubeLinkAttachmentModalVideoPinUpperText
                : linkAttachmentItemId === 'collaboardlinkmanager'
                ? IvicosStrings.colleboardLinkAttachmentModalBoxSubText
                : IvicosStrings.linkAttachmentModalBoxSubText
    };

    // const getVideoId= React.useCallback((url:string) => {
    //     const regex = RegExp(/(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm);
    //     return 'AMTjJAk33Nc';
    // },[])
    // const avatarURL = linkAttachmentItemId==='youtubelinkmanager' && linkAttachmentModalValue ? `https://img.youtube.com/vi/${youtubeId}/0.jpg` : '';
    const imageProps: IImageProps = {
        imageFit: ImageFit.cover,
        height: 150,
        styles: (props) => ({ root: { border: '1px solid ' + props.theme.palette.neutralSecondary } })
    };

    return (
        <Stack aria-label="link-attachment-modal-box">
            <Layer>
                <Dialog
                    modalProps={{ isBlocking: true, styles: { main: { width: '70% !important', minWidth: '280px !important', maxWidth: '400px !important' } } }}
                    hidden={toggleLinkAttachmentModalBox}
                    onDismiss={cancelLinkAttachment}
                    dialogContentProps={dialogContentProps}
                >
                    <Stack tokens={{ childrenGap: 24 }}>
                        <Stack aria-label="link-attachment-modal-box-text-field">
                            <TextField placeholder="https://..." type="text" onChange={onLinkAttachmentValueChange} />
                        </Stack>
                        {linkAttachmentItemId === 'youtubelinkmanager' && linkAttachmentModalValue !== '' && (
                            <Stack aria-label="link-attachment-modal-box-is-video-thumbnail">
                                <Image {...imageProps} src={'https://img.youtube.com/vi/' + linkAttachmentModalValue + '/0.jpg'} alt="Campus logo"></Image>
                            </Stack>
                        )}

                        {linkAttachmentItemId === 'youtubelinkmanager' && (
                            <Stack aria-label="link-attachment-modal-box-is-pinned-checkbox" tokens={{ childrenGap: 16 }}>
                                <Label>{IvicosStrings.youtubeLinkAttachmentModalVideoPinUpperText}</Label>
                                <Checkbox
                                    checked={linkAttachmentItemIsPinned}
                                    onChange={onLinkAttachmentPinnedChange}
                                    className="show-expired-invitations-button"
                                    label={IvicosStrings.youtubeLinkAttachmentModalVideoPinText}
                                />
                            </Stack>
                        )}

                        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 16 }}>
                            <DefaultButton
                                aria-label="link-attachment-modal-box-cancel-button"
                                onClick={cancelLinkAttachment}
                                text={IvicosStrings.cancelActionTitle}
                            />
                            <PrimaryButton
                                aria-label="link-attachment-modal-box-save-button"
                                onClick={saveLinkAttachment}
                                text={IvicosStrings.saveActionTitle}
                                disabled={linkAttachmentModalValue.length < 1}
                            />
                        </Stack>
                    </Stack>
                </Dialog>
            </Layer>
        </Stack>
    );
};

export default LinkAttachmentModalBoxView;
