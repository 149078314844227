import { useValidYouTubeUrl } from 'features/Header/children/CreateInvitationPanel/hooks';
import { useOrganizationBranding } from 'features/Header/hooks';
import { IvicosColors } from 'kits/colorKit';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useState } from 'react';
import { useShowChangeVisitorEntranceVideoOption } from 'shared-state/display-options/hooks';
import { useCurrentOrgId } from 'shared-state/location/hook';

export interface IVisitorEntranceVideoPlayerState {
    videoUrl?: string;
    buttonText?: string;
    providedButtonText: string;
    instructionToSetVideo: string;
    visitorEntranceVideoUrl: string;
    showChangeVisitorEntranceVideoOption: boolean;
    changeOrSelectVideo: (providedText: string) => void;
    changeVideoUrl: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    deleteCurrentVideo: () => void;
    backgroundColor: string;
    saveUploadedVideo: (url: string) => void;
    isSaveButtonDisabled: boolean;
}

export const useVisitorEntranceVideoPlayerState: (onUrlChange: Function, videoUrl?: string, buttonText?: string) => IVisitorEntranceVideoPlayerState = (
    onUrlChange,
    videoUrl,
    buttonText
) => {
    const [instructionToSetVideo, setInstructionToSetVideo] = useState<string>(IvicosStrings.instructionToSetVideoAtVisitorEntrance);
    const [providedButtonText, setProvidedButtonText] = useState<string>(buttonText || IvicosStrings.changeYTVideo);
    const [visitorEntranceVideoUrl, setVisitorEntranceVideoUrl] = useState<string>(videoUrl || '');
    const [showChangeVisitorEntranceVideoOption, setShowChangeVisitorEntranceVideoOption] = useShowChangeVisitorEntranceVideoOption();
    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    useEffect(() => {
        showChangeVisitorEntranceVideoOption && setInstructionToSetVideo(IvicosStrings.pasteYTVideo);
    }, []);

    const changeOrSelectVideo = (providedText: string) => {
        setShowChangeVisitorEntranceVideoOption(true);
        setInstructionToSetVideo(IvicosStrings.pasteYTVideo);

        if (providedText === IvicosStrings.changeYTVideo) {
            if (!visitorEntranceVideoUrl && videoUrl) {
                setVisitorEntranceVideoUrl(videoUrl);
            } else if (visitorEntranceVideoUrl) {
                setVisitorEntranceVideoUrl(visitorEntranceVideoUrl);
            }
        } else {
            setVisitorEntranceVideoUrl('');
        }
    };

    const changeVideoUrl = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setVisitorEntranceVideoUrl(newValue || '');
        const matched = useValidYouTubeUrl(newValue);
        if (matched) {
            setIsSaveButtonDisabled(false);
        } else {
            setIsSaveButtonDisabled(true);
        }
    };

    const deleteCurrentVideo = () => {
        setVisitorEntranceVideoUrl('');
        setInstructionToSetVideo(IvicosStrings.instructionToSetVideoAtVisitorEntrance);
        setShowChangeVisitorEntranceVideoOption(!showChangeVisitorEntranceVideoOption);
        setProvidedButtonText(IvicosStrings.selectYTVideo);
    };

    const saveUploadedVideo = (url: string) => {
        if (typeof url === 'string') {
            const matched = useValidYouTubeUrl(url);
            if (matched && typeof matched[0] === 'string') {
                setInstructionToSetVideo('');
                setShowChangeVisitorEntranceVideoOption(!showChangeVisitorEntranceVideoOption);
                setProvidedButtonText(IvicosStrings.changeYTVideo);
                setVisitorEntranceVideoUrl(matched[0]);
                onUrlChange(matched[0]);
            }
        }
    };

    return {
        videoUrl,
        providedButtonText,
        instructionToSetVideo,
        visitorEntranceVideoUrl,
        showChangeVisitorEntranceVideoOption,
        changeOrSelectVideo,
        changeVideoUrl,
        deleteCurrentVideo,
        backgroundColor,
        saveUploadedVideo,
        isSaveButtonDisabled
    };
};
