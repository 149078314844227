import { AttachmentDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import React from 'react';
import AttachmentUpdateModalView from './AttachmentUpdateModal.view';
import { useAttachmentUpdateModalState } from './AttachmentUpdateModel.state';

interface IAttachmentUpdateModalProps {
    isOpen?: boolean;
    onDismiss?: Function;
    onAttachmentUpdate?: (attachment?: AttachmentDto) => void;
    attachment?: AttachmentDto;
}

const AttachmentUpdateModal: React.FC<IAttachmentUpdateModalProps> = (props) => {
    const state = useAttachmentUpdateModalState(props.onAttachmentUpdate, props.attachment);

    return (
        <AttachmentUpdateModalView
            {...{
                ...state,
                ...{
                    isOpen: props.isOpen,
                    onDismiss: props.onDismiss
                }
            }}
        ></AttachmentUpdateModalView>
    );
};
export default AttachmentUpdateModal;
