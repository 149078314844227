import { Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
interface AlertModalProps {
    title: string;
    subText: string;
    toggleAlert: () => void;
}

export const AlertModal: React.FunctionComponent<AlertModalProps> = ({ title, subText, toggleAlert }) => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: title,
        subText: subText
    };

    return (
        <>
            <Dialog hidden={false} onDismiss={toggleAlert} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <PrimaryButton onClick={toggleAlert} text={IvicosStrings.closeActionTitle} />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default AlertModal;
