import { useEffect, useState } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import {
    aControlNameWidthInMemo,
    aControlTogglePanelState,
    aCrossAreaMessageType,
    aCrossAreaSearchBarInputValue,
    aDirectControlNameWidthInMemo,
    aHelpModalIsOpen,
    aIsCrossAreaUsersVisible,
    aIsMemoOpenOnCrossAreaMessagePanel,
    aIsWaitingAreaMemoDialogOpen,
    aNotificationPosition,
    aRoomSettingsRoomId,
    aShouldUseLocalAvailability,
    aShowAreaPanel,
    aShowAttachmentPanel,
    aShowChangeVisitorEntranceVideoOption,
    aShowCrossAreaMessagePanel,
    aShowInvitationsManagerModal,
    aShowSearchBar,
    aShowVisitorRoomSelectionDialog,
    aSideBarDraggableWidth,
    aSideBarIsMaximized,
    aStaredAreas
} from 'shared-state/display-options/atoms';

export const useControlTogglePanelState = (): any => {
    return useRecoilState(aControlTogglePanelState);
};
export const useControlNameWidthInMemo = (): any => {
    return useRecoilState(aControlNameWidthInMemo);
};
export const useDirectControlNameWidthInMemo = (): any => {
    return useRecoilState(aDirectControlNameWidthInMemo);
};
export const useSideBarDraggableWidth = (): any => {
    return useRecoilState(aSideBarDraggableWidth);
};
export const useIsSideBarMaximized = (): any => {
    return useRecoilState(aSideBarIsMaximized);
};

export const useIsHelpModalOpen = (): any => {
    return useRecoilState(aHelpModalIsOpen);
};

export const useNotificationPosition = (): any => {
    return useRecoilState(aNotificationPosition);
};

export const useShowVisitorRoomSelectionDialog = (): any => {
    return useRecoilState(aShowVisitorRoomSelectionDialog);
};

export const useRoomSettingsRoomId = (): any => {
    return useRecoilState(aRoomSettingsRoomId);
};

export const useShouldUseLocalAvailability = (): any => {
    return useRecoilState(aShouldUseLocalAvailability);
};

export const useStaredAreas = (tenantId: string): any => {
    return useRecoilState(aStaredAreas(tenantId));
};

export const useShowAreaPanel = (): any => {
    return useRecoilState(aShowAreaPanel);
};

export const useShowSearchBar = (): any => {
    return useRecoilState(aShowSearchBar);
};

export const useCrossAreaMessageType = (): any => {
    return useRecoilState(aCrossAreaMessageType);
};

export const useIsWaitingAreaMemoDialogOpen = (): any => {
    return useRecoilState(aIsWaitingAreaMemoDialogOpen);
};

export const useCrossAreaSearchBarInputValue = (): any => {
    return useRecoilState(aCrossAreaSearchBarInputValue);
};

export const useShowCrossAreaMessagePanel = (): any => {
    return useRecoilState(aShowCrossAreaMessagePanel);
};

export const useIsMemoOpenOnCrossAreaMessagePanel = (): any => {
    return useRecoilState(aIsMemoOpenOnCrossAreaMessagePanel);
};

export const useIsCrossAreaUsersVisible = (): any => {
    return useRecoilState(aIsCrossAreaUsersVisible);
};

export const useShowAttachmentPanel = (): any => {
    return useRecoilState(aShowAttachmentPanel);
};

export const useShowInvitationsManagerModal = (): any => {
    return useRecoilState(aShowInvitationsManagerModal);
};

export const useShowChangeVisitorEntranceVideoOption = (): [boolean, SetterOrUpdater<boolean>] => {
    return useRecoilState(aShowChangeVisitorEntranceVideoOption);
};

export const useWindowWidth = (): any => {
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    const responsive = {
        smallDevice: width < 600,
        mediumDevice: (width > 600 || width == 600) && width < 992,
        smallLaptop: (width > 992 || width == 992) && width < 1400,
        largeLaptop: width > 1400 || width == 1400
    };

    return responsive;
};
