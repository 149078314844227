import { getAuthHeader } from '../services/auth_header';

export class AbstractService {
    protected readonly baseUrl: string;

    protected getHeaders: () => { [index: string]: string };

    constructor(baseUrl: string, headerRetrievalFunction?: () => { [index: string]: string }) {
        this.baseUrl = baseUrl;

        if (headerRetrievalFunction && typeof headerRetrievalFunction == 'function') {
            this.getHeaders = headerRetrievalFunction;
        } else {
            this.getHeaders = getAuthHeader;
        }
    }
}
