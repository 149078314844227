import { DefaultValue, selectorFamily } from 'recoil';
import { aTabOptionList } from 'shared-state/tabs/atoms';
import { ITabOption, ITabOptionIdentifier } from 'shared-state/tabs/types';

export const sTabOptionInList = selectorFamily<ITabOption | undefined, ITabOptionIdentifier>({
    key: 'sTabOptionInList',

    get:
        ({ listId, key }) =>
        ({ get }) => {
            const tabList = get(aTabOptionList(listId));
            const tabOption = tabList.find((to) => to.key == key);
            return tabOption;
        },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    set:
        ({ listId, key }) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ get, set }, newValue: ITabOption | DefaultValue | undefined) => {
            if (typeof newValue == 'undefined') {
                set(aTabOptionList(listId), (prevList) => {
                    return prevList.filter((to) => to.key != key);
                });
                return;
            }
            if (newValue instanceof DefaultValue) return;

            set(aTabOptionList(listId), (prevList) => {
                if (!prevList.find((to) => to.key == key)) return [...prevList, ...[newValue]];

                const newList = prevList.map((to) => {
                    if (to.key != key) {
                        if (newValue.isSelected) return { ...to, ...{ isSelected: false } };
                        return to;
                    }

                    return newValue;
                });
                return newList;
            });
        }
});
