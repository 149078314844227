import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import React from 'react';
import { useRoomListMoreButtonState } from './MoreButton.state';
import MoreButtonView from './MoreButton.view';

export interface IRoomListMoreButtonProps {
    actions?: Array<IRoomAction>;
}

export const RoomListMoreButton: React.FC<IRoomListMoreButtonProps> = (props) => {
    const state = useRoomListMoreButtonState();

    return (
        <MoreButtonView
            {...{
                ...state,
                ...{
                    actions: props.actions
                }
            }}
        />
    );
};
