import { DefaultButton, PrimaryButton } from '@fluentui/react';
import React, { ReactElement } from 'react';
import { IC } from 'components/SVGIcon';
import { Check, Loading } from 'kits/IconKit2';

export interface IStatefulButtonViewProps {
    onRenderIcon(): ReactElement;
    variant?: 'default' | 'primary';
    disabled?: boolean;
    onClick?: (e: any, setButtonState: (state: number) => void) => void;
    buttonState: number;
    setButtonState: React.Dispatch<React.SetStateAction<number>>;
    onRenderText: () => string;
}

const StatefulButtonView: React.FC<IStatefulButtonViewProps> = (props) => {
    const { disabled, variant, onClick, buttonState, setButtonState, onRenderIcon, onRenderText } = props;

    const icVariant = variant && variant == 'primary' ? 'light' : 'dark';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onRenderCurrentIcon = (props: any) => {
        if (buttonState == 1)
            return (
                <IC variant={icVariant} spinning size={20}>
                    <Loading />
                </IC>
            );
        if (buttonState == 2)
            return (
                <IC variant={icVariant} size={20}>
                    <Check />
                </IC>
            );

        return (
            <IC variant={icVariant} size={20}>
                {onRenderIcon()}
            </IC>
        );
    };

    if (variant == 'primary')
        return (
            <>
                <PrimaryButton
                    disabled={disabled}
                    onRenderIcon={(props) => onRenderCurrentIcon(props)}
                    onClick={(e: any) => {
                        onClick && onClick(e, setButtonState);
                    }}
                >
                    {onRenderText()}
                </PrimaryButton>
            </>
        );

    return (
        <>
            <DefaultButton
                disabled={disabled}
                onRenderIcon={(props) => onRenderCurrentIcon(props)}
                onClick={(e: any) => {
                    onClick && onClick(e, setButtonState);
                }}
            >
                {onRenderText()}
            </DefaultButton>
        </>
    );
};

export default StatefulButtonView;
