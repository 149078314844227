import React from 'react';
import { getTheme, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import RaiseHand from 'kits/IconKit2/RaiseHand';
import WaitingHead from 'kits/IconKit2/WaitingHead';
import TalkingHead from 'kits/IconKit2/TalkingHead';

export interface IUserRoundTableProps {
    talkTimeColor?: string;
    text?: string;
    avatarType?: string;
}

const UserVirtualModerator: React.FC<IUserRoundTableProps> = (props) => {
    const { talkTimeColor, text, avatarType } = props;
    const theme = getTheme();
    const virtualModeratorIconHandler = (avatarType: string | undefined) => {
        switch (avatarType) {
            case 'raise-hand':
                return (
                    <Stack style={{ position: 'absolute', left: 13.5 }}>
                        <IC variant="dark" size={21}>
                            <RaiseHand />
                        </IC>
                    </Stack>
                );
                break;
            case 'round-table':
                return (
                    <Stack style={{ position: 'absolute', left: 10 }}>
                        <IC variant="dark" size={21}>
                            <WaitingHead />
                        </IC>
                    </Stack>
                );
                break;
            case 'round-table-first':
                return (
                    <Stack style={{ position: 'absolute', left: 13.5 }}>
                        <IC variant="dark" size={21}>
                            <TalkingHead />
                        </IC>
                    </Stack>
                );
        }
    };
    return (
        <Stack horizontal verticalAlign="center">
            <Stack
                horizontalAlign="center"
                verticalAlign="start"
                style={{ width: 32, height: 32, borderRadius: 4, marginRight: 8, backgroundColor: talkTimeColor, opacity: 0.4 }}
            />
            {virtualModeratorIconHandler(avatarType)}
            <Text variant="medium" style={{ color: theme.palette.black }}>
                {text && text.split(' ').length > 1
                    ? `${text.split(' ')[0]} ${text.split(' ')[1].slice(0, 1).toUpperCase()}.`
                    : `${text && text.split(' ')[0]}`}
            </Text>
        </Stack>
    );
};

export default UserVirtualModerator;
