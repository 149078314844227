import React, { useState } from 'react';

export interface IRoomListMoreButtonState {
    setHovering: React.Dispatch<React.SetStateAction<Boolean>>;
}

export const useRoomListMoreButtonState: () => IRoomListMoreButtonState = () => {
    const [, setHovering] = useState<Boolean>(false);
    return { setHovering };
};
