import { DefaultButton, Panel, PrimaryButton, Stack } from '@fluentui/react';
import DynamicForm, { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import { IMember } from 'features/CampusSettings/CampusSettings.state';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

export interface IEditUserPanel {
    isEditUserOpen: boolean;
    dismissEditUserPanel: () => void;
    editUserForm: IDynamicFormElementProps[];
    onEditUserFormChange: (key: string, newValue: any) => void;
    user: IMember | undefined;
    changes: any;
    onUpdateUser: (userId: string, changes: any) => Promise<any | undefined>;
    setChanges: any;
}

const EditUserPanel: React.FC<IEditUserPanel> = ({
    isEditUserOpen,
    dismissEditUserPanel,
    editUserForm,
    onEditUserFormChange,
    user,
    changes,
    onUpdateUser,
    setChanges
}) => {
    return (
        <Panel isOpen={isEditUserOpen} onDismiss={dismissEditUserPanel}>
            <Stack style={{ flexDirection: 'column', alignItems: 'center', height: '500px', justifyContent: 'space-around' }}>
                <DynamicForm formFields={editUserForm} onChange={onEditUserFormChange}></DynamicForm>
                <Stack style={{ flexDirection: 'row', padding: '10px', width: '100%', justifyContent: 'space-around' }}>
                    <DefaultButton text={IvicosStrings.cancelActionTitle} onClick={dismissEditUserPanel} />
                    <PrimaryButton
                        className="confirm-member-edit"
                        text={IvicosStrings.confirmActionTitle}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        onClick={(e) => {
                            dismissEditUserPanel();
                            if (!user) return;
                            if (changes && Object.keys(changes).length > 0) {
                                onUpdateUser &&
                                    onUpdateUser(user.userId, changes).then(() => {
                                        setChanges({});
                                        dismissEditUserPanel();
                                    });
                                return;
                            }
                        }}
                    />
                </Stack>
            </Stack>
        </Panel>
    );
};

export default EditUserPanel;
