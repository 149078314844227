import React from 'react';
import UserBadgeView from './UserBadge.view';
import UserVirtualModerator from './UserVirtualModerator';

export interface IUserBadgeViewProps {
    text?: string;
    sizeBadge: string;
    fontSize: string;
    displayBadgeName: boolean;
    ariaLabel?: string;
    isForUserProfile?: boolean;
    emailForBadge?: string;
    avatarType?: string;
    talkTimeColor?: string;
}

const UserBadge: React.FC<IUserBadgeViewProps> = (props) => {
    const { avatarType } = props;
    return (
        <>
            {!avatarType ? (
                <UserBadgeView
                    {...{
                        sizeBadge: props.sizeBadge,
                        text: props.text,
                        fontSize: props.fontSize,
                        displayBadgeName: props.displayBadgeName,
                        ariaLabel: props.ariaLabel,
                        isForUserProfile: props.isForUserProfile,
                        emailForBadge: props.emailForBadge
                    }}
                />
            ) : (
                <UserVirtualModerator
                    {...{
                        text: props.text,
                        talkTimeColor: props.talkTimeColor,
                        avatarType: props.avatarType
                    }}
                />
            )}
        </>
    );
};

export default UserBadge;
