import React, { useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { setSessionSilent } from 'kits/sessionKit';
import { idpService } from 'services/api/identity-provider.service';
import { isLoggedIn } from 'kits/authKit';

const ProtectedRoute: React.FC<RouteProps> = (props) => {
    setSessionSilent('@rememberedPath', window.location.pathname);

    useEffect(() => {
        const loginCheck = async () => {
            try {
                await idpService.getUser();
            } catch (error) {
                const isLogged = isLoggedIn();

                if (!isLogged) {
                    window.location.href = '/login';
                }
            }
        };

        loginCheck();
    }, []);

    return <>{props.children}</>;
};

export default ProtectedRoute;
