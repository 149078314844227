import React from 'react';
import { DefaultButton } from '@fluentui/react';

interface IResetWizardViewProps {
    onClickEvent: () => void;
}

const ResetWizardView: React.FC<IResetWizardViewProps> = ({ onClickEvent }) => {
    return <DefaultButton onClick={onClickEvent}>Reset </DefaultButton>;
};

export default ResetWizardView;
