import React, { useState, useEffect } from 'react';
import './AudioMeter.css';

const AudioMeter: React.FC = () => {
    const numberOfSegments = 12;
    const [filledSegments, setFilledSegments] = useState(0);
    const context = new AudioContext();
    const analyserNode = new AnalyserNode(context, { fftSize: 256 });

    const getAudio = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    autoGainControl: false,
                    noiseSuppression: true
                }
            });
            return stream;
        } catch (error) {
            console.error('Error getting audio stream:', error);
        }
    };

    const setupContext = async () => {
        try {
            const detectedAudio = await getAudio();
            if (detectedAudio) {
                if (context.state === 'suspended') {
                    await context.resume();
                }
                const source = context.createMediaStreamSource(detectedAudio);
                source.connect(analyserNode);
                // source.connect(analyserNode).connect(context.destination);
            }
        } catch (error) {
            console.error('Error setting up audio context:', error);
        }
    };

    const drawVisualizer = () => {
        requestAnimationFrame(drawVisualizer);

        const bufferLength = analyserNode.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyserNode.getByteFrequencyData(dataArray);

        const segments = dataArray.reduce((total, item) => {
            const y = item / 255;
            return total + (y > 0 ? 1 : 0);
        }, 0);

        const updatedSegments = Math.floor(segments * 0.1);

        setFilledSegments(updatedSegments);
    };

    useEffect(() => {
        setupContext();
        drawVisualizer();

        // Clean up the audio context on unmount
        return () => {
            if (context.state !== 'closed') {
                context.close().catch((error) => {
                    console.error('Error closing audio context:', error);
                });
            }
        };
    }, []);

    return (
        <div className="audio-meter">
            {Array.from({ length: numberOfSegments }).map((_, idx) => (
                <div key={idx} className={`segment ${idx < filledSegments ? 'filled' : ''}`}></div>
            ))}
        </div>
    );
};

export default AudioMeter;
