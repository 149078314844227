import { Link, Stack, Text } from '@fluentui/react';
import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import granted from 'media/img/Granted.svg';
import IvicosStrings from '../kits/language/stringKit';

const NewUserAccessGranted: React.FC<{ email: string }> = ({ email }: any) => {
    const currentTime: number = Math.trunc(Date.now() / 1000);

    const redirectToProfilePage = () => {
        if (localStorage.getItem('ivAccessToken')) {
            const token: any = localStorage.getItem('ivAccessToken');
            const decodeToken: any = jwt_decode(token);
            const expirationTime = decodeToken ? decodeToken.exp : 0;
            const userEmail = decodeToken ? decodeToken.email : '';
            if (expirationTime > currentTime && userEmail === email) {
                window.location.href = '/profile';
                sessionStorage.setItem('showEmail', 'false');
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            redirectToProfilePage();
        }, 5000);
    }, []);

    return (
        <Stack>
            <Stack
                style={{
                    background: 'linear-gradient(150deg, #E4E3E4 41.98%, #E4E4E3 54.26%, rgba(255, 255, 255, 0.807062) 58.41%, #E4E4E3 62.45%, #E4E4E3 74.73%)',
                    flexDirection: 'column',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    style={{
                        height: 443,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack
                        style={{
                            margin: '69px 33px 69px 35px',
                            height: 305,
                            width: 305
                        }}
                    >
                        <Stack
                            style={{
                                width: 70,
                                height: 70,
                                border: '1px solid #009900',
                                borderRadius: '50%',
                                backgroundColor: '#009900',
                                alignSelf: 'center'
                            }}
                        >
                            <img
                                src={granted}
                                alt="awaiting-confirmation"
                                width={30}
                                height={30}
                                style={{
                                    alignSelf: 'center',
                                    marginTop: 19 // measured it and 19 suits as the perfect value to center it
                                }}
                            />
                        </Stack>

                        <Text
                            variant="xLarge"
                            style={{
                                marginTop: 33,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            {IvicosStrings.accessGrantedTitle}
                        </Text>
                        <Text variant="medium" style={{ marginTop: 33 }}>
                            <b>{email}</b>
                            {IvicosStrings.accessGrantedDescription1}
                        </Text>
                        <Text variant="medium" style={{ marginTop: 33 }}>
                            {IvicosStrings.accessGrantedDescription2}{' '}
                            <Link style={{ color: '#000', fontWeight: 700 }} onClick={redirectToProfilePage}>
                                {IvicosStrings.here}
                            </Link>{' '}
                            {IvicosStrings.accessGrantedDescription3}
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default NewUserAccessGranted;
