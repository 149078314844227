import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useOutsideCallControllerState } from './OutsideCallController.state';
import OutsideCallControllerView from './OutsideCallController.view';
import OutsideCallControllerResponsiveView from './OutsideCallControllerResponsive.view';

export interface IOutsideCallControllerProps {
    displayName: string;
}

const OutsideCallController: React.FC<IOutsideCallControllerProps> = ({ displayName }) => {
    const state = useOutsideCallControllerState();
    const { smallDevice, mediumDevice } = useWindowWidth();

    return (
        <>
            {smallDevice || mediumDevice ? (
                <OutsideCallControllerResponsiveView
                    {...{
                        ...state,
                        ...{
                            displayName: displayName
                        }
                    }}
                />
            ) : (
                <OutsideCallControllerView
                    {...{
                        ...state,
                        ...{
                            displayName: displayName
                        }
                    }}
                />
            )}
        </>
    );
};

export default OutsideCallController;
