import * as React from 'react';

function SvgPlus(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M15 5v10H5v2h10v10h2V17h10v-2H17V5h-2z" fill="#000" />
        </svg>
    );
}

export default SvgPlus;
