import React from 'react';
import { useAvailabilitySelectorState } from './AvailabilitySelector.State';
import AvailabilitySelectorView from './AvailabilitySelector.View';

export interface IAvailabilitySelectorProps {
    onPanelDismiss: Function;
}

const AvailabilitySelector: React.FC<IAvailabilitySelectorProps> = (props) => {
    const state = useAvailabilitySelectorState();

    return (
        <AvailabilitySelectorView
            {...{
                ...state,
                ...{
                    onPanelDismiss: props.onPanelDismiss
                }
            }}
        ></AvailabilitySelectorView>
    );
};
export default AvailabilitySelector;
