import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SvgGranted(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.8422 15L29.7752 12.12C30.4727 9.96443 29.5022 7.6199 27.4847 6.58938L24.7891 5.21236L23.4121 2.51682C22.3816 0.499295 20.0371 -0.472718 17.8815 0.226292L15 1.15781L12.12 0.224792C9.96443 -0.472718 7.6199 0.497796 6.58938 2.51532L5.21086 5.21086L2.51532 6.58938C0.497796 7.6199 -0.472718 9.96443 0.224792 12.12L1.15781 15L0.224792 17.88C-0.472718 20.0356 0.497796 22.3801 2.51532 23.4106L5.21086 24.7876L6.58788 27.4832C7.6184 29.5007 9.96293 30.4727 12.1185 29.7737L15 28.8422L17.88 29.7752C20.0356 30.4727 22.3801 29.5022 23.4106 27.4847L24.7876 24.7891L27.4832 23.4121C29.5007 22.3816 30.4727 20.0371 29.7737 17.8815L28.8422 15ZM12.4395 20.5606L8.99992 17.121C8.41491 16.536 8.41491 15.585 8.99992 15C9.58492 14.415 10.5359 14.415 11.1209 15L13.5 17.379L20.3791 10.4999C20.9641 9.91493 21.9151 9.91493 22.5001 10.4999C23.0851 11.0849 23.0851 12.036 22.5001 12.621L14.5605 20.5606C13.9755 21.1456 13.0245 21.1456 12.4395 20.5606Z"
                fill="white"
            />
        </svg>
    );
}

export default SvgGranted;
