import React from 'react';
import { useChangeLogState } from './ChangeLog.state';
import ChangeLogView from './ChangeLog.view';

const ChangeLog: React.FC = () => {
    const state = useChangeLogState();

    return (
        <ChangeLogView
            {...{
                ...state
            }}
        ></ChangeLogView>
    );
};
export default ChangeLog;
