import { DefaultEffects, mergeStyles, Stack, Text } from '@fluentui/react';
import 'intro.js/introjs.css';
import { IvicosColors } from 'kits/colorKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import 'styles/helpers.css';

const FirefoxAlert: React.FC = () => {
    const actionBarStyles = mergeStyles({
        backgroundColor: IvicosColors.Campusorange,
        transition: 'opacity 0.25s ease-in-out',
        width: 'auto',
        boxShadow: DefaultEffects.elevation16,
        padding: '8px 16px'
    });

    return (
        <Stack className={actionBarStyles} horizontal horizontalAlign="center">
            <Text>{IvicosStrings.firefoxAlert}</Text>
        </Stack>
    );
};

export default FirefoxAlert;
