import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { aRoundTableSpeakerList } from 'shared-state/directory/atoms';
import { IProfile } from 'shared-state/identity/types';
import { RoundTableSpeakerInfo } from '../../RoundTableStateManager';

export interface IRoundTableCardStateProps {
    roundTimeLeft: number;
    isLocalUserCurrentSpeaker: boolean;
    currentSpeakerUserName: string;
}

export const useRoundTableCardState: (
    isRoundTableTimerActivated: boolean,
    currentSpeakerIndex: number,
    localProfile: IProfile | undefined,
    roundTableBorder: string,
    setRoundTableBorder: React.Dispatch<React.SetStateAction<string>>,
    roundDurationInMinutes: number
) => IRoundTableCardStateProps = (
    isRoundTableTimerActivated,
    currentSpeakerIndex,
    localProfile,
    roundTableBorder,
    setRoundTableBorder,
    roundDurationInMinutes
) => {
    const [roundTimeLeft, setRoundTimeLeft] = useState(0);

    const [roundTableSpeakerList] = useRecoilState<RoundTableSpeakerInfo[]>(aRoundTableSpeakerList);

    const previousSpeakerName = useRef<string>('');

    const isLocalUserCurrentSpeaker = roundTableSpeakerList[currentSpeakerIndex].userId === localProfile?.displayName;
    const [currentSpeakerUserName, setCurrentSpeakerUserName] = useState<string>('');
    let timeoutTimerId: NodeJS.Timeout;
    let intervalTimerId: NodeJS.Timeout;

    const clearRound = useCallback((intervalTimerId: NodeJS.Timeout) => {
        intervalTimerId && clearInterval(intervalTimerId);
        setRoundTimeLeft(0);
        setRoundTableBorder('');
    }, []);

    useEffect(() => {
        const isWithTimer = isRoundTableTimerActivated && roundDurationInMinutes > 0.001;
        if (!isWithTimer) {
            intervalTimerId !== undefined && clearRound(intervalTimerId);
            setCurrentSpeakerUserName(roundTableSpeakerList[currentSpeakerIndex].userId);
            setRoundTableBorder('');
        } else {
            const hasCurrentSpeakerChanged = roundTableSpeakerList[currentSpeakerIndex].userId !== previousSpeakerName.current;

            if (hasCurrentSpeakerChanged && roundTableSpeakerList[currentSpeakerIndex].userId !== currentSpeakerUserName) {
                timeoutTimerId && clearTimeout(timeoutTimerId);

                timeoutTimerId = setTimeout(() => {
                    previousSpeakerName.current = roundTableSpeakerList[currentSpeakerIndex].userId;
                    setCurrentSpeakerUserName(roundTableSpeakerList[currentSpeakerIndex].userId);
                }, 100);
            }
        }
        return () => {
            if (timeoutTimerId !== undefined) clearTimeout(timeoutTimerId);
        };
    }, [currentSpeakerIndex, roundTableSpeakerList, roundDurationInMinutes, isRoundTableTimerActivated, currentSpeakerUserName]);

    useEffect(() => {
        if (currentSpeakerUserName !== '' && isRoundTableTimerActivated && roundDurationInMinutes > 0.001) intervalTimerId = startTimer();

        return () => {
            if (intervalTimerId) clearInterval(intervalTimerId);
            setRoundTimeLeft(0);
            setRoundTableBorder('');
        };
    }, [currentSpeakerUserName]);

    const startTimer = React.useCallback(() => {
        const expectedDurationInMs = roundDurationInMinutes * 60000;
        const intervalTimerId = setInterval(() => {
            const calculatedTimeLeft = expectedDurationInMs - (new Date().getTime() - roundTableSpeakerList[currentSpeakerIndex].roundStartTimestamp);

            setRoundTimeLeft(calculatedTimeLeft);
            if (roundTableSpeakerList[currentSpeakerIndex].userId == localProfile?.displayName) {
                if (calculatedTimeLeft <= 0) {
                    roundTableBorder !== 'solid red 12px' && setRoundTableBorder('solid red 12px');
                } else if (calculatedTimeLeft <= expectedDurationInMs / 3) {
                    roundTableBorder !== 'solid yellow 6px' && setRoundTableBorder('solid yellow 6px');
                } else {
                    roundTableBorder !== 'solid green 6px' && setRoundTableBorder('solid green 6px');
                }
            } else {
                roundTableBorder !== '' && setRoundTableBorder('');
            }
        }, 1000);

        return intervalTimerId;
    }, [currentSpeakerIndex, roundTableSpeakerList, roundDurationInMinutes, isRoundTableTimerActivated, roundTableBorder]);

    return {
        roundTimeLeft,
        isLocalUserCurrentSpeaker,
        currentSpeakerUserName
    };
};
