import React from 'react';
import { IProfile } from 'shared-state/identity/types';
import { useRoundTableCardState } from './RoundTableCard.state';
import RoundTableCardView from './RoundTableCard.view';

export interface IRoundTableCardProps {
    onNextClicked: () => void;
    localProfile: IProfile | undefined;
    roundDurationInMinutes: number;
    currentSpeakerIndex: number;
    isCurrentSpeakerLastSpeaker: boolean;
    roundTableBorder: string;
    setRoundTableBorder: React.Dispatch<React.SetStateAction<string>>;
    isRoundTableTimerActivated: boolean;
}

const RoundTableCard: React.FC<IRoundTableCardProps> = (props) => {
    const state = useRoundTableCardState(
        props.isRoundTableTimerActivated,
        props.currentSpeakerIndex,
        props.localProfile,
        props.roundTableBorder,
        props.setRoundTableBorder,
        props.roundDurationInMinutes
    );
    return (
        <RoundTableCardView
            {...{
                ...state,
                ...props
            }}
        />
    );
};
export default RoundTableCard;
