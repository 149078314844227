/* eslint-disable react/display-name */
import { IUserAction } from 'features/Rooms/RoomList/helpers/userTypes';
import { HandWave, Memo, KickOut } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentRoomId } from 'shared-state/location/hook';
import { useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { useControlNameWidthInMemo } from 'shared-state/display-options/hooks';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';

export const useUserActions = (user: IProfile): IUserAction[] => {
    const [currentRoomId] = useCurrentRoomId();

    const localProfile: any = useLocalProfile();

    const [, setMessageComposerUserIds] = useMessageComposerUserIds();
    const [, addMessage] = useReceivedMessageInbox();
    const { inviteUserToRoom, kickVisitor } = useEmittingEvents();
    const [, setControlNameWidthInMemo] = useControlNameWidthInMemo();

    const sendMemoAction: IUserAction = {
        key: 'send-memo',
        title: IvicosStrings.sendUserMemoActionTitle,
        action: () => {
            setMessageComposerUserIds([user.uid]);
            setControlNameWidthInMemo(true);
        },
        onRenderIcon: () => <Memo />
    };

    const inviteToRoomAction: IUserAction = {
        key: 'invite-user',
        title: IvicosStrings.inviteUserToRoomActionTitle,
        action: () => {
            currentRoomId && inviteUserToRoom(user.uid, currentRoomId);
            currentRoomId &&
                addMessage({
                    id: makeMessageId(),
                    type: 'confirmation',
                    body: IvicosStrings.invitationSentNotificationActionTitle,
                    timeout: 2000,
                    sender: '',
                    meta: {},
                    timestamp: new Date()
                });
        },

        onRenderIcon: () => <HandWave />
    };

    const kickOutVisitorAction: IUserAction = {
        key: 'kickOut-Visitor',
        title: IvicosStrings.kickOutVisitorActionTitle,
        action: () => {
            kickVisitor(user.uid);
        },

        onRenderIcon: () => <KickOut />
    };

    let actions: any = [];
    if (currentRoomId == user.room && (localProfile?.type == 'visitor' || localProfile?.type == 'auto-confirmed-visitor')) {
        actions = [sendMemoAction];
    } else if (currentRoomId == user.room && localProfile?.type !== 'visitor' && user.type.includes('visitor')) {
        actions = [sendMemoAction, kickOutVisitorAction];
    } else if (currentRoomId == user.room && localProfile?.type !== 'auto-confirmed-visitor' && user.type.includes('auto-confirmed-visitor')) {
        actions = [sendMemoAction, kickOutVisitorAction];
    } else {
        if (user.type.includes('user')) {
            actions = [sendMemoAction, inviteToRoomAction];
        } else if (user.type.includes('visitor') && localProfile?.type !== 'visitor') {
            actions = [sendMemoAction, inviteToRoomAction, kickOutVisitorAction];
        } else if (user.type.includes('visitor') && localProfile?.type == 'visitor') {
            actions = [sendMemoAction, inviteToRoomAction];
        }
    }

    return localProfile?.uid == user.uid ? [] : actions;
};
