import React, { useState } from 'react';
import AzureAuthenticationContext from './azure-authentication-context';
import { AccountInfo } from '@azure/msal-browser';
import { MicrosoftSignInButtonDE } from './MicrosoftSignInButtonDE.view';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const isIE = msie > 0 || msie11 > 0;

// Log In, Log Out button
const AzureAuthenticationButtonDE = ({ onAuthenticated }: any): JSX.Element => {
    // Azure client context
    const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();

    const [, setAuthenticated] = useState<Boolean>(false);
    const [, setUser] = useState<AccountInfo>();

    const logIn = (): any => {
        const logInType = isIE ? 'loginRedirect' : 'loginPopup';

        // Azure Login
        authenticationModule.login(logInType, returnedAccountInfo);
    };

    const returnedAccountInfo = (user: AccountInfo) => {
        // set state
        setAuthenticated(user?.name ? true : false);
        onAuthenticated(user);
        setUser(user);
    };

    const showLogInButton = (): any => {
        return <MicrosoftSignInButtonDE onClick={() => logIn()} />;
    };

    return (
        <div id="authentication">
            {authenticationModule.isAuthenticationConfigured ? showLogInButton() : <div>Authentication Client ID is not configured.</div>}
        </div>
    );
};

export default AzureAuthenticationButtonDE;
