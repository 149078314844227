import { useRecoilState, useRecoilValue } from 'recoil';
import { IProfile } from './types';
import { sProfile } from 'shared-state/identity/selectors';
import { aAvailability, aIdentityClaims, aProfileImage } from './atoms';

export const useLocalProfile = (): IProfile | undefined => {
    return useRecoilValue(sProfile);
};

export const useSharedAvailability = (): any => {
    return useRecoilState(aAvailability);
};

export const useIdentityClaims = (): any => {
    return useRecoilState(aIdentityClaims);
};

export const useLocalProfileImage = (): any => {
    return useRecoilState(aProfileImage);
};
