import { useMemo } from 'react';
import { usePersonalRoomList } from 'shared-state/directory/hooks';
import { sortRoomList } from './helpers';

export interface IRoomListState {
    personalRoomIds: string[];
}

export const useRoomListState: () => IRoomListState = () => {
    const personalRooms = usePersonalRoomList();

    const personalRoomIds = useMemo(() => {
        return sortRoomList(personalRooms).map((r: any) => r.id);
    }, [personalRooms]);

    return {
        personalRoomIds
    };
};
