import React from 'react';

import { useConnectivityModalState } from './ConnectivityModal.state';
import { ConnectivityModalView } from './ConnectivityModal.views';

export const ConnectivityModal: React.FC<{}> = () => {
    const state = useConnectivityModalState();

    return <ConnectivityModalView {...state} />;
};
