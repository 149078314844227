import React from 'react';
import { Modal, PrimaryButton, Stack, Text } from '@fluentui/react';
import { IConnectivityModalState } from './ConnectivityModal.state';
import { IC } from 'components/SVGIcon';
import { WindowClose } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';

export const ConnectivityModalView: React.FC<IConnectivityModalState> = (props) => {
    const { shouldShowError, isOnline } = props;
    return (
        <Modal isBlocking isOpen={shouldShowError}>
            <Stack horizontalAlign="center" style={{ maxWidth: 320, padding: 26 }} tokens={{ childrenGap: 16 }}>
                <IC size={64} variant={'red'}>
                    <WindowClose />
                </IC>
                <Stack horizontalAlign="center" tokens={{ childrenGap: 6 }} style={{ textAlign: 'center' }}>
                    <Text variant="large">{IvicosStrings.connectionLostMessage}</Text>
                    <Text>{IvicosStrings.lostConnectionMessage}</Text>
                    <Text> {IvicosStrings.clickToReconnectMessage}</Text>
                </Stack>
                <PrimaryButton disabled={!isOnline} text={IvicosStrings.reconnectToCampusActionTitle} onClick={() => window.location.reload()} />
            </Stack>
        </Modal>
    );
};
