import React from 'react';
import FlagsView from './Flags.view';

export type IFeatureFlag = 'jitsiEnv' | 'useDarkMode';

export const setFlag = (flag: IFeatureFlag, value: string): any => {
    localStorage.setItem('flag:' + flag, value);
};

export const getFlag = (flag: IFeatureFlag): any => {
    return localStorage.getItem('flag:' + flag);
};

const Flags = (): any => {
    return <FlagsView setFlag={setFlag} getFlag={getFlag} />;
};

export default Flags;
