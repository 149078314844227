import { roomDTOtoResource } from 'kits/apiKit3/legacy';
import IvicosStrings from 'kits/language/stringKit';
import { resourceService } from 'services/api/resource.service';
import { useRoomList } from 'shared-state/directory/hooks';
import { useRoomSettingsRoomId } from 'shared-state/display-options/hooks';
import { useCurrentAreaId, useCurrentCampusId } from 'shared-state/location/hook';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';

export interface ICreateNewRoomButtonState {
    onClick: (e: any, setButtonState: (state: number) => void) => '' | Promise<any> | undefined;
    enableCreateRoomButton?: boolean;
}

export const useCreateNewRoomButtonState: (enableCreateRoomButton?: boolean) => ICreateNewRoomButtonState = (enableCreateRoomButton?: boolean) => {
    const [, setRoomSettingsRoomId] = useRoomSettingsRoomId();
    const [areaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();
    const [, setRoomList] = useRoomList();
    const { updateRoomData } = useEmittingEvents();

    const _createRoom = async () => {
        const defaultRoom = {
            name: IvicosStrings.roomNameInputPlaceholder,
            iconKey: 'Room',
            isPrivate: false,
            isAudioOnly: false,
            isOpenForVisitors: false
        };
        if (!campusId || !areaId) return {};
        const createdRoom = await resourceService.createRoom(campusId, areaId, defaultRoom);
        const roomRes = roomDTOtoResource(createdRoom);
        roomRes.attributes.whitelist = [];
        return { data: roomRes };
    };

    const onClick = (e: any, setButtonState: (state: number) => void) => {
        // Set button state to loading
        setButtonState(1);
        return (
            areaId &&
            _createRoom().then((r: any) => {
                // Set button state to success
                setButtonState(2);

                // Open room settings for new room
                if (r.data) {
                    updateRoomData('creation', r.data);
                    setRoomList((prevRoomList: any) => [...prevRoomList, ...[r.data]]);
                    setRoomSettingsRoomId(r.data.id);
                }

                setTimeout(() => {
                    // Reset button state after timeout
                    setButtonState(0);
                }, 1200);
                return r;
            })
        );
    };

    return { onClick, enableCreateRoomButton };
};
