import { Steps } from 'intro.js-react';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';
import { ISteps } from './Members.types';

const MembersIntro: React.FC = () => {
    const [stepsEnabled, setStepsEnabled] = useState<boolean>();

    useEffect(() => {
        if (localStorage.getItem('intro-manage-access') === 'false') {
            setStepsEnabled(false);
        } else if (localStorage.getItem('intro-manage-access') === 'true') {
            setStepsEnabled(true);
        }
    }, []);

    const steps: ISteps = {
        stepsEnabled: true,
        initialStep: 0,
        steps: [
            {
                element: '.invite',
                intro: IvicosStrings.memberIntroStep1
            },
            {
                element: '.table',
                intro: IvicosStrings.memberIntroStep2,
                position: 'top'
            },
            {
                element: '.filter',
                intro: IvicosStrings.memberIntroStep3,
                tooltipClass: 'introjs-tooltip-thumb'
            }
        ]
    };

    const options = {
        doneLabel: 'Ok!',
        nextLabel: IvicosStrings.nextActionTitle2,
        prevLabel: IvicosStrings.back,
        stepNumbersOfLabel: IvicosStrings.of,
        hideNext: false,
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true
    };

    return (
        <Steps
            enabled={stepsEnabled}
            steps={steps.steps}
            initialStep={steps.initialStep}
            options={options}
            onExit={() => setStepsEnabled(false)}
            onStart={() => {
                localStorage.setItem('intro-manage-access', 'false');
            }}
        />
    );
};

export default MembersIntro;
