import { getTheme, Persona, PrimaryButton, Stack, Text } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { IInviteStepProps } from './InviteStep';

export type ICSVData = { body: string[][]; headline: string[]; setBody: (value: React.SetStateAction<string[][]>) => void };

const InviteStepView: React.FC<IInviteStepProps> = (props) => {
    const { data, invitationStatus, onFinish } = props;
    const { smallDevice } = useWindowWidth();
    const theme = getTheme();

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack style={{ margin: '16px 0px', overflow: 'scroll', height: smallDevice ? 500 : 600 }}>
                {data?.body.map((user, index) => {
                    return (
                        <Stack key={user[0]} style={{ marginBottom: 16 }}>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                <Persona text={user[0]} />
                                <Stack style={{ borderRadius: 50, width: 3, height: 3, backgroundColor: theme.palette.black }} />
                                <Text>
                                    {
                                        [
                                            IvicosStrings.ImportCSVFileTitle3,
                                            IvicosStrings.successfullyInvited,
                                            IvicosStrings.invitationAlreadySent,
                                            IvicosStrings.inactiveAccountInvited
                                        ][invitationStatus[index] || 0]
                                    }
                                </Text>
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
            <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 16 }}>
                <PrimaryButton
                    aria-label="finish-bulk-invite-button"
                    text={IvicosStrings.finishActionTitle}
                    onClick={() => onFinish()}
                    iconProps={{ iconName: 'PowerButton' }}
                    disabled={invitationStatus.length !== data?.body.length}
                />
            </Stack>
        </Stack>
    );
};

export default InviteStepView;
