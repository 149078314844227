import * as React from 'react';

function SvgPersonSearch(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="28" height="25" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.289978 9.96258C0.289978 5.01404 4.31623 0.987793 9.26476 0.987793C14.2133 0.987793 18.2395 5.01404 18.2395 9.96258C18.2395 11.5497 17.8254 13.042 17.0994 14.337L22.1047 18.0508L21.3914 19.0122L16.4446 15.3417C14.8058 17.5235 12.1972 18.9374 9.26476 18.9374C4.31623 18.9374 0.289978 14.9111 0.289978 9.96258ZM16.8588 9.96258C16.8588 5.76104 13.4663 2.36853 9.26476 2.36853C5.06323 2.36853 1.67071 5.76104 1.67071 9.96258C1.67071 14.1641 5.06323 17.5566 9.26476 17.5566C13.4663 17.5566 16.8588 14.1641 16.8588 9.96258ZM5.81292 7.89147C5.81292 5.99296 7.36625 4.43963 9.26476 4.43963C11.1633 4.43963 12.7166 5.99296 12.7166 7.89147C12.7166 8.93781 12.2285 9.86819 11.4869 10.5019C13.0402 11.3083 14.0973 12.9317 14.0973 14.7952H12.7166C12.7166 12.8805 11.1795 11.3433 9.26476 11.3433C7.35007 11.3433 5.81292 12.8805 5.81292 14.7952H4.43219C4.43219 12.9317 5.48931 11.3083 7.04264 10.5019C6.30103 9.86819 5.81292 8.93781 5.81292 7.89147ZM11.3359 7.89147C11.3359 6.73996 10.4163 5.82037 9.26476 5.82037C8.11325 5.82037 7.19366 6.73996 7.19366 7.89147C7.19366 9.04299 8.11325 9.96258 9.26476 9.96258C10.4163 9.96258 11.3359 9.04299 11.3359 7.89147Z"
                fill="black"
            />
        </svg>
    );
}

export default SvgPersonSearch;
