import * as React from 'react';

function SvgShareScreenOff(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M3 7v18h26V7H3zm2 2h22v14H5V9z" fill="#000" />
            <path
                d="M18.828 20.243l1.415-1.415L17.414 16l2.829-2.828-1.415-1.415L16 14.586l-2.828-2.829-1.415 1.415L14.586 16l-2.829 2.828 1.415 1.415L16 17.414l2.828 2.829z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgShareScreenOff;
