import { Dialog, DialogFooter, PrimaryButton, Stack } from '@fluentui/react';
import AlertModal from 'components/AlertModals/AlertModal';
import AreasPanel from 'features/Areas/AreaPanel/AreaPanel';
import VisitorCard from 'features/Header/children/InvitationsManager/children/VisitorsCard/VisitorsCard';
import MemoComposer from 'features/MemoComposer/MemoComposer';
import { aNotification } from 'features/NotificationsLayer/hooks/atoms';
import NotificationsLayer from 'features/NotificationsLayer/NotificationsLayer';
import RoomList from 'features/Rooms/RoomList/RoomList/RoomList';
import RoomView from 'features/Rooms/RoomView/RoomView';
import SideBar from 'features/SideBar/SideBar';
import 'intro.js/introjs.css';
import IvicosStrings from 'kits/language/stringKit';
import { DividerBox } from 'rc-dock';
import 'rc-dock/dist/rc-dock.css';
import { default as React, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useControlTogglePanelState, useIsSideBarMaximized, useSideBarDraggableWidth } from 'shared-state/display-options/hooks';
import { useVisitorList } from 'shared-state/presence/hooks';
import utf8 from 'utf8';
export interface IMainViewProps {
    smallDevice: boolean;
    mobileMenuOpen: boolean;
    toggleAlert: () => void;
    displayAlert: boolean;
}

const MainView: React.FC<IMainViewProps> = ({ smallDevice, mobileMenuOpen, toggleAlert, displayAlert }) => {
    const visitors = useVisitorList()[0];
    // console.log('🚀 ~ file: Dashboard.view.tsx:89 ~ visitors:', visitors);
    const [hideDialog, setHideDialog] = useRecoilState<any>(aNotification);

    const [members] = useState<any>([]);

    const [, setSideBarDraggableWidth] = useSideBarDraggableWidth();

    const [isSidebarMaximized] = useIsSideBarMaximized();
    const [controlTogglePanelState] = useControlTogglePanelState();

    const sideBarContainerStyles = {
        width: smallDevice ? '100%' : isSidebarMaximized ? '20%' : '48px',
        maxWidth: smallDevice ? 'auto' : isSidebarMaximized ? 500 : 48,
        height: '100%',
        display: smallDevice ? 'contents' : 'block',
        minWidth: isSidebarMaximized ? '300px' : '48px'
    };

    const mainViewContainerStyles = { width: smallDevice ? '100%' : '80%', height: isSidebarMaximized && smallDevice ? '0%' : '100%' };

    // This is to detect the changes of width of the sidebar
    const elementRef: any = useRef(null);
    const elementRef2: any = useRef(null);

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            setSideBarDraggableWidth(elementRef.current?.offsetWidth);
        });
        observer.observe(elementRef.current);
        return () => observer.disconnect();
    }, [controlTogglePanelState]);

    return (
        <>
            <Stack
                grow={!smallDevice ? true : false}
                horizontal={!smallDevice ? true : false}
                verticalAlign="stretch"
                style={{ maxWidth: '100%', overflow: 'hidden', height: '100%' }}
            >
                <MemoComposer />
                <AreasPanel />
                <NotificationsLayer />
                {displayAlert && (
                    <AlertModal title={IvicosStrings.generalAlertTitle} subText={IvicosStrings.browserNotSupportAlert} toggleAlert={toggleAlert} />
                )}
                <>
                    {!controlTogglePanelState && <div ref={elementRef}></div>}
                    <DividerBox mode={smallDevice ? 'vertical' : 'horizontal'} style={{ width: '100%', height: '100%' }}>
                        <div style={sideBarContainerStyles} ref={!controlTogglePanelState ? elementRef2 : elementRef}>
                            <SideBar mobileMenuOpen={!smallDevice ? true : mobileMenuOpen}>
                                <RoomList />
                            </SideBar>
                        </div>
                        <div style={mainViewContainerStyles}>
                            <Stack style={{ height: '100%' }}>
                                <RoomView mobileMenuOpen={!smallDevice ? false : mobileMenuOpen} />
                            </Stack>
                        </div>
                    </DividerBox>
                </>

                <Dialog hidden={hideDialog}>
                    <Stack horizontalAlign="center" verticalAlign="center">
                        {visitors.length > 0 &&
                            visitors.map((visitor: any) => {
                                return (
                                    <>
                                        <VisitorCard
                                            name={utf8.decode(visitor.displayName)}
                                            key={visitor.uid}
                                            host={visitor.host}
                                            members={members}
                                            invitationId={visitor.invitationId}
                                            subInfo={visitor.subInfo}
                                        />
                                    </>
                                );
                            })}
                    </Stack>
                    <DialogFooter>
                        <PrimaryButton onClick={() => setHideDialog(!hideDialog)} text={IvicosStrings.closeActionTitle} />
                    </DialogFooter>
                </Dialog>
            </Stack>
        </>
    );
};

export default MainView;
