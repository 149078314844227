import React, { useState } from 'react';
import RoomSettings from '../RoomSettings/RoomSettings';
import RoomListIntro from './RoomList.intro';

const RoomListPanelsView: React.FC<{}> = () => {
    const [displayIntrojs, setDisplayIntrojs] = useState<boolean>(false);
    setTimeout(() => {
        setDisplayIntrojs(true);
    }, 1500);

    return (
        <React.Fragment>
            <RoomSettings />
            {displayIntrojs && <RoomListIntro />}
        </React.Fragment>
    );
};
export default RoomListPanelsView;
