import {
    Checkbox,
    DefaultButton,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    IImageProps,
    IconButton,
    Image,
    ImageFit,
    Label,
    Modal,
    PrimaryButton,
    Stack,
    Text,
    TextField
} from '@fluentui/react';
import { AttachmentDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { aPlayingVideoId } from 'features/LinkAttachmentModalBox/atom';
import AttachmentCreationModal from 'features/Rooms/RoomView/comps/Attachments/AttachmentCreationModal/AttachmentCreationModal';
import IvicosStrings from 'kits/language/stringKit';
// import { IvicosColors } from 'kits/colorKit';
import { IC } from 'components/SVGIcon';
import { DownArrow, UpArrow } from 'kits/IconKit2';
import { IvicosColors } from 'kits/colorKit';
import Vector from 'media/img/Vector.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useRecoilValue } from 'recoil';
import 'styles/helpers.css';
import AttachmentUpdateModal from '../AttachmentUpdateModel/AttachmentUpdateModal';
import AttachmentItem from './AttachmentItem/AttachmentItem';
import { IAttachmentsPanelState } from './AttachmentPanel.state';

const AttachmentsPanelView: React.FC<IAttachmentsPanelState> = (props) => {
    const {
        campusId,
        areaId,
        roomId,
        isAttachmentPanelVisible,
        theme,
        setIsAttachmentPanelVisible,
        isAttachmentCreationModalOpen,
        setIsAttachmentCreationModalOpen,
        setSelectedAttachment,
        refetchAttachments,
        refetchAttachmentsOnCreation,
        isAttachmentUpdateModalOpen,
        attachmentToEdit,
        setIsAttachmentUpdateModalOpen,
        setAttachmentToEdit,
        attachments,
        addOn,
        localProfile,
        onDelete,
        onDragEnd,
        updateAttachmentPinStatus,
        videoThumbnailClick,
        calculateActualVideoStatus,
        isLocalUserVideoOwner,
        videoWatchersList,
        videoOwnerParticipant,
        changeVideoOwner,
        jitsiApi,
        isVideoOwnershipTransferedToLocal,
        previousVideoOwnerParticipant
    } = props;

    const [youtubePinnedAttachments, setYoutubePinnedAttachments] = useState<AttachmentDto[]>([]);
    const [youtubePinnedAttachmentsExpanded, setYoutubePinnedAttachmentsExpanded] = useState<{ [index: string]: boolean }>({});

    const [isVideoPlayingInfoShown, setIsVideoPlayingInfoShown] = useState<boolean>(true);
    const [isVideoOwnerTransferModalShown, setIsVideoOwnerTransferModalShown] = useState<boolean>(false);

    const playingVideoId = useRecoilValue(aPlayingVideoId);

    useEffect(() => {
        setIsVideoOwnerTransferedToLocalUserModalShown(previousVideoOwnerParticipant !== undefined && isVideoOwnershipTransferedToLocal);
    }, [isVideoOwnershipTransferedToLocal]);

    const [isVideoOwnerTransferedToLocalUserModalShown, setIsVideoOwnerTransferedToLocalUserModalShown] = useState<boolean>(isVideoOwnershipTransferedToLocal);

    useEffect(() => {
        const pinnedAttachments = attachments.filter((att) => att.addOnName === 'youtubelinkmanager' && att.isPinned);
        setYoutubePinnedAttachments(pinnedAttachments);
    }, [attachments]);

    useEffect(() => {
        const newPinnedAttachments = youtubePinnedAttachments.filter((att) => !(att.id in youtubePinnedAttachmentsExpanded));
        if (newPinnedAttachments.length > 0) {
            setYoutubePinnedAttachmentsExpanded((prev) => {
                let obj = { ...prev };
                newPinnedAttachments
                    .map((att) => att.id)
                    .forEach((id) => {
                        obj = { ...obj, [id]: true };
                    });
                return obj;
            });
        }
    }, [youtubePinnedAttachments]);

    const imageProps: IImageProps = {
        imageFit: ImageFit.cover,
        height: 150,
        styles: (props) => ({ root: { border: '1px solid ' + props.theme.palette.neutralSecondary }, position: 'relative' })
    };

    const youtubeVideoStyles = {
        root: {
            borderRadius: 2,
            border: '2px ' + theme.palette.themePrimary + ' solid',
            opacity: 1.0,
            cursor: 'pointer'
        }
    };

    const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: '100%' } };

    const toggleExpand = (itemId: string) => {
        setYoutubePinnedAttachmentsExpanded((prev) => {
            const obj = { ...prev };
            obj[itemId] = !obj[itemId];
            return obj;
        });
    };

    const watchers = videoWatchersList
        .map((w) => ({ key: w.participantId, text: w.displayName }))
        .filter((a) => a.key !== videoOwnerParticipant?.participantId);

    const pinIconSvg = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill="currentColor"
                d="M10.059 2.445a1.5 1.5 0 0 0-2.386.354l-2.02 3.79l-2.811.937a.5.5 0 0 0-.196.828L4.793 10.5l-2.647 2.647L2 14l.853-.146L5.5 11.207l2.146 2.147a.5.5 0 0 0 .828-.196l.937-2.81l3.779-2.024a1.5 1.5 0 0 0 .354-2.38l-3.484-3.5Z"
            />
        </svg>
    );

    const youtubeIconSvg = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
        </svg>
    );

    const playLayer = (
        <svg width="249" height="154" viewBox="0 0 249 154" fill="none" xmlns="http://www.w3.org/2000/svg" className="pressable">
            <circle cx="124.5" cy="76.5" r="41" fill="black" fillOpacity="0.4" stroke="white" strokeWidth="3" />
            <path d="M150 77L111 99.5167L111 54.4833L150 77Z" fill="white" />
        </svg>
    );

    const stopLayer = (
        <svg width="249" height="154" viewBox="0 0 249 154" fill="none" xmlns="http://www.w3.org/2000/svg" className="pressable">
            <circle cx="124.5" cy="76.5" r="41" fill="black" fillOpacity="0.4" stroke="white" strokeWidth="3" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M145.102 80.3546L124.247 50L103.392 80.3546H145.102ZM145.092 87.2085H103V96.9219H145.092V87.2085Z"
                fill="white"
            />
        </svg>
    );

    const [userToTransferVideoOwnership, setUserToTransferVideoOwnership] = useState<IDropdownOption>();

    const [filter, setFilter] = useState<string | undefined>('');

    const [filteredAttachments, setFilteredAttachments] = useState<AttachmentDto[]>(attachments);

    const filterAttachments = useCallback((filter: string | undefined, attachments: AttachmentDto[]) => {
        if (filter) return attachments.filter((att) => att.title.toLowerCase().includes(filter.toLowerCase()));
        else return attachments;
    }, []);

    useEffect(() => {
        const filtAtt = filterAttachments(filter, attachments);
        setFilteredAttachments(filtAtt);
    }, [filter, attachments]);

    const checkAndActivateDragEnd = (result: any) => {
        if (!filter || filter === '') onDragEnd(result);
    };

    const modal = (
        <Modal isOpen={isVideoOwnerTransferModalShown}>
            <Stack
                style={{
                    backgroundColor: 'white',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 500,
                    height: 240
                }}
            >
                <Stack horizontal={true}>
                    <Text style={{ color: 'black', marginLeft: 20, marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                        {IvicosStrings.videoOwnerTransferModalTitle}
                    </Text>
                    <IconButton
                        iconProps={{ iconName: 'cancel' }}
                        ariaLabel="Close popup modal"
                        style={{ color: 'black', marginLeft: 250, marginTop: 10 }}
                        onClick={() => setIsVideoOwnerTransferModalShown(false)}
                    />
                </Stack>
                <Stack>
                    <Text
                        variant="large"
                        style={{
                            marginLeft: 20,
                            marginTop: 20,
                            marginRight: 70,
                            marginBottom: 10,
                            fontSize: 16
                        }}
                    >
                        {IvicosStrings.videoOwnerTransferModalSentence1} {userToTransferVideoOwnership && userToTransferVideoOwnership.text}.
                    </Text>
                    <Text
                        variant="large"
                        style={{
                            marginLeft: 20,
                            marginTop: 10,
                            marginRight: 70,
                            marginBottom: 40,
                            fontSize: 16
                        }}
                    >
                        {IvicosStrings.videoOwnerTransferModalSentence2}
                    </Text>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <DefaultButton onClick={() => setIsVideoOwnerTransferModalShown(false)} text={IvicosStrings.videoOwnerTransferModalCancelButtonLabel} />
                    <PrimaryButton
                        onClick={() => {
                            userToTransferVideoOwnership && changeVideoOwner(String(userToTransferVideoOwnership?.key));
                            setIsVideoOwnerTransferModalShown(false);
                        }}
                        text={IvicosStrings.videoOwnerTransferModalTransferButtonLabel}
                    />
                </Stack>
            </Stack>
        </Modal>
    );

    const videoOwnershipTransferredToLocalUserModal = (
        <Modal isOpen={isVideoOwnerTransferedToLocalUserModalShown}>
            <Stack
                style={{
                    backgroundColor: 'white',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 500,
                    height: 280
                }}
            >
                <Stack horizontal={true}>
                    <Text style={{ color: 'black', marginLeft: 20, marginTop: 20, fontSize: 18, fontWeight: 'bold' }}>
                        {IvicosStrings.videoOwnerTransferModalTitle}
                    </Text>
                    <IconButton
                        iconProps={{ iconName: 'cancel' }}
                        ariaLabel="Close popup modal"
                        style={{ color: 'black', marginLeft: 250, marginTop: 10 }}
                        onClick={() => setIsVideoOwnerTransferedToLocalUserModalShown(false)}
                    />
                </Stack>
                <Stack>
                    <Text
                        variant="large"
                        style={{
                            marginLeft: 20,
                            marginTop: 20,
                            marginRight: 70,
                            marginBottom: 10,
                            fontSize: 16
                        }}
                    >
                        {IvicosStrings.videoOwnershipTransferredToLocalUserModalSentence1Part1}
                        {previousVideoOwnerParticipant?.displayName}
                        {IvicosStrings.videoOwnershipTransferredToLocalUserModalSentence1Part2}
                    </Text>
                    <Text
                        variant="large"
                        style={{
                            marginLeft: 20,
                            marginTop: 10,
                            marginRight: 70,
                            marginBottom: 40,
                            fontSize: 16
                        }}
                    >
                        {IvicosStrings.videoOwnershipTransferredToLocalUserModalSentence2}
                    </Text>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign="end">
                    <PrimaryButton
                        onClick={() => {
                            setIsVideoOwnerTransferedToLocalUserModalShown(false);
                        }}
                        style={{
                            marginTop: 10,
                            marginLeft: 240,
                            fontSize: 16
                        }}
                        text={IvicosStrings.videoOwnershipTransferredToLocalUserModalOkButtonLabel}
                    />
                </Stack>
            </Stack>
        </Modal>
    );

    if (!campusId || !areaId || !roomId) return <></>;
    return (
        <>
            {isAttachmentPanelVisible && (
                <Stack
                    style={{
                        background: theme.palette.white,
                        color: theme.semanticColors.bodyText,
                        minWidth: 320,
                        width: 320,
                        height: '87.8vh',
                        padding: '8px 16px',
                        overflowY: 'scroll'
                    }}
                    tokens={{ childrenGap: 16 }}
                >
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        <Text variant="xLarge">{IvicosStrings.attachmentPanelTitle}</Text>
                        <IconButton
                            onClick={() => {
                                setIsAttachmentPanelVisible(false);
                            }}
                            iconProps={{ iconName: 'clear' }}
                        />
                    </Stack>
                    {modal}
                    {videoOwnershipTransferredToLocalUserModal}
                    {/* Wiedergabe  */}
                    {youtubePinnedAttachments.length != 0 && (
                        <Stack style={{ backgroundColor: theme.palette.themeLighter, padding: 5, borderRadius: 4 }}>
                            <Stack
                                style={{
                                    backgroundColor: IvicosColors.Dunkelblau,
                                    textAlign: 'center',
                                    width: '43%',
                                    borderRadius: '15px',
                                    marginBottom: 16
                                }}
                            >
                                <Text variant="small" style={{ color: theme.palette.white, padding: '3.5px 1px', fontWeight: 'bolder' }}>
                                    {IvicosStrings.experimentalLabelTitle}
                                </Text>
                            </Stack>

                            {youtubePinnedAttachments &&
                                youtubePinnedAttachments.find(
                                    (item) => calculateActualVideoStatus(item) === 'playing' || calculateActualVideoStatus(item) === 'controlledByRemoteUser'
                                ) && (
                                    <Stack
                                        tokens={{ childrenGap: 16 }}
                                        style={{ border: '0.5px solid ' + theme.palette.black, padding: 7, borderRadius: 4, marginBottom: 16 }}
                                    >
                                        <Stack horizontal={true} verticalAlign="center">
                                            {youtubeIconSvg}
                                            <Label style={{ marginLeft: 7 }}>{IvicosStrings.youtubeVideoPlaying}</Label>
                                        </Stack>
                                        {isVideoPlayingInfoShown && (
                                            <Stack tokens={{ childrenGap: 16 }}>
                                                <Stack tokens={{ childrenGap: 7 }}>
                                                    <Label>{IvicosStrings.videoControlPanelPlaybackControlledByTitle}</Label>
                                                    <Text>{videoOwnerParticipant && videoOwnerParticipant.displayName}</Text>
                                                </Stack>
                                                {isLocalUserVideoOwner && (
                                                    <Stack>
                                                        <Dropdown
                                                            aria-label="video-owner-update-dropdown"
                                                            options={watchers}
                                                            onChange={(e, i) => {
                                                                if (i !== undefined) {
                                                                    jitsiApi && jitsiApi.pauseShareVideo && jitsiApi.pauseShareVideo();
                                                                    setUserToTransferVideoOwnership(i);
                                                                    setIsVideoOwnerTransferModalShown(true);
                                                                }
                                                            }}
                                                            styles={dropdownStyles}
                                                            label={IvicosStrings.videoControlPanelTransferPlaybackControlTitle}
                                                            notifyOnReselect={true}
                                                        />
                                                    </Stack>
                                                )}
                                            </Stack>
                                        )}
                                        <Stack
                                            onClick={() => {
                                                setIsVideoPlayingInfoShown((prev) => !prev);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="pressable"
                                        >
                                            <IC size={18} variant="dark">
                                                {isVideoPlayingInfoShown ? <UpArrow /> : <DownArrow />}
                                            </IC>
                                        </Stack>
                                    </Stack>
                                )}

                            {youtubePinnedAttachments.find((attachment) => attachment.isPinned) && (
                                <Stack style={{ border: '0.5px solid ' + theme.palette.black, padding: 7, borderRadius: 4 }}>
                                    {youtubePinnedAttachments
                                        .filter((item) => item.isPinned)
                                        .map((item) => (
                                            <Stack key={item.id}>
                                                <Stack horizontal={true} verticalAlign="center" style={{ marginBottom: 10 }}>
                                                    {pinIconSvg}
                                                    <Label style={{ marginLeft: '7px' }}>{IvicosStrings.pinnedAttachmentPanelTitle}</Label>
                                                </Stack>
                                                {youtubePinnedAttachmentsExpanded[item.id] && (
                                                    <Stack
                                                        aria-label="link-attachment-modal-box-is-pinned-video-thumbnail"
                                                        style={{ position: 'relative' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            (isLocalUserVideoOwner || playingVideoId !== '') && videoThumbnailClick(item);
                                                        }}
                                                    >
                                                        <Image
                                                            {...imageProps}
                                                            src={'https://img.youtube.com/vi/' + item.ref + '/0.jpg'}
                                                            alt="Campus logo"
                                                            styles={youtubeVideoStyles}
                                                        ></Image>

                                                        <Stack
                                                            style={{
                                                                zIndex: 100,
                                                                position: 'absolute'
                                                            }}
                                                        >
                                                            {calculateActualVideoStatus(item) === 'playing' ? stopLayer : playLayer}
                                                        </Stack>
                                                    </Stack>
                                                )}
                                                <Text variant="medium" style={{ margin: '7px 0px 16px 0px', fontStyle: 'italic' }}>
                                                    {item.title}
                                                </Text>
                                                {youtubePinnedAttachmentsExpanded[item.id] && (
                                                    <Stack aria-label="link-attachment-modal-box-is-pinned-checkbox" style={{ marginBottom: 16 }}>
                                                        <Checkbox
                                                            checked={item.isPinned}
                                                            className="show-expired-invitations-button"
                                                            label={IvicosStrings.youtubeLinkAttachmentModalVideoPinText}
                                                            onChange={(event: any, checked: any) => {
                                                                event.preventDefault();
                                                                updateAttachmentPinStatus(item, checked).then(() => refetchAttachments());
                                                            }}
                                                        />
                                                    </Stack>
                                                )}
                                                <Stack
                                                    onClick={() => {
                                                        toggleExpand(item.id);
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                    className="pressable"
                                                >
                                                    <IC size={18} variant="dark">
                                                        {youtubePinnedAttachmentsExpanded[item.id] ? <UpArrow /> : <DownArrow />}
                                                    </IC>
                                                </Stack>
                                            </Stack>
                                        ))}
                                </Stack>
                            )}
                        </Stack>
                    )}

                    {isAttachmentCreationModalOpen ? (
                        <AttachmentCreationModal
                            isOpen={isAttachmentCreationModalOpen}
                            onAttachmentCreation={(attachment?) => {
                                setIsAttachmentCreationModalOpen(false);
                                setSelectedAttachment(undefined);
                                setTimeout(() => {
                                    attachment &&
                                        setSelectedAttachment({ addOnId: attachment.addOnName, description: attachment.description, ref: attachment.ref });
                                }, 400);
                                setIsAttachmentPanelVisible(false);
                                refetchAttachmentsOnCreation();
                            }}
                            onDismiss={() => {
                                setIsAttachmentCreationModalOpen(false);
                                refetchAttachmentsOnCreation();
                            }}
                        />
                    ) : null}
                    {isAttachmentUpdateModalOpen ? (
                        <AttachmentUpdateModal
                            attachment={attachmentToEdit}
                            isOpen={isAttachmentUpdateModalOpen}
                            onAttachmentUpdate={() => {
                                setIsAttachmentUpdateModalOpen(false);
                                refetchAttachments();
                                setAttachmentToEdit(undefined);
                            }}
                            onDismiss={() => {
                                setIsAttachmentUpdateModalOpen(false);
                                refetchAttachments();
                                setAttachmentToEdit(undefined);
                            }}
                        />
                    ) : null}
                    <Stack tokens={{ childrenGap: 16 }} style={{ paddingTop: 16 }}>
                        <PrimaryButton
                            aria-label="attachment-create-new-file"
                            onClick={() => {
                                setIsAttachmentCreationModalOpen(true);
                            }}
                            iconProps={{ iconName: 'Add' }}
                            text={IvicosStrings.createNewAttachmentActionTitle}
                        />

                        {/* Temporarily removed filter bar  */}

                        <Stack.Item grow>
                            <TextField
                                ariaLabel="attachments-filter-files"
                                value={filter}
                                iconProps={{ iconName: 'search' }}
                                placeholder={IvicosStrings.filterAttachmentsPlaceholder}
                                onChange={(e, newValue) => {
                                    setFilter(newValue);
                                }}
                            />
                        </Stack.Item>
                        {filter != undefined && filter.length > 0 && (
                            <DefaultButton
                                text={IvicosStrings.clearFilterActionTitle}
                                onClick={() => {
                                    refetchAttachments();
                                    setFilter('');
                                }}
                            />
                        )}
                    </Stack>

                    <Stack style={{ height: '67vh', overflow: 'scroll', border: '1px solid black', borderRadius: '4px', padding: 10 }}>
                        <Stack style={{ marginBottom: '15.5vh' }}>
                            <Stack horizontal verticalAlign="center" style={{ marginBottom: 10 }}>
                                <img src={Vector} style={{ height: 16, marginRight: 5 }} />
                                <Text variant="medium" style={{ fontWeight: 'bold' }}>
                                    {IvicosStrings.savedItemTitle}
                                </Text>
                            </Stack>
                            {filteredAttachments.length <= 0 && (
                                <Stack verticalAlign="center" horizontalAlign="center" style={{ padding: 32, opacity: 0.8 }} tokens={{ childrenGap: 8 }}>
                                    <div style={{ height: 24, minHeight: 24, width: 24, border: '2px ' + theme.palette.black + ' dotted' }} />
                                    <Text variant="small">{IvicosStrings.noAttachmentsWarning}</Text>
                                </Stack>
                            )}
                            <DragDropContext onDragEnd={checkAndActivateDragEnd}>
                                <Droppable droppableId="attachmentList">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {filteredAttachments.map((item, index: any) => (
                                                <AttachmentItem
                                                    key={item.id}
                                                    {...{
                                                        item,
                                                        index,
                                                        campusId,
                                                        areaId,
                                                        roomId,
                                                        setIsAttachmentPanelVisible,
                                                        setSelectedAttachment,
                                                        refetchAttachments,
                                                        setIsAttachmentUpdateModalOpen,
                                                        setAttachmentToEdit,
                                                        addOn,
                                                        localProfile,
                                                        onDelete,
                                                        jitsiApi
                                                    }}
                                                ></AttachmentItem>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default AttachmentsPanelView;
