import { useBoolean } from '@uifabric/react-hooks';
import { useEvent } from 'kits/eventKit';
import { useEffect, useState } from 'react';
import { useIsSideBarMaximized, useWindowWidth } from 'shared-state/display-options/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';

export interface IMainState {
    identityClaims: IIdentityClaims | undefined;
    currentRoomId: string | undefined;
    currentAreaId: string | undefined;
    onTabSelect: () => void;
    smallDevice: boolean;
    mobileMenuOpen: boolean;
    toggleAlert: () => void;
    displayAlert: boolean;
}

export const useMainState: () => IMainState = () => {
    const [identityClaims] = useIdentityClaims();

    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();

    const [, setSideBarIsMaximized] = useIsSideBarMaximized();

    const [displayAlert, { toggle: toggleAlert }] = useBoolean(false);

    const onTabSelect = () => setSideBarIsMaximized(true);

    useEffect(() => {
        if (!('Notification' in window)) {
            toggleAlert();
        } else {
            Notification.requestPermission();
        }
    }, []);

    const { smallDevice } = useWindowWidth();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEvent('closeMobileRoomsPanel', () => {
        setMobileMenuOpen(false);
    });
    useEvent('openMobileRoomsPanel', () => {
        setMobileMenuOpen(true);
    });

    return { identityClaims, currentRoomId, currentAreaId, onTabSelect, smallDevice, mobileMenuOpen, displayAlert, toggleAlert };
};
