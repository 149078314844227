import * as React from 'react';

function SvgVideo(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M2 8v16h22v-3.375l6 3V8.375l-6 3V8H2zm2 2h18v12H4V10zm24 1.625v8.75l-4-2v-4.75l4-2z" fill="#000" />
        </svg>
    );
}

export default SvgVideo;
