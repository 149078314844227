import { Stack, Text, mergeStyles } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import AlertModal from 'components/AlertModals/AlertModal';
import { ISideBarProps } from 'features/SideBar/SideBar';
import { ISideBarState } from 'features/SideBar/SideBar.state';
import { SideBarIconButton } from 'features/SideBar/children/SideBarIconButton';
import { Announce, Close } from 'kits/IconKit2';
import { sendEvent } from 'kits/eventKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { getClassNames } from 'styles/responsiveness';

const SideBarView: React.FC<ISideBarState & ISideBarProps> = (props) => {
    const theme = useTheme();

    const { children, mobileMenuOpen, isMaximized, toggleSidebar, sidebarTitle, toggleAlert, openAnnouncementDialog, displayAlert } = props;

    const { smallDevice } = useWindowWidth();

    const rootStyles = mergeStyles([
        {
            height: '100%',
            userSelect: 'none',
            padding: '0px 0px 0px 16px',
            backgroundColor: 'transparent'
        }
    ]);

    const openPanel = (
        <svg width="24" height="16" viewBox="0 0 24 16" fill={theme.palette.themePrimary} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 7.5001L5 0.8L22 0.799998C22.5523 0.799998 23 1.15817 23 1.6L23 14.4C23 14.8418 22.5523 15.2 22 15.2L5 15.2L5 8.5001H13.7929L11.4645 10.8285C11.2692 11.0238 11.2692 11.3404 11.4645 11.5356C11.6597 11.7309 11.9763 11.7309 12.1716 11.5356L15.3536 8.35365C15.5488 8.15839 15.5488 7.8418 15.3536 7.64654L12.1716 4.46456C11.9763 4.2693 11.6597 4.2693 11.4645 4.46456C11.2692 4.65982 11.2692 4.97641 11.4645 5.17167L13.7929 7.50009L5 7.5001ZM2 16H5L22 16C23.1046 16 24 15.2837 24 14.4L24 1.6C24 0.716342 23.1046 0 22 0H5H2C0.895432 0 0 0.716346 0 1.6V2V14V14.4C0 15.2837 0.895432 16 2 16Z"
                fill="#0078D7"
            />
        </svg>
    );

    const closePanel = (
        <svg width="24" height="16" viewBox="0 0 24 16" fill={theme.palette.themePrimary} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 15.2L5 0.8L22 0.799998C22.5523 0.799998 23 1.15817 23 1.6L23 14.4C23 14.8418 22.5523 15.2 22 15.2L5 15.2ZM2 16H5L22 16C23.1046 16 24 15.2837 24 14.4L24 1.6C24 0.716342 23.1046 0 22 0H5H2C0.895432 0 0 0.716346 0 1.6V2V14V14.4C0 15.2837 0.895432 16 2 16ZM6.64645 8.35346L9.82843 11.5354C10.0237 11.7307 10.3403 11.7307 10.5355 11.5354C10.7308 11.3402 10.7308 11.0236 10.5355 10.8283L8.20711 8.4999H17C17.2761 8.4999 17.5 8.27605 17.5 7.9999C17.5 7.72376 17.2761 7.4999 17 7.4999L8.20711 7.4999L10.5355 5.17148C10.7308 4.97621 10.7308 4.65963 10.5355 4.46437C10.3403 4.26911 10.0237 4.26911 9.82843 4.46437L6.64645 7.64635C6.45118 7.84161 6.45118 8.15819 6.64645 8.35346Z"
                fill="#0078D7"
            />
        </svg>
    );

    const { sideBarView } = getClassNames();

    return (
        <Stack key="sidebar-x" horizontalAlign="stretch" className={smallDevice ? sideBarView : rootStyles} style={mobileMenuOpen ? {} : { display: 'none' }}>
            {displayAlert && <AlertModal title={IvicosStrings.generalAlertTitle} subText={IvicosStrings.connectionTimedOut} toggleAlert={toggleAlert} />}
            <Stack horizontal tokens={{ childrenGap: 8 }} style={{ marginBottom: 8, marginTop: 16 }} verticalAlign="start">
                {!smallDevice ? (
                    <Stack style={{ gap: 15 }}>
                        <SideBarIconButton variant="primary" ariaLabel="toggle-panel" title={IvicosStrings.togglePanelTitle} onClick={() => toggleSidebar()}>
                            {isMaximized ? closePanel : openPanel}
                        </SideBarIconButton>
                    </Stack>
                ) : (
                    <SideBarIconButton title={IvicosStrings.togglePanelTitle} onClick={() => sendEvent('closeMobileRoomsPanel', {})}>
                        <Close />
                    </SideBarIconButton>
                )}
                {isMaximized ? (
                    <>
                        <Stack grow>
                            <Text variant="xLargePlus" style={{ color: theme.palette.black }}>
                                {sidebarTitle}
                            </Text>
                        </Stack>
                        <SideBarIconButton ariaLabel="announce-button" title={IvicosStrings.announcementTitle} onClick={() => openAnnouncementDialog()}>
                            <Announce />
                        </SideBarIconButton>
                    </>
                ) : (
                    <></>
                )}
            </Stack>
            <Stack
                key="sb-content"
                grow
                style={{
                    display: isMaximized ? 'flex' : 'none',
                    overflowY: 'hidden',
                    paddingRight: 10
                }}
            >
                {children}
            </Stack>
            {!isMaximized && <Stack grow />}
            <Stack
                key="sb-footer"
                tokens={{ childrenGap: 16 }}
                horizontal={isMaximized}
                disableShrink={true}
                horizontalAlign="space-around"
                style={{
                    overflow: 'hidden',
                    padding: '16px 0px',
                    minHeight: 64
                }}
            >
                {/* {onRenderTabIcons && onRenderTabIcons()} */}
            </Stack>
        </Stack>
    );
};
export default SideBarView;
