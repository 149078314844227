import React, { useContext, useEffect } from 'react';
import SocketContext from '../context/SocketContext';
import { CLIENT_EMIT_VISITOR_JOINED } from '../events';
import { useSocketHandlers } from '../socket-adapters/event-actions/listeningEvents';
import { useVisitorHandlers } from '../socket-adapters/handlers/visitors';
export interface ISocketVisitorEntranceProps {
    tokenPayload: any;
    eventName: string;
    isVisitorWithHost: boolean;
}

const SocketVisitorEntrance: React.FC<ISocketVisitorEntranceProps> = ({ tokenPayload, eventName, isVisitorWithHost }) => {
    const {
        SocketState: { socket }
    } = useContext(SocketContext);
    const visitorHandlers = useVisitorHandlers();

    useEffect(() => {
        if (socket && tokenPayload) {
            const timer = setTimeout(() => {
                socket.emit(CLIENT_EMIT_VISITOR_JOINED, { tokenPayload, eventName, isVisitorWithHost });
            }, 1000);

            // Cleanup function to clear the timer when the component unmounts
            return () => clearTimeout(timer);
        }
    }, [socket, tokenPayload]);

    useSocketHandlers(visitorHandlers);

    return null;
};

export default SocketVisitorEntrance;
