import { DefaultButton, DefaultEffects, Dialog, DialogType, getTheme, Label, Panel, PrimaryButton, Stack, Text } from '@fluentui/react';
import { Toggle } from '@fluentui/react/lib/Toggle';
import DynamicForm from 'components/DynamicForm/DynamicForm';
import { IC } from 'components/SVGIcon';
import { Close, CloseModal, InfoBubble } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect } from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import RoundTableCard from './children/RoundTableCard/RoundTableCard';
import SpeakerStatsUser from './children/SpeakerStatsUser/SpeakerStatsUser';
import { IRoundTableStateManager, IRoundTableStateUpdate, RoundTableSpeakerInfo } from './RoundTableStateManager';
import { ISpeakerStatsProps } from './SpeakerStats';
import { ISpeakerStatsState } from './SpeakerStats.state';
import { IvicosColors } from 'kits/colorKit';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { aSetTopic, aSetTopicDialog, aShowTopic } from 'features/SetTopicDialog/atom';

export interface ISpeakerStatsViewProps extends ISpeakerStatsProps, ISpeakerStatsState, IRoundTableStateManager {}

const SpeakerStatsView: React.FC<ISpeakerStatsViewProps> = (props) => {
    const theme = getTheme();

    const {
        updateRoundTableStatusLocally,
        updateRoundTableStatusRemotely,
        roundDurationInMinutes,
        currentSpeakerIndex,
        roundTableSpeakerList,
        includeSpeaker,
        removeSpeaker,
        nextSpeaker,

        stats,
        statsAreVisible,
        executeCommand,
        setRoundTableBorder,
        isLocalUserHandRaised,
        onHandRaiseDismiss,
        onChangeMinutes,
        totalTime,
        initialiseRoundTableMode,
        formFields,
        prettifyTime,
        isLocalUserTalksTooMuchModalActive,
        setIsLocalUserTalksTooMuchModalActive,
        isLocalUserTalkingTooMuch,
        isTalkDetailsModalShown,
        setIsTalkDetailsModalShown,
        isRoundTableSettingsModalShown,
        setIsRoundTableSettingsModalShown,
        isRoundTableActive,
        room,
        isRoundTableTimerActivated,
        roundTableBorder
    } = props;

    const { smallDevice, mediumDevice } = useWindowWidth();

    const setToggleSetTopicDialog = useSetRecoilState<boolean>(aSetTopicDialog);
    const [showTopic, setShowTopic] = useRecoilState<boolean>(aShowTopic);
    const topic = useRecoilValue<any>(aSetTopic);

    const onTalkTooMuchModalDismiss = () => {
        setIsLocalUserTalksTooMuchModalActive(false);
    };

    useEffect(() => {
        if (isRoundTableActive && !statsAreVisible) executeCommand('toggleStats');
    }, [isRoundTableActive]);

    const isLargeScreenDevice = !(smallDevice || mediumDevice);

    return (
        <>
            {isRoundTableActive && roundTableSpeakerList[currentSpeakerIndex] && roundTableSpeakerList[currentSpeakerIndex].userId ? (
                <RoundTableCard
                    onNextClicked={nextSpeaker}
                    localProfile={props.localProfile}
                    roundDurationInMinutes={roundDurationInMinutes}
                    currentSpeakerIndex={currentSpeakerIndex}
                    isCurrentSpeakerLastSpeaker={roundTableSpeakerList.length - 1 == currentSpeakerIndex}
                    setRoundTableBorder={setRoundTableBorder}
                    roundTableBorder={roundTableBorder}
                    isRoundTableTimerActivated={isRoundTableTimerActivated}
                />
            ) : null}
            {isLocalUserHandRaised && (
                <Stack
                    horizontalAlign="center"
                    style={{
                        position: 'absolute',
                        ...(isLargeScreenDevice ? { left: '24%' } : { bottom: smallDevice ? '3%' : '', left: smallDevice ? '27%' : '' })
                    }}
                >
                    <Stack
                        styles={{
                            root: {
                                padding: '0.5vh',
                                ...(!isLargeScreenDevice && { cursor: 'pointer' }),
                                width: '240px',
                                height: '80px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                verticalAlign: 'start',
                                backgroundColor: theme.palette.white,
                                color: theme.palette.black,
                                boxShadow: DefaultEffects.elevation8,
                                margin: '5px 0px 0px 5px'
                            }
                        }}
                    >
                        <Stack horizontalAlign="center" style={{ ...(isLargeScreenDevice && { marginLeft: '7px' }) }}>
                            <Label>{IvicosStrings.unraiseHandActionTitle}</Label>
                        </Stack>

                        <Stack style={{ alignItems: 'right', ...(isLargeScreenDevice ? { marginBottom: 10, cursor: 'pointer' } : { paddingBottom: 10 }) }}>
                            <PrimaryButton text={IvicosStrings.unraiseHandActionButtonTitle} onClick={onHandRaiseDismiss} />
                        </Stack>
                    </Stack>
                </Stack>
            )}
            <MobilePanelWrapper
                isLargeScreenDevice={isLargeScreenDevice}
                isOpen={statsAreVisible}
                headerText={IvicosStrings.speakerStatsTitle}
                onDismiss={() => executeCommand('toggleStats')}
            >
                <Stack
                    style={{
                        background: theme.palette.white,
                        color: theme.palette.white,
                        display: !statsAreVisible ? 'none' : 'inherit',
                        ...(isLargeScreenDevice && { height: '100%' })
                    }}
                >
                    {statsAreVisible && isLocalUserTalksTooMuchModalActive && isLocalUserTalkingTooMuch && (
                        <Stack
                            style={{
                                position: 'absolute',
                                width: '30vh',
                                height: '20vh',
                                backgroundColor: theme.palette.white,
                                boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
                                justifyContent: 'center',
                                alignContent: 'center',
                                right: 30,
                                top: 20,
                                zIndex: 2
                            }}
                        >
                            <Stack horizontal horizontalAlign="end" style={{ height: 40 }}>
                                <IC size={24} variant="dark">
                                    <CloseModal style={{ width: '70%', padding: '0.9vh' }} onClick={() => onTalkTooMuchModalDismiss()} className="pressable" />
                                </IC>
                            </Stack>
                            <Stack horizontal verticalAlign="start" style={{ padding: '16px' }} tokens={{ childrenGap: 16 }}>
                                <IC variant="dark" size={48}>
                                    <InfoBubble />
                                </IC>
                                <Stack tokens={{ childrenGap: 16 }} horizontalAlign="start">
                                    <Text variant="large" style={{ color: theme.palette.black }}>
                                        {IvicosStrings.talkingMoreDescription}
                                    </Text>
                                    <Text style={{ color: theme.palette.black }}>{IvicosStrings.talkingMoreDescription2}</Text>
                                </Stack>
                            </Stack>
                            <Stack style={{ height: '3vh' }}></Stack>
                        </Stack>
                    )}
                    {isTalkDetailsModalShown && stats && stats.length > 0 && (
                        <Stack
                            style={{
                                position: 'absolute',
                                width: '30vh',
                                height: '20vh',
                                backgroundColor: theme.palette.white,
                                boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
                                justifyContent: 'center',
                                alignContent: 'center',
                                left: 30,
                                top: 20,
                                zIndex: 2
                            }}
                        >
                            <Stack style={{ height: '3.5vh', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <IC size={24} variant="dark">
                                    <CloseModal
                                        style={{ width: '70%', padding: '0.9vh' }}
                                        onClick={() => {
                                            setIsTalkDetailsModalShown(false);
                                        }}
                                        className="pressable"
                                    />
                                </IC>
                            </Stack>
                            <Stack style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <Text variant="mega" style={{ color: theme.palette.black }}>
                                    {stats[0].speakerTime ? prettifyTime(stats[0].speakerTime) : '00:00:00'}
                                </Text>
                            </Stack>

                            <Stack style={{ height: '3.5vh' }}></Stack>
                        </Stack>
                    )}

                    <Stack
                        style={{
                            padding: 16,
                            background: theme.palette.white,
                            width: 280,
                            ...(isLargeScreenDevice && { height: '100%' })
                        }}
                        tokens={{ childrenGap: 8 }}
                    >
                        {isLargeScreenDevice && (
                            <Stack horizontal style={{ alignItems: 'center', marginBottom: '3.5vh' }}>
                                <Stack grow style={{ color: `${theme.palette.black}` }}>
                                    <Text variant="xLarge">{IvicosStrings.speakerStatsTitle}</Text>
                                </Stack>
                                <Stack
                                    onClick={() => {
                                        executeCommand('toggleStats');
                                    }}
                                    className="pressable"
                                >
                                    <IC size={24} variant="dark">
                                        <Close />
                                    </IC>
                                </Stack>
                            </Stack>
                        )}

                        <Stack
                            style={{
                                paddingLeft: 8,
                                background: theme.palette.white,
                                color: theme.palette.black,
                                ...(isLargeScreenDevice && { height: '100%' })
                            }}
                        >
                            {!showTopic &&
                                topic.length === 0 &&
                                !(props.localProfile?.type === 'visitor' || props.localProfile?.type === 'auto-confirmed-visitor') && (
                                    <DefaultButton
                                        aria-label="set-topic-button"
                                        iconProps={{ iconName: 'edit' }}
                                        style={{ borderRadius: '4px', marginBottom: '20px' }}
                                        text={IvicosStrings.setTopicTitle}
                                        onClick={() => {
                                            setToggleSetTopicDialog(false);
                                            setShowTopic(true);
                                        }}
                                    />
                                )}
                            {stats.length > 0 ? (
                                <>
                                    <Stack
                                        style={{
                                            ...(isLargeScreenDevice && {
                                                // height: 'inherit',
                                                justifyContent: 'space-evenly'
                                            })
                                        }}
                                    >
                                        {isRoundTableActive ? (
                                            <Stack style={{ ...(!isLargeScreenDevice && { marginBottom: 22 }) }}>
                                                <Text variant="xxLarge" style={{ textAlign: 'center', width: '25vh', color: theme.palette.black }}>
                                                    {IvicosStrings.roundTableTitle}
                                                </Text>
                                            </Stack>
                                        ) : (
                                            <Stack style={{ ...(!isLargeScreenDevice && { marginBottom: 22 }) }}>
                                                <Text
                                                    variant="large"
                                                    style={{ textAlign: 'center', color: theme.palette.black, ...(isLargeScreenDevice && { marginLeft: 15 }) }}
                                                >
                                                    {IvicosStrings.totalTalkTimeTitle}:
                                                </Text>
                                                <Text variant="superLarge" style={{ textAlign: 'center', ...(isLargeScreenDevice && { marginLeft: 15 }) }}>
                                                    {prettifyTime(totalTime)}
                                                </Text>
                                            </Stack>
                                        )}

                                        {!isRoundTableActive ? (
                                            <Stack style={{ ...(isLargeScreenDevice ? { height: '100%' } : { marginBottom: 48 }) }}>
                                                <SpeakerStatsUser
                                                    totalTime={totalTime}
                                                    userName={stats[0].userName || 'Me'}
                                                    userId={stats[0].userId}
                                                    speakerTime={stats[0].speakerTime}
                                                    prettifyTime={prettifyTime}
                                                    roundTableIsActive={isRoundTableActive}
                                                    avatarType={stats[0].raisedHandAt > 0 ? 'raise-hand' : 'profile'}
                                                ></SpeakerStatsUser>
                                                <Stack style={{ ...(isLargeScreenDevice ? { height: '80%' } : { height: '36vh' }), overflow: 'scroll' }}>
                                                    <Stack>
                                                        {stats.slice(1).map((stat: any) => (
                                                            <>
                                                                <SpeakerStatsUser
                                                                    key={stat.userId}
                                                                    totalTime={totalTime}
                                                                    userName={stat.userName !== null ? stat.userName : 'something is wrong'}
                                                                    speakerTime={stat.speakerTime}
                                                                    userId={stat.userId}
                                                                    prettifyTime={prettifyTime}
                                                                    roundTableIsActive={isRoundTableActive}
                                                                    avatarType={stat.raisedHandAt > 0 ? 'raise-hand' : 'profile'}
                                                                ></SpeakerStatsUser>
                                                            </>
                                                        ))}
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        ) : (
                                            <Stack style={{ height: '36vh', overflow: 'scroll' }}>
                                                {roundTableSpeakerList &&
                                                    roundTableSpeakerList
                                                        .map((element) => element)
                                                        .sort((a, b) => {
                                                            const calculateNewIndex = (speakerInfo: RoundTableSpeakerInfo) => {
                                                                const index_of_speakerInfo = roundTableSpeakerList.findIndex(
                                                                    (element) => element.userId === speakerInfo.userId
                                                                );
                                                                return index_of_speakerInfo < currentSpeakerIndex
                                                                    ? index_of_speakerInfo + roundTableSpeakerList.length
                                                                    : index_of_speakerInfo;
                                                            };

                                                            return calculateNewIndex(a) - calculateNewIndex(b);
                                                        })
                                                        .map((roundTableSpeaker: RoundTableSpeakerInfo) => {
                                                            const stat = stats.find((obj) => obj.userName == roundTableSpeaker.userId);
                                                            const speakerIndex = roundTableSpeakerList.findIndex(
                                                                (element) => element.userId === roundTableSpeaker.userId
                                                            );

                                                            if (stat && roundTableSpeaker.isInTheRoom)
                                                                return (
                                                                    <>
                                                                        <Stack className="pressable">
                                                                            <SpeakerStatsUser
                                                                                key={stat.userId}
                                                                                totalTime={totalTime}
                                                                                userName={stat.userName !== undefined ? stat.userName : 'something is wrong'}
                                                                                speakerTime={stat.speakerTime}
                                                                                userId={stat.userId}
                                                                                prettifyTime={prettifyTime}
                                                                                roundTableIsActive={isRoundTableActive}
                                                                                avatarType={currentSpeakerIndex < speakerIndex ? 'round-table' : 'profile'}
                                                                                currentSpeakerIndex={currentSpeakerIndex}
                                                                                roundTableSpeakersList={roundTableSpeakerList}
                                                                                userIndexAtRoundTableSpeakerList={speakerIndex}
                                                                                onIncludeSpeaker={includeSpeaker}
                                                                                onRemoveSpeaker={removeSpeaker}
                                                                            ></SpeakerStatsUser>
                                                                        </Stack>
                                                                    </>
                                                                );
                                                        })}
                                            </Stack>
                                        )}

                                        <Stack
                                            {...(isLargeScreenDevice && {
                                                style: {
                                                    backgroundColor: theme.palette.themeLighter,
                                                    borderRadius: 4,
                                                    padding: '6px 6px',
                                                    margin: 6,
                                                    marginBottom: 0
                                                }
                                            })}
                                        >
                                            {isLargeScreenDevice && (
                                                <Stack
                                                    style={{
                                                        backgroundColor: IvicosColors.Dunkelblau,
                                                        textAlign: 'center',
                                                        width: '43%',
                                                        borderRadius: '15px',
                                                        margin: '11px 5px'
                                                    }}
                                                >
                                                    <Text variant="small" style={{ color: theme.palette.white, padding: '3.5px 1px', fontWeight: 'bolder' }}>
                                                        {IvicosStrings.experimentalLabelTitle}
                                                    </Text>
                                                </Stack>
                                            )}
                                            <Stack
                                                horizontal
                                                style={{
                                                    padding: '5px',
                                                    ...(!isLargeScreenDevice && { marginBottom: '1.5vh' })
                                                }}
                                            >
                                                <DefaultButton
                                                    iconProps={{ iconName: 'Clock' }}
                                                    text={isRoundTableActive ? IvicosStrings.stopRoundTableModeTitle : IvicosStrings.startRoundTableModeTitle}
                                                    onClick={
                                                        isRoundTableActive
                                                            ? () => {
                                                                  setIsRoundTableSettingsModalShown(false);
                                                                  const updateData: IRoundTableStateUpdate = {
                                                                      currentSpeakerIndex: 0,
                                                                      roundTableSpeakerList: [],
                                                                      roundDurationInMinutes: '0',
                                                                      isRoundTableActive: false,
                                                                      roundTableRoomId: room.id
                                                                  };
                                                                  updateRoundTableStatusLocally(updateData);
                                                                  updateRoundTableStatusRemotely(updateData);
                                                              }
                                                            : () => {
                                                                  setIsRoundTableSettingsModalShown(true);
                                                              }
                                                    }
                                                    checked={isRoundTableActive}
                                                    disabled={!isRoundTableActive && stats.find((stat) => stat.raisedHandAt) !== undefined}
                                                ></DefaultButton>
                                            </Stack>
                                            {!isRoundTableActive && (
                                                <Stack
                                                    horizontal
                                                    style={{
                                                        padding: '5px',
                                                        marginBottom: '1.5vh'
                                                    }}
                                                >
                                                    <Toggle
                                                        checked={isTalkDetailsModalShown}
                                                        onChange={(event, checked) => {
                                                            setIsTalkDetailsModalShown(checked || false);
                                                        }}
                                                        inlineLabel
                                                    />
                                                    <Text variant="medium" style={{ padding: '0vh 1vh' }}>
                                                        {IvicosStrings.personalTalkTimeToggle}
                                                    </Text>
                                                </Stack>
                                            )}
                                            {!isRoundTableActive && (
                                                <Stack
                                                    horizontal
                                                    style={{
                                                        alignItems: 'center',
                                                        padding: '5px',
                                                        marginTop: 16,
                                                        ...(!isLargeScreenDevice && { marginBottom: '1.5vh' })
                                                    }}
                                                >
                                                    <Toggle
                                                        onChange={() => {
                                                            setIsLocalUserTalksTooMuchModalActive((prev) => !prev);
                                                        }}
                                                        checked={isLocalUserTalksTooMuchModalActive}
                                                        inlineLabel
                                                        defaultChecked
                                                    />
                                                    <Stack horizontalAlign="start">
                                                        {!isLargeScreenDevice && (
                                                            <span
                                                                style={{
                                                                    backgroundColor: theme.palette.themeLight,
                                                                    borderRadius: 12,
                                                                    padding: '3px 6px',
                                                                    fontSize: 10,
                                                                    margin: 6
                                                                }}
                                                            >
                                                                Experimental
                                                            </span>
                                                        )}
                                                        <Text variant="medium" style={{ padding: '0vh 1vh' }}>
                                                            {IvicosStrings.talkingTooMuchToggle}{' '}
                                                        </Text>
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                </>
                            ) : (
                                <Text>{IvicosStrings.noDataTitle}</Text>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </MobilePanelWrapper>
            {isRoundTableSettingsModalShown && (
                <Dialog
                    hidden={!isRoundTableSettingsModalShown}
                    onDismiss={() => {
                        setIsRoundTableSettingsModalShown(false);
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: IvicosStrings.roundTableModeDialogBoxTitle,
                        closeButtonAriaLabel: 'Close'
                    }}
                >
                    <Stack horizontalAlign="end" {...{ verticalAlign: 'end' }} style={{ marginTop: '-40px', marginBottom: '30px' }}>
                        <IC size={20} variant="dark" style={{ cursor: 'pointer' }}>
                            <Close onClick={() => setIsRoundTableSettingsModalShown(false)} />
                        </IC>
                    </Stack>
                    <Stack tokens={{ childrenGap: 24 }}>
                        <DynamicForm formFields={formFields} onChange={onChangeMinutes} />
                        <Stack horizontalAlign="end">
                            <PrimaryButton
                                text={IvicosStrings.startButtonLabel}
                                onClick={() => {
                                    const updateData = initialiseRoundTableMode(stats);
                                    updateRoundTableStatusLocally(updateData);
                                    updateRoundTableStatusRemotely(updateData);
                                    setIsRoundTableSettingsModalShown(false);
                                }}
                            ></PrimaryButton>
                        </Stack>
                    </Stack>
                </Dialog>
            )}
        </>
    );
};
export default React.memo(SpeakerStatsView);

const MobilePanelWrapper = (props: any) => {
    if (!props.isLargeScreenDevice) {
        return (
            <Panel isOpen={props.isOpen} headerText={IvicosStrings.speakerStatsTitle} onDismiss={props.onDismiss}>
                {props.children}
            </Panel>
        );
    }
    return <React.Fragment>{props.children}</React.Fragment>;
};
