/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import * as Dto from './responses.dto';
// import { getAuthHeader } from '../services/auth_header';
import { AbstractService } from '../shared/abstract-service';

export class CampusMediaService extends AbstractService {
    // Images Controller
    public async uploadImage(
        file: File, // the image file
        filename?: string
    ) {
        const formData = new FormData();
        formData.append('file', file, filename);

        const contentTypeHeader = {
            'Content-Type': 'multipart/form-data'
        };

        const response = await axios.post(`${this.baseUrl}/images`, formData, {
            headers: {
                ...this.getHeaders(),
                ...contentTypeHeader
            }
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ImageDto;
    }

    public async getAllUserImages() {
        const response = await axios.get(`${this.baseUrl}/images`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AllImagesDto;
    }

    public async getImage(
        key: string // key is the image name
    ) {
        const response = await axios.get(`${this.baseUrl}/images/${key}`, {
            headers: this.getHeaders()
        });

        if (response.status === 404) {
            throw new Error(response.statusText);
        }
    }

    public async deleteImage(
        key: string // key is the image name
    ) {
        const response = await axios.delete(`${this.baseUrl}/images/${key}`, {
            headers: this.getHeaders()
        });

        if (response.status === 404) {
            throw new Error(response.statusText);
        }
    }

    // Avatar Controller
    public async getUserAvatar() {
        const response = await axios.get(`${this.baseUrl}/avatar`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ImageDto;
    }

    public async updateUserAvatar(
        body: string // the image file
    ) {
        const response = await axios.put(
            `${this.baseUrl}/avatar`,
            {
                body: body
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ImageDto;
    }
}
