import { useCallback } from 'react';

export interface IDynamicFormManagerProps {
    setElementChange: (change: { [index: string]: string | boolean | (string | undefined)[] }) => void;
    hasChanged: () => boolean;
    hasElementChanged: (formKey: string) => boolean;
    resetChanges: () => void;
    deleteChangeForElementIfPresent: (keyToRemove: string) => boolean;
    getElementChange: (key: string) => any;
}
const useDynamicFormManager: (changes: any, setChanges: React.Dispatch<any>) => IDynamicFormManagerProps = (changes, setChanges) => {
    const setElementChange = useCallback((change: { [index: string]: string | boolean | (string | undefined)[] }) => {
        setChanges((prevState: any) => {
            const newState = { ...prevState, ...change };
            return newState;
        });
    }, []);

    const resetChanges = useCallback(() => setChanges({}), []);

    const deleteChangeForElementIfPresent = useCallback(
        (keyToRemove: string) => {
            let isDeleted = false;
            if (keyToRemove in changes) {
                const clone = Object.assign({}, changes);
                isDeleted = delete clone.keyToRemove;
                setChanges(() => {
                    return clone;
                });
            }
            return isDeleted;
        },
        [changes]
    );

    const hasChanged: () => boolean = useCallback(() => changes && Object.keys(changes).length > 0, [changes]);

    const hasElementChanged: (key: string) => boolean = useCallback(
        (formKey: string) => {
            return changes && Object.keys(changes).length && formKey in changes;
        },
        [changes]
    );

    const getElementChange: (key: string) => any = useCallback(
        (key: string) => {
            return changes && Object.keys(changes).length > 0 && changes[key];
        },
        [changes]
    );
    return {
        setElementChange,
        hasChanged,
        hasElementChanged,
        resetChanges,
        deleteChangeForElementIfPresent,
        getElementChange
    };
};
export default useDynamicFormManager;
