import { Depths, Dropdown, getTheme, Icon, IDropdownOption, IDropdownStyles, PrimaryButton, Stack, Text } from '@fluentui/react';
import React, { useState } from 'react';
import JumpDoodle from 'media/img/JumpDoodle.svg';
import { useClassNames } from '../../styles/responsiveness';
import StartSetupButton from 'components/StartSetupButton/StartSetupButton';
import { IInvitationOption } from './OrganisationInvitation.view';
import OrganisationInvitationListView from './OrganisationInvitationList.view';
import IvicosStrings from '../../kits/language/stringKit';
import { IvicosColors } from 'kits/colorKit';

const FindCampusView: React.FC<{
    isLoginDialogVisible: boolean;
    isSetupButtonVisible: boolean;
    selectedLoginOptionKey: string;
    loginOptions: IDropdownOption[];
    onLoginOptionSelected: (key: string | number) => void;
    onLoginButtonClick: () => void;
    invitationOptions: IInvitationOption[];
    optionsAreVisible: boolean;
    setOptionsAreVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    isSetupButtonVisible,
    isLoginDialogVisible,
    selectedLoginOptionKey,
    loginOptions,
    onLoginButtonClick,
    invitationOptions,
    onLoginOptionSelected,
    optionsAreVisible,
    setOptionsAreVisible
}) => {
    const LoginDropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 280 },
        callout: { maxHeight: 200, overflowY: 'scroll' }
    };

    const theme = getTheme();

    const { doodleWelcome, logIntoOrg, welcomePageStyle } = useClassNames();

    const [isOptionsTextUnderlined, setIsOptionsTextUnderlined] = useState<boolean>(false);

    return (
        <Stack>
            <Stack className={welcomePageStyle}>
                {invitationOptions.length > 0 ? (
                    <Stack
                        style={{
                            marginBottom: invitationOptions.length < 2 ? 50 : 15,
                            marginTop: isLoginDialogVisible ? 7 : 120
                        }}
                    >
                        <OrganisationInvitationListView invitationOptions={invitationOptions} />
                    </Stack>
                ) : (
                    <Stack style={{ height: 240 }}></Stack>
                )}
                <Stack tokens={{ childrenGap: 20 }} style={{ width: '100%' }} verticalAlign="space-between" horizontalAlign="center">
                    {isLoginDialogVisible && (
                        <Stack
                            horizontalAlign="start"
                            verticalAlign="center"
                            className={logIntoOrg}
                            style={{
                                background: theme.palette.white,
                                boxShadow: Depths.depth64
                            }}
                            tokens={{ childrenGap: 32 }}
                        >
                            <Stack horizontal tokens={{ childrenGap: 16 }}>
                                <Icon
                                    iconName="Lock"
                                    style={{
                                        fontSize: 24,
                                        color: theme.palette.themePrimary
                                    }}
                                />
                                <Text variant="xLarge">{IvicosStrings.findCampusPageTitle}</Text>
                            </Stack>
                            <Dropdown
                                label={IvicosStrings.findCampusPageLabel}
                                placeholder={IvicosStrings.findCampusPagePlaceholder}
                                data-test-id="selectCampus"
                                options={loginOptions}
                                selectedKey={selectedLoginOptionKey}
                                styles={LoginDropdownStyles}
                                onChange={(event, option) => option && onLoginOptionSelected(option.key)}
                            />
                            <Stack horizontal horizontalAlign="end" style={{ width: '100%' }}>
                                <PrimaryButton type="submit" text={IvicosStrings.login} onClick={onLoginButtonClick} />
                            </Stack>
                        </Stack>
                    )}
                    {(isLoginDialogVisible || invitationOptions.length > 0) && isSetupButtonVisible ? (
                        <Stack
                            data-test-id="CreateCampus"
                            onClick={() => setOptionsAreVisible(!optionsAreVisible)}
                            style={{ cursor: 'pointer' }}
                            horizontal={false}
                            horizontalAlign="center"
                            onMouseEnter={() => setIsOptionsTextUnderlined(true)}
                            onMouseLeave={() => setIsOptionsTextUnderlined(false)}
                        >
                            <PrimaryButton
                                style={{
                                    textDecoration: isOptionsTextUnderlined ? 'underline' : '',
                                    cursor: 'pointer',
                                    backgroundColor: 'transparent',
                                    border: 0,
                                    color: IvicosColors.Schwarz,
                                    fontWeight: 'lighter'
                                }}
                                text={optionsAreVisible ? IvicosStrings.hideOption : IvicosStrings.showOption}
                            />
                            {optionsAreVisible ? (
                                <Stack style={{ marginTop: 13 }}>
                                    <StartSetupButton />
                                </Stack>
                            ) : null}
                        </Stack>
                    ) : null}
                    {!isLoginDialogVisible && invitationOptions.length === 0 && isSetupButtonVisible && (
                        <Stack style={{ marginTop: '5%' }}>
                            <StartSetupButton />
                        </Stack>
                    )}
                </Stack>
            </Stack>
            <Stack>
                <img src={JumpDoodle} alt="logo" width={700} height={500} className={doodleWelcome} />
            </Stack>
        </Stack>
    );
};
export default FindCampusView;
