import { useRecoilState } from 'recoil';
import { aReceivedMessages } from 'shared-state/notifications/atoms';
import { sReceivedMessage } from 'shared-state/notifications/selectors';
import { IReceivedMessage } from 'shared-state/notifications/types';
import { v1 as uuidv1 } from 'uuid';

export const makeMessageId = (): any => {
    return uuidv1();
};

export const useReceivedMessages = (): any => {
    return useRecoilState(aReceivedMessages);
};

export const useReceivedMessage = (id: string): any => {
    return useRecoilState(sReceivedMessage(id));
};

export const useReceivedMessageInbox = (): [IReceivedMessage[], (msg: IReceivedMessage) => void] => {
    const [messages, setMessages] = useRecoilState(aReceivedMessages);

    const addMessage = (newMessage: IReceivedMessage) => {
        // console.log('Adding message... SILENT: ', newMessage);
        // console.log('Adding message... SILENT: ', newMessage.announce);
        if (newMessage.announce) {
            // console.log('Trying to play sound for: ', newMessage.type);
            // const soundName = ['knock', 'invite', 'cross-area-invite'].includes(newMessage.type) ? 'knock' : ['guest'].includes(newMessage.type) ? 'visitor-waiting' : 'memo';

            const soundFilePath = `${process.env.PUBLIC_URL}/sounds/notification.mp3`;
            // const soundFilePath = `${process.env.PUBLIC_URL}/sounds/${soundName}.mp3`;
            const notificationSound = new Audio(soundFilePath);

            notificationSound.preload = 'auto';
            notificationSound.play();
        }

        if (newMessage.timeout) {
            setTimeout(() => {
                setMessages((prevMsgs: IReceivedMessage[]) => prevMsgs.filter((msg) => msg.id != newMessage.id));
            }, newMessage.timeout);
        }

        if (['memo', 'cross-area-memo', 'confirmation', 'memo-at-visitor-entrance'].includes(newMessage.type)) {
            setMessages((prevMsgs) => [...prevMsgs, newMessage]);
        } else {
            if (!newMessage.identityValue) return;

            // Check if an object with similar identityValue exists
            const existingIndex = messages?.findIndex((message) => message.identityValue === newMessage.identityValue);

            if (existingIndex !== -1) {
                // Replace the existing message with the new one
                setMessages((prevMsgs) => {
                    const updatedMessages = [...prevMsgs];
                    updatedMessages[existingIndex] = newMessage;
                    return updatedMessages;
                });
            } else {
                // Push the new message to the array
                setMessages((prevMsgs) => [...prevMsgs, newMessage]);
            }
        }
    };

    return [messages, addMessage];
};
