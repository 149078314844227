import React from 'react';
import InvitationLogoView from './InvitationLogo.view';
import { OrgBrandingDto } from 'services/client-api-wrapper/identity_provider/responses.dto';

export interface IInvitationLogoProps {
    eventName: string | undefined;
    branding: OrgBrandingDto | undefined;
}

const InvitationLogo: React.FC<IInvitationLogoProps> = (props) => {
    const state = props;
    return (
        <InvitationLogoView
            {...{
                ...state
            }}
        ></InvitationLogoView>
    );
};
export default InvitationLogo;
