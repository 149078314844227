import { ITheme, ProgressIndicator, Stack } from '@fluentui/react';
import React, { ReactElement } from 'react';
import 'styles/helpers.css';
import { IC } from 'components/SVGIcon';
import { EuropeSecure } from 'kits/IconKit2';

export interface ICallLoadingScreenViewProps {
    theme: ITheme;
    _label: string;
    _description: string;
    label?: string;
    description?: string;
    onRenderIcon?: () => ReactElement;
}

const CallLoadingScreenView: React.FC<ICallLoadingScreenViewProps> = (props) => {
    const { onRenderIcon, theme, _label, _description } = props;

    const fluidStyle = { width: '100%', height: '100%' };

    const loadingContainerStyle = {
        width: '50%',
        backgroundColor: theme.palette.white,
        padding: '16px',
        borderRadius: 4
    };

    return (
        <Stack style={fluidStyle} verticalAlign="center" horizontalAlign="center">
            <Stack style={loadingContainerStyle} verticalAlign="center" horizontalAlign="center">
                {onRenderIcon ? (
                    onRenderIcon()
                ) : (
                    <Stack verticalAlign="center" horizontalAlign="center" className="brand-icon_wrap" style={{ marginBottom: 32 }}>
                        <IC variant="contrast" size={64}>
                            <EuropeSecure />
                        </IC>
                    </Stack>
                )}
                <Stack style={{ width: '100%' }}>
                    <ProgressIndicator label={_label} description={_description} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default CallLoadingScreenView;
