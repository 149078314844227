import React from 'react';
import CallSurfaceView from './CallSurface.view';

export interface ICallSurfaceProps {
    borderColor?: string;
    isHidden?: Boolean;
    onMouseMove?: (e: any) => void;
    children?: React.ReactNode;
}

const CallSurface: React.FC<ICallSurfaceProps> = (props) => {
    return (
        <CallSurfaceView
            {...{
                ...{
                    borderColor: props.borderColor,
                    isHidden: props.isHidden,
                    onMouseMove: props.onMouseMove
                }
            }}
        >
            {props.children}
        </CallSurfaceView>
    );
};
export default CallSurface;
