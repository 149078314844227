/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@fluentui/react';
import { useOrganizationBranding } from 'features/Header/hooks';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import { campusDTOtoResource } from 'kits/apiKit3/legacy';
import React from 'react';
import { Navigate } from 'react-router';
import SocketProvider from 'services/socket-connection/context/SocketProvider';
import SocketVisitorEntrance from 'services/socket-connection/socket-visitor-entrance';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useCurrentOrgId } from 'shared-state/location/hook';
import { IWaitingAreaState } from './WaitingArea.state';
import ReactPlayer from 'react-player';

const WaitingAreaResponsiveView: React.FC<IWaitingAreaState> = (props) => {
    const {
        headerActions,
        videntityClaims,
        campus,
        customUrl,
        invitationId,
        isTimerActive,
        setIsTimerActive,
        hourSeconds,
        timerProps,
        notifyHost,
        visitorName,
        eventName,
        customizedVideo,
        shouldNotifyHosts,
        hideWhenHostJoin,
        orgId,
        setIsButtonHovered,
        isButtonHovered,
        tokenPayload,
        welcomeMessage,
        isYoutubeVideoVisible,
        isVisitorWithHost
    } = props;

    const redirectVisitor = invitationId ? invitationId : customUrl;

    if (!localStorage.getItem('ivAccessToken')) return <Navigate to={'/i/' + redirectVisitor} />;

    const { mediumDevice } = useWindowWidth();
    const [branding] = useOrganizationBranding(orgId);

    return (
        <>
            <OutsideWrapper campus={campus && campusDTOtoResource(campus)} headerActions={headerActions}>
                <SocketProvider>
                    {(shouldNotifyHosts || isVisitorWithHost) && (
                        <SocketVisitorEntrance tokenPayload={tokenPayload} eventName={eventName} isVisitorWithHost={isVisitorWithHost} />
                    )}
                    <Stack
                        style={{
                            marginBottom: 10
                        }}
                    >
                        <ReactPlayer url={customizedVideo} controls={true} width={275} height={170} />
                    </Stack>
                </SocketProvider>
            </OutsideWrapper>
        </>
    );
};

export default WaitingAreaResponsiveView;
