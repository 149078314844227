import { IDropdownOption, PrimaryButton, Stack, Text } from '@fluentui/react';
import { idpService } from 'services/api/identity-provider.service';
import { UserJoinedOrgsDto, UserPendingInvitationsDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import IvicosStrings from '../../kits/language/stringKit';
import FindCampusView from './FindCampus.view';
import { IInvitationOption } from './OrganisationInvitation.view';
import { IC } from 'components/SVGIcon';
import { CampusLogo } from 'kits/IconKit2';
import { useWindowWidth } from 'shared-state/display-options/hooks';

type orgsReduced = {
    orgId: string;
    orgName: string;
    orgLogo: string;
    orgColors: string[];
    role: 'user' | 'manager';
};

const FindCampus: React.FC<{}> = () => {
    const history = useNavigate();
    const { smallDevice } = useWindowWidth();
    const [loginOptions, setLoginOptions] = useState<IDropdownOption[]>([]);
    const [optionsAreVisible, setOptionsAreVisible] = useState(false);
    const [invitationOptions, setInvitationOptions] = useState<IInvitationOption[]>([]);
    const [selectedOffice, setSelectedOffice] = useState<string>('');
    const [isLoginDialogVisible, setIsLoginDialogVisible] = useState(false);
    const [isSetupButtonVisible] = useState<boolean>(true);
    const [isUserCreatedCampusAlready, setIsUserCreatedCampusAlready] = useState<{ hasCreated: boolean; userCreatedCampusName: string }>({
        hasCreated: false,
        userCreatedCampusName: ''
    });
    const chosenLang = localStorage.getItem('chosenLanguage') || 'de';

    const OfficeList = async () => {
        let officeArr: UserPendingInvitationsDto = [];
        const response = await idpService.showUserPendingInvitations();
        if (response) {
            officeArr = response;
        }
        return officeArr;
    };

    const joinedOrgs = async () => {
        let orgsToDisplay: IDropdownOption[] = [];
        try {
            const response: UserJoinedOrgsDto = await idpService.showUserJoinedOrgs();

            if (response.length === 0) {
                setIsLoginDialogVisible(false);
            } else if (response.length > 0) {
                setIsLoginDialogVisible(true);
                const _response: orgsReduced[] = response; // this is to be able to map through it, due to a typescript issue: https://github.com/microsoft/TypeScript/issues/33591

                const orgs: IDropdownOption[] = _response.map((org: any) => ({
                    key: org.orgId,
                    text: org.orgName
                }));
                orgsToDisplay = orgs;
                setSelectedOffice(_response[0].orgId);
            }
        } catch (error) {
            const userInfo = await idpService.getUser();

            const joinedOrgsArr = userInfo.joinedOrgs;
            if (joinedOrgsArr?.length === 0) {
                setIsUserCreatedCampusAlready({
                    hasCreated: false,
                    userCreatedCampusName: ''
                });
            } else {
                const orgInfo = await idpService.getOrgBranding(joinedOrgsArr[0].orgId);
                setIsUserCreatedCampusAlready({
                    hasCreated: true,
                    userCreatedCampusName: orgInfo.orgName
                });
            }

            setSelectedOffice('0');
            setOptionsAreVisible(true);
        }

        return orgsToDisplay;
    };

    const pendingInvitations = async () => {
        try {
            const response: UserPendingInvitationsDto = await OfficeList();
            if (response.length > 0) {
                const _response: orgsReduced[] = response; // this is to be able to map through it, due to a typescript issue: https://github.com/microsoft/TypeScript/issues/33591
                setInvitationOptions(
                    _response.map((invitation) => ({
                        key: invitation.orgId,
                        color: invitation.orgColors[0],
                        text: invitation.orgName,
                        logo: invitation.orgLogo,
                        onAccept: (onSuccess, onError) => {
                            idpService
                                .userJoinOrg(invitation.orgId, invitation.role)
                                .then(() => {
                                    logInAndRedirect(invitation.orgId);
                                    onSuccess();
                                })
                                .catch(() => onError());
                        },
                        onDecline: () => {
                            idpService
                                .getUser()
                                .then((res) => {
                                    idpService.declineInvitationToJoinOrg(res.email, invitation.orgId);
                                    setTimeout(() => {
                                        fetchPendingInvitations();
                                    }, 500);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                    }))
                );
            }
        } catch (error) {
            history('/login');
        }
    };

    useEffect(() => {
        async function getJoinedOrgs() {
            const response = await joinedOrgs();
            setLoginOptions(response);
        }

        pendingInvitations();
        getJoinedOrgs();
    }, []);

    const fetchPendingInvitations = () => {
        pendingInvitations();
    };
    const logInAndRedirect = async (selectedOffice: string) => {
        try {
            await idpService.authUserToOrg(selectedOffice);
            window.location.href = '/dashboard/areas';
        } catch (err) {
            window.location.href = '/login';
        }
    };

    const onLoginButtonClick = () => {
        selectedOffice && logInAndRedirect(selectedOffice);
    };

    const onLoginOptionSelected = (key: string | number) => {
        typeof key == 'string' && setSelectedOffice(key);
    };

    return (
        <>
            {!selectedOffice ? (
                <Stack style={{ marginTop: 140 }}>
                    <LoadingDialog loadingStrings={[IvicosStrings.waitingForResponseLoadingString]} view="small" typewriter={false} />
                </Stack>
            ) : (
                <>
                    {!isUserCreatedCampusAlready.hasCreated ? (
                        <FindCampusView
                            selectedLoginOptionKey={selectedOffice}
                            {...{
                                isSetupButtonVisible,
                                isLoginDialogVisible,
                                onLoginOptionSelected,
                                onLoginButtonClick,
                                loginOptions,
                                invitationOptions,
                                optionsAreVisible,
                                setOptionsAreVisible
                            }}
                        />
                    ) : (
                        <Stack horizontalAlign="center" style={{ height: '100vh' }} aria-label="trial-period-over-warning">
                            <IC variant="custom" size={smallDevice ? 270 : 400} style={{ height: '100px', alignItems: 'start', marginTop: '150px' }}>
                                <CampusLogo />
                            </IC>
                            <Stack horizontalAlign="center" verticalAlign="center" style={{ marginTop: '150px' }}>
                                <Stack horizontal horizontalAlign="center" verticalAlign="center" style={{ gap: '15px' }}>
                                    <Text>{IvicosStrings.trialPeriodOverButConsider1}</Text>
                                    <Text
                                        onClick={() => window.open(`https://www.ivicos.eu/${chosenLang}/missed-benefits`, '_blank')}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {IvicosStrings.benefitsMissing}
                                    </Text>
                                </Stack>
                                <br />
                                <Stack style={{ gap: '15px' }}>
                                    <Text>{IvicosStrings.trialPeriodOverButConsider2}</Text>
                                    <PrimaryButton text={IvicosStrings.talkToSales} onClick={() => (window.location.href = 'mailto:sales@ivicos.eu')} />
                                </Stack>

                                <br />
                                <br />
                            </Stack>
                        </Stack>
                    )}
                </>
            )}
        </>
    );
};

export default FindCampus;
