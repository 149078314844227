import { DirectionalHint, mergeStyleSets } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useCallback, useState } from 'react';

interface IMultilineTextFieldState {
    isCalloutVisible: boolean;
    styles: any;
    toggleIsCalloutVisible: any;
    buttonId: string;
    directionalHint: any;
    onTextLengthChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    secondTextFieldValue: string;
    language: string;
}

export const useMultilineTextFieldState: (onChange: any, maxLength: number) => IMultilineTextFieldState = (onChange, maxLength) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [secondTextFieldValue, setSecondTextFieldValue] = useState('');
    const buttonId = useId('callout-button');
    const directionalHint = DirectionalHint.rightCenter;
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const onTextLengthChange = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue || newValue.length <= maxLength) {
            setSecondTextFieldValue(newValue || '');
            onChange(event, newValue);
        }
    }, []);

    const language = localStorage.getItem('chosenLanguage') || 'en';

    return {
        isCalloutVisible,
        styles,
        toggleIsCalloutVisible,
        buttonId,
        directionalHint,
        onTextLengthChange,
        secondTextFieldValue,
        language
    };
};
