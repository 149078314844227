import React from 'react';
import PersonalPreferencesView from './PersonalPreferences.View';
import { usePersonalPreferencesState } from './PersonalPreferences.State';

const PersonalPreferences: React.FC = () => {
    const state = usePersonalPreferencesState();

    return (
        <PersonalPreferencesView
            {...{
                ...state
            }}
        ></PersonalPreferencesView>
    );
};
export default PersonalPreferences;
