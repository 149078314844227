import * as React from 'react';

function SvgTalkingHead(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.025 6.813C7.593 2.555 4.754 0 .78 0 .51 0 .25.014 0 .04v14.927l3.818.305v-1.636c1.988.567 2.648-.294 2.648-.294l.843-3.123c1.134-.284 1.418-.851 1.418-.851S7.025 7.096 7.025 6.813zm1.843 6.835a2.48 2.48 0 00.721-1.546 2.547 2.547 0 00-.458-1.691l.649-.498c.504.618.783 1.425.763 2.236a3.505 3.505 0 01-.877 2.244l-.798-.745zm1.844.975a4.462 4.462 0 00.787-2.513 4.38 4.38 0 00-.754-2.48l.917-.59c.572.929.848 2.025.791 3.098a5.35 5.35 0 01-1.08 2.966l-.661-.481zm1.648.784a6.31 6.31 0 001.07-3.292 6.392 6.392 0 00-.832-3.4l.702-.418a7.275 7.275 0 011.085 3.835c0 1.352-.385 2.71-1.11 3.868l-.915-.593z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgTalkingHead;
