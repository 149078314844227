import React from 'react';
import { useAttachmentsPanelState } from './AttachmentPanel.state';
import AttachmentsPanelView from './AttachmentPanel.view';

const AttachmentsPanel: React.FC<{ jitsiAPI: any }> = (props) => {
    const state = useAttachmentsPanelState(props.jitsiAPI);

    return (
        <AttachmentsPanelView
            {...{
                ...state
            }}
        ></AttachmentsPanelView>
    );
};
export default AttachmentsPanel;
