import React from 'react';
import { useMultilineTextFieldState } from './MultilineTextField.state';
import MultilineTextFieldView from './MultilineTextField.view';

interface IChoiceGroupProps {
    label?: string;
    value?: string;
    onChange: any;
    callout?: string;
    resizable?: boolean;
    placeholder?: string;
    maxLength?: number;
}

const MultilineTextField: React.FC<IChoiceGroupProps> = (props) => {
    const state = useMultilineTextFieldState(props.onChange, props.maxLength || 99999999999999);

    return (
        <MultilineTextFieldView
            {...{
                ...state,
                ...{
                    label: props.label,
                    value: props.value,
                    callout: props.callout,
                    resizable: props.resizable,
                    placeholder: props.placeholder,
                    maxLength: props.maxLength
                }
            }}
        ></MultilineTextFieldView>
    );
};

export default MultilineTextField;
