import React from 'react';
import { Link, Stack, Text } from '@fluentui/react';
import { useNavigate } from 'react-router';
import confirmation from 'media/img/Confirmation.svg';
import IvicosStrings from '../kits/language/stringKit';
import { useLanguage } from '../components/context/LanguageContext';

const NewUserAwaitingConfirmation: React.FC<{}> = () => {
    const routeHistory = useNavigate();
    const { selectedLanguageOption }: any = useLanguage();

    return (
        <Stack>
            <Stack
                style={{
                    background: 'linear-gradient(150deg, #E4E3E4 41.98%, #E4E4E3 54.26%, rgba(255, 255, 255, 0.807062) 58.41%, #E4E4E3 62.45%, #E4E4E3 74.73%)',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100vh',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    style={{
                        height: selectedLanguageOption === 'en' ? 503 : 533,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                        justifyContent: 'center'
                    }}
                >
                    <Stack
                        style={{
                            margin: '69px 23px 69px 22px',
                            height: selectedLanguageOption === 'en' ? 373 : 443,
                            width: 330
                        }}
                    >
                        <Stack
                            style={{
                                width: 70,
                                height: 70,
                                border: '1px solid #ffcc00',
                                borderRadius: '50%',
                                backgroundColor: '#ffcc00',
                                alignSelf: 'center'
                            }}
                        >
                            <img
                                src={confirmation}
                                alt="awaiting-confirmation"
                                width={30}
                                height={30}
                                style={{
                                    alignSelf: 'center',
                                    marginTop: 19 // measured it and 19 suits as the perfect value to center it
                                }}
                            />
                        </Stack>

                        <Text
                            variant="xLarge"
                            style={{
                                marginTop: 33,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            {IvicosStrings.userConfirmationPageTitle}
                        </Text>
                        <Stack
                            style={{
                                textAlign: 'justify'
                            }}
                        >
                            <Text variant="medium" style={{ marginTop: 43 }}>
                                {IvicosStrings.userConfirmationPageDescription1}
                            </Text>
                            <Text variant="medium" style={{ marginTop: 43 }}>
                                {IvicosStrings.userConfirmationPageDescription2}
                                <br />
                                {IvicosStrings.userConfirmationPageDescription3}
                            </Text>
                            <Text variant="medium" style={{ marginTop: 43 }}>
                                {IvicosStrings.userConfirmationPageDescription4}
                                <br />
                                {IvicosStrings.userConfirmationPageDescription5}{' '}
                                <Link
                                    style={{ color: '#000', fontWeight: 700 }}
                                    onClick={() => {
                                        routeHistory('/login');
                                        sessionStorage.setItem('showEmail', 'true');
                                    }}
                                >
                                    {IvicosStrings.here}
                                </Link>{' '}
                                {IvicosStrings.userConfirmationPageDescription6}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default NewUserAwaitingConfirmation;
