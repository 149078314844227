import { PrimaryButton, Stack, Text, TooltipHost } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import SvgAreaOverview from 'kits/IconKit2/AreaOverview';
import Visitors from 'kits/IconKit2/Visitors';
import PersonSearch from 'kits/IconKit2/PersonSearch';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { SetterOrUpdater } from 'recoil';
import { useShowInvitationsManagerModal, useWindowWidth } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import 'styles/helpers.css';
import CrossAreaSearchPerson from './CrossAreaDirectMessaging/CrossAreaSearchPerson/CrossAreaSearchPerson';

export interface IAreaSwitchProps {
    selectedArea?: string;
    options: any;
    color?: string;
    setShowAreaPanel: SetterOrUpdater<boolean>;
    onClickOption: (e: any, k: string | null) => void;
    handleSearchPersonIconClick: (event: any) => void;
}
const AreaSwitchView: React.FC<IAreaSwitchProps> = ({ selectedArea, options, color, setShowAreaPanel, onClickOption, handleSearchPersonIconClick }) => {
    const BarDivider = () => {
        return <div style={{ margin: '16px 8px', background: 'rgba(255,255,255,0.3)', width: 1 }} />;
    };
    const [invitationsManagerModal, setInvitationsManagerModal] = useShowInvitationsManagerModal();
    const localProfile: any = useLocalProfile();
    const { smallDevice, mediumDevice } = useWindowWidth();

    return (
        <Stack horizontal style={{ height: '100%' }} grow>
            {selectedArea && selectedArea != 'null' ? (
                // navigator inside campus
                <>
                    <TooltipHost content={smallDevice ? '' : IvicosStrings.panelTitle}>
                        {/* <TooltipHost content={IvicosStrings.areaSwitchTitle}> */}
                        <PrimaryButton
                            aria-label="navigator-icon"
                            onClick={() => setShowAreaPanel(true)}
                            className="pressable--transparent"
                            styles={{ root: { color, height: '100%', padding: '12px 14px', minWidth: '52px' } }}
                        >
                            <IC variant={'custom'} iconColor={color} size={24}>
                                <SvgAreaOverview />
                            </IC>
                        </PrimaryButton>
                    </TooltipHost>
                </>
            ) : (
                localProfile?.type === 'user' && (
                    <>
                        <TooltipHost content={smallDevice ? '' : IvicosStrings.panelTitle}>
                            <PrimaryButton
                                aria-label="navigator-icon"
                                onClick={() => {
                                    setShowAreaPanel(true);
                                    window.location.href = '#';
                                }}
                                className="pressable--transparent"
                                styles={{ root: { color, height: '100%', padding: '12px 14px', minWidth: '52px' } }}
                            >
                                <IC variant={'custom'} size={24} iconColor={color}>
                                    <SvgAreaOverview />
                                </IC>
                            </PrimaryButton>
                        </TooltipHost>
                    </>
                )
            )}
            {localProfile?.type === 'user' && !window.location.pathname.includes('/meet/visitor-') ? (
                <>
                    <TooltipHost content={smallDevice ? '' : IvicosStrings.crossAreaSearchPersonIconTitle}>
                        <PrimaryButton
                            aria-label="search-person-button"
                            onClick={handleSearchPersonIconClick}
                            className="pressable--transparent"
                            styles={{ root: { color, height: '100%', padding: '12px 14px', minWidth: '52px' } }}
                        >
                            <IC variant={'custom'} size={24} iconColor={color}>
                                <PersonSearch />
                            </IC>
                        </PrimaryButton>
                    </TooltipHost>
                    <CrossAreaSearchPerson />
                </>
            ) : null}

            {localProfile?.type === 'user' && !smallDevice ? (
                <>
                    <TooltipHost content={smallDevice ? '' : IvicosStrings.invitationsManagerTitle}>
                        <PrimaryButton
                            aria-label="invitations-manager-button"
                            onClick={() => setInvitationsManagerModal(!invitationsManagerModal)}
                            className="pressable--transparent"
                            styles={{ root: { color, height: '100%', padding: '12px 14px', minWidth: '52px' } }}
                        >
                            <IC variant={'custom'} size={24} iconColor={color}>
                                <Visitors />
                            </IC>
                        </PrimaryButton>
                    </TooltipHost>
                </>
            ) : null}

            {!smallDevice &&
                options &&
                options.map((areaTab: any) => {
                    const isSelectedTab = areaTab.key == selectedArea;
                    const tabWidth = options.length > 4 ? Math.floor(90 / options.length) + '%' : '180px';
                    return (
                        <React.Fragment key={areaTab.key}>
                            {/* favourite areas */}
                            <BarDivider />
                            {!mediumDevice && (
                                <Stack
                                    horizontal
                                    className="padding--default pressable"
                                    style={{ width: tabWidth, backgroundColor: isSelectedTab ? 'rgba(0,0,0,0.3)' : undefined }}
                                    verticalAlign="center"
                                >
                                    <Stack
                                        grow
                                        onClick={(e) => !isSelectedTab && onClickOption(e, areaTab.key)}
                                        verticalAlign="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Text variant="medium" style={{ color: color ? color : 'white' }}>
                                            {areaTab.title}
                                        </Text>
                                    </Stack>
                                </Stack>
                            )}
                        </React.Fragment>
                    );
                })}
            {!smallDevice && <BarDivider />}
        </Stack>
    );
};

export default AreaSwitchView;
