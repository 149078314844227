import React from 'react';
import { Stack, Text } from '@fluentui/react';
import accountCreationSuccessful from 'media/img/AccountCreationSuccessful.svg';
import IvicosStrings from 'kits/language/stringKit';

const AccountCreationSuccessful: React.FC<{}> = () => {
    const email = sessionStorage.getItem('InvEmail');
    return (
        <Stack>
            <Stack
                style={{
                    background: 'linear-gradient(150deg, #E4E3E4 41.98%, #E4E4E3 54.26%, rgba(255, 255, 255, 0.807062) 58.41%, #E4E4E3 62.45%, #E4E4E3 74.73%)',
                    flexDirection: 'column',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    style={{
                        height: 443,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack
                        style={{
                            margin: '69px 33px 69px 35px',
                            height: 254,
                            width: 305
                        }}
                    >
                        <img
                            src={accountCreationSuccessful}
                            alt="account successfully created"
                            width={70}
                            height={70}
                            style={{
                                alignSelf: 'center',
                                marginTop: 19
                            }}
                        />

                        <Text
                            variant="xLarge"
                            style={{
                                marginTop: 51,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                wordBreak: 'break-word'
                            }}
                        >
                            {IvicosStrings.accountCreationSuccessfulTitle1}
                            {email}
                            {IvicosStrings.accountCreationSuccessfulTitle2}
                        </Text>
                        <Text variant="medium" style={{ marginTop: 31, alignItems: 'center' }}>
                            {IvicosStrings.accountCreationSuccessfulDescription}
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AccountCreationSuccessful;
