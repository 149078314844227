import { DefaultEffects, Stack, getTheme } from '@fluentui/react';
import React from 'react';
import { ICallSurfaceProps } from './CallSurface';

const theme = getTheme();
const CallSurfaceView: React.FC<ICallSurfaceProps> = ({ borderColor, children, onMouseMove, isHidden }) => {
    return (
        <Stack
            verticalAlign="stretch"
            grow
            style={{
                backgroundColor: 'transparent',
                display: isHidden ? 'none' : undefined,
                paddingRight: 16,
                paddingLeft: 16,
                paddingTop: 16,
                paddingBottom: borderColor ? 16 : undefined,
                // border: 'solid 2px red',
                height: '100%'
            }}
            onMouseMove={(e) => onMouseMove && onMouseMove(e)}
        >
            <Stack
                verticalAlign="stretch"
                grow
                style={{
                    overflow: 'hidden',
                    position: 'relative',
                    minHeight: '100%',
                    height: '100%',
                    borderRadius: '4px 4px 0px 0px',
                    border: borderColor && `4px ${borderColor} solid`,
                    boxShadow: DefaultEffects.elevation8,
                    backgroundColor: theme.palette.neutralDark
                }}
            >
                {children}
            </Stack>
        </Stack>
    );
};

export default CallSurfaceView;
