import { atom } from 'recoil';
import { IUpdatedVisitorsDeviceSettings } from 'routes/LandingPage/LandingPageSideCard';
import { IJitsiDeviceConfig, IJitsiDeviceList } from './DeviceSettingsAtVisitorEntrance';

export const aAvailableDevicesInDeviceSettings = atom<IJitsiDeviceList>({
    key: 'aAvailableDevicesInDeviceSettings',
    default: {
        audioInput: [],
        audioOutput: [],
        videoInput: []
    }
});
export const aCurrentDevicesInDeviceSettings = atom<IJitsiDeviceConfig>({
    key: 'aCurrentDevicesInDeviceSettings',
    default: {
        audioInput: {
            deviceId: '',
            label: '',
            groupId: '',
            kind: ''
        },
        audioOutput: {
            deviceId: '',
            label: '',
            groupId: '',
            kind: ''
        },
        videoInput: {
            deviceId: '',
            label: '',
            groupId: '',
            kind: ''
        }
    }
});

export const aUpdatedVisitorsDeviceSettings = atom<IUpdatedVisitorsDeviceSettings>({
    key: 'aUpdatedVisitorsDeviceSettings',
    default: {
        isVisitorUpdate: false,
        visitorDeviceType: 'audioInput',
        visitorDevice: {
            deviceId: '',
            label: '',
            groupId: '',
            kind: ''
        }
    }
});

export const aIsSetAudioDeviceSettingsClicked = atom<boolean>({
    key: 'aIsSetAudioDeviceSettingsClicked',
    default: false
});
