import * as React from 'react';

function SvgCheckboxEmpty(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M28 4H4v24h24V4zm-2 2H6v20h20V6z" fill="#000" />
        </svg>
    );
}

export default SvgCheckboxEmpty;
