import React from 'react';
import { Params, useParams } from 'react-router';
import MobileView from './Mobile.view';

export interface IMobileParams extends Params {
    jwt: string;
    room: string;
}

const Mobile: any = () => {
    const params = useParams<IMobileParams>();
    return <MobileView params={params} />;
};

export default Mobile;
