import { IPersonaSharedProps, IPersonaStyles, Link, Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import SvgGranted from 'media/img/Granted2';
import IvicosStrings from '../kits/language/stringKit';
import { getTextColorForBackground } from 'kits/colorKit';
import SVGIcon from 'components/SVGIcon2/SVGIcon2';

const UserAccessGranted: React.FC<{ email: string }> = ({ email }) => {
    const currentTime: number = Math.trunc(Date.now() / 1000);

    const userInfoToken = localStorage.getItem('ivAccessToken') && localStorage.getItem('ivAccessToken');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const decodeToken: any = jwt_decode(userInfoToken!);

    const examplePersona: IPersonaSharedProps = {
        imageUrl: decodeToken.profile_pic,
        primaryText: decodeToken.name,
        secondaryText: email
    };

    const personaStyles: Partial<IPersonaStyles> = {
        primaryText: {
            color: '#000',
            fontWeight: 700
        },
        secondaryText: {
            color: '#000',
            fontWeight: 600
        }
    };

    const redirectToWelcomePage = () => {
        if (localStorage.getItem('ivAccessToken')) {
            const token: any = localStorage.getItem('ivAccessToken');
            const decodeToken: any = jwt_decode(token);
            const expirationTime = decodeToken ? decodeToken.exp : 0;
            const userEmail = decodeToken ? decodeToken.email : '';

            if (expirationTime > currentTime && userEmail === email) {
                window.location.href = '/welcome';
                sessionStorage.setItem('showEmail', 'false');
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            redirectToWelcomePage();
        }, 5000);
    }, []);

    return (
        <Stack
            style={{
                background: 'linear-gradient(150deg, #E4E3E4 41.98%, #E4E4E3 54.26%, rgba(255, 255, 255, 0.807062) 58.41%, #E4E4E3 62.45%, #E4E4E3 74.73%)',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Stack
                style={{
                    height: 470,
                    width: 358,
                    backgroundColor: '#fff',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                }}
            >
                <Stack
                    style={{
                        height: 279,
                        width: 358,
                        backgroundColor: '#fff'
                    }}
                >
                    <Stack
                        style={{
                            padding: '5px 0 0 30px'
                        }}
                    >
                        <Text
                            variant="xxLarge"
                            style={{
                                color: '#000',
                                marginTop: 20,
                                fontWeight: 700
                            }}
                        >
                            {IvicosStrings.accessGrantedTitle}
                        </Text>
                        <Stack
                            aria-label="user-access-granted-image"
                            style={{
                                marginTop: 33,
                                marginBottom: 33
                            }}
                        >
                            <Persona {...examplePersona} size={PersonaSize.size48} styles={personaStyles} />
                        </Stack>
                    </Stack>
                </Stack>

                <Stack
                    style={{
                        height: 322,
                        width: 358,
                        backgroundColor: '#fff'
                    }}
                >
                    <Stack
                        style={{
                            margin: '43px 15px 35px 14px',
                            height: 239,
                            width: 329
                        }}
                    >
                        <Stack
                            style={{
                                width: 70,
                                height: 70,
                                borderRadius: '50%',
                                background: '#172D4D',
                                alignSelf: 'center'
                            }}
                        >
                            <SVGIcon
                                size={30}
                                iconColor={getTextColorForBackground('#172D4D')}
                                style={{
                                    alignSelf: 'center',
                                    marginTop: 19 // measured it and 19 suits as the perfect value to center it
                                }}
                            >
                                <SvgGranted />
                            </SVGIcon>
                        </Stack>
                        <Stack>
                            <Text
                                variant="medium"
                                style={{
                                    color: '#000',
                                    marginTop: 33
                                }}
                            >
                                {IvicosStrings.userAccessGrantedDescription1}
                                <b>{decodeToken.name}</b>
                                {IvicosStrings.userAccessGrantedDescription2}
                            </Text>

                            <Text
                                variant="medium"
                                style={{
                                    color: '#000',
                                    marginTop: 33
                                }}
                            >
                                {IvicosStrings.accessGrantedDescription2}{' '}
                                <Link
                                    style={{
                                        color: '#000',
                                        fontWeight: 700
                                    }}
                                    onClick={redirectToWelcomePage}
                                >
                                    {IvicosStrings.here}
                                </Link>{' '}
                                {IvicosStrings.userAccessGrantedDescription3}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default UserAccessGranted;
