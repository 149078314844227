import Check from './media/medium/check.svg';
import Invite from './media/medium/invite.svg';
import Wave from './media/medium/wave.svg';
import ID from './media/medium/id.svg';

const Medium = {
    Check,
    Invite,
    ID,
    Wave
};

export default Medium;
