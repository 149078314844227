import React from 'react';
import VisitorsInvalidInvitationView from './VisitorInvalidInvitation.view';
import { useVisitorsInvalidInvitationState } from './VisitorInvalidInvitation.state';

const VisitorsInvalidInvitation: React.FC<{ error: any }> = (props) => {
    const state = useVisitorsInvalidInvitationState(props.error);

    return <VisitorsInvalidInvitationView notActiveMessageLine={state.notActiveMessageLine}></VisitorsInvalidInvitationView>;
};
export default VisitorsInvalidInvitation;
