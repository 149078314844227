import { IPersonaProps } from '@fluentui/react';
import React from 'react';
import 'styles/helpers.css';
import { useCreateInvitationPanelState } from './CreateInvitationPanel.state';
import CreateInvitationPanelView from './CreateInvitationPanel.view';

export interface ICreateInvitationPanelProps {
    areaNamesList: {
        key: string;
        text: string;
    }[];
    members: IPersonaProps[] | undefined;
    panelIsOpen: boolean;
    dismissCreateInvitationPanel: () => void;
}

const CreateInvitationPanel: React.FC<ICreateInvitationPanelProps> = (props) => {
    const state = useCreateInvitationPanelState(props.areaNamesList, props.members, props.dismissCreateInvitationPanel);

    return (
        <CreateInvitationPanelView
            {...{
                ...state,
                ...{
                    areaNamesList: props.areaNamesList,
                    members: props.members,
                    dismissCreateInvitationPanel: props.dismissCreateInvitationPanel,
                    panelIsOpen: props.panelIsOpen
                }
            }}
        ></CreateInvitationPanelView>
    );
};

export default CreateInvitationPanel;
