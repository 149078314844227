import { useState } from 'react';

export interface IStatefulButtonState {
    buttonState: number;
    setButtonState: React.Dispatch<React.SetStateAction<number>>;
    onRenderText: () => string;
}

export const useStatefulButtonState: (text: string, loadingText?: string, successText?: string) => IStatefulButtonState = (text, loadingText, successText) => {
    const [buttonState, setButtonState] = useState<number>(0);

    const onRenderText = () => {
        if (buttonState == 1) return loadingText || 'Loading..';
        if (buttonState == 2) return successText || 'Success!';
        return text;
    };

    return {
        buttonState,
        setButtonState,
        onRenderText
    };
};
