import React from 'react';
import { Text, Stack, Image, Link } from '@fluentui/react';
import lonely from '../../media/img/lonely.svg';
import campusLogo from '../../media/img/campus-logo.svg';
import IvicosStrings from 'kits/language/stringKit';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const InsertedInvalidLink = (): JSX.Element => {
    const { smallDevice } = useWindowWidth();

    return (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { height: '100vh' } }}>
            <Stack aria-label="inserted-invalid-link-campus-logo">
                <Image src={campusLogo} alt="Campus logo"></Image>
            </Stack>
            <Stack
                aria-label="inserted-invalid-link-lonely-person"
                style={{
                    marginTop: 65
                }}
            >
                <Image src={lonely} alt="Lonely person"></Image>
            </Stack>

            <Stack
                style={{
                    marginTop: 65,
                    marginLeft: smallDevice ? 10 : 'auto',
                    marginRight: 'auto'
                }}
            >
                <Text variant={'large'}>{IvicosStrings.insertedLinkIncorrect}</Text>
                <Text
                    variant={'medium'}
                    style={{
                        marginTop: 21
                    }}
                >
                    {IvicosStrings.verifyAccuracyOfInvitationLink}
                </Text>
                <Text variant={'medium'}>
                    {IvicosStrings.invalidInvitationProblemPersists}
                    <Link
                        style={{ color: '#000', fontWeight: 700, textDecoration: 'underline' }}
                        href={'mailto:support@ivicos.eu?subject=Invitation%20link%20is%20not%20working'}
                        target="_blank"
                        data-test-id="send-email-to-support"
                    >
                        {IvicosStrings.supportServiceHere}
                    </Link>
                    .
                </Text>
            </Stack>
        </Stack>
    );
};

export default InsertedInvalidLink;
