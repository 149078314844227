import React from 'react';
import { useCopyButtonState } from './CopyButton.state';
import CopyButtonAltView from './CopyButtonAltView.view';
import CopyButtonView from './CopyButtonView';

export interface ICopyButtonProps {
    value: string;
    type: string;
    ariaLabel: string;
}

const CopyButton: React.FC<ICopyButtonProps> = (props) => {
    const state = useCopyButtonState(props.value);

    return (
        <>
            {props.type !== 'alternative-view' ? (
                <CopyButtonView
                    {...{
                        ...state
                    }}
                ></CopyButtonView>
            ) : (
                <CopyButtonAltView
                    {...{
                        ...state,
                        ...{ ariaLabel: props.ariaLabel }
                    }}
                ></CopyButtonAltView>
            )}
        </>
    );
};
export default CopyButton;
