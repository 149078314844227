import { DefaultButton, ITextFieldStyles, IconButton, Label, Stack, Text, TextField, TooltipHost } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { YoutubeLogo } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IVisitorEntranceVideoPlayerViewProps {
    label: string;
    videoUrl?: string;
    providedButtonText: string;
    instructionToSetVideo: string;
    visitorEntranceVideoUrl?: string;
    showChangeVisitorEntranceVideoOption: boolean;
    changeOrSelectVideo: (providedText: string) => void;
    changeVideoUrl: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    deleteCurrentVideo: () => void;
    backgroundColor: string;
    saveUploadedVideo: (url: string) => void;
    isSaveButtonDisabled: boolean;
}

const VisitorEntranceVideoPlayerView: React.FC<IVisitorEntranceVideoPlayerViewProps> = ({
    label,
    videoUrl,
    providedButtonText,
    instructionToSetVideo,
    visitorEntranceVideoUrl,
    showChangeVisitorEntranceVideoOption,
    changeOrSelectVideo,
    changeVideoUrl,
    deleteCurrentVideo,
    backgroundColor,
    saveUploadedVideo,
    isSaveButtonDisabled
}) => {
    const textFieldStyles: Partial<ITextFieldStyles> = {
        fieldGroup: { width: 404, height: 35, border: '1px solid black', padding: '8px 9px', marginBottom: 30 }
    };

    const chosenLanguage = localStorage.getItem('chosenLanguage');

    const { smallDevice } = useWindowWidth();

    return (
        <>
            {label && <Label>{label}</Label>}
            <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 32 }}
                style={{
                    marginBottom: 15
                }}
            >
                {visitorEntranceVideoUrl !== '' ? (
                    <Stack>
                        <ReactPlayer url={visitorEntranceVideoUrl || videoUrl} controls={true} width={206} height={130} />
                    </Stack>
                ) : (
                    <Stack
                        style={{
                            width: instructionToSetVideo === IvicosStrings.pasteYTVideo ? 206 : 225,
                            height: 130,
                            backgroundColor: backgroundColor
                        }}
                        horizontal
                        horizontalAlign="center"
                    >
                        <IC variant={'custom'} size={24}>
                            <YoutubeLogo />
                        </IC>
                    </Stack>
                )}

                {showChangeVisitorEntranceVideoOption ? (
                    <Stack
                        style={{
                            height: 130
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: 700,
                                marginBottom: 10
                            }}
                        >
                            {instructionToSetVideo}
                        </Text>
                        <TextField
                            value={visitorEntranceVideoUrl}
                            onChange={(event, newValue) => typeof newValue == 'string' && changeVideoUrl(event, newValue)}
                            styles={textFieldStyles}
                            data-test-id="visitor-entrance-video-url-input-filed" // does not detect aria-label
                        />
                        <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 30 }}>
                            <TooltipHost content={smallDevice ? '' : IvicosStrings.saveActionTitle}>
                                <IconButton
                                    iconProps={{
                                        iconName: 'CheckMark',
                                        styles: {
                                            root: {
                                                fontSize: '24px'
                                            }
                                        }
                                    }}
                                    disabled={visitorEntranceVideoUrl === '' || isSaveButtonDisabled}
                                    onClick={() => saveUploadedVideo(visitorEntranceVideoUrl || '')}
                                    aria-label="visitor-entrance-video-url-check"
                                />
                            </TooltipHost>
                            <TooltipHost content={smallDevice ? '' : IvicosStrings.deleteActionTitle}>
                                <IconButton
                                    iconProps={{
                                        iconName: 'Delete',
                                        styles: {
                                            root: {
                                                fontSize: '20px'
                                            }
                                        }
                                    }}
                                    onClick={deleteCurrentVideo}
                                    aria-label="visitor-entrance-video-url-delete"
                                />
                            </TooltipHost>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack
                        style={{
                            width: 450
                        }}
                    >
                        {instructionToSetVideo && (
                            <Text style={{ marginLeft: 'auto', width: '100%', padding: '0 5px', marginBottom: chosenLanguage === 'de' ? 42 : 22 }}>
                                {instructionToSetVideo}
                            </Text>
                        )}

                        <Stack style={{ flexDirection: 'column', marginLeft: 'auto', width: chosenLanguage === 'de' ? '42%' : '40%', marginRight: 0 }}>
                            <DefaultButton onClick={() => changeOrSelectVideo(providedButtonText)}>
                                <IC variant={'custom'} size={24} iconColor="#000">
                                    <YoutubeLogo height={20} width={20} />
                                </IC>
                                <Text style={{ marginLeft: 5, fontWeight: 700 }}>{providedButtonText}</Text>
                            </DefaultButton>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default VisitorEntranceVideoPlayerView;
