import { atom } from 'recoil';

export const aNotification = atom<any>({ key: 'aNotification', default: true });
export const aHideNotification = atom<boolean>({ key: 'aHideNotification', default: true });
export const aNotificationSenderId = atom<string>({ key: 'aNotificationSenderId', default: '' });
export const aCloseNotificationBanner = atom<string[]>({ key: 'aCloseNotificationBanner', default: [] });
export const aCloseNotificationBannerIds = atom<string[]>({ key: 'aCloseNotificationBannerIds', default: [] });
export const aIdsOfOtherInvitesExceptLast = atom<string[]>({ key: 'aIdsOfOtherInvitesExceptLast', default: [] });
export const aIdsOfOtherKnocksExceptLast = atom<string[]>({ key: 'aIdsOfOtherKnocksExceptLast', default: [] });
export const aExpandNotification = atom<boolean>({ key: 'aExpandNotification', default: false });
export const aShowViewAllOfNotificationsOnly = atom<boolean>({ key: 'aShowViewAllOfNotificationsOnly', default: false });
export const aControlValue = atom<boolean>({ key: 'aControlValue', default: false });
export const aDismissOverflow = atom<boolean>({ key: 'aDismissOverflow', default: false });
export const aShowViewAll = atom<boolean>({ key: 'aShowViewAll', default: true });
export const aShowSingleMessageNotification = atom<boolean>({ key: 'aShowSingleMessageNotification', default: true });
export const aIsUserSwitchedArea = atom<any>({ key: 'aIsUserSwitchedArea', default: {} });
export const aIsUserOutOfCampus = atom<any>({ key: 'aIsUserOutOfCampus', default: {} });
