import HelpModal from 'features/Header/children/HelpModal/HelpModal';
import TopBar from 'features/Header/children/TopBar/TopBar';
import IdentityPanel from 'features/IdentityPanel/IdentityPanel';
import React, { ReactElement } from 'react';

interface IHeaderProps {
    onRenderDocumentPicker?: (textColor?: string) => ReactElement;
}
const Header: React.FC<IHeaderProps> = ({ onRenderDocumentPicker }) => {
    return (
        <>
            <TopBar>{(textColor: string) => onRenderDocumentPicker && onRenderDocumentPicker(textColor)}</TopBar>
            <HelpModal />
            <IdentityPanel />
        </>
    );
};
export default Header;
