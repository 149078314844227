import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useState } from 'react';
import { campusMediaService } from 'services/api/campus-media.service';

export interface IImagePickerImage {
    id: string;
    url: string;
}

export interface IImagePickerState {
    fetchedImages: IImagePickerImage[];
    modalIsOpen: boolean;
    onModalOpen: () => void;
    selectedImage: IImagePickerImage;
    _onImageSelect: (img: any) => void;
    onDismiss: () => void;
    onImageUploadToLocalStorage: (f: any) => Promise<void>;
    onDiscardImage: (f: string) => void;
}

export const useImagePickerState: (onChange: Function, imageURL: string | undefined) => IImagePickerState = (onChange, imageURL) => {
    const [selectedImageId, setSelectedImageId] = useState<string>('');

    const [fetchedImages, refetchImages] = useMediaImages();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const onDismiss = () => {
        setModalIsOpen(false);
    };

    const uploadMediaFile = async (file: File) => {
        const uploadImage = await campusMediaService.uploadImage(file);
        return uploadImage;
    };

    const _onImageSelect = (img: any) => {
        const imageHasChanged = img.url !== undefined && img.url != imageURL;
        if (imageHasChanged) {
            // setSelectedImageUrl(img.url);
            setSelectedImageId(img.id);
            onChange(img.url);
        }
    };

    const onImageUploadToLocalStorage = async (f: any) => {
        const file = f[0];
        if (typeof file == 'undefined') return;
        const r = await uploadMediaFile(file);
        if (!r) return;
        const nextImg = { id: r.key, url: r.image_url };
        _onImageSelect(nextImg);
    };

    const onDiscardImage = async (key: string) => {
        const isSure = confirm(IvicosStrings.deleteImageDescription);
        if (!isSure) return;
        await campusMediaService.deleteImage(key);
        refetchImages();
    };

    const onModalOpen = () => {
        setModalIsOpen(true);
    };

    return {
        fetchedImages,
        modalIsOpen,
        onModalOpen,
        selectedImage: { id: selectedImageId, url: imageURL ? imageURL : '' },
        _onImageSelect,
        onDismiss,
        onImageUploadToLocalStorage,
        onDiscardImage
    };
};

export const useMediaImages = (): [IImagePickerImage[], Function] => {
    const [images, setImages] = useState<IImagePickerImage[]>([]);

    const refetch = async () => {
        const result = await campusMediaService.getAllUserImages();
        const images: any[] = result.images;
        const fetchedImages: IImagePickerImage[] = images.map((img: any) => ({ id: img.key, url: img.image_url }));

        setImages(fetchedImages);
    };

    useEffect(() => {
        refetch();
    }, []);

    return [images, refetch];
};
