import * as React from 'react';

function SvgThreeDots(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 10 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={5} cy={39} r={5} transform="rotate(-90 5 39)" fill="#436898" />
            <circle cx={5} cy={22} r={5} transform="rotate(-90 5 22)" fill="#436898" />
            <circle cx={5} cy={5} r={5} transform="rotate(-90 5 5)" fill="#436898" />
        </svg>
    );
}

export default SvgThreeDots;
