import { getTheme } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import { useContext, useState } from 'react';
import { useDirectControlNameWidthInMemo, useIsMemoOpenOnCrossAreaMessagePanel } from 'shared-state/display-options/hooks';
import { useDirectMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { useCrossAreaUsersWithIDs } from 'shared-state/presence/hooks';
import SocketContext from 'services/socket-connection/context/SocketContext';
import { CLIENT_EMIT_MESSAGE_EVENT } from 'services/socket-connection/events';
import { IDirectMemoComposerState } from '../../../dataTypes';

export const useDirectMemoComposerState: () => IDirectMemoComposerState = () => {
    const [userIds, setUserIds] = useDirectMessageComposerUserIds();
    const users = useCrossAreaUsersWithIDs(userIds);
    const theme = getTheme();
    const [giphyPanelIsOpen, setGiphyPanelIsOpen] = useState<boolean>(false);
    const [, addMessage] = useReceivedMessageInbox();
    const [memoBody, setMemoBody] = useState<string>('');
    const [mode, setMode] = useState<'markdown' | 'preview'>('markdown');
    const [, setDirectControlNameWidthInMemo] = useDirectControlNameWidthInMemo();
    const [isMemoOpenOnCrossAreaMessagePanel, setIsMemoOpenOnCrossAreaMessagePanel] = useIsMemoOpenOnCrossAreaMessagePanel();
    const {
        SocketState: { socket }
    } = useContext(SocketContext);
    const cancel = () => {
        setMemoBody('');
        setMode('markdown');
        setUserIds([]);
        setDirectControlNameWidthInMemo(false);
        isMemoOpenOnCrossAreaMessagePanel && setIsMemoOpenOnCrossAreaMessagePanel(false);
    };

    const send = () => {
        if (memoBody.length > 0) {
            userIds.forEach((userId: any) => {
                socket && socket.emit(CLIENT_EMIT_MESSAGE_EVENT, { type: 'cross-area-memo', meta: { forUser: userId }, body: memoBody, announce: true });
            });

            addMessage({
                id: makeMessageId(),
                type: 'confirmation',
                body: IvicosStrings.memoSentToNotificationActionTitle + users.map((user: any) => user.displayName).join(', ') + '.',
                timeout: 2500,
                meta: {},
                sender: 'system',
                timestamp: new Date()
            });
            cancel();
        }
    };

    return {
        userIds,
        users,
        theme,
        giphyPanelIsOpen,
        setGiphyPanelIsOpen,
        memoBody,
        setMemoBody,
        mode,
        setMode,
        cancel,
        send
    };
};
