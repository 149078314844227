import React from 'react';
import { useDirectMessagingActions } from './Actions';
import { IconButton, TooltipHost } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { ICrossUserProfile } from '../../dataTypes';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const UserActions: React.FC<{ user: ICrossUserProfile }> = ({ user }) => {
    const actions = useDirectMessagingActions(user);
    const { smallDevice } = useWindowWidth();
    return (
        <>
            {actions?.map((action) => (
                <TooltipHost key={action.key} content={smallDevice ? '' : action.title}>
                    <IconButton
                        onClick={() => action.action()}
                        onRenderIcon={() => (
                            <IC variant="dark" size={16}>
                                {action.onRenderIcon && action.onRenderIcon()}
                            </IC>
                        )}
                    />
                </TooltipHost>
            ))}
        </>
    );
};

export default UserActions;
