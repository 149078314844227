import * as React from 'react';

function SvgInfoBubble(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5 5v22h7.563l2.718 2.719.719.687.719-.687L19.437 27H27V5H5zm2 2h18v18h-6.406l-.313.281L16 27.563l-2.281-2.282-.313-.281H7V7zm8 3v2h2v-2h-2zm0 4v8h2v-8h-2z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgInfoBubble;
