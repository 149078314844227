import * as React from 'react';

function SvgEdgeLogo(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M27.872 22.84c-.4.21-.812.394-1.235.552-1.345.504-2.77.76-4.207.758-5.545 0-10.375-3.815-10.375-8.71a3.689 3.689 0 011.925-3.2c-5.015.212-6.304 5.438-6.304 8.5 0 8.657 7.979 9.534 9.698 9.534.927 0 2.325-.27 3.164-.534l.153-.051a15.04 15.04 0 007.804-6.188.469.469 0 00-.623-.66z"
                fill="url(#edge-logo_svg__paint0_linear_18_537)"
            />
            <path
                opacity={0.35}
                d="M27.872 22.84c-.4.21-.812.394-1.235.552-1.345.504-2.77.76-4.207.758-5.545 0-10.375-3.815-10.375-8.71a3.689 3.689 0 011.925-3.2c-5.015.212-6.304 5.438-6.304 8.5 0 8.657 7.979 9.534 9.698 9.534.927 0 2.325-.27 3.164-.534l.153-.051a15.04 15.04 0 007.804-6.188.469.469 0 00-.623-.66z"
                fill="url(#edge-logo_svg__paint1_radial_18_537)"
            />
            <path
                d="M13.185 28.803a9.28 9.28 0 01-2.665-2.5 9.458 9.458 0 013.46-14.062c.366-.172.99-.484 1.821-.469a3.79 3.79 0 013.01 1.524c.474.631.735 1.397.746 2.186 0-.024 2.866-9.327-9.375-9.327-5.144 0-9.374 4.881-9.374 9.164a15.25 15.25 0 001.419 6.562 14.999 14.999 0 0018.325 7.864 8.854 8.854 0 01-7.357-.937l-.01-.005z"
                fill="url(#edge-logo_svg__paint2_linear_18_537)"
            />
            <path
                opacity={0.41}
                d="M13.185 28.803a9.28 9.28 0 01-2.665-2.5 9.458 9.458 0 013.46-14.062c.366-.172.99-.484 1.821-.469a3.79 3.79 0 013.01 1.524c.474.631.735 1.397.746 2.186 0-.024 2.866-9.327-9.375-9.327-5.144 0-9.374 4.881-9.374 9.164a15.25 15.25 0 001.419 6.562 14.999 14.999 0 0018.325 7.864 8.854 8.854 0 01-7.357-.937l-.01-.005z"
                fill="url(#edge-logo_svg__paint3_radial_18_537)"
            />
            <path
                d="M18.645 17.958c-.095.123-.386.293-.386.663 0 .306.199.6.553.847 1.685 1.172 4.861 1.017 4.87 1.017a6.98 6.98 0 003.547-.978 7.192 7.192 0 003.566-6.197c.03-2.626-.938-4.371-1.33-5.145-2.482-4.857-7.842-7.65-13.67-7.65a15 15 0 00-15 14.788c.056-4.282 4.312-7.74 9.375-7.74.41 0 2.749.04 4.921 1.18 1.915 1.005 2.918 2.22 3.615 3.423.724 1.25.853 2.83.853 3.459s-.32 1.562-.914 2.333z"
                fill="url(#edge-logo_svg__paint4_radial_18_537)"
            />
            <path
                d="M18.645 17.958c-.095.123-.386.293-.386.663 0 .306.199.6.553.847 1.685 1.172 4.861 1.017 4.87 1.017a6.98 6.98 0 003.547-.978 7.192 7.192 0 003.566-6.197c.03-2.626-.938-4.371-1.33-5.145-2.482-4.857-7.842-7.65-13.67-7.65a15 15 0 00-15 14.788c.056-4.282 4.312-7.74 9.375-7.74.41 0 2.749.04 4.921 1.18 1.915 1.005 2.918 2.22 3.615 3.423.724 1.25.853 2.83.853 3.459s-.32 1.562-.914 2.333z"
                fill="url(#edge-logo_svg__paint5_radial_18_537)"
            />
            <defs>
                <radialGradient
                    id="edge-logo_svg__paint1_radial_18_537"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(11.1766 0 0 10.6176 19.218 21.425)"
                >
                    <stop offset={0.72} stopOpacity={0} />
                    <stop offset={0.95} stopOpacity={0.53} />
                    <stop offset={1} />
                </radialGradient>
                <radialGradient
                    id="edge-logo_svg__paint3_radial_18_537"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-81.384 18.392 6.644) scale(16.8063 13.5781)"
                >
                    <stop offset={0.76} stopOpacity={0} />
                    <stop offset={0.95} stopOpacity={0.5} />
                    <stop offset={1} />
                </radialGradient>
                <radialGradient
                    id="edge-logo_svg__paint4_radial_18_537"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(92.291 -1.001 4.871) scale(23.7392 50.5607)"
                >
                    <stop stopColor="#35C1F1" />
                    <stop offset={0.11} stopColor="#34C1ED" />
                    <stop offset={0.23} stopColor="#2FC2DF" />
                    <stop offset={0.31} stopColor="#2BC3D2" />
                    <stop offset={0.67} stopColor="#36C752" />
                </radialGradient>
                <radialGradient
                    id="edge-logo_svg__paint5_radial_18_537"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(3.19376 10.94984 -8.90456 2.59721 28.927 9.585)"
                >
                    <stop stopColor="#66EB6E" />
                    <stop offset={1} stopColor="#66EB6E" stopOpacity={0} />
                </radialGradient>
                <linearGradient id="edge-logo_svg__paint0_linear_18_537" x1={7.676} y1={21.26} x2={28.574} y2={21.26} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0C59A4" />
                    <stop offset={1} stopColor="#114A8B" />
                </linearGradient>
                <linearGradient id="edge-logo_svg__paint2_linear_18_537" x1={18.693} y1={12.195} x2={5.641} y2={26.413} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1B9DE2" />
                    <stop offset={0.16} stopColor="#1595DF" />
                    <stop offset={0.67} stopColor="#0680D7" />
                    <stop offset={1} stopColor="#0078D4" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default SvgEdgeLogo;
