import React from 'react';
import LoadingDialogSmallView from './LoadingDialogSmall.view';
import LoadingDialogFullView from './LoadingDialogFull.view';

const LoadingDialog: React.FC<{ loadingStrings: string | string[]; view: 'full' | 'small'; loop?: true | false; typewriter: boolean }> = ({
    loadingStrings,
    view,
    loop,
    typewriter
}) => {
    const loadingDialogViewHandler = (view: string) => {
        switch (view) {
            case 'full':
                return <LoadingDialogFullView loadingStrings={loadingStrings} loop={loop} typewriter={typewriter} />;
            case 'small':
                return <LoadingDialogSmallView loadingStrings={loadingStrings} loop={loop} typewriter={typewriter} />;
        }
    };
    return <>{loadingDialogViewHandler(view)}</>;
};

export default LoadingDialog;
