import React from 'react';
import { useUserProfileState } from './UserProfile.State';
import UserProfileView from './UserProfile.View';

const UserProfile: React.FC = () => {
    const state = useUserProfileState();

    return (
        <UserProfileView
            {...{
                ...state,
                ...{
                    localProfile: state.localProfile,
                    identityClaims: state.identityClaims,
                    sharedAvailability: state.sharedAvailability,
                    showProfileImageEditingPanel: state.showProfileImageEditingPanel,
                    setShowProfileImageEditingPane: state.setShowProfileImageEditingPanel
                }
            }}
        ></UserProfileView>
    );
};
export default UserProfile;
