import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgLeaveCampus(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5.626 0a1.458 1.458 0 100 2.917 1.458 1.458 0 000-2.917zM4.458 3.5c-.231 0-.446.06-.64.155a.584.584 0 00-.087.034L1.448 4.783a.583.583 0 00-.28.285L.135 7.342a.583.583 0 101.062.482l.845-1.86.754-.226-.351 1.845.001.003a1.448 1.448 0 00.499 1.404l.005.013 2.27 1.789.65 2.45.058.289a.583.583 0 101.142-.23h.002l-.584-2.916a.584.584 0 00-.083-.2L5.304 8.168c.001-.005 0-.01.002-.016l.573-2.867h-.002c.025-.106.04-.215.04-.328A1.46 1.46 0 004.457 3.5zm8.717 1.463a.437.437 0 00-.438.437v.73H8.066a.583.583 0 100 1.166H12.737v.73a.437.437 0 00.746.309l1.313-1.313a.436.436 0 000-.618l-1.312-1.312a.435.435 0 00-.31-.13zM2.392 9.756l-.3.989-1.3 2.3-.032.056-.01.016a.583.583 0 00.972.641h.001a.83.83 0 00.024-.035l1.927-2.925-1.282-1.042z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgLeaveCampus;
