import React from 'react';
import DraggableItemView from './DraggableItem.view';

export interface IDraggableItemProps {
    draggableId: string;
    index: number;
    children?: React.ReactNode;
}

const DraggableItem: React.FC<IDraggableItemProps> = (props) => {
    return (
        <DraggableItemView
            {...{
                draggableId: props.draggableId,
                index: props.index
            }}
        >
            {props.children}
        </DraggableItemView>
    );
};
export default DraggableItem;
