import React from 'react';
import { useVisitorEntranceVideoPlayerState } from './VisitorEntranceVideoPlayer.state';
import VisitorEntranceVideoPlayerView from './VisitorEntranceVideoPlayer.view';

export interface IVisitorEntranceVideoPlayerProps {
    label?: string;
    videoUrl?: string;
    onUrlChange: Function;
    view?: string;
    buttonText?: string;
}

const VisitorEntranceVideoPlayer: React.FC<IVisitorEntranceVideoPlayerProps> = (props) => {
    const state = useVisitorEntranceVideoPlayerState(props.onUrlChange, props.videoUrl, props.buttonText);

    return (
        <VisitorEntranceVideoPlayerView
            {...{
                ...state,
                ...{
                    label: props.label || ''
                }
            }}
        ></VisitorEntranceVideoPlayerView>
    );
};
export default VisitorEntranceVideoPlayer;
