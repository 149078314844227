import { Panel, PanelType } from '@fluentui/react';
import React from 'react';
import { CrossAreaSearch } from './CrossAreaSearch';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { ICrossAreaSearchPersonProps } from '../dataTypes';
import IvicosStrings from 'kits/language/stringKit';

const CrossAreaSearchPersonView: React.FC<ICrossAreaSearchPersonProps> = ({
    inputRef,
    crossAreaSearchBarInputValue,
    setCrossAreaSearchBarInputValue,
    isCrossAreaUsersVisible,
    handleInputChange,
    showSearchBar,
    orgColor,
    showCrossAreaMessagePanel,
    closeCrossAreaMessagePanel
}) => {
    const { smallDevice, mediumDevice } = useWindowWidth();
    const inputStyle =
        smallDevice || mediumDevice
            ? {
                  margin: '25px 50px 3px 0px',
                  fontSize: '14px',
                  padding: '8px 5px',
                  height: '20px',
                  width: '96%',
                  outline: 'none'
              }
            : {
                  margin: showSearchBar ? '5px 18px 3px' : '5px auto 3px auto',
                  fontSize: '14px',
                  padding: showSearchBar ? '8px 5px' : '8px 0px',
                  height: '20px',
                  width: showSearchBar ? '350px' : '0',
                  opacity: showSearchBar ? '1' : '0',
                  border: !showSearchBar && 'none',
                  outline: 'none',
                  transition: 'width 0.3s ease-out, opacity 0.3s ease-out'
              };

    return (
        <>
            {smallDevice || mediumDevice ? (
                <Panel
                    headerText={IvicosStrings.crossAreaSearchPersonPanelHeaderTitle}
                    isOpen={showCrossAreaMessagePanel}
                    type={PanelType.customNear}
                    onDismiss={closeCrossAreaMessagePanel}
                    customWidth={mediumDevice ? '420px' : '100%'}
                    closeButtonAriaLabel={IvicosStrings.crossAreaSearchPersonPanelCloseButton}
                >
                    <CrossAreaSearch
                        inputRef={inputRef}
                        crossAreaSearchBarInputValue={crossAreaSearchBarInputValue}
                        setCrossAreaSearchBarInputValue={setCrossAreaSearchBarInputValue}
                        handleInputChange={handleInputChange}
                        inputStyle={inputStyle}
                        isCrossAreaUsersVisible={isCrossAreaUsersVisible}
                        orgColor={orgColor}
                        mobileView={true}
                    />
                </Panel>
            ) : (
                <CrossAreaSearch
                    inputRef={inputRef}
                    crossAreaSearchBarInputValue={crossAreaSearchBarInputValue}
                    setCrossAreaSearchBarInputValue={setCrossAreaSearchBarInputValue}
                    handleInputChange={handleInputChange}
                    inputStyle={inputStyle}
                    isCrossAreaUsersVisible={isCrossAreaUsersVisible}
                    orgColor={orgColor}
                    mobileView={false}
                />
            )}
        </>
    );
};
export default CrossAreaSearchPersonView;
