import * as React from 'react';

function SvgCopyFull(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.98 0v2.52l5.88 5.88v9.24H21V5.88h-5.88V0H7.98zm7.98.25v4.79h4.79L15.96.25zM0 3.36V21h13.02V9.24H7.14V3.36H0zm7.98.25V8.4h4.79L7.98 3.61z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgCopyFull;
