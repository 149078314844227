import { Steps } from 'intro.js-react';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';

const RoomListIntro: React.FC = () => {
    const [stepsEnabled, setStepsEnabled] = useState<boolean>();

    useEffect(() => {
        if (localStorage.getItem('intro-office') === 'false') {
            setStepsEnabled(false);
        } else if (localStorage.getItem('intro-office') === 'true') {
            setStepsEnabled(true);
        }
    }, []);

    const steps = {
        stepsEnabled: true,
        initialStep: 0,
        steps: [
            {
                element: '.profile-picture',
                intro: IvicosStrings.roomIntroStep1
            },
            {
                element: '.personal-room',
                intro: IvicosStrings.roomIntroStep2,
                position: 'right'
            },
            {
                element: '.other-rooms',
                intro: IvicosStrings.roomIntroStep3
                // position: ''
            },
            {
                element: '.visitors',
                intro: IvicosStrings.roomIntroStep4
                // position: ''
            },
            {
                element: '.call-settings',
                intro: IvicosStrings.roomIntroStep5,
                // position: '',
                tooltipClass: 'introjs-tooltip-settings'
            },
            {
                element: '.call-settings',
                intro: IvicosStrings.roomIntroStep6,
                // position: '',
                tooltipClass: 'introjs-tooltip-video'
            },
            {
                element: '.call-settings',
                intro: IvicosStrings.roomIntroStep7,
                // position: '',
                tooltipClass: 'introjs-tooltip-target'
            }
        ],
        hintsEnabled: false
    };

    const options = {
        doneLabel: 'Ok!',
        nextLabel: IvicosStrings.nextActionTitle2,
        prevLabel: IvicosStrings.back,
        stepNumbersOfLabel: IvicosStrings.of,
        hideNext: false,
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true
    };

    return (
        <Steps
            enabled={stepsEnabled}
            steps={steps.steps}
            initialStep={steps.initialStep}
            options={options}
            onExit={() => setStepsEnabled(false)}
            onStart={() => {
                {
                    localStorage.setItem('intro-office', 'false');
                }
            }}
        />
    );
};

export default RoomListIntro;
