import * as React from 'react';

function SvgMemo(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M28 4l-1.875.906L4.5 15.313 3 16l1.344 1L9 20.5v7.531l1.438-.718 4.5-2.157 5.843 4.032 1.188.843.343-1.437 5.22-22.563L28 4zm-2.875 3.625l-4.406 19.094-4.469-3.063 4.313-9.5 1.406-3.125-2.844 1.844-9.063 5.906-3.218-2.406 18.281-8.75zm-7.781 8.813L14.25 23.25 11 24.844V20.53l6.344-4.093z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgMemo;
