import React from 'react';
import InviteStepView from './InviteStep.view';

export type ICSVData = { body: string[][]; headline: string[]; setBody: (value: React.SetStateAction<string[][]>) => void };

export interface IInviteStepProps {
    data?: ICSVData;
    invitationStatus: number[];
    onFinish: Function;
}

const InviteStep: React.FC<IInviteStepProps> = (props) => {
    return (
        <InviteStepView
            {...{
                data: props.data,
                invitationStatus: props.invitationStatus,
                onFinish: props.onFinish
            }}
        />
    );
};

export default InviteStep;
