import React from 'react';
import IconButton from '../IconButton';
import SvgClipboardError from 'kits/IconKit2/ClipboardError';
import SvgClipboard from 'kits/IconKit2/Clipboard';
import SvgClipboardSuccess from 'kits/IconKit2/ClipboardSuccess';
import { ICopyButtonState } from './CopyButton.state';

const CopyButtonView: React.FC<ICopyButtonState> = (props) => {
    const { status, tooltipContentByStatus, copyValueToClipboard } = props;

    const onRenderIcon = () => {
        if (status == 0) return <SvgClipboardError />;
        if (status == 2) return <SvgClipboardSuccess />;
        return <SvgClipboard />;
    };

    return <IconButton tooltipContent={tooltipContentByStatus[status]} onRenderIcon={onRenderIcon} onPress={copyValueToClipboard}></IconButton>;
};

export default CopyButtonView;
