import { useIdentityPanelState } from 'features/IdentityPanel/IdentityPanel.state';
import React from 'react';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import AvailabilitySelector from './children/AvailabilitySelector/AvailabilitySelector';
import PersonalPreferences from './children/PersonalPreferences/PersonalPreferences';
import UserProfile from './children/UserProfile/UserProfile';
import IdentityPanelView from './IdentityPanel.view';

const IdentityPanel: React.FC<{}> = () => {
    const [identityClaims] = useIdentityClaims();
    const state = useIdentityPanelState();

    return (
        <IdentityPanelView {...state}>
            <UserProfile />
            {!(identityClaims?.roles.includes('visitor') || identityClaims?.roles.includes('auto-confirmed-visitor')) && (
                <AvailabilitySelector onPanelDismiss={state.onPanelDismiss} />
            )}
            {!(identityClaims?.roles.includes('visitor') || identityClaims?.roles.includes('auto-confirmed-visitor')) && <PersonalPreferences />}
        </IdentityPanelView>
    );
};

export default IdentityPanel;
