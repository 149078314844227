import { IC } from 'components/SVGIcon';
import SvgCopyEmpty from 'kits/IconKit2/CopyEmpty';
import SvgCopyFull from 'kits/IconKit2/CopyFull';
import React from 'react';
import IconButton from '../IconButton';

export interface ICopyButtonAltProps {
    status: number;
    tooltipContentByStatus: {
        [index: number]: string;
    };
    copyValueToClipboard: () => void;
    ariaLabel: string;
}

const CopyButtonAltView: React.FC<ICopyButtonAltProps> = (props) => {
    const { status, tooltipContentByStatus, copyValueToClipboard, ariaLabel } = props;

    const onRenderIcon = () => {
        if (status == 0)
            return (
                <IC size={21} variant={'red'}>
                    <SvgCopyFull />
                </IC>
            );
        if (status == 2)
            return (
                <IC size={21} variant={'dark'}>
                    <SvgCopyFull />
                </IC>
            );
        return (
            <IC size={21} variant={'dark'}>
                <SvgCopyEmpty />
            </IC>
        );
    };

    return (
        <IconButton
            tooltipContent={tooltipContentByStatus[status]}
            onRenderIcon={onRenderIcon}
            onPress={copyValueToClipboard}
            ariaLabel={ariaLabel}
        ></IconButton>
    );
};

export default CopyButtonAltView;
