import React from 'react';
import ImportCSVStepView from './ImportCSVStep.view';

export type IFileUploadHandler = (file: File) => void;

export interface IImportCSVStepProps {
    onFileUpload: IFileUploadHandler;
}

const ImportCSVStep: React.FC<IImportCSVStepProps> = (props) => {
    return (
        <ImportCSVStepView
            {...{
                onFileUpload: props.onFileUpload
            }}
        />
    );
};

export default ImportCSVStep;
