import { DefaultEffects, getTheme, mergeStyles, Stack } from '@fluentui/react';
import { IResource } from 'kits/apiKit3/legacy';
import React from 'react';

export interface IAddOnViewProps {
    room?: string;
    frameURL?: string;
    isHidden?: boolean;
    addOn: IResource;
}

const AddOnView: React.FC<IAddOnViewProps> = (props) => {
    const { frameURL, room, isHidden, addOn } = props;
    const theme = getTheme();

    const AddOnViewStyles = mergeStyles({
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        boxShadow: DefaultEffects.elevation16,
        display: isHidden ? 'none' : undefined
    });

    if (!addOn) return <></>;

    return (
        <Stack grow className={AddOnViewStyles}>
            {room && frameURL && <iframe key={frameURL} src={frameURL} frameBorder="0" style={{ width: '100%', height: '100%' }} />}
        </Stack>
    );
};
export default AddOnView;
