import { Stack } from '@fluentui/react';
import InvitationLogo from 'components/InvitationLogo/InvitationLogo';
import { useOrganizationBranding } from 'features/Header/hooks';
import React from 'react';
import VisitorsInvalidInvitation from 'routes/VisitorsInvalidInvitation/VisitorsInvalidInvitation';
import defaultVisitorEntranceBG from '../../media/img/default-bgve.jpg';

interface IVisitorsEntryRouteParams {
    orgId?: string;
    linkActive: boolean;
    errorResponse: any;
}

const VisitorsEntryView: React.FC<IVisitorsEntryRouteParams> = (props) => {
    const { orgId, linkActive, errorResponse } = props;
    const [branding] = useOrganizationBranding(orgId);
    const customBg = branding && branding.visitor_entrance_background_image;

    return (
        <Stack
            grow
            verticalAlign="center"
            style={{
                backgroundImage: `url(${customBg || defaultVisitorEntranceBG})`,
                height: '100vh',
                width: '100vw',
                alignItems: 'center',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >
            {linkActive ? (
                <></>
            ) : errorResponse && branding ? (
                <>
                    <Stack>
                        <InvitationLogo eventName={errorResponse ? errorResponse.event_name : ''} branding={branding} />
                    </Stack>
                    <Stack>
                        <VisitorsInvalidInvitation error={errorResponse} />
                    </Stack>
                </>
            ) : (
                <></>
            )}
        </Stack>
    );
};

export default VisitorsEntryView;
