import { getTheme, ITheme, PivotItem } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { CampusDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { OrgBrandingDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import 'intro.js/introjs.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { SetterOrUpdater } from 'recoil';
import { useCampus } from 'shared-state/directory/hooks';
import { useShowInvitationsManagerModal, useWindowWidth } from 'shared-state/display-options/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { useUserList } from 'shared-state/presence/hooks';
import 'styles/pivot.css';
import { getClassNames } from 'styles/responsiveness';
import { useCurrentOrgBranding } from 'shared-state/directory/hooks';

export interface IDashboardState {
    theme: ITheme;
    tabKey?: string;
    campus?: CampusDto;
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    tabIsVisible: {
        areas: boolean;
        members: boolean;
        settings: boolean;
        billing: boolean;
        log: boolean;
    };
    setTabIsVisible: React.Dispatch<
        React.SetStateAction<{
            areas: boolean;
            members: boolean;
            settings: boolean;
            billing: boolean;
            log: boolean;
        }>
    >;
    handleLinkClick: (item?: PivotItem | undefined) => void;
    isAdmin?: boolean;
    branding?: OrgBrandingDto;
    pivotDisplaySmallDevice: string;
    burgerMenu: string;
    campusNameSmallDevice: string;
    stackTitleSmallDevice: string;
    smallDevice: boolean;
    invitationsManagerModal: boolean;
    setInvitationsManagerModal: SetterOrUpdater<boolean>;
    trialExpiringWarningDisplayedToday: boolean;
    isOrgInTrial: string;
    identityClaims: any;
}

export const useDashboardState: () => IDashboardState = () => {
    const theme = getTheme();
    const { tabKey } = useParams<{ tabKey?: string }>();
    const [, setUserList] = useUserList();
    const [identityClaims] = useIdentityClaims();
    const [campus] = useCampus();
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [invitationsManagerModal, setInvitationsManagerModal] = useShowInvitationsManagerModal();

    const [tabIsVisible, setTabIsVisible] = useState({
        areas: true,
        members: false,
        settings: false,
        billing: false,
        log: false
    });
    const [trialExpiringWarningDisplayedToday, setTrialExpiringWarningDisplayedToday] = useState<boolean>(false);

    useEffect(() => {
        setUserList([]);
        localStorage.setItem('lastAreaId', '');

        const TrialWarningDisplayedToday = Number(localStorage.getItem('TrialWarning')) - new Date().getDate() == 0;
        setTrialExpiringWarningDisplayedToday(TrialWarningDisplayedToday);
    }, []);

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            window.location.href = '/dashboard/' + item.props.itemKey;
            dismissPanel();
        }
    };

    const isAdmin = identityClaims?.roles.includes('manager');
    const isOrgInTrial = identityClaims && Object.keys(identityClaims).includes('orgStatus') && identityClaims.orgStatus == 'trial';

    const [branding] = useCurrentOrgBranding();
    const { pivotDisplaySmallDevice, burgerMenu, campusNameSmallDevice, stackTitleSmallDevice } = getClassNames();
    const { smallDevice } = useWindowWidth();
    return {
        theme,
        tabKey,
        campus,
        isOpen,
        openPanel,
        dismissPanel,
        tabIsVisible,
        setTabIsVisible,
        handleLinkClick,
        isAdmin,
        branding,
        pivotDisplaySmallDevice,
        burgerMenu,
        campusNameSmallDevice,
        stackTitleSmallDevice,
        smallDevice,
        invitationsManagerModal,
        setInvitationsManagerModal,
        trialExpiringWarningDisplayedToday,
        isOrgInTrial,
        identityClaims
    };
};
