import React from 'react';

import { useSideBarState } from 'features/SideBar/SideBar.state';
import SideBarView from 'features/SideBar/SideBar.view';

export interface ISideBarProps {
    mobileMenuOpen: boolean;
    children?: React.ReactNode;
}

const SideBar: React.FC<ISideBarProps> = (props) => {
    const state = useSideBarState();

    return <SideBarView {...props} {...state} />;
};
export default SideBar;
