import { DialogType, getTheme, ITheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ICallAction } from 'features/Rooms/RoomView/CallActions';
import { aSetTopic, aSetTopicDialog, aShowTopic } from 'features/SetTopicDialog/atom';
import 'intro.js/introjs.css';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { Settings } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useRoom } from 'shared-state/directory/hooks';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useCurrentRoomId } from 'shared-state/location/hook';
import { ICallActionGroup } from './CallActionBar';

export interface ICallActionBarState {
    theme: ITheme;
    currentRoomId?: string;
    currentRoom?: IRoomResource;
    hideDialog: boolean;
    toggleHideDialog: () => void;
    dialogContentProps: {
        type: DialogType;
        title: string;
        closeButtonAriaLabel: string;
    };
    setToggleSetTopicDialog: any;
    showTopic: boolean;
    setShowTopic: any;
    topic: string;
    showIconOnHover: boolean;
    setShowIconOnHover: React.Dispatch<React.SetStateAction<boolean>>;
    showCallOut: boolean;
    setShowCallOut: React.Dispatch<React.SetStateAction<boolean>>;
    topicVal: string;
}

export const useCallActionBarState: (actions: Array<ICallActionGroup>) => ICallActionBarState = (actions) => {
    const theme = getTheme();
    const [currentRoomId] = useCurrentRoomId();
    const [currentRoom] = useRoom(currentRoomId || '');
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);

    const { smallDevice } = useWindowWidth();

    const setToggleSetTopicDialog = useSetRecoilState<boolean>(aSetTopicDialog);
    const [showTopic, setShowTopic] = useRecoilState<boolean>(aShowTopic);
    const topic = useRecoilValue<any>(aSetTopic);
    const [showIconOnHover, setShowIconOnHover] = useState<boolean>(false);
    const [showCallOut, setShowCallOut] = useState<boolean>(false);

    useEffect(() => {
        if (smallDevice) {
            const callAction: ICallAction = {
                key: 'device-settings',
                title: IvicosStrings.deviceSettingsActionTitle,
                command: 'toggleDeviceSettings',
                icon: <Settings />
            };
            const groupZero = actions.filter((actionGroup: ICallActionGroup) => actionGroup.key === 'action-group-0')[0];
            groupZero.actions = [];

            const groupOne = actions.filter((actionGroup: ICallActionGroup) => actionGroup.key === 'action-group-1')[0];
            groupOne.actions.push(callAction);
        }
    }, []);

    const textTruncate = (str: string): string => {
        if (str.length > 50) {
            return str.substring(0, 50) + '...';
        } else {
            return str;
        }
    };

    const topicVal = textTruncate(topic);

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Settings',
        closeButtonAriaLabel: IvicosStrings.closeActionTitle
    };

    return {
        theme,
        currentRoomId,
        currentRoom,
        hideDialog,
        toggleHideDialog,
        dialogContentProps,
        setToggleSetTopicDialog,
        showTopic,
        setShowTopic,
        topic,
        showIconOnHover,
        setShowIconOnHover,
        showCallOut,
        setShowCallOut,
        topicVal
    };
};
