import { Modal, Stack, Text, PrimaryButton, IconButton } from '@fluentui/react';
import Header from 'features/Header/Header';
import { useOrganizationBranding } from 'features/Header/hooks';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useNavigate } from 'react-router';

const TrialPeriodExpired: React.FC<{ identity: any; disableHeader?: boolean }> = ({ identity, disableHeader }) => {
    const routeHistory = useNavigate();
    const [branding] = useOrganizationBranding(identity.tid);

    const orgName = branding ? branding.orgName : '';
    const content = IvicosStrings.trialPeriodExpiredContent.replace('[org]', orgName);
    const userContent = IvicosStrings.trialPeriodExpiredUserContent.replace('[org]', orgName);

    return (
        <Stack>
            {!disableHeader && <Header />}
            <Modal isBlocking isOpen onDismiss={() => routeHistory('/welcome')}>
                <Stack style={{ padding: 5, marginBottom: -40 }}>
                    <IconButton style={{ marginLeft: 'auto' }} iconProps={{ iconName: 'Cancel' }} onClick={() => routeHistory('/welcome')} />
                </Stack>
                {['manager', 'owner'].includes(identity.type) ? (
                    <Stack
                        horizontalAlign="center"
                        style={{ maxWidth: 400, padding: 26, alignItems: 'start' }}
                        tokens={{ childrenGap: 32 }}
                        aria-label="trial-expired-modal"
                    >
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 6 }} style={{ alignItems: 'start', gap: '20px' }}>
                            <Text variant="xLarge">{IvicosStrings.trialPeriodExpiredTitle}</Text>
                            <Text>{content}</Text>
                            <Text aria-label="trial-expired-contact-sales-team">
                                {IvicosStrings.trialPeriodExpiredContactSalesTeam1}
                                <b>sales@ivicos.eu</b>
                                {IvicosStrings.trialPeriodExpiredContactSalesTeam2}
                            </Text>

                            <Text>{IvicosStrings.trialPeriodExpiredContactSalesTeam3}</Text>
                        </Stack>
                        <Stack style={{ alignSelf: 'end', flexDirection: 'row', gap: '15px' }}>
                            <PrimaryButton
                                text={IvicosStrings.talkToSales}
                                onClick={() => (window.location.href = 'mailto:sales@ivicos.eu')}
                                aria-label="trial-expired-write-us-button"
                            />
                        </Stack>
                    </Stack>
                ) : (
                    <Stack horizontalAlign="center" style={{ maxWidth: 400, padding: 26, alignItems: 'start' }} tokens={{ childrenGap: 32 }}>
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 6 }} style={{ alignItems: 'start', gap: '20px' }}>
                            <Text variant="xLarge">{IvicosStrings.trialPeriodExpiredTitle}</Text>
                            <Text>{userContent}</Text>
                        </Stack>
                        <Stack style={{ alignSelf: 'end', flexDirection: 'row', gap: '15px' }}>
                            <PrimaryButton
                                text={IvicosStrings.talkToSales}
                                onClick={() => (window.location.href = 'mailto:sales@ivicos.eu')}
                                aria-label="trial-expired-write-us-button"
                            />
                        </Stack>
                    </Stack>
                )}
            </Modal>
        </Stack>
    );
};

export default TrialPeriodExpired;
