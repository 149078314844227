import { getTheme, Panel, Stack, Text } from '@fluentui/react';
import React, { useState } from 'react';
import IvicosStrings from '../../kits/language/stringKit';
import OrganisationInvitationView, { IInvitationOption } from './OrganisationInvitation.view';

const OrganisationInvitationListView: React.FC<{
    invitationOptions: IInvitationOption[];
}> = ({ invitationOptions }) => {
    const theme = getTheme();

    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

    const [isInvitationsTextUnderlined, setIsInvitationsTextUnderlined] = useState<boolean>(false);

    return (
        <>
            {/* <Layer> */}
            <Panel
                styles={{
                    commands: {
                        background: theme.semanticColors.infoBackground + ' !important',
                        marginBottom: 16
                    },
                    contentInner: {
                        background: theme.semanticColors.infoBackground
                    },
                    scrollableContent: {
                        background: theme.semanticColors.infoBackground,
                        height: '100%'
                    },
                    header: {
                        background: theme.semanticColors.infoBackground
                    },
                    navigation: {
                        background: theme.semanticColors.infoBackground
                    }
                }}
                isOpen={isPanelOpen}
                isLightDismiss
                onDismiss={() => setIsPanelOpen(false)}
            >
                <Stack tokens={{ childrenGap: 16 }}>
                    {invitationOptions.map((invitationOption) => (
                        <OrganisationInvitationView key={invitationOption.key} invitationOption={invitationOption} />
                    ))}
                </Stack>
            </Panel>
            {/* </Layer> */}
            <Stack tokens={{ childrenGap: 16 }} horizontalAlign="center">
                {invitationOptions[0] && <OrganisationInvitationView invitationOption={invitationOptions[0]} />}
                {invitationOptions.length > 1 && (
                    <Text
                        aria-label="seeAllInv"
                        style={{
                            textDecoration: isInvitationsTextUnderlined ? 'underline' : '',
                            cursor: 'pointer'
                        }}
                        onClick={() => setIsPanelOpen(true)}
                        onMouseEnter={() => setIsInvitationsTextUnderlined(true)}
                        onMouseLeave={() => setIsInvitationsTextUnderlined(false)}
                    >
                        {IvicosStrings.seeAllInvitations}
                    </Text>
                )}
            </Stack>
        </>
    );
};
export default OrganisationInvitationListView;
