import { DefaultButton, Depths, getTheme, PrimaryButton, Stack, Text } from '@fluentui/react';
import chroma from 'chroma-js';
import React, { useState } from 'react';
import campusLogo from 'media/img/ivcampus-logo.svg';
import IvicosStrings from '../../kits/language/stringKit';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IInvitationOption {
    key: string;
    color?: string;
    text: string;
    logo?: string;
    onAccept: (onSuccess: Function, onError: Function) => void;
    onDecline: () => void;
}
const OrganisationInvitationView: React.FC<{
    invitationOption: IInvitationOption;
}> = ({ invitationOption }) => {
    const theme = getTheme();

    const [buttonState, setButtonState] = useState<number>(0);

    const getTextColorForBackground = (background: string) => {
        if (!background) return '#000';
        if (background.includes('gradient')) return '#fff';

        try {
            const bgColorLightness = chroma(background).hsl()[2];
            return bgColorLightness >= 0.5 ? '#000' : '#fff';
        } catch (err) {
            return '#000';
        }
    };

    const { smallDevice, mediumDevice } = useWindowWidth();

    return (
        <Stack aria-label="OrganisationInvitationListView">
            <Stack
                style={{
                    boxShadow: Depths.depth64,
                    maxWidth: !(mediumDevice || smallDevice) ? 360 : 305,
                    background: invitationOption.color
                }}
            >
                <Stack horizontal>
                    <Stack
                        style={{
                            backgroundImage: `url(${invitationOption.logo || campusLogo})`,
                            height: 100,
                            width: '40%',
                            backgroundSize: 'contain',
                            backgroundPosition: 'left',
                            backgroundRepeat: 'no-repeat',
                            margin: '13px 0px 13px 13px'
                        }}
                    ></Stack>
                    <Stack horizontal verticalAlign={'center'} style={{ padding: '15px 13px' }}>
                        <Text
                            style={{
                                lineHeight: '30px',
                                color: invitationOption.color && getTextColorForBackground(invitationOption.color),
                                width: '100%'
                            }}
                            variant="large"
                        >
                            {IvicosStrings.invitationTitle}
                            <span style={{ fontWeight: 'bold', marginLeft: 5 }}>{invitationOption.text}</span>!
                        </Text>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign="end" style={{ background: theme.palette.white, padding: '13px' }}>
                    <DefaultButton aria-label="decline" style={{ marginRight: 13 }} text={IvicosStrings.decline} onClick={invitationOption.onDecline} />
                    <PrimaryButton
                        aria-label="accept"
                        disabled={buttonState === 1}
                        onClick={() => {
                            setButtonState(1);
                            invitationOption.onAccept(
                                () => setButtonState(0),
                                () => setButtonState(2)
                            );
                        }}
                        text={[IvicosStrings.acceptAndJoin, IvicosStrings.loading, IvicosStrings.somethingWentWrong][buttonState]}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default OrganisationInvitationView;
