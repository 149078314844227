import { IProfile } from 'shared-state/identity/types';
import { useUserFromSpeakerStats } from 'shared-state/presence/hooks';
import { useId } from '@fluentui/react-hooks';
import { useState } from 'react';

export interface ISpeakerStatsUserState {
    user: IProfile | undefined;
    profileImage: string | undefined;
    userType: 'user' | 'visitor' | 'unconfirmed_visitor' | 'auto-confirmed-visitor' | 'manager' | 'owner' | undefined;
    colorReturner: (userName: string) => number;
    buttonId: string;
    isCalloutVisible: boolean;
    SetIsCalloutVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useSpeakerStatsUserState: (userName: string) => ISpeakerStatsUserState = (userName) => {
    const user = useUserFromSpeakerStats(userName);
    const profileImage = user?.profileImage;
    const buttonId = useId('callout-button');
    const [isCalloutVisible, SetIsCalloutVisible] = useState(false);
    const userType = user?.type;
    const colorReturner = (userName: string) => {
        const abc = '0123456789ABCDEFGHIJKLMNOPQRSTUVYWZ';
        const lastUserNameCharacter = userName.slice(-1).toUpperCase();
        const lastCharacterToNumber = abc.indexOf(lastUserNameCharacter);
        return lastCharacterToNumber % 6;
    };

    return { user, profileImage, userType, colorReturner, buttonId, isCalloutVisible, SetIsCalloutVisible };
};
