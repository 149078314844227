import { getTheme, Stack, TooltipHost } from '@fluentui/react';
import React from 'react';
import { IC, ISVGIconVariant } from 'components/SVGIcon';
import { ReactElement } from 'react-markdown';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const IconButton: React.FC<{
    tooltipContent?: string;
    iconVariant?: ISVGIconVariant;
    onRenderIcon: () => ReactElement;
    onPress: () => void;
    isSelected?: boolean;
    isDisabled?: boolean;
    ariaLabel?: string;
}> = ({ isSelected, tooltipContent, onRenderIcon, isDisabled, iconVariant, onPress, ariaLabel }) => {
    const theme = getTheme();
    const { smallDevice } = useWindowWidth();
    const onRenderContent = () => (
        <Stack
            styles={{
                root: {
                    padding: 4,
                    opacity: isDisabled ? 0.7 : 1,
                    background: isSelected ? theme.palette.neutralLighter : theme.palette.white,

                    '&:hover': {
                        background: !isDisabled && theme.palette.neutralLighter,
                        cursor: 'pointer'
                    },
                    '&:active': {
                        background: !isDisabled && theme.palette.neutralLight
                    }
                }
            }}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onClick={(e: any) => !isDisabled && onPress()}
            aria-label={ariaLabel}
        >
            <IC variant={iconVariant || isSelected ? 'primary' : 'dark'} size={24}>
                {onRenderIcon()}
            </IC>
        </Stack>
    );

    const wrap = (content: ReactElement) => {
        if (tooltipContent) return <TooltipHost content={smallDevice ? '' : tooltipContent}>{content}</TooltipHost>;

        return <>{content}</>;
    };

    return wrap(onRenderContent());
};

export default IconButton;
