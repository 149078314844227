import { Text, Stack } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import React from 'react';

export interface IBadgeProps {
    text?: string;
    size: string;
    fontSize: string;
    textColor?: string;
}

const Badge: React.FC<IBadgeProps> = ({ text, size, fontSize, textColor }) => {
    const theme = useTheme();
    return (
        <Stack horizontalAlign="center" verticalAlign="center" style={{ width: '42px', height: '42px' }}>
            <Text
                variant={fontSize === 'medium' ? 'medium' : 'tiny'}
                style={{ position: 'absolute', fontWeight: 'bold', color: theme.semanticColors.bodyText, paddingBottom: '1.5px' }}
            >
                {text
                    ? text.split(' ').length > 1
                        ? `${text.split(' ')[0].slice(0, 1).toUpperCase()}${text.split(' ')[1].slice(0, 1).toUpperCase()}`
                        : `${text.split(' ')[0].slice(0, 1).toUpperCase()}`
                    : 'V'}
            </Text>
            <svg
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit={2}
                width="1em"
                height="1em"
                style={{ width: `${size}px`, height: `${size}px` }}
            >
                <path
                    d="M48 15.34c0-4.189-3.4-7.588-7.589-7.588H7.59A7.592 7.592 0 000 15.34v15.178c0 4.187 3.4 7.587 7.589 7.587h32.822c4.189 0 7.59-3.4 7.59-7.587V15.34z"
                    fill={theme.semanticColors.menuBackground}
                />
                <path
                    fill={theme.semanticColors.bodyText}
                    d="M30.654 7.751h12.447a5.139 5.139 0 015.136 5.137V32.97a5.138 5.138 0 01-5.136 5.136H4.899A5.138 5.138 0 01-.237 32.97V12.89A5.139 5.139 0 014.899 7.75h12.447v1.667H4.899a3.472 3.472 0 00-3.47 3.47V32.97a3.472 3.472 0 003.47 3.47h38.202a3.472 3.472 0 003.47-3.47V12.89a3.472 3.472 0 00-3.47-3.47H30.654V7.75z"
                />
                <path fill={theme.semanticColors.bodyDivider} d="M17.347 0h13.307v12.681H17.347z" />
                <path
                    fill={theme.semanticColors.bodyText}
                    d="M30.654 0H17.346v12.681h13.308V0zm-4.915 1.667h3.248v9.347h-9.974V1.667h3.248v2.922c0 .967.779 1.752 1.739 1.752.96 0 1.739-.785 1.739-1.752V1.667z"
                />
                <path
                    d="M12.251 41.497L9.573 47.89H8.158l-2.67-6.393h1.548l1.883 4.567 1.91-4.567h1.422zM12.76 41.497h1.432v6.393H12.76zM17.692 48c-.489 0-.962-.069-1.419-.206-.456-.136-.823-.315-1.1-.534l.486-1.114c.265.201.58.362.946.484.365.122.73.183 1.096.183.406 0 .707-.063.902-.187.194-.126.291-.291.291-.499a.484.484 0 00-.172-.379 1.303 1.303 0 00-.442-.242 8.63 8.63 0 00-.73-.2 9.853 9.853 0 01-1.157-.347 1.891 1.891 0 01-.774-.558c-.215-.256-.323-.597-.323-1.023 0-.371.098-.707.292-1.01.195-.3.488-.54.88-.716.392-.177.87-.265 1.436-.265.395 0 .78.05 1.158.146.377.098.707.237.99.42l-.441 1.124c-.572-.335-1.144-.502-1.716-.502-.4 0-.697.067-.888.2a.618.618 0 00-.287.53c0 .22.11.382.331.489.221.106.558.211 1.012.315.472.116.857.231 1.159.347.3.116.558.299.773.548.215.25.322.588.322 1.014 0 .365-.098.699-.295 1-.198.302-.494.54-.889.717-.395.177-.875.265-1.44.265zM21.299 41.497h1.432v6.393h-1.432zM25.48 42.702H23.5v-1.205h5.392v1.205h-1.98v5.188H25.48v-5.188zM32.566 48a3.513 3.513 0 01-1.736-.43 3.124 3.124 0 01-1.211-1.182 3.314 3.314 0 01-.438-1.695c0-.627.146-1.191.438-1.694a3.13 3.13 0 011.21-1.183 3.52 3.52 0 011.737-.429c.643 0 1.22.143 1.733.429.513.287.917.681 1.21 1.183a3.28 3.28 0 01.443 1.694c0 .628-.147 1.193-.442 1.695a3.162 3.162 0 01-1.211 1.183c-.513.286-1.09.43-1.733.43zm0-1.26c.366 0 .696-.088.99-.26.295-.174.527-.416.694-.727.168-.31.253-.664.253-1.06a2.19 2.19 0 00-.253-1.059c-.167-.31-.399-.552-.693-.726a1.918 1.918 0 00-.99-.26c-.366 0-.696.086-.99.26a1.844 1.844 0 00-.694.726 2.19 2.19 0 00-.252 1.06c0 .395.084.749.252 1.059.168.31.399.553.693.726.295.173.625.26.99.26zM40.973 47.89l-1.193-1.78h-1.318v1.78H37.03v-6.393h2.68c.547 0 1.023.094 1.427.283.404.189.714.457.932.804.218.347.328.758.328 1.233 0 .475-.11.884-.332 1.228a2.115 2.115 0 01-.942.79l1.388 2.055h-1.538zm-.026-4.073c0-.36-.112-.635-.336-.827-.224-.192-.551-.288-.982-.288h-1.167v2.229h1.167c.43 0 .758-.097.982-.292.224-.195.336-.47.336-.822z"
                    fill={textColor}
                    fillRule="nonzero"
                />
            </svg>
        </Stack>
    );
};

export default Badge;
