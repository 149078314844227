import { Modal, Stack, IconButton, Text, TextField, PrimaryButton, Checkbox } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { IAttachmentUpdateModalState } from './AttachmentUpdateModel.state';

interface IAttachmentUpdateModalViewProps extends IAttachmentUpdateModalState {
    isOpen?: boolean;
    onDismiss?: Function;
}

const AttachmentUpdateModalView: React.FC<IAttachmentUpdateModalViewProps> = (props) => {
    const {
        isOpen,
        onDismiss,
        updateAttachment,
        titleHandler,
        attachmentTitle,
        count,
        descriptionHandler,
        attachmentDescription,
        count2,
        setCount,
        setCount2,
        isPinned,
        setIsPinned,
        addOnName
    } = props;
    return (
        <Modal isOpen={isOpen}>
            <Stack horizontal style={{ padding: 16 }} verticalAlign="center" horizontalAlign="space-between">
                <Text variant="large" style={{ fontWeight: 'bold' }}>
                    {IvicosStrings.editAttachmentTitle}
                </Text>
                <IconButton
                    iconProps={{ iconName: 'clear' }}
                    onClick={() => {
                        onDismiss && onDismiss();
                    }}
                />
            </Stack>
            <form
                onSubmit={(event) => {
                    updateAttachment(event);
                }}
            >
                <Stack style={{ padding: 16, minHeight: 176 }}>
                    <TextField
                        onChange={(e) => {
                            titleHandler(e);
                        }}
                        placeholder={IvicosStrings.giveAttachmentNameTitle}
                        defaultValue={attachmentTitle}
                        maxLength={40}
                        required
                    />
                    <Text variant="small" style={{ marginLeft: 'auto', marginBottom: 10 }}>
                        {count}/40
                    </Text>
                    <TextField
                        onChange={(e) => {
                            descriptionHandler(e);
                        }}
                        placeholder={IvicosStrings.giveAttachmentDescriptionTitle}
                        defaultValue={attachmentDescription === 'No description provided' ? IvicosStrings.noDescriptionProvidedWarning : attachmentDescription}
                        multiline
                        rows={9}
                        resizable={false}
                        maxLength={280}
                    />
                    <Text variant="small" style={{ marginLeft: 'auto' }}>
                        {count2}/280
                    </Text>
                    {addOnName === 'youtubelinkmanager' && (
                        <Checkbox
                            checked={isPinned}
                            className="show-expired-invitations-button"
                            label={IvicosStrings.youtubeLinkAttachmentModalVideoPinText}
                            onChange={(event: any, checked: any) => {
                                event.preventDefault();
                                setIsPinned(checked);
                            }}
                        />
                    )}
                </Stack>
                <Stack style={{ flexDirection: 'row' }}>
                    <PrimaryButton
                        style={{ marginLeft: 100, marginRight: 10, marginBottom: 20, backgroundColor: 'white', color: 'black', borderColor: 'black' }}
                        onClick={() => {
                            onDismiss && onDismiss();
                            setCount(0);
                            setCount2(0);
                        }}
                    >
                        {IvicosStrings.cancelActionTitle}
                    </PrimaryButton>
                    <PrimaryButton style={{ marginRight: 10 }} type="submit">
                        {IvicosStrings.saveActionTitle}
                    </PrimaryButton>
                </Stack>
            </form>
        </Modal>
    );
};

export default AttachmentUpdateModalView;
