import { useNotificationAssets } from 'features/NotificationsLayer/hooks/useNotificationAssets';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useState } from 'react';
import { makeMessageId, useReceivedMessage } from 'shared-state/notifications/hooks';
import { IReceivedMessage } from 'shared-state/notifications/types';
import { INotificationBannerAction } from './NotificationsBanner.view';
import { aShowSingleMessageNotification } from '../hooks/atoms';
import { useSetRecoilState } from 'recoil';

export interface INotificationsBannerState {
    getNotificationIcon: Function;
    timestamp: string;
    title: string;
    description?: string;
    onDismiss: () => void;
    actions: INotificationBannerAction[];
    messageType: string;
    guestId: string;
    hostsOfGuest: any[];
    messageSenderId: string;
    children?: React.ReactNode;
}

export const useNotificationBannerState: (messageId: string) => INotificationsBannerState = (messageId) => {
    const [message, setMessage] = useReceivedMessage(messageId);
    const [webNotification, setWebNotification] = useState<Notification | undefined>();
    const setShowSingleMessageNotification = useSetRecoilState(aShowSingleMessageNotification);

    const onDismiss = () => {
        setMessage((prevMessage: any) => {
            if (!prevMessage) return prevMessage;
            return { ...prevMessage, ...{ dismissed: true } };
        });
        webNotification?.close();
        setShowSingleMessageNotification(true);
    };

    const getMessageFallback = () => {
        const defaultMessage: IReceivedMessage = {
            id: makeMessageId(),
            sender: 'server',
            type: 'info',
            meta: {},
            timestamp: new Date(),
            body: IvicosStrings.somethingWentWrongNotificationActionTitle,
            announce: false
        };

        return defaultMessage;
    };

    const { title, description, timestamp, actions, getNotificationIcon, messageType, guestId, hostsOfGuest, messageSenderId } = useNotificationAssets(
        message || getMessageFallback(),
        onDismiss
    );

    const webNotifcationTitle =
        message?.type == 'memo' || message?.type == 'cross-area-memo' || message.type == 'memo-at-visitor-entrance' ? title + ' ' + description : title;

    useEffect(() => {
        if (document.hasFocus() || ['round-table', 'room-topic'].includes(title)) {
            return;
        }

        setWebNotification(new Notification(webNotifcationTitle, {}));
    }, [title]);

    return { getNotificationIcon, timestamp, title, description, onDismiss, actions, messageType, guestId, hostsOfGuest, messageSenderId };
};
