import { useLocation } from 'react-router';

export interface IIdentityPanelAction {
    title: string;
    action?: (e: any) => void;
    key: string;
    disabled?: boolean;
    symbol: string;
}

export interface IIdentityPanelState {
    onPanelDismiss: Function;
    onTabChange: (tabId: string) => void;
    isPanelOpen: boolean;
    selectedTab: string;
    children?: React.ReactNode;
}

export const useIdentityPanelState: () => IIdentityPanelState = () => {
    const location = useLocation();
    const isPanelOpen = ['#status', '#edit-profile', '#preferences'].includes(location.hash);

    const onPanelDismiss = () => {
        window.location.href = '#';
    };

    const onTabChange = (key: string) => {
        window.location.href = '#' + key;
    };

    const selectedTab = location.hash.replace('#', '');

    return { isPanelOpen, onPanelDismiss, selectedTab, onTabChange };
};
