import { getTheme, ITheme } from '@fluentui/react';
import axios from 'axios';
import { useEffect } from 'react';
import { getClassNames } from 'styles/responsiveness';
import { useStatePersist } from 'use-state-persist';

export interface IChangeLogFeedEntry {
    title: string;
    featuredImage?: string;
    timestamp: string;
    body: string;
}

export interface IChangeLogState {
    changeLogFeed: IChangeLogFeedEntry[];
    theme: ITheme;
    changeLogCard: string;
}

export const useChangeLogState: () => IChangeLogState = () => {
    const theme = getTheme();
    const { changeLogCard } = getClassNames();
    const [changeLogFeed, setChangeLogFeed] = useStatePersist<Array<IChangeLogFeedEntry>>('@changeLogFeed', []);

    const fetchLog = async () => {
        const changelogRequest = await axios.get('https://raw.githubusercontent.com/ivicos-GmbH/changelogs/main/campus.json');

        if (!changelogRequest.data || !Array.isArray(changelogRequest.data.changes)) return console.warn('Changelog unavailable');

        const feed: IChangeLogFeedEntry[] = changelogRequest.data.changes;
        setChangeLogFeed(feed);
    };
    useEffect(() => {
        fetchLog();
    }, []);

    return {
        changeLogFeed,
        theme,
        changeLogCard
    };
};
