import { DefaultButton, getTheme, Label, Modal, Stack, Text } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { IImagePickerImage } from '../ImagePicker.state';
import ImagePickerModal from './ImagePickerModal';

interface IImagePickerViewProps {
    onDismiss: () => void;
    modalIsOpen: boolean;
    selectedImage: IImagePickerImage;
    imageOptions: IImagePickerImage[];
    onImageSelect: (i: IImagePickerImage) => void;
    onFile: (f: any) => void;
    onModalOpen: () => void;
    onDiscardImage: (f: string) => void;
    label?: string;
}

const ImagePickerView: React.FC<IImagePickerViewProps> = ({
    onModalOpen,
    onDismiss,
    imageOptions,
    modalIsOpen,
    selectedImage,
    onImageSelect,
    onFile,
    label,
    onDiscardImage
}) => {
    const theme = getTheme();
    const { smallDevice } = useWindowWidth();

    return (
        <Stack>
            {label && <Label>{label}</Label>}
            <Stack
                style={{
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '320px',
                    padding: '16px 26px',
                    background: theme.palette.neutralLight
                }}
                tokens={{ childrenGap: 16 }}
            >
                <Stack
                    grow
                    style={{
                        width: '100%',
                        height: 128,
                        backgroundColor: theme.palette.themePrimary,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url('${selectedImage.url}')`
                    }}
                >
                    {selectedImage.url == '' && <Text>{IvicosStrings.imageTitle}</Text>}
                </Stack>
                <Stack style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
                    <DefaultButton onClick={onModalOpen}>{IvicosStrings.changeImageActionTitle}</DefaultButton>
                </Stack>
            </Stack>

            <Modal isOpen={modalIsOpen} onDismiss={onDismiss}>
                <ImagePickerModal
                    {...{
                        onDismiss,
                        selectedImage,
                        imageOptions,
                        onImageSelect,
                        onFile,
                        onDiscardImage,
                        theme,
                        smallDevice
                    }}
                />
            </Modal>
        </Stack>
    );
};

export default ImagePickerView;
