import { getTheme, loadTheme, Stack } from '@fluentui/react';
import DirectMemoComposer from 'features/Areas/AreaSwitch/CrossAreaDirectMessaging/DirectMessaging/Actions/DirectMemoComposer/DirectMemoComposer';
import InvitationsManager from 'features/Header/children/InvitationsManager/InvitationsManager';
import Header from 'features/Header/Header';
import React, { useEffect } from 'react';
import { ReactElement } from 'react-markdown';
import { getFlag } from 'routes/Flags/Flags';
import { useCrossAreaMessageType, useShowCrossAreaMessagePanel, useShowInvitationsManagerModal, useShowSearchBar } from 'shared-state/display-options/hooks';
import { DarkTheme } from 'themes/dark';
import { DefaultTheme } from 'themes/default';

interface ICoreViewProps {
    onRenderTopBarDocumentPicker: (textColor?: string) => ReactElement;
    children?: React.ReactNode;
}

const CoreView: React.FC<ICoreViewProps> = ({ onRenderTopBarDocumentPicker, children }) => {
    const theme = getTheme();
    //this should belong to a state file...
    const [invitationsManagerModal, setInvitationsManagerModal] = useShowInvitationsManagerModal();
    const [showSearchBar] = useShowSearchBar();
    const [crossAreaMessageType] = useCrossAreaMessageType();
    const [showCrossAreaMessagePanel] = useShowCrossAreaMessagePanel();

    useEffect(() => {
        getFlag('useDarkMode') == 'YES' ? loadTheme(DarkTheme) : loadTheme(DefaultTheme);
    }, []);

    return (
        <Stack style={{ height: '100%', backgroundColor: theme.palette.neutralLight }} tokens={{ childrenGap: 0 }}>
            <Header onRenderDocumentPicker={onRenderTopBarDocumentPicker} />
            {invitationsManagerModal && <InvitationsManager setInvitationsManagerModal={setInvitationsManagerModal} />}
            {(showSearchBar || crossAreaMessageType || showCrossAreaMessagePanel) && <DirectMemoComposer />}
            {children}
        </Stack>
    );
};

export default CoreView;
