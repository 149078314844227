import React from 'react';
import { useChoiceGroupState } from './ChoiceGroup.state';
import ChoiceGroupView from './ChoiceGroup.view';

interface IChoiceGroupProps {
    label?: string;
    options: any;
    defaultSelectedKey: any;
    onChange: any;
    callout?: string;
    disabled?: boolean;
}

const ChoiceGroup: React.FC<IChoiceGroupProps> = (props) => {
    const state = useChoiceGroupState(props.onChange);

    return (
        <ChoiceGroupView
            {...{
                ...state,
                ...{
                    label: props.label,
                    options: props.options,
                    defaultSelectedKey: props.defaultSelectedKey,
                    callout: props.callout,
                    onChange: props.onChange,
                    disabled: props.disabled
                }
            }}
        ></ChoiceGroupView>
    );
};

export default ChoiceGroup;
