import { getTheme, ITheme } from '@fluentui/react';
import { AttachmentDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { hardcodedAddOns } from 'features/Rooms/RoomView/addons';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { resourceService } from 'services/api/resource.service';
import { useShowAttachmentPanel } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId, useSelectedAttachment } from 'shared-state/location/hook';
import { ParticipantInfo, useYoutubeVideoAttachmentManager } from '../YoutubeVideoAttachmentManager';
import { useEvent } from 'react-use';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';
export interface IAttachmentsPanelState {
    campusId?: string;
    areaId?: string;
    roomId?: string;
    isAttachmentPanelVisible: boolean;
    theme: ITheme;
    setIsAttachmentPanelVisible: SetterOrUpdater<boolean>;
    isAttachmentCreationModalOpen: boolean;
    setIsAttachmentCreationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAttachment: SetterOrUpdater<
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined
    >;
    refetchAttachments: () => Promise<void>;
    refetchAttachmentsOnCreation: () => Promise<void>;
    isAttachmentUpdateModalOpen: boolean;
    attachmentToEdit?: AttachmentDto;
    setIsAttachmentUpdateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAttachmentToEdit: React.Dispatch<React.SetStateAction<AttachmentDto | undefined>>;
    filter?: string;
    setFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
    attachments: AttachmentDto[];

    addOn: (addOnName: string) =>
        | {
              id: string;
              type: string;
              attributes: {
                  urlTemplate: string;
                  iconUrl: string;
                  displayName: string;
                  description: string;
              };
              relationships: {};
          }
        | undefined;
    localProfile?: IProfile;

    onDelete: (attachmentId: any) => Promise<void>;
    onDragEnd: (result: any) => Promise<void>;
    filterAttachment: (filter: any) => void;
    activeAttachmentId: string;
    setActiveAttachmentId: React.Dispatch<React.SetStateAction<string>>;
    updateAttachmentPinStatus: (item: AttachmentDto, checked: boolean) => Promise<void>;
    videoThumbnailClick: (itemId: AttachmentDto) => void;
    calculateActualVideoStatus: (item: AttachmentDto) => 'playing' | 'stopped' | 'controlledByRemoteUser';
    videoWatchersList: ParticipantInfo[];
    isLocalUserVideoOwner: boolean;
    videoOwnerParticipant: undefined | ParticipantInfo;
    changeVideoOwner: (newVideoOwnerId: string) => void;
    jitsiApi: any;
    isVideoOwnershipTransferedToLocal: boolean;
    previousVideoOwnerParticipant: undefined | ParticipantInfo;
}

export const useAttachmentsPanelState: (jitsiApi: any) => IAttachmentsPanelState = (jitsiApi) => {
    const [attachments, setAttachments] = useState<AttachmentDto[]>([]);
    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();
    const [roomId] = useCurrentRoomId();
    const localProfile = useLocalProfile();
    const [isAttachmentPanelVisible, setIsAttachmentPanelVisible] = useShowAttachmentPanel();
    const [filter, setFilter] = useState<string | undefined>();
    const addOns = hardcodedAddOns;
    const [, setSelectedAttachment] = useSelectedAttachment();
    const [attachmentToEdit, setAttachmentToEdit] = useState<AttachmentDto | undefined>();
    const [isAttachmentCreationModalOpen, setIsAttachmentCreationModalOpen] = useState<boolean>(false);
    const [isAttachmentUpdateModalOpen, setIsAttachmentUpdateModalOpen] = useState<boolean>(false);
    const [activeAttachmentId, setActiveAttachmentId] = useState('');
    const { sendAttachmentsUpdatedEvent } = useEmittingEvents();
    const {
        playVideo,
        stopVideo,
        changeVideoOwner,
        playingVideoId,
        playingVideoOwnerId,
        isLocalUserVideoOwner,
        videoWatchersList,
        isVideoOwnershipTransferedToLocal,
        previousVideoOwnerId
    } = useYoutubeVideoAttachmentManager(jitsiApi);

    const calculateActualVideoStatus = (item: AttachmentDto) => {
        const playingStatus: 'playing' | 'stopped' | 'controlledByRemoteUser' = item.ref === playingVideoId ? 'playing' : 'stopped';

        return playingStatus;
    };

    const updateAttachmentPinStatus = async (item: AttachmentDto) => {
        if (!campusId || !areaId || !roomId || !item.id) return;
        await resourceService.updateAttachment(campusId, areaId, roomId, item.id, {});
        setIsAttachmentUpdateModalOpen(false);
        sendAttachmentsUpdatedEvent(roomId);
        refetchAttachments();
        setAttachmentToEdit(undefined);
    };

    useEffect(() => {
        isAttachmentPanelVisible && refetchAttachments();
    }, [campusId, roomId, areaId, isAttachmentPanelVisible]);

    useEffect(() => {
        setSelectedAttachment(undefined);
    }, [campusId, roomId, areaId]);

    const refetchAttachments = async () => {
        if (!campusId || !areaId || !roomId) return;

        const newAttachments = await resourceService.getAllForRoom(campusId, areaId, roomId);

        const sortedAttachments = newAttachments.sort((a, b) => Number(a.position) - Number(b.position));
        setAttachments(sortedAttachments);

        reorderAttachments(sortedAttachments, false);
    };

    useEvent('room-attachments-updated', () => {
        refetchAttachments();
    });

    const refetchAttachmentsOnCreation = async () => {
        if (!campusId || !areaId || !roomId) return;

        const newAttachments = await resourceService.getAllForRoom(campusId, areaId, roomId);

        newAttachments.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
        //  Adjust the position of the rest of the attachments
        for (let i = 1; i < newAttachments.length; i++) {
            newAttachments[i].position = '' + i;
        }
        sendAttachmentsUpdatedEvent(roomId);
        setAttachments(newAttachments);
        reorderAttachments(newAttachments, true);
    };

    const filterAttachment = async (filterValue: any) => {
        const allAttachments = await resourceService.getAllForRoom(campusId, areaId, roomId);
        const filteredAttachments = allAttachments.filter((item) => {
            if (!filterValue && filterValue === '') return allAttachments;

            if (addOn(item.addOnName)?.attributes.displayName.includes(filterValue)) return true;
            return false;
        });
        const sortedAttachments = [...filteredAttachments].sort((a, b) => Number(a.position) - Number(b.position));
        setAttachments(sortedAttachments);
    };

    const theme = getTheme();
    const addOn = (addOnName: string) => addOns.find((addon) => addon.id == addOnName);

    const onDelete = async (attachmentId: any) => {
        try {
            if (confirm(IvicosStrings.deleteAttachmentConfirmationTitle)) {
                await resourceService.deleteAttachment(campusId, areaId, roomId, attachmentId);
                refetchAttachments();
                sendAttachmentsUpdatedEvent(roomId);
            }
        } catch (e) {
            console.log('Error');
        }
    };

    const reorderAttachments = async (sortedAttachments: AttachmentDto[], reorderFetch: boolean) => {
        const newOrder = sortedAttachments.map((attachment, index) => ({
            attachmentId: attachment.id,
            position: index.toString()
        }));

        try {
            await resourceService.reorderAttachments(campusId, areaId, roomId, newOrder);
            reorderFetch && sendAttachmentsUpdatedEvent(roomId);
        } catch (error) {
            console.log('error', error);
        }
    };

    const onDragEnd = async (result: any) => {
        const { destination, source } = result;
        if (!destination || destination === null || destination.droppableId === null) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        const newPosition = destination.index;
        const items = attachments;
        const updatedArray = items.filter((item: any, index: number) => index != source.index);
        const [draggedItem] = items.filter((item: any, index: number) => index === source.index);
        updatedArray.splice(newPosition, 0, draggedItem);
        setAttachments(updatedArray);
        reorderAttachments(updatedArray, false);
    };
    const videoThumbnailClick = (item: AttachmentDto) => {
        if (isLocalUserVideoOwner) calculateActualVideoStatus(item) === 'stopped' ? playVideo(item.ref) : stopVideo();
        else calculateActualVideoStatus(item) === 'stopped' && playVideo(item.ref);
    };

    return {
        campusId,
        areaId,
        roomId,
        isAttachmentPanelVisible,
        theme,
        setIsAttachmentPanelVisible,
        isAttachmentCreationModalOpen,
        setIsAttachmentCreationModalOpen,
        setSelectedAttachment,
        refetchAttachments,
        refetchAttachmentsOnCreation,
        isAttachmentUpdateModalOpen,
        attachmentToEdit,
        setIsAttachmentUpdateModalOpen,
        setAttachmentToEdit,
        filter,
        setFilter,
        attachments: attachments.filter((att) => att.addOnName !== 'collaboardlinkmanager'),
        addOn,
        localProfile,
        onDelete,
        onDragEnd,
        filterAttachment,
        activeAttachmentId,
        setActiveAttachmentId,
        updateAttachmentPinStatus,
        videoThumbnailClick,
        calculateActualVideoStatus,
        isLocalUserVideoOwner,
        videoWatchersList,
        videoOwnerParticipant: videoWatchersList.find((p) => p.participantId === playingVideoOwnerId),
        changeVideoOwner,
        jitsiApi,
        isVideoOwnershipTransferedToLocal,
        previousVideoOwnerParticipant: videoWatchersList.find((p) => p.participantId === previousVideoOwnerId)
    };
};
