import { useRecoilState } from 'recoil';
import { aTabOptionList } from 'shared-state/tabs/atoms';
import { sTabOptionInList } from 'shared-state/tabs/selectors';

export const useTabOptionList = (listId: string): any => {
    return useRecoilState(aTabOptionList(listId));
};

export const useTabOption = (listId: string, key: string): any => {
    return useRecoilState(sTabOptionInList({ listId, key }));
};
