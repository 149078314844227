export const getLocalStorageKeyForOption = (optionName: string): any => {
    return 'option:' + optionName;
};

export const getOptionLocally = (optionName: string): any => {
    const optionKey = getLocalStorageKeyForOption(optionName);
    const option = localStorage.getItem(optionKey);
    return option;
};

export const getOptionArrayLocally = (optionName: string): any => {
    const optionString = getOptionLocally(optionName);

    try {
        const parsedState: string[] = optionString && JSON.parse(optionString);
        return parsedState || [];
    } catch (error) {
        console.log('err');
        return [];
    }
};

export const setOptionLocally = (optionName: string, newValue: string): any => {
    const optionKey = getLocalStorageKeyForOption(optionName);
    const option = localStorage.setItem(optionKey, newValue);
    return option;
};
