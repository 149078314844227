import React from 'react';
import { SetterOrUpdater } from 'recoil';
import { useInvitationsManagerState } from './InvitationsManager.state';
import InvitationsManagerView from './InvitationsManager.view';
export interface IInvitationsManagerProps {
    setInvitationsManagerModal: SetterOrUpdater<boolean>;
}

const InvitationsManager: React.FC<IInvitationsManagerProps> = (props) => {
    const state = useInvitationsManagerState();
    return (
        <InvitationsManagerView
            {...{
                ...state,
                ...{
                    setInvitationsManagerModal: props.setInvitationsManagerModal
                }
            }}
        ></InvitationsManagerView>
    );
};

export default InvitationsManager;
