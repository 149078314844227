// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function sendEvent(name, data) {
    let ev = new CustomEvent(name, {
        detail: data
    });
    window.dispatchEvent(ev);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useEvent(name, handler) {
    const onEvent = (e) => {
        handler(e.detail);
    };
    useEffect(() => {
        window.addEventListener(name, onEvent);

        return () => {
            window.removeEventListener(name, onEvent);
        };
    }, [name, handler]);

    return (data) => sendEvent(name, data);
}
