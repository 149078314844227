import React, { createContext, useContext, useState } from 'react';
import IvicosStrings from '../../kits/language/stringKit';

const LanguageContext = createContext(null);

export const useLanguage = (): any => {
    return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }: any): any => {
    const browserLanguage = navigator.language.includes('de') ? 'de' : 'en';
    const userLanguageChoice = localStorage.getItem('UserLanguageChoice');

    const [selectedLanguageOption, setSelectedLanguageOption] = useState<any>(userLanguageChoice ? userLanguageChoice : browserLanguage);

    IvicosStrings.setLanguage(selectedLanguageOption);

    const value: any = {
        selectedLanguageOption,
        setSelectedLanguageOption
    };

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
