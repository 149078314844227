import { Stack } from '@fluentui/react';
import 'intro.js/introjs.css';
import { DividerBox } from 'rc-dock';
import 'rc-dock/dist/rc-dock.css';
import React from 'react';
import 'styles/resize-handle.css';
import CommonRoomList from './CommonRoomList';
import PersonalRoomList from './PersonalRoomList';
import RoomListPanels from './RoomListPanels';
export interface IindexViewProps {
    personalRoomIds: string[];
}

const IndexView: React.FC<IindexViewProps> = (props) => {
    return (
        <Stack style={{ maxHeight: '100%', height: '100%' }}>
            <RoomListPanels />

            <Stack style={{ maxHeight: '100%', height: '100%' }}>
                <DividerBox mode="vertical" style={{ height: '100%' }}>
                    {props.personalRoomIds.length > 0 && <PersonalRoomList />}

                    <div style={{ height: props.personalRoomIds.length <= 0 ? '100%' : '50%', display: 'flex', flexDirection: 'column' }}>
                        <CommonRoomList />
                    </div>
                </DividerBox>
            </Stack>
        </Stack>
    );
};

export default IndexView;
