import React from 'react';
import { CommandButton, Dropdown, Stack, Text, Toggle } from '@fluentui/react';
import TopBar from 'features/Header/children/TopBar/TopBar';
import { IvicosColors } from 'kits/colorKit';
import { useNavigate } from 'react-router';
import IvicosStrings from 'kits/language/stringKit';

export type IFeatureFlag = 'jitsiEnv' | 'useDarkMode';

interface IFlagsViewProps {
    setFlag: (flag: IFeatureFlag, value: string) => any;
    getFlag: (flag: IFeatureFlag) => any;
}

const FlagsView: React.FC<IFlagsViewProps> = ({ setFlag, getFlag }) => {
    const routeHistory = useNavigate();

    return (
        <Stack style={{ height: '100vh', width: '100vw', background: IvicosColors.Grau25 }}>
            <TopBar title={IvicosStrings.experimentalFeatures} />
            <Stack grow horizontalAlign="center" style={{ paddingTop: 32 }}>
                <Stack horizontalAlign="start" style={{ maxWidth: 560, width: '100%' }}>
                    <CommandButton iconProps={{ iconName: 'back' }} onClick={() => routeHistory('/')}>
                        {IvicosStrings.goBackActionTitle}
                    </CommandButton>
                </Stack>
                <Stack style={{ marginBottom: 32, maxWidth: 560, width: '100%' }} tokens={{ childrenGap: 16 }}>
                    <Stack horizontal style={{ background: '#fff', padding: 26 }} verticalAlign="center">
                        <Stack grow style={{ paddingRight: 26 }}>
                            <Text variant="large">{IvicosStrings.darkModeActionTitle}</Text>
                            <Text variant="small" style={{ maxWidth: '80%' }}>
                                {IvicosStrings.enableThisFrag1}
                            </Text>
                        </Stack>
                        <Toggle
                            defaultChecked={getFlag('useDarkMode') == 'YES'}
                            onChange={(e: any, checked?: boolean) => {
                                setFlag('useDarkMode', checked ? 'YES' : 'NO');
                            }}
                        />
                    </Stack>
                    <Stack horizontal style={{ background: '#fff', padding: 26 }} verticalAlign="center">
                        <Stack grow style={{ paddingRight: 26 }}>
                            <Text variant="large">{IvicosStrings.useJitsiEnv}</Text>
                            <Text variant="small" style={{ maxWidth: '80%' }}>
                                {IvicosStrings.enableThisFrag2}
                            </Text>
                        </Stack>
                        {/* <Toggle
                            defaultChecked={getFlag('useJitsiStagingEnv') == 'YES'}
                            onChange={(e: any, checked?: boolean) => {
                                setFlag('useJitsiStagingEnv', checked ? 'YES' : 'NO');
                            }}
                        />
                        <Toggle
                            defaultChecked={getFlag('useJitsiStagingEnv') == 'YES'}
                            onChange={(e: any, checked?: boolean) => {
                                setFlag('useJitsiStagingEnv', checked ? 'BOTH' : 'NO');
                            }}
                        /> */}
                        <Dropdown
                            placeholder={getFlag('jitsiEnv') ? getFlag('jitsiEnv') : 'production'}
                            onChange={(e: any, i: any) => {
                                setFlag('jitsiEnv', i.key);
                            }}
                            options={[
                                {
                                    key: 'production',
                                    text: 'production'
                                },
                                {
                                    key: 'staging',
                                    text: 'staging'
                                },
                                {
                                    key: 'netways',
                                    text: 'netways'
                                }
                            ]}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default FlagsView;
