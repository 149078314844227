import { DefaultButton, IComboBox, PrimaryButton, Stack } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import ImportedPerson from '../ImportedPerson/ImportedPerson';

export type ICSVData = { body: string[][]; headline: string[]; setBody: (value: React.SetStateAction<string[][]>) => void };

export interface IReviewDataStepViewProps {
    onChangeImportedPersonData: (
        index: number,
        datatype: number
    ) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | IComboBox>, newValue?: string) => void;
    validateEmail: (value: string) => boolean;
    validateRole: (value: string) => boolean;
    validateLanguage: (value: string) => boolean;
    data?: ICSVData;
    declineData: () => void;
    acceptData: () => void;
}

const ReviewDataStepView: React.FC<IReviewDataStepViewProps> = (props) => {
    const { data, declineData, acceptData, onChangeImportedPersonData, validateEmail, validateRole, validateLanguage } = props;

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack style={{ margin: '16px 0px', overflow: 'scroll', height: 600 }}>
                {data?.body &&
                    data?.body.map((line, index) => (
                        <ImportedPerson
                            key={index}
                            indexInCSVDATA={index}
                            email={line[0].toLowerCase()}
                            role={['manager'].includes(line[1].toLowerCase()) ? IvicosStrings.managerRoleTitleInCap : IvicosStrings.userRoleTitleInCap}
                            language={line[2]}
                            onChangeImportedPersonData={onChangeImportedPersonData}
                            validateEmail={validateEmail}
                            validateRole={validateRole}
                            validateLanguage={validateLanguage}
                        />
                    ))}
            </Stack>
            <Stack horizontalAlign="center" horizontal tokens={{ childrenGap: 16 }}>
                <DefaultButton onClick={() => declineData()} text={IvicosStrings.changeFileActionTitle} iconProps={{ iconName: 'ChangeEntitlements' }} />
                <PrimaryButton
                    aria-label="confirm-bulk-invitation-button"
                    onClick={() =>
                        confirm(data && data?.body.length > 1 ? IvicosStrings.confirmBulkInviteQuestion1 : IvicosStrings.confirmBulkInviteQuestion2) &&
                        acceptData()
                    }
                    text={data && data?.body.length > 1 ? IvicosStrings.sendInvitesButtonTitle : IvicosStrings.sendInviteButtonTitle}
                    iconProps={{ iconName: 'Send' }}
                />
            </Stack>
        </Stack>
    );
};

export default ReviewDataStepView;
