import { IvicosColors } from 'kits/colorKit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useRef } from 'react';
import { useCurrentOrgBranding } from 'shared-state/directory/hooks';
import {
    useCrossAreaSearchBarInputValue,
    useIsCrossAreaUsersVisible,
    useIsMemoOpenOnCrossAreaMessagePanel,
    useShowCrossAreaMessagePanel,
    useShowSearchBar
} from 'shared-state/display-options/hooks';
import { ICrossAreaSearchPersonState } from '../dataTypes';

const useCrossAreaSearchPerson: () => ICrossAreaSearchPersonState = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [crossAreaSearchBarInputValue, setCrossAreaSearchBarInputValue] = useCrossAreaSearchBarInputValue();
    const [showSearchBar, setShowSearchBar] = useShowSearchBar();
    const [showCrossAreaMessagePanel, setShowCrossAreaMessagePanel] = useShowCrossAreaMessagePanel();
    const [isCrossAreaUsersVisible, setIsCrossAreaUsersVisible] = useIsCrossAreaUsersVisible();
    const [isMemoOpenOnCrossAreaMessagePanel] = useIsMemoOpenOnCrossAreaMessagePanel();
    const [orgBranding] = useCurrentOrgBranding();
    const orgColor = orgBranding?.orgColors[0] || IvicosColors.Mittelblau;

    const handleInputChange = (e) => {
        setCrossAreaSearchBarInputValue(e.target.value);
        setIsCrossAreaUsersVisible(true);
    };

    useEffect(() => {
        if (showSearchBar) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [showSearchBar]);

    useEffect(() => {
        if (showCrossAreaMessagePanel) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [showCrossAreaMessagePanel]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target) && !event.target.closest('.direct-messaging-container')) {
                setShowSearchBar(false);
                setCrossAreaSearchBarInputValue('');
                setIsCrossAreaUsersVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [inputRef]);

    const closeCrossAreaMessagePanel = () => {
        if (!isMemoOpenOnCrossAreaMessagePanel) {
            setShowCrossAreaMessagePanel(false);
            setCrossAreaSearchBarInputValue('');
            setIsCrossAreaUsersVisible(false);
        }
    };

    return {
        inputRef,
        crossAreaSearchBarInputValue,
        setCrossAreaSearchBarInputValue,
        isCrossAreaUsersVisible,
        handleInputChange,
        showSearchBar,
        orgColor,
        showCrossAreaMessagePanel,
        closeCrossAreaMessagePanel
    };
};
export default useCrossAreaSearchPerson;
