import * as React from 'react';

function SvgRaiseHand(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.75 0c-1.038 0-1.9.727-2.157 1.688a2.204 2.204 0 00-.844-.188A2.262 2.262 0 004.5 3.75v8.063l-.656-.657a2.267 2.267 0 00-3.188 0 2.267 2.267 0 000 3.188L5.74 19.43c.882.879 2.101 1.57 3.54 1.57h3.469A5.26 5.26 0 0018 15.75v-9a2.262 2.262 0 00-2.25-2.25c-.264 0-.513.056-.75.14v-.89a2.262 2.262 0 00-2.25-2.25c-.302 0-.58.08-.844.188C11.648.727 10.786 0 9.749 0zm0 1.5c.424 0 .75.325.75.75v7.5H12v-6c0-.425.325-.75.75-.75.424 0 .75.325.75.75v6H15v-3c0-.425.325-.75.75-.75s.75.325.75.75v9c0 2.08-1.67 3.75-3.75 3.75H9.28c-.955 0-1.775-.466-2.46-1.148l-5.11-5.063a.76.76 0 010-1.078.76.76 0 011.078 0l1.922 1.945 1.289 1.29V3.75c0-.425.325-.75.75-.75s.75.325.75.75v6H9v-7.5c0-.425.325-.75.75-.75z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgRaiseHand;
