import jwtDecode from 'jwt-decode';
import { setSessionSilent } from 'kits/sessionKit';
import React from 'react';
import { Navigate, useParams } from 'react-router';
import { RouteProps } from 'react-router-dom';

const DirectedUserToDashboard: React.FC<RouteProps> = (props: any) => {
    const token: any = localStorage.getItem('ivAccessToken');
    const payload = token && jwtDecode<any>(token);
    const isAdmin = ['owner', 'manager'].includes(payload.role);
    setSessionSilent('@rememberedPath', window.location.pathname);
    const { tabKey } = useParams();

    return <>{(tabKey == 'settings' || tabKey == 'members') && !isAdmin ? <Navigate to="/dashboard/areas" /> : props.children} </>;
};
export default DirectedUserToDashboard;
