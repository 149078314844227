/* eslint-disable react/display-name */
import { IUserAction } from 'features/Rooms/RoomList/helpers/userTypes';
import { HandWave, Memo } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useState } from 'react';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useDirectMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { useDirectControlNameWidthInMemo, useIsMemoOpenOnCrossAreaMessagePanel, useShowCrossAreaMessagePanel } from 'shared-state/display-options/hooks';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';
import { useRoomListIncludingPersonalRooms } from 'shared-state/directory/hooks';

export const useDirectMessagingActions = (user: IProfile): IUserAction[] => {
    const [currentRoomId] = useCurrentRoomId();
    const roomListIncludingPersonalRooms = useRoomListIncludingPersonalRooms();
    const currentRoom = roomListIncludingPersonalRooms.find((room) => room.id === currentRoomId);
    const localProfile: any = useLocalProfile();
    const [currentAreaId] = useCurrentAreaId();
    const [, setDirectMessageComposerUserIds] = useDirectMessageComposerUserIds();
    const [, addMessage] = useReceivedMessageInbox();
    const { crossAreaInviteUserToRoom } = useEmittingEvents();
    const [, setDirectControlNameWidthInMemo] = useDirectControlNameWidthInMemo();
    const [showCrossAreaMessagePanel] = useShowCrossAreaMessagePanel();
    const [isMemoOpenOnCrossAreaMessagePanel, setIsMemoOpenOnCrossAreaMessagePanel] = useIsMemoOpenOnCrossAreaMessagePanel();
    const [isInvited, setIsInvited] = useState(false);
    const chosenLanguage = localStorage.getItem('chosenLanguage');

    const sendMemoAction: IUserAction = {
        key: 'send-memo',
        title: IvicosStrings.sendUserMemoActionTitle,
        action: () => {
            showCrossAreaMessagePanel && !isMemoOpenOnCrossAreaMessagePanel && setIsMemoOpenOnCrossAreaMessagePanel(true);
            setDirectMessageComposerUserIds([user.uid]);
            setDirectControlNameWidthInMemo(true);
        },
        onRenderIcon: () => <Memo fontSize={20} />
    };

    const inviteToRoomAction: IUserAction = {
        key: 'invite-user',
        title: isInvited ? (chosenLanguage === 'en' ? 'Invited' : 'Eingeladen') : IvicosStrings.inviteUserToRoomActionTitle,
        action: () => {
            currentRoom && crossAreaInviteUserToRoom(user.uid, currentRoom);
            currentRoom &&
                addMessage({
                    id: makeMessageId(),
                    type: 'confirmation',
                    body: IvicosStrings.invitationSentNotificationActionTitle,
                    timeout: 2000,
                    sender: '',
                    meta: {},
                    timestamp: new Date()
                });

            showCrossAreaMessagePanel && setIsInvited(!isInvited);
        },

        onRenderIcon: () => <HandWave fontSize={20} />
    };

    let actions: any = [];
    if (!currentAreaId) {
        actions = [sendMemoAction];
    } else {
        actions = [sendMemoAction, inviteToRoomAction];
    }

    return localProfile?.uid == user.uid ? [] : actions;
};
