import { isVisitor } from 'kits/authKit';
import { selector } from 'recoil';
import { aAvailability, aIdentityClaims } from 'shared-state/identity/atoms';
import { IProfile } from 'shared-state/identity/types';
import { aCurrentCampusId, aCurrentRoomId } from 'shared-state/location/atoms';

export const sProfile = selector<IProfile | undefined>({
    key: 'sProfile',
    get: ({ get }) => {
        const idClaims = get(aIdentityClaims);
        const room = get(aCurrentRoomId);
        const campusId = get(aCurrentCampusId);
        const availability = get(aAvailability);
        if (!idClaims || !campusId) return undefined;

        const nextProfile: IProfile = {
            uid: idClaims.uid,
            displayName: idClaims.displayName,
            profileImage: idClaims.profileImageURL,
            room: room,
            availability: availability,
            type: isVisitor(idClaims)
                ? 'visitor'
                : !isVisitor(idClaims) && idClaims.roles.includes('auto-confirmed-visitor')
                ? 'auto-confirmed-visitor'
                : !isVisitor(idClaims) && idClaims.roles.includes('unconfirmed_visitor')
                ? 'unconfirmed_visitor'
                : 'user',
            invitationId: idClaims.visitorInvId,
            campusId
        };

        return nextProfile;
    }
});
