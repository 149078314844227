import * as React from 'react';

function SvgStarFilled(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg aria-label="star-filled" width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M30.336 12.547l-10.172-1.074L16 2.133l-4.164 9.34-10.172 1.074 7.598 6.848L7.14 29.398 16 24.29l8.86 5.11-2.122-10.005 7.598-6.847z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgStarFilled;
