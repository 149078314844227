import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import {
    aCloseNotificationBanner,
    aCloseNotificationBannerIds,
    aControlValue,
    aDismissOverflow,
    aExpandNotification,
    aHideNotification,
    aNotificationSenderId,
    aShowViewAll,
    aShowViewAllOfNotificationsOnly
} from 'features/NotificationsLayer/hooks/atoms';
import IvicosStrings from 'kits/language/stringKit';
import * as React from 'react';
import { useSetRecoilState } from 'recoil';

interface ICloseAllMessagesDialogProps {
    hideDialog: boolean;
    toggleHideDialog: () => void;
    closeMessageDialogValue: string;
    listOfMemos: any;
    idOfMessages: any;
}

const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu
};
const modalPropsStyles = { main: { maxWidth: 450 } };

export const CloseAllMessagesDialog: React.FC<ICloseAllMessagesDialogProps> = ({
    hideDialog,
    toggleHideDialog,
    closeMessageDialogValue,
    listOfMemos,
    idOfMessages
}) => {
    const [isDraggable] = useBoolean(false);
    const setCloseNotificationBanner = useSetRecoilState(aCloseNotificationBanner);
    const setCloseNotificationBannerIds = useSetRecoilState(aCloseNotificationBannerIds);
    const setNotificationSenderId = useSetRecoilState(aNotificationSenderId);
    const setControlValue = useSetRecoilState(aControlValue);
    const setDismissOverflow = useSetRecoilState(aDismissOverflow);
    const setExpandNotification = useSetRecoilState(aExpandNotification);
    const setHideNotification = useSetRecoilState(aHideNotification);
    const setShowViewAllOfNotificationsOnly = useSetRecoilState(aShowViewAllOfNotificationsOnly);

    const setShowViewAll = useSetRecoilState(aShowViewAll);
    const dialogContentProps = {
        type: DialogType.normal,
        title: `${
            closeMessageDialogValue === 'memosAndInvitations'
                ? IvicosStrings.closeAllMemosAndInvitations
                : closeMessageDialogValue === 'memos'
                ? IvicosStrings.closeAllMemos
                : closeMessageDialogValue === 'invitations'
                ? IvicosStrings.closeAllInvitations
                : closeMessageDialogValue === 'visitorNotifications'
                ? IvicosStrings.closeAllVisitorNotifications
                : IvicosStrings.closeAllMessages
        }`,
        subText: `${IvicosStrings.closeAllMessageDialogSubText1} ${
            closeMessageDialogValue === 'memosAndInvitations'
                ? IvicosStrings.memosAndInvitations
                : closeMessageDialogValue === 'memos'
                ? IvicosStrings.onlyMemos
                : closeMessageDialogValue === 'invitations'
                ? IvicosStrings.onlyInvitations
                : closeMessageDialogValue === 'visitorNotifications'
                ? IvicosStrings.onlyVisitorNotifications
                : IvicosStrings.onlyMessages
        }. ${IvicosStrings.closeAllMessageDialogSubText2}`
    };

    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined
        }),
        [isDraggable]
    );

    const ids: any = [];
    const closeGroupedNotificationBanner = (data: any, idOfClosingMessages: any) => {
        data.map((d: any) => ids.push(d.id));
        setCloseNotificationBanner((prevId: any) => {
            const listOfIds = [...prevId, ...ids];
            return [...new Set(listOfIds)];
        });

        setCloseNotificationBannerIds((prevId: any) => {
            const allIds = [...prevId, ...idOfClosingMessages];
            return [...new Set(allIds)];
        });
        setShowViewAllOfNotificationsOnly(false);
        setHideNotification(true);
        setShowViewAll(true);
        setNotificationSenderId('');
        setExpandNotification(false);
        setControlValue(false);
        setDismissOverflow(false);
    };

    return (
        <>
            <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <DefaultButton onClick={toggleHideDialog} text={IvicosStrings.closeAllMessageDialogNo} style={{ fontWeight: 'bold' }} />
                    <PrimaryButton
                        onClick={() => closeGroupedNotificationBanner(listOfMemos, idOfMessages)}
                        text={IvicosStrings.closeAllMessageDialogYes}
                        style={{ fontWeight: 'bold' }}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};
