import { DefaultButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import IvicosStrings from '../../kits/language/stringKit';

const StartSetupButtonView: React.FC<{
    isModalOpen: boolean;
    onModalDismiss: Function;
    onModalShouldOpen: Function;
    onConfirm: Function;
}> = ({ onConfirm }) => {
    return (
        <Stack style={{ alignItems: 'center' }}>
            <Stack>
                <Stack style={{ alignItems: 'center' }} tokens={{ childrenGap: 16 }}>
                    <Text variant="xLarge">{IvicosStrings.startSetupButtonTitle}</Text>
                    <DefaultButton
                        data-test-id="CreateCampusButton"
                        iconProps={{ iconName: 'Add' }}
                        onClick={() => onConfirm()}
                        text={IvicosStrings.startSetupButtonDescription}
                        style={{ padding: 20, marginLeft: 5 }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default StartSetupButtonView;
