/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultButton, DefaultEffects, DirectionalHint, Persona, PersonaSize, PrimaryButton, Stack, Text, TooltipHost, useTheme } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import MarkdownHost from 'features/MarkdownHost/MarkdownHost';
import { Close, Visitors } from 'kits/IconKit2';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { aIsUserOutOfCampus, aIsUserSwitchedArea, aNotificationSenderId, aShowSingleMessageNotification } from '../hooks/atoms';
import { INotificationsBannerState } from './NotificationsBanner.state';
import { useDirectMessageUserList, useUserList, useVisitorList } from 'shared-state/presence/hooks';
import { useFetchMembers } from 'shared-state/directory/hooks';
import { IvicosColors } from 'kits/colorKit';
import IvicosStrings from 'kits/language/stringKit';
import { useWindowWidth } from 'shared-state/display-options/hooks';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationBannerView: React.FC<INotificationsBannerState> = ({
    getNotificationIcon,
    timestamp,
    title,
    description,
    onDismiss,
    actions,
    messageType,
    guestId,
    hostsOfGuest,
    messageSenderId,
    children
}) => {
    const setShowSingleMessageNotification = useSetRecoilState(aShowSingleMessageNotification);
    const theme = useTheme();
    const [visitors] = useVisitorList();
    const [invitationEventname, setInvitationEventName] = useState('');
    const [invitationHostsName, setInvitationHostsName] = useState('');
    const [members, setMembers] = useState<any>([]);
    const [isUserSwitchedArea, setIsUserSwitchedArea] = useRecoilState(aIsUserSwitchedArea);
    const [isUserOutOfCampus, setIsUserOutOfCampus] = useRecoilState(aIsUserOutOfCampus);
    const [users] = useUserList();
    const [directMessageUserList] = useDirectMessageUserList();
    const { smallDevice } = useWindowWidth();

    const allHost = useMemo(() => {
        if (visitors.length > 0 && guestId && messageType === 'guest' && members && members.length > 0) {
            const visitor = visitors.find((visitor) => visitor.uid === guestId);
            if (visitor) {
                setInvitationEventName(visitor.eventName);
                return visitor.hostIds?.map((hostId) => members?.find((person) => person.id === hostId)) || [];
            }
        }
        return [];
    }, [visitors, guestId, messageType, members]);

    useEffect(() => {
        messageType === 'guest' && useFetchMembers(undefined, setMembers);
    }, [messageType]);

    useEffect(() => {
        const hostNames = allHost?.map((host: any) => host?.primaryText);
        const hostNamesString = hostNames.join(', ');
        setInvitationHostsName(hostNamesString);
    }, [allHost]);

    //! This codes needs to more optimized when both of the messaging systems will be merged together
    useEffect(() => {
        if (['memo', 'invite', 'knock'].includes(messageType) && !users.some((user) => user.uid === messageSenderId)) {
            setIsUserSwitchedArea((prevState) => ({ ...prevState, [messageSenderId]: true }));
        } else {
            setIsUserSwitchedArea((prevState) => ({ ...prevState, [messageSenderId]: false }));
        }
    }, [users, messageType, messageSenderId]);

    useEffect(() => {
        if (['cross-area-memo', 'cross-area-invite'].includes(messageType) && !directMessageUserList.some((user) => user.uid === messageSenderId)) {
            setIsUserOutOfCampus((prevState) => ({ ...prevState, [messageSenderId]: true }));
        } else {
            setIsUserOutOfCampus((prevState) => ({ ...prevState, [messageSenderId]: false }));
        }
    }, [directMessageUserList, messageType, messageSenderId]);

    return (
        <Stack
            style={{
                backdropFilter: 'blur(5px)',
                padding: '16px',
                background: theme.palette.white,
                zIndex: 3000,
                color: theme.palette.black,
                wordBreak: 'break-word',
                borderRadius: '4px',
                border: `1px solid ${theme.palette.neutralLight}`
            }}
        >
            <Stack horizontal tokens={{ childrenGap: 16 }}>
                {messageType === 'guest' ? (
                    <Stack>
                        <Stack verticalAlign="center" horizontalAlign="center" style={{ height: 48, width: 48 }}>
                            <Stack style={{ margin: 'auto' }}>{getNotificationIcon()}</Stack>
                        </Stack>
                        {hostsOfGuest.length > 0 ? (
                            hostsOfGuest.length < 3 ? (
                                hostsOfGuest.map((host) => (
                                    <Stack verticalAlign="center" horizontalAlign="center" style={{ height: 48, width: 48 }} key={host.uid}>
                                        <Persona
                                            imageUrl={host.image}
                                            imageInitials={host.name}
                                            size={PersonaSize.size40}
                                            imageAlt={host.name}
                                            styles={{ root: { marginLeft: '23px' } }}
                                        />
                                    </Stack>
                                ))
                            ) : (
                                <Stack>
                                    <Stack verticalAlign="center" horizontalAlign="center" style={{ height: 48, width: 48 }}>
                                        <Persona
                                            imageUrl={hostsOfGuest[0].image}
                                            imageInitials={hostsOfGuest[0].name}
                                            size={PersonaSize.size40}
                                            imageAlt={hostsOfGuest[0].name}
                                            styles={{ root: { marginLeft: '23px' } }}
                                        />
                                    </Stack>
                                    <Stack verticalAlign="center" horizontalAlign="center" style={{ height: 48, width: 48 }}>
                                        <TooltipHost
                                            directionalHint={DirectionalHint.topLeftEdge}
                                            content={
                                                smallDevice ? (
                                                    ''
                                                ) : (
                                                    <>
                                                        {hostsOfGuest.slice(1).map((host) => (
                                                            <Stack
                                                                key={host.uid}
                                                                style={{
                                                                    padding: '2px 5px'
                                                                }}
                                                            >
                                                                {host.name}
                                                            </Stack>
                                                        ))}
                                                    </>
                                                )
                                            }
                                        >
                                            <Stack
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: '50%',
                                                    backgroundColor: IvicosColors.Spacegrün,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: '#000',
                                                    fontSize: '16px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                +{hostsOfGuest.length - 1}
                                            </Stack>
                                        </TooltipHost>
                                    </Stack>
                                </Stack>
                            )
                        ) : null}
                    </Stack>
                ) : (
                    <Stack verticalAlign="center" horizontalAlign="center" style={{ height: 48, width: 48 }}>
                        {['knock', 'invite', 'cross-area-invite', 'memo', 'cross-area-memo'].includes(messageType) ? (
                            <Stack style={{ margin: 'auto' }}>{getNotificationIcon()}</Stack>
                        ) : (
                            <IC
                                size={40}
                                variant="dark"
                                style={{
                                    margin: 'auto'
                                }}
                            >
                                {getNotificationIcon()}
                            </IC>
                        )}
                    </Stack>
                )}

                <Stack grow style={{ maxWidth: 230, color: theme.palette.black }}>
                    <Text variant="tiny" style={{ color: theme.palette.black }}>
                        {timestamp}
                    </Text>
                    <Text variant="large" style={{ color: theme.palette.black, fontWeight: 700 }}>
                        {title}
                    </Text>
                    {messageType === 'guest' ? (
                        <Text variant="medium" style={{ wordWrap: 'break-word', color: theme.palette.black }}>
                            <Stack
                                style={{
                                    marginTop: 10
                                }}
                            >
                                {' '}
                                <Text
                                    style={{
                                        marginTop: 10
                                    }}
                                >
                                    {IvicosStrings.visitorFor}
                                    <span
                                        style={{
                                            fontWeight: 700
                                        }}
                                    >
                                        {invitationEventname ? invitationEventname : 'Loading...'}
                                    </span>{' '}
                                </Text>
                            </Stack>

                            <MarkdownHost>{`${IvicosStrings.visitorHost} ${invitationHostsName ? invitationHostsName : 'Loading...'}`}</MarkdownHost>
                        </Text>
                    ) : (
                        <Text variant="medium" style={{ wordWrap: 'break-word', color: theme.palette.black }}>
                            <MarkdownHost>{description}</MarkdownHost>
                        </Text>
                    )}
                </Stack>
                <Stack>
                    <div
                        className="pressable"
                        onClick={() => {
                            onDismiss();
                            setShowSingleMessageNotification(true);
                        }}
                    >
                        <IC
                            size={24}
                            variant="custom"
                            iconColor={theme.palette.black}
                            style={{
                                position: 'fixed',
                                right: 15
                            }}
                        >
                            <Close />
                        </IC>
                    </div>
                </Stack>
            </Stack>
            <Stack horizontal horizontalAlign="end" style={{ marginTop: 16 }} tokens={{ childrenGap: 8 }}>
                <NotificationBannerActions
                    actions={actions}
                    isUserSwitchedArea={isUserSwitchedArea}
                    isUserOutOfCampus={isUserOutOfCampus}
                    messageType={messageType}
                    messageSenderId={messageSenderId}
                />
            </Stack>
        </Stack>
    );
};

export default NotificationBannerView;

export interface INotificationBannerAction {
    title: string;
    key: string;
    action: Function;
}

export interface INotificationBannerActionsProps {
    actions: INotificationBannerAction[];
    isUserSwitchedArea: boolean;
    isUserOutOfCampus: boolean;
    messageType: string;
    messageSenderId: string;
}

const NotificationBannerActions: React.FC<INotificationBannerActionsProps> = ({
    actions,
    isUserSwitchedArea,
    isUserOutOfCampus,
    messageType,
    messageSenderId
}) => {
    return (
        <>
            {actions.map((action: INotificationBannerAction) =>
                ['Send memo', 'Nachricht senden'].includes(action.title) ? (
                    <DefaultButton
                        key={action.key}
                        style={{ width: '50%' }}
                        onClick={() => action.action()}
                        styles={{
                            root: {
                                fontWeight: 900
                            }
                        }}
                        disabled={
                            (['knock', 'memo', 'invite'].includes(messageType) && isUserSwitchedArea[messageSenderId]) ||
                            (['cross-area-memo', 'cross-area-invite'].includes(messageType) && isUserOutOfCampus[messageSenderId])
                        }
                    >
                        <span
                            style={{
                                fontWeight: 700
                            }}
                        >
                            {action.title}
                        </span>
                    </DefaultButton>
                ) : (
                    <PrimaryButton
                        key={action.key}
                        style={{ width: '50%' }}
                        onClick={() => action.action()}
                        styles={{
                            root: {
                                fontWeight: 900
                            }
                        }}
                        disabled={
                            (['knock', 'memo', 'invite'].includes(messageType) && isUserSwitchedArea[messageSenderId]) ||
                            (['cross-area-memo', 'cross-area-invite'].includes(messageType) && isUserOutOfCampus[messageSenderId])
                        }
                    >
                        {['Meet them', 'Sich treffen'].includes(action.title) && (
                            <IC
                                size={20}
                                variant="light"
                                style={{
                                    marginRight: 5
                                }}
                            >
                                <Visitors />
                            </IC>
                        )}

                        {action.title}
                    </PrimaryButton>
                )
            )}
        </>
    );
};
