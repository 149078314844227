import { Icon, Stack } from '@fluentui/react';
import React from 'react';
import DirectMessaging from '../DirectMessaging/DirectMessaging';
import { ICrossAreaSearchProps } from '../dataTypes';
import IvicosStrings from 'kits/language/stringKit';
import { useShowSearchBar } from 'shared-state/display-options/hooks';

export const CrossAreaSearch: React.FC<ICrossAreaSearchProps> = ({
    inputRef,
    crossAreaSearchBarInputValue,
    setCrossAreaSearchBarInputValue,
    handleInputChange,
    inputStyle,
    isCrossAreaUsersVisible,
    orgColor,
    mobileView
}) => {
    const [showSearchBar] = useShowSearchBar();

    const handleClearInput = (event) => {
        event.stopPropagation();
        setCrossAreaSearchBarInputValue('');
    };

    return (
        <Stack>
            <Stack style={{ position: 'relative' }}>
                <input
                    ref={inputRef}
                    type="text"
                    placeholder={IvicosStrings.crossAreaSearchPersonSearchBarPlaceholder}
                    value={crossAreaSearchBarInputValue}
                    onChange={handleInputChange}
                    style={inputStyle}
                    maxLength={40}
                    aria-label="search-person-input-field"
                />
                {crossAreaSearchBarInputValue && (
                    <Icon
                        iconName="Cancel"
                        style={{
                            position: 'absolute',
                            top: mobileView ? '68%' : '53%',
                            right: mobileView ? '4%' : '8%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: '#000'
                        }}
                        onClick={(e) => handleClearInput(e)}
                    />
                )}
            </Stack>

            {isCrossAreaUsersVisible && mobileView && (
                <Stack
                    style={{
                        width: '100%',
                        maxHeight: '100%',
                        backgroundColor: '#fff',
                        borderRadius: '0 0 5px 5px'
                    }}
                >
                    <DirectMessaging mobileView={true} />
                </Stack>
            )}
            {isCrossAreaUsersVisible && showSearchBar && !mobileView && (
                <Stack
                    style={{
                        width: 400,
                        maxHeight: 500,
                        backgroundColor: orgColor,
                        zIndex: 100,
                        borderRadius: '0 0 5px 5px'
                    }}
                    className="direct-messaging-container"
                    aria-label="search-person-direct-messaging-card"
                >
                    <DirectMessaging mobileView={false} />
                </Stack>
            )}
        </Stack>
    );
};
