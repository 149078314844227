import { Label, List, Stack, Text } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import CreateNewRoomButton from 'features/Rooms/RoomList/CreateNewRoomButton/CreateNewRoomButton';
import ListedRoom from 'features/Rooms/RoomList/ListedRoom/ListedRoom';
import 'intro.js/introjs.css';
import IvicosStrings from 'kits/language/stringKit';
import 'rc-dock/dist/rc-dock.css';
import React, { ReactElement } from 'react';
import 'styles/resize-handle.css';
import { CommonRoomListState } from './CommonRoomList.state';

const CommonRoomListView: React.FC<CommonRoomListState> = (props) => {
    const theme = useTheme();

    const onRenderCell = (roomId: string | undefined): ReactElement => {
        if (!roomId) return <></>;
        return <ListedRoom roomId={roomId} />;
    };
    return (
        <React.Fragment>
            <Label style={{ marginTop: 7 }}>{IvicosStrings.commonRoomsSectionTitle}</Label>
            <Stack grow style={{ position: 'relative', overflow: 'hidden' }}>
                <Stack grow className={'other-rooms'} style={{ position: 'relative', height: '100%', overflowY: 'auto' }}>
                    <Stack>
                        {props.commonRoomIds.length <= 0 ? (
                            <Stack className="padding_vertical" horizontalAlign="center">
                                <Text variant="small">{IvicosStrings.commonRoomsSectionEmptyNoticeTitle}</Text>
                            </Stack>
                        ) : (
                            <Stack style={{ direction: 'ltr', paddingBottom: 22 }}>
                                <List items={props.commonRoomIds} onRenderCell={onRenderCell} />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
                <div
                    style={{
                        background: `linear-gradient(0deg,${theme.palette.neutralLight},${theme.palette.neutralLight},transparent)`,
                        height: 32,
                        minHeight: 16,
                        zIndex: 20,
                        position: 'absolute',
                        bottom: 0,
                        pointerEvents: 'none',
                        width: '100%'
                    }}
                ></div>
            </Stack>
            <Stack style={{ paddingBottom: 8 }}>
                <CreateNewRoomButton enableCreateRoomButton={props.enableCreateRoomButton} />
            </Stack>
        </React.Fragment>
    );
};
export default CommonRoomListView;
