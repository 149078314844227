import { useOnboardingVideoPopupState } from 'features/OnboardingVideoPopup/OnboardingVideoPopup.state';
import OnboardingVideoPopupView from 'features/OnboardingVideoPopup/OnboardingVideoPopup.view';
import React from 'react';

const OnboardingVideoPopup: React.FC<{}> = () => {
    const state = useOnboardingVideoPopupState();

    return <OnboardingVideoPopupView {...state} />;
};

export default OnboardingVideoPopup;
