import React from 'react';
import { getTheme, Stack, Text } from '@fluentui/react';
import Badge from 'components/Badge';

export interface IUserBadgeViewProps {
    text?: string;
    sizeBadge: string;
    fontSize: string;
    displayBadgeName: boolean;
    ariaLabel?: string;
    isForUserProfile?: boolean;
    emailForBadge?: string;
}

const theme = getTheme();

const UserBadgeView: React.FC<IUserBadgeViewProps> = (props) => {
    const { text, sizeBadge, fontSize, displayBadgeName, ariaLabel, isForUserProfile, emailForBadge } = props;
    return (
        <>
            {!isForUserProfile ? (
                <Stack horizontal verticalAlign="center">
                    <Stack horizontalAlign="center" verticalAlign="start" style={{ width: '40px', paddingRight: 10 }} aria-label={ariaLabel}>
                        <Badge text={text} size={sizeBadge} fontSize={fontSize} />
                    </Stack>
                    {displayBadgeName && (
                        <Text variant="medium" style={{ color: theme.palette.black }}>
                            {text && text.split(' ').length > 1
                                ? `${text.split(' ')[0]} ${text.split(' ')[1].slice(0, 1).toUpperCase()}.`
                                : `${text && text.split(' ')[0]}`}
                        </Text>
                    )}
                </Stack>
            ) : (
                <Stack horizontal verticalAlign="center" style={{ padding: '10px' }}>
                    <Badge text={text} size={sizeBadge} fontSize={fontSize} />
                    <Stack verticalAlign="center" style={{ width: '200px', marginLeft: '15px' }}>
                        <Text variant="xLarge">{text}</Text>
                        <Text>{emailForBadge}</Text>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default UserBadgeView;
