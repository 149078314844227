import { AtomEffect } from 'recoil';
import { getOptionArrayLocally, getOptionLocally, setOptionLocally } from 'shared-state/display-options/helpers';

export const useLocalOptionsAtomEffects = (optionName: string): any => {
    const effects: AtomEffect<string | undefined>[] = [
        ({ onSet, setSelf }) => {
            const currentSessionState = getOptionLocally(optionName);
            currentSessionState && setSelf(currentSessionState);
            onSet((newValue) => {
                typeof newValue == 'string' && setOptionLocally(optionName, newValue);
            });
        }
    ];
    return effects;
};

export const useLocalOptionsAtomArrayEffects = (optionName: string): any => {
    const effects: (tenantId: string) => AtomEffect<string[]>[] = (tenantId: string) => [
        ({ onSet, setSelf }) => {
            const currentSessionState = getOptionArrayLocally('tid:' + tenantId + ':' + optionName);
            currentSessionState && setSelf(currentSessionState);

            onSet((newValue) => {
                setOptionLocally('tid:' + tenantId + ':' + optionName, JSON.stringify(newValue));
            });
        }
    ];
    return effects;
};
