import { TooltipDelay } from '@fluentui/react';
import { useUserActions } from 'features/Rooms/RoomList/helpers/userHooks';
import { IUserAction } from 'features/Rooms/RoomList/helpers/userTypes';
import { UserAvailability } from 'kits/availabilityKit';
import { IProfile } from 'shared-state/identity/types';
import { IUserFaceActionStyles } from './UserFace.view';

export interface IUserFaceState {
    profileImageURL: string | undefined;
    actions: IUserAction[];
    toolTipProps: {
        delay: TooltipDelay;
    };
    actionsShouldBeDisplayOnHover: boolean;
    detailsAreHidden: boolean;
    defaultAvailibility: UserAvailability;
}

export const useUserFaceState: (user: IProfile, showDetails: boolean | undefined, actionStyles: IUserFaceActionStyles | undefined) => IUserFaceState = (
    user,
    showDetails,
    actionStyles
) => {
    const profileImageURL = user.profileImage;

    //@TODO Add actions
    const actions: IUserAction[] = useUserActions(user);

    const toolTipProps = {
        delay: TooltipDelay.zero
    };

    const actionsShouldBeDisplayOnHover = actions?.length > 0 && !actionStyles?.hidden && !actionStyles?.expanded;
    const detailsAreHidden = !showDetails;
    const defaultAvailibility: UserAvailability = 'Available';
    return { profileImageURL, actions, toolTipProps, actionsShouldBeDisplayOnHover, detailsAreHidden, defaultAvailibility };
};
