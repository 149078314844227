/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultButton, Depths, Image, PrimaryButton, Stack, Text } from '@fluentui/react';
import { OrgBrandingDto } from '../../services/client-api-wrapper/identity_provider/responses.dto';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import React, { useContext, useEffect, useState } from 'react';
import IvicosStrings from 'kits/language/stringKit';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import AnimatedDoorbellIcon from './AnimatedDoorBell';
import { Memo } from 'kits/IconKit2';
import WaitingAreaMemoComposer from 'routes/HostPerspectiveWaitingArea/RoomSelectionDialog/WaitingAreaMemoComposer/WaitingAreaMemoComposer';
import { useIsWaitingAreaMemoDialogOpen } from 'shared-state/display-options/hooks';
import { useEvent } from 'kits/eventKit';
import SocketContext from 'services/socket-connection/context/SocketContext';
import { useLocalProfile } from 'shared-state/identity/hooks';

export interface IWaitingAreaSideCardProps {
    eventName: string;
    branding: OrgBrandingDto | undefined;
    visitorName: string;
    timerProps: {
        isPlaying: boolean;
        size: number;
        strokeWidth: number;
    };
    hourSeconds: number;
    isTimerActive: boolean;
    setIsTimerActive: (isActive: boolean) => void;
    welcomeMessage: string;
    notifyHost: () => void;
    hideWhenHostJoin: boolean;
    isButtonHovered: boolean;
    setIsButtonHovered: React.Dispatch<React.SetStateAction<boolean>>;
    isSendMemoBtnVisible: boolean;
}

const WaitingAreaSideCard: React.FunctionComponent<IWaitingAreaSideCardProps> = ({
    eventName,
    branding,
    visitorName,
    timerProps,
    hourSeconds,
    isTimerActive,
    setIsTimerActive,
    welcomeMessage,
    notifyHost,
    hideWhenHostJoin,
    isButtonHovered,
    setIsButtonHovered,
    isSendMemoBtnVisible
}) => {
    const {
        SocketState: { socket }
    } = useContext(SocketContext);
    const currentUser = useLocalProfile();
    const customLogo = branding && branding.orgLogo;
    const [isWaitingAreaMemoDialogOpen, setIsWaitingAreaMemoDialogOpen] = useIsWaitingAreaMemoDialogOpen();

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    useEffect(() => {
        isSendMemoBtnVisible && currentUser && socket && socket.emit('client-emit-fetch-hosts-list-at-visitor-entrance', { visitorInfo: currentUser });
    }, [currentUser, socket, isSendMemoBtnVisible]);

    return (
        <Stack style={{ boxShadow: Depths.depth64 }}>
            <Stack style={{ background: backgroundColor }}>
                {/* Logo */}
                <Stack
                    horizontalAlign="start"
                    style={{
                        marginLeft: 22,
                        marginTop: 20
                    }}
                    aria-label="visitor-waiting-area-logo"
                >
                    <Image src={customLogo} alt="Logo" style={{ width: '50%', height: 'auto' }}></Image>
                </Stack>

                {/* Event name */}
                <Stack horizontal verticalAlign={'center'} style={{ padding: '15px 22px' }}>
                    <Text
                        style={{
                            lineHeight: '30px',
                            color: getTextColorForBackground(backgroundColor),
                            width: '100%',
                            wordBreak: 'break-word'
                        }}
                        variant="xLargePlus"
                        aria-label="visitor-waiting-area-event-name"
                    >
                        {eventName}
                    </Text>
                </Stack>

                {isSendMemoBtnVisible && (
                    <Stack style={{ padding: '0 22px 22px 22px' }}>
                        <DefaultButton
                            text="Send host a memo"
                            styles={{
                                root: {
                                    width: '55%',
                                    marginTop: 15,
                                    padding: 'auto'
                                }
                            }}
                            onRenderIcon={() => <Memo style={{ marginRight: -5 }} />}
                            onClick={() => setIsWaitingAreaMemoDialogOpen(true)}
                        />
                        {isWaitingAreaMemoDialogOpen && (
                            <WaitingAreaMemoComposer
                                sentBy="visitor"
                                isOpen={isWaitingAreaMemoDialogOpen}
                                onClose={() => setIsWaitingAreaMemoDialogOpen(false)}
                            />
                        )}
                    </Stack>
                )}
            </Stack>
            {!hideWhenHostJoin && (
                <Stack style={{ background: '#fff' }}>
                    <Stack
                        tokens={{ childrenGap: 10 }}
                        style={{
                            width: '100%',
                            padding: '10px 20px 0 20px'
                            // height: 350
                        }}
                    >
                        {/* Greet visitor */}
                        <Stack style={{ paddingTop: 8, paddingBottom: 4 }}>
                            <Text variant="mediumPlus" aria-label="visitor-waiting-area-greet">
                                <strong>
                                    {IvicosStrings.waitingAreaHelloVisitor} {visitorName}!
                                </strong>
                            </Text>
                        </Stack>

                        {/* Welcome message */}
                        {welcomeMessage && welcomeMessage !== 'wElCoMe_MeSsAgE' && (
                            <Stack style={{ padding: '4px 0' }}>
                                <Text aria-label="visitor-waiting-area-welcome-message">{welcomeMessage}</Text>
                            </Stack>
                        )}
                        <Stack style={{ paddingTop: 4, paddingBottom: 8 }}>
                            {isTimerActive ? (
                                <Stack>
                                    {/* Wait briefly */}
                                    <Stack>
                                        <Text aria-label="visitor-waiting-area-wait-briefly">
                                            <strong>{IvicosStrings.waitingAreaWaitBriefly}</strong> {IvicosStrings.waitingAreaHostInformed}
                                        </Text>
                                    </Stack>

                                    {/* Timer */}
                                    <Stack
                                        horizontal
                                        horizontalAlign="center"
                                        verticalAlign="center"
                                        style={{
                                            marginTop: 8,
                                            borderRadius: 4
                                        }}
                                    >
                                        <Stack style={{ alignItems: 'center', padding: 10 }} aria-label="visitor-waiting-area-timer">
                                            <CountdownCircleTimer
                                                {...timerProps}
                                                isPlaying
                                                initialRemainingTime={hourSeconds}
                                                duration={hourSeconds}
                                                colors="#39689c"
                                                onComplete={() => setIsTimerActive(false)}
                                                strokeWidth={15}
                                            >
                                                {({ elapsedTime }) => {
                                                    return null;
                                                }}
                                            </CountdownCircleTimer>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ) : (
                                <Stack>
                                    {/* No response */}
                                    <Stack
                                        style={{
                                            height: 87
                                        }}
                                    >
                                        <Text aria-label="visitor-waiting-area-no-response">
                                            <strong>{IvicosStrings.waitingAreaNoOneGreet}</strong> {IvicosStrings.waitingAreaClickAgain}
                                        </Text>
                                    </Stack>

                                    {/* Notify Host */}
                                    <Stack horizontal horizontalAlign="center" style={{ height: 65, padding: '4px 0 8px 0' }}>
                                        <PrimaryButton
                                            text={IvicosStrings.waitingAreaNotifyHost}
                                            onClick={() => {
                                                notifyHost();
                                            }}
                                            onMouseEnter={() => setIsButtonHovered(true)}
                                            onMouseLeave={() => setIsButtonHovered(false)}
                                            onRenderIcon={() => <AnimatedDoorbellIcon isButtonHovered={isButtonHovered} />}
                                            aria-label="visitor-waiting-area-notify-host"
                                        />
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
export default WaitingAreaSideCard;
