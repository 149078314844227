import React from 'react';
import 'styles/helpers.css';
import AreaSwitchView from './AreaSwitch.View';
import { useAreaSwitchState } from './AreaSwitch.state';

export interface IAreaSwitchProps {
    color?: string;
}
const AreaSwitch: React.FC<IAreaSwitchProps> = (props) => {
    const state = useAreaSwitchState(props.color);

    return (
        <AreaSwitchView
            {...{
                ...state,
                ...{
                    selectedArea: state.currentAreaId,
                    options: state.areaOptions,
                    color: props.color
                }
            }}
        ></AreaSwitchView>
    );
};

export default AreaSwitch;
