import { IResource } from 'kits/apiKit3/legacy';
import React, { ReactElement } from 'react';
import { useAreaCardState } from './AreaCard.state';
import AreaCardView from './AreaCard.view';

export interface IAreaCardProps {
    fluid?: boolean;
    panelHeader: string;
    isPanelOpen: boolean;
    dismissPanel: () => void;
    campusId: string | undefined;
    deleteAreaHandler: (areaId: string) => void;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    vertical?: boolean;
    area: any;
    team?: any;
    onClick?: (e: any) => void;
    onRenderActions?: () => ReactElement;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    openPanel: () => void;
    areaId: string;
    saveDataForAreaDeletion: any;
    modalIsOpen: boolean;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAreaToEdit: any;
    onStar(a: IResource, isStared: boolean): void;
}

const AreaCard: React.FC<IAreaCardProps> = (props) => {
    const state = useAreaCardState(props.area, props.onStar);
    const {
        vertical,
        area,
        areaId,
        areaName,
        saveDataForAreaDeletion,
        modalIsOpen,
        setAreaToEdit,
        panelHeader,
        isPanelOpen,
        campusId,
        onClick,
        setPanelHeader,
        openPanel,
        setModalIsOpen,
        dismissPanel,
        deleteAreaHandler,
        setAreaName
    } = props;

    return (
        <AreaCardView
            {...{
                ...state,
                ...{
                    vertical,
                    title: area.attributes.displayName,
                    description: area.attributes.desc,
                    team: state.msTeam,
                    onClick,
                    setPanelHeader,
                    onRenderActions: state.renderAreaActions,
                    openPanel,
                    areaId,
                    saveDataForAreaDeletion,
                    modalIsOpen,
                    setModalIsOpen,
                    setAreaToEdit,
                    panelHeader,
                    isPanelOpen,
                    dismissPanel,
                    campusId,
                    deleteAreaHandler,
                    areaName,
                    setAreaName
                }
            }}
        ></AreaCardView>
    );
};
export default AreaCard;
