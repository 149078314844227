import * as React from 'react';

function SvgAnnounce(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M28 3.031l-1.594 1.156s-1.988 1.477-4.843 2.938C18.706 8.585 14.988 10 11.719 10H2v9.125l.031.156 2 7L4.25 27h6.063l-.344-1.281L8.344 20h3.375c3.226 0 6.945 1.441 9.812 2.906 2.867 1.465 4.875 2.907 4.875 2.907L28 26.968v-9.157c1.156-.418 2-1.519 2-2.812s-.844-2.395-2-2.813V3.031zM26 6.75v16.469c-.828-.559-1.484-1.067-3.563-2.125C19.5 19.594 15.707 18.074 12 18v-6c3.742-.074 7.54-1.625 10.469-3.125C24.543 7.812 25.179 7.309 26 6.75zM4 12h6v6H4v-6zm.344 8H6.25l1.406 5H5.75l-1.406-5z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgAnnounce;
