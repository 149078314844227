import { FontWeights, getTheme, IIconProps, mergeStyleSets, Modal, Text } from '@fluentui/react';
import { IButtonStyles, IconButton } from '@fluentui/react/lib/Button';
import IvicosStrings from '../kits/language/stringKit';
import React from 'react';

const PasswordlessAccessModal: React.FC<{ openPasswordlessAccessModal: boolean; setOpenPasswordlessAccessModal: any }> = ({
    openPasswordlessAccessModal,
    setOpenPasswordlessAccessModal
}): any => {
    return (
        <div aria-label="passwordless-access-modal" style={{ position: 'relative' }}>
            <Modal
                isOpen={openPasswordlessAccessModal}
                onDismiss={() => {
                    setOpenPasswordlessAccessModal(false);
                }}
                isBlocking={false}
                containerClassName={contentStyles.container}
            >
                <div className={`${contentStyles.header}`}>
                    <Text
                        variant="large"
                        style={{
                            marginTop: 20,
                            fontWeight: 'bold',
                            position: 'relative'
                        }}
                    >
                        {IvicosStrings.passwordLessAccessModalTitle}
                    </Text>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        data-test-id="close-passwordless-access-modal"
                        onClick={() => {
                            setOpenPasswordlessAccessModal(false);
                        }}
                    />
                </div>
                <div className={contentStyles.body} style={{ marginTop: 10 }}>
                    <Text>{IvicosStrings.passwordLessAccessModalDescription1}</Text>
                    <br />
                    <br />
                    <Text>{IvicosStrings.passwordLessAccessModalDescription2}</Text>
                    <br />
                    <br />
                    <Text style={{ margin: '17px auto 0 auto', textAlign: 'justify' }}>
                        <span style={{ fontWeight: 'bold', marginRight: 2 }}>{IvicosStrings.passwordLessAccessModalDescription3}</span>{' '}
                        {IvicosStrings.passwordLessAccessModalDescription4}
                    </Text>
                    <br />
                    <br />
                    <Text>{IvicosStrings.passwordLessAccessModalDescription5}</Text>
                </div>
            </Modal>
        </div>
    );
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 375,
        //TODO: might need to change the height later on
        height: 535
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'baseline',
            fontWeight: FontWeights.semibold,
            padding: '10px 12px 14px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 }
        }
    },

    closingButton: {
        float: 'right',
        margin: '10px 24px 20px 0'
    }
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};

export default PasswordlessAccessModal;
