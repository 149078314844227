import { useContext, useEffect } from 'react';
import SocketContext from '../../context/SocketContext';

export const useSocketHandlers = (eventHandlers: { eventName: string; callback: (...args: any[]) => void }[]): any => {
    const {
        SocketState: { socket }
    } = useContext(SocketContext);

    useEffect(() => {
        eventHandlers.forEach(({ eventName, callback }) => socket?.on(eventName, callback));
        return () => {
            eventHandlers.forEach(({ eventName, callback }) => socket?.off(eventName, callback));
        };
    }, [eventHandlers]);

    return;
};
