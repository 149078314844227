import React, { useEffect, useState } from 'react';
import { getTheme } from 'office-ui-fabric-react';
import { idpService } from 'services/api/identity-provider.service';
import { useStatePersist } from 'use-state-persist';
import { useEvent } from 'react-use';
import { ITheme } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';

export interface ISetupAssistantInfoLabels {
    open: {
        primary: string;
        secondary: string;
    };
    completed: {
        primary: string;
        secondary: string;
    };
}

export interface ISetupAssistantState {
    theme: ITheme;
    shouldBeVisible: boolean;
    setWasDismissed: React.Dispatch<unknown>;
    setVisibilityOverride: React.Dispatch<React.SetStateAction<boolean>>;
    isCompleted: boolean;
    infoLabels: ISetupAssistantInfoLabels;
    thingsToDo: ISetupAssistantToDo[];
    completedTodos: ISetupAssistantToDo[];
}

export interface ISetupAssistantToDo {
    key: string;
    done: boolean;
    label: string;
    path?: string;
}

export const useSetupAssistantState: () => ISetupAssistantState = () => {
    const theme = getTheme();

    const [wasDismissed, setWasDismissed] = useStatePersist('@setupAssistantWasDismissed');
    const [thingsToDo, setThingsToDo] = useState<ISetupAssistantToDo[]>([]);
    const [prevThingsToDo, setPrevThingsToDo] = useState<ISetupAssistantToDo[]>([]);

    const [visibilityOverride, setVisibilityOverride] = useState<boolean>(false);

    useEffect(() => {
        if (thingsToDo.filter((t) => !t.done).length == 0 && prevThingsToDo.filter((t) => !t.done).length != 0) setVisibilityOverride(true);
        setPrevThingsToDo(thingsToDo);
    }, [thingsToDo]);

    const toDoPaths: any = {
        profile_pic: '/#edit-profile',
        orgLogo: '/dashboard/settings',
        orgColors: '/dashboard/settings'
    };

    const checkProgress = async () => {
        const progress: any = await idpService.checkSetupCompleted();
        const keys = Object.keys(progress);
        const toDoLabels: any = {
            profile_pic: IvicosStrings.setProfileImage,
            orgLogo: IvicosStrings.setOrgLogo,
            orgColors: IvicosStrings.setOrgColor
        };

        const nextThingsToDo = keys.map((key: string) => ({ key, done: progress[key], label: toDoLabels[key], path: toDoPaths[key] }));

        setThingsToDo(nextThingsToDo);
    };

    useEffect(() => {
        checkProgress();
    }, []);

    useEvent('refetchBranding', () => {
        checkProgress();
    });
    useEvent('refetchUserDetails', () => {
        checkProgress();
    });

    const completedTodos = thingsToDo.filter((t) => t.done);
    const isCompleted = thingsToDo.filter((t) => !t.done).length <= 0;
    const shouldBeVisible = visibilityOverride || (!wasDismissed && !isCompleted);

    const infoLabels: ISetupAssistantInfoLabels = {
        open: {
            primary: IvicosStrings.SetupAssistantLabelOpenPrimary,
            secondary: IvicosStrings.SetupAssistantLabelOpenSecondary
        },
        completed: {
            primary: IvicosStrings.SetupAssistantLabelCompletedPrimary,
            secondary: IvicosStrings.SetupAssistantLabelCompletedSecondary
        }
    };

    return {
        theme,
        shouldBeVisible,
        setWasDismissed,
        setVisibilityOverride,
        isCompleted,
        infoLabels,
        thingsToDo,
        completedTodos
    };
};
