import * as React from 'react';

function SvgVisitorsAudio(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.75 0C3.88392 0 3.16667 0.71725 3.16667 1.58333V11.0833C3.16667 11.9494 3.88392 12.6667 4.75 12.6667H9.5C10.3661 12.6667 11.0833 11.9494 11.0833 11.0833V1.58333C11.0833 0.71725 10.3661 0 9.5 0H4.75ZM4.75 1.58333H9.5V11.0833H4.75V1.58333ZM0 7.91667V11.0833C0 13.6958 2.1375 15.8333 4.75 15.8333H6.33333V17.4167H3.16667V19H11.0833V17.4167H7.91667V15.8333H9.5C12.1125 15.8333 14.25 13.6958 14.25 11.0833V7.91667H12.6667V11.0833C12.6667 12.84 11.2567 14.25 9.5 14.25H4.75C2.99329 14.25 1.58333 12.84 1.58333 11.0833V7.91667H0Z"
                fill="black"
            />
        </svg>
    );
}

export default SvgVisitorsAudio;
