import * as React from 'react';

function SvgCopyEmpty(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.98 0v2.52l.84.84V.84h6.3v5.04h5.04V16.8h-6.3v.84H21V5.29L15.71 0H7.98zm7.98 1.43l3.61 3.61h-3.61V1.43zM0 3.36V21h13.02V8.65l-.118-.132-5.04-5.04-.131-.118H0zm.84.84h6.3v5.04h5.04v10.92H.84V4.2zm7.14.604L11.576 8.4H7.98V4.804z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgCopyEmpty;
