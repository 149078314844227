import { mergeStyles, Stack } from '@fluentui/react';
import NotificationBanner from 'features/NotificationsLayer/NotificationsBanner/NotificationBanner';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { IReceivedMessage, ISortedGroupedNotificationForGuest, ISortedGroupedNotificationForMemosAndInviteAndKnock } from 'shared-state/notifications/types';
import NotificationGroupedBanner from './NotificationsBanner/NotificationGroupedBanner/NotificationGroupedBanner';

const NotificationsLayerView: React.FC<{
    displayHint: string;
    receivedMessagesWithoutMemoAndInviteAndKnockAndGuestAndRoundTableUpdate: IReceivedMessage[];
    notificationSenderId: string;
    expandNotification: boolean;
    showViewAllOfNotificationsOnly: boolean;
    controlValue: boolean;
    dismissOverflow: boolean;
    //** MEMO(S) & INVITE(S) & KNOCK(S) */
    sortedUniqueReceivedMemoAndInviteAndKnockVisibleMessagesForSender: IReceivedMessage[];
    sortedMemosAndInvitesAndKnocks: ISortedGroupedNotificationForMemosAndInviteAndKnock[];
    //** GUEST(S) */
    sortedUniqueReceivedGuestVisibleMessagesForSender: any;
    sortedGuests: ISortedGroupedNotificationForGuest[];
    children?: React.ReactNode;
}> = ({
    displayHint,
    receivedMessagesWithoutMemoAndInviteAndKnockAndGuestAndRoundTableUpdate,
    notificationSenderId,
    expandNotification,
    showViewAllOfNotificationsOnly,
    controlValue,
    dismissOverflow,
    sortedUniqueReceivedMemoAndInviteAndKnockVisibleMessagesForSender,
    sortedMemosAndInvitesAndKnocks,
    //** GUEST(S) */
    sortedUniqueReceivedGuestVisibleMessagesForSender,
    sortedGuests,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children
}) => {
    const { smallDevice } = useWindowWidth();
    const rootLayerStyles = mergeStyles([
        {
            width: smallDevice ? 360 : 400,
            right: smallDevice ? 15 : 26,
            zIndex: 303030
        },
        displayHint == 'TOP'
            ? {
                  top: 48,
                  bottom: 'auto'
              }
            : {
                  top: 'auto',
                  bottom: 26
              }
    ]);

    return (
        <Stack
            className={rootLayerStyles}
            tokens={{ childrenGap: 8 }}
            style={{
                maxHeight: dismissOverflow ? 'auto' : 750,
                overflowY: dismissOverflow ? 'hidden' : 'auto',
                position: 'fixed'
            }}
        >
            {/* All other messages except memo(s), invite(s), knock(s) and guest(s) */}
            {receivedMessagesWithoutMemoAndInviteAndKnockAndGuestAndRoundTableUpdate
                .filter((m) => !m.dismissed)
                .map((message) => (
                    <NotificationBanner messageId={message.id} key={message.id} />
                ))}

            {controlValue && (
                <Stack style={{ opacity: showViewAllOfNotificationsOnly ? 1 : 0, transition: '0.5s ease' }}>
                    <Stack tokens={{ childrenGap: 8 }} style={{ maxHeight: expandNotification ? 520 : 0, overflowY: 'auto', transition: '0.5s' }}>
                        {/** MEMO(S) & INVITE(S) & KNOCK(S) */}
                        {sortedUniqueReceivedMemoAndInviteAndKnockVisibleMessagesForSender.length > 1 &&
                            sortedUniqueReceivedMemoAndInviteAndKnockVisibleMessagesForSender
                                .filter((m: any) => !m.dismissed)
                                .map((message: any) => <NotificationBanner messageId={message.id} key={message.id} />)}
                        {/** GUEST(S) */}
                        {sortedUniqueReceivedGuestVisibleMessagesForSender.length > 1 &&
                            sortedUniqueReceivedGuestVisibleMessagesForSender
                                .filter((m: any) => !m.dismissed)
                                .map((message: any) => <NotificationBanner messageId={message.id} key={message.id} />)}
                    </Stack>
                </Stack>
            )}

            {/** MEMO(S) & INVITE(S) & KNOCK(S) */}
            {showViewAllOfNotificationsOnly && notificationSenderId
                ? sortedMemosAndInvitesAndKnocks
                      .filter((m) => m.groupedNotificationSender === notificationSenderId)
                      .map((memo) => (
                          <Stack
                              style={{
                                  opacity: controlValue ? 1 : 0,
                                  transition: '0.5s'
                              }}
                              key={memo.groupedNotificationSender}
                          >
                              <NotificationGroupedBanner
                                  senderId={memo.groupedNotificationSender}
                                  numOfMessages={memo.numOfGroupedNotification}
                                  idOfMessages={memo.idsOfAllOfMemosAndInvitesAndKnocks}
                                  typeOfMessage={memo.typeOfAllOfMemosAndInvitesAndKnocks}
                                  listOfMemos={memo.sortedAllOfMemosAndInvitesAndKnocks}
                                  key={memo.groupedNotificationSender}
                              />
                          </Stack>
                      ))
                : sortedMemosAndInvitesAndKnocks.map((memo) => (
                      <NotificationGroupedBanner
                          senderId={memo.groupedNotificationSender}
                          numOfMessages={memo.numOfGroupedNotification}
                          idOfMessages={memo.idsOfAllOfMemosAndInvitesAndKnocks}
                          typeOfMessage={memo.typeOfAllOfMemosAndInvitesAndKnocks}
                          listOfMemos={memo.sortedAllOfMemosAndInvitesAndKnocks}
                          key={memo.groupedNotificationSender}
                      />
                  ))}

            {/** GUEST(S) */}
            {showViewAllOfNotificationsOnly && notificationSenderId
                ? sortedGuests
                      .filter((g) => g.groupedNotificationSender === notificationSenderId)
                      .map((guest) => (
                          <NotificationGroupedBanner
                              senderId={guest.groupedNotificationSender}
                              numOfMessages={guest.numOfGroupedNotification}
                              idOfMessages={guest.idOfGroupedNotification}
                              typeOfMessage={guest.typeOfGroupedNotification}
                              listOfMemos={guest.filteredListOfGroupedNotification}
                              key={guest.groupedNotificationSender}
                          />
                      ))
                : sortedGuests.map((guest) => (
                      <NotificationGroupedBanner
                          senderId={guest.groupedNotificationSender}
                          numOfMessages={guest.numOfGroupedNotification}
                          idOfMessages={guest.idOfGroupedNotification}
                          typeOfMessage={guest.typeOfGroupedNotification}
                          listOfMemos={guest.filteredListOfGroupedNotification}
                          key={guest.groupedNotificationSender}
                      />
                  ))}
        </Stack>
    );
};

export default NotificationsLayerView;
