import React from 'react';
import { useLinkAttachmentModalBoxState } from './LinkAttachmentModalBox.state';
import LinkAttachmentModalBoxView from './LinkAttachmentModalBox.view';

const LinkAttachmentModalBox: React.FC = () => {
    const state = useLinkAttachmentModalBoxState();

    return (
        <LinkAttachmentModalBoxView
            {...{
                ...state
            }}
        />
    );
};
export default LinkAttachmentModalBox;
