// const apiGatewayBase = 'http://localhost:8080/beta';
// const apiGatewayBase: any = 'https://api-de-eu.ivicos-campus.app/v0';
const apiGatewayBase = process.env.REACT_APP_GATEWAY_URL;
export const getApiGatewayUrl = (path: string, backendBranch: string): any => {
    if (apiGatewayBase?.endsWith('a') || apiGatewayBase?.endsWith('v0')) {
        return `${apiGatewayBase}/${path}/${backendBranch}`;
    } else {
        return `${apiGatewayBase}/${path}`;
    }
};
