import * as React from 'react';

function SvgSettingsVisitor(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3 5V27H29V5H3ZM5 7H27V25H5V7ZM16 10C13.25 10 11 12.25 11 15C11 16.4766 11.6602 17.8008 12.6875 18.7188C10.5273 19.7148 8.85938 21.6523 8.25 24H10.3438C11.168 21.6719 13.3945 20 16 20C18.6055 20 20.832 21.6719 21.6562 24H23.75C23.1406 21.6523 21.4727 19.7148 19.3125 18.7188C20.3398 17.8008 21 16.4766 21 15C21 12.25 18.75 10 16 10ZM16 12C17.668 12 19 13.332 19 15C19 16.668 17.668 18 16 18C14.332 18 13 16.668 13 15C13 13.332 14.332 12 16 12Z"
                fill="black"
            />
        </svg>
    );
}

export default SvgSettingsVisitor;
