/* eslint-disable prettier/prettier */
import { useMemo } from 'react';
import { usePersonalRoomList } from 'shared-state/directory/hooks';
import { sortRoomList } from './helpers';

export interface PersonalRoomListState {
    personalRoomIds: string[];
}
const usePersonalRoomListState: () => PersonalRoomListState = () => {
    const personalRooms = usePersonalRoomList();
    const personalRoomIds = useMemo(() => {
        return sortRoomList(personalRooms).map((r: any) => r.id);
    }, [personalRooms]);

    return {
        personalRoomIds
    };
};

export default usePersonalRoomListState;
