import { IconButton, List, Modal, Stack, Text, getTheme } from '@fluentui/react';
import LinkAttachmentModalBox from 'features/LinkAttachmentModalBox/LinkAttachmentModalBox';
import { IvicosColors } from 'kits/colorKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

interface IAttachmentCreationModalViewProps {
    isOpen?: boolean;
    onDismiss?: Function;
    createAttachment: (addOnName: string, isPinned: boolean, optionalRef?: string | undefined) => Promise<void>;
    addOns: {
        id: string;
        type: string;
        attributes: {
            urlTemplate: string;
            iconUrl: string;
            displayName: string;
            description: string;
        };
        relationships: {};
    }[];
    translation: (attachmentName: string, attachmentDescription: string) => string[];
    setToggleLinkAttachmentModalBox: any;
    setLinkAttachmentItemId: any;
    linkAttachmentItemIsPinned: boolean;
}

const AttachmentCreationModalView: React.FC<IAttachmentCreationModalViewProps> = (props) => {
    const { isOpen, onDismiss, createAttachment, addOns, translation, setToggleLinkAttachmentModalBox, setLinkAttachmentItemId, linkAttachmentItemIsPinned } =
        props;

    const theme = getTheme();

    return (
        <Modal isOpen={isOpen}>
            <Stack horizontal style={{ padding: 16 }} verticalAlign="center" horizontalAlign="space-between">
                <Text variant="large" style={{ fontWeight: 'bold' }}>
                    {IvicosStrings.chooseAttachmentTypeTitle}
                </Text>
                <IconButton
                    iconProps={{ iconName: 'clear' }}
                    onClick={() => {
                        onDismiss && onDismiss();
                    }}
                />
            </Stack>
            <Stack style={{ padding: 16, minHeight: 176 }}>
                <List
                    items={addOns.map((addOn) => ({
                        displayName: addOn.attributes.displayName,
                        description: addOn.attributes.description,
                        id: addOn.id,
                        icon: addOn.attributes.iconUrl
                    }))}
                    onRenderCell={(item) => {
                        if (!item) return <></>;
                        const [displayName, description] = translation(item.displayName, item.description);
                        return (
                            <Stack
                                aria-label="attachments-choose-tool-type"
                                className="pressable"
                                horizontal
                                style={{ padding: 8 }}
                                verticalAlign="center"
                                tokens={{ childrenGap: 8 }}
                                onClick={() => {
                                    if (item.id.endsWith('linkmanager')) {
                                        setToggleLinkAttachmentModalBox(false);
                                        setLinkAttachmentItemId(item.id);
                                        return;
                                    }
                                    createAttachment(item.id, linkAttachmentItemIsPinned);
                                }}
                            >
                                <img src={item.icon || 'https://static-cdn.vercel.app/addon.svg'} height={48} />
                                <Stack>
                                    <Text variant="large">{displayName}</Text>
                                    <Text variant="small">{description}</Text>
                                </Stack>
                                {item.displayName === 'YouTube' && (
                                    <Stack
                                        style={{
                                            backgroundColor: IvicosColors.Dunkelblau,
                                            textAlign: 'center',
                                            width: '43%',
                                            borderRadius: '15px',
                                            margin: '8px 0px 11px 0px'
                                        }}
                                    >
                                        <Text variant="small" style={{ color: theme.palette.white, padding: '3.5px 1px', fontWeight: 'bolder' }}>
                                            {IvicosStrings.experimentalLabelTitle}
                                        </Text>
                                    </Stack>
                                )}
                            </Stack>
                        );
                    }}
                />
            </Stack>
            <LinkAttachmentModalBox />;
        </Modal>
    );
};

export default AttachmentCreationModalView;
