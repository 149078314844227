import { Callout, TextField, DelayedRender, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { QuestionCircle } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

interface IMultilineTextFieldViewProps {
    isCalloutVisible: boolean;
    styles: any;
    toggleIsCalloutVisible: any;
    buttonId: string;
    directionalHint: any;
    label?: string;
    callout?: string;
    value?: string;
    key?: string;
    onTextLengthChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    resizable?: boolean;
    secondTextFieldValue?: string;
    maxLength?: number;
    language: string;
}

const MultilineTextFieldView: React.FC<IMultilineTextFieldViewProps> = (props) => {
    const {
        isCalloutVisible,
        styles,
        toggleIsCalloutVisible,
        buttonId,
        directionalHint,
        label,
        callout,
        value,
        key,
        onTextLengthChange,
        secondTextFieldValue,
        maxLength,
        language
    } = props;

    return (
        <Stack horizontal={false}>
            <Stack style={{ flexDirection: 'column' }}>
                <Text variant={'small'} style={{ marginRight: 'auto', fontSize: '14px', fontWeight: 'bold', marginBottom: 11 }}>
                    {callout != undefined ? IvicosStrings.farewellMessageTitle : ''}
                </Text>
                <Stack style={{ cursor: 'pointer', width: 25, height: 20, marginTop: -25, marginLeft: language === 'de' ? 210 : 130 }}>
                    {callout != undefined ? (
                        <IC variant="custom" size={15}>
                            <QuestionCircle onClick={toggleIsCalloutVisible} id={buttonId} aria-label="calloutFarewellMessage" />
                        </IC>
                    ) : null}
                    {isCalloutVisible && (
                        <Callout
                            className={styles.callout}
                            onDismiss={toggleIsCalloutVisible}
                            role="status"
                            target={`#${buttonId}`}
                            aria-live="assertive"
                            directionalHint={directionalHint}
                        >
                            <DelayedRender>
                                <Text variant="small">{callout}</Text>
                            </DelayedRender>
                        </Callout>
                    )}
                </Stack>
            </Stack>
            <TextField
                multiline
                resizable={false}
                placeholder={value}
                key={key}
                onChange={(event, newValue) => typeof newValue == 'string' && onTextLengthChange(event, newValue)}
                label={label}
                value={value || secondTextFieldValue}
            ></TextField>
            {maxLength && (
                <Text variant={'small'} style={{ marginLeft: 'auto' }}>
                    {secondTextFieldValue?.length}/{maxLength}
                </Text>
            )}
        </Stack>
    );
};

export default MultilineTextFieldView;
