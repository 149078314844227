import { Panel, Stack } from '@fluentui/react';
import { IIdentityPanelState } from 'features/IdentityPanel/IdentityPanel.state';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

const IdentityPanelView: React.FC<IIdentityPanelState> = (props) => {
    const { children, isPanelOpen, onPanelDismiss } = props;

    return (
        <Panel
            aria-label="identity-panel"
            isOpen={isPanelOpen}
            isLightDismiss
            onDismiss={() => {
                onPanelDismiss();
            }}
            headerText={IvicosStrings.identityPanelTitle}
            closeButtonAriaLabel={IvicosStrings.closeActionTitle}
            // Stretch panel content to fill the available height so the footer is positioned
            // at the bottom of the page
            hasCloseButton={true}
            isFooterAtBottom={true}
        >
            {/* This is where the children live, i.e. <UserProfile /> */}
            <Stack>{children}</Stack>
        </Panel>
    );
};

export default IdentityPanelView;
