import { getTheme, ITheme } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';

export interface ICallLoadingScreenState {
    theme: ITheme;
    _label: string;
    _description: string;
}

export const useCallLoadingScreenState: (label?: string, description?: string) => ICallLoadingScreenState = (label, description) => {
    const theme = getTheme();
    const _label = label || IvicosStrings.callLoadingScreenLabel;
    const _description = description || IvicosStrings.callLoadingScreenDescription;

    return {
        theme,
        _label,
        _description
    };
};
