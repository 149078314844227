import { createContext } from 'react';
import { TSocketActionState, TSocketContextProps, TSocketInitialState } from 'services/socket-connection/types';

export const defaultSocketContextState: TSocketInitialState = {
    socket: undefined
};

export const socketReducer = (state: TSocketInitialState, action: TSocketActionState): TSocketInitialState => {
    switch (action.type) {
        case 'UPDATE_SOCKET':
            return {
                ...state,
                socket: action.payload
            };
        default:
            return state;
    }
};

const SocketContext = createContext<TSocketContextProps>({
    SocketState: defaultSocketContextState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    SocketDispatch: () => {}
});

export const SocketContextConsumer = SocketContext.Consumer;
export const SocketContextProvider = SocketContext.Provider;

export default SocketContext;
