import { useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';

export interface IInvitationLogoState {
    eventName: string;
    orgId: string;
}

export const useInvitationLogoState: (invitationId: string) => IInvitationLogoState = (invitationId) => {
    const [eventName, setEventName] = useState('');
    const [orgId, setOrgId] = useState('');

    const Invitation = async () => {
        return await idpService.showInvitation(undefined, invitationId);
    };
    Invitation()
        .then((res) => {
            setEventName(res.event_name);
            setOrgId(res.orgId);
        })
        .catch((error) => {
            setEventName(error.response.data.error.event_name);
            setOrgId(error.response.data.error.orgId);
        });

    return {
        eventName,
        orgId
    };
};
