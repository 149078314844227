import { Stack } from '@fluentui/react';
import StatefulButton from 'components/StatefulButton/StatefulButton';
import { Plus } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { ICreateNewRoomButtonState } from './CreateNewRoomButton.state';
import { IC } from 'components/SVGIcon';

const CreateNewRoomButtonView: React.FC<ICreateNewRoomButtonState> = ({ onClick, enableCreateRoomButton }) => {
    return (
        <Stack horizontalAlign="center">
            <StatefulButton
                text={IvicosStrings.createNewRoomActionTitle}
                loadingText={IvicosStrings.createNewRoomActionLoadingTitle}
                successText={IvicosStrings.savingActionSuccessTitle}
                onRenderIcon={() => (
                    <IC variant={enableCreateRoomButton ? 'dark' : 'deactivated'}>
                        <Plus />
                    </IC>
                )}
                onClick={onClick}
                disabled={!enableCreateRoomButton}
            />
        </Stack>
    );
};

export default CreateNewRoomButtonView;
