import { IComboBox } from '@fluentui/react';
import React from 'react';
import ImportedPersonView from './ImportedPerson.view';

export interface IImportedPersonProps {
    indexInCSVDATA: number;
    email: string;
    role: string;
    language: string;
    onChangeImportedPersonData: (
        index: number,
        datatype: number
    ) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | IComboBox>, newValue?: string) => void;
    validateEmail: (value: string) => boolean;
    validateRole: (value: string) => boolean;
    validateLanguage: (value: string) => boolean;
}

const ImportedPerson: React.FC<IImportedPersonProps> = (props) => {
    return (
        <ImportedPersonView
            {...{
                indexInCSVDATA: props.indexInCSVDATA,
                email: props.email,
                role: props.role,
                language: props.language,
                onChangeImportedPersonData: props.onChangeImportedPersonData,
                validateEmail: props.validateEmail,
                validateRole: props.validateRole,
                validateLanguage: props.validateLanguage
            }}
        />
    );
};

export default ImportedPerson;
