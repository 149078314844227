import { IconButton, Modal, PrimaryButton, Stack, Text } from '@fluentui/react';
import { OrganizationDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';

const TrialPeriodAboutToEndModal: React.FC<{ forceOpenModal?: boolean }> = ({ forceOpenModal }) => {
    const [currentOrg, setCurrentOrg] = useState<OrganizationDto | undefined>(undefined);
    const [orgLifeTimeInDays, setOrgLifeTimeInDays] = useState<number>(0);
    const [orgName, setOrgName] = useState<string>('this organization');

    useEffect(() => {
        idpService.showOrg().then((org) => {
            setCurrentOrg(org);
            setOrgName(org.orgName);
            const oneDayTimestamp = 26 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
            setOrgLifeTimeInDays(Math.round((Number(new Date()) - Number(new Date(org.created_at))) / oneDayTimestamp)); // (Today timestamp - creation date timestamp) / onDayTimestamp
        });
    }, []);

    const dismissWarning = () => {
        setCurrentOrg(undefined);
        localStorage.setItem('TrialWarning', new Date().getDate().toString());
        forceOpenModal ? (window.location.href = '/dashboard') : null;
    };

    return (
        <>
            <Modal isOpen={forceOpenModal || (currentOrg && orgLifeTimeInDays > 9 && true)} onDismiss={dismissWarning}>
                <Stack style={{ padding: 5 }}>
                    <IconButton style={{ marginLeft: 'auto' }} iconProps={{ iconName: 'Cancel' }} onClick={dismissWarning} />
                </Stack>
                <Stack horizontalAlign="center" style={{ maxWidth: 400, padding: 26, alignItems: 'start', marginTop: -40 }} tokens={{ childrenGap: 32 }}>
                    <Stack horizontalAlign="center" tokens={{ childrenGap: 6 }} style={{ alignItems: 'start', gap: '20px' }}>
                        <Text variant="xLarge">{IvicosStrings.trialPeriodAboutToExpireTitle}</Text>
                        <Text>
                            {IvicosStrings.trialPeriodAboutToExpireDaysLeft.replace('[org]', orgName).replace('[count]', (14 - orgLifeTimeInDays).toString())}
                        </Text>
                        <Text>{IvicosStrings.removeOrganizationExplanation}</Text>
                        <Text>{IvicosStrings.trialPeriodAboutToExpireQuestion}</Text>
                    </Stack>
                    <Stack style={{ alignSelf: 'end', flexDirection: 'row', gap: '15px' }}>
                        <PrimaryButton text={IvicosStrings.writeUs} onClick={() => (window.location.href = 'mailto:sales@ivicos.eu')} />
                    </Stack>
                </Stack>
            </Modal>
        </>
    );
};

export default TrialPeriodAboutToEndModal;
