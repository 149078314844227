import * as React from 'react';

function SvgMoreStrong(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M24 16c0-1.658 1.342-3 3-3s3 1.342 3 3-1.342 3-3 3-3-1.342-3-3zM13 16c0-1.658 1.342-3 3-3s3 1.342 3 3-1.342 3-3 3-3-1.342-3-3zM2 16c0-1.658 1.342-3 3-3s3 1.342 3 3-1.342 3-3 3-3-1.342-3-3z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgMoreStrong;
