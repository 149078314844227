// import { CommandBarButton, DefaultEffects, IconButton, ITheme, Label, mergeStyles, Stack, Text, TooltipHost } from '@fluentui/react';
import { CommandBarButton, DefaultEffects, IconButton, ITheme, mergeStyles, PrimaryButton, Stack, Text } from '@fluentui/react';
// import { IC } from 'components/SVGIcon';
import React, { useState } from 'react';
// import { Trash } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import Files from 'react-files';
import { campusMediaService } from 'services/api/campus-media.service';
import { getClassNames } from 'styles/responsiveness';
import { IImagePickerImage } from '../ImagePicker.state';

interface IImagePickerModalProps {
    onDismiss: () => void;
    selectedImage: IImagePickerImage;
    imageOptions: IImagePickerImage[];
    onImageSelect: (i: IImagePickerImage) => void;
    onFile: (f: any) => void;
    onDiscardImage: (f: string) => void;
    theme: ITheme;
    smallDevice: boolean;
}

const ImagePickerModal: React.FC<IImagePickerModalProps> = ({
    onDismiss,
    selectedImage,
    // imageOptions,
    onImageSelect,
    onFile,
    // onDiscardImage,
    theme,
    smallDevice
}) => {
    const onCloseToUploadImage = () => onImageSelect({ id: '', url: '' });

    // const [isHoverVisible, setIsHoverVisible] = useState('');

    const dropZoneStyles = mergeStyles({
        background: theme.palette.neutralLight,
        height: '259px',
        width: '500px',
        margin: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        paddingTop: '0.5vh',
        ':hover': {
            background: theme.palette.themeLight
        },
        ':hover > .note': {
            opacity: 1
        },
        boxShadow: DefaultEffects.elevation8
    });

    const dropZoneSmallDeviceStyles = mergeStyles({
        background: theme.palette.neutralLight,
        width: '30vh',
        height: '22vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        paddingTop: '0.5vh',
        ':hover': {
            background: theme.palette.themeLight
        },
        ':hover > .note': {
            opacity: 1
        },
        margin: '20px'
    });

    // const onImageHoverHandler = (id: string) => {
    //     imageOptions.map((img) => (img.id === id ? setIsHoverVisible(id) : null));
    // };

    const [imageID] = useState<string>();

    // const imageIDCollector = async (id: string) => {
    //     await setImageID(id);
    // };

    const { uploadedImage, uploadedImageSmallDevice } = getClassNames();

    const onDiscardFrontImage = async (key: string) => {
        const isSure = confirm(IvicosStrings.deleteImageDescription);
        if (!isSure) return;
        await campusMediaService.deleteImage(key);
        onCloseToUploadImage();
    };

    return (
        <Stack
            style={
                smallDevice
                    ? { maxWidth: 580, flexDirection: 'column', alignItems: 'center' }
                    : { maxWidth: 800, flexDirection: 'column', alignItems: 'center' }
            }
        >
            <Stack
                horizontal
                horizontalAlign="space-between"
                style={smallDevice ? { width: '100%', padding: '16px 16px' } : { width: '100%', padding: '16px 20px' }}
            >
                <Text style={{ fontWeight: 'bold' }} variant={smallDevice ? 'medium' : 'large'}>
                    {IvicosStrings.uploadNewImageTitle}
                </Text>
                <IconButton aria-label="close-button" iconProps={{ iconName: 'Cancel' }} onClick={onDismiss} />
            </Stack>
            <Stack
                grow
                horizontalAlign="center"
                style={smallDevice ? { flexDirection: 'column' } : { flexDirection: 'row', margin: '0px 32px 0px 32px' }}
                tokens={{ childrenGap: 16 }}
            >
                <Stack horizontal={false}>
                    {selectedImage.url != '' && selectedImage.url != 'none' ? (
                        <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={smallDevice ? { width: '100%', padding: 16 } : { width: '100%' }}
                            tokens={{ childrenGap: 16 }}
                        >
                            <Stack horizontalAlign="center" style={smallDevice ? { height: '100px' } : { width: '460px' }}>
                                <img src={selectedImage.url} alt="selected image" className={smallDevice ? uploadedImageSmallDevice : uploadedImage} />
                            </Stack>
                            <Stack
                                horizontal
                                verticalAlign="center"
                                tokens={smallDevice ? { childrenGap: -8 } : { childrenGap: 16 }}
                                style={{ margin: '8px 0px' }}
                            >
                                <CommandBarButton
                                    onClick={onCloseToUploadImage}
                                    text={IvicosStrings.removeImage}
                                    iconProps={{ iconName: 'Clear' }}
                                    style={smallDevice ? { height: 42, fontSize: '12px' } : { height: 42 }}
                                />
                                <Files
                                    onChange={(files) => {
                                        if (files[0]?.size > 2000000) {
                                            alert(IvicosStrings.uploadCapError);
                                            return;
                                        }
                                        onFile(files);
                                    }}
                                    accepts={['image/jpg', 'image/jpeg', 'image/png', 'image/svg']}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    <CommandBarButton
                                        text={smallDevice ? IvicosStrings.uploadActionTitle : IvicosStrings.uploadNewImageActionTitle}
                                        iconProps={{ iconName: 'Upload' }}
                                        style={smallDevice ? { height: 42, fontSize: '12px' } : { height: 42 }}
                                    />
                                </Files>
                                {selectedImage.url != '' && <PrimaryButton text={IvicosStrings.onImagePickerModalClose} onClick={onDismiss} />}
                                {imageID && (
                                    <CommandBarButton
                                        text={IvicosStrings.deleteActionTitle}
                                        iconProps={{ iconName: 'Delete' }}
                                        style={smallDevice ? { height: 42, fontSize: '12px' } : { height: 42 }}
                                        onClick={() => {
                                            onDiscardFrontImage(imageID);
                                        }}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack style={{ width: '100%', margin: '32px, 0px, 32px, 0px' }} horizontalAlign="center">
                            <Files
                                className={smallDevice ? dropZoneSmallDeviceStyles : dropZoneStyles}
                                onChange={(files) => {
                                    if (files[0]?.size > 2000000) {
                                        alert(IvicosStrings.uploadCapError);
                                        return;
                                    }
                                    onFile(files);
                                }}
                                accepts={['image/jpg', 'image/jpeg', 'image/png', 'image/svg']}
                                maxFileSize={10000000}
                                minFileSize={0}
                                clickable
                            >
                                <Stack style={{ width: '80%' }} horizontalAlign="center">
                                    <Text
                                        style={smallDevice ? { padding: 16, fontWeight: 'bolder' } : { padding: 0, fontWeight: 'bolder' }}
                                        className={mergeStyles(['note', { opacity: 1 }])}
                                    >
                                        {IvicosStrings.clickToUploadImage}
                                    </Text>
                                    <Text style={smallDevice ? { padding: 25 } : { padding: 8 }} className={mergeStyles(['note', { opacity: 1 }])}>
                                        {IvicosStrings.uploadCap}
                                    </Text>
                                </Stack>
                            </Files>
                        </Stack>
                    )}
                </Stack>

                {/* Temporarily removed the image pool  */}

                {/* <Stack
                    style={
                        smallDevice
                            ? {
                                  padding: '0px 16px',
                                  width: 270,
                                  backgroundColor: theme.palette.neutralLighter,
                                  borderBottom: '1px ' + theme.palette.neutralQuaternary + ' solid'
                              }
                            : {
                                  width: selectedImage.url != '' && selectedImage.url != 'none' ? '280px' : '210px',
                                  padding: '0px 7px',
                                  backgroundColor: theme.palette.neutralLighter
                              }
                    }
                    className={imagePool}
                >
                    <Stack style={{ width: '100%', marginBottom: '5px' }}>
                        <Label>{IvicosStrings.recentlyUploadedImages}</Label>
                    </Stack>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        wrap
                        styles={
                            !smallDevice
                                ? { inner: { overflowY: 'scroll', overflowX: 'hidden', width: '100%', height: '300px', marginBottom: '20px' } }
                                : { inner: { overflowY: 'scroll', overflowX: 'hidden', width: '100%', height: '150px', marginBottom: '20px' } }
                        }
                        className="iphone-scroll"
                    >
                        {imageOptions.map((img: IImagePickerImage) => (
                            <Stack
                                key={img.id}
                                aria-label={img.id}
                                styles={
                                    smallDevice
                                        ? {
                                              root: {
                                                  padding: '0.5vh',
                                                  cursor: 'pointer',
                                                  width: '100px',
                                                  height: '100px',
                                                  justifyContent: 'center',
                                                  alignItems: 'center'
                                              }
                                          }
                                        : {
                                              root: {
                                                  padding: '0.5vh',
                                                  cursor: 'pointer',
                                                  width: '180px',
                                                  height: '120px',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  border: '2px transparent solid',
                                                  ':hover': {
                                                      border: '2px ' + theme.palette.themePrimary + ' solid'
                                                  },
                                                  position: 'relative'
                                              }
                                          }
                                }
                                onMouseEnter={() => {
                                    onImageHoverHandler(img.id);
                                }}
                                onMouseLeave={() => setIsHoverVisible('')}
                            >
                                <Stack style={smallDevice ? { width: '80px', height: 'fit' } : {}}>
                                    <img
                                        src={img.url}
                                        alt="img"
                                        key={img.id}
                                        onClick={() => {
                                            onImageSelect(img);
                                            imageIDCollector(img.id);
                                        }}
                                        style={smallDevice ? { width: '100%' } : { height: '90px' }}
                                    />
                                    {isHoverVisible === img.id && !smallDevice && (
                                        <Stack
                                            horizontal
                                            styles={{
                                                root: {
                                                    position: 'absolute',
                                                    right: 0,
                                                    padding: '8px',
                                                    top: 0,
                                                    color: theme.palette.red,
                                                    backgroundColor: theme.palette.white,
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.neutralLighter
                                                    },
                                                    '&:active': {
                                                        backgroundColor: theme.palette.neutralLight
                                                    }
                                                }
                                            }}
                                            onClick={() => {
                                                onDiscardImage(img.id);
                                            }}
                                        >
                                            <TooltipHost content={smallDevice ? '' : IvicosStrings.deleteActionTitle}>
                                                <IC variant="custom" iconColor={theme.palette.red} size={15}>
                                                    <Trash />
                                                </IC>
                                            </TooltipHost>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                </Stack> */}
            </Stack>
        </Stack>
    );
};

export default ImagePickerModal;
