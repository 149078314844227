import * as React from 'react';

function SvgViewAll(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.99998 0.786163C4.5672 0.786163 0.386965 5.57234 0.386965 5.57234L0 6L0.386965 6.42767C0.386965 6.42767 4.19806 10.7734 9.26679 11.1731C9.50864 11.2037 9.7505 11.2138 9.99998 11.2138C10.2495 11.2138 10.4913 11.2037 10.7332 11.1731C15.8019 10.7734 19.613 6.42767 19.613 6.42767L20 6L19.613 5.57234C19.613 5.57234 15.4327 0.786163 9.99998 0.786163ZM9.99998 2.08962C11.4358 2.08962 12.7597 2.4817 13.9104 3.00609C14.3253 3.69347 14.5621 4.48525 14.5621 5.34827C14.5621 7.70317 12.7953 9.63796 10.5091 9.89005C10.4964 9.89259 10.4812 9.88744 10.4684 9.89005C10.3131 9.89768 10.1578 9.91039 9.99998 9.91039C9.82688 9.91039 9.65887 9.90022 9.49085 9.89005C7.20465 9.63796 5.43787 7.70317 5.43787 5.34827C5.43787 4.49796 5.667 3.70624 6.06924 3.02649H6.04887C7.2098 2.49187 8.54884 2.08962 9.99998 2.08962ZM9.99998 3.39308C8.92059 3.39308 8.04479 4.26888 8.04479 5.34827C8.04479 6.42767 8.92059 7.30347 9.99998 7.30347C11.0794 7.30347 11.9552 6.42767 11.9552 5.34827C11.9552 4.26888 11.0794 3.39308 9.99998 3.39308ZM4.29735 4.00408C4.19551 4.44198 4.13441 4.88242 4.13441 5.34827C4.13441 6.49134 4.46028 7.56057 5.03054 8.46433C3.38849 7.51476 2.24796 6.38185 1.87372 6C2.18686 5.67922 3.06007 4.82891 4.29735 4.00408ZM15.7026 4.00408C16.9399 4.82891 17.8131 5.67922 18.1263 6C17.752 6.38185 16.6115 7.51476 14.9694 8.46433C15.5397 7.56057 15.8656 6.49134 15.8656 5.34827C15.8656 4.88242 15.8044 4.4369 15.7026 4.00408Z"
                fill="black"
            />
        </svg>
    );
}

export default SvgViewAll;
