import { Stack, Text } from '@fluentui/react';
import React, { useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import campusIcon from 'media/img/campusIcon.png';
import campusLogo from 'media/img/ivcampus-logo.svg';
import ivicosBlack from 'media/img/ivicos-black.svg';
import IvicosStrings from '../kits/language/stringKit';
import { useClassNames } from '../styles/responsiveness';

const Profile: React.FC<{}> = () => {
    const [name, setName] = useState('');
    const [showMinLimit, setShowMinLimit] = useState(false);
    const { ivicosLogo } = useClassNames();

    const onSubmit = async (event: any) => {
        event.preventDefault();
        await idpService.updateUser(name);
        window.location.href = '/welcome';
    };

    return (
        <Stack>
            <Stack
                style={{
                    backgroundColor: '#e4e4e3',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    style={{
                        marginBottom: 53,
                        width: 375,
                        height: 73
                    }}
                >
                    <img src={campusLogo} alt="logo" />
                </Stack>

                <Stack
                    style={{
                        marginBottom: -50,
                        zIndex: 10
                    }}
                >
                    <img src={campusIcon} alt="campus icon" width={91.56} />
                </Stack>

                <Stack
                    style={{
                        height: 425,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack
                        style={{
                            height: 375,
                            width: 375,
                            marginTop: 30
                        }}
                    >
                        <Stack
                            style={{
                                margin: '37px 26px 36px 26px',
                                height: 302,
                                width: 323
                            }}
                        >
                            <Text
                                variant="xxLarge"
                                style={{
                                    marginTop: 0,
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}
                            >
                                {IvicosStrings.profilePageTitle}
                            </Text>

                            <form
                                onSubmit={(event) => {
                                    onSubmit(event);
                                }}
                            >
                                <input
                                    aria-label="input-field-in-profile-page"
                                    type="text"
                                    id="email"
                                    placeholder={IvicosStrings.profilePageTextFieldPlaceholder}
                                    value={name}
                                    required
                                    maxLength={72}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setShowMinLimit(e.target.value.length < 3);
                                    }}
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    onBlur={(e) => {
                                        if (name.length === 0) {
                                            setShowMinLimit(false);
                                        }
                                    }}
                                    style={{
                                        width: 308,
                                        height: 35,
                                        marginTop: 20,
                                        borderRadius: 5,
                                        paddingLeft: 10
                                    }}
                                />
                                {showMinLimit ? <Text style={{ color: 'red' }}>{IvicosStrings.minimumLimit} </Text> : null}

                                <button
                                    type="submit"
                                    style={{
                                        width: 322,
                                        height: 41,
                                        border: 'none',
                                        backgroundColor: '#172D4C',
                                        cursor: 'pointer',
                                        borderRadius: 5,
                                        marginTop: showMinLimit ? 144 : 163
                                    }}
                                >
                                    <Text
                                        variant="large"
                                        style={{
                                            color: '#fff',
                                            cursor: 'pointer',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {IvicosStrings.continue}
                                    </Text>
                                </button>
                            </form>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack
                    style={{
                        marginTop: 80,
                        marginBottom: 15
                    }}
                    className={ivicosLogo}
                >
                    <Stack
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 5
                        }}
                    >
                        <Text
                            variant="small"
                            style={{
                                alignSelf: 'end',
                                fontWeight: 'bold',
                                marginRight: 2
                            }}
                        >
                            {IvicosStrings.developedByTitle1}
                        </Text>
                        <Stack>
                            <img src={ivicosBlack} alt="ivicos-black-logo" width={75} height={20} />
                        </Stack>
                    </Stack>
                    <Text
                        variant="smallPlus"
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        {IvicosStrings.developedByTitle2}
                    </Text>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Profile;
