import { DefaultValue, selectorFamily } from 'recoil';
import { aReceivedMessages } from 'shared-state/notifications/atoms';
import { IReceivedMessage } from 'shared-state/notifications/types';

export const sReceivedMessage = selectorFamily<IReceivedMessage | undefined, string>({
    key: 'sReceivedMessage',
    get:
        (msgId: string) =>
        ({ get }) => {
            const messageList = get(aReceivedMessages);

            return messageList.find((message) => message.id == msgId);
        },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    set:
        (msgId: string) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ set, get }, newValue) => {
            if (!newValue) return;
            if (newValue instanceof DefaultValue) return;

            set(aReceivedMessages, (prevMessageList) => {
                return prevMessageList.map((msg) => {
                    if (msg.id != msgId) return msg;

                    return newValue;
                });
            });
        }
});
