import { AttachmentDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import {
    aCreatedLinkAttachment,
    aLinkAttachmentItemId,
    aLinkAttachmentItemIsPinned,
    aLinkAttachmentModalBox,
    aLinkAttachmentModalValue
} from 'features/LinkAttachmentModalBox/atom';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { resourceService } from 'services/api/resource.service';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { hardcodedAddOns } from '../../../addons';

export interface IAttachmentCreationModalState {
    createAttachment: (addOnName: string, isPinned: boolean | undefined, optionalRef?: string | undefined) => Promise<void>;
    addOns: {
        id: string;
        type: string;
        attributes: {
            urlTemplate: string;
            iconUrl: string;
            displayName: string;
            description: string;
        };
        relationships: {};
    }[];
    translation: (attachmentName: string, attachmentDescription: string) => string[];
    setToggleLinkAttachmentModalBox: any;
    setLinkAttachmentItemId: any;
    linkAttachmentItemIsPinned: boolean;
}

export const useAttachmentCreationModalState: (
    onDismiss?: Function,
    onAttachmentCreation?: (attachment?: AttachmentDto) => void
) => IAttachmentCreationModalState = (onDismiss, onAttachmentCreation) => {
    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();
    const [roomId] = useCurrentRoomId();

    const randomString = (length = 20) => {
        // Declare all characters
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        // Pick characers randomly
        let str = '';
        for (let i = 0; i < length; i++) {
            str += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return str;
    };

    const createAttachment = async (addOnName: string, isPinned?: boolean, optionalRef?: string) => {
        if (!campusId || !areaId || !roomId) return;
        const attachment = await resourceService.createAttachment(
            campusId,
            areaId,
            roomId,
            addOnName,
            optionalRef || randomString(),
            false,
            isPinned ? isPinned : false
        );
        if (addOnName.endsWith('linkmanager')) {
            onDismiss && onDismiss();
            return;
        }

        onAttachmentCreation && onAttachmentCreation(attachment);
    };

    const translation = (attachmentName: string, attachmentDescription: string) => {
        if (attachmentName === 'Link') {
            return [IvicosStrings.linkTypeTitle, IvicosStrings.linkTypeDescription];
        } else if (attachmentName === 'Collaboard') {
            return [IvicosStrings.collaboardTypeTitle, IvicosStrings.collaboardTypeDescription];
        } else if (attachmentName === 'Etherpad') {
            return [IvicosStrings.etherpadTypeTitle, IvicosStrings.etherpadTypeDescription];
        } else if (attachmentName === 'Tldraw') {
            return [IvicosStrings.tldrawTypeTitle, IvicosStrings.tldrawTypeDescription];
        } else if (attachmentName === 'YouTube') {
            return [IvicosStrings.youtubeLinkTypeTitle, IvicosStrings.youtubeLinkTypeDescription];
        } else {
            return [attachmentName, attachmentDescription];
        }
    };

    const addOns = hardcodedAddOns;
    const setToggleLinkAttachmentModalBox = useSetRecoilState<boolean>(aLinkAttachmentModalBox);
    const [createdLinkAttachment, setCreatedLinkAttachment] = useRecoilState(aCreatedLinkAttachment);
    const [linkAttachmentModalValue, setLinkAttachmentModalValue] = useRecoilState(aLinkAttachmentModalValue);
    const [linkAttachmentItemId, setLinkAttachmentItemId] = useRecoilState(aLinkAttachmentItemId);
    const [linkAttachmentItemIsPinned, setLinkAttachmentItemIsPinned] = useRecoilState(aLinkAttachmentItemIsPinned);

    useEffect(() => {
        createdLinkAttachment && createAttachment(linkAttachmentItemId, linkAttachmentItemIsPinned, linkAttachmentModalValue);
        setLinkAttachmentItemIsPinned(false);
        setCreatedLinkAttachment(false);
        setLinkAttachmentModalValue('');
    }, [createdLinkAttachment]);
    return {
        createAttachment,
        addOns,
        translation,
        setToggleLinkAttachmentModalBox,
        setLinkAttachmentItemId,
        linkAttachmentItemIsPinned
    };
};
