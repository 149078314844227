import React from 'react';
import { WaitingAreaMemoComposerView } from './WaitingAreaMemoComposer.view';
import { useWaitingAreaMemoComposerState } from './WaitingAreaMemoComposer.state';

interface IWaitingAreaMemoComposerProps {
    recipientName?: string;
    recipientId?: string;
    sentBy?: string;
    isOpen?: boolean;
    onClose?: () => void;
}

const WaitingAreaMemoComposer: React.FC<IWaitingAreaMemoComposerProps> = ({ recipientName, recipientId, sentBy, isOpen, onClose }) => {
    const state = useWaitingAreaMemoComposerState(recipientName, recipientId, sentBy, onClose);

    return <WaitingAreaMemoComposerView recipientName={recipientName} sentBy={sentBy} isOpen={isOpen} {...state} />;
};

export default WaitingAreaMemoComposer;
