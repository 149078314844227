export const blobToFile = (inputBlob: any, filename: string): File => {
    inputBlob.name = filename;
    inputBlob.lastModifiedDate = new Date();

    return inputBlob;
};

export const centerCropInImage = (img: HTMLImageElement): any => {
    const aspect = 1 / 1;
    const width = img.width / aspect < img.height * aspect ? 100 : ((img.height * aspect) / img.width) * 100;
    const height = img.width / aspect > img.height * aspect ? 100 : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;
    const _crop = {
        unit: '%',
        width,
        height,
        x,
        y,
        aspect
    };
    return _crop;
};

export const getBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result == 'string') resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

export function getCroppedImg(src: string, _crop: any): Promise<string> {
    return new Promise((resolve, reject) => {
        const image = document.createElement('img');

        image.src = src;

        image.crossOrigin = 'Anonymous';
        image.onload = () => {
            const canvas = document.createElement('canvas');

            const crop = {
                width: (image.width * _crop.width) / 100,
                height: (image.height * _crop.height) / 100,
                x: (_crop.x * image.width) / 100,
                y: (_crop.y * image.height) / 100
            };

            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');

            if (ctx == null) return reject(false);

            // New lines to be added
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

            // As Base64 string
            const base64Image = canvas.toDataURL('image/jpeg');
            return resolve(base64Image);

            // As a blob
            /*canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        1
      );*/
        };
    });
}
