import { DefaultEffects, getTheme, PrimaryButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import IvicosStrings from 'kits/language/stringKit';

import { useWindowWidth } from 'shared-state/display-options/hooks';
import { IRoundTableCardProps } from './RoundTableCard';
import { IRoundTableCardStateProps } from './RoundTableCard.state';

export interface IRoundTableCardViewProps extends IRoundTableCardStateProps, IRoundTableCardProps {}

const RoundTableCardView: React.FC<IRoundTableCardViewProps> = (props) => {
    const { onNextClicked, roundDurationInMinutes, isCurrentSpeakerLastSpeaker, roundTimeLeft, isLocalUserCurrentSpeaker, currentSpeakerUserName } = props;

    const pretifyTime: (timeInMs: number) => string = (timeInMs: number) => {
        function pad(n: number, z?: number) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }
        let s = Math.abs(timeInMs);
        const ms = s % 1000;
        s = (s - ms) / 1000;
        const secs = s % 60;
        s = (s - secs) / 60;
        const mins = s % 60;
        const hrs = (s - mins) / 60;
        const timeLeftString = (timeInMs >= 0 ? '' : '-') + pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
        return timeInMs === 0 ? ' ' : timeLeftString;
    };

    const theme = getTheme();

    const { smallDevice } = useWindowWidth();

    return (
        <Stack
            horizontalAlign="center"
            verticalAlign="end"
            style={{
                backgroundColor: theme.palette.white,
                color: theme.palette.black,
                width: '231px',
                position: 'absolute',
                bottom: smallDevice ? '3%' : '',
                left: smallDevice ? '27%' : '24%',
                boxShadow: DefaultEffects.elevation8,
                margin: isLocalUserCurrentSpeaker ? '5px 0px 0px 5px' : ''
            }}
        >
            {isLocalUserCurrentSpeaker ? (
                <Stack horizontal={false} horizontalAlign="center" verticalAlign="center" style={{ margin: '7px 0px' }}>
                    <Stack horizontal={false} horizontalAlign="center" verticalAlign="center" style={{ margin: '3px 0px' }}>
                        <Text variant="mediumPlus" style={{ fontWeight: 'bold' }}>
                            {IvicosStrings.youAreOnTitle}
                        </Text>
                    </Stack>
                    {roundDurationInMinutes > 0.001 && (
                        <Stack horizontal={false} horizontalAlign="center" verticalAlign="center" style={{ margin: '3px 0px' }}>
                            <Text variant="xxLarge">{pretifyTime(roundTimeLeft)}</Text>
                        </Stack>
                    )}
                    <Stack horizontal={true} horizontalAlign="center" verticalAlign="center" style={{ margin: '3px 0px' }}>
                        <Text variant="medium">
                            {isCurrentSpeakerLastSpeaker ? IvicosStrings.youAreTheLastSpeakerTitle : IvicosStrings.clickNextWhenYouAreDoneTitle}
                        </Text>
                    </Stack>
                    <Stack style={{ margin: '7px 0px' }}>
                        <PrimaryButton
                            text={isCurrentSpeakerLastSpeaker ? IvicosStrings.finishActionTitle : IvicosStrings.nextActionTitle}
                            onClick={onNextClicked}
                        ></PrimaryButton>
                    </Stack>
                </Stack>
            ) : (
                <Stack style={{ backgroundColor: theme.palette.white, color: theme.palette.black, margin: '7px 0px' }}>
                    <Stack style={{ margin: '25px 0px' }} horizontal={false} horizontalAlign="center" verticalAlign="center">
                        <Stack style={{ padding: '0px 10px' }}>
                            <Text variant="mediumPlus" style={{ fontWeight: 'bold' }}>
                                {currentSpeakerUserName} {IvicosStrings.isOnTitle}
                            </Text>
                        </Stack>
                        <Stack style={{ margin: '5px 0px' }} horizontal={false} horizontalAlign="center" verticalAlign="center">
                            {' '}
                            {roundDurationInMinutes > 0.001 && <Text variant="xxLarge">{pretifyTime(roundTimeLeft)}</Text>}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default React.memo(RoundTableCardView);
