import { Icon, ITheme, Label, Stack, Text } from '@fluentui/react';
import { localAvailabilities, UserAvailability } from 'kits/availabilityKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { SetterOrUpdater } from 'recoil';

export interface IAvailabilitySelectorViewProps {
    onPanelDismiss: Function;
    theme: ITheme;
    sharedAvailability?: UserAvailability;
    setSharedAvailability: SetterOrUpdater<UserAvailability | undefined>;
}

const AvailabilitySelectorView: React.FC<IAvailabilitySelectorViewProps> = (props) => {
    const { theme, sharedAvailability, setSharedAvailability, onPanelDismiss } = props;

    return (
        <Stack style={{ paddingBottom: 16, paddingTop: 16 }} tokens={{ childrenGap: 8 }}>
            <Stack>
                <Label>{IvicosStrings.statusTabTitle}</Label>
                {localAvailabilities(theme).map((a) => (
                    <Stack
                        className={'pressable'}
                        aria-label="status-item"
                        horizontal
                        key={a.key}
                        style={{
                            color: sharedAvailability == a.key ? theme.palette.themePrimary : theme.palette.black,
                            padding: '8px 8px'
                        }}
                        tokens={{ childrenGap: 8 }}
                        verticalAlign="center"
                        onClick={() => {
                            setSharedAvailability(a.key);
                            onPanelDismiss();
                        }}
                    >
                        <Icon iconName={a.iconName} style={{ color: a.iconColor }} />
                        <Text variant="medium">{a.description}</Text>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export default AvailabilitySelectorView;
