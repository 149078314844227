import { mergeStyles, Panel, PanelType, Stack } from '@fluentui/react';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import DynamicForm from 'components/DynamicForm/DynamicForm';
import TabToStackHorizontally, { TabAttributes } from 'features/RoomList/RoomSettings/TabToStackHorizontally';
import IvicosStrings from 'kits/language/stringKit';
import React, { CSSProperties } from 'react';
import { IRoomSettingsState } from './RoomSettings.state';

export const RoomSettingsView: React.FC<IRoomSettingsState> = ({
    isOpen,
    confirmationTarget,
    setConfirmationTarget,

    getConfirmationTitle,
    getConfirmationDesc,
    onConfirm,
    onPanelDismiss,

    onFormElementChange,
    formFields,
    onRenderPanelFooterContent,

    activeTab,
    setActiveTab
}) => {
    const tabStyle: CSSProperties = { width: '30px', height: '20px' };
    const tabs: TabAttributes[] = [
        { headerText: IvicosStrings.generalTitle, itemKey: 'General', className: 'one-tab', style: tabStyle },
        { headerText: IvicosStrings.accessTitle, itemKey: 'Access', className: 'one-tab', style: tabStyle }
    ];

    const formFieldContainersClassName = mergeStyles({
        '> div': {
            marginBottom: '26px',
            marginRight: '4px'
        },
        '> div:last-child': {
            marginBottom: '0px'
        }
    });

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth="360px"
            isLightDismiss
            onRenderFooterContent={() => onRenderPanelFooterContent()}
            onDismiss={onPanelDismiss}
            headerText={IvicosStrings.roomSettingsPanelTitle}
            aria-label="room-settings-panel"
        >
            <ConfirmationDialog
                target={confirmationTarget}
                title={getConfirmationTitle()}
                desc={getConfirmationDesc()}
                onDismiss={() => setConfirmationTarget('none')}
                onConfirm={onConfirm}
                isOpen={confirmationTarget != 'none'}
                aria-label="room-settings-confirmation-dialog"
            />
            <TabToStackHorizontally activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
            <Stack grow>
                {activeTab === 'General' ? (
                    <Stack grow>
                        <DynamicForm
                            onChange={onFormElementChange}
                            formFields={formFields.filter(
                                (elem) => !['isOpenForVisitors', 'whitelist', 'toggleWhitelist', 'isWhitelistPanelVisible'].includes(elem.key)
                            )}
                        />
                    </Stack>
                ) : (
                    <Stack grow style={{}} className={formFieldContainersClassName}>
                        <DynamicForm onChange={onFormElementChange} formFields={formFields.filter((elem) => elem.key === 'isOpenForVisitors')} />
                        <DynamicForm onChange={onFormElementChange} formFields={formFields.filter((elem) => elem.key === 'isWhitelistPanelVisible')} />
                        <DynamicForm onChange={onFormElementChange} formFields={formFields.filter((elem) => elem.key === 'whitelist')} />
                    </Stack>
                )}
            </Stack>
        </Panel>
    );
};

export default RoomSettingsView;
