import { useCallback, useState } from 'react';

interface ITextFieldWithMaxLengthState {
    onTextLengthChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    secondTextFieldValue: string;
}

export const useTextFieldWithMaxLengthState: (onChange: any, maxLength: number) => ITextFieldWithMaxLengthState = (onChange, maxLength) => {
    const [secondTextFieldValue, setSecondTextFieldValue] = useState('');

    const onTextLengthChange = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue || newValue.length <= maxLength) {
            setSecondTextFieldValue(newValue || '');
            onChange(event, newValue);
        }
    }, []);

    return {
        onTextLengthChange,
        secondTextFieldValue
    };
};
