import React from 'react';
import { useCreateNewRoomButtonState } from './CreateNewRoomButton.state';
import CreateNewRoomButtonView from './CreateNewRoomButton.view';

const CreateNewRoomButton: React.FC<{ enableCreateRoomButton?: boolean }> = ({ enableCreateRoomButton }): any => {
    const state = useCreateNewRoomButtonState(enableCreateRoomButton);

    return (
        <CreateNewRoomButtonView
            {...{
                ...state
            }}
        />
    );
};

export default CreateNewRoomButton;
