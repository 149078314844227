import React from 'react';
import { useSetTopicDialogState } from './SetTopicDialog.state';
import SetTopicDialogView from './SetTopicDialog.view';

const SetTopicDialog: React.FC = () => {
    const state = useSetTopicDialogState();

    return (
        <SetTopicDialogView
            {...{
                ...state
            }}
        />
    );
};
export default SetTopicDialog;
