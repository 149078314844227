import React from 'react';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import AreasPanel from 'features/Areas/AreaPanel/AreaPanel';
import { useHostPerspectiveWaitingAreaState } from './HostPerspectiveWaitingArea.state';
import HostPerspectiveWaitingAreaView from './HostPerspectiveWaitingArea.view';
import IvicosStrings from 'kits/language/stringKit';

const HostPerspectiveWaitingArea = (): any => {
    const state = useHostPerspectiveWaitingAreaState();

    return (
        <>
            {state.isLoadingDialogVisible && <LoadingDialog loadingStrings={[IvicosStrings.loadingTitle]} view="full" typewriter={false} />}
            <AreasPanel />
            <HostPerspectiveWaitingAreaView
                {...{
                    ...state
                }}
            ></HostPerspectiveWaitingAreaView>
        </>
    );
};

export default HostPerspectiveWaitingArea;
