import { mergeStyleSets } from '@fluentui/merge-styles';
import { useTheme } from '@fluentui/react-theme-provider';
import { DefaultEffects } from 'office-ui-fabric-react';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IComponentClassNames {
    pivotDisplaySmallDevice: string;
    pivotDisplayPanel: string;
    burgerMenu: string;
    stackTitleSmallDevice: string;
    campusNameSmallDevice: string;
    areasSearchBar: string;
    areasSearchBarVertical: string;
    areaCreateBtnVertical: string;
    areaCardWidthVertical: string;
    areaCreateBtn: string;
    areaCardWidth: string;
    settingsOwnerCard: string;
    settingsGeneralCard: string;
    colorSelectorWidth: string;
    imagePickerImage: string;
    imagePickerBg: string;
    imagePickerBgVisitorEntrance: string;
    changeLogCard: string;
    uploadedImage: string;
    uploadedImageSmallDevice: string;
    imagePool: string;
    sideBarView: string;
    innerBurgerMenu: string;
}

export const getClassNames = (): IComponentClassNames => {
    const theme = useTheme();
    const { smallDevice, mediumDevice } = useWindowWidth();

    return mergeStyleSets({
        pivotDisplaySmallDevice: {
            display: 'none',
            '@media(min-width:600px)': {
                display: 'flex'
            },
            width: '23%'
        },

        pivotDisplayPanel: {
            width: '20px'
        },

        burgerMenu: {
            display: smallDevice ? 'block' : 'none',
            marginRight: 'auto'
        },
        innerBurgerMenu: {
            display: smallDevice || mediumDevice ? 'block' : 'none',
            marginRight: 'auto'
        },

        stackTitleSmallDevice: {
            display: smallDevice || mediumDevice ? 'flex' : 'block',
            flexDirection: 'column',
            padding: '0px 26px'
        },

        campusNameSmallDevice: {
            '@media(max-width:600px)': {
                display: 'block',
                width: '100%',
                textAlign: 'center'
            },
            '@media(max-width:800px)': {
                display: 'block',
                width: '100%',
                textAlign: 'center'
            }
        },

        areasSearchBar: {
            width: smallDevice || mediumDevice ? '70%' : '25%',
            '@media(max-width:600px)': {
                width: '70%'
                // minWidth: '250px'
            }
        },
        areasSearchBarVertical: {
            width: '100%'
        },
        areaCreateBtn: {
            width: '20%',
            maxWidth: '150px',
            '@media(max-width:600px)': {
                fontSize: '12px',
                width: '138px'
            }
        },
        areaCreateBtnVertical: {
            fontSize: '12px',
            width: '138px'
        },
        areaCardWidth: {
            width: '245px',
            background: '#fff',
            borderRadius: 2,
            overflow: 'hidden',
            marginBottom: !smallDevice ? '1.5%' : '9%',
            marginRight: '1.5%',

            '@media(max-width:600px)': {
                fontSize: '12px',
                width: '100%'
            }
        },
        areaCardWidthVertical: {
            fontSize: '12px',
            width: '92%',
            alignSelf: 'center',
            marginBottom: '9%',
            boxShadow: DefaultEffects.elevation16
        },
        settingsOwnerCard: {
            background: theme.palette.white,
            padding: '16px 26px',
            borderRadius: 2,
            boxShadow: DefaultEffects.elevation4,
            width: 380,
            '@media(max-width:600px)': {
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start'
            }
        },

        settingsGeneralCard: {
            width: '700px',
            padding: '16px 26px',
            background: theme.palette.white,
            borderRadius: 2,
            boxShadow: DefaultEffects.elevation4,
            '@media(max-width:600px)': {
                width: '100%'
            }
        },

        colorSelectorWidth: {
            height: '60px',
            width: 400,
            border: theme.palette.white + ' solid 2px',
            boxShadow: DefaultEffects.elevation4,
            cursor: 'pointer',
            marginRight: '20px',
            '@media(max-width:600px)': {
                width: '100%'
            }
        },

        imagePickerBg: {
            width: '407px',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        },

        imagePickerBgVisitorEntrance: {
            width: '100%',
            height: 123,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: 'contain',
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'
        },

        imagePickerImage: {
            width: '100%',
            height: 123,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            cursor: 'pointer',
            boxShadow: DefaultEffects.elevation4,
            marginRight: smallDevice ? '0px' : '20px'
        },

        uploadedImage: {
            width: '100%',
            marginBottom: 8,
            alignContent: 'center',
            boxShadow: DefaultEffects.elevation8
        },

        uploadedImageSmallDevice: {
            height: '100px',
            marginBottom: 8,
            alignContent: 'center',
            boxShadow: DefaultEffects.elevation8
        },

        imagePool: {
            '@media(max-width:600px)': {
                // heigth: '15vh'
            }
        },

        changeLogCard: {
            width: smallDevice ? '100%' : 'auto'
        },

        sideBarView: {
            width: '100%',
            height: '100%',
            userSelect: 'none',
            padding: '0px 0px 0px 16px',
            backgroundColor: 'transparent'
        }
    });
};

export interface IComponentUseClassNames {
    // Setup complete page
    doodleSetupComplete: string;

    // Welcome page
    welcomePageStyle: string;
    logIntoOrg: string;
    doodleWelcome: string;

    // Profile and Login page
    ivicosLogo: string;

    // Terms and Condition  page
    termsOfServiceTitle: string;
}

export const useClassNames = (): IComponentUseClassNames => {
    return mergeStyleSets({
        // Setup complete page
        doodleSetupComplete: {
            width: 250,
            height: 250,
            marginLeft: 'auto',
            marginRight: 'auto'
        },

        // Welcome page
        welcomePageStyle: {
            alignItems: 'center',
            backgroundColor: '#e4e4e3',
            height: '100vh',

            '@media(max-width:991.98px)': {
                height: '100vh'
            }
        },

        logIntoOrg: {
            padding: 32,
            maxWidth: 360,
            width: '90%',

            '@media(max-width:991.98px)': {
                padding: 15,
                maxWidth: 305
            }
        },

        doodleWelcome: {
            width: 700,
            height: 500,
            position: 'absolute',
            left: 30,
            top: 398,

            '@media(max-width:1699.98px)': {
                left: 0
            },

            '@media(max-width:1399.98px)': {
                width: 500,
                height: 400,
                left: -100,
                top: 347
            },

            '@media(max-width:1199.98px)': {
                width: 500,
                height: 400,
                left: -100,
                top: 347
            },

            '@media(max-width:991.98px)': {
                display: 'none'
            }
        },

        // Profile and Login page
        ivicosLogo: {
            display: 'inherit',
            '@media(max-width:1199px)': {
                display: 'none'
            }
        },

        // Terms and condition page
        termsOfServiceTitle: {
            position: 'fixed',
            width: 520,
            backgroundColor: '#ffffff',
            '@media(max-width:425px)': {
                width: 355
            },
            '@media(max-width:375px)': {
                width: 305
            }
        }
    });
};
