import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgEditImage(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 70 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5.182 0A5.182 5.182 0 000 5.182V46.64a5.183 5.183 0 005.182 5.182H44.05l4.904-4.903h.005l9.228-9.231-10.248-9.421a6.567 6.567 0 00-8.9.01L28.33 38.17 22.523 33.2a6.54 6.54 0 00-8.522.016L5.182 40.81V6.478c0-.715.58-1.296 1.296-1.296H58.3c.715 0 1.296.58 1.296 1.296v29.798l5.182-5.183V5.183A5.183 5.183 0 0059.596 0H5.182zM20.73 10.364a5.182 5.182 0 100 10.365 5.182 5.182 0 000-10.364zm47.086 23.323c-.437.03-.88.229-1.228.577l-1.462 1.445 2.874 2.895 1.435-1.473c.716-.754.772-1.791.056-2.508L69 34.115c-.32-.32-.75-.459-1.186-.428zm-4.077 3.409l-15.4 15.417-1.695 3.733c-.188.434.3.923.734.754l3.866-1.62 15.4-15.4-2.905-2.884z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgEditImage;
