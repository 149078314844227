import { IContextualMenuProps } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import { IMember } from 'features/CampusSettings/CampusSettings.state';
import 'intro.js/introjs.css';
import IvicosStrings from 'kits/language/stringKit';
import { useEffect, useMemo, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { useInvitationLanguage } from 'shared-state/invitationLanguage/hooks';
import { IInvitee, IMembersState } from './Members.types';

export const useMembersState: () => IMembersState = () => {
    const [user, setUser] = useState<IMember>();
    const [emailToInvite, setEmailToInvite] = useState('');
    const [userToDelete, setUserToDelete] = useState<IMember>();
    const [userForMobileModal, setUserForMobileModal] = useState<IMember>();
    const [idClaims] = useIdentityClaims();
    const [isEditUserOpen, { setTrue: openEditUserPanel, setFalse: dismissEditUserPanel }] = useBoolean(false);
    const [members, setMembers] = useState<IMember[]>([]);
    const [filteredMembers, setfilteredMembers] = useState<IMember[]>([]);
    const [invitees, setInvitees] = useState<IInvitee[]>([]);

    const [displayEmailError, setDisplayEmailError] = useState('none');
    const [isText, setIsText] = useState(false);
    const [isThisOrganizationBillingSystem, setIsThisOrganizationBillingSystem] = useState(false);
    const [isAnyoneBillingSystem, setIsAnyoneBillingSystem] = useState(false);
    const [isUserFromOtherOrgBillingList, setIsUserFromOtherOrgBillingList] = useState(false);
    const [isInvitePanelOpen, { setTrue: openInvitePanel, setFalse: dismissInvitePanel }] = useBoolean(false);
    const [changes, setChanges] = useState<any>({});

    const [hideInviteDialog, { toggle: togglehideInviteDialog }] = useBoolean(true);
    const [hideReinviteDialog, { toggle: togglehideReinviteDialog }] = useBoolean(true);
    const [hideAddAnyoneToYourBillingList, { toggle: toggleHideAddAnyoneToYourBillingList }] = useBoolean(true);
    const [hideUserExistsDialog, { toggle: togglehideUserExistsDialog }] = useBoolean(true);
    const [hideDeleteDialog, { toggle: togglehideDeleteDialog }] = useBoolean(true);
    const [hideNoRoleEnteredDialog, { toggle: togglehideNoRoleEnteredDialog }] = useBoolean(true);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [invitationLanguageChoice, setInvitationLanguageChoice] = useInvitationLanguage();

    const inviteeToMember = (invitee: IInvitee) => {
        const userId = invitee.role === 'inactive' ? 'inactive-invitee' : 'invitee';
        const role = invitee.role === 'inactive' ? 'inactive' : 'invited';

        return {
            ...invitee,
            ...{ name: IvicosStrings.pendingInviteTitle, profilePic: '', userId: userId, role: role }
        };
    };

    const _members = useMemo(
        () => [...invitees.filter((invitee) => !invitee.email.includes('microsoft.tenant')).map((invitee) => inviteeToMember(invitee)), ...members],
        [members, invitees]
    );
    const tenantIdEmail = idClaims?.msTenantId + '@microsoft.tenant';

    const isMicrosoftUser = idClaims?.msTenantId ? true : false;
    const microsoftTenantIsInvited = invitees.find((invitee) => invitee.email.includes(tenantIdEmail)) ? true : false;

    const setMicrosoftTenantIsInvited = async (isInvited: boolean) => {
        try {
            if (isInvited) {
                await idpService.newInvited(tenantIdEmail, 'user', invitationLanguageChoice);
            } else {
                await idpService.deleteInvited(tenantIdEmail);
            }
        } catch (err) {
            console.log(err);
        }
        fetchUsers();
    };

    const fetchUsers = async () => {
        const fetchedMembers = await idpService.showMembers();
        const fetchedInvitees = await idpService.showOrgInvited();
        setMembers(fetchedMembers);
        setInvitees(fetchedInvitees);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const filterUsers = (text: string) => {
        if (text.includes('+') || text.includes('[') || text.includes('(') || text.includes(')') || text.includes('?')) return;
        const regex = new RegExp(text.toUpperCase());
        text.length > 0 ? setIsText(true) : setIsText(false);
        const result: any = _members.filter((member) => regex.test(member.email.toUpperCase()) || regex.test(member.name.toUpperCase()));
        setfilteredMembers(result);
    };

    const userFormForUser: (user: IMember | undefined) => IDynamicFormElementProps[] = (user) => {
        return [
            { key: 'name', label: user?.name, value: user?.name, type: 'section-heading' },
            { key: 'email', label: user?.email, value: user?.email, type: 'section-heading' },
            {
                key: 'role',
                label: IvicosStrings.roleColumnTitle,
                value: {
                    selectedKey: user?.role,
                    options: [
                        { key: 'user', text: IvicosStrings.userRoleTitle },
                        { key: 'manager', text: IvicosStrings.managerRoleTitle }
                    ]
                },
                type: 'dropdown'
            }
        ];
    };

    const [editUserForm, setEditUserForm] = useState<IDynamicFormElementProps[]>(userFormForUser(user));

    useEffect(() => {
        setEditUserForm(userFormForUser(user));
    }, [user]);

    const onChange = (change: { [index: string]: string | boolean }) => {
        setChanges((prevState: any) => {
            const newState = { ...prevState, ...change };
            return newState;
        });
    };

    const onEditUserFormChange = (key: string, newValue: any) => {
        if (key == 'role') {
            onChange({ [key]: newValue.selectedKey });
        } else {
            onChange({ [key]: newValue });
        }
        setEditUserForm((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };

    const onUpdateUser = (userId: string, changes: any): Promise<any | undefined> => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            if (!user) return resolve(undefined);
            idpService.changeMemberRole(changes.role, user.userId).then(() => fetchUsers());
        });
    };

    const [userValue, setUserValue] = useState<'user' | 'manager' | undefined>('user');

    const dropdownUserValues = [
        { key: 'user', text: IvicosStrings.userRoleTitleInCap },
        { key: 'manager', text: IvicosStrings.managerRoleTitleInCap }
    ];

    const dropdownLanguageValues = [
        { key: 'en', text: 'English' },
        { key: 'de', text: 'Deutsch' }
    ];

    const singleInvitationUserRoleHandler = (e: any, item: any) => {
        setUserValue(item.key);
    };

    const singleInvitationUserLanguageHandler = (e: any, item: any) => {
        setInvitationLanguageChoice(item.key);
    };

    const inviteUser = () => {
        const memberEmails = members.map((a) => a.email);
        if (validateEmail(emailToInvite)) {
            setDisplayEmailError('none');
            if (!memberEmails.includes(emailToInvite) && userValue) {
                idpService.newInvited(emailToInvite, userValue, invitationLanguageChoice).then((res) => {
                    const needSponsor = res.find((item) => item.email === emailToInvite)?.need_sponsor;
                    setIsUserFromOtherOrgBillingList(!needSponsor);
                    togglehideInviteDialog();
                });
            } else {
                togglehideUserExistsDialog();
            }
        } else {
            setDisplayEmailError('');
        }
        fetchUsers();
    };

    const reInviteUser = () => {
        const memberEmails = members.map((a) => a.email);
        if (validateEmail(emailToInvite)) {
            setDisplayEmailError('none');
            if (!memberEmails.includes(emailToInvite) && userValue) {
                const needSponsor = invitees.find((invitee) => invitee.email === emailToInvite)?.need_sponsor;
                idpService.newInvited(emailToInvite, userValue, invitationLanguageChoice);
                setIsUserFromOtherOrgBillingList(!needSponsor);
                togglehideInviteDialog();
            } else {
                togglehideUserExistsDialog();
            }
        } else {
            setDisplayEmailError('');
        }
        fetchUsers();
    };

    const emailHasSponsor = async () => {
        const memberEmails = members.map((a) => a.email);
        if (validateEmail(emailToInvite)) {
            setDisplayEmailError('none');
            try {
                if (!memberEmails.includes(emailToInvite) && userValue) {
                    const hasSponsor = await idpService.emailHasSponsor(emailToInvite);
                    return hasSponsor.email_has_sponsor;
                } else {
                    togglehideUserExistsDialog();
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setDisplayEmailError('');
            return;
        }
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const deleteUser = async () => {
        if (userToDelete) {
            if (userToDelete.userId != 'invitee' && userToDelete.userId != 'inactive-invitee') {
                await idpService.removeMember(userToDelete.userId);
            } else {
                idpService.deleteInvited(userToDelete.email);
            }
        }
        await fetchUsers();
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'thisOrganization',
                text: IvicosStrings.inviteMemberLabelTitle,
                iconProps: { iconName: 'AddHome' },
                onClick: () => {
                    openInvitePanel();
                    setIsThisOrganizationBillingSystem(true);
                    setIsAnyoneBillingSystem(false);
                    localStorage.setItem('isAnyoneBillingSystem', 'false');
                }
            },
            {
                key: 'anyone',
                text: IvicosStrings.inviteAnyoneLabelTitle,
                iconProps: { iconName: 'Globe' },
                onClick: () => {
                    openInvitePanel();
                    setIsAnyoneBillingSystem(true);
                    setIsThisOrganizationBillingSystem(false);
                    localStorage.setItem('isAnyoneBillingSystem', 'true');
                }
            }
        ]
    };

    return {
        isEditUserOpen,
        dismissEditUserPanel,
        editUserForm,
        onEditUserFormChange,
        user,
        changes,
        onUpdateUser,
        setChanges,
        filterUsers,
        filteredMembers,
        setEmailToInvite,
        inviteUser,
        reInviteUser,
        emailHasSponsor,
        _members,
        emailToInvite,
        displayEmailError,
        deleteUser,
        userForMobileModal,
        setUserToDelete,
        setUser,
        openEditUserPanel,
        isInvitePanelOpen,
        dismissInvitePanel,
        isText,
        setUserForMobileModal,
        microsoftProps: {
            isMicrosoftUser,
            microsoftTenantIsInvited,
            setMicrosoftTenantIsInvited
        },
        hideInviteDialog,
        hideReinviteDialog,
        hideUserExistsDialog,
        hideDeleteDialog,
        hideNoRoleEnteredDialog,
        togglehideInviteDialog,
        togglehideReinviteDialog,
        togglehideUserExistsDialog,
        togglehideDeleteDialog,
        togglehideNoRoleEnteredDialog,
        isModalOpen,
        showModal,
        hideModal,
        dropdownUserValues,
        singleInvitationUserRoleHandler,
        setUserValue,
        userValue,
        dropdownLanguageValues,
        singleInvitationUserLanguageHandler,
        isThisOrganizationBillingSystem,
        isAnyoneBillingSystem,
        menuProps,
        isUserFromOtherOrgBillingList,
        setIsUserFromOtherOrgBillingList,
        hideAddAnyoneToYourBillingList,
        toggleHideAddAnyoneToYourBillingList
    };
};
