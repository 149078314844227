import { useBoolean } from '@fluentui/react-hooks';
import { IParsedInvitation } from 'features/Header/children/CreateInvitationPanel/Data';
import { getShortMonth, getWeekDay } from 'features/Header/children/CreateInvitationPanel/hooks';
import { sendEvent } from 'kits/eventKit';
import IvicosStrings from 'kits/language/stringKit';
import { useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { resourceService } from 'services/api/resource.service';
import { useCurrentCampusId } from 'shared-state/location/hook';

export interface IInvitationsCardState {
    onDelete: () => Promise<void>;
    cardIsOpen: boolean;
    setCardIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    roomName: string;
    selectedDay: string;
    from1: string;
    to1: string;
    from2: string;
    to2: string;
    specifyRoomName: () => void;
    retrievingDay: () => void;
    retrievingTime: (data: any) => void;
    retrievingShortMonth: (data: any) => void;
    buttonStatus: number;
    copyButtonContent: {
        [index: number]: string;
    };
    copyCopyButtonValueToClipboard: (value: string) => void;
    copyValue: string;
    hideDialog: boolean;
    toggleHideDialog: () => void;
    invitationLinkValue: any;
}

export const useInvitationsCardState: (data: IParsedInvitation, isNewCreated: boolean | undefined) => IInvitationsCardState = (data, isNewCreated) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const currentCampusId = useCurrentCampusId();
    const [cardIsOpen, setCardIsOpen] = useState<boolean>(isNewCreated ? true : false);
    const [roomName, setRoomName] = useState<string>('');
    const [selectedDay, setSelectedDay] = useState<string>('');
    const [from1, setFrom1] = useState<string>('');
    const [to1, setTo1] = useState<string>('');
    const [from2, setFrom2] = useState<string>('');
    const [to2, setTo2] = useState<string>('');

    const invitationLinkValue = data.customUrl ? data.customUrl : data.invitationId;
    const copyValue =
        window.location.host.split(':')[0] === 'localhost'
            ? `http://${window.location.host}/i/${invitationLinkValue}`
            : `https://${window.location.host}/i/${invitationLinkValue}`;

    const [buttonStatus, setButtonStatus] = useState<number>(1);

    const copyButtonContent: { [index: number]: string } = {
        0: IvicosStrings.copyFailedActionTitle,
        1: IvicosStrings.copyActionTitle,
        2: IvicosStrings.copiedActionTitle
    };

    const copyCopyButtonValueToClipboard = (value: string) => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                setButtonStatus(2);
                setTimeout(() => setButtonStatus(1), 1000);
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .catch((err) => {
                setButtonStatus(0);
                setTimeout(() => setButtonStatus(1), 1500);
            });
    };

    const specifyRoomName = async () => {
        if (currentCampusId[0] && data.area) {
            const specificRoom = await resourceService.getRoomById(currentCampusId[0], data.area, data.room);
            if (specificRoom) {
                setRoomName(specificRoom.name);
            }
        }
    };

    const retrievingDay = () => {
        setSelectedDay(getWeekDay(data));
    };

    const retrievingShortMonth = (data: IParsedInvitation) => {
        setFrom1(getShortMonth(data.validFromDate));
        setTo1(getShortMonth(data.validToDate));
    };

    const retrievingTime = (data: any) => {
        const { validFromDate, validToDate } = data;

        if (validFromDate) {
            setFrom2(extractTimeFromDate(validFromDate.toString()).substring(4));
        }
        if (validToDate) {
            setTo2(extractTimeFromDate(validToDate.toString()).substring(4));
        }
    };

    const extractTimeFromDate = (value: any) => {
        const val = value.split(' GMT')[0].split(' ').slice(1, 5);
        if (val) {
            val.splice(2, 1, IvicosStrings.at);
        }
        const newValue = val.join(' ').substring(0, 15);

        return newValue;
    };

    const onDelete = async () => {
        try {
            toggleHideDialog();
            await idpService.deleteInvitation(data.invitationId);
            sendEvent('refetchInvitations', {});
        } catch (e) {
            console.log('Error');
        }
    };

    return {
        onDelete,
        cardIsOpen,
        setCardIsOpen,
        roomName,
        selectedDay,
        from1,
        to1,
        from2,
        to2,
        specifyRoomName,
        retrievingDay,
        retrievingTime,
        retrievingShortMonth,
        buttonStatus,
        copyButtonContent,
        copyCopyButtonValueToClipboard,
        copyValue,
        hideDialog,
        toggleHideDialog,
        invitationLinkValue
    };
};
