import { Persona } from '@fluentui/react';
import React from 'react';
import UserVirtualModerator from './UserVirtualModerator';

export interface IUserPersonaProps {
    sizePersona: number;
    imageUrl?: string;
    text?: string;
    secondaryText?: string;
    key?: string;
    hidePersonaDetails?: boolean;
    presence?: any;
    ariaLabel?: string;
    avatarType?: string;
    talkTimeColor?: string;
}

const UserPersonaView: React.FC<IUserPersonaProps> = (props) => {
    const { sizePersona, imageUrl, text, secondaryText, key, hidePersonaDetails, presence, ariaLabel, avatarType } = props;
    return (
        <>
            {!avatarType || avatarType === 'profile' ? (
                <Persona
                    size={sizePersona}
                    imageUrl={imageUrl}
                    text={text}
                    secondaryText={secondaryText}
                    key={key}
                    hidePersonaDetails={hidePersonaDetails}
                    presence={presence && presence}
                    aria-label={ariaLabel}
                />
            ) : (
                <UserVirtualModerator
                    {...{
                        text: props.text,
                        talkTimeColor: props.talkTimeColor,
                        avatarType: props.avatarType
                    }}
                />
            )}
        </>
    );
};

export default UserPersonaView;
