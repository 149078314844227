import { IResource } from 'kits/apiKit3/legacy';
import React from 'react';
import { ISpeakerStatsSpeaker } from './children/Data';
import useRoundTableStateManager from './RoundTableStateManager';
import { useSpeakerStatsState } from './SpeakerStats.state';
import SpeakerStatsView from './SpeakerStats.view';

export interface ISpeakerStatsProps {
    stats: ISpeakerStatsSpeaker[];
    statsAreVisible: boolean;
    executeCommand: (command: string, data?: any) => void;
    roundTableBorder: string;
    setRoundTableBorder: React.Dispatch<React.SetStateAction<string>>;
    isLocalUserHandRaised: boolean;
    onHandRaiseDismiss: () => void;
    room: IResource;
}

const SpeakerStats: React.FC<ISpeakerStatsProps> = (props) => {
    const state = useSpeakerStatsState(props.stats, props.setRoundTableBorder, props.executeCommand, props.statsAreVisible, props.room.id);
    const roundTableState = useRoundTableStateManager(props.room.id, state.prettifyTime, state.setIsRoundTableTimerActivated);

    return (
        <SpeakerStatsView
            {...{
                ...state,
                ...roundTableState,
                ...props
            }}
        />
    );
};
export default SpeakerStats;
