import * as React from 'react';

function SvgEditIcon(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.303 0c-.43 0-.86.167-1.195.501L.604 7.005l-.026.129-.45 2.262L0 10l.604-.129 2.262-.45.129-.025 6.504-6.504a1.676 1.676 0 000-2.39A1.685 1.685 0 008.303 0zm0 .784c.208 0 .416.095.617.296.4.4.4.834 0 1.234l-.295.282-1.221-1.22.282-.296c.201-.201.41-.296.617-.296zm-1.478 1.17l1.221 1.22L3.072 8.15a2.802 2.802 0 00-1.221-1.221l4.974-4.974zM1.324 7.596c.493.2.88.587 1.08 1.08l-1.35.27.27-1.35z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgEditIcon;
