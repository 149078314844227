import { Stack } from '@fluentui/react';
import RoomView from 'features/Rooms/RoomView/RoomView';
import React from 'react';
import { IHostPerspectiveWaitingAreaState } from './HostPerspectiveWaitingArea.state';
import { RoomSelectionDialog } from './RoomSelectionDialog/RoomSelectionDialog';
import NotificationsLayer from 'features/NotificationsLayer/NotificationsLayer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HostPerspectiveWaitingAreaView: React.FC<IHostPerspectiveWaitingAreaState> = (props) => {
    return (
        <Stack grow horizontal tokens={{ childrenGap: 16 }} style={{ padding: 16 }}>
            <NotificationsLayer />
            <RoomSelectionDialog />

            <RoomView mobileMenuOpen={false} />
        </Stack>
    );
};

export default HostPerspectiveWaitingAreaView;
