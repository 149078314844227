import { ActionButton, DefaultButton, Label, Persona, PersonaSize, PrimaryButton, Stack, Text, TextField, getIcon, registerIcons } from '@fluentui/react';
import Badge from 'components/Badge';
import ProfileImageUploader from 'features/Header/children/ProfileImageUploader/ProfileImageUploader';
import { availabilityToPersonaPresence } from 'kits/availabilityKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { IUserProfileState } from './UserProfile.State';
import SvgVideoBackground from 'kits/IconKit2/VideoBackground';

const UserProfileView: React.FC<IUserProfileState> = (props) => {
    const {
        localProfile,
        identityClaims,
        sharedAvailability,
        showProfileImageEditingPanel,
        setShowProfileImageEditingPanel,
        changeName,
        setChangeName,
        userName,
        handleName,
        handleSubmitClick,
        handleCancelClick,
        showMinLimitText
    } = props;

    if (!getIcon('toggleBackground'))
        registerIcons({
            icons: {
                toggleBackground: <SvgVideoBackground />
            }
        });

    return (
        <>
            <Stack tokens={{ childrenGap: 15 }} style={{ marginTop: 30 }}>
                {localProfile?.type === 'user' ? (
                    <>
                        <Stack horizontal={false} horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                            <Persona
                                imageUrl={localProfile?.profileImage}
                                presence={sharedAvailability && availabilityToPersonaPresence(sharedAvailability)}
                                size={PersonaSize.size120}
                                hidePersonaDetails={true}
                            ></Persona>
                            <Text variant="xLarge" style={{ textAlign: 'center' }}>
                                {userName || localProfile?.displayName}
                            </Text>
                            <Text>{identityClaims?.upn}</Text>
                        </Stack>
                    </>
                ) : (
                    <Stack horizontal verticalAlign="center" style={{ padding: '10px' }}>
                        <Badge text={localProfile?.displayName} size="81" fontSize="medium" />
                        <Stack verticalAlign="center" style={{ width: '200px', marginLeft: '15px' }}>
                            <Text variant="xLarge">{localProfile?.displayName}</Text>
                            <Text>{identityClaims?.upn}</Text>
                        </Stack>
                    </Stack>
                )}
                {changeName ? (
                    <Stack verticalAlign="center" style={{ height: '100px' }}>
                        <Stack>
                            <TextField
                                style={{ textAlign: 'center' }}
                                placeholder={IvicosStrings.editNamePlaceholder}
                                defaultValue={localProfile?.displayName}
                                onChange={(ev, newValue) => {
                                    handleName(newValue);
                                }}
                                maxLength={50}
                                data-test-id="edit-name-input-field" //doesn't detect aria-label
                            />

                            {showMinLimitText && (
                                <Text style={{ color: 'red' }} variant="small">
                                    {IvicosStrings.editNameErrorMessage}
                                </Text>
                            )}

                            <Stack horizontal horizontalAlign="space-between" verticalAlign="end" style={{ height: '100%' }}>
                                <DefaultButton
                                    style={{ marginTop: 5, width: 120, borderRadius: 4 }}
                                    text={IvicosStrings.cancelActionTitle}
                                    onClick={handleCancelClick}
                                    ariaLabel={'edit-name-cancel-btn'}
                                />
                                <PrimaryButton
                                    style={{ marginTop: 5, width: 120, borderRadius: 4 }}
                                    text={IvicosStrings.saveActionTitle}
                                    onClick={handleSubmitClick}
                                    ariaLabel={'edit-name-submit-btn'}
                                    disabled={showMinLimitText}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack horizontal={false} style={{ height: '100px' }}>
                        <Label disabled={localProfile?.type !== 'user'}>{IvicosStrings.appearenceModification}</Label>
                        <ActionButton
                            key={'edit-profileImage'}
                            iconProps={{
                                iconName: 'EditContact'
                            }}
                            onClick={() => {
                                setShowProfileImageEditingPanel(true);
                            }}
                            disabled={localProfile?.type !== 'user'}
                            text={IvicosStrings.updateImageButtonLabel}
                        />
                        <ActionButton
                            key={'edit-profileName'}
                            iconProps={{
                                iconName: 'Edit'
                            }}
                            onClick={() => setChangeName(!changeName)}
                            disabled={localProfile?.type !== 'user'}
                            text={IvicosStrings.editNameButtonLabel}
                        />
                    </Stack>
                )}
            </Stack>

            {showProfileImageEditingPanel && (
                <ProfileImageUploader
                    onClose={() => {
                        setShowProfileImageEditingPanel(false);
                    }}
                />
            )}
        </>
    );
};

export default UserProfileView;
