import { ICrossUserProfile } from 'features/Areas/AreaSwitch/CrossAreaDirectMessaging/dataTypes';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { aDirectMessageUserList, aUserList, aVisitorList } from 'shared-state/presence/atoms';
import {
    sCrossAreaUser,
    sCrossAreaUsersWithIDs,
    sUser,
    sUserFromSpeakerStats,
    sUsers,
    sUsersInRoom,
    sUsersWithIDs,
    sVisitorEntranceUser
} from 'shared-state/presence/selectors';

export const useUserList = (): any => {
    return useRecoilState(aUserList);
};

export const useDirectMessageUserList = (): [ICrossUserProfile[], SetterOrUpdater<ICrossUserProfile[]>] => {
    return useRecoilState(aDirectMessageUserList);
};
export const useVisitorList = (): any => {
    return useRecoilState(aVisitorList);
};

export const useUsers = (): any => {
    return useRecoilValue(sUsers);
};

export const useUser = (userId: string): any => {
    return useRecoilValue(sUser(userId));
};

export const useCrossAreaUser = (userId: string): any => {
    return useRecoilValue(sCrossAreaUser(userId));
};
export const useVisitorEntranceUser = (userId: string): any => {
    return useRecoilValue(sVisitorEntranceUser(userId));
};

export const useUserFromSpeakerStats = (userName: string): any => {
    return useRecoilValue(sUserFromSpeakerStats(userName));
};

export const useUsersInRoom = (roomId: string): any => {
    return useRecoilValue(sUsersInRoom(roomId));
};

export const useUsersWithIDs = (userIds: string[]): any => {
    return useRecoilValue(sUsersWithIDs(userIds));
};
export const useCrossAreaUsersWithIDs = (userIds: string[]): any => {
    return useRecoilValue(sCrossAreaUsersWithIDs(userIds));
};
