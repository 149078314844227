import { Callout, DefaultButton, DelayedRender, DirectionalHint, getTheme, Label, mergeStyleSets, Modal, Stack, Text } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { IC } from 'components/SVGIcon';
import { QuestionCircle } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import imageBg from 'media/img/imageBg.png';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { getClassNames } from 'styles/responsiveness';
import { IImagePickerImage } from '../ImagePicker.state';
import ImagePickerModal from './ImagePickerModal';

interface ISettingsImagePickerViewProps {
    onDismiss: () => void;
    modalIsOpen: boolean;
    selectedImage: IImagePickerImage;
    imageOptions: IImagePickerImage[];
    onImageSelect: (i: IImagePickerImage) => void;
    onFile: (f: any) => void;
    onModalOpen: () => void;
    onDiscardImage: (f: string) => void;
    label?: string;
    callout?: string;
    buttonText?: string;
}

const SettingsImagePickerView: React.FC<ISettingsImagePickerViewProps> = ({
    onModalOpen,
    onDismiss,
    imageOptions,
    modalIsOpen,
    selectedImage,
    onImageSelect,
    onFile,
    label,
    onDiscardImage,
    callout,
    buttonText
}) => {
    const theme = getTheme();

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const directionalHint = DirectionalHint.rightCenter;
    const buttonId = useId('callout-button');

    const styles = mergeStyleSets({
        button: {
            width: 130
        },
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const { imagePickerBg, imagePickerImage } = getClassNames();
    const { smallDevice } = useWindowWidth();

    return (
        <>
            {label && <Label style={{}}>{label}</Label>}
            <Stack>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 32 }}>
                    <Stack aria-label={'backgroundImagePickerPanel'} horizontal style={smallDevice ? { width: '100%' } : { width: '430px' }}>
                        <Stack
                            grow
                            style={{
                                backgroundImage: `url('${imageBg}')`
                            }}
                            className={imagePickerImage}
                            onClick={onModalOpen}
                        >
                            <Stack
                                grow
                                style={{
                                    backgroundImage: `url('${selectedImage.url}')`
                                }}
                                className={imagePickerBg}
                            >
                                {selectedImage.url == '' && <Text>{IvicosStrings.imageTitle}</Text>}
                            </Stack>
                        </Stack>

                        {!smallDevice && (
                            <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                                <QuestionCircle onClick={toggleIsCalloutVisible} id={buttonId} />
                            </IC>
                        )}
                    </Stack>

                    {isCalloutVisible && (
                        <Callout
                            className={styles.callout}
                            onDismiss={toggleIsCalloutVisible}
                            role="status"
                            target={`#${buttonId}`}
                            aria-live="assertive"
                            directionalHint={directionalHint}
                        >
                            <DelayedRender>
                                <Text variant="small">{callout}</Text>
                            </DelayedRender>
                        </Callout>
                    )}

                    {!smallDevice && (
                        <Stack style={{ flexDirection: 'column', marginLeft: 'auto', width: '23%' }}>
                            <DefaultButton text={buttonText} iconProps={{ iconName: 'edit' }} onClick={onModalOpen}></DefaultButton>
                        </Stack>
                    )}
                </Stack>

                <Modal isOpen={modalIsOpen} onDismiss={onDismiss}>
                    <ImagePickerModal
                        {...{
                            onDismiss,
                            selectedImage,
                            imageOptions,
                            onImageSelect,
                            onFile,
                            onDiscardImage,
                            theme,
                            smallDevice
                        }}
                    />
                </Modal>
            </Stack>
        </>
    );
};

export default SettingsImagePickerView;
