import { getTheme, Icon, PrimaryButton, Stack, Text } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React, { useState } from 'react';
import Files from 'react-files';
import { idpService } from 'services/api/identity-provider.service';
import { IImportCSVStepProps } from './ImportCSVStep';

const ImportCSVStepView: React.FC<IImportCSVStepProps> = (props) => {
    const { onFileUpload } = props;
    const theme = getTheme();
    const [language, setLanguage] = useState('');
    const getUserLanguage = async () => {
        try {
            const response = await idpService.getUser();
            const Language = response.preferred_language;
            setLanguage(Language);
        } catch (error) {
            console.log(error);
        }
    };
    getUserLanguage();
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack tokens={{ childrenGap: 32 }} style={{ margin: '16px 0px' }}>
                <Text>
                    <span style={{ fontWeight: 'bold' }}>{IvicosStrings.bulkInviteStep1Title}</span> {IvicosStrings.bulkInviteStep1Description}
                </Text>
                {language === 'en' ? (
                    <a
                        href={
                            'data:text/csv;charset=utf-8,' +
                            encodeURIComponent('EMAIL;ROLE;LANGUAGE\nmax.mustermann@example.org;User;English\nmax.mustermann@example.org;Manager;Deutsch')
                        }
                        download="template.csv"
                    >
                        <PrimaryButton iconProps={{ iconName: 'Download' }} text={IvicosStrings.downloadCSVTemplateFileLabel} />
                    </a>
                ) : (
                    <a
                        href={
                            'data:text/csv;charset=utf-8,' +
                            encodeURIComponent('EMAIL;ROLLE;SPRACHE\nmax.mustermann@example.org;Mitglied;English\nmax.mustermann@example.org;Manager;Deutsch')
                        }
                        download="template.csv"
                    >
                        <PrimaryButton iconProps={{ iconName: 'Download' }} text={IvicosStrings.downloadCSVTemplateFileLabel} />
                    </a>
                )}
                <Text>
                    <span style={{ fontWeight: 'bold' }}>{IvicosStrings.bulkInviteStep2Title}</span> {IvicosStrings.bulkInviteStep2Description}
                </Text>
            </Stack>
            <Files className="file-upload" onChange={(files: File[]) => onFileUpload(files[0])} accepts={['text/csv']} minFileSize={0} clickable>
                <Stack
                    horizontalAlign="center"
                    verticalAlign="center"
                    tokens={{ childrenGap: 16 }}
                    styles={{
                        root: {
                            background: theme.palette.neutralLighter,
                            minHeight: 320,
                            cursor: 'pointer',
                            borderRadius: 2,
                            '&:hover': { background: theme.palette.neutralLight }
                        }
                    }}
                >
                    <Icon iconName="OpenFile" style={{ fontSize: 64 }} />
                    <Text variant="medium" style={{ marginLeft: 15 }}>
                        {IvicosStrings.ImportCSVFilePlaceholder}
                    </Text>
                </Stack>
            </Files>
        </Stack>
    );
};

export default ImportCSVStepView;
