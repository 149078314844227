import React from 'react';
import { useShowAreaPanel } from 'shared-state/display-options/hooks';
import AreasPanelView from './AreaPanelView';

const AreasPanel: React.FC<{}> = () => {
    const [showPanel, setShowPanel] = useShowAreaPanel();

    return <AreasPanelView showPanel={showPanel} setShowPanel={setShowPanel}></AreasPanelView>;
};

export default AreasPanel;
