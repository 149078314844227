import React from 'react';
import ConfirmationDialogView from './ConfirmationDialog.view';

export interface IConfirmationDialogProps {
    title: string;
    desc: string;
    confirmText?: string;
    cancelText?: string;
    onConfirm: (target: string) => void;
    onDismiss: (target: string) => void;
    target: string;
    isOpen?: boolean;
}

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = (props) => {
    return (
        <>
            <ConfirmationDialogView
                {...{
                    title: props.title,
                    desc: props.desc,
                    confirmText: props.confirmText,
                    cancelText: props.cancelText,
                    onConfirm: props.onConfirm,
                    onDismiss: props.onDismiss,
                    target: props.target,
                    isOpen: props.isOpen
                }}
            />
        </>
    );
};

export default ConfirmationDialog;
