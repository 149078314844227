import { IPersonaProps, PersonaPresence } from '@fluentui/react';
import { OrgBrandingDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import { useOrganizationBranding } from 'features/Header/hooks';
import { availabilityToPersonaPresence } from 'kits/availabilityKit';
import IconKit from 'kits/IconKit';
import IvicosStrings from 'kits/language/stringKit';
import { ReactElement } from 'react';
import { useCampus } from 'shared-state/directory/hooks';
import { useIsHelpModalOpen } from 'shared-state/display-options/hooks';
import { useIdentityClaims, useLocalProfile, useSharedAvailability } from 'shared-state/identity/hooks';
import { useCurrentOrgId } from 'shared-state/location/hook';

export interface ITopBarLink {
    title: string;
    key: string;
    action?: (e: any) => void;
    getSymbol?: () => ReactElement;
    symbol?: string;
}

export interface ITopBarState {
    barTitle: string;
    links: ITopBarLink[];
    branding: OrgBrandingDto | undefined;
    personaProps: IPersonaProps | undefined;
    localProfile: any;
    variantLabel: string;
    children?: any;
}

export const useTopBarState: () => ITopBarState = () => {
    const localProfile = useLocalProfile();

    const [campus] = useCampus();

    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);
    const [, setIsHelpModalOpen] = useIsHelpModalOpen();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [localAvailability, setLocalAvailability] = useSharedAvailability();

    const variantLabel = window.location.origin.includes('canary') ? 'CANARY' : window.location.origin.includes('localhost') ? 'DEBUG' : 'PROD';

    const [identity] = useIdentityClaims();

    const links = [
        {
            key: 'help',
            title: IvicosStrings.helpLinkTitle,
            symbol: IconKit.IconSet.Small.Help,
            action: () => setIsHelpModalOpen(true)
        }
    ];

    const personaProps = identity
        ? {
              primaryText: identity?.displayName,
              imageUrl: identity?.profileImageURL,
              presence: (localAvailability && availabilityToPersonaPresence(localAvailability)) || PersonaPresence.online,
              onClick: () => (window.location.href = '#status')
          }
        : undefined;

    const barTitle = campus?.name || branding?.orgName || '';
    return { localProfile, branding, links, barTitle, variantLabel, personaProps };
};
