import { Stack } from '@fluentui/react';
import { useOrganizationBranding } from 'features/Header/hooks';
import OutsideCallController from 'features/Visitors/Entrance/OutsideCallController/OutsideCallController';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import React from 'react';
import { Navigate } from 'react-router';
import { ILandingPageState } from './LandingPage.state';
import LandingPageSideCard from './LandingPageSideCard';

const LandingPageView: React.FC<ILandingPageState> = (props) => {
    const { videntityClaims, customUrl, invitationId, eventName, orgId, goInClicked } = props;

    const redirectVisitor = invitationId ? invitationId : customUrl;

    if (!localStorage.getItem('ivAccessToken')) return <Navigate to={'/i/' + redirectVisitor} />;

    const [branding] = useOrganizationBranding(orgId);

    return (
        <>
            <OutsideWrapper backgroundImage={branding?.visitor_entrance_background_image}>
                {/* Jitsi call */}
                {videntityClaims && (
                    <Stack verticalAlign="center">
                        <OutsideCallController displayName={videntityClaims.displayName} />
                    </Stack>
                )}

                {/* Side card */}
                <Stack verticalAlign="center" style={{ marginTop: 16, width: 340, marginRight: 16, marginLeft: 30 }}>
                    <LandingPageSideCard eventName={eventName} branding={branding} videntityClaims={videntityClaims} goInClicked={goInClicked} />
                </Stack>
            </OutsideWrapper>
        </>
    );
};

export default LandingPageView;
