import { getTheme, mergeStyles, Panel, PanelType, Stack, Text } from '@fluentui/react';
import UserFace from 'features/Rooms/RoomList/UserFace/UserFace';
import React from 'react';
import { IProfile } from 'shared-state/identity/types';

export interface IUserFaceOverflowButtonViewProps {
    showOverflowUserPanel: boolean;
    setShowOverflowUserPanel: React.Dispatch<React.SetStateAction<boolean>>;
    users: IProfile[];
    overflowIndex: number;
    panelTitle?: string;
}

const UserFaceOverflowButtonView: React.FC<IUserFaceOverflowButtonViewProps> = ({
    showOverflowUserPanel,
    setShowOverflowUserPanel,
    users,
    overflowIndex,
    panelTitle
}) => {
    const theme = getTheme();
    const buttonStyles = mergeStyles({
        height: 42,
        width: 42,
        borderRadius: 42 / 2,
        background: 'rgba(0,0,0,0.25)',
        fontSize: 18,
        color: theme.palette.black
    });
    return (
        <>
            <Stack className={'pressable ' + buttonStyles} verticalAlign="center" horizontalAlign="center" onClick={() => setShowOverflowUserPanel(true)}>
                +{users.slice(overflowIndex).length}
                <Panel
                    type={PanelType.customNear}
                    customWidth={'320px'}
                    isOpen={showOverflowUserPanel}
                    isLightDismiss
                    onDismiss={() => setShowOverflowUserPanel(false)}
                >
                    <Stack tokens={{ childrenGap: 16 }}>
                        {panelTitle && <Text variant="large">{panelTitle}</Text>}
                        <Stack tokens={{ childrenGap: 16 }}>
                            {users.map((user, uindex) => (
                                <UserFace key={user.uid} index={uindex} user={user} showDetails actionStyles={{ expanded: true }} />
                            ))}
                        </Stack>
                    </Stack>
                </Panel>
            </Stack>
        </>
    );
};

export default UserFaceOverflowButtonView;
