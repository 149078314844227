import { Dialog, DialogType, IDialogContentProps, Layer, PrimaryButton, Separator, Stack, Text, TextField, TooltipHost } from '@fluentui/react';
import React from 'react';
import IvicosStrings from 'kits/language/stringKit';
import GiphyPanel from 'features/MemoComposer/GiphyPanel';
import UserFacepile from 'features/Rooms/RoomList/UserFacepile/UserFacepile';
import { IC } from 'components/SVGIcon';
import SvgMarkdown from 'kits/IconKit2/Markdown';
import MarkdownHost from 'features/MarkdownHost/MarkdownHost';
import SvgEditMessage from 'kits/IconKit2/EditMessage';
import IconButton from 'components/IconButton';
import { Eye, Gif } from 'kits/IconKit2';
import { IDirectMemoComposerState } from '../../../dataTypes';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const DirectMemoComposerView: React.FC<IDirectMemoComposerState> = (props) => {
    const { userIds, users, theme, giphyPanelIsOpen, setGiphyPanelIsOpen, memoBody, setMemoBody, mode, setMode, cancel, send } = props;
    const { smallDevice } = useWindowWidth();
    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        styles: {
            title: {
                fontSize: 16
            }
        },
        title: IvicosStrings.sendMemoToActionTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle
    };

    const standardBorder = '1px ' + theme.semanticColors.inputBorder + ' solid';
    const focusBorder = '2px ' + theme.palette.themePrimary + ' solid';

    if (userIds.length <= 0) return <></>;

    return (
        <Stack>
            <Layer>
                <GiphyPanel
                    isOpen={giphyPanelIsOpen}
                    onGifSelected={(gifUrl) => {
                        setMemoBody((memoBody) => memoBody + '\n' + `![Giphy GIF](${gifUrl})`);
                        setGiphyPanelIsOpen(false);
                        setMode('preview');
                    }}
                    onDismiss={() => setGiphyPanelIsOpen(false)}
                />
                <Dialog
                    modalProps={{ isBlocking: true, styles: { main: { width: '90% !important', minWidth: '340px !important', maxWidth: '450px !important' } } }}
                    hidden={false}
                    onDismiss={cancel}
                    dialogContentProps={dialogContentProps}
                >
                    <Stack tokens={{ childrenGap: 16 }}>
                        <UserFacepile users={users} actionStyles={{ hidden: true }} />

                        <Stack
                            styles={{
                                root: {
                                    border: standardBorder,
                                    padding: 9,
                                    position: 'relative',
                                    borderRadius: 4,
                                    '&:focus-within': {
                                        padding: 8,
                                        border: focusBorder
                                    }
                                }
                            }}
                        >
                            {mode == 'markdown' && (
                                <Stack
                                    style={{ cursor: 'pointer', position: 'absolute', top: 6, right: 8, zIndex: 300 }}
                                    onClick={() => {
                                        window.open('https://www.markdownguide.org/basic-syntax/', '_blank');
                                    }}
                                >
                                    <TooltipHost content={smallDevice ? '' : IvicosStrings.inputSupportsMarkdownTitle}>
                                        <IC variant="dark" size={16}>
                                            <SvgMarkdown />
                                        </IC>
                                    </TooltipHost>
                                </Stack>
                            )}
                            {mode == 'markdown' ? (
                                <TextField
                                    borderless
                                    autoFocus={true}
                                    placeholder={IvicosStrings.memoPlaceholder}
                                    value={memoBody}
                                    multiline
                                    rows={4}
                                    resizable={false}
                                    maxLength={256}
                                    onChange={(e, newValue) => {
                                        setMemoBody(newValue || '');
                                    }}
                                ></TextField>
                            ) : (
                                <Text variant="medium" style={{ wordWrap: 'break-word', paddingLeft: 8, minHeight: 4 * 18 }}>
                                    <MarkdownHost>{memoBody}</MarkdownHost>
                                </Text>
                            )}
                            <Separator />
                            <Stack horizontal horizontalAlign="space-between">
                                <Stack horizontal tokens={{ childrenGap: 8 }}>
                                    <IconButton
                                        tooltipContent={IvicosStrings.editMessageActionTitle}
                                        isSelected={mode == 'markdown'}
                                        onPress={() => setMode('markdown')}
                                        onRenderIcon={() => <SvgEditMessage />}
                                    />
                                    <IconButton
                                        tooltipContent={IvicosStrings.previewActionTitle}
                                        isDisabled={memoBody.length <= 0}
                                        isSelected={mode == 'preview'}
                                        onPress={() => setMode('preview')}
                                        onRenderIcon={() => <Eye />}
                                    />
                                </Stack>
                                <Stack horizontal tokens={{ childrenGap: 8 }}>
                                    <IconButton
                                        tooltipContent={IvicosStrings.addGifActionTitle}
                                        onPress={() => setGiphyPanelIsOpen(true)}
                                        onRenderIcon={() => <Gif />}
                                    />
                                    <PrimaryButton onClick={send} text={IvicosStrings.sendActionTitle} />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Dialog>
            </Layer>
        </Stack>
    );
};

export default DirectMemoComposerView;
