import React from 'react';
import { useBasicListState } from './BasicList.state';
import BasicListView from './BasicList.view';

interface IBasicListProps {
    items: any;
    onChange: any;
    label?: string;
    callout?: string;
    disabled?: boolean;
    height?: string;
    overflow?: string;
}

const ChoiceGroup: React.FC<IBasicListProps> = (props) => {
    const state = useBasicListState(props.onChange);

    return (
        <BasicListView
            {...{
                ...state,
                ...{
                    items: props.items,
                    // defaultSelectedKey: props.defaultSelectedKey,
                    callout: props.callout,
                    onChange: props.onChange,
                    disabled: props.disabled,
                    label: props.label,
                    height: props.height,
                    overflow: props.overflow
                }
            }}
        ></BasicListView>
    );
};

export default ChoiceGroup;
