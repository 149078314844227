import { Callout, DelayedRender, List, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import IconKit from 'kits/IconKit';
import { QuestionCircle } from 'kits/IconKit2';
import React from 'react';
import styled from 'styled-components';

interface IBasicListViewProps {
    isCalloutVisible: boolean;
    styles: any;
    toggleIsCalloutVisible: any;
    buttonId: string;
    directionalHint: any;
    onBasicListChange: (ev: any, option: any) => void;
    label?: string;
    items: string[];
    callout?: string;
    disabled?: boolean;
    height?: string;
    overflow?: string;
    backgroundColor?: string;
}

const BasicListView: React.FC<IBasicListViewProps> = (props) => {
    const { isCalloutVisible, styles, toggleIsCalloutVisible, buttonId, directionalHint, onBasicListChange, label, items, callout, height, backgroundColor } =
        props;
    const renderListCell = (item: any) => {
        const Wrapper = styled.section`
            cursor: pointer;
            user-select: none;
            [tabIndex]:focus {
                background-color: ${IvicosColors.Mittelblau};
                color: white;
            }
            :hover {
                background-color: ${IvicosColors.Grau50};
            }
        `;
        return (
            <>
                <Wrapper>
                    <Stack
                        tabIndex={1}
                        onClick={(e: any) => {
                            onBasicListChange(e, item.key);
                        }}
                        tokens={{ childrenGap: 8 }}
                    >
                        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
                            <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 44, height: 44 }}>
                                <IconKit.Icon icon={IconKit.getIcon('Rooms', item.icon)}></IconKit.Icon>
                            </Stack>
                            <Stack>
                                <Text variant="medium" color={backgroundColor && getTextColorForBackground(backgroundColor)}>
                                    {item.text}
                                </Text>
                            </Stack>
                        </Stack>
                    </Stack>
                </Wrapper>
            </>
        );
    };

    return (
        <Stack>
            <Stack>
                <Stack horizontal horizontalAlign="space-between">
                    {label ? (
                        <Stack>
                            <Text style={{ fontWeight: 'bold' }}>{label}</Text>
                        </Stack>
                    ) : null}
                    {callout != undefined ? (
                        <Stack>
                            <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                                <QuestionCircle onClick={toggleIsCalloutVisible} id={buttonId} />
                            </IC>
                        </Stack>
                    ) : null}
                </Stack>
                <Stack horizontal={false} style={{ width: '100%', maxHeight: height, overflow: 'auto' }}>
                    <List items={items} onRenderCell={renderListCell}></List>
                </Stack>
            </Stack>

            {isCalloutVisible && (
                <Callout
                    className={styles.callout}
                    onDismiss={toggleIsCalloutVisible}
                    role="status"
                    target={`#${buttonId}`}
                    aria-live="assertive"
                    directionalHint={directionalHint}
                >
                    <DelayedRender>
                        <Text variant="small">{callout}</Text>
                    </DelayedRender>
                </Callout>
            )}
        </Stack>
    );
};

export default BasicListView;
