import { IRoomResource } from 'kits/apiKit3/legacy';
import { useCallback, useContext } from 'react';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import { CLIENT_EMIT_MESSAGE_EVENT, CLIENT_EMIT_ROOM_EVENT } from 'services/socket-connection/events';
import { TUpdateEvent } from 'services/socket-connection/types';
import SocketContext from '../../context/SocketContext';
import { v1 as uuidv1 } from 'uuid';
import { useMessagingEvents } from 'services/socket-connection/hooks';
import { IRoundTableStateUpdate } from 'features/Rooms/SpeakerStats/RoundTableStateManager';

export const useEmittingEvents = (): {
    updateRoomData: (type: 'update' | 'deletion' | 'creation', room?: IRoomResource, roomId?: string) => void;
    inviteUserToRoom: (userId: string, roomId: string) => void;
    crossAreaInviteUserToRoom: (userId: string, roomId: string) => void;
    sendMemoToUser: (userId: string, memoBody: string) => void;
    kickVisitor: (userId: string) => void;
    sendAccessRequest: (userId: string, roomId: string) => void;
    sendAccessResponse: (userId: string, jitsiSessionId: string) => void;
    sendRoomTopic: (userId: string, roomId: string, topic: string) => void;
    knock: (room?: string) => void;
    roundTable: (roomId: string, updateData: IRoundTableStateUpdate) => void;
    sendAttachmentsUpdatedEvent: (roomId: string) => void;
} => {
    const [areaId] = useCurrentAreaId();
    const [roomId] = useCurrentRoomId();
    const usersInRoom = useUsersInRoom(roomId || '');
    const {
        SocketState: { socket }
    } = useContext(SocketContext);
    const sendMessagingEvent = useMessagingEvents();

    const updateRoomData = useCallback(
        (type: 'update' | 'deletion' | 'creation', room?: IRoomResource, roomId?: string) => {
            const roomUpdateEvent: TUpdateEvent = { type, room, roomId };
            areaId && socket && socket.emit(CLIENT_EMIT_ROOM_EVENT, roomUpdateEvent);
        },
        [areaId, socket]
    );

    const inviteUserToRoom = (userId: string, roomId: string) => {
        let id;
        if (usersInRoom.length > 0) {
            id = localStorage.getItem('jitsiSessionId');
        } else {
            if (roomId.includes('personal')) {
                id = uuidv1();
            } else {
                id = roomId;
            }
            localStorage.setItem('jitsiSessionId', id);
        }

        sendMessagingEvent({
            type: 'invite',
            meta: { forUser: userId, attachedRoom: roomId },
            body: id || '',
            announce: true
        });
    };

    const crossAreaInviteUserToRoom = (userId: string, room: any) => {
        let id;
        if (usersInRoom.length > 0) {
            id = localStorage.getItem('jitsiSessionId');
        } else {
            if (room.id.includes('personal')) {
                id = uuidv1();
            } else {
                id = room.id;
            }
            localStorage.setItem('jitsiSessionId', id);
        }

        areaId &&
            socket &&
            socket.emit(CLIENT_EMIT_MESSAGE_EVENT, {
                type: 'cross-area-invite',
                meta: { forUser: userId, attachedRoom: room.id },
                body: id || '',
                room,
                announce: true
            });
    };

    const sendMemoToUser = (userId: string, memoBody: string) => {
        sendMessagingEvent({
            type: 'memo',
            meta: { forUser: userId },
            body: memoBody,
            announce: true
        });
    };

    const kickVisitor = (userId: string) => {
        sendMessagingEvent({
            type: 'kick-visitor',
            meta: { forUser: userId },
            announce: false
        });
    };

    const sendAccessRequest = (userId: string, roomId: string) => {
        sendMessagingEvent({
            type: 'request-jitsisessionid',
            meta: { forUser: userId },
            body: roomId,
            announce: true
        });
    };

    const sendAccessResponse = (userId: string, jitsiSessionId: string) => {
        sendMessagingEvent({
            type: 'response-jitsisessionid',
            meta: { forUser: userId },
            body: jitsiSessionId,
            announce: true
        });
    };

    const sendRoomTopic = (userId: string, roomId: string, topic: string) => {
        sendMessagingEvent({
            type: 'room-topic',
            meta: { forUser: userId, attachedRoom: roomId },
            body: topic,
            announce: false
        });
    };

    const knock = (room?: string) => {
        sendMessagingEvent({
            type: 'knock',
            meta: { forRoom: room || roomId },
            announce: true
        });
    };

    const roundTable = (roomId: string, updateData: IRoundTableStateUpdate) => {
        sendMessagingEvent({
            type: 'round-table-update',
            meta: { forRoom: roomId },
            body: updateData,
            announce: false,
            timeout: 0
        });
    };

    const sendAttachmentsUpdatedEvent = (roomId: string) => {
        sendMessagingEvent({
            type: 'room-attachments-updated',
            meta: { forRoom: roomId },
            announce: false
        });
    };

    return {
        updateRoomData,
        inviteUserToRoom,
        crossAreaInviteUserToRoom,
        sendMemoToUser,
        kickVisitor,
        sendAccessRequest,
        sendAccessResponse,
        sendRoomTopic,
        knock,
        roundTable,
        sendAttachmentsUpdatedEvent
    };
};
