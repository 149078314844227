import { Dialog, DialogType, IDialogContentProps, Layer, PrimaryButton, Separator, Stack, Text, TextField, TooltipHost } from '@fluentui/react';
import Badge from 'components/Badge';
import IconButton from 'components/IconButton';
import { IC } from 'components/SVGIcon';
import MarkdownHost from 'features/MarkdownHost/MarkdownHost';
import GiphyPanel from 'features/MemoComposer/GiphyPanel';
import { Eye, Gif } from 'kits/IconKit2';
import SvgEditMessage from 'kits/IconKit2/EditMessage';
import SvgMarkdown from 'kits/IconKit2/Markdown';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import utf8 from 'utf8';
import { useWaitingAreaMemoComposerState } from './WaitingAreaMemoComposer.state';

interface IWaitingAreaMemoComposerViewProps {
    recipientName?: string;
    sentBy?: string;
    isOpen?: boolean;
}

export const WaitingAreaMemoComposerView: React.FC<IWaitingAreaMemoComposerViewProps & ReturnType<typeof useWaitingAreaMemoComposerState>> = ({
    recipientName,
    sentBy,
    isOpen,
    theme,
    giphyPanelIsOpen,
    setGiphyPanelIsOpen,
    memoBody,
    setMemoBody,
    mode,
    setMode,
    cancel,
    send
}) => {
    const { smallDevice } = useWindowWidth();
    const chosenLanguage = localStorage.getItem('chosenLanguage') || 'en';
    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        styles: {
            title: {
                fontSize: 16
            }
        },
        title: `${IvicosStrings.sendMemoToActionTitle} ${sentBy === 'visitor' ? (chosenLanguage === 'en' ? 'all hosts' : 'alle Hosts') : ''}`,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle
    };

    const standardBorder = '1px ' + theme.semanticColors.inputBorder + ' solid';
    const focusBorder = '2px ' + theme.palette.themePrimary + ' solid';

    if (!isOpen) return null;

    const hostsListAtVE = JSON.parse(sessionStorage.getItem('hostsListAtVE') || 'null');
    console.log('🚀 ~ hostsListAtVE:', hostsListAtVE);

    return (
        <Stack>
            <Layer>
                <GiphyPanel
                    isOpen={giphyPanelIsOpen}
                    onGifSelected={(gifUrl) => {
                        setMemoBody(memoBody + '\n' + `![Giphy GIF](${gifUrl})`);
                        setGiphyPanelIsOpen(false);
                        setMode('preview');
                    }}
                    onDismiss={() => setGiphyPanelIsOpen(false)}
                />
                <Dialog
                    modalProps={{ isBlocking: true, styles: { main: { width: '90% !important', minWidth: '340px !important', maxWidth: '450px !important' } } }}
                    hidden={false}
                    onDismiss={cancel}
                    dialogContentProps={dialogContentProps}
                >
                    <Stack tokens={{ childrenGap: 16 }}>
                        {sentBy === 'host' ? (
                            <Stack horizontal>
                                <Badge text={utf8.decode(recipientName || '')} size="41" fontSize="medium" />
                                <Text
                                    variant="large"
                                    style={{
                                        lineHeight: '25px',
                                        marginTop: 7,
                                        marginLeft: 10,
                                        marginRight: 10,
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    {utf8.decode(recipientName || '')}
                                </Text>
                            </Stack>
                        ) : // hostsListAtVE &&
                        // (hostsListAtVE.length > 1 ? (
                        //     <Stack horizontal>
                        //         {hostsListAtVE.map((host) => (
                        //             <Persona
                        //                 key={host.id}
                        //                 imageUrl={host.profileImage}
                        //                 size={PersonaSize.size40}
                        //                 imageAlt={host.displayName}
                        //                 imageInitials={host.displayName.split(' ')[0][0].toUpperCase()}
                        //                 styles={{
                        //                     root: {
                        //                         width: '40px',
                        //                         height: '40px',
                        //                         margin: '0 4px'
                        //                     }
                        //                 }}
                        //             />
                        //         ))}
                        //     </Stack>
                        // ) : (
                        //     <Stack>
                        //         <Persona
                        //             text={hostsListAtVE[0].displayName}
                        //             imageUrl={hostsListAtVE[0].profileImage}
                        //             size={PersonaSize.size40}
                        //             imageAlt={hostsListAtVE[0].displayName}
                        //         />
                        //     </Stack>
                        // ))
                        null}
                        <Stack
                            styles={{
                                root: {
                                    border: standardBorder,
                                    padding: 9,
                                    position: 'relative',
                                    borderRadius: 4,
                                    '&:focus-within': {
                                        padding: 8,
                                        border: focusBorder
                                    }
                                }
                            }}
                        >
                            {mode === 'markdown' && (
                                <Stack
                                    style={{ cursor: 'pointer', position: 'absolute', top: 6, right: 8, zIndex: 300 }}
                                    onClick={() => {
                                        window.open('https://www.markdownguide.org/basic-syntax/', '_blank');
                                    }}
                                >
                                    <TooltipHost content={smallDevice ? '' : IvicosStrings.inputSupportsMarkdownTitle}>
                                        <IC variant="dark" size={16}>
                                            <SvgMarkdown />
                                        </IC>
                                    </TooltipHost>
                                </Stack>
                            )}
                            {mode === 'markdown' ? (
                                <TextField
                                    borderless
                                    autoFocus={true}
                                    placeholder={IvicosStrings.memoPlaceholder}
                                    value={memoBody}
                                    multiline
                                    rows={4}
                                    resizable={false}
                                    maxLength={256}
                                    onChange={(e, newValue) => {
                                        setMemoBody(newValue || '');
                                    }}
                                />
                            ) : (
                                <Text variant="medium" style={{ wordWrap: 'break-word', paddingLeft: 8, minHeight: 4 * 18 }}>
                                    <MarkdownHost>{memoBody}</MarkdownHost>
                                </Text>
                            )}
                            <Separator />
                            <Stack horizontal horizontalAlign="space-between">
                                <Stack horizontal tokens={{ childrenGap: 8 }}>
                                    <IconButton
                                        tooltipContent={IvicosStrings.editMessageActionTitle}
                                        isSelected={mode === 'markdown'}
                                        onPress={() => setMode('markdown')}
                                        onRenderIcon={() => <SvgEditMessage />}
                                    />
                                    <IconButton
                                        tooltipContent={IvicosStrings.previewActionTitle}
                                        isDisabled={memoBody.length <= 0}
                                        isSelected={mode === 'preview'}
                                        onPress={() => setMode('preview')}
                                        onRenderIcon={() => <Eye />}
                                    />
                                </Stack>
                                <Stack horizontal tokens={{ childrenGap: 8 }}>
                                    <IconButton
                                        tooltipContent={IvicosStrings.addGifActionTitle}
                                        onPress={() => setGiphyPanelIsOpen(true)}
                                        onRenderIcon={() => <Gif />}
                                    />
                                    <PrimaryButton onClick={send} text={IvicosStrings.sendActionTitle} />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Dialog>
            </Layer>
        </Stack>
    );
};
