import QRPhone from './media/small/qrphone.svg';

import Video from './media/small/video.svg';
import VideoOff from './media/small/video--off.svg';

import RoomAdd from './media/small/room--add.svg';
import RoomDelete from './media/small/room--delete.svg';

import VisitorInvite from './media/small/guest--invite.svg';
import Lift from './media/small/lift.svg';

import Trash from './media/small/trash.svg';
import Jitsi from './media/small/jitsi.svg';

import Splitscreen from './media/small/splitscreen.svg';
import Fullscreen from './media/small/fullscreen.svg';

import Mic from './media/small/mic.svg';
import MicOff from './media/small/mic--off.svg';

import Edit from './media/small/edit.svg';
import Save from './media/small/save.svg';
import Spinner from './media/small/spinner.svg';
import More from './media/small/more.svg';

import Config from './media/small/config.svg';
import DoorOpen from './media/small/door--open.svg';
import Add from './media/small/add.svg';
import Updating from './media/small/updating.svg';
import Remove from './media/small/remove.svg';
import Tweak from './media/small/tweak.svg';
import WindowClose from './media/small/window--close.svg';
import Grid from './media/small/grid.svg';
import Exit from './media/small/exit.svg';

import Memo from './media/small/memo.svg';

import SideBar from './media/small/sidebar.svg';

import Copy from './media/small/copy.svg';
import CopySuccess from './media/small/copy--success.svg';
import CopyError from './media/small/copy--error.svg';

import ScreenOn from './media/small/screen--on.svg';
import ScreenOff from './media/small/screen--off.svg';

import Experimental from './media/small/experimental.svg';
import Help from './media/small/help.svg';

import Check from './media/small/check.svg';

import Note from './media/small/note.svg';
import NoteAdd from './media/small/note--add.svg';

import HandKnock from './media/small/hand--knock.svg';
import HandWave from './media/small/hand--wave.svg';

import ProtectedEu from './media/small/protected--eu.svg';

import Tile from './media/small/tile.svg';
import ViewingMode from './media/small/viewing-mode.svg';

const Small = {
    QRPhone,
    Check,
    Fullscreen,
    Splitscreen,
    Save,
    Spinner,
    RoomAdd,
    RoomDelete,
    SideBar,
    Video,
    VideoOff,
    Help,
    Mic,
    MicOff,
    Edit,
    More,
    Experimental,
    Config,
    DoorOpen,
    Add,
    Updating,
    Remove,
    Tweak,
    WindowClose,
    Grid,
    Exit,
    Memo,
    ScreenOn,
    ScreenOff,
    HandKnock,
    HandWave,
    Tile,
    VisitorInvite,
    Copy,
    CopySuccess,
    CopyError,
    Lift,
    Note,
    NoteAdd,
    Trash,
    ViewingMode,
    ProtectedEu,
    Jitsi
};

export default Small;
