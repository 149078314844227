import * as React from 'react';

function SvgCheckbox(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4 4v24h24V12.187l-2 2V26H6V6h19.813l2-2H4zm23.281 3.281L16 18.563l-4.281-4.282-1.438 1.438 5 5 .719.687.719-.687 12-12L27.28 7.28z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgCheckbox;
