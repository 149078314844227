import { ICallAction } from 'features/Rooms/RoomView/CallActions';
import React from 'react';
import CallActionButtonView from './CallActionButton.view';

export interface ICallActionButtonProps {
    action: ICallAction;
    onPressed: (a: ICallAction) => void;
}

const CallActionButton: React.FC<ICallActionButtonProps> = (props) => {
    return (
        <CallActionButtonView
            {...{
                ...{
                    action: props.action,
                    onPressed: props.onPressed
                }
            }}
        ></CallActionButtonView>
    );
};
export default CallActionButton;
