import { AreaDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { IRoomResource } from 'kits/apiKit3/legacy';
import IvicosStrings from 'kits/language/stringKit';
import { DefaultValue, selector, selectorFamily } from 'recoil';
import { aAreaList, aRoomList, aRoomListStyles } from 'shared-state/directory/atoms';
import { aCurrentAreaId } from 'shared-state/location/atoms';
import { sUsers } from 'shared-state/presence/selectors';

export const sStyledRoomList = selector<IRoomResource[]>({
    key: 'sStyledRoomList',
    get: ({ get }) => {
        //TODO sort
        const roomList = get(aRoomList);
        const styles = get(aRoomListStyles);

        if (!roomList) return [];

        const nextStylesList = roomList.map((room: IRoomResource) => {
            const areaCheck = !styles.highlightFromArea || styles.highlightFromArea == room.relationships.area;
            const visitorCheck = !styles.highlightForVisitors || room.attributes.openForVisitors;

            return {
                ...room,
                ...{
                    attributes: {
                        ...room.attributes,
                        ...{ dimmed: !(areaCheck && visitorCheck) }
                    }
                }
            };
        });

        return nextStylesList;
    }
});

export const sPersonalRoomList = selector<IRoomResource[]>({
    key: 'sPersonalRoomList',
    get: ({ get }) => {
        const areaId = get(aCurrentAreaId);
        const userList = get(sUsers);

        const usersInPersonalRooms = userList.filter((u) => u.room?.includes('personal-'));
        const personalRoomIds = Array.from(new Set(usersInPersonalRooms.map((u) => u.room)));

        const nextPersonalRoomList: IRoomResource[] = personalRoomIds.map((roomId) => ({
            id: roomId || '-',
            type: 'iv_campus_room',
            attributes: {
                variant: 'DEFAULT',
                displayName:
                    // eslint-disable-next-line quotes
                    userList.find((u) => u.uid == roomId?.replace('personal-', '').split('_')[0])?.displayName + IvicosStrings.personalRoomTitle ||
                    IvicosStrings.unknownPersonalRoomTitle,
                private: true,
                symbol: 'Room'
            },
            relationships: {
                area: areaId || '-',
                owner: roomId?.replace('personal-', '').split('_')[0] || '*'
            }
        }));

        return nextPersonalRoomList;
    }
});

export const sRoom = selectorFamily<IRoomResource | undefined, string>({
    key: 'sRoom',
    get:
        (roomId: string) =>
        ({ get }) => {
            const roomList = get(sStyledRoomList);
            const personalRoomList = get(sPersonalRoomList);
            return [...roomList, ...personalRoomList].find((room) => room.id == roomId);
        },
    set:
        (roomId: string) =>
        ({ set, get }, newValue) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const roomList = get(aRoomList);
            if (!newValue) return set(aRoomList, (prevRoomList) => prevRoomList.filter((r) => r.id != roomId));
            if (newValue instanceof DefaultValue) return;

            set(aRoomList, (prevRoomList) => {
                const room$ = prevRoomList.find((room) => room.id == roomId);
                if (room$)
                    return prevRoomList.map((room) => {
                        if (room.id != roomId) return room;

                        return newValue;
                    });

                return [...prevRoomList, ...[newValue]];
            });
        }
});

export const sRoomListIncludingPersonalRooms = selector<IRoomResource[]>({
    key: 'sRoomListIncludingPersonalRooms',
    get: ({ get }) => {
        const roomList = get(sStyledRoomList);
        const personalRoomList = get(sPersonalRoomList);
        return [...roomList, ...personalRoomList];
    }
});

export const sArea = selectorFamily<AreaDto | undefined, string | undefined>({
    key: 'sArea',
    get:
        (areaId: string | undefined) =>
        ({ get }) => {
            if (!areaId) return undefined;
            const areaList = get(aAreaList);
            return areaList.find((a) => a.id == areaId);
        },
    set:
        (areaId: string | undefined) =>
        ({ set, get }, newValue) => {
            if (!areaId) return;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const areaList = get(aAreaList);
            if (!newValue) return set(aAreaList, (prevAreaList) => prevAreaList.filter((a) => a.id != areaId));
            if (newValue instanceof DefaultValue) return;

            set(aAreaList, (prevAreaList) => {
                const room$ = prevAreaList.find((area) => area.id == areaId);
                if (room$)
                    return prevAreaList.map((area) => {
                        if (area.id != areaId) return area;

                        return newValue;
                    });

                return [...prevAreaList, ...[newValue]];
            });
        }
});
