import { Modal, Overlay } from '@fluentui/react';
import React, { useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { campusMediaService } from 'services/api/campus-media.service';
import ImageCropper from './ImageCropper';
import { blobToFile } from './helpers';
import { sendEvent } from 'kits/eventKit';

export interface IProfileImageUploaderProps {
    onImage?: (imageURL: string) => void;
    onClose?: () => void;
}

const ProfileImageUploader: React.FC<IProfileImageUploaderProps> = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const [currentImage, setCurrentImage] = useState<string | undefined>();

    const _onImage = async (resultingImage: string) => {
        const imageAsBlob = await fetch(resultingImage).then((res) => res.blob());
        const today = new Date();

        const fileName = 'avatar-' + today.getTime() + '.jpg';

        const imageAsFile = blobToFile(imageAsBlob, fileName);

        const uploadedImage = await campusMediaService.uploadImage(imageAsFile, fileName);

        await idpService.updateUser(undefined, uploadedImage.image_url, undefined);

        sendEvent('refetchUserDetails', {});
        _onClose();
    };

    const _onClose = () => {
        onClose && onClose();
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen} styles={{ root: { height: '100%' }, scrollableContent: { height: '500px' } }}>
            {isOpen && (
                <Overlay style={{ zIndex: 356789 }}>
                    <ImageCropper
                        src={currentImage}
                        onDismiss={() => {
                            _onClose();
                        }}
                        onSelection={(selection) => {
                            setCurrentImage(selection);
                            if (!selection) idpService.updateUser(undefined, 'none', undefined);
                            selection && _onImage(selection);
                        }}
                    />
                </Overlay>
            )}
        </Modal>
    );
};

export default ProfileImageUploader;
