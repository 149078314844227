import { useEvent } from 'kits/eventKit';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';
import { aHandRaiseButtonDisabled, aRoundTableSpeakerList } from 'shared-state/directory/atoms';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useUsersInRoom } from 'shared-state/presence/hooks';

export interface RoundTableSpeakerInfo {
    userId: string;
    roundStartTimestamp: number;
    isInTheRoom: boolean;
}
export interface IRoundTableStateUpdate {
    roundDurationInMinutes?: string;
    roundTableSpeakerList?: RoundTableSpeakerInfo[];
    currentSpeakerIndex?: number;
    isRoundTableActive?: boolean;
    roundTableRoomId: string;
    updateTimeStamp?: number;
    isRepeatingUpdate?: boolean;
}

export interface IRoundTableStateManager {
    updateRoundTableStatusLocally: (updateData: IRoundTableStateUpdate) => void;
    updateRoundTableStatusRemotely: (updateData: IRoundTableStateUpdate) => void;
    roundDurationInMinutes: number;
    currentSpeakerIndex: number;
    roundTableSpeakerList: RoundTableSpeakerInfo[];
    isRoundTableActive: boolean;
    includeSpeaker: (userToAdd: RoundTableSpeakerInfo) => void;
    removeSpeaker: (userToRemove: RoundTableSpeakerInfo) => void;
    nextSpeaker: () => void;
}

const useRoundTableStateManager: (
    roomId: string,
    prettifyTime: (timeInMs: number) => string,
    setIsRoundTableTimerActivated: React.Dispatch<React.SetStateAction<boolean>>
) => IRoundTableStateManager = (roomId, prettifyTime, setIsRoundTableTimerActivated) => {
    const usersInRoom: IProfile[] = useUsersInRoom(roomId);
    const { roundTable } = useEmittingEvents();
    const localUser = useLocalProfile();

    const [roundDurationInMinutes, setRoundDurationInMinutes] = useState<number>(0);

    const [currentSpeakerIndex, setCurrentSpeakerIndex] = useState<number>(0);
    const [roundTableSpeakerList, setRoundTableSpeakerList] = useRecoilState<RoundTableSpeakerInfo[]>(aRoundTableSpeakerList);

    const [isLocalUserCurrentSpeaker, setIsLocalUserCurrentSpeaker] = useState<boolean>(false);
    const [isRoundTableActive, setIsRoundTableActive] = useState<boolean>(false);

    const [roundTableRoomId, setRoundTableRoomId] = useState<string>(roomId);

    const [, setHandRaiseButtonDisabled] = useRecoilState<boolean>(aHandRaiseButtonDisabled);

    const [lastUpdateTimeStamp, setLastUpdateTimeStamp] = useState<number>(0);

    useEffect(() => {
        setHandRaiseButtonDisabled(isRoundTableActive);
    }, [isRoundTableActive]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let timerId: NodeJS.Timeout;
    useEffect(() => {
        if (roundTableSpeakerList && roundTableSpeakerList.length > 0) {
            setIsLocalUserCurrentSpeaker(roundTableSpeakerList[currentSpeakerIndex].userId == localUser?.displayName);
        }
    }, [roundTableSpeakerList, currentSpeakerIndex]);

    useEvent('round-table-update', (updateData: IRoundTableStateUpdate) => {
        if (updateData.roundTableRoomId == roomId) {
            const isIncomingUpdateDominant =
                (updateData.isRepeatingUpdate && updateData.updateTimeStamp && updateData.updateTimeStamp - lastUpdateTimeStamp > 1000) ||
                !updateData.isRepeatingUpdate;

            if (isIncomingUpdateDominant && updateData.updateTimeStamp) {
                setLastUpdateTimeStamp(updateData.updateTimeStamp);
                updateRoundTableStatusLocally(updateData);
            }
        }
    });

    const updateRoundTableStatusLocally = useCallback(
        (updateData: IRoundTableStateUpdate) => {
            updateData.roundDurationInMinutes &&
                Math.abs(parseFloat(updateData.roundDurationInMinutes) - roundDurationInMinutes) > 0.001 &&
                setRoundDurationInMinutes(parseFloat(updateData.roundDurationInMinutes));
            updateData.currentSpeakerIndex !== undefined &&
                updateData.currentSpeakerIndex !== currentSpeakerIndex &&
                setCurrentSpeakerIndex(updateData.currentSpeakerIndex);
            const updatedRoundTableSpeakerListAsString = updateData.roundTableSpeakerList
                ?.map((item) => item.userId + '-' + item.roundStartTimestamp + '-' + item.isInTheRoom)
                .join(',');
            const currentRoundTableSpeakerListAsString = roundTableSpeakerList
                .map((item) => item.userId + '-' + item.roundStartTimestamp + '-' + item.isInTheRoom)
                .join(',');
            const hasRoundTableSpeakerListChanged = updatedRoundTableSpeakerListAsString !== currentRoundTableSpeakerListAsString;
            updateData.roundTableSpeakerList && hasRoundTableSpeakerListChanged && setRoundTableSpeakerList(updateData.roundTableSpeakerList);
            updateData.isRoundTableActive !== undefined &&
                updateData.isRoundTableActive !== isRoundTableActive &&
                setIsRoundTableActive(updateData.isRoundTableActive);
            updateData.roundTableRoomId !== undefined && updateData.roundTableRoomId !== roundTableRoomId && setRoundTableRoomId(updateData.roundTableRoomId);
        },
        [roundDurationInMinutes, currentSpeakerIndex, roundTableSpeakerList, isRoundTableActive, roundTableRoomId]
    );

    const updateRoundTableStatusRemotely = useCallback((updateData: IRoundTableStateUpdate) => {
        updateData.updateTimeStamp = new Date().getTime();
        setLastUpdateTimeStamp(updateData.updateTimeStamp);
        roundTable(roomId, updateData);
    }, []);

    let pingTimer: NodeJS.Timeout;

    const pingWithUpdate = useCallback(() => {
        const updateObject = {
            roundTableSpeakerList,
            isRoundTableActive,
            roundDurationInMinutes: String(roundDurationInMinutes),
            currentSpeakerIndex,
            roundTableRoomId,
            isRepeatingUpdate: true
        };
        updateRoundTableStatusRemotely(updateObject);
    }, [roundDurationInMinutes, roundTableSpeakerList, currentSpeakerIndex, isRoundTableActive, roundTableRoomId]);

    useEffect(() => {
        roundDurationInMinutes !== undefined && setIsRoundTableTimerActivated(roundDurationInMinutes > 0.0001 && isRoundTableActive);
    }, [roundDurationInMinutes, isRoundTableActive]);

    useEffect(() => {
        if (isLocalUserCurrentSpeaker && isRoundTableActive) {
            pingTimer = setInterval(pingWithUpdate, 2000);
        } else {
            pingTimer && clearInterval(pingTimer);
        }
        return () => clearInterval(pingTimer);
    }, [isLocalUserCurrentSpeaker, isRoundTableActive, roundTableSpeakerList, currentSpeakerIndex, isRoundTableActive, roundTableRoomId]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const isRoundTableValid =
                isRoundTableActive &&
                roundDurationInMinutes !== undefined &&
                roundTableSpeakerList &&
                roundTableSpeakerList.length > currentSpeakerIndex &&
                roomId === roundTableRoomId;

            const userNamesInRoom = usersInRoom.map((user) => user.displayName);
            const userNamesSortedAndJoined = userNamesInRoom
                .map((userName) => userName)
                .sort()
                .join(',');
            const speakerListPresentUserNamesSortedAndJoined = roundTableSpeakerList
                .filter((speaker) => speaker.isInTheRoom)
                .map((speaker) => speaker.userId)
                .sort()
                .join(',');
            const isUsersAndListConsistent = userNamesSortedAndJoined === speakerListPresentUserNamesSortedAndJoined;

            if (isRoundTableValid && !isUsersAndListConsistent) {
                let roundTableSpeakerListReEvaluated: RoundTableSpeakerInfo[] = [...roundTableSpeakerList];

                const roundTableSpeakerNames = roundTableSpeakerList.map((speaker) => speaker.userId);

                const userInRoomAndNotInSpeakerList: RoundTableSpeakerInfo[] = userNamesInRoom
                    .filter((userName) => !roundTableSpeakerNames.includes(userName))
                    .map((userName) => ({ userId: userName, roundStartTimestamp: 0, isInTheRoom: true }));

                if (userInRoomAndNotInSpeakerList && userInRoomAndNotInSpeakerList.length > 0)
                    roundTableSpeakerListReEvaluated = [...roundTableSpeakerListReEvaluated, ...userInRoomAndNotInSpeakerList];

                // Users are classified as present in the room and not
                roundTableSpeakerListReEvaluated = roundTableSpeakerListReEvaluated.map((speaker) => ({
                    ...speaker,
                    isInTheRoom: userNamesInRoom.includes(speaker.userId)
                }));

                // Users which have not talked yet and not in the room present anymore are removed from the round table speaker waiting list
                roundTableSpeakerListReEvaluated = roundTableSpeakerListReEvaluated.filter(
                    (speaker, index) => index < currentSpeakerIndex || speaker.isInTheRoom
                );

                // If the currentSpeakerIndex pointer is out of the range of list, roundtable should be deactivated
                let updateObject: IRoundTableStateUpdate;
                if (currentSpeakerIndex >= roundTableSpeakerListReEvaluated.length) {
                    updateObject = {
                        currentSpeakerIndex: 0,
                        roundTableSpeakerList: [],
                        roundDurationInMinutes: '0',
                        isRoundTableActive: false,
                        roundTableRoomId: roomId
                    };
                } else {
                    if (roundTableSpeakerListReEvaluated[currentSpeakerIndex].roundStartTimestamp === 0)
                        roundTableSpeakerListReEvaluated[currentSpeakerIndex].roundStartTimestamp = new Date().getTime();
                    updateObject = {
                        roundTableSpeakerList: roundTableSpeakerListReEvaluated,
                        isRoundTableActive: true,
                        roundDurationInMinutes: String(roundDurationInMinutes),
                        currentSpeakerIndex: currentSpeakerIndex,
                        roundTableRoomId: roomId
                    };
                }

                //  We should decide a user present in the room as the braadcaster of this decision (in our case the first user present in the room and roundtable list)
                const broadcaster = roundTableSpeakerListReEvaluated
                    .filter((speaker, index) => speaker.isInTheRoom == true && index <= currentSpeakerIndex)
                    .map((speaker) => speaker)
                    .pop();

                const isLocalUserThBroadcaster = broadcaster?.userId === localUser?.displayName;

                if (isLocalUserThBroadcaster) {
                    updateRoundTableStatusLocally(updateObject);
                    updateRoundTableStatusRemotely(updateObject);
                }
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [usersInRoom, roomId, currentSpeakerIndex, isRoundTableActive, roundDurationInMinutes]);

    const nextSpeaker = React.useCallback(() => {
        let updateData: IRoundTableStateUpdate;
        if (currentSpeakerIndex + 1 < roundTableSpeakerList.length) {
            //next
            const roundTableSpeakerListCopy = roundTableSpeakerList.map((speakerInfo) => ({ ...speakerInfo }));
            roundTableSpeakerListCopy[currentSpeakerIndex + 1].roundStartTimestamp = new Date().getTime();
            updateData = { currentSpeakerIndex: currentSpeakerIndex + 1, roundTableSpeakerList: roundTableSpeakerListCopy, roundTableRoomId: roomId };
        } //finish
        else {
            updateData = {
                currentSpeakerIndex: 0,
                roundTableSpeakerList: [],
                roundDurationInMinutes: '0',
                isRoundTableActive: false,
                roundTableRoomId: roomId
            };
        }
        updateRoundTableStatusLocally(updateData);
        updateRoundTableStatusRemotely(updateData);
    }, [currentSpeakerIndex, roundTableSpeakerList, roundTableRoomId]);

    const includeSpeaker = React.useCallback(
        (userToAdd: RoundTableSpeakerInfo) => {
            const roundTableSpeakerListCopy = roundTableSpeakerList.map((item) => item);
            const itemToRepalceIndex = roundTableSpeakerListCopy.findIndex((item) => item.userId === userToAdd.userId);
            roundTableSpeakerListCopy.splice(itemToRepalceIndex, 1);
            roundTableSpeakerListCopy.push(userToAdd);
            const updateData = { roundTableSpeakerList: roundTableSpeakerListCopy, currentSpeakerIndex: currentSpeakerIndex - 1, roundTableRoomId: roomId };
            updateRoundTableStatusLocally(updateData);
            updateRoundTableStatusRemotely(updateData);
        },
        [roundTableSpeakerList, currentSpeakerIndex, roundTableRoomId]
    );

    const removeSpeaker = React.useCallback(
        (userToRemove: RoundTableSpeakerInfo) => {
            const roundTableSpeakerListCopy = roundTableSpeakerList.map((item) => item);

            const itemToRepalceIndex = roundTableSpeakerListCopy.findIndex((item) => item.userId === userToRemove.userId);
            if (itemToRepalceIndex < roundTableSpeakerListCopy.length && currentSpeakerIndex !== roundTableSpeakerListCopy.length - 1) {
                const removedUser = roundTableSpeakerListCopy.splice(itemToRepalceIndex, 1)[0];
                roundTableSpeakerListCopy.splice(currentSpeakerIndex, 0, removedUser);
                const nextSpeakerInfo = { ...roundTableSpeakerListCopy[currentSpeakerIndex + 1] };
                nextSpeakerInfo.roundStartTimestamp = new Date().getTime();
                roundTableSpeakerListCopy.splice(currentSpeakerIndex + 1, 1);
                roundTableSpeakerListCopy.splice(currentSpeakerIndex + 1, 0, nextSpeakerInfo);

                const updateData = { roundTableSpeakerList: roundTableSpeakerListCopy, currentSpeakerIndex: currentSpeakerIndex + 1, roundTableRoomId: roomId };
                updateRoundTableStatusLocally(updateData);
                updateRoundTableStatusRemotely(updateData);
            } else {
                const updateData = {
                    currentSpeakerIndex: 0,
                    roundTableSpeakerList: [],
                    roundDurationInMinutes: '0',
                    isRoundTableActive: false,
                    roundTableRoomId: roomId
                };
                updateRoundTableStatusLocally(updateData);
                updateRoundTableStatusRemotely(updateData);
            }
        },
        [roundTableSpeakerList, currentSpeakerIndex, roundTableRoomId]
    );

    return {
        updateRoundTableStatusLocally,
        updateRoundTableStatusRemotely,
        roundDurationInMinutes,
        currentSpeakerIndex,
        roundTableSpeakerList,
        isRoundTableActive,
        includeSpeaker,
        removeSpeaker,
        nextSpeaker
    };
};

export default useRoundTableStateManager;
