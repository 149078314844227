import * as React from 'react';

function SvgVisitors(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11 4C8.803 4 7 5.803 7 8c0 1.152.51 2.176 1.299 2.908C6.949 11.806 6 13.266 6 15v1H4v2h14v2.617l2 1V28h6v-6.383l2-1V15c0-1.734-.948-3.194-2.299-4.092C26.49 10.176 27 9.152 27 8c0-2.197-1.803-4-4-4s-4 1.803-4 4c0 1.152.51 2.176 1.299 2.908C18.949 11.806 18 13.266 18 15v1h-2v-1c0-1.734-.948-3.194-2.299-4.092C14.49 10.176 15 9.152 15 8c0-2.197-1.803-4-4-4zm0 2c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm12 0c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm-12 6c1.668 0 3 1.332 3 3v1H8v-1c0-1.668 1.332-3 3-3zm12 0c1.669 0 3 1.332 3 3v4.383l-2 1V26h-2v-5.617l-2-1V15c0-1.668 1.331-3 3-3zm-13 8v1H7v7h10v-7h-3v-1h-4zm-1 3h6v3H9v-3z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgVisitors;
