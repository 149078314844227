import { DatePicker, Label } from '@fluentui/react';
import React from 'react';

interface IColorSelectorViewProps {
    label?: string;
    value: Date | undefined;
    onDateChange: (d: any) => void;
    minDate: Date | undefined;
}

const DateSelectorView: React.FC<IColorSelectorViewProps> = (props) => {
    const { label, value, onDateChange, minDate } = props;

    return (
        <>
            {label && <Label>{label}</Label>}
            <DatePicker value={value} onSelectDate={(d: Date | null | undefined) => onDateChange(d)} minDate={minDate} />
        </>
    );
};

export default DateSelectorView;
