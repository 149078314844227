import * as React from 'react';

function SvgDoorbell(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="1em" height="1em" {...props}>
            <path d="M15 3a2 2 0 00-2 2v.262C9.552 6.152 7 9.274 7 13v2.395c0 1.59-.327 3.152-.936 4.605h17.872A11.91 11.91 0 0123 15.395V13c0-3.726-2.552-6.849-6-7.738V5a2 2 0 00-2-2zM5 22a1 1 0 100 2h7.176A3 3 0 0012 25a3 3 0 003 3 3 3 0 003-3 3 3 0 00-.174-1H25a1 1 0 100-2H5z" />
        </svg>
    );
}

export default SvgDoorbell;
