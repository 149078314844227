import * as React from 'react';

function SvgKickOut(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="1em" height="1em" {...props}>
            <path d="M2 5.938l-2 .03v.125c-.008.196-.227 7.446 3.219 14.845 3.406 7.32 10.644 14.648 24.781 15v10.468l1.594-1.218 19-14.376L49.656 30l-1.062-.813-19-14.375L28 13.595v10.312c-12.34-.254-18.797-4.644-22.188-9.031C2.32 10.355 2 5.937 2 5.937zm1.031 8.28c.387.637.688 1.262 1.188 1.907C8.07 21.105 15.449 26 29 26h1v-8.375L46.344 30 30 42.375V34h-1C14.832 34 8.29 27.062 5.031 20.062c-.945-2.03-1.55-3.98-2-5.843z" />
        </svg>
    );
}

export default SvgKickOut;
