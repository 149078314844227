import { atomFamily } from 'recoil';
import { useTokenAtomEffects } from './effects';
//Token for Tennant
export const aIvAccessToken = atomFamily<string | undefined, string>({
    key: 'aIvAccessToken',
    default: undefined,
    effects_UNSTABLE: useTokenAtomEffects('iv-access')
});
export const aIvIdentityToken = atomFamily<string | undefined, string>({
    key: 'aIvIdentityToken',
    default: undefined,
    effects_UNSTABLE: useTokenAtomEffects('iv-identity')
});
export const aMsAccessToken = atomFamily<string | undefined, string>({
    key: 'aMsAccessToken',
    default: undefined,
    effects_UNSTABLE: useTokenAtomEffects('ms-access')
});

export const aJitsiToken = atomFamily<string | undefined, string>({
    key: 'aJitsiToken',
    default: undefined,
    effects_UNSTABLE: useTokenAtomEffects('jitsi-jwt')
});

export const aJitsiRoom = atomFamily<string | undefined, string>({
    key: 'aJitsiRoom',
    default: undefined,
    effects_UNSTABLE: useTokenAtomEffects('jitsi-room')
});
export const aJitsiDomain = atomFamily<string | undefined, string>({
    key: 'aJitsiDomain',
    default: undefined,
    effects_UNSTABLE: useTokenAtomEffects('jitsi-domain')
});
