import { AreaDto, CampusDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { OrgBrandingDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import { RoundTableSpeakerInfo } from 'features/Rooms/SpeakerStats/RoundTableStateManager';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { atom } from 'recoil';
import { IInvitationsChanges } from './types';

export const aAPILanguage = atom<string | undefined>({ key: 'aAPILanguage', default: undefined });
export const aReviewInvitations = atom<boolean>({ key: 'aReviewInvitations', default: true });

export const aRoomList = atom<IRoomResource[]>({ key: 'aRoomList', default: [] });

export const aHandRaiseButtonDisabled = atom<any>({ key: 'aHandRaiseButtonDisabled', default: false });
export const aRoundTableSpeakerList = atom<RoundTableSpeakerInfo[]>({ key: 'aRoundTableSpeakerList', default: [] });

export const aRoomListStyles = atom<any>({ key: 'aRoomListStyles', default: {} });
export const aSelectedVisitor = atom<string | undefined>({ key: 'aSelectedVisitor', default: undefined });

export const aCampus = atom<CampusDto | undefined>({ key: 'aCampus', default: undefined });
export const aAreaList = atom<AreaDto[]>({ key: 'aAreaList', default: [] });

export const aOrgBranding = atom<OrgBrandingDto | undefined>({ key: 'aOrgBranding', default: undefined });

export const aInvitationsChanges = atom<IInvitationsChanges[]>({ key: 'aInvitationsChanges', default: [] });

export const aCurrentSpeakerNameForTimer = atom<string>({ key: 'aCurrentSpeakerNameForTimer', default: '' });

export const aLastRoundTimeLeft = atom<number>({ key: 'aLastRoundTimeLeft', default: 0 });
