import jwt from 'jwt-decode';
import { signMicrosoftOut } from 'kits/msKit';
import { getSession, useSessionIdentity } from 'kits/sessionKit';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getTokens } from 'services/api/token-storage.service';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
export interface IIdentity {
    type: string;
    uid: string;
    upn: string;
    areas: Array<string>;
    displayName: string;
    idp: string;
    roles: Array<string>;
    tid?: string;
    sub?: string;
    iss?: string;
    exp?: Date;
    iat?: Date;
    aud?: string;
}

export const getUserId = (): any => sessionStorage.getItem('iv-uid');

export const getIdToken = (): any => sessionStorage.getItem('iv-id-token');
export const getAccessToken = (): any => sessionStorage.getItem('iv-token');

export const idTokenIsPresent = (): any => (getIdToken() ? true : false);
export const accessTokenIsPresent = (): any => (getAccessToken() ? true : false);
export const isVisitor = (identity: IIdentity): any => identity.roles && identity.roles.includes('visitor');

export interface IAuthError {
    code: number;
    title?: string;
    detail?: string;
}

export function useUserIdentity(): [IIdentity | undefined, () => void, IAuthError[]] {
    const [identity, setIdentity] = useSessionIdentity('user');
    const [, setIdentityClaims] = useIdentityClaims();
    const [authErrors] = useState<IAuthError[]>([]);

    const fetchData = async () => {
        const [msAccessToken] = getSession('@msAccessToken');
        if (!msAccessToken || msAccessToken == 'undefined' || msAccessToken == null) return;

        const token = localStorage.getItem('ivAccessToken');
        if (!token) return;

        const userData = jwt<any>(token);
        if (!userData || !userData.sub) return;

        const userSub: string = userData.sub;

        const userIdentity: IIdentityClaims = {
            type: userData.type,
            uid: userSub.split(':')[2],
            upn: userData.email,
            areas: ['*'],
            displayName: userData.name,
            idp: userSub.split(':')[0],
            roles: [userData.role],
            tid: userSub.split(':')[1]
        };
        sessionStorage.setItem('iv-token', token);

        sessionStorage.setItem('iv-uid', userIdentity.uid);
        setIdentity(userIdentity);
        setIdentityClaims(userIdentity);
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let isCanceled = false;
        fetchData();

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isCanceled = true;
        };
    }, []);

    return [identity, fetchData, authErrors];
}

export function useVisitorIdentity(): [IIdentity | undefined, () => void, boolean] {
    const [identity, setIdentity] = useSessionIdentity('visitor');
    const [, setIdentityClaims] = useIdentityClaims();

    const [isCacheStale, setIsCacheStale] = useState<boolean>(true);

    const routeHistory = useNavigate();

    const fetchData = async () => {
        // Fetches new state
        const tokens = localStorage.getItem('ivAccessToken');
        const confirmationToken = tokens?.split(':')[1];
        if (!confirmationToken) {
            //Error
            console.log('err');
            return routeHistory('/error/403');
        }

        const userData = jwt<any>(confirmationToken);
        if (!userData || !userData.sub) return;

        const userSub: string = userData.sub;

        const userIdentity: IIdentityClaims = {
            type: userData.type,
            uid: userSub.split(':')[2],
            upn: userData.email,
            areas: ['*'],
            displayName: userData.name,
            idp: userSub.split(':')[0],
            roles: [userData.role],
            tid: userSub.split(':')[1]
        };

        setIsCacheStale(false);

        sessionStorage.setItem('iv-uid', userIdentity.uid);
        setIdentity(userIdentity);
        setIdentityClaims(userIdentity);
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let isCanceled = false;
        fetchData();

        return () => {
            isCanceled = true;
        };
    }, []);

    return [identity, fetchData, isCacheStale];
}

export function isLoggedIn(): boolean {
    const tokens = getTokens();
    const decodedToken: any = jwt(tokens.accessToken);

    if (!tokens.accessToken || tokens.accessToken === 'undefined' || !decodedToken) {
        return false;
    }

    if (decodedToken.role === 'auto-confirmed-visitor' && decodedToken.confirmed_by === 'auto-confirmed' && typeof tokens.confirmationToken === 'undefined') {
        return true;
    }

    if (decodedToken.role === 'unconfirmed_visitor' && typeof tokens.confirmationToken !== 'undefined') {
        return true;
    }

    return false;
}

export function signOut(includeMS?: boolean): any {
    sessionStorage.clear();
    localStorage.clear();

    includeMS && signMicrosoftOut();

    window.location.href = '/';
}
