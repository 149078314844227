import * as React from 'react';

function SvgInsideBill(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.1165 5.68332V5.54937L1.04953 5.43336L0.68325 4.79881L8.01445 0.576981L15.3456 4.79881L14.9794 5.43336L14.9124 5.54937V5.68332V15.5H1.1165V5.68332ZM8.264 0.988765L8.01445 0.845028L7.7649 0.988765L1.59993 4.53964L1.34949 4.68389V4.97291V14.767V15.267H1.84949H14.1794H14.6794V14.767V4.97291V4.68389L14.429 4.53964L8.264 0.988765ZM6.57619 10.1602L7.08439 9.81672L6.64552 9.38819C6.27638 9.02776 6.04846 8.53084 6.04846 7.98555C6.04846 6.90707 6.93597 6.01957 8.01445 6.01957C9.09293 6.01957 9.98043 6.90707 9.98043 7.98555C9.98043 8.53084 9.75252 9.02776 9.38338 9.38819L8.94451 9.81672L9.45271 10.1602C10.0105 10.5372 10.4133 11.1232 10.5479 11.801H10.3114C10.0844 10.7393 9.14744 9.95154 8.01445 9.95154C6.88146 9.95154 5.94454 10.7393 5.71749 11.801H5.48098C5.61559 11.1232 6.01836 10.5372 6.57619 10.1602ZM9.74744 7.98555C9.74744 7.02114 8.97886 6.25256 8.01445 6.25256C7.05004 6.25256 6.28146 7.02114 6.28146 7.98555C6.28146 8.94996 7.05004 9.71854 8.01445 9.71854C8.97886 9.71854 9.74744 8.94996 9.74744 7.98555Z"
                fill="#000"
                stroke="#0078d4"
            />
        </svg>
    );
}

export default SvgInsideBill;
