import { Stack, Text } from '@fluentui/react';
import { Depths } from '@uifabric/fluent-theme';
import { InvitationDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import InvitationLogo from 'components/InvitationLogo/InvitationLogo';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import { useOrganizationBranding } from 'features/Header/hooks';
import { signOut } from 'kits/authKit';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';
import { Params, useParams } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { useCurrentOrgId } from 'shared-state/location/hook';
import defaultVisitorEntranceBG from '../../media/img/default-bgve.jpg';

export interface IVisitorsInvalidInvitationRouteParams extends Params {
    invitationId: string;
}

const VisitorsExit: React.FC = () => {
    const params = useParams<IVisitorsInvalidInvitationRouteParams>();
    const [orgId, setOrgId] = useCurrentOrgId();
    const [invitation, setInvitation] = useState<InvitationDto | undefined>(undefined);

    const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState(true);

    const clean = () => {
        setTimeout(() => {
            setIsLoadingDialogVisible(false);
        }, 1000);
    };

    clean();

    const fetchInvitation = async () => {
        try {
            const nextInvitation = await idpService.showInvitation(undefined, params.invitationId);

            setOrgId(nextInvitation.orgId);
            setInvitation(nextInvitation);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        fetchInvitation();
    }, [params.invitationId]);

    const [branding] = useOrganizationBranding(orgId);
    const customFarewellMessage = branding && branding.visitor_farewell_message;
    const customBg = branding && branding.visitor_entrance_background_image;

    // If the visitor presses back button after someone kicked them out from the campus.
    window.addEventListener('popstate', () => {
        signOut(false);
    });

    return (
        <>
            {isLoadingDialogVisible && <LoadingDialog loadingStrings={[IvicosStrings.loadingTitle]} view="full" typewriter={false} />}
            <Stack
                grow
                verticalAlign="center"
                style={{
                    backgroundImage: `url(${customBg || defaultVisitorEntranceBG})`,
                    height: '100vh',
                    width: '100vw',
                    alignItems: 'center',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                <Stack>{<InvitationLogo eventName={invitation?.event_name} branding={branding} />}</Stack>
                <Stack style={{ boxShadow: Depths.depth64, width: 350, height: 300, backgroundColor: '#FFFFFF' }}>
                    <Stack style={{ width: 350, height: 300, backgroundColor: '#FFFFFF' }}>
                        <Stack style={{ margin: 20 }}>
                            <Text style={{ marginTop: 20, fontWeight: 'bold' }} variant="large">
                                {IvicosStrings.thanksForVisitMessage}
                            </Text>
                            <Text style={{ marginTop: 50, wordBreak: 'break-word' }} variant="medium">
                                {customFarewellMessage}
                            </Text>
                        </Stack>
                    </Stack>
                    <Stack style={{ height: 50, backgroundColor: '#FFFFFF', margin: 20 }}>
                        <Text variant="small">{IvicosStrings.VisitorExitDescription1}</Text>
                        <Stack horizontal verticalAlign="center">
                            <Text variant="small" style={{ marginTop: 3 }}>
                                {IvicosStrings.VisitorExitDescription2}
                            </Text>

                            <Stack style={{ marginLeft: 3 }}>
                                <a href="https://ivicos.eu" target="_blank" rel="noreferrer">
                                    <Text variant="small">{IvicosStrings.VisitorExitDescription3}</Text>
                                </a>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default VisitorsExit;
