import React from 'react';
import { useDirectMemoComposerState } from './DirectMemoComposer.state';
import DirectMemoComposerView from './DirectMemoComposer.view';

const DirectMemoComposer: React.FC<{}> = () => {
    const state = useDirectMemoComposerState();

    return (
        <DirectMemoComposerView
            {...{
                ...state
            }}
        />
    );
};
export default DirectMemoComposer;
