import React from 'react';
import ResetWizardView from './ResetWizard.view';

const onClickEvent = () => {
    localStorage.clear();
    sessionStorage.clear();
    return (window.location.href = '/');
};

const ResetWizard = (): any => {
    return <ResetWizardView onClickEvent={onClickEvent} />;
};

export default ResetWizard;
