import { Callout, DirectionalHint, getTheme, IconButton, PersonaSize, Stack, Text } from '@fluentui/react';
import DynamicAvatar from 'components/UserImage/DynamicAvatar';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { ISpeakerStatsUserProps } from './SpeakerStatsUser';
import { ISpeakerStatsUserState } from './SpeakerStatsUser.state';

export interface ISpeakerStatsUserViewProps extends ISpeakerStatsUserState, ISpeakerStatsUserProps {}

const SpeakerStatsUserView: React.FC<ISpeakerStatsUserViewProps> = (props) => {
    const {
        speakerTime,
        totalTime,
        userName,
        prettifyTime,
        profileImage,
        userType,
        colorReturner,
        roundTableIsActive,
        avatarType,
        currentSpeakerIndex,
        roundTableSpeakersList,
        userIndexAtRoundTableSpeakerList,
        buttonId,
        isCalloutVisible,
        SetIsCalloutVisible,
        onIncludeSpeaker,
        onRemoveSpeaker
    } = props;
    const COLORS = ['#0B84A5', '#F6C85F', '#6F4E7C', '#9DD866', '#CA472F', '#FFA056', '#8DDDD0', '#0ba59d', '#5f9ef6', '#8c21cf', '#b01b00'];
    const talkTimeColor = COLORS[colorReturner(userName)];
    const theme = getTheme();

    let dynamicAvatarText = 'Invalid Name';
    if (userName)
        dynamicAvatarText = userName.split(' ').length > 1 ? `${userName.split(' ')[0]} ${userName.split(' ')[1].slice(0, 1)}.` : `${userName.split(' ')[0]}`;

    const userIsIncludable =
        currentSpeakerIndex !== undefined && userIndexAtRoundTableSpeakerList !== undefined && currentSpeakerIndex > userIndexAtRoundTableSpeakerList;
    const userIsRemovable =
        currentSpeakerIndex !== undefined && userIndexAtRoundTableSpeakerList !== undefined && currentSpeakerIndex <= userIndexAtRoundTableSpeakerList;
    return (
        <>
            <Stack style={{ margin: '1vh 0vh', position: 'relative', justifyContent: 'center', color: theme.palette.white }}>
                <Stack style={{ paddingLeft: 46 }}>
                    <Stack
                        style={{
                            height: 32,
                            borderRadius: 2,
                            width: `${speakerTime && (speakerTime / totalTime) * 100}%`,
                            backgroundColor: talkTimeColor,
                            opacity: 0.4
                        }}
                    ></Stack>
                </Stack>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    verticalAlign="center"
                    style={{
                        height: 32,
                        width: '100%',
                        paddingRight: 16,
                        position: 'absolute',
                        padding: 8,
                        textAlign: 'center',
                        fontWeight: 'bolder'
                    }}
                >
                    <Stack grow>
                        <DynamicAvatar
                            text={dynamicAvatarText}
                            imageUrl={profileImage}
                            sizePersona={PersonaSize.size32}
                            sizeBadge="30"
                            fontSize="small"
                            displayBadgeName={true}
                            userType={userType}
                            talkTimeColor={talkTimeColor}
                            roundTableIsActive={roundTableIsActive}
                            avatarType={
                                roundTableIsActive &&
                                currentSpeakerIndex !== undefined &&
                                roundTableSpeakersList &&
                                roundTableSpeakersList[currentSpeakerIndex].userId === userName
                                    ? 'round-table-first'
                                    : avatarType
                            }
                        />
                    </Stack>
                    {!roundTableIsActive && <Text style={{ fontSize: '20px', color: theme.palette.black }}>{speakerTime && prettifyTime(speakerTime)}</Text>}
                    {onRemoveSpeaker !== undefined && userIsRemovable && (
                        <Stack style={{ padding: 10, cursor: 'pointer' }} id={buttonId}>
                            <IconButton
                                iconProps={{ iconName: 'Remove' }}
                                ariaLabel="Remove User"
                                onClick={() =>
                                    onRemoveSpeaker({
                                        userId: userName,
                                        roundStartTimestamp: 0,
                                        isInTheRoom: true
                                    })
                                }
                                onMouseEnter={() => SetIsCalloutVisible(true)}
                                onMouseLeave={() => SetIsCalloutVisible(false)}
                            />
                        </Stack>
                    )}

                    {onIncludeSpeaker !== undefined && userIsIncludable && (
                        <Stack style={{ padding: 10, cursor: 'pointer' }} id={buttonId}>
                            <IconButton
                                iconProps={{ iconName: 'Add' }}
                                ariaLabel="Add User"
                                onClick={() =>
                                    onIncludeSpeaker({
                                        userId: userName,
                                        roundStartTimestamp: 0,
                                        isInTheRoom: true
                                    })
                                }
                                onMouseEnter={() => SetIsCalloutVisible(true)}
                                onMouseLeave={() => SetIsCalloutVisible(false)}
                            />
                        </Stack>
                    )}
                </Stack>
            </Stack>

            <Stack style={{ width: '100%', height: '1px', backgroundColor: 'lightgray' }}></Stack>

            {isCalloutVisible && roundTableIsActive && (
                <Callout role="alertdialog" directionalHint={DirectionalHint.topRightEdge} gapSpace={0} target={`#${buttonId}`} setInitialFocus>
                    {onRemoveSpeaker !== undefined && userIsRemovable && (
                        <Stack
                            style={{ padding: 10, cursor: 'pointer' }}
                            onClick={() =>
                                onRemoveSpeaker({
                                    userId: userName,
                                    roundStartTimestamp: 0,
                                    isInTheRoom: true
                                })
                            }
                        >
                            <Text>{IvicosStrings.removeUserFromRoundTableButtonLabel}</Text>
                        </Stack>
                    )}

                    {onIncludeSpeaker !== undefined && userIsIncludable && (
                        <Stack
                            style={{ padding: 10, cursor: 'pointer' }}
                            onClick={() =>
                                onIncludeSpeaker({
                                    userId: userName,
                                    roundStartTimestamp: 0,
                                    isInTheRoom: true
                                })
                            }
                        >
                            <Text>{IvicosStrings.addUserToRoundTableButtonLabel}</Text>
                        </Stack>
                    )}
                </Callout>
            )}
        </>
    );
};
export default SpeakerStatsUserView;
