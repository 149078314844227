import { ICallAction } from 'features/Rooms/RoomView/CallActions';
import 'intro.js/introjs.css';
import React from 'react';
import { useCallActionBarState } from './CallActionBar.state';
import CallActionBarView from './CallActionBar.view';

export interface ICallActionGroup {
    key: string;
    actions: Array<ICallAction>;
}
export interface ICallActionBarProps {
    actions: Array<ICallActionGroup>;
    onActionPressed: (a: ICallAction) => void;
    visible?: boolean;
}
const CallActionBar: React.FC<ICallActionBarProps> = (props) => {
    const state = useCallActionBarState(props.actions);

    return (
        <CallActionBarView
            {...{
                ...state,
                ...{
                    actions: props.actions,
                    onActionPressed: props.onActionPressed,
                    visible: props.visible
                }
            }}
        ></CallActionBarView>
    );
};
export default CallActionBar;
