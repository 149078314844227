/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import * as Dto from './responses.dto';
// import { getAuthHeader } from '../services/auth_header';
import { AbstractService } from '../shared/abstract-service';

export class CampusApiService extends AbstractService {
    private readonly campusUrl: string;
    private readonly areaUrl: Function;
    private readonly roomUrl: Function;
    private readonly attachmentUrl: Function;
    private readonly changelogUrl: string;

    constructor(baseUrl: string, headerRetrievalFunction?: () => { [index: string]: string }) {
        super(baseUrl, headerRetrievalFunction);
        this.campusUrl = `${this.baseUrl}/campuses`;
        this.areaUrl = (campusId: string) => `${this.campusUrl}/${campusId}/areas`;
        this.roomUrl = (campusId: string, areaId: string) => `${this.areaUrl(campusId)}/${areaId}/rooms`;
        this.attachmentUrl = (campusId: string, areaId: string, roomId: string) => `${this.roomUrl(campusId, areaId)}/${roomId}/attachments`;
        this.changelogUrl = `${this.baseUrl}/changelog`;
    }

    // Campus Controller
    // MUST REVIEW CAMPUS ENTITY AND DECIDE WHAT KEEPS IN THE CAMPUS ENTITY AND WHAT MOVES TO THE ORGANIZATION ENTITY
    public async createCampus(provider: string, orgId: string, name: string, domain: string, chosenLang: string): Promise<any> {
        const response = await axios.post(
            `${this.campusUrl}`,
            {
                identityProvider: provider,
                orgId: orgId,
                name: name,
                domain: domain,
                chosenLang: chosenLang
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 201) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.CampusDto;
    }

    public async getCampusById(campusId: string) {
        const response = await axios.get(`${this.campusUrl}/${campusId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.CampusDto;
    }

    public async updateCampus(campusId: string, changes: { name: string }) {
        const { name } = changes;
        const response = await axios.patch(
            `${this.campusUrl}/${campusId}`,
            {
                name: name
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.CampusDto;
    }

    // Area Controller
    public async createArea(campusId: string, name: string) {
        const response = await axios.post(
            `${this.areaUrl(campusId)}`,
            {
                name: name
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 201) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AreaDto;
    }

    public async getAllForCampus(campusId: string) {
        const response = await axios.get(`${this.areaUrl(campusId)}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AllAreasDto;
    }

    public async getAreaById(campusId: string, areaId: string) {
        const response = await axios.get(`${this.areaUrl(campusId)}/${areaId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AreaDto;
    }

    public async updateArea(campusId: string, areaId: string, changes: { name: string }) {
        const { name } = changes;
        const response = await axios.patch(
            `${this.areaUrl(campusId)}/${areaId}`,
            {
                name: name
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AreaDto;
    }

    public async deleteArea(campusId: string, areaId: string) {
        const response = await axios.delete(`${this.areaUrl(campusId)}/${areaId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 204) {
            throw new Error(response.statusText);
        }
    }

    // Room Controller
    public async createRoom(
        campusId: string,
        areaId: string,
        attributes: { name: string; iconKey: string; isAudioOnly: boolean; isOpenForVisitors: boolean; isPrivate: boolean; backgroundImageUrl?: string }
    ) {
        const response = await axios.post(`${this.roomUrl(campusId, areaId)}`, attributes, {
            headers: this.getHeaders()
        });

        if (response.status != 201) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.RoomDto;
    }

    public async getAllForArea(campusId: string, areaId: string) {
        const response = await axios.get(`${this.roomUrl(campusId, areaId)}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AllRoomsDto;
    }

    public async getRoomById(campusId: string, areaId: string, roomId: string) {
        const response = await axios.get(`${this.roomUrl(campusId, areaId)}/${roomId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.RoomDto;
    }

    public async updateRoom(
        campusId: string,
        areaId: string,
        roomId: string,
        changes: {
            name?: string;
            iconKey?: string;
            isAudioOnly?: boolean;
            isOpenForVisitors?: boolean;
            isPrivate?: boolean;
            backgroundImageUrl?: string;
            whitelist?: string[];
        }
    ) {
        const response = await axios.patch(`${this.roomUrl(campusId, areaId)}/${roomId}`, changes, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.RoomDto;
    }

    public async archiveRoom(campusId: string, areaId: string, roomId: string) {
        const response = await axios.patch(
            `${this.roomUrl(campusId, areaId)}/${roomId}/archive`,
            { isArchived: true },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }
    }

    // Attachment Controller
    public async getAllForRoom(campusId: string, areaId: string, roomId: string) {
        const response = await axios.get(`${this.attachmentUrl(campusId, areaId, roomId)}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AllAttachmentsDto;
    }

    public async getAttachmentById(campusId: string, areaId: string, roomId: string, attachmentId: string) {
        const response = await axios.get(`${this.attachmentUrl(campusId, areaId, roomId)}/${attachmentId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AttachmentDto;
    }

    public async createAttachment(
        campusId: string,
        areaId: string,
        roomId: string,
        addOnName: string,
        ref: string,
        isOpenForVisitors: boolean,
        isPinned: boolean
    ) {
        const response = await axios.post(
            `${this.attachmentUrl(campusId, areaId, roomId)}`,
            {
                addOnName: addOnName,
                ref: ref,
                isOpenForVisitors: isOpenForVisitors,
                isPinned: isPinned
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 201) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AttachmentDto;
    }

    public async updateAttachment(
        campusId: string,
        areaId: string,
        roomId: string,
        attachmentId: string,
        changes: { addOnName?: string; description?: string; title?: string; ref?: string; isOpenForVisitors?: boolean; isPinned?: boolean }
    ) {
        const response = await axios.patch(`${this.attachmentUrl(campusId, areaId, roomId)}/${attachmentId}`, changes, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AttachmentDto;
    }

    public async reorderAttachments(campusId: string, areaId: string, roomId: string, newOrder: { attachmentId: string; position: string }[]) {
        const response = await axios.patch(
            `${this.attachmentUrl(campusId, areaId, roomId)}-reorder`,
            {
                newOrder: newOrder
            },
            {
                headers: this.getHeaders()
            }
        );

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AllAttachmentsDto;
    }

    public async deleteAttachment(campusId: string, areaId: string, roomId: string, attachmentId: string) {
        const response = await axios.delete(`${this.attachmentUrl(campusId, areaId, roomId)}/${attachmentId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }
    }

    // Changelog Controller
    public async getAllChangelogs() {
        const response = await axios.get(`${this.changelogUrl}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.AllChangelogsDto;
    }

    public async getChangelogById(changelogId: string) {
        const response = await axios.get(`${this.changelogUrl}/${changelogId}`, {
            headers: this.getHeaders()
        });

        if (response.status != 200) {
            throw new Error(response.statusText);
        }

        return response.data as Dto.ChangelogDto;
    }
}
