import { getTheme, ITheme, PersonaPresence } from '@fluentui/react';
import { availabilityToPersonaPresence, UserAvailability } from 'kits/availabilityKit';
import { useEffect } from 'react';
import { SetterOrUpdater } from 'recoil';
import { useIdentityClaims, useSharedAvailability } from 'shared-state/identity/hooks';

export interface IAvailabilitySelectorState {
    theme: ITheme;
    sharedAvailability?: UserAvailability;
    setSharedAvailability: SetterOrUpdater<UserAvailability | undefined>;
}

export const useAvailabilitySelectorState: () => IAvailabilitySelectorState = () => {
    const theme = getTheme();
    const [identity] = useIdentityClaims();
    const [sharedAvailability, setSharedAvailability] = useSharedAvailability();
    const [localAvailability, setLocalAvailability] = useSharedAvailability();

    const presenceFromIdClaims = identity
        ? {
              presence: (localAvailability && availabilityToPersonaPresence(localAvailability)) || PersonaPresence.online
          }
        : undefined;

    useEffect(() => {
        // function saves the user's status when it is available
        presenceFromIdClaims?.presence === 2 ? setLocalAvailability('Available') : null;
    }, [presenceFromIdClaims?.presence]);

    return {
        theme,
        sharedAvailability,
        setSharedAvailability
    };
};
