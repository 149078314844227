import React from 'react';
import useCrossAreaSearchPerson from './CrossAreaSearchPerson.state';
import CrossAreaSearchPersonView from './CrossAreaSearchPerson.view';

const CrossAreaSearchPerson: React.FC<{}> = () => {
    const state = useCrossAreaSearchPerson();

    return <CrossAreaSearchPersonView {...state}></CrossAreaSearchPersonView>;
};
export default CrossAreaSearchPerson;
