import LocalizedStrings from 'react-localization';
import { de } from './langs/de';
import { en } from './langs/en';
import { IStrings } from './stringsType';

const IvicosStrings: IStrings = new LocalizedStrings({
    en: en,
    de: de
});

export default IvicosStrings;
