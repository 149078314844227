import { useEffect, useState } from 'react';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';

export interface IHostPerspectiveWaitingAreaState {
    areaId?: string;
    campusId?: string;
    roomId?: string;
    isLoadingDialogVisible: boolean;
}

export const useHostPerspectiveWaitingAreaState: () => IHostPerspectiveWaitingAreaState = () => {
    const [areaId, setAreaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();
    const [roomId] = useCurrentRoomId();

    useEffect(() => {
        setAreaId('waiting-area');
    }, []);

    const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState(true);

    const clean = () => {
        setTimeout(() => {
            setIsLoadingDialogVisible(false);
        }, 1000);
    };

    clean();

    return { areaId, campusId, roomId, isLoadingDialogVisible };
};
