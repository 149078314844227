import * as React from 'react';

function SvgLoading(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 4C9.385 4 4 9.385 4 16c0 .73.065 1.462.195 2.172l1.97-.36A10.057 10.057 0 016 16c0-5.534 4.466-10 10-10 5.535 0 10 4.466 10 10h2c0-6.615-5.384-12-12-12zM6.768 19.85l-1.846.77a12.038 12.038 0 001.738 2.915L8.22 22.28a10.01 10.01 0 01-1.451-2.43zm16.97 2.486c-.42.51-.891.98-1.402 1.4l1.27 1.545a12.086 12.086 0 001.677-1.677l-1.545-1.268zM9.721 23.783L8.465 25.34a12 12 0 002.916 1.738l.77-1.846a9.946 9.946 0 01-2.43-1.449zm10.968 1.053a9.89 9.89 0 01-1.83.75l.569 1.918c.758-.226 1.499-.53 2.2-.902l-.939-1.766zm-6.5 1.002l-.36 1.967a12.116 12.116 0 003.259.146l-.178-1.992a10.117 10.117 0 01-2.72-.121z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgLoading;
