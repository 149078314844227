import React from 'react';
import 'styles/helpers.css';
import { useDirectMessagingState } from './DirectMessaging.state';
import DirectMessagingView from './DirectMessaging.view';
import { IDirectMessagingProps } from '../dataTypes';

const DirectMessaging: React.FC<IDirectMessagingProps> = (props) => {
    const state = useDirectMessagingState();

    return (
        <DirectMessagingView
            {...{
                ...state,
                ...{
                    mobileView: props.mobileView
                }
            }}
        ></DirectMessagingView>
    );
};

export default DirectMessaging;
