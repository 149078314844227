import { useCallback, useContext } from 'react';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import SocketContext from './context/SocketContext';
import { CLIENT_EMIT_MESSAGE_EVENT, CLIENT_EMIT_TOKEN_TO_CONFIRM_VISITOR } from './events';
import { useEmittingEvents } from './socket-adapters/event-actions/emittingEvents';
import { TMessagingEvent } from './types';
import { v1 as uuidv1 } from 'uuid';
import { useEffect, useRef } from 'react';
import io, { ManagerOptions, Socket, SocketOptions } from 'socket.io-client';

export const useSocket = (url: string, options?: Partial<ManagerOptions & SocketOptions> | undefined): Socket => {
    const { current: socket } = useRef(io(url, options));

    useEffect(() => {
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]);

    return socket;
};

export const useJitsiSessionIdHandler = (): any => {
    const [roomId] = useCurrentRoomId();
    const { sendAccessResponse } = useEmittingEvents();

    const handleSessionIdRequest = useCallback(
        (userId: string, requestedRoomId: string) => {
            if (roomId == requestedRoomId) {
                sendAccessResponse(userId, localStorage.getItem('jitsiSessionId') || 'undefined');
            }
        },
        [roomId, sendAccessResponse]
    );

    const handleSessionIdResponse = useCallback((jitsiSessionId: string) => {
        if (localStorage.getItem('jitsiSessionId') !== jitsiSessionId) {
            // routeHistory.push('/areas/'+areaId);
        }
    }, []);
    return { handleSessionIdRequest, handleSessionIdResponse };
};

export const useJitsiSessionRouter = (): any => {
    const conditionRouting = useCallback(() => {
        const newJitsiSessionId = uuidv1();
        localStorage.setItem('jitsiSessionId', newJitsiSessionId);
    }, []);
    return { conditionRouting };
};

export const useMessagingEvents = (): ((messagingEvent: TMessagingEvent) => void) => {
    const [areaId] = useCurrentAreaId();
    const {
        SocketState: { socket }
    } = useContext(SocketContext);

    const sendMessagingEvent = useCallback(
        (messagingEvent: TMessagingEvent) => {
            areaId && socket && socket.emit(CLIENT_EMIT_MESSAGE_EVENT, messagingEvent);
        },
        [areaId, socket]
    );
    return sendMessagingEvent;
};

export const useTokenSender = (): any => {
    const {
        SocketState: { socket }
    } = useContext(SocketContext);

    return useCallback(
        (token: string, user: string, destinationRoom?: string, destinationArea?: string, noVideoRoom?: boolean, isWhitelisted?: boolean) =>
            socket &&
            socket.emit(CLIENT_EMIT_TOKEN_TO_CONFIRM_VISITOR, {
                type: 'visitor-access',
                meta: {
                    forUser: user,
                    attachedRoom: destinationRoom,
                    noVideoRoom: noVideoRoom,
                    attachedArea: destinationArea,
                    isWhitelisted: isWhitelisted
                },
                token: token
            }),
        [socket]
    );
};
