import { ActionButton, Dialog, DialogFooter, DialogType, IconButton, IIconProps, Modal, PrimaryButton, Stack, Text } from '@fluentui/react';
import { IHelpModalState } from 'features/Header/children/HelpModal/HelpModal.state';
import IconKit from 'kits/IconKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

const HelpModalView: React.FC<IHelpModalState> = (props) => {
    const {
        isHelpModalOpen,
        onHelpModalDismiss,
        openHelpCentrePage,
        openReportErrorPage,
        refreshPage,
        isTutorialResetDialogOpen
        //! deactivated for the time being
        // resetTutorials
    } = props;

    const dialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.dialogBoxTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle,
        subText: IvicosStrings.dialogBoxDescription
    };

    //! deactivated for the time being
    // const refreshIcon: IIconProps = { iconName: 'Refresh' };
    const helpCentreIcon: IIconProps = { iconName: 'Help' };
    const reportBugIcon: IIconProps = { iconName: 'Error' };

    return (
        <>
            <Modal aria-label="help-modal" isOpen={isHelpModalOpen} onDismiss={() => onHelpModalDismiss()} isBlocking={false} className="help-modal-class">
                <Stack horizontal horizontalAlign="end">
                    <IconButton
                        iconProps={{ iconName: 'cancel' }}
                        ariaLabel="close popup modal"
                        onClick={() => onHelpModalDismiss()}
                        data-test-id="help-modal-close"
                    />
                </Stack>
                <Stack
                    verticalAlign={'center'}
                    horizontalAlign={'start'}
                    tokens={{ childrenGap: 8 }}
                    style={{ paddingBottom: '20px', marginRight: '10%', marginLeft: '20%' }}
                >
                    <Stack horizontal horizontalAlign={'center'} style={{ marginBottom: '10px', marginLeft: '7px' }} tokens={{ childrenGap: 5 }}>
                        <IconKit.Icon icon={IconKit.IconSet.Small.Help} />
                        <Text variant={'xLarge'}>{IvicosStrings.helpCentreActionTitle}</Text>
                    </Stack>
                    <Stack style={{ marginRight: '2px' }}>
                        {/*
                        Disable code for the time being as it is not working properly and also a bit outdated. So it is being deactivated for now. In future, it can be reactivated or removed.
                        */}
                        {/* <ActionButton iconProps={refreshIcon} text={IvicosStrings.resetTutorialsActionTitle} onClick={() => resetTutorials()}></ActionButton> */}
                        <ActionButton iconProps={helpCentreIcon} text={IvicosStrings.helpCentreActionTitle} onClick={() => openHelpCentrePage()}></ActionButton>
                        <ActionButton
                            iconProps={reportBugIcon}
                            text={IvicosStrings.reportErrorActionTitle}
                            onClick={() => openReportErrorPage()}
                        ></ActionButton>
                    </Stack>
                </Stack>
            </Modal>

            <Dialog hidden={!isTutorialResetDialogOpen} onDismiss={() => refreshPage()} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <PrimaryButton onClick={() => refreshPage()} text={IvicosStrings.refreshPageActionTitle} />
                </DialogFooter>
            </Dialog>
        </>
    );
};
export default HelpModalView;
