import * as React from 'react';

function SvgBraveLogo(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M26.117 10.212l-.927-2.507.638-1.438c.08-.19.04-.4-.1-.55L23.983 3.95a2.823 2.823 0 00-2.93-.679l-.489.17L17.892.545l-4.516-.03h-.03L8.8.555 6.138 3.47 5.66 3.3a2.797 2.797 0 00-2.951.689L.934 5.788c-.12.12-.15.29-.09.439l.668 1.498-.917 2.507.598 2.267 2.712 10.316a5.238 5.238 0 002.034 2.946s3.29 2.327 6.53 4.434c.289.19.588.32.907.32.319 0 .618-.13.907-.32 3.649-2.397 6.53-4.444 6.53-4.444a5.27 5.27 0 002.024-2.946l2.692-10.316.588-2.277z"
                fill="#F15A22"
            />
            <path
                style={{
                    mixBlendMode: 'lighten'
                }}
                opacity={0.15}
                d="M4.034 23.253L.595 10.45l1.007-2.507-.698-1.857 1.665-1.698c.548-.49 1.625-.659 2.123-.37l2.603 1.499 3.39.789 2.641-1.099.22 22.74c-.04 3.275.169 2.926-2.234 1.378L5.38 25.34c-.638-.609-1.126-1.298-1.346-2.087z"
                fill="url(#brave-logo_svg__paint0_linear_74_189)"
            />
            <path
                style={{
                    mixBlendMode: 'darken'
                }}
                opacity={0.4}
                d="M20.753 25.705l-5.044 3.456c-1.406.768-2.084 1.527-2.194 1.158-.09-.29-.02-1.139-.05-2.457l-.06-22.24c.01-.22.16-.59.42-.55l2.572.78 3.708-.58 2.453-1.807a.68.68 0 01.877.05l2.193 2.097c.2.21.21.62.09.879l-.608 1.128 1.007 2.607-3.47 12.922c-.538 1.608-1.295 2.028-1.894 2.557z"
                fill="url(#brave-logo_svg__paint1_linear_74_189)"
            />
            <path
                d="M13.954 18.969c-.12-.05-.25-.09-.29-.09h-.319c-.039 0-.169.04-.288.09l-1.296.54c-.12.049-.32.139-.439.199l-1.954 1.018c-.12.06-.13.17-.02.25l1.725 1.219c.11.08.279.21.379.3l.767.658.36.32.737.659c.1.09.26.09.359 0l.758-.66c.1-.09.259-.229.358-.319l.768-.669c.1-.09.27-.22.379-.3l1.725-1.228c.11-.08.1-.19-.02-.25l-1.954-.998c-.12-.06-.32-.15-.439-.2l-1.296-.54z"
                fill="#fff"
            />
            <path
                d="M23.307 10.657c.04-.13.04-.18.04-.18 0-.13-.01-.35-.03-.48l-.1-.29c-.06-.119-.16-.309-.24-.419l-1.126-1.667c-.07-.11-.2-.28-.289-.39l-1.455-1.827c-.08-.1-.16-.19-.17-.18h-.02s-.11.02-.24.04l-2.222.44c-.13.03-.34.07-.469.09l-.04.009c-.13.02-.339.01-.468-.03l-1.865-.6c-.13-.04-.339-.099-.458-.129 0 0-.38-.09-.688-.08-.31 0-.688.08-.688.08-.13.03-.339.09-.459.13l-1.864.6c-.13.039-.339.049-.469.029l-.04-.01c-.129-.02-.338-.07-.468-.09l-2.243-.42c-.13-.03-.24-.04-.24-.04h-.02c-.01 0-.089.08-.169.18L5.352 7.252c-.08.1-.21.28-.29.39L3.937 9.308c-.07.11-.18.3-.24.42l-.099.29c-.02.13-.04.349-.03.479 0 0 0 .04.04.18.07.24.24.459.24.459.079.1.229.27.318.36l3.3 3.515c.09.1.12.28.07.4l-.688 1.627c-.05.12-.06.32-.01.45l.19.509c.159.43.428.809.787 1.098l.668.54c.1.08.28.11.399.05l2.113-1.01c.12-.06.3-.179.4-.269l1.515-1.368c.219-.2.229-.54.03-.759l-3.18-2.147a.279.279 0 01-.09-.35l1.395-2.636c.06-.12.07-.31.02-.43l-.17-.389a.686.686 0 00-.318-.31L6.498 8.48c-.12-.05-.12-.1.01-.11l2.642-.25c.13-.009.339.01.469.04l2.352.66c.13.04.21.17.19.3l-.818 4.484c-.02.13-.02.31.01.41.03.099.16.189.29.219l1.634.35c.13.03.34.03.469 0l1.525-.35c.13-.03.26-.13.29-.22.03-.09.04-.28.01-.41l-.808-4.483c-.02-.13.06-.27.19-.3l2.352-.66c.13-.04.34-.049.469-.04l2.642.25c.13.01.14.06.01.11l-4.098 1.558c-.12.05-.27.18-.319.31l-.17.39c-.05.12-.05.319.02.429l1.406 2.636c.06.12.02.27-.09.35l-3.18 2.157c-.21.21-.19.56.03.759l1.515 1.368c.1.09.28.21.4.26l2.123 1.008c.12.06.299.03.399-.05l.668-.549c.358-.29.628-.669.777-1.098l.19-.51c.05-.12.04-.33-.01-.449l-.688-1.628c-.05-.12-.02-.3.07-.4l3.3-3.515c.09-.1.229-.26.319-.36-.02-.029.159-.249.219-.488z"
                fill="#fff"
            />
            <defs>
                <linearGradient id="brave-logo_svg__paint0_linear_74_189" x1={0.595} y1={17.176} x2={13.558} y2={17.176} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#fff" />
                    <stop offset={0.141} stopColor="#fff" stopOpacity={0.958} />
                    <stop offset={1} stopColor="#fff" stopOpacity={0.7} />
                </linearGradient>
                <linearGradient id="brave-logo_svg__paint1_linear_74_189" x1={13.401} y1={16.867} x2={26.117} y2={16.867} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F1F1F2" />
                    <stop offset={0.092} stopColor="#E4E5E6" />
                    <stop offset={0.236} stopColor="#D9DADB" />
                    <stop offset={0.438} stopColor="#D2D4D5" />
                    <stop offset={1} stopColor="#D0D2D3" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default SvgBraveLogo;
