import { DetailsList, getTheme, IColumn, Icon, mergeStyleSets, Persona, PersonaSize, Stack, Text, TooltipHost } from '@fluentui/react';
import { IC, ICButton } from 'components/SVGIcon';
import { IMember, IMemberBilling } from 'features/CampusSettings/CampusSettings.state';
import { InsideBill, OutsideBill, Settings, ThreeDots, Trash } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IMemberColumns {
    setUser: React.Dispatch<React.SetStateAction<IMember | undefined>>;
    openEditUserPanel: () => void;
    setEmailToInvite: React.Dispatch<React.SetStateAction<string>>;
    togglehideReinviteDialog: () => void;
    togglehideDeleteDialog: () => void;
    setUserToDelete: React.Dispatch<React.SetStateAction<IMember | undefined>>;
    showModal: () => void;
    setUserForMobileModal: React.Dispatch<React.SetStateAction<IMember | undefined>>;
    isText: boolean;
    _members: (
        | IMember
        | {
              name: string;
              profilePic: string;
              userId: string;
              role: string;
              email: string;
          }
    )[];
    filteredMembers: IMember[];
    toggleHideAddAnyoneToYourBillingList: () => void;
}

const MemberColumns: React.FC<IMemberColumns> = ({
    setUser,
    openEditUserPanel,
    setEmailToInvite,
    togglehideReinviteDialog,
    togglehideDeleteDialog,
    setUserToDelete,
    showModal,
    setUserForMobileModal,
    isText,
    _members,
    filteredMembers,
    toggleHideAddAnyoneToYourBillingList
}) => {
    const theme = getTheme();

    const classNames = mergeStyleSets({
        fields: {
            fontWeight: 600,
            fontSize: 17,
            marginBottom: 18,
            marginTop: 18,
            color: theme.palette.black
        },
        mobileFields: {
            marginBottom: 18,
            marginTop: 18,
            color: theme.palette.black
        }
    });

    const { smallDevice, smallLaptop, largeLaptop } = useWindowWidth();

    const membersBillingButton = (member: IMemberBilling) => {
        let tooltipContent = '';
        let iconElement: any = null;

        if (member.userId === 'inactive-invitee' && member.need_sponsor) {
            tooltipContent = IvicosStrings.invitedSponsorTooltipYourList;
            iconElement = <InsideBill aria-label="inside-bill-icon"></InsideBill>;
        } else if (member.userId === 'invitee') {
            if (member.need_sponsor) {
                tooltipContent = IvicosStrings.invitedSponsorTooltipYourList;
                iconElement = <InsideBill aria-label="inside-bill-icon"></InsideBill>;
            } else {
                tooltipContent = IvicosStrings.invitedSponsorTooltipOtherList;
                iconElement = <OutsideBill aria-label="outside-bill-icon"></OutsideBill>;
            }
        } else if (member.sponsor === 'own') {
            tooltipContent = IvicosStrings.invitedSponsorTooltipYourList;
            iconElement = <InsideBill aria-label="inside-bill-icon"></InsideBill>;
        } else {
            tooltipContent = IvicosStrings.invitedSponsorTooltipOtherList;
            iconElement = <OutsideBill aria-label="outside-bill-icon"></OutsideBill>;
        }

        return (
            <ICButton className="member-billings-button" variant={'rect'}>
                <TooltipHost content={smallDevice ? '' : tooltipContent}>
                    <IC variant={'custom'}>{iconElement}</IC>
                </TooltipHost>
            </ICButton>
        );
    };

    const settingsOrResendButton = (member: IMember) => {
        if (member.userId != 'invitee' && member.userId != 'inactive-invitee' && member.role != 'owner') {
            return (
                <ICButton
                    className="edit-member-button"
                    variant={'rect'}
                    onClick={() => {
                        setUser(member);
                        openEditUserPanel();
                    }}
                >
                    <Settings />
                </ICButton>
            );
        } else if (member.userId == 'invitee') {
            return (
                <ICButton
                    className="resend-invitation-button"
                    variant={'rect'}
                    onClick={() => {
                        setEmailToInvite(member.email);
                        togglehideReinviteDialog();
                    }}
                >
                    <Icon aria-label="mail-repeat-icon" iconName="MailRepeat" />
                </ICButton>
            );
        } else if (member.userId === 'inactive-invitee') {
            return (
                <ICButton
                    variant={'rect'}
                    onClick={() => {
                        setEmailToInvite(member.email);
                        toggleHideAddAnyoneToYourBillingList();
                    }}
                >
                    <Icon aria-label="inactive-icon" iconName="Important" />
                </ICButton>
            );
        }
    };

    const deleteButton = (user: IMember) =>
        user.role != 'owner' && (
            <ICButton
                className="delete-member-button"
                aria-label="delete-member-button"
                variant={'rect'}
                onClick={() => {
                    togglehideDeleteDialog();
                    setUserToDelete(user);
                }}
            >
                <Trash />
            </ICButton>
        );

    const threeDotsButton = (user: IMember) =>
        user.role != 'owner' && (
            <IC variant={'custom'} size={15} style={{ cursor: 'pointer', transform: 'rotate(90deg)' }}>
                <ThreeDots
                    onClick={() => {
                        showModal();
                        setUserForMobileModal(user);
                    }}
                />
            </IC>
        );

    const userImage = (user: IMember) => <Persona size={PersonaSize.size32} imageUrl={user.profile_pic}></Persona>;

    const mobileNameView = (user: IMember) => (
        <Stack>
            <Text variant="mediumPlus" style={{ marginBottom: 5 }}>
                {user.name}
            </Text>
            <Text variant="small">{user.email}</Text>
        </Stack>
    );

    const mobileRoleView = (user: IMember) => (
        <>
            <Text variant="mediumPlus" style={{ marginBottom: 5 }}>
                {user.role === 'user' && IvicosStrings.userRoleTitleInCap}
                {user.role === 'manager' && IvicosStrings.managerRoleTitleInCap}
                {user.role === 'inactive' && IvicosStrings.inactiveInviteeRoleTitle}
                {user.role === 'invited' && IvicosStrings.inviteeRoleTitle}
                {user.role === 'owner' && IvicosStrings.ownerRoleTitle}
            </Text>
        </>
    );

    const memberColumns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            ariaLabel: 'Profile Images',
            fieldName: 'img',
            minWidth: 24,
            maxWidth: largeLaptop || smallLaptop ? 128 : 32,
            isPadded: true,
            onRender: (user: IMember) => userImage(user),
            className: classNames.fields
        },
        {
            key: 'column2',
            name: IvicosStrings.nameColumnTitle,
            ariaLabel: 'Member names',
            fieldName: 'name',
            minWidth: 128,
            maxWidth: largeLaptop ? 512 : 256,
            isPadded: true,
            className: classNames.fields
        },
        {
            key: 'columnX',
            name: IvicosStrings.emailColumnTitle,
            ariaLabel: 'Member emails',
            fieldName: 'email',
            minWidth: 190,
            maxWidth: largeLaptop ? 512 : 256,
            isPadded: true,
            className: classNames.fields
        },
        {
            key: 'column3',
            name: IvicosStrings.roleColumnTitle,
            ariaLabel: 'Member roles',
            fieldName: 'role',
            minWidth: largeLaptop ? 128 : 64,
            maxWidth: largeLaptop || smallLaptop ? 512 : 64,
            isPadded: true,
            onRender: (user: IMember) => mobileRoleView(user),
            className: classNames.fields
        },
        {
            key: 'column4',
            name: '',
            ariaLabel: 'Member billings',
            minWidth: largeLaptop || smallLaptop ? 50 : 30,
            maxWidth: largeLaptop || smallLaptop ? 50 : 30,
            isPadded: true,
            className: classNames.fields,
            onRender: (user: IMemberBilling) => membersBillingButton(user)
        },
        {
            key: 'column5',
            name: '',
            ariaLabel: 'Edit User',
            minWidth: largeLaptop || smallLaptop ? 50 : 30,
            maxWidth: largeLaptop || smallLaptop ? 50 : 30,
            isPadded: true,
            className: classNames.fields,
            onRender: (user: IMember) => settingsOrResendButton(user)
        },
        {
            key: 'column6',
            name: '',
            ariaLabel: 'Delete User',
            minWidth: largeLaptop || smallLaptop ? 50 : 30,
            maxWidth: largeLaptop || smallLaptop ? 50 : 30,
            isPadded: true,
            className: classNames.fields,
            onRender: (user: IMember) => deleteButton(user)
        }
    ];

    const mobileMemberColumns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            ariaLabel: 'Profile Images',
            fieldName: 'img',
            minWidth: 8,
            maxWidth: 12,
            isPadded: true,
            onRender: (user: IMember) => userImage(user),
            className: classNames.mobileFields
        },
        {
            key: 'column2',
            name: 'Name',
            ariaLabel: 'Member names',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 128,
            isPadded: true,
            onRender: (user: IMember) => mobileNameView(user),
            className: classNames.mobileFields
        },
        {
            key: 'column3',
            name: 'Role',
            ariaLabel: 'Member roles',
            fieldName: 'role',
            minWidth: 32,
            maxWidth: 256,
            isPadded: true,
            onRender: (user: IMember) => mobileRoleView(user),
            className: classNames.mobileFields
        },
        {
            key: 'column4',
            name: '',
            ariaLabel: 'Edit User',
            minWidth: 1,
            maxWidth: 15,
            isPadded: true,
            className: classNames.mobileFields,
            onRender: (user: IMember) => threeDotsButton(user)
        }
    ];

    return (
        <Stack aria-label="members-list" style={{ padding: '16px 0px 16px 0px', height: 'calc(100% - 16px)' }}>
            <DetailsList
                items={!isText ? _members : filteredMembers}
                columns={!smallDevice ? memberColumns : mobileMemberColumns}
                checkboxVisibility={2}
                compact={true}
                styles={{ root: { height: 'calc(100%)' } }}
                className={'table'}
            />
        </Stack>
    );
};

export default MemberColumns;
