import {
    Callout,
    DelayedRender,
    Depths,
    DirectionalHint,
    Dropdown,
    // FontIcon,
    ITextFieldStyles,
    Image,
    PrimaryButton,
    Stack,
    Text,
    TextField,
    getTheme,
    mergeStyleSets,
    mergeStyles
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { OrgBrandingDto } from '../../services/client-api-wrapper/identity_provider/responses.dto';
import { IC } from 'components/SVGIcon';
import { QuestionCircle, Settings, VisitorsAudio, VisitorsVideo } from 'kits/IconKit2';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import React, { useState } from 'react';
import AudioMeter from './AudioMeter';
import { useRecoilState, useRecoilValue } from 'recoil';

import IvicosStrings from 'kits/language/stringKit';
import { IIdentityClaims } from 'shared-state/identity/types';

import {
    aAvailableDevicesInDeviceSettings,
    aCurrentDevicesInDeviceSettings,
    aIsSetAudioDeviceSettingsClicked,
    aUpdatedVisitorsDeviceSettings
} from 'features/Rooms/RoomView/DeviceSettingsAtVisitorEntrance/atom';
import {
    IJitsiDevice,
    IJitsiDeviceConfig,
    IJitsiDeviceList,
    IJitsiDeviceType
} from 'features/Rooms/RoomView/DeviceSettingsAtVisitorEntrance/DeviceSettingsAtVisitorEntrance';
import { aIsAudioDeviceSetVisible } from './atom';

export interface IUpdatedVisitorsDeviceSettings {
    isVisitorUpdate: boolean | undefined;
    visitorDeviceType: IJitsiDeviceType;
    visitorDevice: IJitsiDevice;
}
export interface ILandingPageSideCardProps {
    eventName: string;
    branding: OrgBrandingDto | undefined;
    videntityClaims: IIdentityClaims | undefined;
    goInClicked: (name?: string) => void;
}

const LandingPageSideCard: React.FunctionComponent<ILandingPageSideCardProps> = ({ eventName, branding, videntityClaims, goInClicked }) => {
    const theme = getTheme();
    const buttonId = useId('callout-button');
    const buttonIdAudio = useId('callout-button');
    // const buttonIdRecommend = useId('callout-button');
    const customLogo = branding && branding.orgLogo;
    const [isEnterNameCalloutVisible, setIsEnterNameCalloutVisible] = useState<boolean>(false);
    const [isAudioCalloutVisible, setIsAudioCalloutVisible] = useState<boolean>(false);
    // const [isRecommendCalloutVisible, setIsRecommendCalloutVisible] = useState<boolean>(false);
    const [isAudioDeviceSetVisible, setIsAudioDeviceSetVisible] = useRecoilState<boolean>(aIsAudioDeviceSetVisible);
    const availableDevices = useRecoilValue<IJitsiDeviceList>(aAvailableDevicesInDeviceSettings);
    const currentDevices = useRecoilValue<IJitsiDeviceConfig>(aCurrentDevicesInDeviceSettings);
    const [, setUpdatedVisitorsDeviceSettings] = useRecoilState<any>(aUpdatedVisitorsDeviceSettings);
    const [landingPageVisitorName, setLandingPageVisitorName] = useState<string>('');
    const [isSetAudioDeviceSettingsClicked, SetIsSetAudioDeviceSettingsClicked] = useRecoilState(aIsSetAudioDeviceSettingsClicked);

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    const textFieldStyles: Partial<ITextFieldStyles> = {
        fieldGroup: { width: 300, height: 35, border: '1px solid black', padding: '4px 5px', marginTop: 5 }
    };
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const deviceSettingStyle = mergeStyles({
        marginLeft: 6,
        cursor: 'pointer',
        ':hover': {
            color: theme.palette.themePrimary
        }
    });

    const updateVisitorsDeviceSettings = (type: IJitsiDeviceType, device: IJitsiDevice) => {
        const updatedSettings = {
            isVisitorUpdate: videntityClaims?.roles.includes('unconfirmed_visitor') && videntityClaims.type === 'visitor',
            visitorDeviceType: type,
            visitorDevice: device
        };
        setUpdatedVisitorsDeviceSettings(updatedSettings);
    };

    return (
        <Stack style={{ boxShadow: Depths.depth64 }}>
            <Stack style={{ background: backgroundColor }}>
                {/* Logo */}
                <Stack
                    horizontalAlign="start"
                    style={{
                        marginLeft: 22,
                        marginTop: 20
                    }}
                    aria-label="visitor-landing-page-logo"
                >
                    <Image src={customLogo} alt="Logo" style={{ width: '50%', height: 'auto' }}></Image>
                </Stack>

                {/* Event name */}
                <Stack horizontal verticalAlign={'center'} style={{ padding: '15px 22px' }} aria-label="visitor-landing-page-event-name">
                    <Text
                        style={{
                            lineHeight: '30px',
                            color: getTextColorForBackground(backgroundColor),
                            width: '100%',
                            wordBreak: 'break-word'
                        }}
                        variant="xLargePlus"
                    >
                        {eventName}
                    </Text>
                </Stack>

                {/* Enter name */}
                <Stack verticalAlign={'center'} style={{ padding: '15px 22px' }}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Text
                            style={{
                                lineHeight: '30px',
                                color: getTextColorForBackground(backgroundColor),
                                fontWeight: 700
                            }}
                            variant="medium"
                            aria-label="visitor-landing-page-enter-name"
                        >
                            {IvicosStrings.landingPageEnterName}
                        </Text>
                        {isEnterNameCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                onDismiss={() => setIsEnterNameCalloutVisible(false)}
                                role="status"
                                target={`#${buttonId}`}
                                aria-live="assertive"
                                directionalHint={DirectionalHint.leftCenter}
                            >
                                <DelayedRender>
                                    <Stack tokens={{ childrenGap: 8 }}>
                                        <Text variant="small"> {IvicosStrings.landingPageEnterNameCallOutPart1}</Text>
                                        <Text variant="small">{IvicosStrings.landingPageEnterNameCallOutPart2}</Text>
                                    </Stack>
                                </DelayedRender>
                            </Callout>
                        )}
                        <IC
                            variant={getTextColorForBackground(backgroundColor) === '#fff' ? 'light' : 'custom'}
                            size={15}
                            style={{ cursor: 'pointer', marginRight: 0 }}
                        >
                            <QuestionCircle
                                onClick={() => setIsEnterNameCalloutVisible(!isEnterNameCalloutVisible)}
                                id={buttonId}
                                data-test-id="visitor-landing-page-enter-name-callout"
                            />
                        </IC>
                    </Stack>

                    <TextField
                        value={landingPageVisitorName}
                        onChange={(event, newValue) => typeof newValue == 'string' && setLandingPageVisitorName(newValue)}
                        styles={textFieldStyles}
                        placeholder="Campus Visitor"
                        data-test-id="visitor-landing-page-enter-name-text-field"
                    />
                    <Text
                        variant="small"
                        aria-label="visitor-landing-page-privacy-hint"
                        style={{
                            color: getTextColorForBackground(backgroundColor)
                        }}
                    >
                        {IvicosStrings.landingPagePrivacyHint}
                    </Text>
                </Stack>

                {/* Go in */}
                <Stack horizontalAlign="end" style={{ padding: 10, marginRight: 8, borderRadius: 4 }}>
                    <PrimaryButton
                        text={'Go in'}
                        onClick={() => {
                            goInClicked(landingPageVisitorName);
                        }}
                        aria-label="visitor-landing-page-go-in"
                    />
                </Stack>
            </Stack>

            <Stack style={{ background: '#fff' }}>
                <Stack
                    style={{
                        width: '100%',
                        padding: '10px 20px 0 20px'
                    }}
                >
                    {/* Audio */}
                    <Stack horizontal horizontalAlign="space-between" style={{ margin: '10px 0' }}>
                        <Stack horizontal>
                            <IC variant="dark" size={16}>
                                <VisitorsAudio />
                            </IC>
                            <Text style={{ fontWeight: 700, marginLeft: 6 }} aria-label="visitor-landing-page-audio">
                                Audio
                            </Text>
                        </Stack>
                        {isAudioCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                onDismiss={() => setIsAudioCalloutVisible(false)}
                                role="status"
                                target={`#${buttonIdAudio}`}
                                aria-live="assertive"
                                directionalHint={DirectionalHint.leftCenter}
                            >
                                <DelayedRender>
                                    <Stack tokens={{ childrenGap: 8 }}>
                                        <Text variant="small">{IvicosStrings.landingPageAudioCallOut}</Text>
                                    </Stack>
                                </DelayedRender>
                            </Callout>
                        )}
                        <IC variant="custom" size={15} style={{ cursor: 'pointer', marginRight: 0 }}>
                            <QuestionCircle
                                onClick={() => setIsAudioCalloutVisible(!isAudioCalloutVisible)}
                                id={buttonIdAudio}
                                data-test-id="visitor-landing-page-audio-callout"
                            />
                        </IC>
                    </Stack>

                    {/* Audio meter */}
                    <Stack style={{ margin: '10px 0' }} aria-label="visitor-landing-page-audio-meter">
                        <AudioMeter />
                    </Stack>

                    {/* Set Audio Devices */}
                    <Stack
                        horizontal
                        style={{ margin: '10px 0' }}
                        onClick={() => {
                            SetIsSetAudioDeviceSettingsClicked(!isSetAudioDeviceSettingsClicked);
                            setIsAudioDeviceSetVisible(!isAudioDeviceSetVisible);
                        }}
                    >
                        <IC variant="primary" size={16}>
                            <Settings />
                        </IC>
                        <Text className={deviceSettingStyle} aria-label="visitor-landing-page-set-audio-devices">
                            {IvicosStrings.landingPageSetAudioDevices}
                        </Text>
                    </Stack>
                    {isAudioDeviceSetVisible && (
                        <Stack style={{ marginBottom: 10 }} aria-label="visitor-landing-page-audio-dropdown">
                            <Dropdown
                                label={IvicosStrings.audioInputDeviceTitle}
                                selectedKey={currentDevices?.audioInput && currentDevices.audioInput.deviceId}
                                onChange={(e, i) => updateVisitorsDeviceSettings('audioInput', i?.data)}
                                placeholder={IvicosStrings.selectDevicePlaceholder}
                                options={
                                    availableDevices?.audioInput?.map((device: any) => ({
                                        key: device.deviceId,
                                        text: device.label,
                                        data: device
                                    })) || []
                                }
                                styles={{}}
                            />
                            <Dropdown
                                label={IvicosStrings.audioOutputDevice}
                                selectedKey={currentDevices?.audioOutput && currentDevices.audioOutput.deviceId}
                                onChange={(e, i) => updateVisitorsDeviceSettings('audioOutput', i?.data || {})}
                                placeholder={IvicosStrings.selectDevicePlaceholder}
                                options={
                                    availableDevices?.audioOutput?.map((device: any) => ({
                                        key: device.deviceId,
                                        text: device.label,
                                        data: device
                                    })) || []
                                }
                                styles={{}}
                            />
                        </Stack>
                    )}

                    {/* Video */}
                    <Stack horizontal style={{ margin: '10px 0 5px 0' }}>
                        {' '}
                        <IC variant="dark" size={16}>
                            <VisitorsVideo />
                        </IC>
                        <Text style={{ fontWeight: 700, marginLeft: 6 }} aria-label="visitor-landing-page-video">
                            Video
                        </Text>
                    </Stack>
                    <Stack style={{ marginBottom: 40 }} aria-label="visitor-landing-page-video-dropdown">
                        <Dropdown
                            selectedKey={currentDevices?.videoInput && currentDevices.videoInput.deviceId}
                            onChange={(e, i) => updateVisitorsDeviceSettings('videoInput', i?.data)}
                            placeholder={IvicosStrings.selectDevicePlaceholder}
                            options={
                                availableDevices?.videoInput?.map((device: any) => ({
                                    key: device.deviceId,
                                    text: device.label,
                                    data: device
                                })) || []
                            }
                            styles={{}}
                        />
                    </Stack>

                    {/* Recommendations */}
                    {/* <Stack horizontal horizontalAlign="space-between" style={{ margin: '10px 0' }}>
                        <Stack horizontal>
                            <FontIcon iconName="Info" />
                            <Stack style={{ padding: '0 5px' }}>
                                <Text style={{ fontWeight: 700, marginLeft: 6, marginTop: -3, wordBreak: 'break-word' }} aria-label="visitor-landing-page-risk">
                                    {IvicosStrings.landingPageRisk}
                                </Text>
                                <Text style={{ fontWeight: 700, marginLeft: 6 }} aria-label="visitor-landing-page-recommend">
                                    {IvicosStrings.landingPageWeRecommend}
                                </Text>
                            </Stack>
                        </Stack>
                        {isRecommendCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                onDismiss={() => setIsRecommendCalloutVisible(false)}
                                role="status"
                                target={`#${buttonIdRecommend}`}
                                aria-live="assertive"
                                directionalHint={DirectionalHint.leftCenter}
                            >
                                <DelayedRender>
                                    <Stack tokens={{ childrenGap: 8 }}>
                                        <Text variant="small">{IvicosStrings.landingPageRiskCallOutPart1}</Text>
                                        <Text variant="small"> {IvicosStrings.landingPageRiskCallOutPart2}</Text>
                                    </Stack>
                                </DelayedRender>
                            </Callout>
                        )}
                        <Stack>
                            <IC variant="custom" size={15} style={{ cursor: 'pointer', marginRight: 0 }}>
                                <QuestionCircle
                                    onClick={() => setIsRecommendCalloutVisible(!isRecommendCalloutVisible)}
                                    id={buttonIdRecommend}
                                    data-test-id="visitor-landing-page-recommend-callout"
                                />
                            </IC>
                        </Stack>
                    </Stack> */}
                    {/* <Stack
                        horizontal
                        tokens={{ childrenGap: 20 }}
                        style={{
                            margin: '5px 0 20px 22px',
                            padding: '0 5px'
                        }}
                    >
                        <Stack
                            style={{
                                cursor: 'pointer'
                            }}
                            aria-label="visitor-landing-page-brave"
                            onClick={() => window.open('https://brave.com/', '_blank')}
                        >
                            <IC variant="custom" size={16}>
                                <BraveIcon />
                            </IC>
                        </Stack>
                        <Stack
                            style={{
                                cursor: 'pointer'
                            }}
                            aria-label="visitor-landing-page-chrome"
                            onClick={() => window.open('https://www.google.com/chrome/', '_blank')}
                        >
                            <IC variant="custom" size={16}>
                                <GoogleChromeIcon />
                            </IC>
                        </Stack>
                        <Stack
                            style={{
                                cursor: 'pointer'
                            }}
                            aria-label="visitor-landing-page-edge"
                            onClick={() => window.open('https://www.microsoft.com/en-us/edge?form=MA13FJ', '_blank')}
                        >
                            <IC variant="custom" size={16}>
                                <EdgeIcon />
                            </IC>
                        </Stack>
                    </Stack> */}
                </Stack>
            </Stack>
        </Stack>
    );
};
export default LandingPageSideCard;
