import { useRecoilState } from 'recoil';
import { aIsYoutubeVideoVisible } from 'routes/WaitingArea/atom';
import { idpService } from 'services/api/identity-provider.service';
import { SERVER_EMIT_TOKEN_TO_CONFIRM_VISITOR } from 'services/socket-connection/events';
import { TEventHandler, TVisitorToken } from 'services/socket-connection/types';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentRoomId } from 'shared-state/location/hook';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { IReceivedMessage } from 'shared-state/notifications/types';

const useVisitorTokenHandler = () => {
    const localProfile = useLocalProfile();
    const [currentRoomId] = useCurrentRoomId();

    const handleVisitorToken = (data: TVisitorToken) => {
        // console.log('[RTA] Received a token', data);

        if (data.meta?.forUser !== localProfile?.uid || !currentRoomId?.includes('visitor')) return;

        idpService.useConfirmationToken(data.token);

        if (data.meta?.attachedRoom && data.meta.attachedArea) {
            const { attachedRoom, attachedArea, noVideoRoom, isWhitelisted } = data.meta;
            noVideoRoom && sessionStorage.setItem(`noVideo-${attachedRoom}`, attachedRoom);

            setTimeout(() => {
                if (isWhitelisted) {
                    sessionStorage.setItem('whitelist-roomId', attachedRoom);
                }
                window.location.href = `/areas/${attachedArea}/rooms/${attachedRoom}`;
            }, 500);
        }
    };
    return handleVisitorToken;
};

const useTheVisibilityOfVideoHandler = () => {
    const [, setIsYoutubeVideoVisible] = useRecoilState(aIsYoutubeVideoVisible);
    const handleTheVisibilityOfVideo = (data: any) => {
        setIsYoutubeVideoVisible(data.isYoutubeVideoVisible);
        sessionStorage.setItem('isYoutubeVideoVisible', data.isYoutubeVideoVisible);
    };

    return handleTheVisibilityOfVideo;
};

const useMessageAtVisitorEntranceHandler = () => {
    const [, addMessage] = useReceivedMessageInbox();
    const handleMessageAtVisitorEntrance = (data: any) => {
        const { type, sender, body, announce } = data;
        const newReceivedMessage: IReceivedMessage = {
            id: makeMessageId(),
            sender: sender.uid,
            type: type,
            timestamp: new Date(),
            meta: {},
            body: body,
            announce: announce,
            timeout: undefined,
            senderInfo: sender
        };
        addMessage(newReceivedMessage);
    };

    return handleMessageAtVisitorEntrance;
};

const useFetchHostsListAtVisitorEntranceHandler = () => {
    const handleFetchHostsListAtVisitorEntrance = (data: any) => {
        if (!data) return;
        sessionStorage.setItem('hostsListAtVE', JSON.stringify(data));
    };
    return handleFetchHostsListAtVisitorEntrance;
};

export const useVisitorHandlers = (): TEventHandler[] => {
    const handleVisitorToken = useVisitorTokenHandler();
    const handleTheVisibilityOfVideo = useTheVisibilityOfVideoHandler();
    const handleMessageAtVisitorEntrance = useMessageAtVisitorEntranceHandler();
    const handleFetchHostsListAtVisitorEntrance = useFetchHostsListAtVisitorEntranceHandler();
    return [
        {
            eventName: SERVER_EMIT_TOKEN_TO_CONFIRM_VISITOR,
            callback: handleVisitorToken
        },
        {
            eventName: 'server-emit-stop-showing-video-at-visitor-entrance',
            callback: handleTheVisibilityOfVideo
        },
        {
            eventName: 'server-emit-message-at-visitor-entrance',
            callback: handleMessageAtVisitorEntrance
        },
        {
            eventName: 'server-emit-fetch-hosts-list-at-visitor-entrance',
            callback: handleFetchHostsListAtVisitorEntrance
        }
    ];
};
