import { Stack, Text } from '@fluentui/react';
import React, { useState } from 'react';
import campusIcon from 'media/img/campusIcon.png';
import EnterDoodle from 'media/img/EnterDoodle.svg';
import campusLogo from 'media/img/ivcampus-logo.svg';
import IvicosStrings from '../kits/language/stringKit';
import { useClassNames } from '../styles/responsiveness';

const SetupComplete: React.FC<{}> = () => {
    const [opacityVal, setOpacityVal] = useState(false);

    const { doodleSetupComplete } = useClassNames();

    return (
        <Stack
            style={{
                backgroundColor: '#e4e4e3',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Stack
                style={{
                    marginBottom: 53,
                    width: 375,
                    height: 73
                }}
            >
                <img src={campusLogo} alt="logo" />
            </Stack>

            <Stack
                style={{
                    marginBottom: -50,
                    zIndex: 10
                }}
            >
                <img src={campusIcon} alt="campus icon" width={91.56} />
            </Stack>

            <Stack
                style={{
                    height: 425,
                    width: 375,
                    backgroundColor: '#fff',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                }}
            >
                <Stack
                    style={{
                        height: 400,
                        width: 375,
                        marginTop: 30
                    }}
                >
                    <Stack
                        style={{
                            margin: '37px 26px 36px 26px',
                            height: 302,
                            width: 323
                        }}
                    >
                        <Text
                            variant="xxLarge"
                            style={{
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            {IvicosStrings.setupCompletePageTitle}
                        </Text>
                        <img src={EnterDoodle} alt="logo" className={doodleSetupComplete} />

                        <Stack
                            style={{
                                alignItems: 'center'
                            }}
                        >
                            <button
                                type="submit"
                                style={{
                                    width: 300,
                                    height: 38,
                                    border: 'none',
                                    backgroundColor: '#172D4C',
                                    cursor: 'pointer',
                                    borderRadius: 5,
                                    opacity: opacityVal ? 0.7 : 1
                                }}
                                onMouseOver={() => {
                                    setOpacityVal(true);
                                }}
                                onMouseOut={() => {
                                    setOpacityVal(false);
                                }}
                                onClick={() => (window.location.href = '/dashboard/areas')}
                            >
                                <Text
                                    variant="large"
                                    style={{
                                        color: '#fff',
                                        cursor: 'pointer',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {IvicosStrings.enter}
                                </Text>
                            </button>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default SetupComplete;
