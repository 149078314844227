import {
    DefaultButton,
    DefaultEffects,
    Dialog,
    DialogFooter,
    DialogType,
    Label,
    Panel,
    PrimaryButton,
    SearchBox,
    Stack,
    Text,
    TextField,
    getTheme
} from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { useOrganizationBranding } from 'features/Header/hooks';
import Star from 'kits/IconKit2/Star';
import { IResource } from 'kits/apiKit3/legacy';
import { getTextColorForBackground } from 'kits/colorKit';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentOrgId } from 'shared-state/location/hook';
import { getClassNames } from 'styles/responsiveness';
import AreaCard from '../AreaCard/AreaCard';

export interface IAreaListViewProps {
    areas: {
        isStared: boolean;
        id: string;
        type: string;
        attributes: any;
        relationships: any;
    }[];
    vertical?: boolean;
    panelHeader: string;
    modalIsOpen: boolean;
    onAreaSelected: (areaId: string) => void;
    staredAreas: string[];
    onAreaStared: (a: IResource, s: boolean) => void;
    campusId?: string;
    roomId?: string;
    isPanelOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    saveDataForAreaDeletion: (
        areaName: string,
        areaId: string
    ) => {
        name: string;
        id: string;
    };
    areaToDelete: {
        name: string;
        id: string;
    };
    searchArea: (e: any, newValue: any) => void;
    searchInput: string;
    identityClaims?: IIdentityClaims;
    deleteAreaHandler: (areaId: string) => Promise<void>;
    setAreaToEdit: React.Dispatch<React.SetStateAction<string>>;
    onCreateAreaHandler: () => Promise<void>;
    onAreaChangeHandler: () => Promise<void>;
    onDismiss: () => void;
}

const AreaListView: React.FC<IAreaListViewProps> = ({
    areas,
    onAreaSelected,
    vertical,
    onAreaStared,
    campusId,
    isPanelOpen,
    openPanel,
    dismissPanel,
    panelHeader,
    setPanelHeader,
    areaName,
    setAreaName,
    modalIsOpen,
    setModalIsOpen,
    saveDataForAreaDeletion,
    areaToDelete,
    searchArea,
    searchInput,
    identityClaims,
    deleteAreaHandler,
    setAreaToEdit,
    onCreateAreaHandler,
    onAreaChangeHandler,
    onDismiss
}) => {
    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);
    const color = branding?.orgColors[0];
    const theme = getTheme();

    const filterAreas = (searchValue: any) => {
        if (searchValue === '') {
            return areas;
        } else {
            return (areas = areas.filter((area: any) => area.attributes.displayName.toLowerCase().includes(searchValue.toLowerCase())));
        }
    };

    // Responsive design
    const { areasSearchBar, areaCreateBtn, areasSearchBarVertical, areaCreateBtnVertical } = getClassNames();

    const dialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.deleteAreaActionTitle,
        subText: `${IvicosStrings.deleteAreaDescription} ${areaToDelete.name}?`
    };

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: false
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack tokens={{ childrenGap: 15 }} horizontal aria-label="buttons-container">
                {panelHeader === IvicosStrings.createAreaTitle ? (
                    <PrimaryButton
                        style={{ width: 110 }}
                        text={IvicosStrings.confirmActionTitle}
                        onClick={() => {
                            onCreateAreaHandler();
                            dismissPanel();
                        }}
                        aria-label="confirm-button"
                    />
                ) : (
                    <PrimaryButton
                        style={{ width: 110 }}
                        text={IvicosStrings.saveActionTitle}
                        onClick={() => {
                            onAreaChangeHandler();
                            dismissPanel();
                        }}
                        aria-label="confirm-button"
                    />
                )}
                <DefaultButton
                    text={IvicosStrings.cancelActionTitle}
                    style={{ marginRight: 10, width: 110 }}
                    onClick={() => {
                        dismissPanel();
                    }}
                    aria-label="cancel-button"
                />
            </Stack>
        ),
        [dismissPanel]
    );

    return (
        <>
            <Stack grow className={vertical ? '' : 'padding--large'} tokens={{ childrenGap: 16 }}>
                <Stack horizontalAlign="start" tokens={{ childrenGap: 16 }} horizontal>
                    {vertical ? (
                        <Stack horizontal={false} style={{ width: '100%' }}>
                            <Label>{IvicosStrings.availableAreasTitle}</Label>
                            <SearchBox
                                data-test-id={vertical ? 'search-areas-input-vertical' : 'search-areas-input'} //doesn't detect aria-label
                                placeholder={IvicosStrings.searchAreasPlaceholder}
                                iconProps={{ iconName: 'search' }}
                                type="text"
                                onChange={searchArea}
                                value={searchInput}
                                className={vertical ? areasSearchBarVertical : areasSearchBar}
                            />
                        </Stack>
                    ) : (
                        <SearchBox
                            data-test-id={vertical ? 'search-areas-input-vertical' : 'search-areas-input'} //doesn't detect aria-label
                            placeholder={IvicosStrings.searchAreasPlaceholder}
                            iconProps={{ iconName: 'search' }}
                            type="text"
                            onChange={searchArea}
                            value={searchInput}
                            className={vertical ? areasSearchBarVertical : areasSearchBar}
                        />
                    )}

                    {(identityClaims?.roles.includes('manager') || identityClaims?.roles.includes('owner')) && !vertical && (
                        <PrimaryButton
                            aria-label="add-area-button"
                            onClick={() => {
                                openPanel();
                                setPanelHeader(IvicosStrings.createAreaTitle);
                            }}
                            text={IvicosStrings.addAreaActionTitle}
                            className={vertical ? areaCreateBtnVertical : areaCreateBtn}
                            iconProps={{ iconName: 'Add' }}
                        />
                    )}
                </Stack>
                <Stack
                    grow
                    style={{
                        position: 'relative',
                        height: vertical ? '100%' : '400px',
                        width: vertical ? '110%' : '100%',
                        alignSelf: 'center',
                        overflowY: 'auto',
                        paddingBottom: '200px'
                    }}
                >
                    <Stack
                        aria-label={vertical ? 'area-card-container-vertical' : 'area-cards-container'}
                        horizontal={!vertical}
                        verticalAlign="start"
                        wrap={!vertical}
                    >
                        {filterAreas(searchInput).map((area: any) => {
                            return (
                                <AreaCard
                                    key={area.id}
                                    campusId={campusId}
                                    areaId={area.id}
                                    area={area}
                                    areaName={areaName}
                                    setAreaName={setAreaName}
                                    setAreaToEdit={(areaName: string, areaId: string) => {
                                        setAreaToEdit(areaId);
                                        setAreaName(areaName);
                                    }}
                                    onClick={() => {
                                        onAreaSelected(area.id);
                                    }}
                                    onStar={(a, s) => onAreaStared(a, s)}
                                    deleteAreaHandler={(areaId: string) => {
                                        deleteAreaHandler(areaId);
                                    }}
                                    saveDataForAreaDeletion={saveDataForAreaDeletion}
                                    modalIsOpen={modalIsOpen}
                                    setModalIsOpen={setModalIsOpen}
                                    fluid={vertical}
                                    panelHeader={panelHeader}
                                    setPanelHeader={setPanelHeader}
                                    isPanelOpen={isPanelOpen}
                                    openPanel={openPanel}
                                    dismissPanel={dismissPanel}
                                    vertical={vertical}
                                />
                            );
                        })}
                    </Stack>
                </Stack>
            </Stack>

            {/* <Panel onRenderFooterContent={onRenderFooterContent} isFooterAtBottom={true} isOpen={true} headerText={panelHeader} onDismiss={dismissPanel}> */}
            <Panel
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
                isOpen={isPanelOpen}
                headerText={panelHeader}
                onDismiss={dismissPanel}
                aria-label="edit-create-area-panel"
            >
                <Stack aria-label="panel-create-area" horizontal={false} horizontalAlign="center" tokens={{ childrenGap: 15 }} style={{ marginTop: 21 }}>
                    <Stack
                        style={{
                            width: '100px',
                            height: '100px',
                            backgroundColor: color,
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `1px solid ${theme.palette.white}`,
                            boxShadow: DefaultEffects.elevation8
                        }}
                    >
                        <Text variant="xxLarge" style={{ color: color && getTextColorForBackground(color) }} aria-label="new-area-icon">
                            {areaName === '' ? (
                                <Stack>
                                    <IC variant="custom" size={35} iconColor={color && getTextColorForBackground(color)}>
                                        <Star />
                                    </IC>
                                </Stack>
                            ) : (
                                areaName
                                    .split(' ')
                                    .reduce((a, b) => a + b[0], '')
                                    .slice(0, 2)
                                    .toUpperCase()
                            )}
                        </Text>
                    </Stack>
                    <Stack>
                        <Text variant="large" style={{ fontWeight: 'bold' }}>
                            {areaName}
                        </Text>
                    </Stack>
                    <Stack style={{ width: '100%' }}>
                        <TextField
                            placeholder={IvicosStrings.enterAreaNamePlaceholder}
                            label={IvicosStrings.enterAreaNameLabel}
                            type="text"
                            value={areaName}
                            onChange={(e, newValue) => typeof newValue == 'string' && setAreaName(newValue)}
                        />
                    </Stack>
                </Stack>
            </Panel>

            <Dialog hidden={modalIsOpen} onDismiss={onDismiss} dialogContentProps={dialogContentProps} modalProps={modelProps}>
                <DialogFooter aria-label="delete-area-dialog-footer">
                    <DefaultButton text={IvicosStrings.cancelActionTitle} onClick={onDismiss} />
                    <PrimaryButton
                        text={IvicosStrings.deleteAreaActionTitle}
                        onClick={() => {
                            deleteAreaHandler(areaToDelete.id);
                            setModalIsOpen(true);
                        }}
                        aria-label="delete-area-primary-button"
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default AreaListView;
