import { getTheme, PrimaryButton, Separator, Stack, Text, TextField } from '@fluentui/react';
import 'intro.js/introjs.css';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import Dialogs from './children/Dialogs';
import EditUserPanel from './children/EditUserPanel';
import InvitationPanel from './children/InvitationPanel';
import MemberColumns from './children/MemberColumns';
import MembersIntro from './Members.intro.';
import { IMembersState } from './Members.types';

const MembersView: React.FC<IMembersState> = ({
    isEditUserOpen,
    dismissEditUserPanel,
    editUserForm,
    onEditUserFormChange,
    user,
    changes,
    onUpdateUser,
    setChanges,
    filterUsers,
    filteredMembers,
    setEmailToInvite,
    inviteUser,
    reInviteUser,
    emailHasSponsor,
    _members,
    emailToInvite,
    displayEmailError,
    deleteUser,
    userForMobileModal,
    setUserForMobileModal,
    setUserToDelete,
    setUser,
    openEditUserPanel,
    isInvitePanelOpen,
    dismissInvitePanel,
    isText,
    microsoftProps,
    hideInviteDialog,
    hideReinviteDialog,
    hideUserExistsDialog,
    hideDeleteDialog,
    hideNoRoleEnteredDialog,
    togglehideInviteDialog,
    togglehideReinviteDialog,
    togglehideUserExistsDialog,
    togglehideDeleteDialog,
    togglehideNoRoleEnteredDialog,
    isModalOpen,
    showModal,
    hideModal,
    dropdownUserValues,
    singleInvitationUserRoleHandler,
    userValue,
    setUserValue,
    dropdownLanguageValues,
    singleInvitationUserLanguageHandler,
    isThisOrganizationBillingSystem,
    isAnyoneBillingSystem,
    menuProps,
    isUserFromOtherOrgBillingList,
    setIsUserFromOtherOrgBillingList,
    hideAddAnyoneToYourBillingList,
    toggleHideAddAnyoneToYourBillingList
}) => {
    const theme = getTheme();
    const { smallDevice, largeLaptop } = useWindowWidth();
    const iconProps = { iconName: 'Search' };

    return (
        <Stack style={{ height: '100%' }}>
            {smallDevice && (
                <Stack style={{ padding: '26px 0px 13px 17px' }}>
                    <Text variant="large">{IvicosStrings.manageAccessTitle}</Text>
                </Stack>
            )}
            <Stack className={largeLaptop ? 'padding--large' : 'padding--small'} style={{ height: '100%' }}>
                <Stack
                    horizontal={!smallDevice ? true : false}
                    verticalAlign="center"
                    horizontalAlign="start"
                    style={{ maxWidth: 1500 }}
                    tokens={{ childrenGap: 16 }}
                >
                    <TextField
                        data-test-id="filter-members-textfield"
                        onChange={(e: any) => filterUsers(e.target.value)}
                        style={
                            filteredMembers.length === 0
                                ? { color: theme.palette.red, minWidth: smallDevice ? 200 : 400 }
                                : { color: theme.palette.green, minWidth: smallDevice ? 200 : 400 }
                        }
                        placeholder={IvicosStrings.filterMembersPlaceholder}
                        iconProps={iconProps}
                        className={'filter'}
                    />
                    <PrimaryButton
                        aria-label="invite-member-button"
                        text={IvicosStrings.inviteMemberLabel}
                        iconProps={{ iconName: 'Add' }}
                        menuProps={menuProps}
                    />
                    {microsoftProps.microsoftTenantIsInvited && (
                        <Stack horizontal={smallDevice ? false : true} verticalAlign="center" horizontalAlign="space-around">
                            <Separator vertical={smallDevice ? false : true} />
                            <Text style={smallDevice ? { color: theme.palette.black } : { marginLeft: 15, color: theme.palette.black }}>
                                {IvicosStrings.msEnabledTitle}
                            </Text>
                        </Stack>
                    )}
                </Stack>
                <MemberColumns
                    setUser={setUser}
                    openEditUserPanel={openEditUserPanel}
                    setEmailToInvite={setEmailToInvite}
                    togglehideReinviteDialog={togglehideReinviteDialog}
                    togglehideDeleteDialog={togglehideDeleteDialog}
                    setUserToDelete={setUserToDelete}
                    showModal={showModal}
                    setUserForMobileModal={setUserForMobileModal}
                    isText={isText}
                    _members={_members}
                    filteredMembers={filteredMembers}
                    toggleHideAddAnyoneToYourBillingList={toggleHideAddAnyoneToYourBillingList}
                />
            </Stack>

            <InvitationPanel
                isThisOrganizationBillingSystem={isThisOrganizationBillingSystem}
                isAnyoneBillingSystem={isAnyoneBillingSystem}
                isInvitePanelOpen={isInvitePanelOpen}
                dismissInvitePanel={dismissInvitePanel}
                setEmailToInvite={setEmailToInvite}
                emailToInvite={emailToInvite}
                displayEmailError={displayEmailError}
                inviteUser={inviteUser}
                emailHasSponsor={emailHasSponsor}
                dropdownUserValues={dropdownUserValues}
                singleInvitationUserRoleHandler={singleInvitationUserRoleHandler}
                userValue={userValue}
                setUserValue={setUserValue}
                dropdownLanguageValues={dropdownLanguageValues}
                singleInvitationUserLanguageHandler={singleInvitationUserLanguageHandler}
                toggleHideAddAnyoneToYourBillingList={toggleHideAddAnyoneToYourBillingList}
            />

            <EditUserPanel
                isEditUserOpen={isEditUserOpen}
                dismissEditUserPanel={dismissEditUserPanel}
                editUserForm={editUserForm}
                onEditUserFormChange={onEditUserFormChange}
                user={user}
                changes={changes}
                onUpdateUser={onUpdateUser}
                setChanges={setChanges}
            />

            <Dialogs
                emailToInvite={emailToInvite}
                setEmailToInvite={setEmailToInvite}
                inviteUser={inviteUser}
                reInviteUser={reInviteUser}
                deleteUser={deleteUser}
                setUser={setUser}
                setUserToDelete={setUserToDelete}
                userForMobileModal={userForMobileModal}
                openEditUserPanel={openEditUserPanel}
                togglehideInviteDialog={togglehideInviteDialog}
                togglehideUserExistsDialog={togglehideUserExistsDialog}
                togglehideReinviteDialog={togglehideReinviteDialog}
                togglehideDeleteDialog={togglehideDeleteDialog}
                togglehideNoRoleEnteredDialog={togglehideNoRoleEnteredDialog}
                hideDeleteDialog={hideDeleteDialog}
                hideInviteDialog={hideInviteDialog}
                hideReinviteDialog={hideReinviteDialog}
                hideUserExistsDialog={hideUserExistsDialog}
                hideNoRoleEnteredDialog={hideNoRoleEnteredDialog}
                isModalOpen={isModalOpen}
                hideModal={hideModal}
                isUserFromOtherOrgBillingList={isUserFromOtherOrgBillingList}
                setIsUserFromOtherOrgBillingList={setIsUserFromOtherOrgBillingList}
                hideAddAnyoneToYourBillingList={hideAddAnyoneToYourBillingList}
                toggleHideAddAnyoneToYourBillingList={toggleHideAddAnyoneToYourBillingList}
            />

            <MembersIntro />
        </Stack>
    );
};

export default MembersView;
