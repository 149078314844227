import Cafe from './media/rooms/cafe.svg';
import Class from './media/rooms/class.svg';
import Desk from './media/rooms/desk.svg';
import Meeting from './media/rooms/meeting.svg';
import Room from './media/rooms/room.svg';
import Lounge from './media/rooms/lounge.svg';
import Lobby from './media/rooms/lobby.svg';
import Gym from './media/rooms/gym.svg';
import Podcast from './media/rooms/podcast.svg';
import Lunch from './media/rooms/lunch.svg';
import Bar from './media/rooms/bar.svg';

const Rooms = {
    Cafe,
    Class,
    Desk,
    Meeting,
    Room,
    Lounge,
    Lobby,
    Bar,
    Lunch,
    Podcast,
    Gym
};

export default Rooms;
