import { DefaultButton, Text, PrimaryButton, Stack, IconButton, mergeStyles } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Files from 'react-files';
import { centerCropInImage, getBase64, getCroppedImg } from './helpers';
import IvicosStrings from 'kits/language/stringKit';

export interface IImageCropperProps {
    onDismiss?: Function;
    onSelection?: (selection?: string) => void;
    src?: string;
}

const ImageCropper: React.FC<IImageCropperProps> = ({ onDismiss, onSelection, src }) => {
    const [selectedImage, setSelectedImage] = useState<string | undefined>(src);

    const [crop, setCrop] = useState<any>({
        aspect: 1 / 1,
        unit: 'px',
        width: 128
    });
    const [percentageCrop, setPercentageCrop] = useState<any>({});

    const dropZoneStyles = mergeStyles({
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        backgroundColor: '#e6e6e6',
        '&:hover': {
            backgroundColor: '#b8b8b8'
        },
        '&:active': {
            backgroundColor: '#939393'
        }
    });

    useEffect(() => {
        setSelectedImage(src);
    }, [src]);

    const onImage = (images: File[]) => {
        const [image] = images;

        if (image.size > 2000000) {
            alert(IvicosStrings.uploadCapError);
            return;
        }

        getBase64(image).then((b64) => {
            setSelectedImage(b64);
        });
    };

    const _onDismiss = () => onDismiss && onDismiss();

    const _onSelection = (selection?: string) => onSelection && onSelection(selection);

    const onImageSelection = () => {
        if (!selectedImage) return _onSelection(undefined);
        getCroppedImg(selectedImage, percentageCrop).then((imgString: string) => _onSelection(imgString));
    };

    const onLoad = useCallback(
        (img) => {
            const nextCrop = centerCropInImage(img);
            setCrop(nextCrop);
            setPercentageCrop(nextCrop);

            return false; // Return false if you set crop state in here.
        },
        [crop]
    );

    return (
        <Stack style={{ background: '#fff', height: '100%', overflowY: 'scroll' }}>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" style={{ padding: 16 }}>
                <Text variant="mediumPlus">{IvicosStrings.profileImageUploaderTitle}</Text>
                <IconButton iconProps={{ iconName: 'clear' }} onClick={_onDismiss} />
            </Stack>
            <Stack grow style={{ padding: 16 }}>
                {selectedImage ? (
                    <Stack verticalAlign="center" style={{ height: '100%' }}>
                        <div style={{ width: '100%', height: 'inherit' }}>
                            <ReactCrop
                                imageStyle={{
                                    maxHeight: 480
                                }}
                                circularCrop
                                keepSelection
                                src={selectedImage}
                                crop={crop}
                                onImageLoaded={onLoad}
                                ruleOfThirds
                                onChange={(nextCrop: any, nextPercentageCrop: any) => {
                                    setCrop(nextCrop);
                                    setPercentageCrop(nextPercentageCrop);
                                }}
                            />
                        </div>
                        <Stack
                            horizontal
                            horizontalAlign="space-between"
                            style={{
                                zIndex: 2999,
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                background: '#fff'
                            }}
                        >
                            <DefaultButton text={IvicosStrings.discard} iconProps={{ iconName: 'Trash' }} onClick={() => setSelectedImage(undefined)} />
                            <PrimaryButton text={IvicosStrings.save} iconProps={{ iconName: 'Save' }} onClick={() => onImageSelection()} />
                        </Stack>
                    </Stack>
                ) : (
                    <Stack grow tokens={{ childrenGap: 16 }}>
                        <Stack.Item grow>
                            <Files
                                className={dropZoneStyles}
                                onChange={onImage}
                                accepts={['image/jpg', 'image/jpeg', 'image/png']}
                                maxFileSize={10000000}
                                minFileSize={0}
                                clickable
                            >
                                <Stack verticalAlign="center" horizontalAlign="center" style={{ height: '100%', padding: 16 }} tokens={{ childrenGap: 16 }}>
                                    <Text>{IvicosStrings.profileImageUploaderDescription}</Text>
                                    <Text>{IvicosStrings.profileImageUploaderDescription02}</Text>
                                </Stack>
                            </Files>
                        </Stack.Item>
                        <Stack
                            horizontal
                            horizontalAlign="space-between"
                            style={{
                                zIndex: 2999,
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                background: '#fff'
                            }}
                        >
                            <DefaultButton text={IvicosStrings.cancel} onClick={_onDismiss} />
                            <PrimaryButton
                                text={IvicosStrings.save}
                                iconProps={{ iconName: 'Save' }}
                                onClick={() => {
                                    _onSelection(undefined);
                                }}
                            />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default ImageCropper;
