import { DirectionalHint, IColor } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { getClassNames } from 'styles/responsiveness';

interface IColorSelectorState {
    isModalOpen: boolean;
    showModal: () => void;
    hideModal: () => void;
    toggleIsCalloutVisible: () => void;
    isCalloutVisible: boolean;
    smallDevice: boolean;
    colorSelectorWidth: string;
    buttonId: string;
    directionalHint: 12;
    colorChange: (ev: any, colorObj: IColor) => any;
    onDismiss: () => void;
}

export const useColorSelectorState: (onChange: any, value: string) => IColorSelectorState = (onChange, value) => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const { smallDevice } = useWindowWidth();
    const { colorSelectorWidth } = getClassNames();
    const buttonId = useId('callout-button');
    const directionalHint = DirectionalHint.rightCenter;

    const colorChange = React.useCallback((ev: any, colorObj: IColor) => onChange('#' + colorObj.hex), []);

    const onDismiss = () => {
        hideModal();
        onChange(value);
    };

    return {
        isModalOpen,
        showModal,
        hideModal,
        isCalloutVisible,
        toggleIsCalloutVisible,
        smallDevice,
        colorSelectorWidth,
        buttonId,
        directionalHint,
        colorChange,
        onDismiss
    };
};
