import { parseUrlTemplate } from 'features/Rooms/RoomView/addons';
import { IResource } from 'kits/apiKit3/legacy';
import { useCallback, useEffect, useState } from 'react';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentRoomId } from 'shared-state/location/hook';

export interface IAddOnStateProps {
    room?: string;
    frameURL?: string;
}

export const useAddOnState: (addOn: IResource, attachmentRef?: string, onMessage?: (message: any) => void) => IAddOnStateProps = (
    addOn,
    attachmentRef,
    onMessage
) => {
    const [room] = useCurrentRoomId();
    const localProfile = useLocalProfile();

    const [frameURL, setFrameURL] = useState<string | undefined>();

    useEffect(() => {
        const url = parseUrlTemplate(addOn, room ? room.split('_')[0] : '', attachmentRef || '', localProfile?.displayName || '');
        setFrameURL(url);
    }, [addOn, room, attachmentRef]);

    const onMessageEvent = useCallback(
        (event: MessageEvent) => {
            if (frameURL && !frameURL.includes(event.origin)) return;
            onMessage && onMessage(event.data);
        },
        [frameURL]
    );

    useEffect(() => {
        window.addEventListener('message', onMessageEvent);
        return () => {
            window.removeEventListener('message', onMessageEvent);
        };
    }, [onMessage]);

    return {
        room,
        frameURL
    };
};
