import { ITheme, PersonaPresence } from '@fluentui/react';
import IvicosStrings from './language/stringKit';

export type UserAvailability = 'Available' | 'AvailableIdle' | 'Away' | 'BeRightBack' | 'DoNotDisturb' | 'BusyIdle' | 'Busy' | 'Offline' | 'PresenceUnknown';

export interface ILocalAvailability {
    key: UserAvailability;
    description: string;
    iconName: string;
    iconColor: string;
}
export const localAvailabilities: (theme: ITheme) => ILocalAvailability[] = (theme: ITheme) => [
    {
        key: 'Available',
        description: IvicosStrings.available,
        iconName: 'SkypeCircleCheck',
        iconColor: theme.palette.green
    },
    {
        key: 'Away',
        description: IvicosStrings.away,
        iconName: 'SkypeCircleClock',
        iconColor: theme.palette.orangeLighter
    },
    {
        key: 'BeRightBack',
        description: IvicosStrings.beRightBack,
        iconName: 'SkypeCircleClock',
        iconColor: theme.palette.orangeLighter
    },
    {
        key: 'DoNotDisturb',
        description: IvicosStrings.doNotDisturb,
        iconName: 'SkypeCircleMinus',
        iconColor: theme.palette.red
    },
    {
        key: 'Busy',
        description: IvicosStrings.busy,
        iconName: 'StatusCircleOuter',
        iconColor: theme.palette.red
    }
];

export function availabilityToPersonaPresence(availability: UserAvailability): any {
    const lookupTable = {
        Available: PersonaPresence.online,
        AvailableIdle: PersonaPresence.online,
        Away: PersonaPresence.away,
        BeRightBack: PersonaPresence.away,
        DoNotDisturb: PersonaPresence.dnd,
        BusyIdle: PersonaPresence.busy,
        Busy: PersonaPresence.busy,
        Offline: PersonaPresence.online,
        PresenceUnknown: PersonaPresence.online
    };
    return lookupTable[availability] || PersonaPresence.online;
}

export interface IMSUserAvailability {
    id: string;
    availability: UserAvailability;
    activity: string;
}
