import React, { ReactElement } from 'react';
import StatefulButtonView from './StatefulButtonView';
import { useStatefulButtonState } from './StatefulButton.state';

export interface IStatefulButtonProps {
    text: string;
    onRenderIcon(): ReactElement;
    variant?: 'default' | 'primary';
    loadingText?: string;
    successText?: string;
    disabled?: boolean;
    onClick?: (e: any, setButtonState: (state: number) => void) => void;
}

const StatefulButton: React.FC<IStatefulButtonProps> = (props) => {
    const state = useStatefulButtonState(props.text, props.loadingText, props.successText);

    return (
        <StatefulButtonView
            {...{
                ...state,
                ...{
                    disabled: props.disabled,
                    variant: props.variant,
                    onRenderIcon: props.onRenderIcon,
                    onClick: props.onClick
                }
            }}
        ></StatefulButtonView>
    );
};
export default StatefulButton;
