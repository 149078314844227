/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable react/display-name */
import React from 'react';
import Brand from './Brand';
import Rooms from './Rooms';
import Small from './Small';
import Medium from './Medium';
import xLarge from './xLarge';
import Notifications from './Notifications';
import { IconButton, ContextualMenuItemType, TooltipHost, getTheme } from '@fluentui/react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
const theme = getTheme();
const IconKit = {
    IconSet: {
        Rooms,
        Small,
        Medium,
        xLarge,
        Notifications,
        Brand
    },
    getIcon: (set, name) => {
        return IconKit.IconSet[set][name];
    },
    getIcons: (set) => {
        return IconKit.IconSet[set];
    },
    Icon: (props) => {
        return (
            <>
                {props.size ? (
                    <img src={props.icon} className={props.className} style={props.style} width={props.size} />
                ) : (
                    <img src={props.icon} className={props.className} style={props.style} width="24" />
                )}
            </>
        );
    },
    Button: (props) => {
        const { smallDevice } = useWindowWidth();
        return (
            <TooltipHost content={smallDevice ? '' : props.title}>
                <IconButton
                    disabled={props.disabled}
                    style={{ ...{ opacity: props.disabled && '0.7' }, ...props.style }}
                    styles={{ rootHovered: { backgroundColor: theme.palette.white } }}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onRenderIcon={(iconProps) => <IconKit.Icon icon={props.icon} size={props.iconSize || 16} />}
                    iconProps={props.iconProps}
                    ariaLabel={props.ariaLabel}
                    onClick={(e) => {
                        if (props.onClick) props.onClick(e);
                    }}
                />
            </TooltipHost>
        );
    },
    MoreButton: (props) => {
        /*
            Takes props:
                actions: {
                    key: "id of action",
                    title: "display name of action",
                    action: "function to execute on click",
                    symbol: "small iconkit icon to display next to action"
                }
        */

        let actionList = props.actions
            .filter((action) => action && action.title)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map((action, actionIndex) => {
                if (action.isDivider)
                    return {
                        key: action.key,
                        itemType: ContextualMenuItemType.Divider
                    };

                return {
                    key: action.key,
                    text: action.title,
                    onClick: (e) => action.action(e),
                    iconProps: { iconName: 'Room' },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onRenderIcon: (props) => <IconKit.Icon icon={IconKit.IconSet.Small[action.symbol]} size={16} />
                };
            });

        return (
            <IconButton
                key="MoreButton"
                onRenderIcon={() => <IconKit.Icon icon={IconKit.IconSet.Small.More} size={16} />}
                onRenderMenuIcon={() => <></>}
                iconProps={{ iconName: 'MoreVertical' }}
                menuProps={{ items: actionList }}
            />
        );
    }
};

export default IconKit;
