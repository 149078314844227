import { IPersonaProps } from '@fluentui/react';
import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import { IResource, roomDTOtoResource } from 'kits/apiKit3/legacy';
import IvicosStrings from 'kits/language/stringKit';
import React, { useCallback, useEffect, useState } from 'react';
import { resourceService } from 'services/api/resource.service';
import { useRoom } from 'shared-state/directory/hooks';
import { useRoomSettingsRoomId } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import useCampusMembersManager from './campusMembersManager';
import useConfirmationPanelManager from './confirmationPanelManager';
import useDynamicFormManager from './dynamicFormManager';

export interface IRoomSettingsState {
    isOpen: any;
    confirmationTarget: any;
    setConfirmationTarget: any;

    getConfirmationTitle: any;
    getConfirmationDesc: any;
    onConfirm: any;
    onPanelDismiss: any;

    onFormElementChange: (key: string, value: any) => void;
    formFields: IDynamicFormElementProps[];

    onRenderPanelFooterContent: any;
    activeTab: string;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export const useRoomSettingsState: () => IRoomSettingsState = () => {
    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();
    const [currentRoomId] = useCurrentRoomId();
    const [roomSettingsRoomId, setRoomSettingsRoomId] = useRoomSettingsRoomId();
    const isOpen = roomSettingsRoomId.length > 0;

    const [room, setRoom] = useRoom(roomSettingsRoomId);

    const [members] = useCampusMembersManager();

    const usersInRoom = useUsersInRoom(roomSettingsRoomId);

    const extractRoomWhitelistedPersona = useCallback((whitelist: Set<string>, members: IPersonaProps[]) => {
        const whitelistedPersona = members.filter((member: IPersonaProps) => member.id !== undefined && whitelist.has(member.id));
        return whitelistedPersona;
    }, []);

    const localProfile = useLocalProfile();

    const [changes, setChanges] = useState<any>({});

    const defaultWhitelistSelectorIsVisible = room && room?.attributes.whitelist ? room?.attributes.whitelist.length > 0 : false;

    const [whitelistSelectorIsVisible, setWhitelistSelectorIsVisible] = useState<boolean>(defaultWhitelistSelectorIsVisible);

    useEffect(() => {
        setWhitelistSelectorIsVisible(room && room?.attributes.whitelist ? room?.attributes.whitelist.length > 0 || room?.attributes.private : false);
    }, [room]);

    const getSelectedItems = useCallback(() => {
        const roomHasUsers = usersInRoom && usersInRoom.length > 0;
        //if room has whitelist return whitelisted members
        if (room?.attributes.whitelist && room?.attributes.whitelist.length > 0 && members) {
            const personaInWhitelist = extractRoomWhitelistedPersona(new Set(room?.attributes.whitelist), members);
            return personaInWhitelist;
        }
        //if room doesn't have whitelist but there are users in room return these users
        else if (room?.attributes.whitelist && room?.attributes.whitelist.length === 0 && members && roomHasUsers) {
            const userIdsInRoom = usersInRoom.map((user: any) => user.uid);
            localProfile && userIdsInRoom.push(localProfile.uid);
            const personaInRoom = extractRoomWhitelistedPersona(new Set(userIdsInRoom), members);
            return personaInRoom;
            //if room doesn't have whitelist and there are no users in room return the local user
        } else return localProfile && members ? extractRoomWhitelistedPersona(new Set([localProfile.uid]), members) : [];
    }, [members, room, usersInRoom]);

    const generateRoomSettingsForm: (room: IResource | undefined) => IDynamicFormElementProps[] = useCallback(
        (room) => {
            return [
                {
                    key: 'name',
                    label: IvicosStrings.roomNameInputLabel,
                    value: room?.attributes.displayName,
                    type: 'text-field-with-max-length',
                    maxLength: 50
                },
                { key: 'iconKey', label: IvicosStrings.iconPickerLabel, value: room?.attributes.symbol, type: 'icon' },
                {
                    key: 'backgroundImageURL',
                    label: IvicosStrings.backgroundImageTitle,
                    type: 'image',
                    value: room?.attributes.backgroundImageURL
                },
                {
                    key: 'isAudioOnly',
                    label: IvicosStrings.audioOnlyCheckBoxLabel,
                    description: IvicosStrings.audioOnlyCheckBoxDescription,
                    value: room?.attributes.audioOnly,
                    type: 'checkbox'
                },
                {
                    key: 'isOpenForVisitors',
                    label: IvicosStrings.openToVisitorsCheckBoxLabel,
                    description: IvicosStrings.openToVisitorsCheckBoxDescription,
                    value: room?.attributes.openForVisitors,
                    type: 'checkbox',
                    disabled: whitelistSelectorIsVisible
                },
                {
                    key: 'isWhitelistPanelVisible',
                    label: IvicosStrings.toggleWhitelistLabel,
                    value: room && room?.attributes.whitelist ? room?.attributes.whitelist.length > 0 || room?.attributes.private : false,
                    type: 'toggle'
                },
                {
                    key: 'whitelist',
                    label: IvicosStrings.whitelistSelectorLabel,
                    description: IvicosStrings.whitelistSelectorDescription,
                    value: {
                        selectedItems: getSelectedItems(),
                        membersList: members,
                        isVisible: whitelistSelectorIsVisible
                    },
                    type: 'personaSelector',
                    placeholder: IvicosStrings.whitelistSelectorLabel
                }
            ];
        },
        [room, members, whitelistSelectorIsVisible]
    );
    const [formFields, setFormFields] = useState<IDynamicFormElementProps[]>([]);

    const updateRoomFormElements = (key: string, fieldName: string, newValue: boolean | string | string[]) => {
        setFormFields((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ [fieldName]: newValue } };
            });
        });
    };

    const { setElementChange, hasChanged, hasElementChanged, resetChanges, deleteChangeForElementIfPresent, getElementChange } = useDynamicFormManager(
        changes,
        setChanges
    );

    useEffect(() => {
        setFormFields(generateRoomSettingsForm(room));
    }, [room]);

    useEffect(() => {
        updateRoomFormElements('isOpenForVisitors', 'disabled', whitelistSelectorIsVisible);
        updateRoomFormElements('whitelist', 'value.isVisible', whitelistSelectorIsVisible);

        setFormFields((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != 'whitelist') return el;
                return { ...el, ...{ ['value']: { ...el['value'], ...{ ['isVisible']: whitelistSelectorIsVisible } } } };
            });
        });
    }, [room, whitelistSelectorIsVisible]);

    const isTheUserInsideTheRoom = currentRoomId == roomSettingsRoomId;

    const onRemoveRoom = (roomId: string): Promise<boolean> => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            if (!areaId || !campusId || !roomId) return resolve(false);
            resourceService.archiveRoom(campusId, areaId, roomId).then(() => {
                resolve(true);

                // removing roomId from sessionStorage on archiving the room
                if (sessionStorage.getItem(`noVideo-${roomId}`)) {
                    sessionStorage.removeItem(`noVideo-${roomId}`);
                }
            });
        });
    };

    const onUpdateRoom = (roomId: string, changes: any): Promise<any | undefined> => {
        const defaultBackgroundImageUrl =
            'https://ivicos-image-upload-service.s3-eu-central-2.ionoscloud.com/images/microsoft:071974ab-5085-4b54-8d64-c536aeb22991:f9437c41-fece-449a-89c7-c8fe1ed2bd1b/c53fd7f4-153d-4351-a571-6ed4e6adca0c.jpeg';
        if ('backgroundImageURL' in changes && changes.backgroundImageURL === '') changes.backgroundImageURL = defaultBackgroundImageUrl;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            if (!areaId || !campusId || !roomId) return resolve(undefined);
            resourceService.updateRoom(campusId, areaId, roomId, changes).then((room) => {
                resolve({ data: roomDTOtoResource(room) });
            });
        });
    };

    const [confirmationTarget, setConfirmationTarget, getConfirmationTitle, getConfirmationDesc, onRenderPanelFooterContent, onConfirm, onPanelDismiss] =
        useConfirmationPanelManager(
            room,
            setRoom,
            setRoomSettingsRoomId,
            onRemoveRoom,
            onUpdateRoom,
            isTheUserInsideTheRoom,
            hasChanged,
            changes,
            resetChanges
        );

    const onFormElementChange: (key: string, value: any) => void = (key: any, value: any) => {
        setElementChange({ [key]: value });
        updateRoomFormElements(key, 'value', value);
    };

    useEffect(() => {
        if (whitelistSelectorIsVisible) deleteChangeForElementIfPresent('isOpenForVisitors');
        if (!hasElementChanged('whitelist') || getElementChange('whitelist').length == 0)
            setElementChange({ whitelist: getSelectedItems().map((item) => item.id) });
        else if (defaultWhitelistSelectorIsVisible) setElementChange({ whitelist: [] });
        else deleteChangeForElementIfPresent('whitelist');
    }, [whitelistSelectorIsVisible]);

    useEffect(() => {
        if (hasElementChanged('isWhitelistPanelVisible')) setWhitelistSelectorIsVisible(getElementChange('isWhitelistPanelVisible') == true);
    }, [room, members, changes]);

    useEffect(() => {
        resetChanges();
    }, [room, members]);

    const [activeTab, setActiveTab] = useState<string>('General');

    return {
        isOpen,
        confirmationTarget,
        setConfirmationTarget,

        getConfirmationTitle,
        getConfirmationDesc,
        onConfirm,
        onPanelDismiss,

        onFormElementChange,
        formFields,
        onRenderPanelFooterContent,

        activeTab,
        setActiveTab
    };
};
