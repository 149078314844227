import { Callout, DelayedRender, DirectionalHint, Stack, Text, TextField, mergeStyleSets } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { IC } from 'components/SVGIcon';
import QuestionCircle from 'kits/IconKit2/QuestionCircle';
import React from 'react';

interface ITextFieldWithMaxLengthViewProps {
    label?: string;
    value?: string;
    key?: string;
    onTextLengthChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    secondTextFieldValue?: string;
    errorMessage?: string;
    maxLength?: number;
    required?: boolean;
    callout?: string;
    dataTestId?: string;
}

const TextFieldWithMaxLengthView: React.FC<ITextFieldWithMaxLengthViewProps> = (props) => {
    const { label, value, key, onTextLengthChange, secondTextFieldValue, maxLength, required, errorMessage, callout, dataTestId } = props;
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const styles = mergeStyleSets({
        button: {
            width: 130
        },
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });
    const buttonId = useId('callout-button');
    const directionalHint = DirectionalHint.rightCenter;

    return (
        <Stack horizontal={false}>
            <Stack horizontal={false}>
                <Stack style={{ marginBottom: -23, marginLeft: -200, cursor: 'pointer', zIndex: 9999 }}>
                    {/* callout icon */}
                    {callout && (
                        <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                            <QuestionCircle onClick={toggleIsCalloutVisible} id={buttonId} />
                        </IC>
                    )}

                    {/* callout box */}
                    {isCalloutVisible && (
                        <Callout
                            className={styles.callout}
                            onDismiss={toggleIsCalloutVisible}
                            role="status"
                            target={`#${buttonId}`}
                            aria-live="assertive"
                            directionalHint={directionalHint}
                        >
                            <DelayedRender>
                                <Text variant="small">{callout}</Text>
                            </DelayedRender>
                        </Callout>
                    )}
                </Stack>
                <TextField
                    placeholder={value}
                    key={key}
                    onChange={(event, newValue) => typeof newValue == 'string' && onTextLengthChange(event, newValue)}
                    label={label}
                    errorMessage={errorMessage}
                    value={value || secondTextFieldValue}
                    required={required}
                    data-test-id={dataTestId}
                ></TextField>
            </Stack>
            {/* characters counter */}
            {maxLength && (
                <Text variant={'small'} style={{ marginLeft: 'auto' }}>
                    {secondTextFieldValue?.length || value?.length}/{maxLength}
                </Text>
            )}
        </Stack>
    );
};

export default TextFieldWithMaxLengthView;
