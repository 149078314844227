import { Modal, PrimaryButton, Stack, Text, IconButton } from '@fluentui/react';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import TrialPeriodAboutToEndModal from 'components/TrialPeriodAboutToEndModal/TrialPeriodAboutToEndModal';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';

const Billing: React.FC = () => {
    const [billingAccess, setBillingAccess] = useState<string>('');

    useEffect(() => {
        const getBillingResponse = async () => {
            try {
                const { data } = await idpService.showBilling();
                setBillingAccess(data);
            } catch (error: any) {
                window.location.href = '/dashboard';
            }
        };
        getBillingResponse();
    }, []);

    return (
        <Stack style={{ height: '100%', alignItems: 'center' }}>
            {billingAccess === '' && <LoadingDialog loadingStrings={[IvicosStrings.loadingStringBilling]} view="small" typewriter={false} />}
            {billingAccess === 'trial' && <TrialPeriodAboutToEndModal forceOpenModal={true} />}
            {billingAccess === 'active_direct' && (
                <Modal isOpen onDismiss={() => (window.location.href = '/dashboard')}>
                    <Stack style={{ padding: 5 }}>
                        <IconButton
                            style={{ marginLeft: 'auto' }}
                            iconProps={{ iconName: 'Cancel' }}
                            onClick={() => (window.location.href = '/dashboard/areas')}
                        />
                    </Stack>

                    <Stack horizontalAlign="center" style={{ maxWidth: 400, padding: 26, alignItems: 'start', marginTop: -40 }} tokens={{ childrenGap: 32 }}>
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 6 }} style={{ alignItems: 'start', gap: '20px' }}>
                            <Text variant="xLarge">{IvicosStrings.billingActiveDirectTitle}</Text>

                            <Text>
                                {IvicosStrings.billingActiveDirectDescription}
                                <b>sales@ivicos.eu</b>
                            </Text>
                            <Text>{IvicosStrings.billingActiveDirectAlternative}</Text>
                        </Stack>
                        <Stack style={{ alignSelf: 'end', flexDirection: 'row', gap: '15px' }}>
                            <PrimaryButton text={IvicosStrings.writeUs} onClick={() => (window.location.href = 'mailto:sales@ivicos.eu')} />
                        </Stack>
                    </Stack>
                </Modal>
            )}
        </Stack>
    );
};

export default Billing;
