import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useCampusSettingsState } from './CampusSettings.state';
import CampusSettingsView from './CampusSettings.view';

const CampusSettings: React.FC = () => {
    const state = useCampusSettingsState();

    return (
        <>
            {Object.values(state).includes(undefined) ? (
                <LoadingDialog loadingStrings={[IvicosStrings.loadingStringSettings]} view="small" typewriter={false} />
            ) : (
                <CampusSettingsView
                    {...{
                        ...state,
                        ...{
                            microsoftProps: {
                                microsoftTenantIsInvited: state.microsoftTenantIsInvited,
                                isMicrosoftUser: state.isMicrosoftUser,
                                setMicrosoftTenantIsInvited: state.setMicrosoftTenantIsInvited
                            }
                        }
                    }}
                />
            )}
        </>
    );
};

export default CampusSettings;
