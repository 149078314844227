import { getTheme, IChoiceGroupOption, ITheme, PivotItem } from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { CampusDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { OrganizationDto } from 'services/client-api-wrapper/identity_provider/responses.dto';
import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { resourceService } from 'services/api/resource.service';
import { useCampus } from 'shared-state/directory/hooks';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentOrgId } from 'shared-state/location/hook';
import { getClassNames } from 'styles/responsiveness';

export interface ICampusSettingsState {
    currentUser?: IProfile;
    isFormModalOpen: boolean;
    showFormModal: () => void;
    hideFormModal: () => void;
    titleId: string;
    colorButtonId: string;
    campus?: CampusDto;
    onCampusFormChange: (key: string, newValue: any) => void;
    onUpdateCampus?: (campusId: string, changes: IChanges) => Promise<any | undefined>;
    setChanges: any;
    changes: any;
    formFieldsCampus: IDynamicFormElementProps[];
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    isDisable: boolean;
    disableButton: () => void;
    notDisableButton: () => void;
    theme: ITheme;
    fetchCampus: Function;
    microsoftProps: { microsoftTenantIsInvited: boolean; isMicrosoftUser: boolean; setMicrosoftTenantIsInvited: (isInvited: boolean) => void };
    pivotDisplaySmallDevice: string;
    selectedKey: string;
    setSelectedKey: React.Dispatch<React.SetStateAction<string>>;
    handleLinkClick: (item?: PivotItem | undefined) => void;
    formFieldsVisitors: IDynamicFormElementProps[];
    onVisitorFormChange: (key: string, newValue: any) => void;
    onUpdateVisitor: (changes: IChanges) => any;
    setRemoveOrgOpened: React.Dispatch<React.SetStateAction<boolean>>;
    removeOrgOpened: boolean;
    performActionRemoveOrg: () => void;
    removeOrgOptions: IChoiceGroupOption[];
    setRemoveOrgChoice: any;
    isSavingChanges: boolean;
    setIsSavingChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IChanges {
    name: string;
    color: string;
    logo: string;
    image: string;
    'video-url': string;
    message: string;
}

export interface IMember {
    userId: string;
    profile_pic: string;
    name: string;
    email: string;
    role: 'user' | 'manager' | 'owner' | 'invited' | 'inactive';
}
export interface IMemberBilling {
    userId: string;
    profile_pic: string;
    name: string;
    email: string;
    role: 'user' | 'manager' | 'owner' | 'invited' | 'inactive';
    sponsor: 'own' | 'other' | 'no-sponsor';
    need_sponsor: boolean;
}

export interface IInvitee {
    email: string;
    role: 'user' | 'manager' | 'inactive';
}

export const useCampusSettingsState = (): any => {
    const routeHistory = useNavigate();
    const currentUser = useLocalProfile();
    const [isFormModalOpen, { setTrue: showFormModal, setFalse: hideFormModal }] = useBoolean(false);
    const titleId = useId('title');
    const colorButtonId = useId('callout-button');

    const [currentOrgId] = useCurrentOrgId();

    const [campus, setCampus] = useCampus();
    const [org, setOrg] = useState<OrganizationDto | undefined>();

    const [, setMembers] = useState<IMember[]>([]);
    const [idClaims] = useIdentityClaims();
    const [invitees, setInvitees] = useState<IInvitee[]>([]);
    const tenantIdEmail = idClaims?.msTenantId + '@microsoft.tenant';
    const isMicrosoftUser = idClaims?.msTenantId ? true : false;
    const microsoftTenantIsInvited = invitees.find((invitee) => invitee.email.includes(tenantIdEmail)) ? true : false;
    const [changes, setChanges] = useState<any>({});
    const [isSavingChanges, setIsSavingChanges] = useState(false);

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [isDisable, { setTrue: disableButton, setFalse: notDisableButton }] = useBoolean(true);
    const theme = getTheme();
    const { pivotDisplaySmallDevice } = getClassNames();

    const [selectedKey, setSelectedKey] = React.useState('campus');

    const handleLinkClick = (item?: PivotItem) => {
        item && setSelectedKey(item?.props.itemKey || '');
    };

    const setMicrosoftTenantIsInvited = async (isInvited: boolean) => {
        try {
            if (isInvited) {
                await idpService.newInvited(tenantIdEmail, 'user', 'en');
            } else {
                await idpService.deleteInvited(tenantIdEmail);
            }
        } catch (err) {
            console.log(err);
        }
        fetchUsers();
    };

    const fetchUsers = async () => {
        const fetchedMembers = await idpService.showMembers();
        const fetchedInvitees = await idpService.showOrgInvited();
        setMembers(fetchedMembers);
        setInvitees(fetchedInvitees);
    };

    const fetchCampus = async () => {
        if (!currentOrgId) return;
        const fetchedOrg = await idpService.showOrg();
        setOrg(fetchedOrg);
    };

    useEffect(() => {
        fetchCampus().catch((err: any) => {
            console.error(err);
        });
    }, [currentOrgId]);

    const onChange = (change: { [index: string]: string | boolean }) => {
        setChanges((prevState: any) => {
            const newState = { ...prevState, ...change };
            return newState;
        });
    };

    const onCampusFormChange = (key: string, newValue: any) => {
        onChange({ [key]: newValue });
        setFormFieldsCampus((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };
    const onVisitorFormChange = (key: string, newValue: any) => {
        onChange({ [key]: newValue });
        setFormFieldsVisitors((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };

    const onUpdateCampus = async (campusId: string, changes: IChanges): Promise<any | undefined> => {
        return new Promise((resolve) => {
            if (changes.name == '') return resolve(undefined);

            resourceService.updateCampus(campusId, { name: changes.name }).then((updatedCampus) => {
                setCampus(updatedCampus);
                idpService.updateOrg(changes.name, changes.logo, changes.color ? [changes.color] : undefined).then((updatedOrg) => {
                    setOrg(updatedOrg);

                    resolve(true);
                });
            });
        });
    };
    const onUpdateVisitor = async (changes: IChanges): Promise<any> => {
        const response = await idpService.updateOrg(org?.orgName, org?.orgLogo, org?.orgColors, changes.image, changes['video-url'], changes.message);
        setOrg(response);
    };

    const formFieldsForCampus: (campus: any, org?: OrganizationDto) => IDynamicFormElementProps[] = (campus, org) => {
        return [
            { key: 'name', label: IvicosStrings.campusNameTitle, value: campus?.name || '', type: 'text' },
            {
                key: 'color',
                label: IvicosStrings.campusColorTitle,
                value: org?.orgColors[0] || '',
                type: 'color',
                callout: IvicosStrings.changeColorCalloutTitle
            },
            {
                key: 'logo',
                label: IvicosStrings.campusLogoTitle,
                value: org?.orgLogo || '',
                type: 'image',
                view: 'settings',
                callout: IvicosStrings.changeLogoCalloutTitle,
                buttonText: IvicosStrings.changeLogoActionTitle
            }
        ];
    };

    const [formFieldsCampus, setFormFieldsCampus] = useState<IDynamicFormElementProps[]>(formFieldsForCampus(campus, org));

    const formFieldsForVisitors: (campus: any, org?: OrganizationDto) => IDynamicFormElementProps[] = (campus, org) => {
        return [
            {
                key: 'image',
                label: IvicosStrings.backgroundImageTitle,
                value: org?.visitor_entrance_background_image || '',
                type: 'image',
                view: 'visitor entrance',
                buttonText: IvicosStrings.changeImageActionTitle
            },
            {
                key: 'video-url',
                label: IvicosStrings.visitorEntranceYTTitle,
                videoUrl: org?.visitor_entrance_video_url,
                type: 'visitor-entrance-video-player',
                view: 'visitor entrance',
                buttonText: IvicosStrings.changeYTVideo
            },
            {
                key: 'message',
                value: org?.visitor_farewell_message || '',
                type: 'multiline-text',
                maxLength: 272,
                callout: IvicosStrings.farewellMessageCallout
            }
        ];
    };

    const [formFieldsVisitors, setFormFieldsVisitors] = useState<IDynamicFormElementProps[]>(formFieldsForVisitors(campus, org));

    const [removeOrgOpened, setRemoveOrgOpened] = useState<boolean>(false);
    const [removeOrgChoice, setRemoveOrgChoice] = useState<any>('refuse');
    const removeOrgOptions: IChoiceGroupOption[] = [
        { key: 'accept', text: IvicosStrings.removeOrganizationChoiceAccept },
        { key: 'refuse', text: IvicosStrings.removeOrganizationChoiceRefuse }
    ];
    const performActionRemoveOrg = async () => {
        if (removeOrgChoice == 'accept') {
            await idpService.archiveOrg();
            routeHistory('/welcome');
            return;
        }

        setRemoveOrgOpened(false);
    };

    useEffect(() => {
        setFormFieldsCampus(formFieldsForCampus(campus, org));
        setFormFieldsVisitors(formFieldsForVisitors(campus, org));
    }, [campus, org]);

    return {
        currentUser,
        isFormModalOpen,
        showFormModal,
        hideFormModal,
        titleId,
        colorButtonId,
        campus,
        isMicrosoftUser,
        microsoftTenantIsInvited,
        setMicrosoftTenantIsInvited,
        onCampusFormChange,
        onUpdateCampus,
        setChanges,
        changes,
        formFieldsCampus,
        isOpen,
        openPanel,
        dismissPanel,
        isDisable,
        disableButton,
        notDisableButton,
        theme,
        fetchCampus,
        pivotDisplaySmallDevice,
        selectedKey,
        setSelectedKey,
        handleLinkClick,
        formFieldsVisitors,
        onVisitorFormChange,
        onUpdateVisitor,
        setRemoveOrgOpened,
        removeOrgOpened,
        performActionRemoveOrg,
        removeOrgOptions,
        setRemoveOrgChoice,
        isSavingChanges,
        setIsSavingChanges
    };
};
