import * as React from 'react';

function SvgHandWave(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.281 4.031c-.77 0-1.543.293-2.125.875a3 3 0 00-.812 1.563c-.106-.012-.207-.032-.313-.032-.77 0-1.543.293-2.125.875-1.066 1.067-1.136 2.766-.25 3.938a3.002 3.002 0 00-1.187.969c-.895 1.246-.653 2.91.375 3.937l.031.032 11.438 11.125c2.257 2.234 5.937 2.25 8.187 0l.219-.25L23.78 26c1.137-1.137 2.473-2.434 2.938-4.25l2.156-7.844h-.031a2.999 2.999 0 00-1.907-3.75A2.846 2.846 0 0026 10a3.02 3.02 0 00-2.844 2.063l-1.093 3.5-2.594-2.594-7.907-7.875-.156-.188a3.002 3.002 0 00-2.125-.875zm6.031.032l-.624 1.875c3.23 1.093 5.468 4.593 5.468 4.593l1.688-1.062s-2.39-4-6.532-5.406zM9.25 6c.25 0 .52.113.719.313L23 19.343l.5-1.593 1.563-5.063A.978.978 0 0126 12c.11 0 .21.027.313.063.53.175.8.718.625 1.25v.062L24.78 21.25c-.285 1.102-1.289 2.223-2.406 3.344l-1.313 1.281a3.749 3.749 0 01-5.343 0h-.031L4.28 14.75c-.383-.383-.457-.973-.187-1.344.398-.55 1.062-.594 1.5-.156l5.375 5.375 1.437-1.406-5.375-5.375-.093-.094-1.625-1.594a.977.977 0 010-1.406 1.014 1.014 0 011.437 0l7.063 7.063 1.406-1.407L8.563 7.75a1.014 1.014 0 010-1.438C8.761 6.114 9 6 9.25 6zM4.437 20.656l-1.78.907.468.906s1.863 3.742 5.438 5.437l.906.438.875-1.813-.906-.437C6.68 24.789 4.875 21.53 4.875 21.53l-.438-.875z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgHandWave;
