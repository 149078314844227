import { Stack, Text, Image } from '@fluentui/react';
import { Depths } from '@fluentui/theme';
import { IvicosColors, getTextColorForBackground } from 'kits/colorKit';
import IvicosLogo from 'media/logos/iv_campus_logo_white.svg';
import React from 'react';

export interface IInvitationLogoViewProps {
    eventName: string;
    orgId: string;
}

const InvitationLogoView: React.FC<any> = (props) => {
    const { eventName, branding } = props;
    const customLogo = branding && branding.orgLogo;

    const backgroundColor =
        (branding &&
            branding.orgColors &&
            (branding.orgColors.length > 1 ? `linear-gradient(45deg,${branding.orgColors.join(',')})` : branding.orgColors[0])) ||
        IvicosColors.CampusGradient;

    return (
        <Stack style={{ boxShadow: Depths.depth64, width: 350, background: backgroundColor }}>
            <Stack
                horizontal
                horizontalAlign="start"
                style={{
                    marginLeft: 22,
                    marginTop: 20
                }}
            >
                <Image src={customLogo || IvicosLogo} alt="Logo" style={{ width: '50%', height: 'auto' }}></Image>
            </Stack>
            <Stack horizontal verticalAlign={'center'} style={{ padding: '15px 22px' }}>
                <Text
                    style={{
                        lineHeight: '30px',
                        color: getTextColorForBackground(backgroundColor),
                        width: '100%',
                        wordBreak: 'break-all'
                    }}
                    variant="xLargePlus"
                >
                    {eventName}
                </Text>
            </Stack>
        </Stack>
    );
};

export default InvitationLogoView;
