import React from 'react';

import { useHelpModalState } from 'features/Header/children/HelpModal/HelpModal.state';
import HelpModalView from 'features/Header/children/HelpModal/HelpModal.view';

const HelpModal: React.FC<{}> = () => {
    const state = useHelpModalState();

    return <HelpModalView {...state} />;
};
export default HelpModal;
