import * as React from 'react';

function SvgWaitingHead(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.025 6.813C7.593 2.555 4.754 0 .78 0 .51 0 .25.014 0 .04v14.927l3.818.305v-1.636c1.988.567 2.648-.294 2.648-.294l.843-3.123c1.134-.284 1.418-.851 1.418-.851S7.025 7.096 7.025 6.813z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgWaitingHead;
