import { ComboBox, getTheme, IComboBox, IComboBoxOption, IComboBoxStyles, Stack } from '@fluentui/react';
import { ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { IImportedPersonProps } from './ImportedPerson';

const ImportedPersonView: React.FC<IImportedPersonProps> = (props) => {
    const { indexInCSVDATA, email, role, language, onChangeImportedPersonData, validateEmail, validateRole, validateLanguage } = props;

    const validateEmailMessage = (value: string) => {
        return validateEmail(value) ? '' : IvicosStrings.inviteMemberErrorMessage;
    };

    const validateRoleMessage = validateRole(role) ? undefined : role + IvicosStrings.InviteRoleErrorMessage;

    const validateLanguageMessage = validateLanguage(language) ? undefined : language + IvicosStrings.inviteLanguageErrorMessage;

    const onImportedUserRoleChange = React.useCallback((event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined): void => {
        if (option) onChangeImportedPersonData(indexInCSVDATA, 1)(event, option.text);
    }, []);

    const onImportedUserLanguageChange = React.useCallback((event: React.FormEvent<IComboBox>, languageOptions?: IComboBoxOption | undefined): void => {
        if (languageOptions) onChangeImportedPersonData(indexInCSVDATA, 2)(event, languageOptions.text);
    }, []);
    const { smallDevice } = useWindowWidth();

    const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: smallDevice ? { width: 200 } : { width: 300 } };

    const options: IComboBoxOption[] = [
        { key: 'user', text: IvicosStrings.userRoleTitleInCap },
        { key: 'manager', text: IvicosStrings.managerRoleTitleInCap }
    ];

    const languageOptions: IComboBoxOption[] = [
        { key: 'en', text: IvicosStrings.englishLanguageTitle },
        { key: 'de', text: IvicosStrings.deutschLanguageTitle }
    ];
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 120 } };

    const theme = getTheme();

    return (
        <Stack horizontal={!smallDevice} tokens={{ childrenGap: 16 }} style={{ marginBottom: smallDevice ? 5 : 0 }}>
            <TextField
                label={IvicosStrings.emailTitleLabel}
                onChange={onChangeImportedPersonData(indexInCSVDATA, 0)}
                onGetErrorMessage={validateEmailMessage}
                placeholder={IvicosStrings.emailTitlePlaceholder}
                value={email}
                validateOnLoad={true}
                readOnly={false}
                styles={textFieldStyles}
            />
            <ComboBox
                label={IvicosStrings.roleTitleLabel}
                options={options}
                selectedKey={role}
                text={role}
                errorMessage={validateRoleMessage}
                allowFreeform
                onChange={onImportedUserRoleChange}
                styles={comboBoxStyles}
            />
            <ComboBox
                label={IvicosStrings.languageTitleLabel}
                options={languageOptions}
                selectedKey={language}
                text={language}
                errorMessage={validateLanguageMessage}
                allowFreeform
                onChange={onImportedUserLanguageChange}
                styles={comboBoxStyles}
            />
            {smallDevice && <Stack style={{ height: 0.1, width: '100%', backgroundColor: theme.palette.black }}></Stack>}
        </Stack>
    );
};

export default ImportedPersonView;
