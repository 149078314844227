/* eslint-disable react/display-name */
import { Checkbox, Dropdown, IChoiceGroupOption, IPersonaProps, Text, TextField, Toggle } from '@fluentui/react';
import BasicList from 'components/BasicList/BasicList';
import ChoiceGroup from 'components/ChoiceGroup/ChoiceGroup';
import DateSelector from 'components/DateSelector/DateSelector';
import ImagePicker from 'components/ImagePicker/ImagePicker';
import MultilineTextField from 'components/MultilineTextField/MultilineTextField';
import TextFieldWithMaxLength from 'components/TextFieldWithMaxLength/TextFieldWithMaxLength';
import { PeoplePicker } from 'features/RoomList/PeoplePicker/PeoplePicker';
import React from 'react';
import ColorSelector from '../ColorSelector/ColorSelector';
import IconPicker from '../IconPicker/IconPicker';
import { IDynamicFormType } from './DynamicForm';
import VisitorEntranceVideoPlayer from 'components/VisitorEntranceVideoPlayer/VisitorEntranceVideoPlayer';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IDynamicFormElementState {
    key: string;
    type: IDynamicFormType;
    label?: string;
    value?: any;
    description?: string;
    callout?: string;
    width?: string;
    height?: string;
    view?: string;
    isVisible?: boolean;
    disabled?: boolean;
    options?: IChoiceGroupOption[];
    resizable?: boolean;
    maxLength?: number;
    placeholder?: string;
    minDate?: Date | undefined;
    items: any;
    overflow: string;
    buttonText?: string;
    errorMessage?: string;
    required?: boolean;
    videoUrl?: string;
}

export interface IDynamicFormStateProps {
    ElemDictionary: any;
}

export const useDynamicFormState: (onChange: any) => IDynamicFormStateProps = (onChange) => {
    const { smallDevice } = useWindowWidth();

    const ElemDictionary = {
        text: (elem: IDynamicFormElementState) => (
            <TextField
                value={elem.value}
                key={elem.key}
                onChange={(event, newValue) => typeof newValue == 'string' && onChange(elem.key, newValue)}
                label={elem.label}
                errorMessage={elem.errorMessage}
                disabled={elem.disabled}
                placeholder={elem.placeholder}
                required={elem.required}
                data-test-id="text-input-field"
            ></TextField>
        ),
        'text-field-with-max-length': (elem: IDynamicFormElementState) => (
            <TextFieldWithMaxLength
                value={elem.value}
                key={elem.key}
                onChange={(event: any, newValue: any) => typeof newValue == 'string' && onChange(elem.key, newValue)}
                label={elem.label}
                errorMessage={elem.errorMessage}
                placeholder={elem.value}
                maxLength={elem.maxLength}
                required={elem.required}
                callout={elem.callout}
                dataTestId="text-input-field-max-length"
            ></TextFieldWithMaxLength>
        ),
        dropdown: (elem: IDynamicFormElementState) => (
            <Dropdown
                key={elem.key}
                label={elem.label}
                options={elem.value.options}
                selectedKey={elem.value.selectedKey}
                onChange={(event, option) => onChange(elem.key, { options: elem.value.options, selectedKey: option?.key })}
                disabled={elem.disabled || false}
                styles={{ dropdownItemsWrapper: { height: elem.height, width: elem.width, overflow: 'scroll' } }}
                className="dropdown"
            />
        ),
        image: (elem: IDynamicFormElementState) => (
            <ImagePicker
                imageURL={elem.value}
                onChange={(p: string) => onChange(elem.key, p)}
                key={elem.key}
                label={elem.label}
                view={elem.view || ''}
                callout={elem.callout}
                buttonText={elem.buttonText}
            />
        ),
        'visitor-entrance-video-player': (elem: IDynamicFormElementState) => (
            <VisitorEntranceVideoPlayer
                key={elem.key}
                label={elem.label}
                videoUrl={elem.videoUrl}
                onUrlChange={(p: string) => onChange(elem.key, p)}
                view={elem.view || ''}
                buttonText={elem.buttonText}
            />
        ),
        color: (elem: IDynamicFormElementState) => (
            <ColorSelector
                key={elem.key}
                value={elem.value}
                label={elem.label}
                onChange={(c: string) => onChange(elem.key, c)}
                callout={elem.callout}
                width={smallDevice ? '100%' : elem.width || '400px'}
                height={elem.height || '60px'}
            />
        ),
        'multiline-text': (elem: IDynamicFormElementState) => (
            <MultilineTextField
                // multiline
                resizable={elem.resizable || false}
                placeholder={elem.value}
                value={elem.value}
                key={elem.key}
                onChange={(event: any, newValue: any) => typeof newValue == 'string' && onChange(elem.key, newValue)}
                label={elem.label}
                maxLength={elem.maxLength}
                callout={elem.callout}
            ></MultilineTextField>
        ),
        icon: (elem: IDynamicFormElementState) => (
            <IconPicker onChange={(i: string) => onChange(elem.key, i)} variant={'Rooms'} value={elem.value} label={elem.label} key={elem.key}></IconPicker>
        ),
        toggle: (elem: IDynamicFormElementState) => (
            <Toggle
                key={elem.key}
                inlineLabel
                label={elem.label}
                checked={elem.value}
                role="checkbox"
                onChange={(event, checked) => onChange(elem.key, checked)}
            ></Toggle>
        ),
        checkbox: (elem: IDynamicFormElementState) => (
            <Checkbox
                key={elem.key}
                label={elem.label}
                checked={elem.value}
                onChange={(event, checked) => onChange(elem.key, checked)}
                disabled={elem.disabled}
            ></Checkbox>
        ),
        'section-heading': (elem: IDynamicFormElementState) => (
            <Text variant="xLarge" key={elem.key}>
                {elem.label}
            </Text>
        ),
        personaSelector: (elem: IDynamicFormElementState) => (
            <PeoplePicker
                key={elem.key}
                label={elem.label}
                membersList={elem.value.membersList ? elem.value.membersList : []}
                selectedItems={elem.value.selectedItems}
                isVisible={elem.value.isVisible}
                onChangeSelections={(items?: IPersonaProps[] | undefined): void => {
                    const userIds = items?.map((item) => item.id);
                    onChange(elem.key, userIds);
                }}
                placeholder={elem.placeholder || ''}
            ></PeoplePicker>
        ),
        datepicker: (elem: IDynamicFormElementState) => (
            <DateSelector label={elem.label} value={elem.value} onChange={(d: Date) => onChange(elem.key, d)} minDate={elem.minDate}></DateSelector>
        ),
        choicepicker: (elem: IDynamicFormElementState) => (
            <ChoiceGroup
                label={elem.label}
                options={elem.options}
                defaultSelectedKey={elem.value}
                onChange={(checked: any) => onChange(elem.key, checked)}
                callout={elem.callout}
            ></ChoiceGroup>
        ),
        'room-list': (elem: IDynamicFormElementState) => (
            <BasicList
                height={elem.height}
                overflow={elem.overflow}
                label={elem.label}
                items={elem.items}
                onChange={(item: any) => onChange(elem.key, item)}
                callout={elem.callout}
            ></BasicList>
        )
    };

    return { ElemDictionary };
};
