import {
    Callout,
    DelayedRender,
    DirectionalHint,
    Dropdown,
    FontWeights,
    getTheme,
    IDropdownStyles,
    ITheme,
    memoizeFunction,
    mergeStyleSets,
    Panel,
    PrimaryButton,
    Stack,
    Text,
    TextField
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { IC } from 'components/SVGIcon';
import { QuestionCircle } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { useInvitationLanguage } from 'shared-state/invitationLanguage/hooks';

export interface IInvitationPanel {
    isInvitePanelOpen: boolean;
    isThisOrganizationBillingSystem: boolean;
    isAnyoneBillingSystem: boolean;
    dismissInvitePanel: () => void;
    setEmailToInvite: React.Dispatch<React.SetStateAction<string>>;
    emailToInvite: string;
    displayEmailError: string;
    inviteUser: () => void;
    emailHasSponsor: () => Promise<boolean | undefined>;
    singleInvitationUserRoleHandler: (e: any, item: any) => void;
    dropdownUserValues: {
        key: string;
        text: string;
    }[];
    userValue: 'user' | 'manager' | undefined;
    setUserValue: React.Dispatch<React.SetStateAction<'user' | 'manager' | undefined>>;
    dropdownLanguageValues: {
        key: string;
        text: string;
    }[];
    singleInvitationUserLanguageHandler: (e: any, item: any) => void;
    toggleHideAddAnyoneToYourBillingList: () => void;
}

const InvitationPanel: React.FC<IInvitationPanel> = ({
    isInvitePanelOpen,
    isAnyoneBillingSystem,
    dismissInvitePanel,
    setEmailToInvite,
    emailToInvite,
    displayEmailError,
    inviteUser,
    emailHasSponsor,
    singleInvitationUserRoleHandler,
    dropdownUserValues,
    userValue,
    setUserValue,
    dropdownLanguageValues,
    singleInvitationUserLanguageHandler,
    toggleHideAddAnyoneToYourBillingList
}) => {
    const routeHistory = useNavigate();
    const theme = getTheme();
    const getDescriptionStyles = memoizeFunction((theme: ITheme) => ({
        root: { color: theme.palette.red, fontWeight: FontWeights.bold, display: displayEmailError }
    }));
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const directionalHint = DirectionalHint.rightCenter;
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 140 } };
    const dropdownStyles2: Partial<IDropdownStyles> = { dropdown: { width: 160 } };

    const onRenderDescription = (props: any): any => {
        return (
            <>
                <Text variant="small" styles={getDescriptionStyles(theme)}>
                    {props.description}
                </Text>
            </>
        );
    };

    const [invitationLanguageChoice, setInvitationLanguageChoice] = useInvitationLanguage();
    const showLanguage = () => {
        const lang = invitationLanguageChoice;
        if (lang === 'de') {
            return 'Deutsch';
        } else return 'English';
    };

    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await idpService.getUser();
                setInvitationLanguageChoice(response.preferred_language);
            } catch (error) {
                console.log(error);
            }
        };
        getUserData();
        showLanguage();
    }, []);

    const handleSendInviteBtn = async () => {
        if (isAnyoneBillingSystem) {
            const hasSponsor = await emailHasSponsor();
            if (hasSponsor) {
                inviteUser();
            } else {
                toggleHideAddAnyoneToYourBillingList();
            }
        } else {
            inviteUser();
            setUserValue('user');
        }
    };
    return (
        <Panel
            headerText={IvicosStrings.inviteMemberLabel}
            isOpen={isInvitePanelOpen}
            onDismiss={dismissInvitePanel}
            closeButtonAriaLabel={IvicosStrings.closeActionTitle}
        >
            <Stack horizontal={false} verticalAlign="start" style={{ marginTop: 32 }} tokens={{ childrenGap: 64 }}>
                <Stack horizontal={false} horizontalAlign="start" tokens={{ childrenGap: 16 }}>
                    <Stack horizontal verticalAlign="center" horizontalAlign="space-between" style={{ width: '100%' }}>
                        <Text variant="mediumPlus" style={{ fontWeight: 'bolder', width: '100%' }}>
                            {IvicosStrings.inviteSingleUserTitle}
                        </Text>
                        <Stack style={{ marginRight: '20px' }}>
                            <IC variant="custom" iconColor={theme.palette.black} size={15} style={{ cursor: 'pointer' }}>
                                <QuestionCircle onClick={toggleIsCalloutVisible} id={buttonId} />
                            </IC>
                        </Stack>
                        {isCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                onDismiss={toggleIsCalloutVisible}
                                role="status"
                                target={`#${buttonId}`}
                                aria-live="assertive"
                                directionalHint={directionalHint}
                            >
                                <DelayedRender>
                                    <Text variant="small">{IvicosStrings.inviteMemberDescription1}</Text>
                                </DelayedRender>
                            </Callout>
                        )}
                    </Stack>
                    <Stack>
                        <TextField
                            // data-test-id="email-for-invitation-textfield"
                            onChange={(e: any) => setEmailToInvite(e.target.value)}
                            style={{ minWidth: 270 }}
                            placeholder={IvicosStrings.emailTitlePlaceholder}
                            value={emailToInvite}
                            description={IvicosStrings.inviteMemberErrorMessage}
                            onRenderDescription={onRenderDescription}
                            label={IvicosStrings.emailTitleLabel}
                            className={'invite'}
                        />
                    </Stack>
                    <Stack>
                        <Dropdown
                            aria-label="role-assignment-dropdown"
                            placeholder={IvicosStrings.rolePlaceHolder}
                            options={dropdownUserValues}
                            selectedKey={userValue}
                            onChange={(e, i) => singleInvitationUserRoleHandler(e, i)}
                            styles={dropdownStyles}
                            label={IvicosStrings.roleTitleLabel}
                        />
                    </Stack>
                    <Stack>
                        <Dropdown
                            aria-label="language-assignment-dropdown"
                            placeholder={showLanguage()}
                            options={dropdownLanguageValues}
                            onChange={(e, i) => singleInvitationUserLanguageHandler(e, i)}
                            styles={dropdownStyles2}
                            label={IvicosStrings.languageTitleLabel}
                        />
                    </Stack>
                    <PrimaryButton
                        text={IvicosStrings.sendInviteButtonTitle}
                        onClick={handleSendInviteBtn}
                        // onClick={() => {
                        //     inviteUser();
                        //     setUserValue('user');
                        // }}
                        aria-label="confirm-invitation-button"
                        iconProps={{ iconName: 'Send' }}
                    />
                </Stack>
                <Stack horizontal={false} horizontalAlign="start" tokens={{ childrenGap: 16 }} style={{ width: '100%' }}>
                    <Text variant="mediumPlus" style={{ fontWeight: 'bolder' }}>
                        {IvicosStrings.inviteMultipleUserTitle}
                    </Text>
                    <Text variant="medium">
                        {IvicosStrings.bulkInviteDescription1}
                        <span style={{ fontWeight: 'bold' }}>.csv</span>
                        {IvicosStrings.bulkInviteDescription2}
                    </Text>
                    <PrimaryButton
                        aria-label="bulk-invitation-button"
                        text={IvicosStrings.openBulkInviteButtonTitle}
                        onClick={() => routeHistory('/dashboard/members/powerImporter')}
                        iconProps={{ iconName: 'AddGroup' }}
                    />
                </Stack>
            </Stack>
        </Panel>
    );
};

export default InvitationPanel;
