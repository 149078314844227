import { IPersonaProps, PersonaPresence } from '@fluentui/react';
import { aCampusMembers } from 'features/Rooms/RoomView/atom';
import { availabilityToPersonaPresence } from 'kits/availabilityKit';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { idpService } from 'services/api/identity-provider.service';
import { useSharedAvailability } from 'shared-state/identity/hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCampusMembersManager = () => {
    const [members, setMembers] = useRecoilState<IPersonaProps[]>(aCampusMembers);

    const [localAvailability] = useSharedAvailability();

    const convertMemberToPersona = (member: any): IPersonaProps => {
        const { userId, profile_pic, name } = member;

        const personaDetails = {
            id: userId,
            primaryText: name,
            imageUrl: profile_pic,
            presence: (localAvailability && availabilityToPersonaPresence(localAvailability)) || PersonaPresence.online
        };
        return personaDetails;
    };
    const fetchMembersList = useCallback(async (): Promise<void> => {
        const fetchedMembers = await idpService.showMembers();
        const memberPersona: IPersonaProps[] = [];
        if (fetchedMembers) {
            fetchedMembers.forEach((member) => memberPersona.push(convertMemberToPersona(member)));
            setMembers(memberPersona);
        }
    }, []);

    useEffect(() => {
        fetchMembersList();
    }, []);

    return [members];
};

export default useCampusMembersManager;
