import { Stack, Text } from '@fluentui/react';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { idpService } from 'services/api/identity-provider.service';
import IvicosStrings from '../kits/language/stringKit';
import NewUserAccessGranted from './NewUserAccessGranted';
import NewUserSomethingWrong from './NewUserSomethingWrong';
import UserAccessGranted from './UserAccessGranted';
import { useLanguage } from 'components/context/LanguageContext';

const MagicLinkVerification: React.FC<{}> = () => {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showAcceptMessage, setShowAcceptMessage] = useState(false);
    const [showUserAccessGranted, setShowUserAccessGranted] = useState(false);
    const [showNewUserAccessGranted, setShowNewUserAccessGranted] = useState(false);
    const { selectedLanguageOption, setSelectedLanguageOption }: any = useLanguage();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const decodeToken: any = jwt_decode(token!);

    useEffect(() => {
        if (!token) {
            return;
        }

        const runAuth = async () => {
            try {
                const authUserResponse = await idpService.authUser(token);
                setSelectedLanguageOption(authUserResponse.toString());
                IvicosStrings.setLanguage(authUserResponse.toString());
                localStorage.setItem('UserLanguageChoice', authUserResponse.toString());
                setShowAcceptMessage(true);
            } catch (error) {
                setShowErrorMessage(true);
                return;
            }

            try {
                await idpService.showUserJoinedOrgs();
                setShowUserAccessGranted(true);
            } catch (error) {
                const user = await idpService.getUser();
                user.name.includes('User-') ? setShowNewUserAccessGranted(true) : setShowUserAccessGranted(true);
            }
        };

        runAuth();
    }, [setSelectedLanguageOption, token]);

    return (
        <div>
            {showErrorMessage && <NewUserSomethingWrong preferredLanguage={selectedLanguageOption} />}
            {showAcceptMessage && showNewUserAccessGranted && <NewUserAccessGranted email={decodeToken.email} />}
            {showAcceptMessage && showUserAccessGranted && <UserAccessGranted email={decodeToken.email} />}
            {showAcceptMessage && !showUserAccessGranted && (
                <Stack
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        justifyContent: 'center'
                    }}
                >
                    <Stack
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            height: 425,
                            width: 375,
                            backgroundColor: '#fff',
                            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                        }}
                    >
                        <Stack
                            style={{
                                height: 375,
                                width: 375,
                                marginTop: 30
                            }}
                        >
                            <Text
                                variant="xxLarge"
                                style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: 169,
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}
                            >
                                {IvicosStrings.processingLoadingText}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </div>
    );
};

export default MagicLinkVerification;
