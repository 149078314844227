import { IPersonaProps } from '@fluentui/react';
import React from 'react';
import { useInvitationsCardState } from './InvitationsCard.state';
import InvitationsCardView from './InvitationsCard.view';
import { IParsedInvitation } from 'features/Header/children/CreateInvitationPanel/Data';

export interface IInvitationsCardProps {
    data: IParsedInvitation;
    members: IPersonaProps[] | undefined;
    isNewCreated?: boolean;
}

const InvitationsCard: React.FC<IInvitationsCardProps> = (props) => {
    const state = useInvitationsCardState(props.data, props.isNewCreated);

    return (
        <InvitationsCardView
            {...{
                ...state,
                ...{
                    data: props.data,
                    members: props.members,
                    isNewCreated: props.isNewCreated
                }
            }}
        ></InvitationsCardView>
    );
};
export default InvitationsCard;
