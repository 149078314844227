import React from 'react';
import { useDateSelectorState } from './DateSelector.state';
import DateSelectorView from './DateSelector.view';

interface IColorSelectorProps {
    label?: string;
    value: Date | undefined;
    onChange: any;
    callout?: string;
    minDate: Date | undefined;
}

const DateSelector: React.FC<IColorSelectorProps> = (props) => {
    const state = useDateSelectorState(props.onChange, props.value);

    return (
        <DateSelectorView
            {...{
                ...state,
                ...{
                    label: props.label,
                    value: props.value,
                    callout: props.callout,
                    minDate: props.minDate
                }
            }}
        ></DateSelectorView>
    );
};

export default DateSelector;
