import React, { CSSProperties } from 'react';
import IconPickerView from './IconPickerView';

export interface IIconPickerProps {
    onChange: (key: string) => void;
    variant: string;
    value: string;
    label?: string;
    style?: CSSProperties;
}

const IconPicker: React.FC<IIconPickerProps> = (props) => {
    return (
        <IconPickerView
            {...{
                ...{
                    onChange: props.onChange,
                    variant: props.variant,
                    value: props.value,
                    label: props.label,
                    style: props.style
                }
            }}
        ></IconPickerView>
    );
};

export default IconPicker;
