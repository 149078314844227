import React from 'react';
import UserBadge from './children/UserBadge';
import UserPersonaView from './children/UserPersona.view';

export interface IUserImageProps {
    sizePersona: number;
    imageUrl?: string;
    secondaryText?: string;
    key?: string;
    hidePersonaDetails?: boolean;
    presence?: any;
    ariaLabel?: string;
    sizeBadge: string;
    fontSize: string;
    userType?: string;
    displayBadgeName: boolean;
    roundTableIsActive?: boolean;
    text?: string;
    talkTimeColor?: string;
    avatarType?: string;
    isForUserProfile?: boolean;
    emailForBadge?: string;
}

const DynamicAvatar: React.FC<IUserImageProps> = (props) => {
    return (
        <>
            {props.userType !== 'visitor' && props.userType !== 'auto-confirmed-visitor' ? (
                <UserPersonaView
                    {...{
                        sizePersona: props.sizePersona,
                        imageUrl: props.imageUrl,
                        text: props.text,
                        secondaryText: props.secondaryText,
                        key: props.key,
                        hidePersonaDetails: props.hidePersonaDetails,
                        presence: props.presence,
                        ariaLabel: props.ariaLabel,
                        talkTimeColor: props.talkTimeColor,
                        avatarType: props.avatarType
                    }}
                />
            ) : (
                <UserBadge
                    {...{
                        sizeBadge: props.sizeBadge,
                        text: props.text,
                        fontSize: props.fontSize,
                        displayBadgeName: props.displayBadgeName,
                        ariaLabel: props.ariaLabel,
                        talkTimeColor: props.talkTimeColor,
                        isForUserProfile: props.isForUserProfile,
                        emailForBadge: props.emailForBadge,
                        avatarType: props.avatarType === 'profile' ? undefined : props.avatarType
                    }}
                />
            )}
        </>
    );
};

export default DynamicAvatar;
