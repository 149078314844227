import { DefaultButton, Dialog, DialogType, IDialogContentProps, Layer, PrimaryButton, Stack } from '@fluentui/react';
import DynamicForm from 'components/DynamicForm/DynamicForm';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { aSetTopic } from './atom';
import { ISetTopicDialogState } from './SetTopicDialog.state';

const SetTopicDialogView: React.FC<ISetTopicDialogState> = (props) => {
    const { toggleSetTopicDialog, cancelTopicName, saveTopicName, formFieldsSetTopicDialog, onSetTopicDialogChange } = props;
    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        styles: {
            title: {
                fontSize: 18
            }
        },
        title: IvicosStrings.setATopicDialogBoxTitle,
        closeButtonAriaLabel: IvicosStrings.closeActionTitle
    };

    const topic = useRecoilValue<any>(aSetTopic);

    return (
        <Stack>
            <Layer>
                <Dialog
                    modalProps={{ isBlocking: true, styles: { main: { width: '70% !important', minWidth: '280px !important', maxWidth: '400px !important' } } }}
                    hidden={toggleSetTopicDialog}
                    onDismiss={cancelTopicName}
                    dialogContentProps={dialogContentProps}
                >
                    <Stack tokens={{ childrenGap: 16 }}>
                        <Stack aria-label="set-topic-dialog-type-box">
                            <DynamicForm formFields={formFieldsSetTopicDialog} onChange={onSetTopicDialogChange} />
                        </Stack>

                        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 16 }}>
                            <DefaultButton aria-label="set-topic-dialog-cancel-button" onClick={cancelTopicName} text={IvicosStrings.cancelActionTitle} />
                            <PrimaryButton
                                aria-label="set-topic-dialog-save-button"
                                onClick={saveTopicName}
                                text={IvicosStrings.saveActionTitle}
                                disabled={topic.length < 1}
                            />
                        </Stack>
                    </Stack>
                </Dialog>
            </Layer>
        </Stack>
    );
};

export default SetTopicDialogView;
