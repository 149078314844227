/* eslint-disable quotes */
import { Stack, Text } from '@fluentui/react';
import { Depths } from '@uifabric/fluent-theme';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { IVisitorsInvalidInvitationState } from './VisitorInvalidInvitation.state';

export interface IVisitorsInvalidInvitationRouteParams {
    invitationId: string;
}

const VisitorsInvalidInvitationView: React.FC<IVisitorsInvalidInvitationState> = (props) => {
    return (
        <Stack grow verticalAlign="center" style={{ alignItems: 'center' }}>
            <Stack style={{ boxShadow: Depths.depth64, width: 350, height: 260, backgroundColor: '#FFFFFF' }}>
                <Stack style={{ width: 350, height: 300, backgroundColor: '#FFFFFF' }}>
                    <Stack style={{ margin: 20 }}>
                        <Text style={{ marginTop: 10, marginBottom: 20, fontWeight: 'bold' }} variant="xLarge">
                            {IvicosStrings.invitationLinkNotValid}
                        </Text>
                        <Text style={{ marginTop: 30, marginBottom: 20 }}>{props.notActiveMessageLine}</Text>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default VisitorsInvalidInvitationView;
