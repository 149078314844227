import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import IvicosStrings from 'kits/language/stringKit';
import { IProfile } from 'shared-state/identity/types';

export const shuffleArray = (originalArray: ISpeakerStatsSpeaker[]): ISpeakerStatsSpeaker[] => {
    return originalArray.map((obj) => ({ ...obj })).sort(() => 0.5 - Math.random());
};

export const timerOptions = [
    { key: '0.5', text: '0.5' },
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
    { key: '4', text: '4' },
    { key: '5', text: '5' },
    { key: '6', text: '6' },
    { key: '7', text: '7' },
    { key: '8', text: '8' },
    { key: '9', text: '9' },
    { key: '10', text: '10' }
];

export const timerForm: (roundTableMinutes: any, isRoundTableTimerActivated: boolean, smallDevice: any) => IDynamicFormElementProps[] = (
    roundTableMinutes,
    isRoundTableTimerActivated,
    smallDevice
) => {
    return [
        {
            key: 'applyTimer',
            label: IvicosStrings.applyTimer,
            value: isRoundTableTimerActivated,
            type: 'checkbox'
        },
        {
            key: 'timer',
            label: IvicosStrings.minutesLabel,
            value: {
                selectedKey: roundTableMinutes,
                options: timerOptions
            },
            type: 'dropdown',
            disabled: !isRoundTableTimerActivated,
            height: smallDevice ? '' : '150px'
        }
    ];
};

export interface ISpeakerStatsSpeaker {
    userId: string;
    userName?: string;
    speakerTime: number;
    uid?: string;
    raisedHandAt: number;
    hasLeft: boolean;
    isSharedVideoOwner: boolean;
}
export interface ISpeakerStats {
    speakerData: ISpeakerStatsSpeaker[];
}

export interface IRoundTableSpeakersList {
    avatarType?: string;
    hasSpoken?: boolean;
    raisedHandAt?: number;
    userId?: string;
    userName?: string;
    speakerTime?: number;
    uid?: string;
}

export interface ISpeakerStatsStateProps {
    speakerTimeReminderIsEnabled: boolean;
    setSpeakerTimeReminderIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    modalTalkOpen: boolean;
    setModalTalkOpen: React.Dispatch<React.SetStateAction<boolean>>;
    modalLocalTalk: boolean;
    setModalLocalTalk: Function;
    pretifyTime: (timeInMs: number) => string;
    onTalkModalDismiss: () => void;
    onLocalTalkDismiss: () => void;
    totalTime: number;
    hideRoundTableModeDialog: boolean;
    toggleHideRoundTableModeDialog: () => void;
    initialiseRoundTableMode: () => void;
    roundTableIsActive: boolean;
    roundTableTimerActivated: boolean;
    toggleRoundTableTimerActivated: () => void;
    roundTableMinutes: string;
    currentSpeaker: string;
    localProfile: IProfile | undefined;
    onNextClicked: () => void;
    roundTableSpeakersList: IRoundTableSpeakersList[];
    roundTableNames: string[];
    timer: number;
    onIncludeSpeaker: (userName: string) => void;
    formFields: IDynamicFormElementProps[];
    onChangeMinutes: (key: string, newValue: any) => void;
    isRoundTableButtonEnabled: boolean;
    terminateRoundTableMode: () => void;
    speakerRemoval: (userName: string) => void;
    roundTableButtonDisabled: boolean;
}
