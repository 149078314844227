import { PivotItem } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';

export interface IOnboardingLanguage {
    displayName: string;
    languageCode: string;
}

export interface IOnboardingVideoPopupState {
    dialogTitle: string;
    onDialogDismiss: Function;
    isDialogOpen: boolean;
    //! deactivated the tutorialConfirmation step
    // currentStep: 'tutorialConfirmation' | 'introVideo';
    // onSkipTutorial: Function;
    // onStartTutorial: Function;

    introVideoSrc: string;
    onSkipIntroVideo: Function;
    availableLanguages: IOnboardingLanguage[];
    onLanguageSelected: (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => void;

    videoRef: MutableRefObject<HTMLVideoElement | null>;
    isVideoPlaying: boolean;
    playVideo: Function;
    pauseVideo: Function;
    language: string;
}

export const useOnboardingVideoPopupState: () => IOnboardingVideoPopupState = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const [language, setLanguage] = useState<string>('EN');

    const availableLanguages: IOnboardingLanguage[] = [
        {
            displayName: 'English',
            languageCode: 'EN'
        },
        {
            displayName: 'Deutsch',
            languageCode: 'DE'
        }
    ];

    const videoSrcByLanguage: any = {
        EN: 'https://static-cdn.vercel.app/ivCAMPUS-Onboarding-EN-short.mp4',
        DE: 'https://static-cdn.vercel.app/ivCAMPUS-Onboarding-DE-short.mp4'
    };

    //! deactivated the tutorialConfirmation step
    // const [currentStep, setCurrentStep] = useState<IOnboardingVideoPopupState['currentStep']>('introVideo');
    // const disableTutorials = () => {
    //     localStorage.setItem('intro-areas', 'false');
    //     localStorage.setItem('intro-manage-access', 'false');
    //     localStorage.setItem('intro-campus-settings', 'false');
    //     localStorage.setItem('intro-office', 'false');
    // };

    // const enableTutorials = () => {
    //     localStorage.setItem('intro-areas', 'true');
    //     localStorage.setItem('intro-manage-access', 'true');
    //     localStorage.setItem('intro-campus-settings', 'true');
    //     localStorage.setItem('intro-office', 'true');
    // };

    const checkIfOpen = async () => {
        try {
            const user = await idpService.getUser();
            const willDialogBeOpen = !user.videoDisplayed;
            setIsDialogOpen(willDialogBeOpen);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        checkIfOpen();
    }, []);

    const doNotShowVideoAgain = () => {
        idpService.videoDisplayedToUser();
    };

    const onDialogDismiss = () => {
        doNotShowVideoAgain();
        setIsDialogOpen(false);
    };

    //! deactivated the tutorialConfirmation step
    // const onDialogDismiss = () => {
    //     if (currentStep == 'introVideo') {
    //         doNotShowVideoAgain();
    //         setCurrentStep('tutorialConfirmation');
    //         return;
    //     }
    //     disableTutorials();
    //     setIsDialogOpen(false);
    // };

    // const dialogTitlesByStep = {
    //     introVideo: IvicosStrings.introVideoTitle,
    //     tutorialConfirmation: IvicosStrings.tutorialConfirmationTitle
    // };

    //const dialogTitle = dialogTitlesByStep[currentStep] || '';

    // const onSkipIntroVideo = () => {
    //     setCurrentStep('tutorialConfirmation');
    //     doNotShowVideoAgain();
    // };

    const dialogTitle = IvicosStrings.introVideoTitle;

    const onSkipIntroVideo = () => {
        doNotShowVideoAgain();
        setIsDialogOpen(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onLanguageSelected = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        const selectedLanguage = item?.props.itemKey;
        selectedLanguage && setLanguage(selectedLanguage);
    };

    const introVideoSrc = videoSrcByLanguage[language];
    const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);

    const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null);

    const playVideo = () => {
        videoRef.current?.play();
        setIsVideoPlaying(true);
    };
    const pauseVideo = () => {
        videoRef.current?.pause();
        setIsVideoPlaying(false);
    };

    //! deactivated the tutorialConfirmation step
    // const onStartTutorial = () => {
    //     setIsDialogOpen(false);
    //     enableTutorials();
    //     window.location.reload();
    // };

    // const onSkipTutorial = () => {
    //     setIsDialogOpen(false);
    //     disableTutorials();
    // };

    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await idpService.getUser();
                const Language = response.preferred_language;
                IvicosStrings.setLanguage(Language || (navigator.language === 'de' ? 'de' : 'en'));

                setLanguage(response.preferred_language);
            } catch (error) {
                console.log(error);
            }
        };
        getUserData();
    }, []);

    return {
        isVideoPlaying,
        playVideo,
        pauseVideo,
        videoRef,
        isDialogOpen,
        onDialogDismiss,
        dialogTitle,
        onSkipIntroVideo,
        availableLanguages,
        introVideoSrc,
        onLanguageSelected,
        //! deactivated the tutorialConfirmation step
        // currentStep,
        // onStartTutorial,
        // onSkipTutorial,
        language
    };
};
