import React from 'react';
import { RoundTableSpeakerInfo } from '../../RoundTableStateManager';
import { useSpeakerStatsUserState } from './SpeakerStatsUser.state';
import SpeakerStatsUserView from './SpeakerStatsUser.view';

export interface ISpeakerStatsUserProps {
    speakerTime?: number;
    userId?: string;
    totalTime: number;
    userName: string;
    prettifyTime: (timeInMs: number) => string;
    roundTableIsActive: boolean;
    avatarType?: string;
    currentSpeakerIndex?: number;
    roundTableSpeakersList?: RoundTableSpeakerInfo[];
    userIndexAtRoundTableSpeakerList?: number;
    onIncludeSpeaker?: (userToAdd: RoundTableSpeakerInfo) => void;
    onRemoveSpeaker?: (userToRemove: RoundTableSpeakerInfo) => void;
}

const SpeakerStatsUser: React.FC<ISpeakerStatsUserProps> = (props) => {
    const state = useSpeakerStatsUserState(props.userName);
    return (
        <SpeakerStatsUserView
            {...{
                ...state,
                ...props
            }}
        />
    );
};
export default SpeakerStatsUser;
