import {
    Callout,
    DefaultButton,
    DelayedRender,
    DirectionalHint,
    IPersonaProps,
    IconButton,
    Label,
    Panel,
    PrimaryButton,
    Stack,
    Text,
    TextField,
    mergeStyleSets
} from '@fluentui/react';
import DynamicForm, { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import IvicosStrings from 'kits/language/stringKit';
import React, { useState } from 'react';
import 'styles/helpers.css';
import { IParsedInvitation, IRoomListsDetails } from './Data';
import InvitationsCard from '../InvitationsManager/children/InvitationsCard/InvitationsCard';
import { IvicosColors } from 'kits/colorKit';
import { QuestionCircle } from 'kits/IconKit2';
import { IC } from 'components/SVGIcon';
import { useId } from '@uifabric/react-hooks';
import CopyButton from 'components/CopyButton/CopyButton';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { IProfile } from 'shared-state/identity/types';
import { sendEvent } from 'kits/eventKit';
import VideoForTheWaitingRoom from './VideoForTheWaitingRoom';
import AlertModal from 'components/AlertModals/AlertModal';

export interface ICreateInvitationPanelViewProps {
    formFields: IDynamicFormElementProps[];
    onCreateInviteFormChange: (key: string, newValue: any) => void;
    onCreateInvitation: any;
    dismissCreateInvitationPanel: () => void;
    isDisable: boolean;
    updateIsOpen: boolean;
    onModalDismiss: any;
    invitationLinkWithCampusName: string;
    editablePartOfTheLink: string;
    setUpdateIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdateInvitationCustomUrl: () => void;
    customPathError: string;
    onChangeCustomUrlInputField: (value: string | undefined) => void;
    selectedArea: boolean;
    fetchedRooms: IRoomListsDetails[];
    panelIsOpen: boolean;
    members: IPersonaProps[] | undefined;
    newCreatedInvitation: IParsedInvitation | undefined;
    setNewCreatedInvitation: React.Dispatch<React.SetStateAction<IParsedInvitation | undefined>>;
    newEditablePartOfTheLink: string;
    setChanges: React.Dispatch<React.SetStateAction<any>>;
    creatorOfTheInvitation: IProfile | undefined;
    emailSubject: string;
    emailBody: string;
    customizedVideoUrlForWaitingRoom: string;
    setCustomizedVideoUrlForWaitingRoom: React.Dispatch<React.SetStateAction<string>>;
    onSavingVideoUrlForWaitingRoom: (isDefaultVideoIsUsed: boolean, defaultVideoAtVisitorEntrance?: string) => void;
    toggleAlert: () => void;
    displayAlert: boolean;
}

const CreateInvitationPanelView: React.FC<ICreateInvitationPanelViewProps> = (props) => {
    const {
        formFields,
        onCreateInviteFormChange,
        onCreateInvitation,
        dismissCreateInvitationPanel,
        isDisable,
        updateIsOpen,
        invitationLinkWithCampusName,
        editablePartOfTheLink,
        setUpdateIsOpen,
        onUpdateInvitationCustomUrl,
        customPathError,
        onChangeCustomUrlInputField,
        selectedArea,
        fetchedRooms,
        panelIsOpen,
        members,
        newCreatedInvitation,
        setNewCreatedInvitation,
        newEditablePartOfTheLink,
        setChanges,
        creatorOfTheInvitation,
        emailSubject,
        emailBody,
        customizedVideoUrlForWaitingRoom,
        setCustomizedVideoUrlForWaitingRoom,
        onSavingVideoUrlForWaitingRoom,
        displayAlert,
        toggleAlert
    } = props;

    const [isButtonHovered, setIsButtonHovered] = useState<any>({
        update: false,
        calendar: false,
        email: false,
        download: false
    });
    const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
    const buttonId = useId('callout-button');
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            padding: '10px 14px'
        }
    });

    const customUrlCallout = IvicosStrings.customUrlCallout.split('.');

    const extractDateString = (dateObject: Date | undefined) => {
        if (dateObject) {
            return dateObject.getFullYear() + '-' + ('0' + (dateObject.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObject.getDate()).slice(-2);
        }
        return undefined;
    };

    return (
        <Panel
            isOpen={panelIsOpen}
            onDismiss={() => {
                dismissCreateInvitationPanel();
                setNewCreatedInvitation(undefined);
                setChanges({
                    name: '',
                    validity: 'single',
                    recurringType: {
                        selectedKey: 'everyDay'
                    },
                    on: { selectedKey: 'monday' },
                    hosts: [creatorOfTheInvitation?.uid],
                    visitorEntrance: 'direct'
                });
                setTimeout(() => {
                    sendEvent('refetchInvitations', {});
                }, 500);
            }}
            headerText={IvicosStrings.createInvitationTitle}
            styles={{ main: { background: 'white', paddingBottom: '100px' } }}
            aria-label="invitation-creation-panel"
        >
            <Stack
                horizontal={false}
                grow
                style={{
                    overflowX: 'hidden',
                    scrollBehavior: 'smooth'
                }}
            >
                {displayAlert && <AlertModal title={IvicosStrings.errorInButton} subText={IvicosStrings.invalidDataAlert} toggleAlert={toggleAlert} />}
                {!updateIsOpen && !newCreatedInvitation ? (
                    // PANEL TO CREATE THE INVITATION //
                    <Stack>
                        <DynamicForm formFields={formFields} onChange={onCreateInviteFormChange}></DynamicForm>
                        {selectedArea && fetchedRooms.length === 0 && (
                            <Text style={{ fontWeight: 600, color: 'red' }}>{IvicosStrings.NoRoomAvailableDescription}</Text>
                        )}
                        <Stack
                            horizontal
                            horizontalAlign="center"
                            style={{ position: 'fixed', bottom: 0, backgroundColor: 'white', padding: '0px 0px 24px 0px' }}
                        >
                            <DefaultButton text={IvicosStrings.cancelActionTitle} style={{ marginRight: 16 }} onClick={dismissCreateInvitationPanel} />
                            <PrimaryButton
                                text={IvicosStrings.createAndContinue}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onCreateInvitation();
                                }}
                                disabled={isDisable}
                                aria-label="submit-new-invitation"
                            />
                        </Stack>
                    </Stack>
                ) : (
                    // PANEL AFTER CREATING THE INVITATION //
                    newCreatedInvitation && (
                        <Stack aria-label="after-inv-creation-panel" tokens={{ childrenGap: 40 }} style={{ marginTop: 20 }}>
                            {/* invitation card */}
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Text style={{ fontWeight: 'bold', marginBottom: 16 }} data-test-id="invitation-card-section-title">
                                    {IvicosStrings.updateInviationTitle}
                                </Text>
                                <InvitationsCard data={newCreatedInvitation} members={members} isNewCreated={true} />
                            </Stack>

                            {/* youtube video for visitor entrance */}
                            <VideoForTheWaitingRoom
                                customizedVideoUrlForWaitingRoom={customizedVideoUrlForWaitingRoom}
                                setCustomizedVideoUrlForWaitingRoom={setCustomizedVideoUrlForWaitingRoom}
                                onSavingVideoUrlForWaitingRoom={onSavingVideoUrlForWaitingRoom}
                            />

                            {/* custom url */}
                            <Stack tokens={{ childrenGap: 16 }}>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <Text style={{ fontWeight: 'bold' }} data-test-id="custom-url-section-title">
                                        {IvicosStrings.editCustomUrlTitle}
                                    </Text>
                                    <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                                        <QuestionCircle onClick={() => setIsCalloutVisible(!isCalloutVisible)} id={buttonId} />
                                    </IC>
                                    {/* callout for custom url */}
                                    {isCalloutVisible && (
                                        <Callout
                                            className={styles.callout}
                                            onDismiss={() => setIsCalloutVisible(false)}
                                            role="status"
                                            target={`#${buttonId}`}
                                            aria-live="assertive"
                                            directionalHint={DirectionalHint.leftCenter}
                                        >
                                            <DelayedRender>
                                                <Stack tokens={{ childrenGap: 8 }}>
                                                    <Text variant="small">{customUrlCallout[0]}</Text>
                                                    <Text variant="small">{customUrlCallout[1]}</Text>
                                                    <Text variant="small">{customUrlCallout[2]}</Text>
                                                </Stack>
                                            </DelayedRender>
                                        </Callout>
                                    )}
                                </Stack>
                                <Stack horizontal verticalAlign="space-around" style={{ width: '100%' }} aria-label="custom-url-preview">
                                    <Text style={{ width: '95%', wordBreak: 'break-all', marginRight: 2 }}>
                                        {invitationLinkWithCampusName}
                                        <strong>{editablePartOfTheLink}</strong>
                                    </Text>
                                    <CopyButton
                                        ariaLabel={'copy-invitation-link-icon'}
                                        type="alternative-view"
                                        value={`${invitationLinkWithCampusName}${editablePartOfTheLink}`}
                                    />
                                </Stack>
                                {!updateIsOpen ? (
                                    <PrimaryButton
                                        onClick={() => setUpdateIsOpen(true)}
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent',
                                            border: 0,
                                            color: IvicosColors.Schwarz,
                                            fontWeight: 'lighter',
                                            display: 'flex',
                                            alignItems: 'start',
                                            marginBottom: '32px',
                                            padding: 0
                                        }}
                                        onMouseEnter={() => setIsButtonHovered({ update: true })}
                                        onMouseLeave={() => setIsButtonHovered({ update: false })}
                                        aria-label="custom-url-edit-button"
                                    >
                                        <IconButton iconProps={{ iconName: 'Link' }} />
                                        <Text style={{ textDecoration: isButtonHovered.update ? 'underline' : '' }}>{IvicosStrings.editCustomUrl}</Text>
                                    </PrimaryButton>
                                ) : (
                                    // Input box to edit the custom URL
                                    <Stack>
                                        <TextField
                                            data-test-id="modify-link-appearance-modal-text-field" //doesn't detect aria-label
                                            placeholder={'Type anything here'}
                                            type="text"
                                            onChange={(ev, newValue: string | undefined) => {
                                                onChangeCustomUrlInputField(newValue);
                                            }}
                                            minLength={1}
                                            maxLength={50}
                                            value={newEditablePartOfTheLink}
                                            errorMessage={customPathError}
                                        />
                                        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 44 }}>
                                            <IconButton
                                                iconProps={{ iconName: 'CheckMark' }}
                                                onClick={() => onUpdateInvitationCustomUrl()}
                                                disabled={
                                                    customPathError === IvicosStrings.customUrlModalErrorNoSpecialCharacters ||
                                                    customPathError === IvicosStrings.customUrlModalErrorMinimumLimit ||
                                                    customPathError === IvicosStrings.customUrlModalErrorCustomUrlExists
                                                }
                                                aria-label="accept-new-custom-url"
                                            />
                                            <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => setUpdateIsOpen(false)} />
                                        </Stack>
                                    </Stack>
                                )}
                            </Stack>

                            {/* calendar integration */}
                            <Stack tokens={{ childrenGap: 16 }} style={{ marginTop: '15px' }}>
                                <Text style={{ fontWeight: 'bold' }} data-test-id="invitation-actions-section-title">
                                    {IvicosStrings.calendarIntegrationTitle}
                                </Text>
                                <Stack tokens={{ childrenGap: 16 }}>
                                    {/* Email integration */}
                                    <PrimaryButton
                                        onClick={() => window.open(`mailto:email@email.com?subject=${emailSubject}&body=${emailBody}`)}
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent',
                                            border: 0,
                                            color: IvicosColors.Schwarz,
                                            fontWeight: 'lighter',
                                            display: 'flex',
                                            alignItems: 'start',
                                            padding: 0
                                        }}
                                        onMouseEnter={() => setIsButtonHovered({ email: true })}
                                        onMouseLeave={() => setIsButtonHovered({ email: false })}
                                        aria-label="invitation-send-email-button"
                                    >
                                        <IconButton iconProps={{ iconName: 'Mail' }} />
                                        <Text style={{ textDecoration: isButtonHovered.email ? 'underline' : '' }}>
                                            {IvicosStrings.invitationToEmailButton}
                                        </Text>
                                    </PrimaryButton>

                                    {/* Calendar integration */}
                                    <Stack tokens={{ childrenGap: 16 }}>
                                        <Label data-test-id="invitation-add-to-calendar-title">{IvicosStrings.calendarIntegrationButton}</Label>
                                        <AddToCalendarButton
                                            name={newCreatedInvitation.name}
                                            options={['Outlook.com', 'Google', 'Apple', 'MicrosoftTeams', 'Microsoft365', 'Yahoo', 'iCal']}
                                            startDate={extractDateString(newCreatedInvitation.validFromDate)}
                                            endDate={extractDateString(newCreatedInvitation.validToDate)}
                                            startTime={newCreatedInvitation.validFromTime}
                                            endTime={newCreatedInvitation.validToTime}
                                            location={`${invitationLinkWithCampusName}${editablePartOfTheLink}`}
                                            timeZone="currentBrowser"
                                            iCalFileName={newCreatedInvitation.name}
                                            trigger="click"
                                            buttonsList
                                            hideTextLabelButton
                                            buttonStyle="round"
                                            size="5"
                                            label={IvicosStrings.calendarIntegrationButton}
                                            aria-label="invitation-add-to-calendar-group-buttons"
                                        />
                                    </Stack>

                                    {/* Footer */}
                                    <Stack
                                        horizontal
                                        horizontalAlign="end"
                                        style={{ position: 'fixed', bottom: 0, right: 0, backgroundColor: 'white', padding: '0px 10px 24px 0px' }}
                                    >
                                        <PrimaryButton
                                            text={IvicosStrings.closeActionTitle}
                                            style={{ marginRight: 16 }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dismissCreateInvitationPanel();
                                                setNewCreatedInvitation(undefined);
                                                setChanges({
                                                    name: '',
                                                    validity: 'single',
                                                    recurringType: {
                                                        selectedKey: 'everyDay'
                                                    },
                                                    on: { selectedKey: 'monday' },
                                                    hosts: [creatorOfTheInvitation?.uid],
                                                    visitorEntrance: 'direct'
                                                });
                                                setTimeout(() => {
                                                    sendEvent('refetchInvitations', {});
                                                }, 500);
                                            }}
                                            aria-label="close-second-invitation-panel"
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    )
                )}
            </Stack>
        </Panel>
    );
};
export default CreateInvitationPanelView;
