import React from 'react';
import { useSetupAssistantState } from './SetupAssistant.state';
import SetupAssistantView from './SetupAssistant.view';

const SetupAssistant: React.FC = () => {
    const state = useSetupAssistantState();

    return (
        <SetupAssistantView
            {...{
                ...state
            }}
        ></SetupAssistantView>
    );
};
export default SetupAssistant;
