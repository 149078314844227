import { Checkbox, IPersonaProps, Modal, Pivot, PivotItem, PrimaryButton, Separator, Stack, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react/lib/Button';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { SetterOrUpdater } from 'recoil';
import { useVisitorList } from 'shared-state/presence/hooks';
import utf8 from 'utf8';
import CreateInvitationPanel from '../CreateInvitationPanel/CreateInvitationPanel';
import InvitationsCard from './children/InvitationsCard/InvitationsCard';
import VisitorCard from './children/VisitorsCard/VisitorsCard';

export interface IInvitationsManagerProps {
    setInvitationsManagerModal: SetterOrUpdater<boolean>;
    invitationsFilterValue: string;
    saveInvitationsFilterValue: (e: any, newValue: any) => void;
    groupedInvitationsByDayActive: any;
    groupedInvitationsByCondition: any;
    groupedInvitationsByDayExpired: any;
    isCreateInvitationOpen: boolean;
    openCreateInvitationPanel: () => void;
    dismissCreateInvitationPanel: () => void;
    areaNamesList: {
        key: string;
        text: string;
    }[];
    members: IPersonaProps[] | undefined;
    translateMonth: (day: string) => string | undefined;
    translateDay: (con: string) => string | undefined;
}

const InvitationsManagerView: React.FC<IInvitationsManagerProps> = (props) => {
    const {
        setInvitationsManagerModal,
        invitationsFilterValue,
        saveInvitationsFilterValue,
        groupedInvitationsByDayActive,
        groupedInvitationsByCondition,
        groupedInvitationsByDayExpired,
        isCreateInvitationOpen,
        openCreateInvitationPanel,
        dismissCreateInvitationPanel,
        areaNamesList,
        members,
        translateMonth,
        translateDay
    } = props;

    const visitorArray = useVisitorList();
    const visitors = visitorArray[0];
    const [expiredVisible, { toggle: toggleExpiredVisible }] = useBoolean(false);

    return (
        <>
            <CreateInvitationPanel
                areaNamesList={areaNamesList}
                members={members}
                dismissCreateInvitationPanel={dismissCreateInvitationPanel}
                panelIsOpen={isCreateInvitationOpen}
            />
            <Modal isOpen={true} onDismiss={() => setInvitationsManagerModal(false)}>
                <Stack horizontal horizontalAlign="space-between" style={{ width: '100%', padding: '10px 10px 0px 10px' }}>
                    <Text variant="xLarge">{IvicosStrings.invitationsManagerTitle}</Text>
                    <IconButton ariaLabel="Close popup modal" onClick={() => setInvitationsManagerModal(false)} iconProps={{ iconName: 'Clear' }} />
                </Stack>
                <Stack style={{ width: '744px', height: '502px', padding: '10px 0px 0px 10px' }}>
                    <Pivot aria-label="Invitations manager pivot">
                        <PivotItem headerText={IvicosStrings.invitationsTitle} className="invitations-tab">
                            <Stack horizontal style={{ margin: '32px 0px 0px 7px' }}>
                                <TextField
                                    style={{ width: '343px' }}
                                    placeholder={IvicosStrings.filterInvitationsPlaceholder}
                                    iconProps={{ iconName: 'search' }}
                                    type="text"
                                    onChange={saveInvitationsFilterValue}
                                    value={invitationsFilterValue}
                                    data-test-id="filter-invitations"
                                />
                                <PrimaryButton
                                    aria-label="create-invitation-button"
                                    text={IvicosStrings.createInvitationButtonTitle}
                                    iconProps={{ iconName: 'Add' }}
                                    style={{ marginLeft: '30px' }}
                                    onClick={openCreateInvitationPanel}
                                />
                            </Stack>
                            <Stack horizontal horizontalAlign="space-around" style={{ marginTop: '30px' }}>
                                <Stack style={{ width: '350px', height: '330px' }}>
                                    <Text variant="mediumPlus" style={{ fontWeight: 'bold', paddingLeft: '7px', paddingBottom: '5px' }}>
                                        {IvicosStrings.upcomingInvitationsTitle}
                                    </Text>
                                    <Stack style={{ margin: '12px 0px 10px 7px' }}>
                                        <Checkbox
                                            checked={expiredVisible}
                                            onChange={() => {
                                                toggleExpiredVisible();
                                            }}
                                            className="show-expired-invitations-button"
                                            label={IvicosStrings.showExpiredInvitationsActionTitle}
                                        />
                                    </Stack>
                                    <Stack style={{ height: '320px', padding: '10px', overflow: 'scroll' }} data-test-id="single-invitations-list">
                                        {Object.keys(expiredVisible ? groupedInvitationsByDayExpired : groupedInvitationsByDayActive).map((day: string) => (
                                            <>
                                                <Stack style={{ fontWeight: 'bold', margin: '10px 0px' }}>
                                                    {/* {months[new Date(day).getMonth()]}  {new Date(day).getFullYear()} */}
                                                    {translateMonth(day)} {new Date(day).getFullYear()}
                                                </Stack>
                                                {expiredVisible
                                                    ? groupedInvitationsByDayExpired[day].map((inv: any) => (
                                                          <>
                                                              <Stack key={inv.index}>
                                                                  <InvitationsCard data={inv} members={members} />
                                                              </Stack>
                                                          </>
                                                      ))
                                                    : groupedInvitationsByDayActive[day].map((inv: any) => (
                                                          <>
                                                              <Stack key={inv.index}>
                                                                  <InvitationsCard data={inv} members={members} />
                                                              </Stack>
                                                          </>
                                                      ))}
                                            </>
                                        ))}
                                    </Stack>
                                </Stack>
                                <Separator vertical></Separator>
                                <Stack style={{ width: '350px', height: '330px', marginRight: 10 }}>
                                    <Text variant="mediumPlus" style={{ fontWeight: 'bold', paddingLeft: '7px', paddingBottom: '5px' }}>
                                        {IvicosStrings.recurringInvitationsTitle}
                                    </Text>
                                    <Stack style={{ height: '320px', padding: '10px', overflow: 'scroll' }} data-test-id="recurring-invitations-list">
                                        {Object.keys(groupedInvitationsByCondition).map((cond: string) => {
                                            return (
                                                <>
                                                    {groupedInvitationsByCondition[cond].length !== 0 ? (
                                                        <>
                                                            <Stack style={{ fontWeight: 'bold', margin: '10px 0px' }}>{translateDay(cond)}</Stack>
                                                            {groupedInvitationsByCondition[cond].map((inv: any) => {
                                                                return (
                                                                    <>
                                                                        <Stack key={inv.index}>
                                                                            <InvitationsCard data={inv} members={members} />
                                                                        </Stack>
                                                                    </>
                                                                );
                                                            })}
                                                        </>
                                                    ) : null}
                                                </>
                                            );
                                        })}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </PivotItem>
                        <PivotItem headerText={IvicosStrings.waitingVisitorsTitle} className="waiting-visitors-tab">
                            {visitors.length > 0 ? (
                                visitors.map((visitor: any) => {
                                    return (
                                        <>
                                            <VisitorCard
                                                name={utf8.decode(visitor.displayName)}
                                                key={visitor.uid}
                                                host={visitor.host}
                                                members={members}
                                                invitationId={visitor.invitationId}
                                                subInfo={visitor.subInfo}
                                            />
                                        </>
                                    );
                                })
                            ) : (
                                <Stack style={{ margin: '20px 0 0 10px' }}>
                                    <Text>{IvicosStrings.noVisitorsDescriptionTitle}</Text>
                                </Stack>
                            )}
                        </PivotItem>
                    </Pivot>
                </Stack>
            </Modal>
        </>
    );
};

export default InvitationsManagerView;
