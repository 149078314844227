import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useCurrentRoomId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';
import { aSetTopic, aSetTopicDialog, aShowTopic } from './atom';

export interface ISetTopicDialogState {
    toggleSetTopicDialog: boolean;
    formFieldsSetTopicDialog: IDynamicFormElementProps[];
    cancelTopicName: () => void;
    saveTopicName: () => void;
    onSetTopicDialogChange: (key: string, newValue: any) => void;
}

export const useSetTopicDialogState: () => ISetTopicDialogState = () => {
    const [toggleSetTopicDialog, setToggleSetTopicDialog] = useRecoilState<boolean>(aSetTopicDialog);
    const [topic, setTopic] = useRecoilState<any>(aSetTopic);
    const setShowTopic = useSetRecoilState<boolean>(aShowTopic);

    const [roomId] = useCurrentRoomId();
    const usersInRoom = useUsersInRoom(roomId || '');
    const { sendRoomTopic } = useEmittingEvents();

    const cancelTopicName = () => {
        setToggleSetTopicDialog(true);
        if (localStorage.getItem('topicTitle') === null) {
            setTopic('');
            setShowTopic(false);
        } else {
            setTopic(localStorage.getItem('topicTitle'));
        }
    };

    const saveTopicName = () => {
        if (topic.length > 0) {
            setTopic(topic);
            localStorage.setItem('topicTitle', topic);
            localStorage.setItem('roomIdWithTopic', roomId);
            setToggleSetTopicDialog(true);
            sendRoomTopicToAll();
        }
    };

    const sendRoomTopicToAll = () => {
        usersInRoom.map((user: any) => {
            if (localStorage.getItem('topicTitle')) {
                sendRoomTopic(user.uid, user.room, topic);
            }
        });
    };

    const formFieldsForSetTopicDialog: () => IDynamicFormElementProps[] = () => {
        return [
            {
                key: 'topic',
                label: '',
                value: topic,
                type: 'multiline-text',
                maxLength: 72
            }
        ];
    };

    const [formFieldsSetTopicDialog, setFormFieldsSetTopicDialog] = useState<IDynamicFormElementProps[]>(formFieldsForSetTopicDialog());

    const onSetTopicDialogChange = (key: string, newValue: any) => {
        setTopic(newValue);
        setFormFieldsSetTopicDialog((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };

    useEffect(() => {
        onSetTopicDialogChange('topic', topic);
    }, [topic]);

    useEffect(() => {
        sendRoomTopicToAll();
    }, [usersInRoom.length]);

    return {
        toggleSetTopicDialog,
        cancelTopicName,
        saveTopicName,
        formFieldsSetTopicDialog,
        onSetTopicDialogChange
    };
};
