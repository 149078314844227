import { DefaultButton, getTheme, Label, Modal, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { useOrganizationBranding } from 'features/Header/hooks';
import { getTextColorForBackground, IvicosColors } from 'kits/colorKit';
import EditImage from 'kits/IconKit2/EditImage';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useCurrentOrgId } from 'shared-state/location/hook';
import { IImagePickerImage } from '../ImagePicker.state';
import ImagePickerModal from './ImagePickerModal';

interface IVisitorEntranceImagePickerViewProps {
    onDismiss: () => void;
    modalIsOpen: boolean;
    selectedImage: IImagePickerImage;
    imageOptions: IImagePickerImage[];
    onImageSelect: (i: IImagePickerImage) => void;
    onFile: (f: any) => void;
    onModalOpen: () => void;
    onDiscardImage: (f: string) => void;
    label?: string;
    buttonText?: string;
}

const VisitorEntranceImagePickerView: React.FC<IVisitorEntranceImagePickerViewProps> = ({
    onModalOpen,
    onDismiss,
    imageOptions,
    modalIsOpen,
    selectedImage,
    onImageSelect,
    onFile,
    label,
    onDiscardImage,
    buttonText
}) => {
    const theme = getTheme();
    const { smallDevice } = useWindowWidth();
    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);
    const bgColor: string | undefined = branding?.orgColors[0];

    return (
        <>
            {label && <Label style={{}}>{label}</Label>}
            <Stack style={{ marginBottom: '10px' }}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 32 }}>
                    <Stack
                        aria-label={'backgroundImagePickerPanel'}
                        onClick={onModalOpen}
                        horizontalAlign="center"
                        verticalAlign="center"
                        style={{
                            width: '407px',
                            height: '127px',
                            background: bgColor === undefined ? IvicosColors.Mittelblau : bgColor,
                            backgroundImage: `url('${selectedImage.url}')`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            border: '0.5px solid' + getTextColorForBackground(bgColor || 'white'),
                            cursor: 'pointer'
                        }}
                    >
                        {selectedImage.url == '' && (
                            <IC variant={'custom'} iconColor={bgColor === undefined ? 'white' : getTextColorForBackground(bgColor)} size={50}>
                                <EditImage />
                            </IC>
                        )}
                    </Stack>

                    {!smallDevice && (
                        <Stack>
                            <Text style={{ marginLeft: 'auto', width: '410px', marginBottom: '40px' }}>{IvicosStrings.changeImageCalloutTitle}</Text>
                            <Stack style={{ flexDirection: 'column', marginLeft: 'auto', width: '36%' }}>
                                <DefaultButton text={buttonText} iconProps={{ iconName: 'edit' }} onClick={onModalOpen}></DefaultButton>
                            </Stack>
                        </Stack>
                    )}
                </Stack>

                <Modal isOpen={modalIsOpen} onDismiss={onDismiss}>
                    <ImagePickerModal
                        {...{
                            onDismiss,
                            selectedImage,
                            imageOptions,
                            onImageSelect,
                            onFile,
                            onDiscardImage,
                            theme,
                            smallDevice
                        }}
                    />
                </Modal>
            </Stack>
        </>
    );
};

export default VisitorEntranceImagePickerView;
