import React, { useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { idpService } from 'services/api/identity-provider.service';

const DirectedToWelcome: React.FC<RouteProps> = (props) => {
    useEffect(() => {
        const loginCheck = async () => {
            try {
                await idpService.getUser();
                window.location.href = '/welcome';
            } catch (error) {
                console.log('Not authorized');
            }
        };

        loginCheck();
    }, []);

    return <>{props.children}</>;
};
export default DirectedToWelcome;
