import { getTheme } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import { useContext, useState } from 'react';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import SocketContext from 'services/socket-connection/context/SocketContext';
import { useLocalProfile } from 'shared-state/identity/hooks';

export const useWaitingAreaMemoComposerState = (
    recipientName?: string,
    recipientId?: string,
    sentBy?: string,
    onClose?: () => void
): {
    theme: any;
    giphyPanelIsOpen: boolean;
    setGiphyPanelIsOpen: (open: boolean) => void;
    memoBody: string;
    setMemoBody: (body: string) => void;
    mode: 'markdown' | 'preview';
    setMode: (mode: 'markdown' | 'preview') => void;
    cancel: () => void;
    send: () => void;
} => {
    const theme = getTheme();
    const [giphyPanelIsOpen, setGiphyPanelIsOpen] = useState<boolean>(false);
    const [, addMessage] = useReceivedMessageInbox();
    const [memoBody, setMemoBody] = useState<string>('');
    const [mode, setMode] = useState<'markdown' | 'preview'>('markdown');
    const {
        SocketState: { socket }
    } = useContext(SocketContext);
    const currentUser = useLocalProfile();

    const cancel = () => {
        setMemoBody('');
        setMode('markdown');
        onClose?.();
    };

    const send = () => {
        if (memoBody.length > 0) {
            socket?.emit('client-emit-message-at-visitor-entrance', {
                type: 'memo-at-visitor-entrance',
                meta: { senderId: currentUser?.uid, forUser: recipientId, sentBy },
                body: memoBody,
                announce: true
            });

            addMessage({
                id: makeMessageId(),
                type: 'confirmation',
                body: `${IvicosStrings.memoSentToNotificationActionTitle} ${sentBy === 'host' ? recipientName : 'Host'}`,
                timeout: 2500,
                meta: {},
                sender: 'system',
                timestamp: new Date()
            });
            cancel();
        }
    };

    return {
        theme,
        giphyPanelIsOpen,
        setGiphyPanelIsOpen,
        memoBody,
        setMemoBody,
        mode,
        setMode,
        cancel,
        send
    };
};
