import * as React from 'react';

function SvgShareScreen(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3 7v18h26V7H3zm2 2h22v14H5V9zm11 1.594l-.719.687-3.5 3.5 1.438 1.438L15 14.437V20h2v-5.563l1.781 1.782 1.438-1.438-3.5-3.5-.719-.687z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgShareScreen;
