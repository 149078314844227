import * as React from 'react';

function SvgGif(props: React.SVGProps<SVGSVGElement>): any {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2.875 6C1.32 6 0 7.254 0 8.813v14.374C0 24.747 1.32 26 2.875 26h26.25C30.68 26 32 24.746 32 23.187V8.813C32 7.255 30.68 6 29.125 6H2.875zm0 2h26.25c.516 0 .875.383.875.813v14.374c0 .43-.36.813-.875.813H2.875C2.359 24 2 23.617 2 23.187V8.813c0-.43.36-.812.875-.812z"
                fill="#000"
            />
            <path
                d="M8.438 11A3.434 3.434 0 005 14.438v4.124A3.434 3.434 0 008.438 22h3.437v-5.5h-2.75v2.75h-.688c-.295 0-.687-.392-.687-.688v-4.125c0-.295.392-.687.688-.687.295 0 .687.392.687.688v.687h2.75v-.688A3.434 3.434 0 008.437 11zm6.058 0v11h2.75V11h-2.75zm5.5 0v11h2.75v-4.125h2.75v-2.75h-2.75V13.75h4.125V11h-6.875z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgGif;
