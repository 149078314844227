import { ActionButton, Callout, DefaultEffects, DirectionalHint, ITheme, mergeStyles, PrimaryButton, Stack, Text, TooltipHost } from '@fluentui/react';
import { AreaDto } from 'services/client-api-wrapper/campus_api/responses.dto';
import { IC } from 'components/SVGIcon';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { getTextColorForBackground } from 'kits/colorKit';
import { Group, MoreStrong } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import { TeamImage } from 'kits/profileImageKit';
import React, { ReactElement } from 'react';
import { useArea } from 'shared-state/directory/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useWindowWidth } from 'shared-state/display-options/hooks';

export interface IAreaCardViewProps {
    fluid?: boolean;
    panelHeader: string;
    isPanelOpen: boolean;
    dismissPanel: () => void;
    campusId: any;
    deleteAreaHandler: (areaId: string) => void;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    vertical?: boolean;
    title: string;
    description?: string;
    team: any;
    isPublic?: boolean;
    onClick?: (e: any) => void;
    onRenderActions: () => ReactElement;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    openPanel: () => void;
    areaId: string;
    saveDataForAreaDeletion: any;
    modalIsOpen: boolean;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAreaToEdit: any;
    theme: ITheme;
    getPrimaryCardColor: () => string;
    stepsEnabled?: boolean;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    steps: {
        initialStep: number;
        steps: (
            | {
                  element: string;
                  intro: string;
                  position: string;
                  tooltipClass: string;
                  showProgress?: undefined;
                  showBullets?: undefined;
              }
            | {
                  element: string;
                  intro: string;
                  position: string;
                  showProgress: boolean;
                  showBullets: boolean;
                  tooltipClass?: undefined;
              }
        )[];
    };
    options: {
        doneLabel: string;
        hideNext: boolean;
        showStepNumbers: boolean;
        showBullets: boolean;
        hidePrev: boolean;
    };
    areaCardWidth: string;
    areaCardWidthVertical: string;
    area?: AreaDto;
    descriptionId: string;
    labelId: string;
    buttonId: string;
    identityClaims?: IIdentityClaims;
    isCalloutVisible: boolean;
    toggleIsCalloutVisible: () => void;
    color: string | undefined;
}

const AreaCardView: React.FC<IAreaCardViewProps> = (props) => {
    const {
        vertical,
        title,
        description,
        team,
        onClick,
        onRenderActions,
        setPanelHeader,
        openPanel,
        areaId,
        saveDataForAreaDeletion,
        modalIsOpen,
        setAreaToEdit,
        setModalIsOpen,
        theme,
        getPrimaryCardColor,
        stepsEnabled,
        setStepsEnabled,
        steps,
        options,
        areaCardWidth,
        areaCardWidthVertical,
        descriptionId,
        labelId,
        buttonId,
        identityClaims,
        isCalloutVisible,
        toggleIsCalloutVisible,
        color
    } = props;

    const areaCardImageStyles = mergeStyles({
        borderRadius: 2,
        height: 48,
        width: 48,
        minHeight: 48,
        maxHeight: 48,
        border: '2px #fff solid',
        boxShadow: DefaultEffects.elevation8,
        color: theme.palette.neutralLighter,
        background: getPrimaryCardColor(),
        marginTop: -48,
        marginBottom: 16
    });

    const [area] = useArea(areaId);
    const { smallDevice } = useWindowWidth();
    return (
        <>
            <Steps
                enabled={stepsEnabled}
                onStart={() => {
                    localStorage.setItem('intro-areas', 'false');
                }}
                options={options}
                steps={steps.steps}
                initialStep={steps.initialStep}
                onExit={() => {
                    setStepsEnabled(false);
                }}
            />
            <Stack
                style={{
                    boxShadow: DefaultEffects.elevation16
                }}
                className={`${vertical ? areaCardWidthVertical : areaCardWidth} one-card`}
                aria-label="area-card"
            >
                <Stack
                    aria-label="area-color"
                    key="area-color"
                    horizontalAlign="end"
                    verticalAlign="center"
                    style={{ height: 32, background: getPrimaryCardColor() }}
                    horizontal
                >
                    <Stack horizontal verticalAlign="center" horizontalAlign="space-between" style={{ marginRight: 7 }}>
                        <PrimaryButton className="pressable--transparent" styles={{ root: { minWidth: 21, padding: 0 } }}>
                            {onRenderActions && onRenderActions()}
                        </PrimaryButton>
                        {(identityClaims?.roles.includes('manager') || identityClaims?.roles.includes('owner')) && (
                            <PrimaryButton
                                aria-label="three-dots-button"
                                onClick={toggleIsCalloutVisible}
                                className="pressable--transparent"
                                styles={{ root: { cursor: 'pointer', minWidth: 21, padding: '0 0 0 5' } }}
                            >
                                <TooltipHost content={smallDevice ? '' : IvicosStrings.showMoreOptionsTooltip} directionalHint={DirectionalHint.topCenter}>
                                    <IC
                                        size={17}
                                        variant="custom"
                                        iconColor={color ? color && getTextColorForBackground(color) : 'white'}
                                        style={{ rotate: '90deg' }}
                                    >
                                        <MoreStrong id={buttonId} />
                                    </IC>
                                </TooltipHost>
                            </PrimaryButton>
                        )}
                    </Stack>

                    {isCalloutVisible && (
                        <Callout
                            ariaLabelledBy={labelId}
                            ariaDescribedBy={descriptionId}
                            role="alertdialog"
                            gapSpace={0}
                            target={`#${buttonId}`}
                            onDismiss={toggleIsCalloutVisible}
                            setInitialFocus
                            directionalHint={DirectionalHint.bottomCenter}
                        >
                            <Stack horizontal={false}>
                                <ActionButton
                                    onClick={() => {
                                        openPanel();
                                        setAreaToEdit(area && area.name, areaId);
                                        setPanelHeader(IvicosStrings.editAreaActionTitle);
                                    }}
                                    text={IvicosStrings.editAreaActionTitle}
                                    iconProps={{ iconName: 'Edit' }}
                                    aria-label="edit-area-button"
                                />
                                <ActionButton
                                    onClick={() => {
                                        setModalIsOpen(!modalIsOpen);
                                        saveDataForAreaDeletion(area && area.name, areaId);
                                    }}
                                    text={IvicosStrings.deleteAreaActionTitle}
                                    iconProps={{ iconName: 'Delete' }}
                                    aria-label="delete-area-button"
                                />
                            </Stack>
                        </Callout>
                    )}
                </Stack>
                <Stack
                    className="padding--default padding_vertical--large"
                    styles={{
                        root: {
                            background: theme.palette.white,
                            cursor: 'pointer',
                            ':hover': {
                                background: theme.palette.themeLighter
                            },
                            ':active': {
                                background: theme.palette.themeLight
                            }
                        }
                    }}
                    onClick={(e) => onClick && onClick(e)}
                >
                    <Stack key="area-image" verticalAlign="center" horizontalAlign="center" className={areaCardImageStyles}>
                        {team ? (
                            <TeamImage teamId={team.id} imageProps={{ style: { width: '100%', height: '100%' } }} />
                        ) : (
                            <Text variant="large" style={{ color: getTextColorForBackground(getPrimaryCardColor()), fontWeight: 'bold' }}>
                                {title
                                    .split(' ')
                                    .reduce((a, b) => a + b[0], '')
                                    .slice(0, 2)
                                    .toUpperCase()}
                            </Text>
                        )}
                    </Stack>
                    <Text variant="large" style={{ fontWeight: 'bold' }}>
                        {title}
                    </Text>
                    <Stack horizontal tokens={{ childrenGap: 8 }} style={{ marginTop: 16 }}>
                        <IC size={20} variant="dark">
                            <Group />
                        </IC>
                        <Text variant="medium" style={{ color: theme.palette.black }}>
                            {IvicosStrings.areaOpenToEveryoneLabel}
                        </Text>
                    </Stack>

                    {description && (
                        <Stack style={{ borderTop: '1px #e6e6e6 solid', marginTop: 16, paddingTop: 8, color: theme.palette.black }}>
                            <Text>{description}</Text>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default AreaCardView;
