import ArrowLeft from './media/xlarge/arrow--left.svg';
import DoorOpen from './media/xlarge/door--open.svg';
import LockedEurope from './media/xlarge/locked--europe.svg';
import Disconnected from './media/xlarge/disconnected.svg';

const xLarge = {
    ArrowLeft,
    DoorOpen,
    LockedEurope,
    Disconnected
};

export default xLarge;
