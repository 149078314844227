import { IIdentity } from 'kits/authKit';
import { sendEvent } from 'kits/eventKit';
import { useState } from 'react';

export const useSession = (key: string, defaultValue?: string): [any, (val: any) => void] => {
    const initialValue = sessionStorage.getItem('@sessionKit:' + key);
    if (!initialValue && defaultValue) sessionStorage.setItem('@sessionKit:' + key, defaultValue);

    const [value] = useState(sessionStorage.getItem('@sessionKit:' + key));

    return [value, (val: any) => setSession(key, val)];
};

export const useSessionIdentity = (key: string, defaultValue?: IIdentity): [any, (val: any) => void] => {
    const retrieveFromStorage = () => {
        try {
            const unparsedVal = sessionStorage.getItem('@sessionKit:' + key);
            if (!unparsedVal) return undefined;
            const val = JSON.parse(unparsedVal);
            return val;
        } catch (err) {
            return undefined;
        }
    };
    const initialValue = retrieveFromStorage();
    if (!initialValue && defaultValue) sessionStorage.setItem('@sessionKit:' + key, JSON.stringify(defaultValue));

    const [value] = useState(retrieveFromStorage());

    return [value, (val: any) => setSessionIdentity(key, val)];
};

export const getSession = (key: string): [any, (val: any) => void] => {
    return [sessionStorage.getItem('@sessionKit:' + key), (val: any) => setSession(key, val)];
};

export const setSession = (key: string, value: any): any => {
    if (sessionStorage.getItem('@sessionKit:' + key) == value) return;

    sessionStorage.setItem('@sessionKit:' + key, value);
    sendEvent('sessionKit_' + key, {
        key: key,
        value: value
    });
};

export const setSessionIdentity = (key: string, value: IIdentity): any => {
    sessionStorage.setItem('@sessionKit:' + key, JSON.stringify(value));
    sendEvent('sessionKit_' + key, {
        key: key,
        value: value
    });
};

export const setSessionSilent = (key: string, value: any): any => {
    if (sessionStorage.getItem('@sessionKit:' + key) == value) return;

    sessionStorage.setItem('@sessionKit:' + key, value);
};

export const updateSession = (key: string): any => {
    const value = sessionStorage.getItem('@sessionKit:' + key);
    sendEvent('sessionKit_' + key, {
        key: key,
        value: value
    });
};
