import { UserAvailability } from 'kits/availabilityKit';
import { sendEvent } from 'kits/eventKit';
import { useCallback, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { useIdentityClaims, useLocalProfile, useSharedAvailability } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';

export interface IUserProfileState {
    localProfile?: any;
    identityClaims?: IIdentityClaims;
    sharedAvailability?: UserAvailability;
    showProfileImageEditingPanel: boolean;
    setShowProfileImageEditingPanel: React.Dispatch<React.SetStateAction<boolean>>;
    changeName: boolean;
    setChangeName: React.Dispatch<React.SetStateAction<boolean>>;
    userName: string;
    handleName: (value: any) => void;
    handleSubmitClick: () => void;
    handleCancelClick: () => void;
    showMinLimitText: boolean;
}

export const useUserProfileState: () => IUserProfileState = () => {
    const localProfile = useLocalProfile();
    const [identityClaims] = useIdentityClaims();
    const [sharedAvailability] = useSharedAvailability();
    const [showProfileImageEditingPanel, setShowProfileImageEditingPanel] = useState<boolean>(false);
    const [changeName, setChangeName] = useState(false);
    const [userName, setUserName] = useState('');

    const [showMinLimitText, setShowMinLimitText] = useState(false);

    const handleName = (value: any) => {
        setShowMinLimitText(value?.length < 1);
        setUserName(value);
    };

    const handleSubmitClick = useCallback(() => {
        idpService
            .updateUser(userName, undefined, undefined, undefined, undefined)
            .then(() => {
                sendEvent('refetchUserDetails', {});
                setChangeName(false);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, [userName]);

    const handleCancelClick = () => {
        setChangeName(false);
        setUserName(localProfile?.displayName || '');
        setShowMinLimitText(false);
    };

    return {
        localProfile,
        identityClaims,
        sharedAvailability,
        showProfileImageEditingPanel,
        setShowProfileImageEditingPanel,
        changeName,
        setChangeName,
        userName,
        handleName,
        handleSubmitClick,
        handleCancelClick,
        showMinLimitText
    };
};
