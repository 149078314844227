import { getTheme, ITheme, mergeStyles } from '@fluentui/react';
import { useRoomActions } from 'features/Rooms/RoomList/helpers/roomHooks';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { sendEvent } from 'kits/eventKit';
import { useEffect } from 'react';
import { useRoom } from 'shared-state/directory/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useUsers, useUsersInRoom, useVisitorList } from 'shared-state/presence/hooks';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';
import 'styles/RoomList.css';
import { v1 as uuidv1 } from 'uuid';
import { useNavigate } from 'react-router';
import { IRoomAction } from '../helpers/roomTypes';

export interface IListedRoomState {
    isSelected: boolean;
    usersInRoom: IProfile[];
    actions: IRoomAction[];
    rootStyle: string;
    room: IRoomResource | undefined;
    onDoubleClick: () => void;
    isRoomEnteranceAllowedForUser: () => boolean;
    theme: ITheme;
    userId: string | undefined;
    isVisitorInArea?: boolean;
}

export const useListedRoomState: (roomId: string) => IListedRoomState = (roomId) => {
    const [currentRoom] = useCurrentRoomId();
    const isSelected = currentRoom == roomId;

    const localProfile = useLocalProfile();
    const [room] = useRoom(roomId);

    const usersInRoom = useUsersInRoom(roomId);

    const actions = useRoomActions(roomId);

    const rootStyle = mergeStyles({ marginBottom: 8, overflow: 'hidden', borderRadius: 4 });

    const theme = getTheme();
    const [areaId] = useCurrentAreaId();

    const visitors = useVisitorList();
    const users = useUsers();
    const routeHistory = useNavigate();
    const { sendAccessRequest } = useEmittingEvents();

    const isUserInSomeonesPersonalRoom = () => {
        return currentRoom && currentRoom.includes('personal') && currentRoom.includes(localProfile?.uid || '') == false;
    };

    useEffect(() => {
        if (room && isUserInSomeonesPersonalRoom()) {
            const userId = currentRoom?.slice(9, 45);
            sendAccessRequest(userId || '', room.id || '');
        }
    }, [areaId, currentRoom]);

    const userId = localProfile?.uid;
    const isRoomEnteranceAllowedForUser: () => boolean = () => {
        if (!userId || !room) return false;

        if ((localProfile?.type == 'visitor' || localProfile?.type == 'auto-confirmed-visitor') && !room.attributes.openForVisitors) return false;
        else if (room.attributes.whitelist)
            return (
                (room.attributes.whitelist.length === 0 && !room.attributes.private) ||
                room.attributes.whitelist.includes(userId) ||
                userId === room?.attributes.creatorId
            );
        else return true;
    };
    const onDoubleClick = () => {
        if (room && isRoomEnteranceAllowedForUser()) {
            const newJitsiSessionId = uuidv1();
            localStorage.setItem('jitsiSessionId', newJitsiSessionId);
            routeHistory('/areas/' + areaId + '/rooms/' + room.id);
            sendEvent('closeMobileRoomsPanel', {});
        } else return;
    };

    const visitorHosts = visitors[0].map((visitor: any) => visitor.host).join();

    const visitorRooms = users.map((user: any) => user.type).join();

    const isVisitorInArea = visitorHosts.includes(localProfile?.uid || '@@@@@@@@2') || visitorRooms.includes('visitor');

    return {
        isSelected,
        usersInRoom,
        actions,
        rootStyle,
        currentRoom,
        localProfile,
        room,
        onDoubleClick,
        isVisitorInArea,
        isRoomEnteranceAllowedForUser,
        theme,
        userId
    };
};
