import { useRecoilState, useRecoilValue } from 'recoil';
import { aCreatedLinkAttachment, aLinkAttachmentItemId, aLinkAttachmentItemIsPinned, aLinkAttachmentModalBox, aLinkAttachmentModalValue } from './atom';
import { Buffer } from 'buffer';

export interface ILinkAttachmentModalBoxState {
    toggleLinkAttachmentModalBox: boolean;
    cancelLinkAttachment: () => void;
    saveLinkAttachment: () => void;
    onLinkAttachmentValueChange: (e: any, newValue: any) => void;
    onLinkAttachmentPinnedChange: (e: any, newValue: any) => void;
    linkAttachmentModalValue: string;
    linkAttachmentItemId: string;
    linkAttachmentItemIsPinned: boolean;
}

export const useLinkAttachmentModalBoxState: () => ILinkAttachmentModalBoxState = () => {
    const [toggleLinkAttachmentModalBox, setToggleLinkAttachmentModalBox] = useRecoilState<boolean>(aLinkAttachmentModalBox);
    const [linkAttachmentModalValue, setLinkAttachmentModalValue] = useRecoilState(aLinkAttachmentModalValue);
    const [, setCreatedLinkAttachment] = useRecoilState(aCreatedLinkAttachment);
    const linkAttachmentItemId = useRecoilValue(aLinkAttachmentItemId);
    const [linkAttachmentItemIsPinned, setLinkAttachmentItemIsPinned] = useRecoilState(aLinkAttachmentItemIsPinned);

    const cancelLinkAttachment = () => {
        setLinkAttachmentModalValue('');
        setToggleLinkAttachmentModalBox(true);
        setCreatedLinkAttachment(false);
        setLinkAttachmentItemIsPinned(false);
    };

    const saveLinkAttachment = () => {
        if (linkAttachmentModalValue.length > 0 && linkAttachmentItemId.endsWith('linkmanager')) {
            const urlToSave =
                linkAttachmentItemId === 'linkmanager' ? Buffer.from(linkAttachmentModalValue, 'binary').toString('base64') : linkAttachmentModalValue;
            setLinkAttachmentModalValue(urlToSave);
            setCreatedLinkAttachment(true);
            setToggleLinkAttachmentModalBox(true);
        }
    };

    const onLinkAttachmentValueChange = (e: any, newValue: any) => {
        if (typeof newValue == 'string') {
            const httpTest = /https|http/;
            const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|(?:m\.)?youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/; // eslint-disable-line max-len
            const collaboardRegex = /^(?:https?:\/\/)(web|Api|de|ch-api|ch)\.collaboard\.app\/collaboard\/(\d+)$/;

            if (linkAttachmentItemId === 'youtubelinkmanager') {
                const matched = newValue.match(p);
                if (matched) setLinkAttachmentModalValue(matched[1]);
                else setLinkAttachmentModalValue('');
            } else if (linkAttachmentItemId === 'collaboardlinkmanager') {
                const matched = newValue.match(collaboardRegex);
                if (matched && parseInt(matched[2])) setLinkAttachmentModalValue(matched[2]);
                else setLinkAttachmentModalValue('');
            } else if (httpTest.test(newValue)) {
                setLinkAttachmentModalValue(newValue);
            } else {
                const newUpdatedValue = `https://${newValue}`;
                setLinkAttachmentModalValue(newUpdatedValue);
            }
        } else {
            return;
        }
    };

    const onLinkAttachmentPinnedChange = (e: any, checked: any) => {
        if (typeof checked == 'boolean') {
            setLinkAttachmentItemIsPinned(checked);
        } else {
            return;
        }
    };

    return {
        toggleLinkAttachmentModalBox,
        cancelLinkAttachment,
        saveLinkAttachment,
        onLinkAttachmentValueChange,
        onLinkAttachmentPinnedChange,
        linkAttachmentModalValue,
        linkAttachmentItemId,
        linkAttachmentItemIsPinned
    };
};
