import { useMemo } from 'react';
import { useStyledRoomList } from 'shared-state/directory/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { sortRoomList } from './helpers';

export interface CommonRoomListState {
    enableCreateRoomButton?: boolean;
    commonRoomIds: string[];
}
const useCommonRoomListState: () => CommonRoomListState = () => {
    const commonRooms = useStyledRoomList();
    const [identityClaims] = useIdentityClaims();
    const enableCreateRoomButton = identityClaims?.roles.includes('manager') || identityClaims?.roles.includes('owner');
    const commonRoomIds = useMemo(() => {
        return sortRoomList(commonRooms).map((r: any) => r.id);
    }, [commonRooms]);

    return { enableCreateRoomButton, commonRoomIds };
};
export default useCommonRoomListState;
